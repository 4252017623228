import { Api } from './api'
import { User } from '@/models/user'

const { API_ENDPOINT } = process.env

export const getUser = async (userId: string): Promise<any> => {
  const response = await Api.get(`${API_ENDPOINT}/platform/users/${userId}?ts=${Date.now()}`)

  return response
}

export const getUserById = async (userId: string) => {
  const user = await getUser(userId)
  const roles = []

  for (const key in user.roles) {
    if (Object.prototype.hasOwnProperty.call(user.roles, key)) {
      if (user.roles[key]) {
        roles.push(key)
      }
    }
  }

  return new User({
    id: user._id,
    role: user.role,
    roles,
    level: user.level,
    email: user.email,
    avatar: user.avatar,
    firstName: user.profile.first_name,
    lastName: user.profile.last_name,
    fullName: user.fullName,
    dni: user.profile.dni,
    country: user.profile.country,
    cityV2: user.profile.cityV2,
    isNotFromArg: user.flags.isNotFromArg,
    phone: user.profile.phone,
    isTutor: user.flags.isTutor,
    hasBeca: user.flags.hasBeca,
    getStreamToken: user.getStreamToken,
    subscription: user.subscription,
    preferencesFlag: user.flags.hasSetPreferences,
    coderPlus: user.flags.coderPlus
  })
}
