
import { defineComponent, reactive, ref, Ref, onMounted } from 'vue'
import { useUserStore } from '@/store/modules/user.store'
import { useI18n } from 'vue-i18n'
import { useToast } from 'primevue/usetoast'
import Button from 'primevue/button'
import Header from './components/Header.vue'
import FirstStep from './components/FirstStep.vue'
import SecondStep from './components/SecondStep.vue'
import LastStep from './components/LastStep.vue'
import LoadingScreen from '@/views/LoadingScreen.vue'
import LoginSlides from '@/views/LoginSlides.vue'

// Service
import { updateUserOnboarding, completeUserOnboarding } from '@/api/newPlatform/profile.api'
import { UpdateUserOnboardingArgs } from '@/models/profile/profile'

export default defineComponent({
  components: {
    Header,
    FirstStep,
    SecondStep,
    LastStep,
    Button,
    LoadingScreen,
    LoginSlides
  },
  setup() {
    const { t } = useI18n()
    const userTmp = useUserStore()
    const user = reactive({ ...userTmp })
    const currentStep: Ref<number> = ref(1)
    const lastStep: Ref<number> = ref(3)
    const isSaving: Ref<boolean> = ref(false)
    const toast = useToast()
    const finalLoading = ref<boolean>(false)
    const education = reactive({
      title: '',
      institute: '',
      range: {
        from: '',
        to: '',
        currently: false
      }
    })
    const work = reactive({
      title: '',
      company: '',
      range: {
        from: '',
        to: '',
        currently: false
      }
    })

    const nextStep = () => {
      currentStep.value++
    }

    const finish = async () => {
      const userId = user.id
      const profile = {
        first_name: user.firstName,
        last_name: user.lastName,
        email: user.email,
        phone: user.phone,
        country: user.country,
        cityV2: user.cityV2,
        education: education.title && education.range.from ? [education]: [],
        works: work.title && work.range.from ? [work]: []
      }

      isSaving.value = true

      const success = await updateUserOnboarding(userId, { _id: userId, profile } as UpdateUserOnboardingArgs)
      const completed = await completeUserOnboarding(userId)

      if (!success || !completed) {
        isSaving.value = false
        toast.add({
          severity: 'error',
          detail: '¡Error!',
          life: 3000
        })
      } else {
        finalLoading.value = true
        setTimeout(() => {
          window.parent.postMessage(JSON.stringify({ type: 'courses' }), '*')
        }, 5000)
      }
    }

    onMounted(async () => {
      await userTmp.getUser()
    })

    return {
      t,
      user,
      education,
      work,
      currentStep,
      lastStep,
      isSaving,
      nextStep,
      finish,
      finalLoading
    }
  }
})
