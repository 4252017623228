
import { defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useToast } from 'primevue/usetoast'

export default defineComponent({
  emits: ['select', 'remove'],
  props: {
    multiple: Boolean,
    maxFileSize: { type: Number, default: 10000000 },
    maxFileSizeInMB: { type: Number, default: 10 }
  },
  setup (props, { emit }) {
    const { t } = useI18n()
    const file = ref()
    const toast = useToast()
    const choose = () => file.value.choose()

    /* Checking if the file is more than 1, if it is, it will show an error message and get allowed file
    if it is not, it will emit the event. */
    const select = (event: any) => {
      if(event.files.length > 1) {
        event.files.length = 1
        return toast.add({ severity: 'error', detail: t('inputfile.error.fileLimit.message'), life: 3000 })
      }else if (event.files.length === 0) return 
      
      return emit('select', event.files[0])
    }
    const remove = () => emit('remove')

    return { t, file, choose, select, remove }
  }
})
