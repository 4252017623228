import { RouteRecordRaw } from 'vue-router'
import Ratings from './Ratings.vue'
import { NewPlatform as Names } from '@/router/route-names'

const RatingRoutes: Array<RouteRecordRaw> = [
  {
    path: '',
    component: Ratings,
    name: Names.RATINGS
  },
  {
    path: '/ratings/:classId',
    component: Ratings,
    name: Names.RATING_BY_CLASS
  }
]

export default RatingRoutes
