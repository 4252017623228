import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-788646ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "opinionTag p-d-flex p-flex-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ColumnOpinion = _resolveComponent("ColumnOpinion")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.tagPositive?.tags.length)
      ? (_openBlock(), _createBlock(_component_ColumnOpinion, {
          key: 0,
          feedbackType: "positive",
          tags: _ctx.tagPositive.tags,
          tagTotals: _ctx.tagPositive.total,
          totals: _ctx.tagTotals
        }, null, 8, ["tags", "tagTotals", "totals"]))
      : _createCommentVNode("", true),
    (_ctx.tagNeutral?.tags.length || _ctx.tagNegative?.tags.length)
      ? (_openBlock(), _createBlock(_component_ColumnOpinion, {
          key: 1,
          feedbackType: "suggestion",
          tags: _ctx.tagsNegAndNeutral,
          tagTotals: (_ctx.tagNeutral?.total || 0) + (_ctx.tagNegative?.total || 0),
          totals: _ctx.tagTotals
        }, null, 8, ["tags", "tagTotals", "totals"]))
      : _createCommentVNode("", true)
  ]))
}