<template>
  <path
    d="M4.125 7.5C4.74632 7.5 5.25 6.99632 5.25 6.375C5.25 5.75368 4.74632 5.25 4.125 5.25C3.50368 5.25 3 5.75368 3 6.375C3 6.99632 3.50368 7.5 4.125 7.5Z"
    fill="#F2F2F2"
  />
  <path
    d="M4.125 13.125C4.74632 13.125 5.25 12.6213 5.25 12C5.25 11.3787 4.74632 10.875 4.125 10.875C3.50368 10.875 3 11.3787 3 12C3 12.6213 3.50368 13.125 4.125 13.125Z"
    fill="#F2F2F2"
  />
  <path
    d="M4.125 18.7505C4.74632 18.7505 5.25 18.2469 5.25 17.6255C5.25 17.0042 4.74632 16.5005 4.125 16.5005C3.50368 16.5005 3 17.0042 3 17.6255C3 18.2469 3.50368 18.7505 4.125 18.7505Z"
    fill="#F2F2F2"
  />
  <path d="M8.25 6.375H20.25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  <path d="M8.25061 12H20.25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  <path d="M8.25061 17.625H20.25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</template>
