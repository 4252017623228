import { IProjectModule } from '@/models/classes/project'

import { optionsListClasses as copies } from '@/locales/classes/es.json'

type Challenge = {
  label: string
  id: string
  color: 'primary' | 'secondary'
  isDeliverExpired: boolean
  stage: string
}

// Function to retrieve btn label (short or long label) + props for multiple challenges (tp | preIntegrator | integrator) data
export function useChallengeBtn(type: 'long' | 'short', challenges: IProjectModule[]): Challenge[] {
  const parsedChallenges: Challenge[] = []
  const longlabel = type === 'long'

  if (challenges.length === 1 && !challenges[0].flags?.isPreIntegratorTp && !challenges[0].flags?.isIntegratorTp) {
    parsedChallenges.push({
      label: copies.goToChallenge.long,
      id: challenges[0]._id,
      color: 'primary',
      isDeliverExpired: !!challenges[0].flags?.isDeliverExpired,
      stage: challenges[0].stage
    })

    return parsedChallenges
  } else if (
    challenges.every((item) => !item?.flags?.isPreIntegratorTp && !item?.flags?.isIntegratorTp) &&
    challenges?.length > 1
  ) {
    challenges.forEach((item, index) => {
      parsedChallenges.push({
        label: longlabel ? `${copies.goTo} ${++index}º ${copies.goToChallenge.short}` : `${++index}º ${copies.goToChallenge.short}`,
        id: item._id,
        color: index === 1 ? 'primary' : 'secondary',
        isDeliverExpired: !!item.flags?.isDeliverExpired,
        stage: item.stage
      })
    })

    return parsedChallenges
  } else {
    challenges.forEach((item) => {
      let text = copies.goToChallenge[type]
      if (item?.flags?.isPreIntegratorTp) text = copies.goToPreIntegratorTp[type]
      else if (item?.flags?.isIntegratorTp) text = copies.goToIntegratorTp[type]

      parsedChallenges.push({
        label: text,
        id: item._id,
        color: item.flags?.isIntegratorTp || item.flags?.isPreIntegratorTp ? 'primary' : 'secondary',
        isDeliverExpired: !!item.flags?.isDeliverExpired,
        stage: item.stage
      })
    })

    return parsedChallenges
  }
}
