
import { defineComponent } from 'vue'
import SkeletonText from '@/components/aero/skeleton/SkeletonText.vue'
import SkeletonInput from '@/components/aero/skeleton/SkeletonInput.vue'
import SkeletonBox from '@/components/aero/skeleton/SkeletonBox.vue'
import SkeletonIcon from '@/components/aero/skeleton/SkeletonIcon.vue'

export default defineComponent({
  components: { SkeletonText, SkeletonInput, SkeletonBox, SkeletonIcon },
  props: {}
})
