import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SkeletonProfileCardSmall = _resolveComponent("SkeletonProfileCardSmall")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { class: "teacherGroup p-d-flex p-flex-column" }, {
    default: _withCtx(() => [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(4, (i) => {
        return _createVNode(_component_SkeletonProfileCardSmall, { key: i })
      }), 64))
    ]),
    _: 1
  }))
}