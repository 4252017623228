import {
  IWorkExperience,
  WorkExperienceResponse,
  IEducation,
  EducationResponse,
  ReferralCouponResponse
} from '@/models/newPlatform/profile'
import { Api } from '../api'

import {
  GetProfileInfoArgs,
  UpdateUserOnboardingArgs,
  UpdateOnboardingArgs,
  UpdateProfileArgs,
  ChangePasswordResponse,
  UpdateSocialNetworksArgs,
  User,
  SocialNetwork,
  Payout,
  PayoutInvoice,
  UpdateBillingDataArgs,
  FileResponse,
  Banking,
  UserProfile,
  Skill,
  Language,
  UserWorkPreference,
  Country
} from '@/models/profile/profile'

import { Payment, Invoice } from '@/models/profile/billing'

import { Talent } from '@/models/talent/talent'

import { UserBalance } from '@/models/profile/balance'

const { API_ENDPOINT, ACADEMIC_API_ENDPOINT, AUTH0_API, AUTH0_CLIENT_ID, AUTH0_CONNECTION, VUE_APP_CH_API_BFF } = process.env

/**
 * API Call to fetch the user's profile information
 * @param param.userId `required` Logged in user ID
 * @returns Promise containing user profile information
 */
export const getProfile: (args: GetProfileInfoArgs) => Promise<UserProfile> = async ({ userId }) => {
  return Api.get(`${API_ENDPOINT}/platform/users/${userId}?lean=1&_id=${userId}&session=1&ts=${Date.now()}&userId=${userId}`)
}

export const getCountries: () => Promise<{ data: Country[], msg: string, error: boolean }> = async () => {
  return Api.get('https://countriesnow.space/api/v0.1/countries')
}

export const getSkills: () => Promise<Skill[]> = async () => {
  return Api.get(`${API_ENDPOINT}/platform/talent/skills`)
}

export const getSkillsUser: (userId: string) => Promise<Skill[]> = async (userId: string) => {
  return Api.get(`${API_ENDPOINT}/platform/users/${userId}/skills`)
}

export const setSkills: (userId: string, skills: Skill[]) => Promise<any> = async (userId, skills) => {
  return Api.post(`${API_ENDPOINT}/platform/users/${userId}/skills`, { skills })
}

export const deleteSkill: (userId: string, skillId: string) => Promise<any> = async (userId, skillId) => {
  return Api.del(`${API_ENDPOINT}/platform/users/${userId}/skills/${skillId}`)
}

export const getExpertiseAreas: () => Promise<UserWorkPreference[]> = async () => {
  return Api.get(`${API_ENDPOINT}/platform/talent/expertice-areas`)
}

export const getExpertiseUser: (userId: string) => Promise<UserWorkPreference[]> = async (userId: string) => {
  return Api.get(`${API_ENDPOINT}/platform/users/${userId}/expertice`)
}

export const setExpertise: (userId: string, experticeArea: UserWorkPreference[]) => Promise<UserWorkPreference[]> = async (userId, experticeArea) => {
  return Api.put(`${API_ENDPOINT}/platform/users/${userId}/expertice`, { expertice: experticeArea })
}

export const setFlagPreferences: (userId: string, flag: boolean) => Promise<any> = async (userId, flag) => {
  return Api.put(`${API_ENDPOINT}/platform/users/${userId}/preferences`, { preferencesFlag: flag })
}

export const getLanguagesUser: (userId: string) => Promise<Language[]> = async (userId: string) => {
  return Api.get(`${API_ENDPOINT}/platform/users/${userId}/languages`)
}

export const getLanguages: (country: string) => Promise<Language[]> = async (country: string) => {
  return Api.get(`${API_ENDPOINT}/platform/talent/languages?country=${country}`)
}

export const getLanguageLevel: (country: string) => Promise<any[]> = async (country: string) => {
  return Api.get(`${API_ENDPOINT}/platform/talent/language-levels?country=${country}`)
}

export const setLanguages: (userId: string, languages: Language[]) => Promise<Language> = async (userId, languages) => {
  return Api.put(`${API_ENDPOINT}/platform/users/${userId}/languages`, { languages })
}

export const deleteLanguages: (userId: string, languageId: string) => Promise<any> = async (userId, languageId) => {
  return Api.del(`${API_ENDPOINT}/platform/users/${userId}/languages/${languageId}`)
}

export const getUserWorkModalities: (userId: string) => Promise<UserWorkPreference[]> = async (userId: string) => {
  return Api.get(`${API_ENDPOINT}/platform/users/${userId}/work-modalities`)
}

export const getUserIndustriesPreference: (userId: string) => Promise<UserWorkPreference[]> = async (userId: string) => {
  return Api.get(`${API_ENDPOINT}/platform/users/${userId}/industries-preference`)
}

export const setUserWorkModalities: (userId: string, workModalities: UserWorkPreference[]) => Promise<UserWorkPreference[]> = async (userId, workModalities) => {
  return Api.put(`${API_ENDPOINT}/platform/users/${userId}/work-modalities`, { workModalities })
}

export const setUserIndustriesPreference: (userId: string, industriesPreference: UserWorkPreference[]) => Promise<UserWorkPreference[]> = async (userId, industriesPreference) => {
  return Api.put(`${API_ENDPOINT}/platform/users/${userId}/industries-preference`, { industriesPreference })
}
export const uploadCurriculum = async (formData: FormData): Promise<FileResponse> => {
  return Api.post(`${API_ENDPOINT}/platform/upload/files`, formData, null, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

export const getCurriculumUser: (userId: string) => Promise<any> = async (userId) => {
  return Api.get(`${API_ENDPOINT}/platform/users/${userId}/cv-link`)
}

export const setCurriculumUser: (userId: string, cvLink: any) => Promise<any> = async (userId, cvLink) => {
  return Api.put(`${API_ENDPOINT}/platform/users/${userId}/cv-link`, { cvLink })
}

/**
 * API Call to fetch request to change password
 * @param param.userId `required` Logged in user ID
 * @returns Promise containing object with success key
 */
export const changePassword = async ({ userId }: { userId: string }): Promise<ChangePasswordResponse> => {
  return Api.post(`${API_ENDPOINT}/platform/users/${userId}/change-password`, { v2: 1, plat_2021: true })
}

export const changePasswordAuth0 = async (userEmail: string) => {
  return Api.post(`${AUTH0_API}/dbconnections/change_password`, {
    'client_id': AUTH0_CLIENT_ID,
    'email': userEmail,
    'connection': AUTH0_CONNECTION
  })
}

export const confirmPasswordChange = async ({ userId, token, password, force = false, notification = false }: { userId: string, token: string, password: string, force: boolean, notification: false }): Promise<ChangePasswordResponse> => {
  return Api.put(`${API_ENDPOINT}/platform/change-password-request`, { userId, token, password, force, notification })
}

/**
 * API Call to download a certificate in pdf format
 * @param courseId `required` current course
 * @param userId `required` Logged in user ID
 * @param user `required` user from API call
 * @returns Promise containing a pdf
 */
export const getEnrollCertificate = async (courseId: string, userId: string, user: User): Promise<Blob> => {
  const payload = { courseId, userId, user }

  return await Api.post(
    `${API_ENDPOINT}/platform/courses/${courseId}/students/${userId}/self-management/enroll-cert`,
    payload,
    {},
    {
      responseType: 'blob'
    }
  )
}

/**
 * API Call to request an aditional schedule from some course
 * @param courseId `required` current course
 * @param userId `required` Logged in user ID
 * @param reason `required` why you want to request and aditional schedule
 * @returns confirmation message
 */
export const requestAditionalSchedule = async (courseId: string, userId: string, reason: string) => {
  const payload = {
    courseId,
    reason,
    schedule: false,
    userId
  }

  return await Api.post(`${API_ENDPOINT}/platform/courses/${courseId}/request-additional-schedule`, payload)
}

/**
 * API Call to request an re schedule a class from some course
 * @param courseId `required` Current id course
 * @param userId `required` Logged in user ID
 * @param reason `required` Why you want to request and aditional schedule
 * @returns confirmation message
 */
export const requestReSchedule = async (courseId: string, userId: string, reason: string) => {
  const payload = {
    courseId,
    reason,
    userId,
    schedule: {}
  }

  return await Api.post(`${API_ENDPOINT}/platform/courses/${courseId}/request-additional-schedule`, payload)
}

/**
 * API Call to get zoom credentials
 * @param courseId `required` Current id course
 * @returns credentials
 */
export const getZoomCredentials = async (courseId: string): Promise<{ email: string; password: string }> => {
  return await Api.get(`${API_ENDPOINT}/platform/courses/${courseId}/zoom`)
}

/**
 * API Call to update user onboarding profile
 * @param param.userId `required` Logged in user ID
 * @param param.studyLevel `required` User's study level
 * @param param.whyTakeTheCourse `required` User's reasons to take the course
 * @returns Promise containing pre-updated user onboarding profile information
 */
export const updateOnboarding = async ({ userId, ...profile }: UpdateOnboardingArgs) => {
  return Api.put(`${API_ENDPOINT}/platform/users/${userId}/onboarding-profile`, { profile })
}

/**
 * API Call to update user onboarding profile
 * @param param.userId `required` Logged in user ID
 * @param param.UpdateUserOnboardingArgs `required` User's information
 * @returns Promise containing pre-updated user onboarding profile information
 */
export const updateUserOnboarding = async (userId: string, user: UpdateUserOnboardingArgs) => {
  return Api.put(`${API_ENDPOINT}/platform/users/${userId}/onboarding-profile`, user)
}

/**
 * API Call to update user onboarding flgag
 * @param param.userId `required` Logged in user ID
 * @returns Promise containing updated user onboarding flag
 */
export const completeUserOnboarding = async (userId: string) => {
  return Api.put(`${API_ENDPOINT}/platform/users/${userId}/onboardingv2`, { userId })
}

/**
 * API Call to update the user's profile information
 * @param param.userId `required` Logged in user ID
 * @param param.bio `required` User's bio
 * @returns Promise
 */
export const updateProfile = ({ userId, ...profile }: UpdateProfileArgs) => {
  return Api.put(`${API_ENDPOINT}/platform/users/${userId}`, { profile, _id: userId })
}

/**
 * API Call to update the user's social networks
 * @param param.userId `required` Logged in user ID
 * @param.socialNetworks `required` User's social networks
 * @returns Promise containing updated user social networks
 */
export const updateSocialNetworks = ({ userId, socialNetworks }: UpdateSocialNetworksArgs) => {
  return Api.put(`${API_ENDPOINT}/platform/users/${userId}/social-networks`, { socialNetworks, userId })
}

/**
 * API Call to get user's social networks
 * @param param.userId `required` Logged in user ID
 * @returns Promise containing user social networks
 */
export const getSocialNetworks = ({ userId }: { userId: User['_id'] }): Promise<SocialNetwork[]> => {
  return Api.get(`${API_ENDPOINT}/platform/users/${userId}/social-networks`)
}

/**
 * API Call to get user's education information
 * @param userId `required` Logged user ID
 * @returns Promise containing array of user's education information
 * @see [Postman]{@link https://coderplatformers.postman.co/workspace/Coder-House-Platform~9b3cd88b-81e2-4307-8432-95267b456aa7/request/17745020-6d356596-05b7-4c0b-8e53-3f5ae05f9cc9 }
 */

export const getEducation = async (userId: string): Promise<EducationResponse[]> => {
  return Api.get(`${API_ENDPOINT}/platform/users/${userId}/education?ts=${Date.now()}`)
}

/**
 * API Call to create education information
 * @param userId `required` Logged user ID
 * @param education `required`education data to be created
 * @returns Promise containing the created education data
 * @see [Postman]{@link https://coderplatformers.postman.co/workspace/Coder-House-Platform~9b3cd88b-81e2-4307-8432-95267b456aa7/request/17745020-edcdcb68-35aa-4870-92c4-e585ab43ff16 }
 */

export const setEducation = async (userId: string, education: IEducation): Promise<EducationResponse> => {
  return Api.post(`${API_ENDPOINT}/platform/users/${userId}/education?ts=${Date.now()}`, education)
}

/**
 * API Call to update education information
 * @param userId `required` Logged user ID
 * @param education `required`education data to be updated
 * @returns Promise containing the updated education data
 * @see [Postman]{@link https://coderplatformers.postman.co/workspace/Coder-House-Platform~9b3cd88b-81e2-4307-8432-95267b456aa7/request/17745020-cf434bc9-bc28-43f0-9519-c6aff0525d2d }
 */

export const updateEducation = async (userId: string, education: IEducation): Promise<EducationResponse> => {
  return Api.put(`${API_ENDPOINT}/platform/users/${userId}/education/${education._id}?ts=${Date.now()}`, education)
}

/**
 * API Call to delete education information
 * @param userId `required` Logged user ID
 * @param educationId `required`education id to be deleted
 * @returns boolean
 * @see [Postman]{@link https://coderplatformers.postman.co/workspace/Coder-House-Platform~9b3cd88b-81e2-4307-8432-95267b456aa7/request/17745020-137fd8d2-27d6-404f-b597-360b7070a475 }
 */

export const deleteEducation = async (userId: string, educationId: string) => {
  return Api.del(`${API_ENDPOINT}/platform/users/${userId}/education/${educationId}`)
}

/**
 * API Call to get user's work experience information
 * @param userId `required` Logged user ID
 * @returns promise containing array of user's work experience information
 * @see [Postman]{@link https://coderplatformers.postman.co/workspace/Coder-House-Platform~9b3cd88b-81e2-4307-8432-95267b456aa7/request/17745020-fbcf0606-c297-4f9e-996b-a7afd5df21ee}
 */

export const getWorkExperience = async (userId: string): Promise<WorkExperienceResponse[]> => {
  return await Api.get(`${API_ENDPOINT}/platform/users/${userId}/works?ts=${Date.now()}`)
}

/**
 * API Call to create work experience information
 * @param userId `required` Logged user ID
 * @param education `required` work experience data to be created
 * @returns Promise containing the created work experience data
 * @see [Postman]{@link https://coderplatformers.postman.co/workspace/Coder-House-Platform~9b3cd88b-81e2-4307-8432-95267b456aa7/request/17745020-7ed0da94-0ece-4f6b-82d3-a5f397ed6a3b }
 */

export const setWorkExperience = async (userId: string, work: IWorkExperience): Promise<WorkExperienceResponse> => {
  return await Api.post(`${API_ENDPOINT}/platform/users/${userId}/works?ts=${Date.now()}`, work)
}

/**
 * API Call to update work experience information
 * @param userId `required` Logged user ID
 * @param education `required` work experience data to be updated
 * @returns Promise containing the created work experience data
 * @see [Postman]{@link https://coderplatformers.postman.co/workspace/Coder-House-Platform~9b3cd88b-81e2-4307-8432-95267b456aa7/request/17745020-c49b3ba1-0ff4-4aab-b51e-1d9d1c8da2c8 }
 */

export const updateWorkExperience = async (userId: string, work: IWorkExperience): Promise<WorkExperienceResponse> => {
  return await Api.put(`${API_ENDPOINT}/platform/users/${userId}/works/${work._id}?ts=${Date.now()}}`, work)
}

/**
 * API Call to delete work experience information
 * @param userId `required` Logged user ID
 * @param workId `required` work id to be deleted
 * @returns boolean
 * @see [Postman]{@link https://coderplatformers.postman.co/workspace/Coder-House-Platform~9b3cd88b-81e2-4307-8432-95267b456aa7/request/17745020-c7f59330-e783-4bb9-8ec2-49b935476c10}
 */

export const deleteWorkExperience = async (userId: string, workId: string) => {
  return await Api.del(`${API_ENDPOINT}/platform/users/${userId}/works/${workId}`)
}

/**
 * API Call to get user's referral coupon
 * @param userId `required` Logged user ID
 * @param ts
 * @return promise containing the user referral code with additional information
 * @see [Postman]{@link https://coderplatformers.postman.co/workspace/Coder-House-Platform~9b3cd88b-81e2-4307-8432-95267b456aa7/request/17745020-631089ae-ba82-4d5c-a0cb-9743e39749ef}
 **/

export const getReferralCoupon = async (userId: string): Promise<ReferralCouponResponse> => {
  const ts = new Date().toISOString().slice(0, 15)
  return await Api.get(`${API_ENDPOINT}/platform/users/${userId}/referral-cupon?ts=${ts}`)
}

/**
 * API Call to shorten url link to share referral coupon
 * @param urlToShorten `required` url to be shortened
 * @return promise containing the shorten url from cuttly
 * @see [Postman]{@link https://coderplatformers.postman.co/workspace/Coder-House-Platform~9b3cd88b-81e2-4307-8432-95267b456aa7/request/17745020-8d0597f8-5dd2-4ce0-a046-6473eb074b26}
 **/

export const getShortenUrl = async (urlToShorten: string): Promise<string> => {
  return await Api.post(`${API_ENDPOINT}/url-shortner`, { url: urlToShorten })
}

/**
 * API Call to check if coupon can be shared (every 5 hours it can be shared)
 * @param userId `required`
 * @param referralCuponId `required` _id of the coupon
 * @param medium `required` could be either linkedin/whatsapp
 * @return {"sucess": boolean, "message"?: string}
 * @see [Postman]{@link https://coderplatformers.postman.co/workspace/Coder-House-Platform~9b3cd88b-81e2-4307-8432-95267b456aa7/request/17745020-0a1e30df-3777-4d67-b511-e1aa6f91df01}
 **/

export const shareCoupon = async (
  userId: string,
  referralCuponId: string,
  shareTo: string
): Promise<{ success: boolean; message?: string }> => {
  return await Api.put(`${API_ENDPOINT}/platform/users/${userId}/referral-cupon/${referralCuponId}/sharing`, { medium: shareTo })
}
/**
 * API Call to get user's billing history
 * @param userId `required` Logged in user ID
 * @returns Promise containing user's billing history
 * @see [Postman]{@link https://coderplatformers.postman.co/workspace/Coder-House-Platform~9b3cd88b-81e2-4307-8432-95267b456aa7/request/17745020-63ad50fb-6132-4900-a88a-c4533174e9d2 }
 */

export const getPayouts = (userId: string): Promise<Payout[]> => {
  return Api.get(`${API_ENDPOINT}/platform/users/${userId}/payouts?ts=${Date.now()}&userId=${userId}`)
}

/**
 * API Call to get certain bill/invoice detailed
 * @param userId `required` Logged in user ID
 * @param payoutId `required` ID from the payout object
 * @param invoiceId `required` invoice ID to request
 * @returns Promise containing a single invoice detailed
 * @see [Postman]{@link https://coderplatformers.postman.co/workspace/Coder-House-Platform~9b3cd88b-81e2-4307-8432-95267b456aa7/request/17745020-042b4d25-65fa-42d8-bf38-da71764d801c}
 */
export const getInvoice = (userId: string, payoutId: string, invoiceId: string): Promise<PayoutInvoice> => {
  return Api.get(
    `${API_ENDPOINT}/platform/users/${userId}/payouts/${payoutId}/invoices/${invoiceId}?invoiceId=${invoiceId}&payoutId=${payoutId}&ts=${Date.now()}&userId=${userId}`
  )
}

/**
 * API call to upload AFIP constancy
 * @param formData `required` files
 * @returns information about the document
 */
export const uploadAFIPConstancy = async (formData: FormData): Promise<FileResponse> => {
  return await Api.post(`${API_ENDPOINT}/platform/upload/files`, formData, null, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

/**
 * API call to update billing information
 * @param userId `required` loged user id
 * @returns billing information
 */
export const updateBillingData = ({ userId, ...profile }: UpdateBillingDataArgs) => {
  return Api.put(`${API_ENDPOINT}/platform/users/${userId}`, { ...profile, _id: userId, flagPayments: 1 })
}

/**
 * API call to get billing information
 * @param userId `required` loged user id
 * @returns billing information
 */
export const getInvoiceInfo = (userId: string): Promise<Banking> => {
  return Api.get(`${API_ENDPOINT}/platform/users/${userId}/profile-invoice-info?_id=${userId}&ts=${Date.now()}&userId=${userId}`)
}

/**
 * API call to get all payments for some user
 * @param userId `required` loged user id
 * @returns array of payments
 */
export const getPayments = (userId: string): Promise<Payment[]> => {
  return Api.get(`${API_ENDPOINT}/platform/users/${userId}/payouts?ts=${Date.now()}&userId=${userId}`)
}

/**
 * API call to upload some invoice
 * @param formData `required` files
 * @returns information about the document
 */
export const uploadInvoice = async (formData: FormData): Promise<FileResponse> => {
  return await Api.post(`${API_ENDPOINT}/platform/upload/files`, formData, null, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

/**
 * API call to get upload bill
 * @param userId `required` loged user id
 * @param payoutId `required`
 * @param invoiceId `required`
 * @param invoice `required`
 * @returns
 */
export const uploadBill = ({
  userId,
  payoutId,
  invoiceId,
  invoice
}: {
  userId: string
  payoutId: string
  invoiceId: string
  invoice: Invoice
}) => {
  const payload = { userId, payoutId, invoiceId, invoice }
  return Api.put(`${API_ENDPOINT}/platform/users/${userId}/payouts/${payoutId}/invoices/${invoiceId}`, payload)
}

/**
 * API call to upload profile picture
 * @param formData `required` image
 * @returns url of the image
 */
export const uploadProfileImage = (formData: FormData): Promise<{ url: string }> => {
  return Api.post(`${API_ENDPOINT}/platform/upload/images`, formData, null, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

export const updateUserAvatar = (userId: string, profile: { avatar: string }): Promise<User> => {
  return Api.put(`${API_ENDPOINT}/platform/users/${userId}`, { _id: userId, profile })
}

export const updateTalent = async (): Promise<Talent> => {
  return Api.post(`${ACADEMIC_API_ENDPOINT}/talent/publish`, {})
}

export const getUserAvailableBalance = async (userId: string): Promise<UserBalance[]> => {
  return Api.get(`${API_ENDPOINT}/platform/users/${userId}/profile-available-balance`)
}

export const transferUserBalance = async (userId: string, dstUserEmail: string, balanceId: string): Promise<void> => {
  return Api.post(
    `${API_ENDPOINT}/platform/users/${userId}/available-balance/transfer`,
    {
      userEmail: dstUserEmail,
      balanceId
    }
  )
}

export const getSocialAccounts: () => Promise<any> = async () => {
  return Api.get(`${VUE_APP_CH_API_BFF}/social-accounts`)
}

export const removeSocialAccount = async (email: string, provider: string): Promise<any> => {
  return Api.put(`${VUE_APP_CH_API_BFF}/remove-social-account`, { email, provider })
}
