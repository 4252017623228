import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-d-flex p-ai-center p-ml-5" }
const _hoisted_2 = { class: "p-mb-3" }
const _hoisted_3 = { class: "p-d-flex p-ai-center p-ml-5" }
const _hoisted_4 = { class: "p-d-flex p-ai-center p-ml-5" }
const _hoisted_5 = { class: "p-d-flex p-ai-center p-ml-5" }
const _hoisted_6 = { class: "p-d-flex p-ai-center p-ml-5" }
const _hoisted_7 = { class: "p-d-flex p-ai-center p-ml-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_skeleton_icon = _resolveComponent("skeleton-icon")!
  const _component_skeleton_text = _resolveComponent("skeleton-text")!
  const _component_skeleton_box = _resolveComponent("skeleton-box")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_skeleton_icon, { class: "p-mr-4" }),
      _createVNode(_component_skeleton_text, { maxWords: 3 })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_skeleton_box, { height: '170px' })
    ]),
    _createVNode(_component_skeleton_box, { height: '100px' }),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_skeleton_icon, { class: "p-mr-4" }),
      _createVNode(_component_skeleton_text, { maxWords: 3 })
    ]),
    _createVNode(_component_skeleton_box, { height: '100px' }),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_skeleton_icon, { class: "p-mr-4" }),
      _createVNode(_component_skeleton_text, { maxWords: 3 })
    ]),
    _createVNode(_component_skeleton_box, { height: '100px' }),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_skeleton_icon, { class: "p-mr-4" }),
      _createVNode(_component_skeleton_text, { maxWords: 3 })
    ]),
    _createVNode(_component_skeleton_box, { height: '100px' }),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_skeleton_icon, { class: "p-mr-4" }),
      _createVNode(_component_skeleton_text, { maxWords: 3 })
    ]),
    _createVNode(_component_skeleton_box, { height: '100px' }),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_skeleton_icon, { class: "p-mr-4" }),
      _createVNode(_component_skeleton_text, { maxWords: 3 })
    ])
  ], 64))
}