// APIs services
import {
  commentPost,
  getPosts,
  getRatingAndNextClass as getRating,
  likePost,
  upsertCommentLike,
  getCourseCertificate,
  getLeaderboard,
  getPerk as getPerkFromAPI,
  savePost,
  deletePost,
  wasStudentOnboardingCompleted,
  wasTeacherOnboardingCompleted,
  setStudentOnboardingCompleted,
  setTeacherOnboardingCompleted,
  getSavedPosts,
  getMyPosts
} from '@/api/newPlatform/dashboard.api'

// Models
import community from '@/mocks/data/dashboard/community.json'
import { IGetPostsResponse, INewComment, IPost } from '@/models/dashboard/community'
import { GetProfileInfoArgs } from '@/models/profile/profile'

/**
 * Service to retrieve the posts
 * @param userId
 * @param role logged in user role
 * @param skip number of posts to retrieve
 * @param returnMock return mock data or not
 * @param category category filter to be applied to posts
 * @returns posts and pinned posts
 */
export const getAllPosts = async ({
  returnMock,
  role,
  ...params
}: {
  userId: string
  role: number
  skip: number
  searchByTitle?: string
  category?: string
  returnMock?: boolean
}): Promise<IGetPostsResponse | typeof community> => {
  if (returnMock) {
    return {
      ...community
    }
  }

  const isTeacher = +role !== 2
  const { posts, pinnedPosts } = await getPosts({ ...params, isTeacher })

  return { posts, pinnedPosts }
}

// TODO: Type response attached to API request
/**
 * Service to comment a blog post
 * @param param Params used to create a new comment into an existent blog post.
 * Pass `returnMock = true` if you want to return mock data
 */
export const commentPostService: (data: INewComment) => Promise<void> = async ({ blogId, userId, comment, returnMock = false }) => {
  if (returnMock) {
    return
  }

  await commentPost({ blogId, userId, comment })
}

/**
 * Service for API call to like an already existing post
 * @param postId ID of the post that is set to be liked
 * @param userId Logged-in User ID
 */
export const likePostService: (postId: IPost['_id'], userId: string) => Promise<void> = async (postId, userId) => {
  await likePost(postId, userId)
}

export const getRatingAndNextClass = async (userId: string, camada: string) => {
  const response = await getRating(userId, camada)

  return response
}

export const getCertificate = async (userId: string, camada: string) => {
  const response = await getCourseCertificate(userId, camada)
  return response
}

export const getUserRanking = async (userId: string, courseId: string) => {
  const response = await getLeaderboard(userId, courseId)

  return response
}

/**
 * API Service to like a post comment
 * @param commentId comment to be liked
 * @param blogId blog id of the comment set to be liked
 * @param userId userId from the currently logged session
 */
export const likeCommentService = async (commentId: string, blogId: string, userId: string) => {
  await upsertCommentLike(commentId, blogId, userId)
}

/**
 * Deletes a post from the feed given a specific `Post ID`
 * @param postId Post that is set to be removed from feed
 */
export const deletePostService: (postId: IPost['_id']) => Promise<void> = async (postId) => {
  await deletePost(postId)
}

export const getPerk = async (userId: string, courseId: string) => {
  const response = await getPerkFromAPI(userId, courseId)

  return response
}

/**
 * API Service check if the onboarding is completed
 * @param userId Logged user id
 * @param blogId to save/unsave
 * @returns success boolean
 */
export const savePostService = async (userId: string, blogId: string): Promise<boolean> => {
  const response = await savePost(userId, blogId)

  return response
}

/**
 * API Service check if the onboarding is completed
 * @param userId userId from the currently logged session
 * @param role user role from the currently logged session
 * @returns boolean
 */

export const checkOnboardingCompleted = async (userId: string, role: number) => {
  if (role === 2) {
    const response = wasStudentOnboardingCompleted(userId)
    return response
  } else {
    const response = wasTeacherOnboardingCompleted(userId)
    return response
  }
}

/**
 * API Service to update the onboarding to completed
 * @param userId userId from the currently logged session
 * @param role user role from the currently logged session
 * @returns boolean
 */

export const setOnboardingCompleted = async (userId: string, role: number) => {
  if (role === 2) {
    const response = setStudentOnboardingCompleted(userId)
    return response
  } else {
    const response = setTeacherOnboardingCompleted(userId)
    return response
  }
}

/**
 * Service for saved posts API Call
 * @param param.userId `required` Gets saved posts from the currently logged in user
 * @returns saved posts
 */
export const getSavedPostsService: (args: GetProfileInfoArgs) => Promise<IGetPostsResponse> = async ({ userId }) => {
  return await getSavedPosts({ userId })
}

/**
 * Service for fetching posts that were created by the logged in user
 * @param param.userId `required` currentlu logged in user id
 * @returns posts created by the logged in user
 */
export const getMyPostsService: (args: GetProfileInfoArgs) => Promise<IGetPostsResponse> = async ({ userId }) => {
  return await getMyPosts({ userId })
}
