<template>
  <path
    d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path d="M8 5V8.5" stroke-linecap="round" stroke-linejoin="round" />
  <path
    d="M8 11.25C8.27614 11.25 8.5 11.0261 8.5 10.75C8.5 10.4739 8.27614 10.25 8 10.25C7.72386 10.25 7.5 10.4739 7.5 10.75C7.5 11.0261 7.72386 11.25 8 11.25Z"
  />
</template>
