<template>
  <g>
    <path 
      d="M12 7.5V12" 
      stroke-linecap="round" 
      stroke-linejoin="round"
    />
    <path 
      d="M15.8971 14.25L12 12" 
      stroke-linecap="round" 
      stroke-linejoin="round"
    />
    <path 
      d="M6.73438 9.34814H2.98438V5.59814" 
      stroke-linecap="round" 
      stroke-linejoin="round"
    />
    <path 
      d="M6.16635 17.8336C7.32014 18.9874 8.79015 19.7732 10.3905 20.0915C11.9908 20.4098 13.6496 20.2464 15.1571 19.622C16.6646 18.9976 17.9531 17.9402 18.8596 16.5835C19.7661 15.2268 20.25 13.6317 20.25 12C20.25 10.3683 19.7661 8.77325 18.8596 7.41655C17.9531 6.05984 16.6646 5.00242 15.1571 4.378C13.6496 3.75357 11.9908 3.5902 10.3905 3.90853C8.79015 4.22685 7.32014 5.01259 6.16635 6.16637L2.98438 9.34835" 
      stroke-linecap="round" 
      stroke-linejoin="round"
    />
  </g>
</template>
