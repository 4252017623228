import { defineStore } from 'pinia'
import { Locale } from '@/models/locale/locale'
import { getLocale } from '@/api/locale.api'
import { Language } from '@/utils/locale'
import { usePersonStore } from './person.store'
import { useI18nStore } from './i18n.store'

export const useLocaleStore = defineStore({
  id: 'locale',
  state: () => ({
    locale: null as Locale | null,
    isLoading: false
  }),
	getters: {
		getLocale (): Locale | null  {
			return this.locale
		}
	},
	actions: {
		async fetchLocale () {
      this.isLoading = true
      const personStore = usePersonStore()
      const person = personStore.person
      if(person) {
        const lang = Language.get(person.country) as string
        this.locale = await getLocale(lang)
        useI18nStore().setLocale(this.locale.locale)
        useI18nStore().setLocaleMessages(this.locale.locale, this.locale.translations)
        this.isLoading = false
      }
    }
	}
})