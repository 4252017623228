import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-644bfe28"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-my-3" }
const _hoisted_2 = {
  key: 0,
  class: "error-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChallengePreview = _resolveComponent("ChallengePreview")!
  const _component_ChallengeEvaluation = _resolveComponent("ChallengeEvaluation")!
  const _component_InputEditor = _resolveComponent("InputEditor")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ChallengePreview, {
      comment: _ctx.correction.comment,
      challenge: _ctx.correction.homework
    }, null, 8, ["comment", "challenge"]),
    _createVNode(_component_ChallengeEvaluation, {
      "evaluation-criteria": _ctx.evaluationCriteria,
      evaluation: _ctx.state.evaluation,
      "onUpdate:evaluation": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.evaluation) = $event))
    }, null, 8, ["evaluation-criteria", "evaluation"]),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.evaluationError)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.t('homework.challenge.peerReview.correction.evaluationError')), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("h6", null, _toDisplayString(_ctx.t('homework.challenge.peerReview.correction.finalComment')), 1),
    _createVNode(_component_InputEditor, {
      label: _ctx.t('homework.challenge.upload.comment.label'),
      modelValue: _ctx.state.comment,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.comment) = $event)),
      error: _ctx.v$.comment.$invalid && _ctx.sent,
      errorMessage: _ctx.v$.comment.$errors[0] && _ctx.t(`form.validation.${_ctx.v$.comment.$errors[0].$validator}`)
    }, null, 8, ["label", "modelValue", "error", "errorMessage"]),
    _createVNode(_component_Button, {
      disabled: _ctx.isEnabled,
      loading: _ctx.sending,
      label: _ctx.t('homework.challenge.peerReview.correction.sendAndContinue'),
      onClick: _ctx.send,
      class: "p-button-lg send p-button-custom-primary"
    }, null, 8, ["disabled", "loading", "label", "onClick"])
  ]))
}