<template>
  <div class="timeline__container">
    <slot />
  </div>
</template>

<style lang="scss">
.timeline__container {
  background-image: linear-gradient(
    to right,
    transparent 0px,
    transparent 42px,
    var(--gray-1) 44px,
    var(--gray-1) 45px,
    transparent 38px,
    transparent 100%
  );
}
.timeline__step {
  margin-bottom: var(--space-lg);

  .timeline__step:last-of-type {
    margin-bottom: 0;
  }

  /* &:last-of-type {
    margin-bottom: 0;
  } */
}
</style>
