
import { defineComponent } from 'vue'

// Components
import InputError from './InputError.vue'

export default defineComponent({
  props: {
    inputId: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    hint: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: ''
    }
  },
  components: { InputError }
})
