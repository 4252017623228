import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "select" }
const _hoisted_2 = ["name", "disabled"]
const _hoisted_3 = {
  key: 0,
  disabled: "",
  value: ""
}
const _hoisted_4 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chevron_down = _resolveComponent("chevron-down")!
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_icon, { strokeColor: "var(--light-gray)" }, {
      default: _withCtx(() => [
        _createVNode(_component_chevron_down)
      ]),
      _: 1
    }),
    _withDirectives(_createElementVNode("select", {
      name: _ctx.name,
      id: "name",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedOption) = $event)),
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleSelectedValue && _ctx.handleSelectedValue(...args))),
      disabled: _ctx.disabled
    }, [
      (_ctx.placeholder)
        ? (_openBlock(), _createElementBlock("option", _hoisted_3, _toDisplayString(_ctx.placeholder), 1))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createElementBlock("option", {
          key: option.value,
          value: option.value
        }, _toDisplayString(option.label), 9, _hoisted_4))
      }), 128))
    ], 40, _hoisted_2), [
      [_vModelSelect, _ctx.selectedOption]
    ])
  ]))
}