import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-72a6f519"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "t2 p-mt-3 p-ml-2" }
const _hoisted_2 = { class: "t2 text-white" }
const _hoisted_3 = {
  key: 1,
  class: "form__container"
}
const _hoisted_4 = { class: "form__wrapper" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "p-mb-2 t2 medium" }
const _hoisted_7 = { class: "p-d-block text-white p1" }
const _hoisted_8 = { class: "p-mt-4 p2" }
const _hoisted_9 = {
  href: "https://www.coderhouse.com/legales",
  class: "p-button tertiary p2",
  target: "_blank",
  rel: "noopener noreferrer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_toast = _resolveComponent("toast")!
  const _component_ErrorPage = _resolveComponent("ErrorPage")!
  const _component_breadcrumb = _resolveComponent("breadcrumb")!
  const _component_SkeletonCourseInfoCard = _resolveComponent("SkeletonCourseInfoCard")!
  const _component_SkeletonCardAlert = _resolveComponent("SkeletonCardAlert")!
  const _component_CourseInformation = _resolveComponent("CourseInformation")!
  const _component_SmallCardAlert = _resolveComponent("SmallCardAlert")!
  const _component_SkeletonTransferCourse = _resolveComponent("SkeletonTransferCourse")!
  const _component_placeholder_state = _resolveComponent("placeholder-state")!
  const _component_SkeletonTableFilter = _resolveComponent("SkeletonTableFilter")!
  const _component_courses_table = _resolveComponent("courses-table")!
  const _component_Form = _resolveComponent("Form")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_two_cols_layout = _resolveComponent("two-cols-layout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_toast),
    (_ctx.courseError)
      ? (_openBlock(), _createBlock(_component_ErrorPage, {
          key: 0,
          copies: _ctx.errorPageCopies
        }, null, 8, ["copies"]))
      : (_openBlock(), _createBlock(_component_two_cols_layout, { key: 1 }, {
          aside: _withCtx(() => [
            _createVNode(_component_breadcrumb, {
              class: "p-mt-3 p-ml-2",
              home: { label: `${_ctx.transferCopies.breadcrum.home}`, to: '/profile' },
              model: [
          {
            label: `${_ctx.transferCopies.breadcrum.model}`,
            to: `/profile/transfer-course/${_ctx.camadaNro}`
          }
        ]
            }, null, 8, ["home", "model"]),
            _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.transferCopies.titleAside), 1),
            (_ctx.isLoading)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_SkeletonCourseInfoCard),
                  _createVNode(_component_SkeletonCardAlert)
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createVNode(_component_CourseInformation, {
                    class: "p-mt-2 p-ml-2",
                    courseName: _ctx.currentCourse.courseName,
                    imgCategory: _ctx.currentCourse.category,
                    camadaNro: _ctx.camadaNro,
                    courseDaysAndHours: _ctx.currentCourse.daysAndHours,
                    courseStartDate: _ctx.currentCourse.startDate,
                    courseEndDate: _ctx.currentCourse.endDate
                  }, null, 8, ["courseName", "imgCategory", "camadaNro", "courseDaysAndHours", "courseStartDate", "courseEndDate"]),
                  (_ctx.displayAlertCard)
                    ? (_openBlock(), _createBlock(_component_SmallCardAlert, {
                        key: 0,
                        class: "p-mt-2 p-ml-2",
                        title: _ctx.penaltyCardTitle,
                        description: _ctx.studentChangeCourseCopies.alert.description,
                        anchor: { link: _ctx.studentChangeCourseCopies.alert.anchor.link, text: _ctx.studentChangeCourseCopies.alert.anchor.text }
                      }, null, 8, ["title", "description", "anchor"]))
                    : _createCommentVNode("", true)
                ], 64))
          ]),
          content: _withCtx(() => [
            _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.transferCopies.titleContent), 1),
            (!_ctx.reenrollStatus.alreadyRequested && _ctx.isLoading)
              ? (_openBlock(), _createBlock(_component_SkeletonTransferCourse, { key: 0 }))
              : (!_ctx.reenrollStatus.alreadyRequested && !_ctx.isLoading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_Form, {
                        questions: _ctx.questions,
                        rules: _ctx.toUserRules,
                        onOnChange: _ctx.handleChange,
                        onOnSelect: _ctx.handleSelect,
                        onOnCancel: _ctx.handleCancel,
                        onOnSubmit: _ctx.handleSubmit,
                        buttonLabel: _ctx.buttonLabels,
                        allowedToEditForm: !_ctx.disableSubmit && !!_ctx.comissionSelected
                      }, {
                        default: _withCtx(() => [
                          (_ctx.disableSubmit)
                            ? (_openBlock(), _createBlock(_component_placeholder_state, {
                                key: 0,
                                isDisabled: "",
                                role: 2,
                                customCopies: {
                line1: _ctx.transferCopies.placeholderEmpty
              },
                                style: {"grid-column":"1 / -1","margin-bottom":"2rem"}
                              }, null, 8, ["customCopies"]))
                            : _createCommentVNode("", true),
                          (_ctx.isLoadingCourses)
                            ? (_openBlock(), _createBlock(_component_SkeletonTableFilter, {
                                key: 1,
                                style: {"grid-column":"1 / -1","margin-bottom":"2rem"}
                              }))
                            : _createCommentVNode("", true),
                          (!_ctx.isLoadingCourses && !_ctx.disableSubmit && _ctx.coursesData.length)
                            ? (_openBlock(), _createBlock(_component_courses_table, {
                                key: 2,
                                coursesData: _ctx.coursesData,
                                leadTeacherId: _ctx.currentCourse.leadTeacher,
                                onOnSelectComission: _ctx.handleChooseComission
                              }, null, 8, ["coursesData", "leadTeacherId", "onOnSelectComission"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["questions", "rules", "onOnChange", "onOnSelect", "onOnCancel", "onOnSubmit", "buttonLabel", "allowedToEditForm"])
                    ])
                  ]))
                : _createCommentVNode("", true),
            _createVNode(_component_Dialog, {
              visible: _ctx.displayDialog,
              "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.displayDialog) = $event)),
              modal: true,
              onHide: _ctx.handleCancel
            }, {
              header: _withCtx(() => [
                (_ctx.currentCourse.courseName)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.currentCourse.courseName), 1),
                      _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.transferCopies.dialog.subtitle), 1)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              footer: _withCtx(() => [
                _createElementVNode("a", _hoisted_9, _toDisplayString(_ctx.transferCopies.dialog.tAndc), 1),
                _createVNode(_component_Button, {
                  label: _ctx.transferCopies.dialog.discard,
                  class: "secondary",
                  onClick: _ctx.handleDiscardRequest
                }, null, 8, ["label", "onClick"])
              ]),
              default: _withCtx(() => [
                _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.transferCopies.dialog.message), 1)
              ]),
              _: 1
            }, 8, ["visible", "onHide"])
          ]),
          _: 1
        }))
  ], 64))
}