<template>
  <path
    d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M6.65625 19.2469C7.99239 17.0357 9.87666 15.2068 12.1267 13.9373C14.3768 12.6677 16.9165 12.0004 19.5 12C20.0063 12 20.5031 12.0281 21 12.075"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M17.6251 4.97815C16.2194 6.70448 14.4467 8.09582 12.4358 9.051C10.4248 10.0062 8.22633 10.5012 6.0001 10.5C5.05343 10.499 4.10885 10.4111 3.17822 10.2375"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M8.05322 3.90942C10.8437 5.55438 13.0288 8.05592 14.2838 11.0422C15.5388 14.0284 15.7966 17.3399 15.0188 20.4844"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</template>
