import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9304979c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form__wrapper p-mt-2" }
const _hoisted_2 = {
  key: 0,
  class: "text-light-gray p-mb-4 p-mt-1 t3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Toast = _resolveComponent("Toast")!
  const _component_PlaceholderState = _resolveComponent("PlaceholderState")!
  const _component_SkeletonTableFilter = _resolveComponent("SkeletonTableFilter")!
  const _component_CoursesTable = _resolveComponent("CoursesTable")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Toast),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Form, {
        questions: _ctx.questions,
        onOnChange: _ctx.handleChange,
        onOnCancel: _ctx.handleCancel,
        onOnSubmit: _ctx.handleSendRequest,
        buttonLabel: _ctx.buttonLabels,
        classStyles: false,
        allowedToEditForm: !_ctx.disableSubmit && !!_ctx.comissionSelected
      }, {
        default: _withCtx(() => [
          (!_ctx.isLoadingCourses && !_ctx.disableSubmit)
            ? (_openBlock(), _createElementBlock("h2", _hoisted_2, _toDisplayString(_ctx.studentChangeCopies.course.chooseYourComision), 1))
            : _createCommentVNode("", true),
          (_ctx.disableSubmit)
            ? (_openBlock(), _createBlock(_component_PlaceholderState, {
                key: 1,
                role: 2,
                isDisabled: "",
                customCopies: {
          line1: _ctx.transferCopies.placeholderEmpty
        },
                style: {"grid-column":"1 / -1","margin-bottom":"2rem"}
              }, null, 8, ["customCopies"]))
            : _createCommentVNode("", true),
          (_ctx.isLoadingCourses)
            ? (_openBlock(), _createBlock(_component_SkeletonTableFilter, {
                key: 2,
                style: {"grid-column":"1 / -1","margin-bottom":"2rem"}
              }))
            : _createCommentVNode("", true),
          (!_ctx.isLoadingCourses && !_ctx.disableSubmit && _ctx.coursesData.length)
            ? (_openBlock(), _createBlock(_component_CoursesTable, {
                key: 3,
                coursesData: _ctx.coursesData,
                leadTeacherId: _ctx.leadTeacherId,
                onOnSelectComission: _ctx.handleChooseComission
              }, null, 8, ["coursesData", "leadTeacherId", "onOnSelectComission"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["questions", "onOnChange", "onOnCancel", "onOnSubmit", "buttonLabel", "allowedToEditForm"])
    ])
  ], 64))
}