<template>
  <div class="skeleton--input skeleton">
    <span />
  </div>
</template>

<style lang="scss" scoped>
.skeleton--input {
  border-radius: var(--border-radius);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--space-sm);
  > span {
    height: var(--space-sm);
    display: block;
    width: 50%;
    min-width: 10ch;
    border-radius: var(--border-radius);
    background-color: var(--gray);
  }
}
</style>
