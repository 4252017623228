import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChallengeStudent = _resolveComponent("ChallengeStudent")!
  const _component_ChallengePreview = _resolveComponent("ChallengePreview")!
  const _component_ChallengeEvaluation = _resolveComponent("ChallengeEvaluation")!
  const _component_InputEditor = _resolveComponent("InputEditor")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ChallengeStudent, { student: _ctx.student }, null, 8, ["student"]),
    _createVNode(_component_ChallengePreview, {
      comment: _ctx.correction.comment,
      challenge: _ctx.correction.homework
    }, null, 8, ["comment", "challenge"]),
    _createVNode(_component_ChallengeEvaluation, {
      "is-tutor": "",
      "evaluation-criteria": _ctx.evaluationCriteria,
      evaluation: _ctx.state.evaluation,
      "onUpdate:evaluation": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.evaluation) = $event)),
      "students-evaluations": _ctx.evaluations,
      usePeerReview: _ctx.usePeerReview
    }, null, 8, ["evaluation-criteria", "evaluation", "students-evaluations", "usePeerReview"]),
    _createVNode(_component_InputEditor, {
      label: _ctx.t('homework.challenge.upload.comment.label'),
      modelValue: _ctx.state.comment,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.comment) = $event)),
      onComment: _ctx.getComment,
      error: _ctx.v$.comment.$invalid && _ctx.sent,
      errorMessage: _ctx.v$.comment.$errors[0] && _ctx.t(`form.validation.${_ctx.v$.comment.$errors[0].$validator}`)
    }, null, 8, ["label", "modelValue", "onComment", "error", "errorMessage"]),
    _createVNode(_component_Button, {
      loading: _ctx.sending && _ctx.sendApprove,
      disabled: _ctx.sending || _ctx.disabledApprove,
      label: 
        _ctx.type === 'regular' || _ctx.type === 'complementary'
          ? _ctx.t('homework.challenge.peerReview.correction.tutor.approve')
          : _ctx.t('homework.challenge.peerReview.correction.tutor.qualify')
      ,
      onClick: _cache[2] || (_cache[2] = () => _ctx.send(true)),
      class: "p-button-lg send p-button-custom-primary"
    }, null, 8, ["loading", "disabled", "label"]),
    (!_ctx.listTypes[_ctx.type])
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 0,
          loading: _ctx.sending && _ctx.sendRequest,
          disabled: _ctx.sending || _ctx.disabledApprove,
          label: 
        _ctx.type === 'complementary' ?
          _ctx.t('homework.challenge.complementary.correction.tutor.giveFeedback')
          :_ctx.t('homework.challenge.peerReview.correction.tutor.requestOtherDelivery'),
          onClick: _cache[3] || (_cache[3] = () => _ctx.send(false)),
          class: "p-button-lg send p-button-custom-secondary p-mr-2"
        }, null, 8, ["loading", "disabled", "label"]))
      : _createCommentVNode("", true),
    (_ctx.listTypes[_ctx.type])
      ? (_openBlock(), _createBlock(_component_Dropdown, {
          key: 1,
          modelValue: _ctx.state.grade,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.state.grade) = $event)),
          options: _ctx.listTypes[_ctx.type],
          optionLabel: "label",
          placeholder: _ctx.t('homework.challenge.peerReview.correction.note'),
          class: "p-mr-2 custom-select",
          optionValue: "value"
        }, null, 8, ["modelValue", "options", "placeholder"]))
      : _createCommentVNode("", true)
  ]))
}