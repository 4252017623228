
// Vendor
import { defineComponent, reactive, ref } from 'vue'

// Vuelidate
import useVuelidate from '@vuelidate/core'
import { url } from '@vuelidate/validators'

// Components
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import InputFile from '@/components/aero/inputs/InputFile.vue'
import InputWrapper from '@/components/aero/inputs/InputWrapper.vue'
import RichTextEditor from '@/components/aero/inputs/RichTextEditor.vue'

// Api & service
import { postDeliverFile } from '@/api/newPlatform/challenges.api'
import { onSubmitDeliver } from '@/services/challenges.services'

// Store
import { useCourseStore } from '@/store/modules/course.store'
import { useUserStore } from '@/store/modules/user.store'

// Locales
import copies from '@/locales/challenges/es.json'

// Models
import { IProjectDeliver } from '@/models/newPlatform/challenges/deliver'

export default defineComponent({
  components: { Button, InputFile, InputText, InputWrapper, RichTextEditor },
  props: {
    moduleId: {
      type: String,
      required: true
    },
    moduleName: {
      type: String,
      required: true
    },
    stageId: {
      type: String,
      required: true
    },
    classId: {
      type: String,
      required: true
    },
    projectId: {
      type: String,
      default: ''
    },
    DOMIdOverlay: { type: String, required: false }
  },
  emits: ['onDelivered'],
  setup(props, { emit }) {
    const { id: userId, email } = useUserStore()
    const { id: courseId, camadaNro } = useCourseStore()

    const formComment = ref('')
    const form = reactive({
      file: null as File | null,
      link: null as string | null,
      comment: '' as string
    })
    const isDelivering = ref<boolean>(false)

    const handleChange = (e: File) => (form.file = e)

    /**
     * Handle text inside text editor
     * @param text Text value inside text editor component
     */
    const handleTextEditor: (text: string) => void = (text: string) => {
      formComment.value = text
    }

    const rules = {
      link: { url }
    }

    const validations = useVuelidate(rules, form)

    const handlePostDeliver = async () => {
      try {
        isDelivering.value = true

        const project: IProjectDeliver = {
          userId,
          classId: props.classId,
          stage: props.stageId,
          module: props.moduleId,
          link: form.link || ''
        }

        if (form.file) {
          const prefix = [camadaNro, props.moduleName, email.split('@')[0]].join('-')
          const res = await postDeliverFile(userId, form.file, prefix)
          project.link = res.files && res.files[0] && res.files[0].url

          if (props.projectId) project._id = props.projectId

          const { _id } = await onSubmitDeliver(project, courseId, formComment.value, true)
          emit('onDelivered', _id)
        } else {
          if (!form.link) return
          if (props.projectId) project._id = props.projectId
          const { _id } = await onSubmitDeliver(project, courseId, formComment.value)

          emit('onDelivered', _id)
        }

        form.link = null
        form.file = null
        form.comment = ''
        formComment.value = ''
      } catch (e) {
        emit('onDelivered', null)
      } finally {
        isDelivering.value = false
      }
    }

    return {
      form,
      handleChange,
      handlePostDeliver,
      handleTextEditor,
      isDelivering,
      formComment,
      copies,
      validations
    }
  }
})
