export const Language = new Map<string, string> ([
  ['es', 'es-ES'],
  ['ar', 'es-AR'],
  ['mx', 'es-MX'],
  ['cl', 'es-CL'],
  ['co', 'es-CO'],
  ['br', 'pt-BR'],
  ['pe', 'es-PE'],
  ['us', 'es-US']
])

export const dayNames = new Map<string, string[]> ([
  ['es', ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']],
  ['pt', ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sabado']]
])

export const dayNamesShort = new Map<string, string[]> ([
  ['es', ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sab']],
  ['pt', ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']]
])

export const dayNamesMin = new Map<string, string[]> ([
  ['es', ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa']],
  ['pt', ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa']]
])

export const monthNames = new Map<string, string[]> ([
  ['es', ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']],
  ['pt', ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']]
])

export const monthNamesShort = new Map<string, string[]> ([
  ['es', ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']],
  ['pt', ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']]
])
