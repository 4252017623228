import { removeEntriesWithUndefinedValue } from '@/utils/objects'
import axios, { AxiosError, AxiosRequestConfig } from 'axios'

export class Api {
  static async request<T> (config: AxiosRequestConfig): Promise<T> {
    try {
      const { data } = await axios(config)
      return data
    } catch (error) {
      if ((error as AxiosError).isAxiosError) {
        const axiosErr = error as AxiosError
        const errorObject = {
          error: axiosErr.response?.data.error,
          code: axiosErr.code || axiosErr.response?.data.statusCode,
          message: axiosErr.response?.data.message || axiosErr.response?.statusText,
          config: {
            baseUrl: axiosErr.config.baseURL,
            headers: axiosErr.config.headers,
            url: axiosErr.config.url
          }
        }
        throw errorObject
      } else {
        throw error
      }
    }
  }

  static async get<T, K = Record<string, string | number | boolean | Array<unknown>>, C = AxiosRequestConfig> (
    url: string,
    params?: K,
    config?: C | AxiosRequestConfig
  ): Promise<T> {
    return this.request<T>({
      method: 'GET',
      url,
      params: removeEntriesWithUndefinedValue<K>(params),
      ...config
    })
  }

  static async del<T> (
    url: string,
    params?: Record<string, string | number | boolean | Array<unknown>>,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return this.request<T>({
      method: 'DELETE',
      url,
      params,
      ...config
    })
  }

  static async post<
    T,
    K = Record<string, string | number | boolean | Array<unknown>>,
    R = Record<string, string | number | boolean | Array<unknown>>,
    C = AxiosRequestConfig
  > (
    url: string,
    body: K,
    params?: R | Record<string, string | number | boolean | Array<unknown>>,
    config?: C | AxiosRequestConfig
  ): Promise<T> {
    return this.request<T>({
      method: 'POST',
      url,
      params,
      data: body,
      ...config
    })
  }

  static async patch<T, K> (
    url: string,
    body: K,
    params?: Record<string, string | number | boolean | Array<unknown>>,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return this.request<T>({
      method: 'PATCH',
      url,
      params,
      data: body,
      ...config
    })
  }

  static async put<
    T,
    K = Record<string, string | number | boolean | Array<unknown>>,
    R = Record<string, string | number | boolean | Array<unknown>>,
    C = Record<string, string | number | boolean | Array<unknown>>,
  > (
    url: string,
    body: K,
    params?: R | Record<string, string | number | boolean | Array<unknown>>,
    config?: C | AxiosRequestConfig
  ): Promise<T> {
    return this.request<T>({
      method: 'PUT',
      url,
      params,
      data: body,
      ...config
    })
  }
}
