
import { defineComponent, PropType } from 'vue'

// Copies
import { offers } from '@/locales/dashboard/es.json'

// Components
import Avatar from '@/components/aero/dataDisplay/Avatar.vue'

// Models
import { IStudent } from '@/models/newPlatform/challenges/student'

export default defineComponent({
  components: { Avatar },
  props: {
    relatedStudents: { type: Array as PropType<IStudent[]> },
    offer: { type: String, required: true },
    course: { type: String, required: true },
    link: { type: String, default: 'https://www.coderhouse.com' }
  },
  setup() {
    return { offers }
  }
})
