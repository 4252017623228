
import { defineComponent, reactive, ref, watch, onMounted } from 'vue'

// services
import { checkOnboardingCompleted, setOnboardingCompleted } from '@/services/newPlatform/dashboard.services'

// copies
import copies from '@/locales/dashboard/es.json'

export default defineComponent({
  props: {
    role: { type: Number, required: true },
    isTutor: { type: Boolean, required: true },
    userId: { type: String, required: true }
  },

  setup(props) {
    const onboardingComplete = ref<boolean>(false)
    const roleName = props.role === 2 ? 'student' : props.role === 3 && !props.isTutor ? 'teacher' : 'tutor'

    // onboarding handlers
    const handleNextStep = () => {
      onboardingDialog.step++
    }

    const handlePreviousStep = () => {
      onboardingDialog.step--
    }

    const handleCloseDialog = () => {
      onboardingDialog.display = false
    }
    const windowWidth = ref(0)
    const dialogWidth = ref('40%')
    const dialogHeight = ref('5rem')
    // onboarding
    const onboardingDialog = reactive({
      display: false,
      step: 0,
      title: '',
      message: '',
      ctaPrimaryLabel: copies.onboarding.ctaLabel.begin,
      ctaSecondaryLabel: copies.onboarding.ctaLabel.discard
    })

    watch(onboardingDialog, () => {
      onboardingDialog.title = copies.onboarding[roleName].steps[onboardingDialog.step].title
      onboardingDialog.message = copies.onboarding[roleName].steps[onboardingDialog.step].message
      onboardingDialog.ctaPrimaryLabel =
        onboardingDialog.step < 4
          ? copies.onboarding.ctaLabel.next
          : props.role === 2
          ? copies.onboarding.ctaLabel.finish
          : copies.onboarding.ctaLabel.begin
      onboardingDialog.ctaSecondaryLabel =
        onboardingDialog.step === 1 ? copies.onboarding.ctaLabel.downloadZoom : copies.onboarding.ctaLabel.previous
    })

    const setDialogWidth = () => {
      windowWidth.value = window.innerWidth
      if (windowWidth.value < 768) {
        dialogWidth.value = '70%'
        dialogHeight.value = '6.25rem'
      } else {
        dialogWidth.value = '40%'
        dialogHeight.value = '5rem'
      }
    }

    onMounted(async () => {
      // check if the user has the onboarding completed
      onboardingComplete.value = await checkOnboardingCompleted(props.userId, props.role)

      if (!onboardingComplete.value) {
        setDialogWidth()

        onboardingDialog.title = copies.onboarding[roleName].steps[0].title
        onboardingDialog.message = copies.onboarding[roleName].steps[0].message

        // Update the onboarding to completed
        setOnboardingCompleted(props.userId, props.role)
        onboardingDialog.display = true
      }
    })

    return {
      onboardingDialog,
      handleCloseDialog,
      handlePreviousStep,
      handleNextStep,
      dialogWidth,
      dialogHeight,
      onboardingComplete
    }
  }
})
