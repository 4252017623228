import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0d838093"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-d-flex p-pt-2 card--description" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "p-d-flex p-flex-column p-jc-center p-ml-3" }
const _hoisted_4 = { class: "text-light-gray p2 p-mb-2" }
const _hoisted_5 = { class: "p-mt-5 p-d-flex p-flex-column card--schedule" }
const _hoisted_6 = { class: "p-d-flex p-ai-center p-jc-center p-px-2" }
const _hoisted_7 = { class: "p-ml-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_calendar = _resolveComponent("calendar")!
  const _component_clock = _resolveComponent("clock")!
  const _component_user = _resolveComponent("user")!
  const _component_icon = _resolveComponent("icon")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          src: `/assets/icons/categories/${_ctx.courseCategory}.svg`,
          alt: _ctx.courseCategory
        }, null, 8, _hoisted_2),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.topInformation.title), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.topInformation.description), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bottomInformation, (info, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "p-d-flex p-ai-center",
            key: index
          }, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_icon, {
                size: 18,
                strokeColor: "var(--light-gray)"
              }, {
                default: _withCtx(() => [
                  (info.icon === 'calendar')
                    ? (_openBlock(), _createBlock(_component_calendar, { key: 0 }))
                    : (info.icon === 'clock')
                      ? (_openBlock(), _createBlock(_component_clock, { key: 1 }))
                      : (info.icon === 'user')
                        ? (_openBlock(), _createBlock(_component_user, { key: 2 }))
                        : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1024)
            ]),
            _createElementVNode("p", _hoisted_7, _toDisplayString(info.description), 1)
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }))
}