
// Vendor
import { defineComponent, PropType, ref } from 'vue'

// Components
import ColumnOpinion from './ColumnOpinion.vue'

// Models
import { TagData, Tag } from '@/models/ratings/ratings'

export default defineComponent({
  components: {
    ColumnOpinion
  },
  props: {
    tagPositive: {
      type: Object as PropType<TagData>,
      required: true
    },
    tagNeutral: {
      type: Object as PropType<TagData>,
      required: true
    },
    tagNegative: {
      type: Object as PropType<TagData>,
      required: true
    },
    tagTotals: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const tagsNegAndNeutral = ref<Tag[]>([])
    if (props.tagNeutral?.tags) tagsNegAndNeutral.value.push(...props.tagNeutral.tags)
    if (props.tagNegative?.tags) tagsNegAndNeutral.value.push(...props.tagNegative.tags)

    return {
      tagsNegAndNeutral
    }
  }
})
