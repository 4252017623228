// Vendor
import { defineStore } from 'pinia'

const notificationStateDefault = {
  hasNewNotification: false
}

export const useNotificationStore = defineStore({
  id: 'notification-store',
  state() {
    const state: { hasNewNotification: boolean } = notificationStateDefault

    return state
  },
  actions: {
    newNotification() {
      this.hasNewNotification = true
    },
    clearState() {
      this.hasNewNotification = false
    }
  }
})
