
import { defineComponent, ref, reactive, watch, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { required, helpers, email, numeric } from '@vuelidate/validators'

// Components
import TwoColsLayout from '@/components/aero/layout/TwoColsLayout.vue'
import Form from '@/components/aero/forms/Form.vue'
import PlaceholderState from '@/components/aero/surfaces/challenges/PlaceholderState.vue'
import CourseInformation from '@/components/aero/surfaces/profile/changeCourse/CourseInformation.vue'
import Toast from '@/components/aero/feedback/Toast.vue'
import CoursesTable from '@/views/profile/components/CoursesTable.vue'
import SmallCardAlert from '@/components/aero/surfaces/profile/changeCourse/SmallCardAlert.vue'
import ErrorPage from '@/components/aero/dataDisplay/ErrorPage.vue'
import SkeletonCourseInfoCard from '@/components/aero/skeleton/SkeletonCourseInfoCard.vue'
import SkeletonCardAlert from '@/components/aero/skeleton/SkeletonAlertCard.vue'
import SkeletonTableFilter from '@/components/aero/skeleton/SkeletonTableFilter.vue'
import SkeletonTransferCourse from '@/components/aero/skeleton/SkeletonTransferCourse.vue'

// PrimeVue Components
import Dialog from 'primevue/dialog'

// Hooks
import { useToast } from 'primevue/usetoast'

// Store
import { themeStore } from '@/store/modules/themes.store'
import { useUserStore } from '@/store/modules/user.store'

// Services
import { getCourse } from '@/api/newPlatform/course.api'
import { getAllProductsService, getIncomingCoursesOfSameCategory } from '@/services/newPlatform/products.services'
import {
  getRequests,
  isRequestReenrollValid,
  getPenalty,
  requestReenroll,
  discardRequest
} from '@/services/newPlatform/profile.services'

import { payPenalty } from '@/services/newPlatform/payment.service'

// Copies
import {
  transferCourse as transferCopies,
  studentChangeCourse as studentChangeCourseCopies,
  errorPage as errorPageCopies
} from '@/locales/profile/es.json'

// Utils
import { parseToMillis, getCompleteDate } from '@/utils/datetime'

// Models
import { Product, ProductCategory } from '@/models/newPlatform/products/products'
import { IncomingClass } from '@/models/newPlatform/products/productCourse'
import { SelfManagementRequest, SelfManagementFlags, PaymentPayload } from '@/models/newPlatform/selfManagement'

// Amplitude
import { trackEvent } from '@/amplitude/actions'
import { ProfileEvents } from '@/amplitude/constants'
import { ToastMessageOptions } from 'primevue/toast'

export default defineComponent({
  components: {
    TwoColsLayout,
    Form,
    PlaceholderState,
    CourseInformation,
    CoursesTable,
    Toast,
    Dialog,
    SmallCardAlert,
    SkeletonTableFilter,
    SkeletonCourseInfoCard,
    SkeletonCardAlert,
    SkeletonTransferCourse,
    ErrorPage
  },
  setup() {
    const theme = themeStore()
    theme.changeDefault('aero')

    const route = useRoute()
    const router = useRouter()

    // Toast hook
    const toast = useToast()

    const isLoading = ref<boolean>(true)
    const isLoadingCourses = ref<boolean>(false)
    const courseError = ref(false)

    const { id: userId, country } = useUserStore()

    // All coder products
    const productsList = ref<Product[]>([])

    // current course state (aside data)
    const currentCourse = reactive({
      _id: '',
      category: '',
      productId: '',
      leadTeacher: '',
      courseName: '',
      camadaNro: '',
      daysAndHours: '',
      startDate: '',
      endDate: ''
    })

    const reenrollStatus = {
      requests: [] as SelfManagementRequest[],
      flags: {} as SelfManagementFlags,
      requestId: '',
      steps: {
        currentRequest: false,
        reenroll: false
      },
      alreadyRequested: false
    }
    // dialog to communicate that a request is already is progress
    const displayDialog = ref<boolean>(false)
    const displayAlertCard = ref(false)

    const penaltyCardTitle = ref('')

    const penalty = {
      alias: '',
      price: 0,
      type: ''
    }

    // List of comission available per course
    const coursesData = ref<IncomingClass[]>([])
    const comissionSelected = ref('')

    // Form data
    const toUser = reactive({
      email: '',
      dni: '',
      fullname: '',
      phone: '',
      isCurrentCourse: false,
      category: '',
      course: '',
      productId: ''
    })

    // state to watch if the user changed the category to empty the list of courses and hide the table
    const newCategory = ref('')
    const newCourse = ref('')

    watch(newCategory, () => {
      comissionSelected.value = ''
      toUser.productId = ''
      toUser.course = ''
      coursesData.value = []
    })

    // Form states
    const disableSubmit = ref<boolean>(true)
    const isCurrentCourse = ref<boolean>(false)

    const questions = ref([
      {
        title: transferCopies.questions.toUser.title,
        styles: { all: 'grid-column: 1 / 3; margin-bottom: 0 !important;' }
      },
      {
        name: 'email',
        label: transferCopies.questions.email.label,
        placeholder: transferCopies.questions.email.placeholder,
        type: 'text',
        styles: { all: 'margin: 0 !important' }
      },
      {
        name: 'dni',
        label: transferCopies.questions.dni.label,
        placeholder: transferCopies.questions.dni.placeholder,
        type: 'text',
        styles: { all: 'margin: 0 !important' }
      },
      {
        name: 'fullname',
        label: transferCopies.questions.fullname.label,
        placeholder: transferCopies.questions.fullname.placeholder,
        type: 'text',
        styles: { all: 'margin: 0 !important' }
      },
      {
        name: 'phone',
        label: transferCopies.questions.phone.label,
        placeholder: transferCopies.questions.phone.placeholder,
        type: 'text',
        styles: { all: 'margin: 0 !important' }
      },
      {
        title: transferCopies.questions.currentCourse.title,
        name: 'currentCourse',
        checked: false,
        label: transferCopies.questions.currentCourse.label,
        type: 'checkbox-binary',
        styles: { all: 'grid-column: 1 / 3;  margin-top: 1rem !important; margin-bottom: 1rem !important;' }
      },
      {
        name: 'category',
        label: transferCopies.questions.category.label,
        defaultValue: '',
        disabled: false,
        type: 'select',
        options: [
          { label: transferCopies.questions.category.options.design, value: ProductCategory.design },
          { label: transferCopies.questions.category.options.marketing, value: ProductCategory.marketing },
          { label: transferCopies.questions.category.options.development, value: ProductCategory.development },
          { label: transferCopies.questions.category.options.product, value: ProductCategory.product },
          { label: transferCopies.questions.category.options.data, value: ProductCategory.data },
          { label: transferCopies.questions.category.options.finance, value: ProductCategory.finance },
          { label: transferCopies.questions.category.options.business, value: ProductCategory.business },
          { label: transferCopies.questions.category.options.cybersecurity, value: ProductCategory.cybersecurity }
        ]
      },
      {
        name: 'course',
        label: transferCopies.questions.course.label,
        defaultValue: '',
        disabled: false,
        type: 'select',
        options: [
          {
            label: '',
            value: ''
          }
        ]
      }
    ])

    const toUserRules = {
      email: {
        required: helpers.withMessage(transferCopies.genericRequiredError, required),
        email: helpers.withMessage(transferCopies.genericEmailError, email)
      },
      dni: {
        required: helpers.withMessage(transferCopies.genericRequiredError, required),
        numeric: helpers.withMessage(transferCopies.genericNumberError, numeric)
      },
      fullname: {
        required: helpers.withMessage(transferCopies.genericRequiredError, required)
      },
      phone: {
        required: helpers.withMessage(transferCopies.genericRequiredError, required),
        numeric: helpers.withMessage(transferCopies.genericNumberError, numeric)
      }
    }

    const buttonLabels = ref({
      submit: '',
      cancel: transferCopies.questions.cta.cancel
    })

    // creates a list of courses depending on the category previuosly selected
    watch(toUser, () => {
      const filtered = productsList.value.filter((product) => product.categories[0] === toUser.category)
      const newList = filtered.map((product) => {
        return {
          label: product.title,
          value: product._id
        }
      })
      questions.value[7].options = newList
    })

    // Set self-management requests and penalty status
    const setRequests = async () => {
      try {
        const requests = await getRequests(userId, currentCourse._id)
        reenrollStatus.requests = requests

        if (requests.length) {
          const currentRequest = requests.find((item) => {
            if (item.fromCourse === currentCourse._id && ['process', 'processs'].includes(item.status)) {
              return ['reenroll', 'unenroll', 'change', 'transfer'].includes(item.type)
            } else {
              return false
            }
          })
          if (currentRequest?._id) {
            reenrollStatus.alreadyRequested = true
            reenrollStatus.requestId = currentRequest._id
            reenrollStatus.steps.currentRequest = true
          }
        }

        reenrollStatus.steps.reenroll = true

        const requestFlags = await isRequestReenrollValid(userId, currentCourse._id)
        reenrollStatus.flags = requestFlags

        buttonLabels.value.submit = reenrollStatus.flags.hasToPay
          ? transferCopies.questions.cta.payPenalty
          : transferCopies.questions.cta.sendRquest
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e, 'error while trying to retrieve requests data')
        throw e
      }
    }

    const setPenalties = async () => {
      try {
        const response = await getPenalty(userId, country, 'reenroll')
        penalty.alias = response.pricing.alias
        penalty.price = response.pricing.price
        penalty.type = response.type
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e, 'error while trying to retrieve penalty data')
        throw e
      }
    }

    const setSelfManagementStatus = async () => {
      try {
        await Promise.all([setRequests(), setPenalties()])
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('error while trying to retrieve data from self-management requests and penalties')
        throw e
      }
    }

    // Current course data onload (aside)
    const getCurrentCourseData = async () => {
      try {
        const { camadaNro } = route.params

        const response = await getCourse(userId, camadaNro.toString())
        currentCourse.courseName = response.course.level.title
        currentCourse.daysAndHours = response.course.daysAndHours
        currentCourse.startDate = response.course.startDate
        currentCourse.endDate = response.course.endDate

        // category name to render de apropiate icon at the aside section
        currentCourse.category = response.course.level.categories[0]
        // productId and courseId in case the user choose to transfer the same course
        currentCourse.productId = response.course.level._id
        currentCourse._id = response.course._id
        currentCourse.leadTeacher = response.course.camada.leadTeacher?._id || ''
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('error while trying to retrieve data from the current course')
        throw e
      }
    }

    const getAllProducts = async () => {
      try {
        const products = await getAllProductsService(country, 'remote')
        productsList.value = products
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e, 'error while trying to retrieve all products')
        throw e
      }
    }

    const getCourses = async () => {
      if (!newCourse.value) return
      isLoadingCourses.value = true
      try {
        const response = await getIncomingCoursesOfSameCategory(country, 'remotes', newCourse.value)

        coursesData.value = response.map((course) => {
          const teacherName = course.camada?.leadTeacher?.fullName || 'No asignado aún'
          const startDate = parseToMillis(course.startDate)
          const endDate = parseToMillis(course.endDate)

          return {
            teacherName,
            camadaNro: course.camada.id,
            days: course.days.join(' y '),
            schedule: course.hours,
            duration: `${getCompleteDate(startDate)} al ${getCompleteDate(endDate)}`,
            daysToStart: course.startsIn,
            leadTeacherId: course.camada?.leadTeacher?._id || '',
            startDate: parseToMillis(course.startDate),
            numDays: course._days,
            courseId: course._id,
            value: course._id
          }
        })
      } catch (e) {
        courseError.value = true
        // eslint-disable-next-line no-console
        console.log(e, 'error while trying to retrieve courses per category')
      } finally {
        isLoadingCourses.value = false
      }
    }

    watch(newCourse, () => {
      getCourses()
    })

    //  Enable/disable submit button and show/hide empty state placeholder
    watch(toUser, () => {
      if (!toUser.isCurrentCourse) {
        questions.value[6].disabled = false
        questions.value[7].disabled = false
      } else {
        questions.value[6].disabled = true
        questions.value[7].disabled = true
      }

      if (!questions.value[6].disabled && toUser.category && toUser.course) {
        disableSubmit.value = false
      } else if (questions.value[6].disabled && toUser.isCurrentCourse) {
        disableSubmit.value = false
      } else {
        disableSubmit.value = true
      }
    })

    // clear comission selected when the user switched between current course (checkbox input) and a new one using Select inputs
    watch(isCurrentCourse, () => {
      comissionSelected.value = ''
    })

    // DOM Handlers
    const handleChange = (values: { [x: string]: string }) => {
      toUser.email = values.email
      toUser.dni = values.dni
      toUser.fullname = values.fullname
      toUser.phone = values.phone
      toUser.isCurrentCourse = !!values.currentCourse
      toUser.category = values.category
      toUser.course = values.course

      newCategory.value = values.category
      newCourse.value = toUser.isCurrentCourse ? currentCourse.productId : toUser.course

      isCurrentCourse.value = !!values.currentCourse
    }

    const handleChooseComission = (value: string) => {
      comissionSelected.value = value
    }
    const handleCancel = () => {
      router.push('/profile')
    }

    const handleDiscardRequest = async () => {
      //  set loading true
      const body = {
        userId,
        requestId: reenrollStatus.requestId
      }
      const { success, error, group, life } = transferCopies.toast.toastSeverity as { success: 'success', life: number, error: 'error', group: string}
      try {
        await discardRequest(body)

        trackEvent(ProfileEvents.DISCARD_REQUEST)

        toast.add({ severity: success, detail: transferCopies.toast.discardRequest.success, group, life })
      } catch (e) {
        toast.add({ severity: error, detail: transferCopies.toast.discardRequest.error, group, life })
      } finally {
        // set loading state false
        setTimeout(() => {
          router.push('/profile')
        }, 300)
      }
    }

    const handlePayPenalty = async (payload: PaymentPayload): Promise<any> => {
      try {
        return await new Promise((resolve, reject) => {
          payPenalty(payload, (err: boolean, data: any) => (err ? reject(err) : resolve(data)))
        })
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e, 'error payment')
        throw e
      }
    }

    const handleSubmit = async () => {
      const isSelfPayment = 1
      // isSelfPayment: 0 => sends notificacion without payment
      // isSelfPayment: 1 => mercadolibre checkout
      if (!comissionSelected.value) return

      const newUser = {
        email: toUser.email,
        fullName: toUser.fullname,
        dni: toUser.dni,
        phone: toUser.phone
      }

      const payload = {
        userId,
        fromCourseId: currentCourse._id,
        toCourseId: comissionSelected.value,
        flag: reenrollStatus.flags.penaltyReenroll ? 'reenroll' : 'change',
        isSelfPayment,
        country,
        toUser: newUser,
        isTransferRequest: 1
      }

      const { success, error, info, group, life } = transferCopies.toast.toastSeverity

      try {
        const data = await requestReenroll(payload)
        if (isSelfPayment && data.gateway) {
          localStorage.setItem('ch-reenroll-request', JSON.stringify(data))
          toast.add({
            severity: info as 'info',
            detail: transferCopies.toast.payPenalty.info,
            group,
            life
          })

          await new Promise((resolve) => setTimeout(resolve, 3000))
          const _data = await handlePayPenalty({
            ...data,
            ...payload
          })

          if (_data?.success) {
            toast.add({ severity: success as ToastMessageOptions['severity'], detail: transferCopies.toast.payPenalty.success, group, life })

            trackEvent(ProfileEvents.TRANSFER_COURSE)

            setTimeout(() => {
              router.push('/profile')
            }, 300)
          } else {
            throw new Error()
          }
        } else {
          // No penalty, submit request without payment
          toast.add({ severity: success as 'success', detail: transferCopies.toast.sendRequest.success, group, life })

          trackEvent(ProfileEvents.TRANSFER_COURSE)

          // redirect profile
          setTimeout(() => {
            router.push('/profile')
          }, 300)
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
        toast.add({ severity: error as ToastMessageOptions['severity'], detail: transferCopies.toast.sendRequest.error, group, life })
      }
    }

    onMounted(async () => {
      try {
        await getCurrentCourseData()
        await setSelfManagementStatus()
        // check if a request is already in progress
        if (reenrollStatus.alreadyRequested) {
          displayDialog.value = true
        } else {
          getAllProducts()

          let penaltyTitle = ''

          if (reenrollStatus.flags.hasToPay || reenrollStatus.flags.penaltyReenroll) {
            displayAlertCard.value = true
            if (!reenrollStatus.flags.isStarted) penaltyTitle = transferCopies.penaltyCard.notStarted
            if (reenrollStatus.flags.isStarted) penaltyTitle = transferCopies.penaltyCard.started
            if (reenrollStatus.flags.isEnded) penaltyTitle = transferCopies.penaltyCard.finished
          }

          penaltyCardTitle.value = `${penaltyTitle} ${penalty.alias}. `
        }
      } catch (e) {
        courseError.value = true
        // eslint-disable-next-line no-console
        console.log(e)
      } finally {
        isLoading.value = false
      }
    })

    return {
      questions,
      toUserRules,
      isCurrentCourse,
      currentCourse,
      handleChange,
      handleCancel,
      handleSubmit,
      handleChooseComission,
      handleDiscardRequest,
      buttonLabels,
      disableSubmit,
      toUser,
      transferCopies,
      studentChangeCourseCopies,
      errorPageCopies,
      coursesData,
      comissionSelected,
      reenrollStatus,
      displayDialog,
      camadaNro: route.params.camadaNro,
      isLoading,
      isLoadingCourses,
      penaltyCardTitle,
      courseError,
      displayAlertCard
    }
  }
})
