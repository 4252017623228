const overlayDOM = document.createElement('div')

export function setAnimations(overlayId: string) {
  const viewDOM = document.querySelector(`#${overlayId}`)

  const dropZoneDOM = document.querySelector('#dropzone-container')
  const dropzoneBgDOM = document.querySelector('#dropzone-background')
  const dropZoneActiveDOM = document.querySelector('#dropzone-active-overlay')

  const iconUploadDOM = document.querySelector('#icon__upload')

  if (dropzoneBgDOM && dropZoneDOM && dropZoneActiveDOM && iconUploadDOM) {
    viewDOM?.appendChild(overlayDOM)
    viewDOM?.addEventListener('dragover', (e: Event) => dragOverView(e, dropzoneBgDOM, dropZoneDOM))
    viewDOM?.addEventListener('drop', (e: Event) => dropView(e, dropzoneBgDOM, dropZoneActiveDOM, iconUploadDOM, dropZoneDOM))
    viewDOM?.addEventListener('click', (e: Event) =>
      dropAnimation(e, overlayDOM, dropzoneBgDOM, dropZoneActiveDOM, iconUploadDOM, dropZoneDOM)
    )

    dropZoneDOM?.addEventListener('dragenter', (e: Event) => dropzoneEnter(e, dropZoneActiveDOM, iconUploadDOM))

    dropZoneActiveDOM?.addEventListener('dragleave', (e: Event) => dropzoneLeave(e, dropZoneActiveDOM, iconUploadDOM))
  }
}

export function removeAnimations(overlayId: string) {
  const viewDOM = document.querySelector(`#${overlayId}`)

  const dropZoneDOM = document.querySelector('#dropzone-container')
  const dropzoneBgDOM = document.querySelector('#dropzone-background')
  const dropZoneActiveDOM = document.querySelector('#dropzone-active-overlay')

  const iconUploadDOM = document.querySelector('#icon__upload')

  if (dropzoneBgDOM && dropZoneDOM && dropZoneActiveDOM && iconUploadDOM) {
    viewDOM?.removeEventListener('dragover', (e: Event) => dragOverView(e, dropzoneBgDOM, dropZoneDOM))
    viewDOM?.removeEventListener('drop', (e: Event) => dropView(e, dropzoneBgDOM, dropZoneActiveDOM, iconUploadDOM, dropZoneDOM))
    viewDOM?.removeEventListener('click', (e: Event) =>
      dropAnimation(e, overlayDOM, dropzoneBgDOM, dropZoneActiveDOM, iconUploadDOM, dropZoneDOM)
    )

    dropZoneDOM?.removeEventListener('dragenter', (e: Event) => dropzoneEnter(e, dropZoneActiveDOM, iconUploadDOM))

    dropZoneActiveDOM?.removeEventListener('dragleave', (e: Event) => dropzoneLeave(e, dropZoneActiveDOM, iconUploadDOM))
  }
}

function dragOverView(e: Event, dropzoneBgDOM: Element, dropZoneDOM: Element) {
  e.preventDefault()
  e.stopPropagation()

  overlayDOM.classList.add('animation__overlay')
  dropzoneBgDOM?.classList.add('animation__dropzoneBg')
  dropZoneDOM?.classList.add('animation__drop-zone__border--active')
  dropZoneDOM?.classList.remove('animation__drop-zone--file-dropped')
}

function dropView(e: Event, dropzoneBgDOM: Element, dropZoneActiveDOM: Element, iconUploadDOM: Element, dropZoneDOM: Element) {
  e.preventDefault()
  e.stopPropagation()

  overlayDOM.classList.remove('animation__overlay')
  dropzoneBgDOM?.classList.remove('animation__dropzoneBg')
  dropZoneActiveDOM?.classList.remove('animation__dropzone--active')
  iconUploadDOM?.classList.remove('animation__iconUpload')
  dropZoneDOM?.classList.remove('animation__drop-zone__border--active')
}

function dropzoneEnter(e: Event, dropZoneActiveDOM: Element, iconUploadDOM: Element) {
  e.preventDefault()
  e.stopPropagation()

  dropZoneActiveDOM?.classList.add('animation__dropzone--active')
  dropZoneActiveDOM?.classList.replace('animation__dropzone--inactive', 'animation__dropzone--active')
  iconUploadDOM?.classList.add('animation__iconUpload')
}

function dropzoneLeave(e: Event, dropZoneActiveDOM: Element, iconUploadDOM: Element) {
  e.preventDefault()
  e.stopPropagation()

  dropZoneActiveDOM?.classList.replace('animation__dropzone--active', 'animation__dropzone--inactive')
  iconUploadDOM?.classList.remove('animation__iconUpload')
}

function dropAnimation(
  e: Event,
  overlayDOM: Element,
  dropzoneBgDOM: Element,
  dropZoneActiveDOM: Element,
  iconUploadDOM: Element,
  dropZoneDOM: Element
) {
  e.preventDefault()
  e.stopPropagation()

  if (overlayDOM.classList.contains('animation__overlay')) {
    dropView(e, dropzoneBgDOM, dropZoneActiveDOM, iconUploadDOM, dropZoneDOM)
  }
}
