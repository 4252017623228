import { approvePenalty } from '@/api/newPlatform/selfManagement.api'
import { PaymentPayload } from '@/models/newPlatform/selfManagement'

const paymentMethods = {
  items: [
    {
      id: 'gateway-stripe-v2',
      src: 'https://js.stripe.com/v2/',
      cb() {
        // eslint-disable-next-line no-console
        console.log(`${this.id}-loaded`)
      }
    },
    {
      id: 'gateway-stripe-checkout',
      src: 'https://checkout.stripe.com/checkout.js',
      cb() {
        // eslint-disable-next-line no-console
        console.log(`${this.id}-loaded`)
      }
    },
    {
      id: 'gateway-stripe-v3',
      src: 'https://js.stripe.com/v3/',
      cb() {
        // eslint-disable-next-line no-console
        console.log(`${this.id}-loaded`)
      }
    },
    {
      id: 'gateway-mercadopago',
      src: 'https://secure.mlstatic.com/sdk/javascript/v1/mercadopago.js',
      cb() {
        // eslint-disable-next-line no-console
        console.log(`${this.id}-loaded`)
      }
    }
  ],
  get: (id: string, cb: () => void) => {
    const item = paymentMethods.items.find((item) => item.id.includes(id))
    if (item?.id) {
      if (!document.getElementById(item.id)) {
        const tag = document.createElement('script')
        tag.id = item.id
        tag.src = item.src
        tag.async = !!1
        tag.defer = !!1
        tag.onload = cb || item.cb
        document.body.appendChild(tag)
      } else {
        item.cb()
        if (cb) cb()
      }
    } else {
      // eslint-disable-next-line no-console
      console.log(id, item)
      cb()
    }
  },
  getAll: (ids: string[], cb: () => void) => {
    const assets = paymentMethods.items.filter((item) => ids.find((id) => item.id.includes(id)))
    let count = assets.length
    assets.forEach((item) => {
      return paymentMethods.get(item.id, () => {
        count--
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (!count) cb(null)
      })
    })
  }
}

function getGateways(gateway: string, cb: any) {
  return paymentMethods.getAll([gateway], cb)
}

export const payPenalty = async (purchase: PaymentPayload, cb: any) => {
  const gateway = purchase.gateway

  try {
    return getGateways(gateway, () => {
      if (gateway === 'mercadopago') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        $MPC.openCheckout({
          url: purchase.init_point,
          mode: 'modal',
          onreturn(data: any) {
            if (data && data.collection_id && data.collection_status === 'approved') {
              const params = Object.assign({}, data, {
                gateway,
                pid: data.external_reference,
                userId: purchase.userId
              })
              approvePenalty(params).then(() => {
                cb(null, { success: true })
              }, cb)
            } else {
              cb(new Error('Payment Error'), { success: false })
            }
          }
        })
      } else if (gateway === 'stripe') {
        const item = purchase.response.display_items[0]
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const checkout = StripeCheckout.configure({
          key: purchase.publicKey,
          image: item.images[0],
          allowRememberMe: false,
          token(token: any) {
            const params = Object.assign({}, token, {
              gateway,
              pid: purchase.purchaseId,
              userId: purchase.userId
            })
            approvePenalty(params).then(() => cb(null, { success: true }), cb)
            return false
          }
        })
        checkout.open({
          name: item.description,
          description: item.name,
          currency: item.currency,
          amount: item.amount,
          email: purchase.response.customer_email
        })
      }
      return purchase
    })
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e)
  }
}
