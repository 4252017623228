
import { defineComponent, PropType } from 'vue'
import { ITutorChallengeStudents } from '@/models/peer-review/challenge'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  props: {
    student: {
      type: Object as PropType<ITutorChallengeStudents>,
      required: true
    }
  },
  setup() {
    const { t } = useI18n()
    const initialLetter = (name: string) => {
      return (name?.trim() || '').charAt(0)
    }
    return {
      t,
      initialLetter
    }
  }
})
