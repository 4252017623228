export interface UserBalance {
  _id: string;
  claimed: boolean
  claimedAt?: Date
  enable: boolean
  onlyForLevel: boolean
  management: string
  price: number
  comment: string
  currency: {
    name: string
    symbol: string
    'iso_code': string
    'symbol_position': string
  }
  createdAt: Date
}

export function isBalanceAvailable(balance: UserBalance): boolean {
  return balance.enable && !balance.claimed && balance.management === 'free'
}
