/* eslint-disable @typescript-eslint/no-explicit-any */

// Assets
import defaultCriterias from '@/assets/challenges/defaultEvaluationCriterias.json'

// Models
import { IClass } from '@/models/classes/class'
import { ISchedule } from '@/models/classes/schedule'
import {
  IDeliver,
  IDeliverTeacher,
  TDeliverReview,
  DeliverMessage,
  IDeliverLock,
  IProjectDeliver,
  IStudentDelivers
} from '@/models/newPlatform/challenges/deliver'
import { IFlagsTeacher, IModule, IModuleTeacher } from '@/models/newPlatform/challenges/module'
import { IProgress } from '@/models/newPlatform/challenges/progress'
import { ITimeline, ITimeLineStatus } from '@/models/newPlatform/challenges/timeline'

import { DateTime } from 'luxon'

// Services
import { Api } from '../api'
import { getClasses } from '../classes.api'

// copies
import copies from '@/locales/challenges/es.json'

const { API_ENDPOINT } = process.env

/**
 * API request to retrieve data from a certain module.
 * @param moduleId Logged user id
 * @returns module data.
 * @see [Postman]{@link https://coderplatformers.postman.co/workspace/Coder-House-Platform~9b3cd88b-81e2-4307-8432-95267b456aa7/request/17745020-550a98ca-23c5-47fe-ab1e-e37c46f41d05}
 */

const getModule = async (moduleId: string): Promise<any> => {
  // retorna un objeto con los detalles del modulo/desafio
  return await Api.get(`${API_ENDPOINT}/platform/modules/${moduleId}?ts=${Date.now()}`)
}

export const getModules = async (userId: string, camadaNro: number): Promise<any> => {
  // retorna un array de clases junto con sus modulos (desafios)
  return await Api.get(`${API_ENDPOINT}/platform/users/${userId}/courses/${camadaNro}/program/progress?ts=${Date.now()}`)
}

/**
 * API request to retrieve data from a certain deliver.
 * @param camadaNro current course's number
 * @param deliverId deliverId to request
 * @returns single deliver data.
 * @see [Postman]{@link https://coderplatformers.postman.co/workspace/Coder-House-Platform~9b3cd88b-81e2-4307-8432-95267b456aa7/request/17745020-95141689-2447-4d94-8147-3f653c94780d}
 */

export const getProject = async (camadaNro: number, deliverId: string): Promise<any> => {
  const url = `${API_ENDPOINT}/platform/camadas/${camadaNro}/delivers/${deliverId}`
  return await Api.get(url)
}

/**
 * API request to retrieve data student's delivers.
 * @param userId Logged user id
 * @param courseId current course's id
 * @returns student's current delivers.
 * @see [Postman]{@link https://coderplatformers.postman.co/workspace/Coder-House-Platform~9b3cd88b-81e2-4307-8432-95267b456aa7/request/17745020-53ab75be-6c96-4c26-aaa4-bed1291c65f2}
 */

export const getProjects = async (userId: string, courseId: string): Promise<any> => {
  // retorna un array de projects (entregas) con sus chats y timelines, tambien on el curso y modulo
  return await Api.get(
    `${API_ENDPOINT}/platform/courses/${courseId}/student/${userId}/projects?courseId=${courseId}&ts=${Date.now()}`
  )
}

/**
 * Multiple API requests to retrieve data from a challenge.
 * @param userId Logged user id
 * @param camadaNro current course's number
 * @param moduleId Logged user id
 * @param courseId current course's id
 * @returns student's deliver status and matching module
 */

export const getChallengeData = async (userId: string, camadaNro: number, moduleId: string, courseId: string) => {
  // recupera data de varios endpoints para armar un solo objeto
  const response = await Promise.all([getModule(moduleId), getClasses(userId, camadaNro.toString()), getProjects(userId, courseId)])

  const module = response[0]
  const { class: _class, schedules } = response[1]
  const projects = response[2]

  const classData = _class.stages.find((c: IClass) => c._id === module.stage)
  const classSchedule = schedules.find((s: ISchedule) => s.stage === module.stage)
  const hasProject = projects.length > 0 ? projects.find((_project: any) => _project.module?._id === module._id) : false

  const data = {
    _id: module._id,
    name: module.name,
    openDateModule: classSchedule?.from || 0,
    evaluationCriteria: module.evaluationCriteria.length > 0 ? module.evaluationCriteria : defaultCriterias,
    slug: module.slug,
    expiresAt: classSchedule?.flags.deliverExpirationDate || 0,
    slideUrl: module.slideUrl,
    scheduleId: classSchedule._id,
    class: {
      _id: module.class,
      order: classData ? classData.order + 1 : 0,
      name: module.name
    },
    stage: module.stage,
    project: hasProject ? hasProject._id : ''
  }
  return data
}

/**
 * Multiple API requests to retrieve data from all challenges and the student's current deliver status for each challenge.
 * @param userId Logged user id
 * @param courseId current course's id
 * @param camadaNro current course's number
 * @returns course's challenges and deliver status for each challenge
 */

export const getChallengesData = async (userId: string, courseId: string, camadaNro: number) => {
  // recupera data de varios endpoints para armar un array de desafios del alumno junto con sus estados

  const modules: [] = await getModules(userId, camadaNro)
  const challenges: any[] = modules.map((_module: IClass) => _module.modules)

  const projects: [] = await getProjects(userId, courseId)

  const data = challenges.flat().map((_challenge: IModule) => {
    const module: any = modules.find((_module: any) => _module._id === _challenge.stage)
    const project: IDeliver = projects.find((_project: IDeliver) => _project.module?._id === _challenge._id) || { _id: '0' }

    const status = () => {
      if (project.status) {
        if (project.status?.approved) return 'approved'
        if (project.chat?.slice(-1)[0].text === copies.ReviewChallenge.message.deliverAgain) return 'disapproved'
        return 'delivered'
      } else {
        return 'not delivered'
      }
    }

    const getFinalDeadline = (timeline: ITimeline[]) => {
      const finalDeadline = timeline.find((step) => step.key === ITimeLineStatus.finalDeadline)
      if (finalDeadline) {
        return typeof finalDeadline.date === 'string' ? DateTime.fromISO(finalDeadline.date).toMillis() : finalDeadline.date
      } else {
        return null
      }
    }

    return {
      _id: _challenge._id,
      name: _challenge.name,
      slug: _challenge.slug,
      expiresAt: _challenge.flags?.deliverExpirationDate,
      evaluationCriteria: [],
      class: {
        _id: module.schedule._id,
        order: module.order + 1,
        name: module.name,
        day: module.schedule.day
      },
      flags: {
        isIntegratorTp: _challenge.flags?.isIntegratorTp,
        isPreIntegratorTp: _challenge.flags?.isPreIntegratorTp,
        isComplementaryTp: _challenge.flags?.isComplementaryTp,
        isTp: _challenge.flags?.isTp
      },
      stage: _challenge.stage,
      slideUrl: _challenge.slideUrl,
      status: status(),
      projectId: project.status ? project._id : '',
      timeline: project.timeline || [],
      projectData: project.status ? project : null,
      finalDeadline: project.timeline ? getFinalDeadline(project.timeline) : null
    }
  })

  return data
}

// TEACHER TUTOR

/**
 * API requests to retrieve data from the current course's modules and student's data and delivers.
 * @param camadaNro current course's number
 * @param teacherId Logged user id (user must have teacher or tutor role)
 * @returns course's modules and student's data and delivers
 * @see [Postman]{@link https://coderplatformers.postman.co/workspace/Coder-House-Platform~9b3cd88b-81e2-4307-8432-95267b456aa7/request/17745020-95141689-2447-4d94-8147-3f653c94780d}
 */

const getCourseModulesAndDelivers = async (camadaNro: number, teacherId: string) => {
  const { modules, delivers, allStudents } = await Api.get(
    `${API_ENDPOINT}/platform/camadas/${camadaNro}/delivers?camadaId=${camadaNro}&teacherId=${teacherId}&ts=${Date.now()}&v2=1`
  )
  return { modules, delivers, allStudents }
}

/**
 * API requests to retrieve data from the current course's modules and student's data and delivers.
 * @param camadaNro current course's number
 * @param teacherId Logged user id (user must have teacher or tutor role)
 * @returns course's modules and student's data and delivers
 * @see [Postman]{@link https://coderplatformers.postman.co/workspace/Coder-House-Platform~9b3cd88b-81e2-4307-8432-95267b456aa7/request/17745020-023badef-5fcf-426d-b8c0-65b11a5325c4}
 */

const getDeliversByModule = async (camadaNro: number, moduleId: string, teacherId: string): Promise<any> => {
  return await Api.get(
    `${API_ENDPOINT}/platform/camadas/${camadaNro}/modules/${moduleId}/delivers?camadaId=${camadaNro}&moduleId=${moduleId}&teacherId=${teacherId}&ts=${Date.now()}`
  )
}

/**
 * API request to retrieve stats from the current course's delivers.
 * @param camadaNro current course's number
 * @param teacherId (Required for user with tutor role) Logged user id
 * with tutor role it needs teacherId to retrieve stats from the group of students that has been assigned
 * with teacher role retrives stats from the whole group of students
 * @returns course's delivers stats
 * @see [Postman]{@link https://coderplatformers.postman.co/workspace/Coder-House-Platform~9b3cd88b-81e2-4307-8432-95267b456aa7/request/17745020-489da3a5-a62c-4d63-95f8-0477abc0ae47}
 */

export const getCourseDeliversProgress = async (camadaNro: number, teacherId?: string): Promise<IProgress> => {
  return await Api.get(
    `${API_ENDPOINT}/platform/camadas/${camadaNro}/delivers/progress?camadaId=${camadaNro}&ts=${Date.now()}${
      teacherId ? '&teacherId=' + teacherId : ''
    }`
  )
}

/**
 * API request to retrieve data a specific student deliver/project.
 * @param camadaNro current course's number
 * @param deliverId id from the deliver/project to request the data
 * @returns deliver/project data
 * @see [Postman]{@link https://coderplatformers.postman.co/workspace/Coder-House-Platform~9b3cd88b-81e2-4307-8432-95267b456aa7/request/17745020-95141689-2447-4d94-8147-3f653c94780d}
 */

export const getDeliverById = async (camadaNro: number, deliverId: string): Promise<any> => {
  return Api.get(`${API_ENDPOINT}/platform/camadas/${camadaNro}/delivers/${deliverId}?ts=${Date.now()}`)
}

/**
 * Service to retrieve data from the current course's modules and delivers
 * @param camadaNro current course's number
 * @param teacherId Logged user id (user must have teacher or tutor role)
 * @returns List of the course's modules. List of handed delivers and list of students
 * user with teacher role, retrieves the complete list of students
 * user with tutor role, retrieves a list of the student that has been assigned
 */

export const getModulesAndDeliversData = async (camadaNro: number, teacherId: string) => {
  const { modules, delivers, allStudents } = await getCourseModulesAndDelivers(camadaNro, teacherId)
  const { class: _class } = await getClasses(teacherId, camadaNro.toString())
  const modulesList: IModuleTeacher[] = []
  const deliversList: IDeliver[] = []
  const studentsList: IStudentDelivers[] = []
  modules.forEach((module: IModuleTeacher) => {
    const moduleData = {
      _id: module._id,
      name: module.name,
      expiresAt: module.flags.deliverExpirationDate,
      deliverTimeLeftToExpire: module.flags.deliverTimeLeftToExpire,
      class: {
        classId: module.schedule?.index + 1,
        day: module.schedule?.day
      },
      flags: module.flags,
      progress: {
        challenges: module.progress.challenges,
        projects: module.progress.projects,
        total: module.progress.total
      },
      stage: module.stage,
      schedule: {
        day: module.schedule.day,
        index: module.schedule.index
      }
    }

    modulesList.push(moduleData)
  })
  delivers.forEach((deliver: any) => {
    const classData = _class.stages.find((c: IClass) => c._id === deliver.stage)

    let flagsData = {}
    if (deliver.flags) {
      flagsData = {
        wasApproved: deliver.flags.wasApproved,
        wasDisapproved: deliver.flags.wasDissaproved,
        wasReviewed: deliver.flags.wasReviewed,
        wasReviewedOnTime: deliver.flags.wasReviewedOnTime,
        isDeliverExpired: deliver.flags.isDeliverExpired,
        isLocked: deliver.flags.isLocked,
        isClosed: deliver.flags.isClosed,
        isIntegratorTp: deliver.flags.isIntegratorTp
      }
    } else {
      flagsData = {
        wasApproved: deliver.status.approved,
        wasDisapproved: deliver.status.disapproved,
        wasReviewed: deliver.status.reviewed
      }
    }

    const data: IDeliver = {
      _id: deliver._id,
      createdAt: deliver.createdAt,
      unlockedUntil: deliver.unlockedUntil ? deliver.unlockedUntil : '',
      expiresAt: deliver.flags?.deliverExpirationDate,
      deliverTimeLeftToExpire: deliver.flags?.deliverTimeLeftToExpire,
      firstReviewEstimatedAt: deliver.flags?.firstReviewEstimatedAt,
      hoursLeftToReview: deliver.flags?.hoursLeftToReview,
      hoursLeftToReviewExpired: deliver.hoursLeftToReviewExpired,
      student: {
        _id: deliver.student
      },
      status: {
        approved: deliver.status.approved,
        disapproved: deliver.status.disapproved,
        reviewed: deliver.status.reviewed
      },
      flags: flagsData,
      module: {
        _id: deliver.moduleId,
        name: deliver.module?.name
      },
      class: {
        classId: classData ? classData.order + 1 : 0,
        className: classData ? classData.name : ''
      },
      chat: deliver.chat,
      timeline: deliver.timeline,
      alias: deliver.alias
    }

    deliversList.push(data)
  })

  allStudents.forEach((student: any) => {
    const studentData = {
      student: {
        _id: student._id,
        fullName: student.fullName,
        initials: student.initials,
        avatar: student.avatar
      },
      stats: student.stats,
      delivers: [] as IDeliver[]
    }

    if (student.delivers.length) {
      student.delivers.forEach((deliver: IDeliver) => {
        const deliverData = {
          _id: deliver._id,
          flags: {
            wasApproved: deliver.flags?.wasApproved,
            wasDisapproved: deliver.flags?.wasDissaproved,
            wasReviewed: deliver.flags?.wasReviewed,
            wasReviewedOnTime: deliver.flags?.wasReviewedOnTime,
            isDeliverExpired: deliver.flags?.isDeliverExpired,
            isLocked: deliver.flags?.isLocked,
            isClosed: deliver.flags?.isClosed,
            hasChatsUnviewed: deliver.flags?.hasChatsUnviewed
          },
          unlockedUntil: deliver.unlockedUntil || '',
          expiresAt: deliver.flags?.deliverExpirationDate,
          firstReviewEstimatedAt: deliver.flags?.firstReviewEstimatedAt,
          hoursLeftToReview: deliver.flags?.hoursLeftToReview,
          hoursLeftToReviewExpired: deliver.flags?.hoursLeftToReviewExpired,
          locked: deliver.locked,
          alias: deliver.alias,
          module: {
            _id: deliver.module?._id
          },
          timeline: deliver.timeline,
          chat: deliver.chat
        }

        studentData.delivers.push(deliverData)
      })
    }
    studentsList.push(studentData)
  })
  return { modules: modulesList, delivers: deliversList, students: studentsList }
}

/**
 * Service to retrieve list of delivers from a certain module
 * @param camadaNro current course's number
 * @param moduleId id of the module to make the request
 * @param teacherId Logged user id (user must have teacher or tutor role)
 * @returns List of students data with each deliver data (if it has one)
 * user with teacher role, retrieves the complete list of students
 * user with tutor role, retrieves a list of the student that has been assigned
 */

export const getDeliversByModuleData = async (camadaNro: number, moduleId: string, teacherId: string) => {
  const { name, flags, students, schedule, slideUrl } = await getDeliversByModule(camadaNro, moduleId, teacherId)

  const classData = {
    classId: schedule.index + 1,
    _id: schedule._id
  }

  const deliversList: IDeliverTeacher[] = []

  const deliverFlags: IFlagsTeacher = flags

  students.forEach((student: any) => {
    const data: IDeliverTeacher = {
      _id: student.flags.hasDeliver ? student.deliver._id : '',
      student: {
        _id: student._id,
        fullName: student.fullName,
        initials: student.initials,
        avatar: student.avatar
      },
      flags: {
        hasDeliver: student.flags.hasDeliver
      },
      deliver: { _id: '' }
    }

    if (data.flags.hasDeliver) {
      const deliverData: IDeliver = {
        _id: student.deliver?._id,
        status: {
          approved: student.deliver.status?.approved
        },
        enable: student.deliver.enable,
        student: student.deliver.student,
        stage: student.deliver.stage,
        chat: student.deliver.chat,
        module: {
          _id: student.deliver.module?._id
        },
        createdAt: student.deliver.createdAt,
        flags: {
          isPreIntegratorTp: student.deliver.flags?.isPreIntegratorTp,
          isIntegratorTp: student.deliver.flags?.isIntegratorTp,
          isLocked: student.deliver.flags?.isLocked,
          isClosed: student.deliver.flags?.isClosed,
          wasApproved: student.deliver.flags.wasApproved,
          wasReviewed: student.deliver.flags?.wasReviewed,
          wasDeliverOnTime: student.deliver.flags?.wasDeliverOnTime,
          wasReviewedOnTime: student.deliver.flags?.wasReviewedOnTime,
          firstReviewEstimatedAt: student.deliver?.flags?.firstReviewEstimatedAt,
          hoursLeftToReview: student.deliver.flags?.hoursLeftToReview,
          hasChatsUnviewed: student.deliver.flags?.hasChatsUnviewed,
          reviewsCount: student.deliver.flags?.reviewsCount
        },
        unlockedUntil: student.deliver.unlockedUntil,
        alias: student.deliver.alias,
        locked: student.deliver.locked,
        timeline: student.deliver.timeline
      }

      data.deliver = deliverData
    }

    deliversList.push(data)
  })

  return { moduleName: name, slideUrl, class: classData, students: deliversList, deliverFlags, openDateModule: schedule.day }
}

/**
 * Service to retrieve data from a certain deliver/project
 * @param camadaNro current course's number
 * @param deliverId id from the deliver/project to request the data
 * @returns deliver/project data
 */
export const getDeliverData = async (camadaNro: number, deliverId: string) => {
  const {
    _id,
    student,
    relatedStudents,
    status,
    createdAt,
    module,
    schedule,
    chat,
    flags,
    alias,
    locked,
    timeline,
    unlockedUntil = null,
    flagDeliverAfterExpire
  } = await getDeliverById(camadaNro, deliverId)

  const challengeData: IDeliver = {
    _id,
    createdAt: createdAt,
    unlockedUntil: unlockedUntil || '',
    flagDeliverAfterExpire: flagDeliverAfterExpire,
    expiresAt: schedule ? schedule.flags.deliverExpirationDate : '000',
    deliverTimeLeftToExpire: flags.deliverTimeLeftToExpire,
    firstReviewEstimatedAt: flags.firstReviewEstimatedAt,
    hoursLeftToReview: flags.hoursLeftToReview,
    hoursLeftToReviewExpired: flags.hoursLeftToReviewExpired,
    student: {
      _id: student._id,
      fullName: student.fullName,
      initials: student.initials,
      avatar: student.avatar
    },
    status: {
      approved: status.approved,
      disapproved: status.disapproved,
      reviewed: status.reviewed
    },
    class: {
      classId: schedule.index + 1,
      className: schedule.stage.name
    },
    module: {
      _id: module._id,
      name: module.name
    },
    flags: {
      wasApproved: flags.wasApproved,
      wasDisapproved: flags.wasDissaproved,
      wasReviewed: flags.wasReviewed,
      wasReviewedOnTime: flags.wasReviewedOnTime,
      isDeliverExpired: flags.isDeliverExpired,
      isLocked: flags.isLocked,
      isClosed: flags.isClosed,
      hasChatsUnviewed: flags.hasChatsUnviewed,
      isIntegratorTp: flags.isIntegratorTp,
      isPreIntegratorTp: flags.isPreIntegratorTp
    },
    score: flags.score || 0,
    toSoftScore: status?.toSoftScore || '',
    locked,
    alias,
    chat,
    timeline,
    relatedStudents: relatedStudents || []
  }

  return challengeData
}

interface IDeliverLink {
  class: string
  student: string
  stage: string
  module: string
  link: string
  flagDeliverAfterExpire: boolean
  _id?: string
}

/**
 * API to create/update a student's deliver
 * @param courseId current course's id
 * @param project object containing the project data
 * @returns project data created/updated
 * Create => @see [Postman]{@link https://coderplatformers.postman.co/workspace/Coder-House-Platform~9b3cd88b-81e2-4307-8432-95267b456aa7/request/17745020-2f684873-d372-4471-98e2-bba6abafd15b }
 * Update => @see [Postman]{@link https://coderplatformers.postman.co/workspace/Coder-House-Platform~9b3cd88b-81e2-4307-8432-95267b456aa7/request/17745020-8b3f6114-9050-45b3-8fd1-bcce5ba04ec1 }
 */

export const postDeliverLink = async (project: IProjectDeliver, courseId: string): Promise<any> => {
  const body: IDeliverLink = {
    student: project.userId,
    class: project.classId,
    stage: project.stage,
    module: project.module,
    link: project.link,
    flagDeliverAfterExpire: false
  }
  try {
    if (project._id) {
      body._id = project._id
      return await Api.put(`${API_ENDPOINT}/platform/courses/${courseId}/student/${project.userId}/projects/${project._id}`, body)
    } else {
      return await Api.post(`${API_ENDPOINT}/platform/courses/${courseId}/student/${project.userId}/projects`, body)
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error, 'error api deliver link')
  }
}

interface IMessage {
  from: string
  richText: boolean
  text: string
  link: string
  file?: string
  date: number
}

/**
 * API to send a message to the chat
 * @param userId Logged user id
 * @param courseId current course's id
 * @param projectId project id to associate the message
 * @param isRichText boolean to specify if the message is using the rich text component
 * @param message text containing the message to be send
 * @param link url to access the project or the url of the project where is hosted if it was uplodead as a file
 * @param file (optional) url of the project where is hosted if it was uplodead as a file
 * @returns project data with array of chat updated
 * @see [Postman]{@link https://coderplatformers.postman.co/workspace/Coder-House-Platform~9b3cd88b-81e2-4307-8432-95267b456aa7/request/17745020-2660f9e7-113c-4cbf-ac4f-c69acc2b3171 }
 */

export const postDeliverMessage = async (
  userId: string,
  courseId: string,
  projectId: string,
  isRichText: boolean,
  message: string,
  link: string,
  file?: string,
  date = Date.now()
): Promise<any> => {
  const body: IMessage = {
    from: userId,
    richText: isRichText,
    text: message,
    link,
    date
  }
  if (file) body.file = file
  return await Api.post(`${API_ENDPOINT}/platform/courses/${courseId}/projects/${projectId}/message`, body)
}

/**
 * API request to upload a file
 * @param userId current logged user
 * @param file file object to be uploaded
 * @fileName unique file name associated to the deliver and student
 * @returns object containg the url where the file can be access
 */

export const postDeliverFile = async (userId: string, file: File, fileName: string): Promise<any> => {
  const formData = new window.FormData()
  const body = { userId, file, prefix: fileName }
  Object.entries(body).map(([k, v]) => formData.append(k, v))
  try {
    return await Api.post(`${API_ENDPOINT}/platform/upload/files`, formData, null, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error, 'error api upload file')
  }
}

/**
 * API request to update the project status to unreviewed
 * @param courseId current course's id
 * @param projectId project id to update the status
 * @body object containing current courseId and project id to be updated
 * @returns course's modules and student's data and delivers
 * @see [Postman]{@link https://coderplatformers.postman.co/workspace/Coder-House-Platform~9b3cd88b-81e2-4307-8432-95267b456aa7/request/17745020-375cca3a-8aa6-4018-b006-696f79f6a924}
 */

export const unreviewProject = async (courseId: string, projectId: string, body: { courseId: string; projectId: string }) => {
  return await Api.put(`${API_ENDPOINT}/platform/courses/${courseId}/projects/${projectId}/unreviewed`, body)
}

/**
 * API request to approve a certain project
 * @param courseId current course's id
 * @param projectId project id to update the status
 * @body object containing project data such as courseId, projectId, score and soft score
 * @returns project data updated
 * @see [Postman]{@link https://coderplatformers.postman.co/workspace/Coder-House-Platform~9b3cd88b-81e2-4307-8432-95267b456aa7/request/17745020-03dddb50-c26f-467c-a64e-fbb95f9d074d4}
 */

export const approveProject = (courseId: string, projectId: string, body: TDeliverReview) => {
  return Api.put(`${API_ENDPOINT}/platform/courses/${courseId}/projects/${projectId}/approve`, body)
}

/**
 * API request to disapprove a certain project
 * @param courseId current course's id
 * @param projectId project id to update the status
 * @param body object containing project data such as courseId, projectId, score and soft score
 * @returns project data updated
 */

export const disapproveProject = (courseId: string, projectId: string, body: TDeliverReview) => {
  return Api.put(`${API_ENDPOINT}/platform/courses/${courseId}/projects/${projectId}/disapprove`, body)
}

/**
 * API request to lock a certain project
 * @param deliver object containing project data such as courseId, projectId
 * @returns project data updated
 * @see [Postman]{@link https://coderplatformers.postman.co/workspace/Coder-House-Platform~9b3cd88b-81e2-4307-8432-95267b456aa7/request/17745020-dbb83eee-ceb6-4a20-a0db-c0fe4740b069}
 */

export const lockDeliver = (deliver: IDeliverLock) => {
  return Api.put(
    `${API_ENDPOINT}/platform/courses/${deliver.courseId}/projects/${deliver.projectId}/lock?ts=${Date.now()}`,
    deliver
  )
}

/**
 * API request to unlock a certain project for period of 48 hours
 * @param deliver object containing project data such as courseId, projectId
 * @returns project data updated with the new deadline
 * @see [Postman]{@link https://coderplatformers.postman.co/workspace/Coder-House-Platform~9b3cd88b-81e2-4307-8432-95267b456aa7/request/17745020-15a03220-6a12-476c-a42b-9edecbe5b8b1}
 */

export const unLockDeliver = (deliver: IDeliverLock): Promise<IDeliver> => {
  return Api.put(
    `${API_ENDPOINT}/platform/courses/${deliver.courseId}/projects/${deliver.projectId}/unlock?ts=${Date.now()}`,
    deliver
  )
}

/**
 * API request update the message status to viewed
 * @param deliver object containing project data such as courseId, projectId, teacherId, chatId and timestamp
 * @returns message with the viewed status updated
 * @see [Postman]{@link https://coderplatformers.postman.co/workspace/Coder-House-Platform~9b3cd88b-81e2-4307-8432-95267b456aa7/request/17745020-5e151d23-46d0-46cb-b8ff-335b515915c1}
 */

export const messageViewed = (deliver: DeliverMessage) => {
  return Api.put(
    `${API_ENDPOINT}/platform/courses/${deliver.courseId}/teacher/${deliver.teacherId}/projects/${deliver.projectId}/chat/${deliver.chatId}/viewed`,
    deliver
  )
}

/**
 * API request update the message status to unviewed
 * @param deliver object containing project data such as courseId, projectId, teacherId, chatId and timestamp
 * @returns message with the viewed status updated
 * @see [Postman]{@link https://coderplatformers.postman.co/workspace/Coder-House-Platform~9b3cd88b-81e2-4307-8432-95267b456aa7/request/17745020-aa578fa6-fe13-4cfd-bc46-a32dfa4ac55f}
 */

export const messageUnviewed = (deliver: DeliverMessage) => {
  return Api.put(
    `${API_ENDPOINT}/platform/courses/${deliver.courseId}/teacher/${deliver.teacherId}/projects/${deliver.projectId}/chat/${deliver.chatId}/unviewed`,
    deliver
  )
}

/**
 * API call to get link for re enable expired deliver or feedback
 * @param userId `required` loged user id
 * @param courseId `required` current course
 * @param scheduleId `required` schedule to re enable
 * @param flag `required` feedback or deliver
 * @returns url
 */
export const getScheduleReEnableLink = async (
  userId: string,
  courseId: string,
  scheduleId: string,
  flag: 'feedback' | 'deliver'
): Promise<string> => {
  const ts = new Date().toISOString().slice(0, 15)

  const { link } = await Api.get(
    `${API_ENDPOINT}/platform/courses/${courseId}/schedules/${scheduleId}/reenable?courseId=${courseId}&flag=${flag}&scheduleId=${scheduleId}&studentId=${userId}&ts=${ts}`
  )

  return link
}
