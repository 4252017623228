
// Vendor
import { defineComponent, onMounted, ref, PropType, watch } from 'vue'
import Button from 'primevue/button'
import { isSupported } from 'firebase/messaging'

// Components
import Notification from './Notification.vue'
import CardPlaceholder from '@/components/aero/surfaces/CardPlaceholder.vue'
import PlaceholderState from '@/components/aero/surfaces/challenges/PlaceholderState.vue'
import CustomTransition from '@/components/aero/misc/CustomTransition.vue'

// Stores
import { useUserSessionStore } from '@/store/modules/user-session.store'
import { useNotificationStore } from '@/store/modules/notifications.store'

// Models
import { INotification } from '@/models/navigation/notifications'

// Services
import { markAsReadNotificationService, markAllAsReadNotificationService } from '@/services/newPlatform/navigation.services'
import { getTokenFCM } from '@/services/newPlatform/firebase.services'

import copies from '@/locales/navigation/es.json'

// Lottie
import lottie, { AnimationItem } from 'lottie-web'
import bellAnimation from '@/assets/bell-notification.json'

// Amplitude
import { trackEvent } from '@/amplitude/actions'
import { NotificationsEvents } from '@/amplitude/constants'

export default defineComponent({
  components: { Notification, CardPlaceholder, Button, PlaceholderState, CustomTransition },
  props: {
    notifications: { type: Array as PropType<INotification[]>, required: true },
    error: { type: Boolean, default: false }
  },
  setup(props) {
    const notificationPanelRef = ref()
    const localNotifications = ref<Array<INotification>>(props.notifications)
    const showNotificationPanel = ref<boolean>(false)
    const hasNotifications = ref<boolean>(props.notifications.length > 0)

    const notificationStore = useNotificationStore()

    const { userId } = useUserSessionStore()

    const bellNotification = ref<AnimationItem>()

    const loadLotties = () => {
      const bellDOM = document.getElementById('bell-notification')
      if (bellDOM) {
        bellNotification.value = lottie.loadAnimation({
          container: bellDOM,
          renderer: 'svg',
          loop: false,
          autoplay: false,
          animationData: bellAnimation
        })
      }
    }

    const animNewNotification = () => {
      bellNotification.value?.setSpeed(1)
      if (localNotifications.value.length === 1) {
        bellNotification.value?.playSegments([3, 88], true)
      } else {
        bellNotification.value?.playSegments([120, 205], true)
      }
    }

    // hover in - has notification
    // hover in - 0 notifications
    const animHoverIn = () => {
      bellNotification.value?.setSpeed(0.5)
      if (hasNotifications.value) {
        bellNotification.value?.playSegments([237, 261], true)
        bellNotification.value?.stop()
      } else {
        bellNotification.value?.playSegments([440, 464], true)
      }
    }

    // hover out - has notification
    // hover out - 0 notifications
    const animHoverOut = () => {
      bellNotification.value?.setSpeed(0.5)

      if (hasNotifications.value) {
        bellNotification.value?.playSegments([293, 267], false)
      } else {
        bellNotification.value?.playSegments([496, 520], false)
      }
    }

    // mark all as read
    const animClearNotifications = () => {
      bellNotification.value?.playSegments([349, 408], true)
    }

    // watch for animations
    watch(notificationStore, () => {
      if (notificationStore.hasNewNotification) {
        animNewNotification()
      }
    })

    watch(hasNotifications, () => {
      if (!hasNotifications.value) animClearNotifications()
    })

    const toggleNotificationPanel = (e: Event) => {
      showNotificationPanel.value = !showNotificationPanel.value

      if (showNotificationPanel.value) trackEvent(NotificationsEvents.OPENED_NOTIFICATION_INBOX)

      e.stopPropagation()
    }

    const handleCloseNotificationPanel = () => {
      showNotificationPanel.value = false
    }

    const markAsReadAllNotifications = async () => {
      const notificationsToMark: INotification[] = [...localNotifications.value]

      localNotifications.value = []
      hasNotifications.value = false

      await markAllAsReadNotificationService(userId, notificationsToMark)
    }

    const markAsReadNotification = async (notificationId: string) => {
      const newLocalNotifications = localNotifications.value.filter((n) => n._id !== notificationId)

      localNotifications.value = newLocalNotifications
      hasNotifications.value = newLocalNotifications?.length > 0

      await markAsReadNotificationService(userId, notificationId)
    }

    const handleClick = ({ id, closePanel }: { id: string; closePanel: boolean }) => {
      trackEvent(NotificationsEvents.CLICKED_ON_NOTIFICATION)

      markAsReadNotification(id)
      closePanel && handleCloseNotificationPanel()
    }

    const requestPermission = async () => {
      try {
        /* FCM is not supported on Safari
         * @see https://firebase.google.com/docs/web/environments-js-sdk#browsers
         */
        const FCMisSupported = await isSupported()
        if (FCMisSupported) {
          const granted =
            window.Notification.permission === 'granted' || (await window.Notification.requestPermission()) === 'granted'

          if (granted) {
            await getTokenFCM(userId)
          } else {
            // eslint-disable-next-line no-console
            console.log('Denied permissions')
          }
        } else {
          // eslint-disable-next-line no-console
          console.log('FCM is not supported on this platform')
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(`Error getting permission for Web Push Messaging: ${e}`)
      }
    }

    onMounted(() => {
      requestPermission()

      loadLotties()
      if (hasNotifications.value) animNewNotification()

      document.addEventListener(
        'click',
        (e) => {
          e.stopPropagation()
          const composed = e.composedPath()
          const clickOutside = composed.some((el) => el === notificationPanelRef.value)

          if (!clickOutside) {
            handleCloseNotificationPanel()
          }
        },
        false
      )

      // resets new notification to false
      bellNotification.value?.addEventListener('complete', notificationStore.clearState)
    })

    return {
      toggleNotificationPanel,
      showNotificationPanel,
      notificationPanelRef,
      handleCloseNotificationPanel,
      hasNotifications,
      handleClick,
      localNotifications,
      markAsReadAllNotifications,
      copies,
      animHoverIn,
      animHoverOut
    }
  }
})
