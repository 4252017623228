
// Vendor
import { defineComponent, ref, onMounted, computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { DateTime } from 'luxon'

import esLang from '@/locales/classes/es.json'

// Components
import ClassesList from './components/ClassesList.vue'
import TwoColsLayout from '@/components/aero/layout/TwoColsLayout.vue'
import BecaCard from '@/components/aero/surfaces/classes/BecaCard.vue'
import DateCard from '@/components/aero/surfaces/classes/DateCard.vue'
import FormAfterClass from '@/components/aero/forms/FormCreateAfterclass.vue'
import AlertBanner from '@/components/aero/feedback/AlertBanner.vue'
import Dialog from 'primevue/dialog'
import Toast from '@/components/aero/feedback/Toast.vue'
import ErrorPage from '@/components/aero/dataDisplay/ErrorPage.vue'
import CardPlaceholder from '@/components/aero/surfaces/CardPlaceholder.vue'
import { useConfirm } from 'primevue/useconfirm'
import ConfirmDialog from 'primevue/confirmdialog'
import CustomTransition from '@/components/aero/misc/CustomTransition.vue'

// Theme
import { themeStore } from '@/store/modules/themes.store'

// Services
import { getClassesData, TGetClases, getFeedbackLink } from '@/services/classes.services'
import { deleteAfterclass } from '@/api/classes.api'
import { getScheduleReEnableLink } from '@/services/challenges.services'

// Store
import { useUserSessionStore } from '@/store/modules/user-session.store'
import { useCourseStore } from '@/store/modules/course.store'

import SkeletonDateCard from '@/components/aero/skeleton/SkeletonDateCard.vue'
import SkeletonBecaCard from '@/components/aero/skeleton/SkeletonBecaCard.vue'

// Models
import { IClass } from '@/models/classes/class'
import { IProjectProgress } from '@/models/classes/projectProgress'

// Amplitude
import { ClassesEvent } from '@/amplitude/constants'
import { trackEvent } from '@/amplitude/actions'

// Toast hook for Composition API
import { useToast } from 'primevue/usetoast'

export default defineComponent({
  components: {
    ErrorPage,
    TwoColsLayout,
    BecaCard,
    DateCard,
    FormAfterClass,
    SkeletonDateCard,
    SkeletonBecaCard,
    ClassesList,
    Dialog,
    AlertBanner,
    Toast,
    CardPlaceholder,
    ConfirmDialog,
    CustomTransition
  },
  setup() {
    const session = useUserSessionStore()
    const theme = themeStore()
    theme.changeDefault('aero')

    const course = useCourseStore()
    const courseId = course.id
    const camadaNro = course.camadaNro

    const confirm = useConfirm()
    const toast = useToast()

    const isCourseEnded = ref<boolean>(false)
    const thisWeek = ref<Array<IClass>>([])
    const rol = ref<number>(0)
    const endDate = ref<Date>(new Date())
    const hasBeca = ref<boolean>(false)
    const becaSuspended = ref<boolean>(false)
    const absents = ref({
      maxAmount: 0,
      completed: 0,
      perc: 0,
      flags: {
        warningMaxAmountReached: false,
        alertMaxAmountSurpassed: false
      }
    })
    const attendance = ref({})
    const classes = ref<Array<IClass>>([])
    const classesForBeca = ref<Array<IClass>>([])
    const afterClasses = ref<Array<IClass>>([])

    const projectProgress = ref<Array<IProjectProgress>>([])

    const showForm = ref<boolean>(false)
    const stageAfterclass = ref<string>('')
    const complementaryId = ref<string>('')
    const isLoading = ref<boolean>(true)
    const isError = ref<boolean>(false)
    const displayDialog = ref<boolean>(false)
    const pendingDelete = ref<boolean>(false)

    const route = useRoute()
    const router = useRouter()

    const copies = ref<any>(esLang)

    const setThisWeek = computed(() => {
      const classesList = [...classes.value, ...afterClasses.value]
      if (classesList[0].schedule?.day > Date.now()) {
        return classesList.slice(0, 2)
      } else {
        return classesList
          .filter(
            (_class) =>
              DateTime.fromMillis(_class.schedule?.day || 0) > DateTime.local().startOf('week') &&
              DateTime.fromMillis(_class.schedule?.day || 0) < DateTime.local().endOf('week')
          )
          .sort((_a, _b) => _a.schedule?.day - _b.schedule?.day)
      }
    })

    const setThisWeekTitle = computed(() => {
      const firstClass = classes.value[0].schedule?.day
      const lastClass = classes.value.slice(-1)[0]
      if (firstClass && firstClass > Date.now()) {
        return copies.value.thisWeek.firstWeekTitle
      } else if (
        DateTime.fromMillis(lastClass?.schedule?.day || 0) > DateTime.local().startOf('week') &&
        DateTime.fromMillis(lastClass?.schedule?.day || 0) < DateTime.local().endOf('week')
      ) {
        return copies.value.thisWeek.lastWeekTitle
      } else if (lastClass?.schedule?.day < Date.now()) {
        return ''
      } else {
        return copies.value.thisWeek.title
      }
    })

    const handleMountedComponent = async () => {
      isError.value = false
      const { mockdata, order } = route.query
      const rolParam = route.query.rol

      const getClasesDataprops: TGetClases = {
        returnMockData: !!mockdata,
        rol: `${rolParam}`,
        order: Number(order),
        userId: session.userId,
        camadaId: course.camadaNro
      }

      try {
        const response = await getClassesData(getClasesDataprops)

      if (response) isLoading.value = false
        hasBeca.value = response.user.beca.hasBeca
        becaSuspended.value = response.user.progress.absents.flags.alertMaxAmountSurpassed
        rol.value = response.user.profile.role
        endDate.value = response.endDate
        classes.value = response.content.classes.classesList
        classesForBeca.value = response.content.classes.classesList

        afterClasses.value = response.content.classes.complementarySchedules

        isCourseEnded.value = classes.value.slice(-1)[0].schedule.day < Date.now()

        // set absents, attendance and projectProgress only if the role is a student
        if (rol.value === 2) {
          absents.value = response.user.progress.absents
          attendance.value = response.user.progress.attendance
          projectProgress.value = response.content.classes.projectProgress
        }
      } catch {
        isLoading.value = false
        isError.value = true
        hasBeca.value = false
        becaSuspended.value = false
        rol.value = 0
        endDate.value = new Date()
        classes.value = []
        classesForBeca.value = []
        afterClasses.value = []
      }
    }

    onMounted(handleMountedComponent)

    watch(course, () => {
      isLoading.value = true
      handleMountedComponent()
    })

    // Show error toast when after class could not be created properly
    const displayError = () => {
      const {
        toast: {
          afterClass: { error },
          toastError: { severity },
          group,
          life
        }
      } = copies.value
      toast.add({
        severity,
        detail: error,
        group,
        life
      })
    }

    // Display success toast when after class was created successfully
    const displaySuccess = () => {
      const {
        toast: {
          afterClass: { success },
          toastSuccess: { severity },
          group,
          life
        }
      } = copies.value
      toast.add({ severity, detail: success, group, life })
    }

    // Display success toast when after class was edited successfully

    const displayEditedSuccess = () => {
      const {
        toast: {
          afterClass: { editedSuccess },
          toastSuccess: { severity },
          group,
          life
        }
      } = copies.value
      toast.add({ severity, detail: editedSuccess, group, life })
    }

    const handleOpenForm = () => {
      showForm.value = true
    }

    const handleCloseForm = () => {
      showForm.value = false
      complementaryId.value = ''
      stageAfterclass.value = ''
    }

    const handleAfterClassValue = (stageId: string, afterclassId = '') => {
      stageAfterclass.value = stageId
      complementaryId.value = afterclassId
    }

    const onSubmit = (afterClass: any, edited: boolean) => {
      const formatedDay = typeof afterClass.day === 'string' ? DateTime.fromISO(afterClass.day).toMillis() : afterClass.day
      const newAfterClassLocal = ref<IClass>({
        _id: afterClass._id,
        name: afterClass.title,
        flags: {
          isFree: false,
          isLeveling: false,
          isPlaybook: false,
          isStarter: false,
          isWorkshop: false,
          normalized: false,
          enable: false,
          hasDelivers: false,
          isFinalDeliver: false,
          isLocked: false,
          isLast: false
        },
        schedule: {
          _id: afterClass._id,
          stage: afterClass.stage,
          isAdditional: true,
          day: formatedDay,
          from: DateTime.fromISO(afterClass.from).toMillis(),
          to: DateTime.fromISO(afterClass.to).toMillis(),
          enable: true
        },
        videoLink: {
          liveVideoLink: afterClass.liveVideoLink,
          recordingVideoLink: ''
        },
        isUserAbsent: false
      })

      if (edited) {
        // search the old afterclass and delete it
        const tempList = afterClasses.value.filter((afterC) => afterC._id !== afterClass._id)
        // update the afterclass list with the edited afterclass
        afterClasses.value = [...tempList, newAfterClassLocal.value]
        // Show edited successfully toast
        displayEditedSuccess()
      } else {
        afterClasses.value = [...afterClasses.value, newAfterClassLocal.value]
        // Show successfully created after class toast
        displaySuccess()
      }
    }

    const handleOpenDialog = () => {
      displayDialog.value = true
    }

    const handleEditAfterclass = () => {
      handleOpenForm()
      displayDialog.value = false
      trackEvent(ClassesEvent.EDIT_AFTERCLASS, { origin: 'confirmation delete dialog' })
    }

    const handleDeleteAfterclass = async () => {
      try {
        pendingDelete.value = true
        await deleteAfterclass(courseId, complementaryId.value)
        afterClasses.value = afterClasses.value.filter((afterC) => afterC._id !== complementaryId.value)
        trackEvent(ClassesEvent.DELETE_AFTERCLASS, { origin: 'confirmation delete dialog' })
        // notify success
      } catch (e) {
        // notify error
      } finally {
        pendingDelete.value = false
        displayDialog.value = false
        complementaryId.value = ''
        stageAfterclass.value = ''
      }
    }

    const handleEnableRate = (c: IClass) => {
      confirm.require({
        message: 'Tenés 30 min para valorar esta clase. Tené en cuenta que ya no va a sumar puntos para el Top 10',
        header: '¿Querés habilitar la valoración de esta clase?',
        acceptLabel: 'Habilitar',
        rejectLabel: 'Cancelar',
        rejectClass: 'btn-reject',
        accept: async () => {
          const userId = session.userId
          const scheduleId = c.schedule._id
          const flag = 'feedback'
          const enableFeedback: string = await getScheduleReEnableLink(userId, courseId, scheduleId, flag)
          if (enableFeedback) {
            const link = await getFeedbackLink(course.camadaNro, scheduleId, userId)
            window.open(link, '_blank')
          } else {
            toast.add({ severity: 'error', summary: 'Error', detail: 'No habilitaste el desafío', life: 3000 })
          }
        }
      })
    }

    // Banner buttons when beca is lost
    const changeComision = () => {
      router.push(`/profile/change-course/${course.camadaNro}`)
    }

    return {
      courseId,
      camadaNro,
      copies,
      isLoading,
      isError,
      hasBeca,
      absents,
      attendance,
      becaSuspended,
      classes,
      classesForBeca,
      afterClasses,
      projectProgress,
      rol,
      endDate,
      thisWeek,
      showForm,
      handleOpenForm,
      handleCloseForm,
      handleAfterClassValue,
      stageAfterclass,
      complementaryId,
      onSubmit,
      displayDialog,
      pendingDelete,
      handleDeleteAfterclass,
      handleEditAfterclass,
      handleOpenDialog,
      setThisWeek,
      setThisWeekTitle,
      displayError,
      displaySuccess,
      isCourseEnded,
      handleEnableRate,
      changeComision
    }
  }
})
