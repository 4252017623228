import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-864b9e5a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-d-flex p-jc-between header-container" }
const _hoisted_2 = { class: "p-d-flex p-ai-center" }
const _hoisted_3 = { class: "" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "overlaypanel-content" }
const _hoisted_6 = { class: "p-field-checkbox p-d-flex p-pl-lg-3" }
const _hoisted_7 = {
  for: "termsCheck",
  class: "terms text"
}
const _hoisted_8 = ["href"]
const _hoisted_9 = {
  key: 0,
  class: "terms-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.modal,
    "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modal) = $event)),
    style: {width: '744px'},
    modal: true,
    closable: false
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('editProfile.termsModal.warning')), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("img", {
            class: "close-modal",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal())),
            src: require('@/assets/icons/Light_X.svg'),
            alt: "Close modal"
          }, null, 8, _hoisted_4)
        ])
      ])
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: _ctx.$t('editProfile.termsModal.cancelButton'),
        onClick: _ctx.closeModal,
        class: "close-button"
      }, null, 8, ["label", "onClick"]),
      _createVNode(_component_Button, {
        label: _ctx.$t('editProfile.termsModal.publishButton'),
        onClick: _ctx.publishProfile
      }, null, 8, ["label", "onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_Checkbox, {
            id: "termsCheck",
            modelValue: _ctx.checked,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.checked) = $event)),
            binary: "true",
            class: "terms-check"
          }, null, 8, ["modelValue"]),
          _createElementVNode("label", _hoisted_7, [
            _createTextVNode(_toDisplayString(_ctx.$t('editProfile.termsModal.accept')) + " ", 1),
            _createElementVNode("a", {
              class: "terms link",
              target: "_blank",
              href: `${_ctx.TALENT_URL}legales-coder-talento`
            }, _toDisplayString(_ctx.$t('editProfile.termsModal.termsConditions')), 9, _hoisted_8)
          ])
        ]),
        (_ctx.validationError)
          ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.$t('editProfile.termsModal.error')), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}