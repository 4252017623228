const ordinalTextMapping = [
  // units
  ['','Primero','Segundo','Tercero','Cuarto','Quinto','Sexto','Séptimo','Octavo','Noveno'],
  // tens
  ['','Décimo','Vigésimo','Trigésimo','Cuadragésimo','Quincuagésimo','Sexagésimo','Septuagésimo','Octagésimo','Nonagésimo'],
  // hundreds
  ['','Centésimo','Ducentésimo','Tricentésimo','Cuadrigentésimo','Quingentésimo','Sexcentésimo','Septingentésimo','Octingentésimo','Noningentésimo'],
  // thousandths
  ['','Milésimo','Dosmilésimo','Tresmilésimo ','Cuatromilésimo','Cincomilésimo','Seismilésimo','Sietemilésimo','Ochomilésimo','Nuevemilésimo']
]

export const toOrdinal = (number = 0, female = false) => {
  let ordinal = ''
  const digits = [...number.toString()]
  digits.forEach((digit, i) => {
    let digitOrdinal = ordinalTextMapping[digits.length - i - 1][parseInt(digit)]
    if (!digitOrdinal) return
    if (female) digitOrdinal = digitOrdinal.substr(0, digitOrdinal.length-1) + 'a'
    ordinal += digitOrdinal + ' '
  })
  return ordinal.trim()
}
