import { useUserSessionStore } from '@/store/modules/user-session.store'
import { usePersonStore } from '@/store/modules/person.store'
import { useLocaleStore } from '@/store/modules/locale.store'
import { useUserStore } from '@/store/modules/user.store'
import { useCourseStore } from '@/store/modules/course.store'
import { createRouter, createWebHistory, RouteLocationNormalized, RouteRecordRaw } from 'vue-router'
import { h, resolveComponent } from 'vue'

import Home from '@/views/home/Home.vue'
import JobOffersRoutes from '@/views/jobOffer/routes'
import { PageTitle, NewPlatform, Names, CODERHOUSE_PLATFORM } from '@/router/route-names'
import PeerReviewRoutes from '@/views/peerReview/routes'
import ChallengesRoutes from '@/views/challenges/routes'
import ProfileRoutes from '@/views/profile/routes'
import RankingRoutes from '@/views/ranking/routes'
import RatingRoutes from '@/views/ratings/routes'
import Chat from '@/views/chat/Chat.vue'
import Classes from '@/views/classes/Classes.vue'
import Dashboard from '@/views/dashboard/Dashboard.vue'
import Commission from '@/views/commission/Commission.vue'
import Attendance from '@/views/attendance/Attendance.vue'
import EmptyLayout from '@/layouts/EmptyLayout.vue'
import Login from '@/views/Login.vue'
import Error from '@/views/Error.vue'
import PasswordChange from '@/views/PasswordChange.vue'
import OnboardingRoutes from '@/views/onboarding/routes'
import VideotutorialsRoutes from '@/views/videotutorials/routes'

import { inIframe } from '@/utils/iframe'

import { getCourse } from '@/api/newPlatform/course.api'

// Amplitude
import { trackPageView } from '@/amplitude/actions'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: EmptyLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/home',
        name: 'Home',
        component: Home
      },
      {
        path: '/jobs',
        name: 'jobOffers',
        component: {
          name: 'JobOffersRoutes',
          render: () => h(resolveComponent('router-view'))
        },
        children: JobOffersRoutes
      },
      {
        path: '/classes',
        alias: ['/cursos/:camadaId/:productSlug', '/cursos/:camadaId/:productSlug/clases'],
        name: NewPlatform.CLASSES,
        component: Classes
      },
      {
        path: '/commission',
        name: NewPlatform.COMMISSION,
        component: Commission
      },
      {
        path: '/attendance',
        name: NewPlatform.ATTENDANCE,
        component: Attendance
      },
      {
        path: '/dashboard',
        name: NewPlatform.DASHBOARD,
        component: Dashboard
      },
      {
        path: '/challenges',
        name: 'challenges',
        alias: ['/cursos/:camadaId/:productSlug/desafios', '/cursos/:camadaId/:productSlug/entregas'],
        component: {
          name: 'challengesRoutes',
          render: () => h(resolveComponent('router-view'))
        },
        children: ChallengesRoutes
      },
      {
        path: '/ranking',
        name: 'ranking',
        component: {
          name: 'rankingRoutes',
          render: () => h(resolveComponent('router-view'))
        },
        children: RankingRoutes
      },
      {
        path: '/profile',
        name: 'profile',
        alias: ['/profile/:userId'],
        component: {
          name: 'ProfileRoutes',
          render: () => h(resolveComponent('router-view'))
        },
        children: ProfileRoutes
      },
      {
        path: '/chat',
        alias: ['/chat/:chatId'],
        name: NewPlatform.CHAT,
        component: Chat
      },
      {
        path: '/reviews/:courseId/:homeworkId/:homeworkPlanId',
        name: 'peerReview',
        component: {
          name: 'peerReviewRoutes',
          render: () => h(resolveComponent('router-view'))
        },
        children: PeerReviewRoutes
      },
      {
        path: '/ratings',
        name: 'ratings',
        component: {
          name: 'RatingRoutes',
          render: () => h(resolveComponent('router-view'))
        },
        children: RatingRoutes
      },
      {
        path: '/onboarding',
        name: 'onboarding',
        component: {
          name: 'OnboardingRoutes',
          render: () => h(resolveComponent('router-view'))
        },
        children: OnboardingRoutes
      },
      {
        path: '/videotutorials',
        name: 'videotutorials',
        component: {
          name: 'VideotutorialsRoutes',
          render: () => h(resolveComponent('router-view'))
        },
        children: VideotutorialsRoutes
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/cambiar-contrasena',
    name: 'PasswordChange',
    component: PasswordChange
  },
  {
    path: '/error',
    name: 'Error',
    component: Error
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const handleChangeTitle = (to: string) => {
  const courseFromStore = useCourseStore()
  const name: Names = to as Names
  const course = courseFromStore.name || CODERHOUSE_PLATFORM
  const title = to ? `${PageTitle[name]}: ${course}` : course

  document.title = title
}

const handleTrackPageView = (to: string) => {
  const name: Names = to as Names
  trackPageView(name)
}

const hasQueryParams = (route: RouteLocationNormalized) => {
  return !!Object.keys(route.query).length
}

router.beforeEach(async (to, from, next) => {
  const session = useUserSessionStore()
  const person = usePersonStore()
  const locale = useLocaleStore()
  const hideTranslations = to.query.hideTranslations === 'true'

  if (process.env.MOCK_USER === '1') {
    const user = useUserStore()
    await user.getUser()

    handleChangeTitle(String(to.name))
    handleTrackPageView(String(to.name))

    next()
  }

  if (session.isAuthenticated) {
    await person.getPerson()
    if (!hideTranslations) await locale.fetchLocale()

    handleChangeTitle(String(to.name))
    handleTrackPageView(String(to.name))

    if (!hasQueryParams(to) && hasQueryParams(from)) {
      next({ ...to, query: from.query })
    } else {
      next()
    }
  } else if (to.name === 'Login') {
    next()
  } else {
    if (inIframe()) {
      try {
        await session.login()
        await person.getPerson()
        if (!hideTranslations) await locale.fetchLocale()

        const user = useUserStore()
        await user.getUser()

        const pathname = window.location.pathname.slice(1).split('/')

        if (pathname[0] === 'cursos') {
          const courseFromStore = useCourseStore()
          const { course } = await getCourse(session.userId, pathname[1])

          courseFromStore.setCourse({
            camadaNro: pathname[1],
            id: course._id,
            camadaId: '',
            name: '',
            leaderboard: [],
            isEnded: false,
            isStarted: false,
            isInProgress: false,
            hasCoderAsk: course.flags.hasCoderAsk,
            hasCoderAskActive: course.flags.hasCoderAskActive
          })
        }

        if (!hasQueryParams(to) && hasQueryParams(from)) {
          next({ ...to, query: from.query })
        } else {
          next()
        }
      } catch (error) {
        next({
          path: '/error'
        })
      }
    } else {
      next({
        path: '/login'
      })
    }
  }
})

export default router
