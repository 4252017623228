import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a58fca18"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "main-layout"
}
const _hoisted_2 = { class: "sidebar-wrapper" }
const _hoisted_3 = { class: "main-content-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_side_bar = _resolveComponent("side-bar")!
  const _component_header_component = _resolveComponent("header-component")!

  return (_ctx.showNavigation)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_side_bar, {
            isOpenMenu: _ctx.isOpenMenu,
            onCloseMenu: _ctx.handleCloseMenu
          }, null, 8, ["isOpenMenu", "onCloseMenu"])
        ]),
        _createElementVNode("div", {
          class: "inner-wrapper",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleCloseMenu && _ctx.handleCloseMenu(...args)))
        }, [
          _createVNode(_component_header_component, { onOpenMenu: _ctx.handleOpenMenu }, null, 8, ["onOpenMenu"]),
          _createElementVNode("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ])
        ])
      ]))
    : _renderSlot(_ctx.$slots, "default", { key: 1 }, undefined, true)
}