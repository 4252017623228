
import { computed, defineComponent, PropType, ref } from 'vue'

// PrimeVue components
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'

// Models
import { IUser as UserInfo } from '@/models/user'
import { City } from '@/models/profile/profile'

// Components
import Card from '@/components/aero/surfaces/Card.vue'
import Toast from '@/components/aero/feedback/Toast.vue'
import ButtonIcon from '@/components/aero/buttons/ButtonIcon.vue'
import SocialNetworkIcon from '@/components/aero/icon/templates/SocialNetworkIcon.vue'
import Icon from '@/components/aero/icon/Icon.vue'
import { Camera } from '@/components/aero/icon/templates'
import SkeletonIcon from '@/components/aero/skeleton/SkeletonIcon.vue'
import Avatar from '@/components/aero/dataDisplay/Avatar.vue'

// Store
import { useUserStore } from '@/store/modules/user.store'

// Hooks
import { useToast } from 'primevue/usetoast'

// Utils
import { parseGoal } from '@/utils/stringParser'

// Copies
import copies from '@/locales/profile/es.json'

// Services
import { changePasswordService, updatePicture } from '@/services/newPlatform/profile.services'
import { ToastMessageOptions } from 'primevue/toast'

export default defineComponent({
  components: {
    Avatar,
    Button,
    Card,
    Dialog,
    Toast,
    ButtonIcon,
    SocialNetworkIcon,
    Icon,
    Camera,
    SkeletonIcon
  },
  props: {
    information: { type: Object, required: true },
    user: { type: Object as PropType<UserInfo>, required: true },
    course: { type: String, required: true },
    editingProfile: { type: Boolean, required: true },
    isVisitorView: { type: Boolean, default: false }
  },
  emits: ['onClickEdit'],
  setup(props, { emit }) {
    const userStore = useUserStore()
    const toast = useToast()

    const loadingUpdateImage = ref(false)

    const changePasswordRefs = ref<{ dialogOpen: boolean; loading: boolean }>({
      dialogOpen: false,
      loading: false
    })

    const socialNetworksToShow = computed(() =>
      Object.entries(props.information.socialNetworks || {})
        .filter(([_name, value]) => value)
        .map(([name, link]) => ({ name, link }))
    )

    const parseCity = (cityV2: string | City) => {
      if (typeof cityV2 === 'string') return cityV2
      if (typeof cityV2 === 'object') {
        return `${cityV2?.address_components[1]?.long_name}, ${cityV2?.address_components[2]?.long_name}`
      }
      return false
    }

    const parsedInformation = computed(() =>
      Object.entries(props.information).reduce((acc, [key, value]) => {
        if (key === 'socialNetworks') return acc

        let currVal
        if (key === 'personalGoal' && typeof value === 'string') {
          currVal = parseGoal(value)
        } else if (key === 'cityV2') {
          currVal = parseCity(value)
        } else {
          currVal = value
        }

        if (currVal) return { ...acc, [key]: currVal }
        return acc
      }, {})
    )

    // Computed
    const getRoleCopy = computed(() => {
      return {
        2: copies.student,
        3: (props.user as UserInfo).isTutor ? copies.tutor : copies.professor
      }[(props.user as UserInfo).role as 2 | 3]
    })

    /* Method to update the profile picture */
    const errorUpadtingProfile = () => {
      toast.add({
        severity: copies.toast.updatePicture.toastSeverity.error as ToastMessageOptions['severity'],
        detail: copies.toast.updatePicture.error,
        group: copies.toast.updatePicture.group,
        life: copies.toast.updatePicture.life
      })
    }

    const updateProfilePicture = async (e: Event) => {
      const target = e.target as HTMLInputElement
      const file: File = (target.files as FileList)[0]
      if (!file) return
      loadingUpdateImage.value = true

      try {
        const responseLink = await updatePicture(props.user.id, file)
        loadingUpdateImage.value = false

        if (!responseLink) return errorUpadtingProfile()

        userStore.updateAvatar(responseLink)
      } catch (err) {
        loadingUpdateImage.value = false
        errorUpadtingProfile()
        // eslint-disable-next-line no-console
        console.error(err)
      }
    }

    /** Method to manage click on edit button */
    const handleToggleEdit = () => {
      emit('onClickEdit')
    }

    /** Method to provide feedback to user when confirm change password */
    const changePasswordFeedback = (success: boolean) => {
      const successStr = success ? 'success' : 'error'
      toast.add({
        severity: successStr,
        detail: copies.changePassword.toast[successStr].detail,
        group: copies.changePassword.toast.group,
        life: copies.changePassword.toast.life
      })
    }

    const changePasswordHandlers = {
      /** Method to handle click on change password button */
      click: () => {
        changePasswordRefs.value.dialogOpen = true
      },
      /** Method to handle click on confirm button on change password confirm dialog */
      confirm: async () => {
        changePasswordRefs.value.loading = true

        const success = await changePasswordService({ userEmail: props.user.email })

        changePasswordRefs.value.loading = false

        changePasswordRefs.value.dialogOpen = false

        changePasswordFeedback(success)
      },
      /** Method to handle click on cancel button on change password confirm dialog */
      cancel: () => {
        changePasswordRefs.value.dialogOpen = false
      }
    }

    const handleClickEditEmail = () => {
      // TODO: handle this
    }

    return {
      copies,
      getRoleCopy,
      changePasswordHandlers,
      changePasswordRefs,
      handleToggleEdit,
      handleClickEditEmail,
      parsedInformation,
      socialNetworksToShow,
      updateProfilePicture,
      loadingUpdateImage
    }
  }
})
