import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-595da8d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "p3 error"
}
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.error)
    ? (_openBlock(), _createElementBlock("p", _hoisted_1, [
        _createElementVNode("img", {
          src: require('@/assets/icons/pi-icon-alert-red.svg')
        }, null, 8, _hoisted_2),
        _createTextVNode(" " + _toDisplayString(_ctx.error), 1)
      ]))
    : _createCommentVNode("", true)
}