
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  emits: ['show-criteria'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const showCriteria = () => {
      emit('show-criteria')
    }
    return { t, showCriteria }
  }
})
