
// Vendor
import { defineComponent, PropType } from 'vue'

// Components
import ButtonIcon from '@/components/aero/buttons/ButtonIcon.vue'
import Icon from '@/components/aero/icon/Icon.vue'
import { File, Download, Trash, LinkIcon } from '@/components/aero/icon/templates'

// Services
import getDownloadLink from '@/services/newPlatform/getDownloadLink'

interface IFile {
  text: string
  type?: string
  size?: string
}

export default defineComponent({
  components: {
    File,
    Download,
    LinkIcon,
    Trash,
    Icon,
    ButtonIcon
  },
  props: {
    isUpload: { type: Boolean, default: false },
    isDownload: { type: Boolean, default: false },
    isLink: { type: Boolean, default: false },
    data: { type: Object as PropType<IFile>, required: true },
    styles: { type: Object }
  },
  emits: ['onClick', 'onDelete'],
  setup(_, { emit }) {
    const handleDownload = () => {
      if (_.isDownload) {
        const downloadUrl = getDownloadLink(_.data.text)
        window.open(downloadUrl, '_blank')
      } else {
        emit('onClick')
      }
    }

    const handleDelete = () => {
      emit('onDelete')
    }

    return { handleDownload, handleDelete }
  }
})
