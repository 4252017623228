
import { defineComponent, PropType, ref, onMounted } from 'vue'
import { InputSelect } from '@/models/commons/InputSelect'

import Icon from '../icon/Icon.vue'
import ChevronDown from '../icon/templates/chevron-down.vue'

export default defineComponent({
  components: { Icon, ChevronDown },
  props: {
    name: { type: String, default: '', required: true },
    options: {
      type: Array as PropType<InputSelect[]>,
      default: () => [],
      required: true
    },
    valueSuggested: { type: String, default: '', required: false },
    disabled: { type: Boolean, default: false, required: false },
    placeholder: { type: String, default: '' }
  },
  setup(props, context) {
    const selectedOption = ref('')

    function handleSelectedValue(e: Event) {
      const { target } = e
      if (target) {
        context.emit(`${props.name}`, (target as HTMLInputElement).value)
      }
    }
    onMounted(() => {
      // set the suggested value
      if (props.valueSuggested) {
        selectedOption.value = props.valueSuggested
      }
    })

    return { selectedOption, handleSelectedValue }
  }
})
