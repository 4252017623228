import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-70e79c42"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-d-flex p-flex-wrap gap" }
const _hoisted_2 = { class: "p-d-flex gap p-mt-3 p-mb-4" }
const _hoisted_3 = { class: "skeletonContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_skeleton_text = _resolveComponent("skeleton-text")!
  const _component_SkeletonInput = _resolveComponent("SkeletonInput")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_skeleton_text, { length: 12 }),
        _createVNode(_component_skeleton_text, { length: 18 }),
        _createVNode(_component_skeleton_text, { length: 20 })
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_skeleton_text, { length: 12 }),
        _createVNode(_component_skeleton_text, { length: 16 })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_SkeletonInput)
      ])
    ]),
    _: 1
  }))
}