<template>
  <main class="layout-main">
    <slot/>
  </main>
</template>

<style lang="scss" scoped>
@import 'src/assets/styles/old_theme/variables.scss';
.layout-main {
  min-height: calc(100vh - #{$horizontal-menu-height + 42px});
}
</style>
