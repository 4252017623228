import { RouteRecordRaw } from 'vue-router'
import Profile from '@/views/profile/Profile.vue'
import ChangeCourseStudent from '@/views/profile/ChangeCourseStudent.vue'
import TransferCourse from '@/views/profile/TransferCourse.vue'
import { NewPlatform as Names } from '@/router/route-names'

const ProfileRoutes: Array<RouteRecordRaw> = [
  {
    path: '',
    component: Profile,
    name: Names.PROFILE
  },
  {
    path: 'change-course/:camadaNro',
    component: ChangeCourseStudent,
    name: Names.PROFILE_CHANGE_COURSE
  },
  {
    path: 'transfer-course/:camadaNro',
    component: TransferCourse,
    name: Names.PROFILE_TRANSFER_COURSE
  },
  {
    path: '/:userId',
    component: Profile,
    name: Names.PROFILE_VISITOR_VIEW
  }
]

export default ProfileRoutes
