
import { defineComponent, PropType } from 'vue'
import { DateTime } from 'luxon'

// Components
import Card from '@/components/aero/surfaces/Card.vue'

// Models
import { Schedule } from '@/models/dashboard/rating'

// Utils
import { getTime } from '@/utils/datetime'

export default defineComponent({
  components: { Card },
  name: 'DateCard',
  props: {
    nextClass: {
      type: Object as PropType<Schedule>,
      required: true
    },
    role: {
      type: Number,
      default: 2, // student 2, teacher 3
      required: false
    },

    copies: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const dt = DateTime.fromMillis(props.nextClass.day)
    const formattedTime = getTime(props.nextClass.from)
    const shortDay = dt.toFormat('ccc').toLocaleString().toUpperCase().slice(0, 2)

    return {
      dt,
      formattedTime,
      shortDay
    }
  }
})
