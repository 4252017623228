import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-289c2643"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-d-flex p-jc-between header-container" }
const _hoisted_2 = { class: "p-d-flex p-ai-center" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "selectors-container" }
const _hoisted_5 = { class: "modality" }
const _hoisted_6 = { class: "interest" }
const _hoisted_7 = { class: "footer-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.modal,
    "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modal) = $event)),
    class: _normalizeClass(_ctx.user?.country === 'br' ? 'user-modal-br' : 'user-modal'),
    style: _normalizeStyle({width: _ctx.user?.country === 'br' ? '30rem' : '744px', 'min-width': _ctx.user?.country === 'br' ? '30rem': '50rem', height: '332px'}),
    modal: true,
    closable: false
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('editProfile.professionalInfo.goals.modal.selectGoal')), 1),
        _createElementVNode("div", null, [
          _createElementVNode("img", {
            class: "close-modal",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal())),
            src: require('@/assets/icons/Light_X.svg'),
            alt: "Close modal"
          }, null, 8, _hoisted_3)
        ])
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_Button, {
          label: _ctx.$t('editProfile.professionalInfo.goals.modal.cancelButton'),
          onClick: _ctx.closeModal,
          class: "close-button"
        }, null, 8, ["label", "onClick"]),
        _createVNode(_component_Button, {
          label: _ctx.$t('editProfile.professionalInfo.goals.modal.addButton'),
          class: "submit-button",
          onClick: _ctx.saveGoals
        }, null, 8, ["label", "onClick"])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("h5", null, _toDisplayString(_ctx.$t('editProfile.professionalInfo.goals.modal.modality')), 1),
          _createVNode(_component_MultiSelect, {
            modelValue: _ctx.selectedModalities,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedModalities) = $event)),
            options: _ctx.modalities,
            optionLabel: "name",
            placeholder: _ctx.$t('editProfile.professionalInfo.goals.modal.select'),
            scrollHeight: "272px"
          }, null, 8, ["modelValue", "options", "placeholder"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("h5", null, _toDisplayString(_ctx.$t('editProfile.professionalInfo.goals.modal.interest')), 1),
          _createVNode(_component_MultiSelect, {
            modelValue: _ctx.selectedIndustries,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedIndustries) = $event)),
            options: _ctx.industries,
            optionLabel: "name",
            placeholder: _ctx.$t('editProfile.professionalInfo.goals.modal.select'),
            scrollHeight: "272px",
            virtualScrollerOptions: { itemSize: 18 }
          }, null, 8, ["modelValue", "options", "placeholder"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["visible", "class", "style"]))
}