
import { defineComponent, PropType } from 'vue'

// Models
import { SocialNetwork } from '@/models/profile/profile'

// Icons
import {
  Instagram,
  Facebook,
  Twitter,
  Linkedin,
  Behance,
  Upload,
  Github,
  Dribbble,
  Youtube,
  Vimeo
} from '@/components/aero/icon/templates'

export default defineComponent({
  props: {
    name: { type: String as PropType<SocialNetwork['key']>, required: true }
  },
  components: {
    Instagram,
    Facebook,
    Twitter,
    Linkedin,
    Behance,
    Upload,
    Github,
    Dribbble,
    Youtube,
    Vimeo
  }
})
