import amplitude from 'amplitude-js'

import { UserProperties } from '@/models/amplitude'
// Page names
import { Names } from '@/router/route-names'

export const setUserProperties = (userProperties: UserProperties): void => {
  amplitude.getInstance().setUserProperties(userProperties)
}

export const trackEvent = (name: string, properties: { [k: string]: string } = {}) => {
  amplitude.getInstance().logEvent(name, { ...properties })
}

export const trackPageView = (pageName: Names) => {
  trackEvent('Pageview', { 'Page name': pageName })
}
