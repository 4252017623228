
import { defineComponent, ref, onMounted } from 'vue'
import Chart from 'chart.js'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  props: {
    positives: { type: Number, required: true },
    neutrals: { type: Number, required: true },
    negatives: { type: Number, required: true },
    defaultFontSize: { type: Number, required: false, default: 12 }
  },
  setup(props) {
    const data = ref()
    const { t } = useI18n()

    const colorItems = ['rgba(130, 219, 145, 1)', 'rgba(243, 173, 90, 1)', 'rgba(251, 122, 80, 1)']
    onMounted(() => {
      const ctx = document.querySelector('#ratingChart') as HTMLCanvasElement
      data.value = new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: [
            t('rating.chart.rating.positives.label'),
            t('rating.chart.rating.neutrals.label'),
            t('rating.chart.rating.negatives.label')
          ],
          datasets: [
            {
              data: [props.positives, props.neutrals, props.negatives],
              backgroundColor: colorItems,
              hoverBackgroundColor: colorItems,
              borderColor: ['rgba(39, 40, 45, 1)', 'rgba(39, 40, 45, 1)', 'rgba(39, 40, 45, 1)'],
              borderWidth: 2
            }
          ]
        },
        options: {
          cutoutPercentage: 60,
          rotation: 0.1,
          legend: {
            labels: {
              fontColor: 'rgba(242, 242, 242, 1)',
              fontFamily: 'Untitled Sans Regular',
              boxWidth: 30,
              usePointStyle: true,
              padding: 20
            },
            position: 'bottom'
          },
          tooltips: {
            bodyFontSize: props.defaultFontSize,
            bodyFontFamily: 'Untitled Sans Medium',
            caretSize: 6,
            caretPadding: -2,
            cornerRadius: 8,
            xPadding: 8,
            yPadding: 6,
            displayColors: false,
            callbacks: {
              labelTextColor: function (TooltipItem) {
                return colorItems[TooltipItem.index as number]
              },
              label: function (tooltipItem: any, data: any) {
                return data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] + '%'
              }
            }
          },
          hover: {
            mode: 'index'
          }
        }
      })
    })

    return { data }
  }
})
