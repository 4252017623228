
import { defineComponent } from 'vue'

export default defineComponent({
  emits: ['update:modelValue'],
  props: {
    label: {
      type: String,
      required: true
    },
    modelValue: null,
    error: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    }
  },
  setup (props, { emit }) {
    const change = (event: any) => emit('update:modelValue', event.target.value)
    return { change }
  }
})
