
import { defineComponent, PropType, ref, Ref, watch, onMounted } from 'vue'
import Icon from '@/components/aero/icon/Icon.vue'
import { ChevronUp } from '@/components/aero/icon/templates'
import SelectInput from '@/components/aero/inputs/SelectInput.vue'
import Dialog from 'primevue/dialog'
import Checkbox from 'primevue/checkbox'
import Button from 'primevue/button'
import { IWrangleJobOffer, IWrangleJobInput, IWrangleJobApplication } from '@/models/jobs/jobs-offers'
import { apply, getWrangleJobForm } from '@/api/job-offers.api'
import { IUser } from '@/models/user'
import { useUserStore } from '@/store/modules/user.store'
import { useUserSessionStore } from '@/store/modules/user-session.store'
import { useI18n } from 'vue-i18n'
import moment from 'moment'

export default defineComponent({
  props: {
    jobOffer: {
      required: true,
      type: Object as PropType<IWrangleJobOffer>
    }
  },
  components: {
    Icon,
    ChevronUp,
    Dialog,
    Checkbox,
    Button,
    SelectInput
  },
  setup (props) {
    // const user = useUserStore()
    const showDescription = ref(false)
    const showModal = ref(false)
    const showOverlay = ref(false)
    const coderPlusPrice = ref()
    const coderPlusCurrency = ref()
    const session = useUserSessionStore()
    const idToken = session.token
    const accessToken = session.auth
    const userID = ref()
    const country = ref()
    const { t } = useI18n()
    const activeCoderPlus = ref(false)
    // const publishedDate = moment(props.jobOffer.pubDate * 1000).fromNow()
    const publishedDate = moment(props.jobOffer.pubDate).fromNow()
    const jobDescription = props.jobOffer.description.replaceAll('href', 'target="_blank" href').replaceAll('\n', '<br>')  // 17 hours ago
    const wrangleInputs: Ref<IWrangleJobInput[]> = ref([])

    const isCoderPlus = async (idToken: string, accessToken: string) => {
    try {
      const url = process.env.VUE_APP_CH_API_BFF
      const res = await fetch(`${url}/coder-plus`,
      {
        headers: { 
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          idToken,
          accessToken
        },
        mode: 'cors'
      })
      const data = await res.json()
      if (data.isCoderPlus) {
        activeCoderPlus.value = true
      }
      else {
        activeCoderPlus.value = false
        coderPlusPrice.value = data.price / 3
        coderPlusCurrency.value = data.currency
      }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('hay un error', error)
      }
    }

    // isCoderPlus(idToken, accessToken)

    const landingLink = async () => {
      const user = useUserStore()
      await user.getUser()

      userID.value = user.id
      country.value = user.country
    }

    // landingLink()

    const applyCTA = () => {
      showModal.value = false
      showOverlay.value = false
      window.dataLayer.push({
        event: 'trackEvent',
        eventCategory: 'bolsa_de_empleo',
        eventAction: 'aplicar_CTA',
        eventLabel: 'aplicar_por_mi_cuenta'
      }) 
    }

    const suscribeCTA = () => {
      showModal.value = false
      showOverlay.value = false
      window.dataLayer.push({
        event: 'trackEvent',
        eventCategory: 'bolsa_de_empleo',
        eventAction: 'suscribirme_CTA',
        eventLabel: 'suscribirme'
      }) 
    }

    const closeModal = () => {
      showModal.value = false
      showOverlay.value = false
    }

    const jobOfferDataNoCoderPlus = async () => {
      showModal.value = true
      showOverlay.value = true
    }

    const jobOfferData = async () => {
      showModal.value = true
      showOverlay.value = true
      const response = await getWrangleJobForm(props.jobOffer.applyNowLink)
      wrangleInputs.value = response.formInfo
    }

    const apply = () => {
      const error = wrangleInputs.value.some(input => input.required && !input.value)
      // eslint-disable-next-line no-console
      console.log('error', error, props.jobOffer)
      if (!error) {
        const wrangleJob: IWrangleJobApplication = {
          'address': '',
          'jobId': props.jobOffer.id,
          'url': props.jobOffer.applyNowLink,
          'inputs': wrangleInputs.value,
          'filename': '',
          'coverLetter': '',
          'profileId': ''
        }

        showModal.value = false
        showOverlay.value = false
      }
    }

    return {
      t,
      showDescription,
      jobOfferData,
      jobOfferDataNoCoderPlus,
      showModal,
      closeModal,
      showOverlay,
      coderPlusPrice,
      coderPlusCurrency,
      applyCTA,
      suscribeCTA,
      activeCoderPlus,
      jobDescription,
      publishedDate,
      wrangleInputs,
      apply
    }
  }
})
