import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "p3 medium"
}
const _hoisted_2 = {
  key: 1,
  class: "p3 medium text-white"
}
const _hoisted_3 = {
  key: 2,
  class: "p3 medium"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.role === 2 && _ctx.showChallengeType)
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.showChallengeType()), 1))
      : _createCommentVNode("", true),
    (_ctx.state === _ctx.copies.reDeliver.slug)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.copies.reDeliver.label), 1))
      : _createCommentVNode("", true),
    (_ctx.role !== 2 && _ctx.showChallengeType)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.showChallengeType()), 1))
      : _createCommentVNode("", true)
  ], 64))
}