import { RouteRecordRaw } from 'vue-router'
import Jobs from '@/views/jobOffer/Jobs.vue'
import { JobOffers as Names } from '@/router/route-names'

const JobOffersRoutes: Array<RouteRecordRaw> = [
  {
    path: '',
    component: Jobs,
    name: Names.Jobs
  }
]

export default JobOffersRoutes
