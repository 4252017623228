<template>
  <div class="loading-container">
    <div class="loading-icon">
      <icon-base width="129" height="129" viewBox="0 0 129 129" :strokeColor="'transparent'">
        <rocket />
      </icon-base>
    </div>
    <div class="loading-title">
      <span>{{ title }}</span>
    </div>
    <div class="loading-subtitle">
      <span>{{ subtitle }}</span>
    </div>
  </div>
</template>

<script>
import IconBase from '@/components/commons/IconBase.vue'
import Rocket from '@/components/commons/icon-templates/Rocket.vue'

export default {
  components: {
    IconBase,
    Rocket
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.loading-container {
  background-color: #1f2023;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading-title {
  margin-top: 2.1rem;
  margin-bottom: 2.5rem;
  span {
    font-weight: 600;
    font-size: 2.1rem;
  }
}

.loading-subtitle {
  span {
    font-weight: 400;
    font-size: 1.5rem;
  }
}
</style>