
// Vendor
import { defineComponent, onMounted, ref, toRefs, reactive } from 'vue'
import { useRoute } from 'vue-router'
import { DateTime, Interval } from 'luxon'

// Store
import { useUserSessionStore } from '@/store/modules/user-session.store'
import { useCourseStore } from '@/store/modules/course.store'

// API
import { getChallengeDataService, TGetChallenge } from '@/services/challenges.services'
import { getDeliverData } from '@/api/newPlatform/challenges.api'

// Models

import { IChat } from '@/models/newPlatform/challenges/chat'
import { ITimeline, ITimeLineStatus } from '@/models/newPlatform/challenges/timeline'

// Components
import TwoColsLayout from '@/components/aero/layout/TwoColsLayout.vue'
import Breadcrumb from 'primevue/breadcrumb'
import ImageCard from '@/components/aero/surfaces/challenges/ImageCard.vue'
import FormDeliverChallenge from '@/views/challenges/components/FormDeliverChallenge.vue'
import SkeletonDesafioAside from '@/components/aero/skeleton/SkeletonDesafioAside.vue'
import StateHistorial from '@/components/aero/dataDisplay/StateHistorial.vue'
import PlaceholderState from '@/components/aero/surfaces/challenges/PlaceholderState.vue'
import Message from '@/components/aero/surfaces/Message.vue'
import Toast from '@/components/aero/feedback/Toast.vue'
import SkeletonTimeline from '@/components/aero/skeleton/SkeletonTimeline.vue'
import SkeletonText from '@/components/aero/skeleton/SkeletonText.vue'
import Icon from '@/components/aero/icon/Icon.vue'
import ErrorPage from '@/components/aero/dataDisplay/ErrorPage.vue'
import { Clock } from '@/components/aero/icon/templates'

// Hooks
import { useTimeline } from '@/hooks/challenges/useTimeline'
import { useToast } from 'primevue/usetoast'

// Locales
import copies from '@/locales/challenges/es.json'

// Utils
import { getTime, getDate, getHours } from '@/utils/datetime'

// Amplitude
import { ChallengesEvents } from '@/amplitude/constants'
import { trackEvent } from '@/amplitude/actions'
import { ToastMessageOptions } from 'primevue/toast'

export default defineComponent({
  name: 'Challenge',
  components: {
    TwoColsLayout,
    Breadcrumb,
    ErrorPage,
    ImageCard,
    FormDeliverChallenge,
    SkeletonDesafioAside,
    StateHistorial,
    PlaceholderState,
    Message,
    Toast,
    SkeletonTimeline,
    SkeletonText,
    Icon,
    Clock
  },
  setup() {
    const userId = useUserSessionStore().userId
    const { id: courseId, camadaNro } = useCourseStore()

    const route = useRoute()

    const { mockdata, order, rol } = route.query
    const { challengeId } = route.params

    const rolParam = rol || 2

    const isLoading = ref<boolean>(true)
    const isError = ref<boolean>(false)
    const isTimeLineLoading = ref<boolean>(true)

    const forceEnableDeliver = ref(false)

    const challenge = ref()

    const challengeData = reactive({
      moduleId: '',
      challengeName: '',
      openDateChallenge: 0,
      evaluationCriteria: [],
      expiresAt: 0,
      scheduleId: '',
      isDeliverExpired: false,
      isChallengeEnabled: false,
      slideUrl: '',
      stage: '',
      class: {
        order: 0,
        className: '',
        classId: ''
      }
    })

    const projectData = reactive({
      deliverId: '',
      wasApproved: false,
      wasDisapproved: false,
      wasReviewed: false,
      unlockedUntil: null as null | number,
      finalDeadline: null as null | number,
      isLocked: false,
      timeline: [] as ITimeline[],
      chat: [] as IChat[],
      history: [] as (ITimeline | IChat)[]
    })

    let expiresChallengeDateText = ''
    const toast = useToast()

    // Fetch Data from API Service
    const getChallenge = async () => {
      const getChallengeDataProps: TGetChallenge = {
        returnMockData: !!mockdata,
        rol: `${rolParam}`,
        order: Number(order),
        userId: userId,
        camadaId: camadaNro,
        courseId: courseId,
        challengeId: String(challengeId) || '000'
      }
      try {
        const response = await getChallengeDataService(getChallengeDataProps)

        const millisDate =
          typeof response.openDateModule === 'string'
            ? DateTime.fromISO(response.openDateModule).toMillis()
            : response.openDateModule

        challengeData.moduleId = response._id
        challengeData.scheduleId = response.scheduleId
        challengeData.challengeName = response.name
        challengeData.openDateChallenge = millisDate
        challengeData.isChallengeEnabled = challengeData.openDateChallenge < Date.now()

        challengeData.evaluationCriteria = response.evaluationCriteria
        challengeData.expiresAt = response.expiresAt
        challengeData.slideUrl = response.slideUrl || ''
        challengeData.isDeliverExpired = response.expiresAt < Date.now()
        challengeData.class.order = response.class.order
        challengeData.class.className = response.class.name
        challengeData.class.classId = response.class._id
        challengeData.stage = response.stage

        const history = {
          timeline: projectData.timeline,
          chat: projectData.chat
        }
        projectData.history = useTimeline(
          history,
          challengeData.openDateChallenge,
          challengeData.expiresAt,
          challengeData.isChallengeEnabled
        )

        if (response.project) {
          isLoading.value = false
          await getDeliverStatus(response.project)
        }

        challenge.value = response
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e, 'error getChallenge')

        isError.value = true
      } finally {
        isLoading.value = false
        isTimeLineLoading.value = false
      }
    }

    const getDeliverStatus = async (deliverId: string, camadaId = Number(camadaNro)) => {
      try {
        isTimeLineLoading.value = true
        const response = await getDeliverData(camadaId, deliverId)
        const unlockedDate = response.unlockedUntil ? DateTime.fromISO(response.unlockedUntil).toMillis() : 0

        projectData.deliverId = deliverId
        projectData.wasApproved = response.flags?.wasApproved || false
        projectData.wasDisapproved = response.flags?.wasDisapproved || false
        projectData.wasReviewed = response.flags?.wasReviewed || false
        projectData.unlockedUntil = unlockedDate
        projectData.timeline = response.timeline || []
        projectData.chat = response.chat || []
        projectData.finalDeadline = response.timeline ? getFinalDeadline(response.timeline) : null
        projectData.isLocked = response.locked || false
        const history = {
          timeline: projectData.timeline.filter(
            (step) => step.key !== ITimeLineStatus.finalDeadline && step.key !== ITimeLineStatus.reviewExpected
          ),
          chat: projectData.chat
        }
        projectData.history = useTimeline(
          history,
          challengeData.openDateChallenge,
          projectData.finalDeadline || 0,
          challengeData.isChallengeEnabled
        )
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e, 'no se pudo cargar el desafio por ID')

        isError.value = true
      } finally {
        isTimeLineLoading.value = false
      }
    }

    const setDateTimeString = (_date: number) => {
      const date = getDate(_date)
      const time = getTime(_date)

      return `${date} a las ${time}`
    }

    const handleOnDelivered = (deliverId: string) => {
      if (deliverId) {
        onDeliverSuccess()
        projectData.deliverId = deliverId
        getDeliverStatus(deliverId)

        handleTrackEvent(ChallengesEvents.HAND_CHALLENGE)
      } else {
        onDeliverError()
      }
    }

    const onDeliverSuccess = () => {
      toast.add({
        severity: copies.ReviewChallenge.toast.toastSeverity.success as ToastMessageOptions['severity'],
        detail: copies.studentChallenge.toast.deliver.success,
        group: copies.ReviewChallenge.toast.group,
        life: copies.ReviewChallenge.toast.life
      })
    }

    const onDeliverError = () => {
      toast.add({
        severity: copies.ReviewChallenge.toast.toastSeverity.error as 'error',
        detail: copies.studentChallenge.toast.deliver.error,
        group: copies.ReviewChallenge.toast.group,
        life: copies.ReviewChallenge.toast.life
      })
    }

    const setCountdown = (date: number) => {
      const now = DateTime.now()
      const later = DateTime.fromMillis(date)
      const interval = Interval.fromDateTimes(now, later)

      // if (now > later) return
      const round = (v: number) => Math.round(Math.abs(v))

      if (round(interval.length('hour')) > 24) {
        return `${copies.countdown.expiresAt} ${copies.countdown.el} ${setDateTimeTag(date)}`
      } else {
        const end = DateTime.fromMillis(date)
        const diffTime = end.diff(now, ['hours', 'minutes']).toObject()

        const minutes = diffTime.minutes && round(diffTime.minutes)

        const hasHoursLeft = diffTime.hours && diffTime.hours > 0
        const expiresCountdown = `${copies.countdown.expiresAt} ${copies.countdown.en} ${
          hasHoursLeft ? diffTime.hours + 'h' + minutes + 'm' : minutes + 'm'
        }`

        return expiresCountdown
      }
    }

    const getFinalDeadline = (timeline: ITimeline[]) => {
      const finalDeadline = timeline.find((step) => step.key === ITimeLineStatus.finalDeadline)
      if (finalDeadline) {
        return typeof finalDeadline.date === 'string' ? DateTime.fromISO(finalDeadline.date).toMillis() : finalDeadline.date
      } else {
        return null
      }
    }

    const setDateTimeTag = (date: number) => {
      return `${getDate(date)} ${getHours(date)}`
    }

    // Amplitude track event
    const handleTrackEvent = (name: string, props = {}) => {
      trackEvent(name, props)
    }

    /** Force enable deliver when fd (force deviler) and stu (student ID)
     *  are received by query params
     */
    const handleForceEnableDeliver = () => {
      const { fd, stu, ts } = route.query
      const { challengeId } = route.params

      const onTime = ts && +ts > Date.now()

      if ((fd === challengeId || fd === challengeData.scheduleId) && stu === userId && onTime) {
        forceEnableDeliver.value = true
      }
    }

    onMounted(async () => {
      await getChallenge()
      handleForceEnableDeliver()
      handleTrackEvent(ChallengesEvents.VIEW_MODULE_DETAIL, { module_name: challengeData.challengeName })

      expiresChallengeDateText = challengeData.expiresAt ? setDateTimeString(challengeData.expiresAt) : ''
    })

    return {
      userId,
      challenge,
      ...toRefs(challengeData),
      ...toRefs(challengeData.class),
      projectData,
      isLoading,
      isError,
      isTimeLineLoading,
      copies,
      challengeId,
      handleOnDelivered,
      expiresChallengeDateText,
      ITimeLineStatus,
      setCountdown,
      setDateTimeString,
      handleTrackEvent,
      ChallengesEvents,
      forceEnableDeliver
    }
  }
})
