
import { defineComponent, reactive, ref, onMounted } from 'vue'

// Services
import {
  getEducationInformation,
  getWorkExperienceInformation,
  deleteEducationInformation,
  deleteWorkExperienceInformation
} from '@/services/newPlatform/profile.services'

// Components
import FormStudies from '@/views/profile/components/TrainingView/FormStudies.vue'
import FormWorkExperience from '@/views/profile/components/TrainingView/FormWorkExperience.vue'
import CardInformationLarge from '@/components/aero/surfaces/profile/CardInformationLarge/CardInformationLarge.vue'
import Dialog from 'primevue/dialog'
import Toast from '@/components/aero/feedback/Toast.vue'
import CustomTransition from '@/components/aero/misc/CustomTransition.vue'

// Copies
import copies from '@/locales/profile/es.json'
import { useI18n } from 'vue-i18n'

// Hooks
import { useToast } from 'primevue/usetoast'

// Models
import { IExperience } from '@/models/newPlatform/profile'

// Amplitude
import { trackEvent } from '@/amplitude/actions'
import { ProfileEvents } from '@/amplitude/constants'

export default defineComponent({
  components: {
    FormStudies,
    CardInformationLarge,
    FormWorkExperience,
    Dialog,
    Toast,
    CustomTransition
  },
  props: {
    userId: { type: String, required: true },
    role: { type: Number, required: true },
    isVisitorView: { type: Boolean, default: false }
  },
  setup(props) {
    const { t } = useI18n()

    const academicData = ref<IExperience[]>([])
    const showStudiesForm = ref<boolean>(false)
    const academicDataId = ref<string>('')

    const workData = ref<IExperience[]>([])
    const showWorkForm = ref<boolean>(false)
    const workDataId = ref<string>('')

    const displayDialog = ref<boolean>(false)
    const isDialogLoading = ref<boolean>(false)

    const editFormValues = reactive({
      title: '',
      subtitle: '',
      startDate: '' as string | Date,
      endDate: '' as string | Date,
      currently: false,
      _id: ''
    })

    // Toast hook
    const toast = useToast()

    const getAcademicData = async () => {
      try {
        const response = await getEducationInformation(props.userId)
        academicData.value = response
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn('error while trying to retrive education data', e)
        // TODO: Set error message
      }
    }

    const getWorkExperienceData = async () => {
      try {
        const response = await getWorkExperienceInformation(props.userId)
        workData.value = response
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn('error while trying to retrive work experience data', e)
        // TODO: Set error message
      }
    }

    const resetDefaultValues = () => {
      editFormValues.title = ''
      editFormValues.subtitle = ''
      editFormValues.startDate = ''
      editFormValues.endDate = ''
      editFormValues._id = ''
      editFormValues.currently = false
    }

    const handleCloseForm = () => {
      displayDialog.value = false
      if (academicDataId.value) {
        academicDataId.value = ''
      } else {
        workDataId.value = ''
      }
    }

    const handleCancelForm = (isEducation: boolean) => {
      resetDefaultValues()
      if (isEducation) {
        showStudiesForm.value = false
      } else {
        showWorkForm.value = false
      }
    }

    const handleStudiesSubmit = (values: IExperience) => {
      try {
        // check if the id already exists and update data
        const isEditting = academicData.value.find((data) => data._id === values._id)

        if (isEditting) {
          const index = academicData.value.indexOf(isEditting)

          // replace with the new values
          academicData.value.splice(index, 1, values)
        } else {
          // creates new data
          academicData.value.push(values)
        }

        resetDefaultValues()

        const { group, life } = copies.formStudies.toast.create
        toast.add({ severity: 'success', detail: t('profile.formStudies.toast.create.success.detail'), group, life })
      } catch (e) {
        const { group, life } = copies.formStudies.toast.create
        toast.add({ severity: 'error', detail: t('profile.formStudies.toast.create.error.detail'), group, life })
      } finally {
        showStudiesForm.value = false
      }
    }

    const handleWorkSubmit = (values: IExperience) => {
      try {
        // check if the id already exists and update data
        const isEditting = workData.value.find((data) => data._id === values._id)

        if (isEditting) {
          const index = workData.value.indexOf(isEditting)

          // replace with the new values
          workData.value.splice(index, 1, values)
        } else {
          // creates new data
          workData.value.push(values)
        }

        resetDefaultValues()

        const { group, life } = copies.formStudies.toast.create
        toast.add({ severity: 'success', detail: t('profile.formStudies.toast.create.success.detail'), group, life })
      } catch (e) {
        const { group, life } = copies.formStudies.toast.create
        toast.add({ severity: 'error', detail: t('profile.formStudies.toast.create.error.detail'), group, life })
      } finally {
        showWorkForm.value = false
      }
    }

    // Works for both education and work
    const handleEditData = (prevValues: IExperience, isEducation: boolean) => {
      editFormValues.title = prevValues.title
      editFormValues.subtitle = prevValues.subtitle
      editFormValues.startDate = prevValues.startDate
      editFormValues.endDate = prevValues.currently ? '' : prevValues.endDate
      editFormValues.currently = prevValues.currently
      editFormValues._id = prevValues._id || ''
      if (isEducation) {
        showStudiesForm.value = true
      } else {
        showWorkForm.value = true
      }
    }

    const handleDeleteStudies = (studyId: string) => {
      academicDataId.value = studyId
      displayDialog.value = true
    }

    const handleDeleteWork = (workId: string) => {
      workDataId.value = workId
      displayDialog.value = true
    }

    // confirm delete for academic/work info
    const handleConfirmDelete = async () => {
      try {
        isDialogLoading.value = true
        if (academicDataId.value) {
          // Delete permanently academic data
          await deleteEducationInformation(props.userId, academicDataId.value)

          trackEvent(ProfileEvents.DELETE_ACADEMIC_EXPERIENCE)

          // update academicData UI
          academicData.value = academicData.value.filter((item) => item._id !== academicDataId.value)
        } else {
          // Delete permanently work data
          await deleteWorkExperienceInformation(props.userId, workDataId.value)

          trackEvent(ProfileEvents.DELETE_WORK_EXPERIENCE)

          // update workData UI
          workData.value = workData.value.filter((item) => item._id !== workDataId.value)
        }

        handleCloseForm()

        const { group, life } = copies.formStudies.toast.delete
        toast.add({ severity: 'success', detail: t('profile.formStudies.toast.create.success.detail'), group, life })
      } catch (e) {
        const { group, life } = copies.formStudies.toast.delete
        toast.add({ severity: 'error', detail: t('profile.formStudies.toast.create.error.detail'), group, life })
      } finally {
        isDialogLoading.value = false
      }
    }

    onMounted(() => {
      getAcademicData()
      getWorkExperienceData()
    })

    return {
      academicData,
      showStudiesForm,
      workData,
      showWorkForm,
      editFormValues,
      handleStudiesSubmit,
      handleEditData,
      handleDeleteStudies,
      handleWorkSubmit,
      handleDeleteWork,
      copies,
      displayDialog,
      handleCancelForm,
      handleCloseForm,
      handleConfirmDelete,
      isDialogLoading,
      academicDataId,
      t
    }
  }
})
