import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-30a0b18a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "student-ranking" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ranking_card = _resolveComponent("ranking-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ranking_card, {
      link: '/ranking',
      progress: _ctx.progress,
      total: _ctx.total,
      ranking: `${_ctx.copies.ranking.position} #${_ctx.ranking} ${_ctx.copies.ranking.ofRanking}`
    }, null, 8, ["progress", "total", "ranking"])
  ]))
}