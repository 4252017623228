
import { defineComponent } from 'vue'

// Components
import ButtonIcon from '@/components/aero/buttons/ButtonIcon.vue'
import { ChevronRight } from '@/components/aero/icon/templates'

export default defineComponent({
  components: { ButtonIcon, ChevronRight },
  props: {
    img: { type: String, required: false },
    title: { type: String, required: true },
    message: { type: String, required: true },
    borderColor: { type: String, required: false, default: 'gradient' },
    link: { type: String, required: true },
    buttonText: { type: String, required: false, default: '' },
    paddingSm: { type: Boolean, required: false, default: false },
    paddingMd: { type: Boolean, required: false, default: false },
    arrowLink: { type: Boolean, default: false }
  }
})
