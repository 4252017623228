import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-db77f758"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { id: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("main", {
      class: _normalizeClass(_ctx.mainClass)
    }, [
      _createElementVNode("aside", {
        class: _normalizeClass([_ctx.isEmbed ? 'embed' : '', _ctx.asideClass])
      }, [
        _renderSlot(_ctx.$slots, "aside", {}, undefined, true)
      ], 2),
      _createElementVNode("section", _hoisted_2, [
        _renderSlot(_ctx.$slots, "content", {}, undefined, true)
      ])
    ], 2)
  ]))
}