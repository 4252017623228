import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78c4e85a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "onboarding-container"
}
const _hoisted_2 = { class: "onboarding-form" }
const _hoisted_3 = { class: "slides" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_FirstStep = _resolveComponent("FirstStep")!
  const _component_SecondStep = _resolveComponent("SecondStep")!
  const _component_LastStep = _resolveComponent("LastStep")!
  const _component_Button = _resolveComponent("Button")!
  const _component_login_slides = _resolveComponent("login-slides")!
  const _component_loading_screen = _resolveComponent("loading-screen")!

  return (!_ctx.finalLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Header),
          (_ctx.currentStep === 1)
            ? (_openBlock(), _createBlock(_component_FirstStep, {
                key: 0,
                user: _ctx.user,
                onChangeFirstName: _cache[0] || (_cache[0] = (value) => (_ctx.user.firstName = value)),
                onChangeLastName: _cache[1] || (_cache[1] = (value) => (_ctx.user.lastName = value)),
                onChangePhone: _cache[2] || (_cache[2] = (value) => (_ctx.user.phone = value)),
                onChangeCountry: _cache[3] || (_cache[3] = (value) => (_ctx.user.country = value)),
                onChangeCity: _cache[4] || (_cache[4] = (value) => (_ctx.user.cityV2 = value))
              }, null, 8, ["user"]))
            : _createCommentVNode("", true),
          (_ctx.currentStep === 2)
            ? (_openBlock(), _createBlock(_component_SecondStep, {
                key: 1,
                user: _ctx.user,
                onChangeEducationTitle: _cache[5] || (_cache[5] = (value) => (_ctx.education.title = value)),
                onChangeEducationInstitute: _cache[6] || (_cache[6] = (value) => (_ctx.education.institute = value)),
                onChangeEducationFrom: _cache[7] || (_cache[7] = (value) => (_ctx.education.range.from = value)),
                onChangeEducationTo: _cache[8] || (_cache[8] = (value) => (_ctx.education.range.to = value)),
                onChangeCurrentlyStudying: _cache[9] || (_cache[9] = (value) => (_ctx.education.range.currently = value))
              }, null, 8, ["user"]))
            : _createCommentVNode("", true),
          (_ctx.currentStep === _ctx.lastStep)
            ? (_openBlock(), _createBlock(_component_LastStep, {
                key: 2,
                user: _ctx.user,
                onChangeWorkTitle: _cache[10] || (_cache[10] = (value) => (_ctx.work.title = value)),
                onChangeWorkCompany: _cache[11] || (_cache[11] = (value) => (_ctx.work.company = value)),
                onChangeWorkFrom: _cache[12] || (_cache[12] = (value) => (_ctx.work.range.from = value)),
                onChangeWorkTo: _cache[13] || (_cache[13] = (value) => (_ctx.work.range.to = value)),
                onChangeCurrentlyWorking: _cache[14] || (_cache[14] = (value) => (_ctx.work.range.currently = value))
              }, null, 8, ["user"]))
            : _createCommentVNode("", true),
          (_ctx.currentStep !== _ctx.lastStep)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 3,
                onClick: _ctx.nextStep,
                label: _ctx.t('onboarding.step-personal.continue'),
                disabled: !_ctx.user.firstName || !_ctx.user.lastName || !_ctx.user.email || !_ctx.user.country,
                class: "p-button-action"
              }, null, 8, ["onClick", "label", "disabled"]))
            : _createCommentVNode("", true),
          (_ctx.currentStep === _ctx.lastStep)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 4,
                onClick: _ctx.finish,
                label: _ctx.t('onboarding.step-personal.finish'),
                disabled: _ctx.isSaving,
                class: "p-button-action"
              }, null, 8, ["onClick", "label", "disabled"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_login_slides)
        ])
      ]))
    : (_openBlock(), _createBlock(_component_loading_screen, {
        key: 1,
        title: _ctx.t('onboarding.finish-screen.title'),
        subtitle: _ctx.t('onboarding.finish-screen.subtitle')
      }, null, 8, ["title", "subtitle"]))
}