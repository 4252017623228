export enum ChallengeHistoryStates {
  AVAILABLE = 'available',
  SENT = 'sent',
  DELIVERED = 'delivered',
  APPROVED = 'approved',
  DISAPPROVED = 'disapproved',
  FORWARDED = 'forwarded',

  PENDING_DELIVERY = 'pending_delivery',
  PENDING_FEEDBACK = 'pending_feedback',
  UNAVAILABLE = 'unavailable',
  EXPIRED = 'expired'
}

export const Viewers = new Map<string, string>([
  ['google', 'https://docs.google.com/gview?embedded=true&url='], // accept: .doc, .docx, .pdf
  ['microsoft', 'https://view.officeapps.live.com/op/view.aspx?src='], // accept: .doc, .docx, .ppt, .pptx
  ['figma', 'https://www.figma.com/embed?embed_host=astra&url='] // accept: figma files ** figma files should be public urls
])

export enum CorrectionStates {
  DELIVERED = 'DELIVERED',
  READY_FOR_REVIEW = 'READY_FOR_REVIEW',
  PENDING_REVIEW = 'PENDING_REVIEW',
  BLOCKED = 'BLOCKED'
}

export enum HomeworkDeliveryStatus {
  SENT = 'sent',
  DELIVERED = 'delivered',
  CLAIMED_FOR_FORWARDING = 'claimedForwarding',
  FORWARDED = 'forwarded',
  APPROVED = 'approved',
  EXPIRED = 'expired',
  DISAPPROVED = 'disapproved',
  REVIEWED = 'reviewed'
}

export enum HomeworkTypes {
  REGULAR = 'regular',
  FINAL = 'final',
  PREFINAL = 'preFinal',
  COMPLEMENTARY = 'complementary'
}