import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "information__wrapper p-d-flex p-ai-center p-jc-between" }
const _hoisted_2 = { class: "text-light-gray p3 p-mr-3" }
const _hoisted_3 = { class: "text-white medium p2 p-mr-2" }
const _hoisted_4 = { class: "text-white p2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dots = _resolveComponent("dots")!
  const _component_button_icon = _resolveComponent("button-icon")!
  const _component_Menu = _resolveComponent("Menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.item.currently ? _ctx.formatYears(_ctx.item.startDate) : _ctx.formatYears(_ctx.item.startDate, _ctx.item.endDate)), 1),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.item.title), 1),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.item.subtitle), 1)
    ]),
    (!_ctx.isVisitorView)
      ? (_openBlock(), _createBlock(_component_button_icon, {
          key: 0,
          href: "#options",
          onClick: _ctx.toggle,
          "aria-haspopup": "true",
          "aria-controls": "overlay_menu",
          color: "var(--light-gray)"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_dots)
          ]),
          _: 1
        }, 8, ["onClick"]))
      : _createCommentVNode("", true),
    _createVNode(_component_Menu, {
      id: "overlay_menu",
      ref: "menu",
      model: _ctx.options,
      popup: true
    }, null, 8, ["model"])
  ]))
}