
// PrimeVue Component
import Toast from 'primevue/toast'

// Locales
import copies from '@/locales/challenges/es.json'

// Custom Icons
import Icon from '../../../components/aero/icon/Icon.vue'
import { Check1, Info, AlertCircle } from '../../../components/aero/icon/templates'
import { defineComponent } from 'vue'

// Custom Toast Component which can be used by any component just by making use of the useToast hook, referencing the "bc" group
export default defineComponent ({
  props: {
    showRetryMessage: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: { Toast, Icon, Check1, Info, AlertCircle },
  setup(props, { emit }) {
    return {
      copies,
      handleRetryCallback: () => {
        emit('onRetryCallback')
      }
    }
  }
})
