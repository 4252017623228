import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "p-d-flex p-flex-row p-jc-evenly p-ai-center" }
const _hoisted_2 = { class: "p-ml-2 p-mr-2 toast-message medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_check_1 = _resolveComponent("check-1")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_info = _resolveComponent("info")!
  const _component_alert_circle = _resolveComponent("alert-circle")!
  const _component_Toast = _resolveComponent("Toast", true)!

  return (_openBlock(), _createBlock(_component_Toast, {
    position: "bottom-center",
    group: "bc"
  }, {
    message: _withCtx((slotProps) => [
      _createElementVNode("div", _hoisted_1, [
        (slotProps.message.severity === _ctx.copies.ReviewChallenge.toast.toastSeverity.success)
          ? (_openBlock(), _createBlock(_component_Icon, {
              key: 0,
              strokeColor: "var(--success)"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_check_1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (slotProps.message.severity === _ctx.copies.ReviewChallenge.toast.toastSeverity.info)
          ? (_openBlock(), _createBlock(_component_Icon, {
              key: 1,
              strokeColor: "var(--blue)"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_info)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (slotProps.message.severity === _ctx.copies.ReviewChallenge.toast.toastSeverity.error)
          ? (_openBlock(), _createBlock(_component_Icon, {
              key: 2,
              strokeColor: "var(--error)"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_alert_circle)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createElementVNode("p", _hoisted_2, [
          _createTextVNode(_toDisplayString(slotProps.message.detail) + " ", 1),
          (slotProps.message.severity === 'error' && _ctx.showRetryMessage)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleRetryCallback && _ctx.handleRetryCallback(...args))),
                class: "p-ml-1 medium toast-retry"
              }, "Volver a intentar"))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }))
}