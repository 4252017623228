import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4894ef1d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-mt-5 p-mb-3" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "description p-mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DynamicCard = _resolveComponent("DynamicCard")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    class: "custom-dialog",
    header: _ctx.t('homework.challenge.criteria.title'),
    visible: _ctx.show,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.show) = $event)),
    modal: "",
    closable: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_DynamicCard, {
          borderless: "",
          shadowless: ""
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.criteria, (step, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "criteria p-py-2 p-px-6"
              }, [
                _createElementVNode("h6", _hoisted_2, _toDisplayString(index + 1) + ". " + _toDisplayString(step.title), 1),
                _createElementVNode("span", _hoisted_3, _toDisplayString(step.description), 1)
              ]))
            }), 128))
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["header", "visible"]))
}