import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64af0764"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mostVotedTag p-d-flex p-ai-center p-py-4" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "p-d-flex p-flex-column p-ml-4" }
const _hoisted_4 = { class: "p2 text-light-gray" }
const _hoisted_5 = { class: "p2 p-mt-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: `/assets/icons/rating/${_ctx.feedbackType}-feedback.svg`,
      alt: _ctx.feedbackType
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.title
            ? _ctx.title
            : _ctx.feedbackType === 'suggestion'
            ? _ctx.t('rating.sidebar.mostVotedSuggestion')
            : _ctx.t('rating.sidebar.mostVotedPositive')), 1),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.tagDescription), 1)
    ])
  ]))
}