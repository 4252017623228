import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2e3a8b39"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-d-flex p-ai-center p-mb-3" }
const _hoisted_2 = { class: "p-pl-3" }
const _hoisted_3 = { class: "p1 p-mb-1 medium" }
const _hoisted_4 = { class: "text-light-gray p2" }
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = { class: "p-pl-4 text-wrapper" }
const _hoisted_7 = { class: "p2 text-light-gray p-mb-1" }
const _hoisted_8 = { class: "p2 medium text-wrapper__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_progress_circle = _resolveComponent("progress-circle")!
  const _component_card = _resolveComponent("card")!

  return (_openBlock(), _createBlock(_component_card, null, {
    default: _withCtx(() => [
      _createElementVNode("header", _hoisted_1, [
        _createVNode(_component_progress_circle, {
          radius: 40,
          progress: _ctx.attendancePercentage,
          color: _ctx.attendancePercentage === 100 ? 'success' : 'purple'
        }, null, 8, ["progress", "color"]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.copies.header.title), 1),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.copies.header.description), 1)
        ])
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.attendance, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "smallcard full-width p-py-4 p-d-flex p-ai-center",
          key: item
        }, [
          _createElementVNode("img", {
            src: `/assets/icons/attendance/${item.type}-student.svg`,
            alt: item.type
          }, null, 8, _hoisted_5),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("p", _hoisted_7, _toDisplayString(item.type === 'active' ? _ctx.copies.activeStudent.title : _ctx.copies.inactiveStudent.title), 1),
            _createElementVNode("p", _hoisted_8, _toDisplayString(item.type === 'active' ? _ctx.copies.activeStudent.description : _ctx.copies.inactiveStudent.description), 1)
          ]),
          _createElementVNode("span", {
            class: _normalizeClass(["p-ml-auto t3 medium", item.type === 'active' ? 'text-success' : 'text-light-gray'])
          }, _toDisplayString(item.value), 3)
        ]))
      }), 128))
    ]),
    _: 1
  }))
}