
// Vendor
import { defineComponent, onMounted, PropType, toRefs, reactive } from 'vue'
import { DateTime, Interval } from 'luxon'

// Models
import { IChallenge } from '@/models/newPlatform/challenges/challenge'
import { ITimeline, ITimeLineStatus } from '@/models/newPlatform/challenges/timeline'

// Components
import Card from '@/components/aero/surfaces/Card.vue'
import Icon from '@/components/aero/icon/Icon.vue'
import { Clock } from '@/components/aero/icon/templates'

// Utils
import { getDate, getHours } from '@/utils/datetime'

// Amplitude
import { ChallengesEvents } from '@/amplitude/constants'
import { trackEvent } from '@/amplitude/actions'

// copies
import copies from '@/locales/challenges/es.json'

export default defineComponent({
  components: { Card, Clock, Icon },
  props: {
    challenge: {
      type: Object as PropType<IChallenge>,
      required: true
    },
    camadaNro: {
      type: String,
      required: true
    },
    useCoderChallenge: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const challengeData = reactive({
      label: '',
      expiresAt: '',
      isNextToExpire: false
    })

    const setChallengeData = () => {
      const expiresDate = props.challenge.projectData?.timeline
        ? getFinalDeadline(props.challenge.projectData.timeline)
        : props.challenge.expiresAt
      const now = DateTime.now()
      const later = expiresDate && DateTime.fromMillis(expiresDate)
      const interval = later && Interval.fromDateTimes(now, later)

      const round = (v: number) => Math.round(Math.abs(v))
      if (interval && round(interval.length('hour')) > 24) {
        // flag to set cta color
        challengeData.isNextToExpire = false

        // set cta label
        if (props.challenge.projectData && !props.challenge.projectData?.chat?.slice(-1)[0].flags.fromStudent) {
          challengeData.label = copies.EntregaCard.ctaLabel.deliverAgain
        } else {
          challengeData.label = copies.EntregaCard.ctaLabel.viewChallenge
        }

        // set expires text
        if (expiresDate) {
          challengeData.expiresAt = `${copies.countdown.expiresAt} ${copies.countdown.el} ${getDate(expiresDate)} ${getHours(
            expiresDate
          )}`
        }
      } else {
        //  less than 24 hours
        const end = expiresDate && DateTime.fromMillis(expiresDate)
        const diffTime = end && end.diff(now, ['hours', 'minutes']).toObject()

        // flag to set cta color
        challengeData.isNextToExpire = true
        // set cta label
        if (props.challenge.projectData && !props.challenge.projectData?.chat?.slice(-1)[0].flags.fromStudent) {
          challengeData.label = copies.EntregaCard.ctaLabel.deliverAgain
        } else {
          challengeData.label = copies.EntregaCard.ctaLabel.deliver
        }

        // set expires text
        const minutes = diffTime && diffTime.minutes && round(diffTime.minutes)
        const hours = diffTime && diffTime.hours && round(diffTime.hours)
        challengeData.expiresAt = `${copies.countdown.expiresAt} ${copies.countdown.en} ${hours}h ${minutes}m`
      }
    }

    const getFinalDeadline = (timeline: ITimeline[]) => {
      const finalDeadline = timeline.find((step) => step.key === ITimeLineStatus.finalDeadline)
      if (finalDeadline) {
        return typeof finalDeadline.date === 'string' ? DateTime.fromISO(finalDeadline.date).toMillis() : finalDeadline.date
      } else {
        return null
      }
    }

    // Amplitude track event
    const handleTrackEvent = (name: string, props = {}) => {
      trackEvent(name, props)
    }

    onMounted(() => {
      setChallengeData()
    })

    return {
      ...toRefs(challengeData),
      copies,
      handleTrackEvent,
      ChallengesEvents
    }
  }
})
