<template>
  <path
    d="M17.3115 31.7376H13.8468C12.7004 31.7365 11.5673 31.4851 10.5227 31.0003C9.47817 30.5154 8.54607 29.8082 7.78828 28.9254C7.0305 28.0427 6.46437 27.0046 6.12746 25.8802C5.79055 24.7558 5.69058 23.5707 5.83417 22.4036C5.97776 21.2365 6.36164 20.1141 6.96032 19.1108C7.55901 18.1076 8.3588 17.2465 9.30662 16.5847C10.2544 15.9229 11.3286 15.4755 12.4578 15.2722C13.587 15.069 14.7455 15.1145 15.8562 15.4057"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M15.1475 19.6548C15.1476 17.7975 15.5823 15.9669 16.4157 14.3138C17.2491 12.6607 18.4574 11.2323 19.9409 10.1465C21.4244 9.0606 23.1407 8.34832 24.9484 8.06831C26.7562 7.7883 28.6035 7.94858 30.3383 8.53593C32.0731 9.12328 33.6456 10.1209 34.9263 11.4466C36.207 12.7722 37.1592 14.388 37.7044 16.1608C38.2497 17.9335 38.3724 19.8125 38.0624 21.6429C37.7524 23.4732 37.0187 25.2026 35.9216 26.6884"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path d="M20.1963 25.246L25.2455 20.1968L30.2946 25.246" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  <path d="M25.2461 31.7378V20.1968" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
</template>
