import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2c37ac7c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_skeleton_icon = _resolveComponent("skeleton-icon")!
  const _component_skeleton_text = _resolveComponent("skeleton-text")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { class: "p-d-flex p-ai-center" }, {
    default: _withCtx(() => [
      _createVNode(_component_skeleton_icon, { class: "feedbackIcon p-mr-3" }),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_skeleton_text, {
          maxWords: Math.floor(Math.random() * 6) + 6
        }, null, 8, ["maxWords"])
      ])
    ]),
    _: 1
  }))
}