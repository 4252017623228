import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, toDisplayString as _toDisplayString, Fragment as _Fragment, renderList as _renderList, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4fd482f7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { role: "header" }
const _hoisted_2 = { class: "p-d-flex p-ai-center" }
const _hoisted_3 = ["href"]
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "p1 text-light-gray p-ml-1" }
const _hoisted_6 = ["selected", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_menu_icon = _resolveComponent("menu-icon")!
  const _component_icon = _resolveComponent("icon")!
  const _component_course_selector = _resolveComponent("course-selector")!
  const _component_skeleton_icon = _resolveComponent("skeleton-icon")!
  const _component_notifications = _resolveComponent("notifications")!
  const _component_skeleton_box = _resolveComponent("skeleton-box")!
  const _component_trophy = _resolveComponent("trophy")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_avatar = _resolveComponent("avatar")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "open-menu p-ai-center",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleOpenMenu && _ctx.handleOpenMenu(...args)))
      }, [
        _createVNode(_component_icon, null, {
          default: _withCtx(() => [
            _createVNode(_component_menu_icon)
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_course_selector)
    ]),
    _createElementVNode("nav", null, [
      (_ctx.showButtons && _ctx.teachersSharedFolderLink && _ctx.role !== 2)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            href: _ctx.teachersSharedFolderLink,
            target: "_blank",
            rel: "noopener noreferrer",
            class: "p-button tertiary folder-links"
          }, "Ir a carpeta docente", 8, _hoisted_3))
        : _createCommentVNode("", true),
      (_ctx.showButtons && _ctx.sharedFolderLink)
        ? (_openBlock(), _createElementBlock("a", {
            key: 1,
            href: _ctx.sharedFolderLink,
            target: "_blank",
            rel: "noopener noreferrer",
            class: _normalizeClass(["p-button secondary", ['folder-links', _ctx.teachersSharedFolderLink && _ctx.sharedFolderLink && _ctx.role !== 2 ? 'secondary' : 'tertiary']])
          }, "Ir al material", 10, _hoisted_4))
        : _createCommentVNode("", true),
      (_ctx.loadingNotification)
        ? (_openBlock(), _createBlock(_component_skeleton_icon, { key: 2 }))
        : (_openBlock(), _createBlock(_component_notifications, {
            key: 3,
            notifications: _ctx.userNotifications,
            error: _ctx.errorNotifications
          }, null, 8, ["notifications", "error"])),
      (_ctx.role === 2)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
            (_ctx.loadingRanking)
              ? (_openBlock(), _createBlock(_component_skeleton_box, {
                  key: 0,
                  width: '3.125rem',
                  height: 'var(--space-md)'
                }, null, 8, ["height"]))
              : (_openBlock(), _createBlock(_component_router_link, {
                  key: 1,
                  to: "/ranking",
                  class: "p-d-flex p-ai-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_icon, { strokeColor: 'var(--light-gray)' }, {
                      default: _withCtx(() => [
                        _createVNode(_component_trophy)
                      ]),
                      _: 1
                    }, 8, ["strokeColor"]),
                    _createElementVNode("span", _hoisted_5, " #" + _toDisplayString(_ctx.userRanking), 1)
                  ]),
                  _: 1
                }))
          ], 64))
        : _createCommentVNode("", true),
      _createVNode(_component_router_link, {
        to: "/profile",
        class: "avatar"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_avatar, {
            name: _ctx.firstName,
            role: _ctx.role,
            level: _ctx.level,
            imageLink: _ctx.imageLink
          }, null, 8, ["name", "role", "level", "imageLink"])
        ]),
        _: 1
      }),
      (_ctx.MOCK_USER)
        ? (_openBlock(), _createElementBlock("select", {
            key: 5,
            name: "",
            id: "",
            onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleChangeUser && _ctx.handleChangeUser(...args))),
            class: "mock-user-select"
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users, (user) => {
              return (_openBlock(), _createElementBlock("option", {
                key: user.id,
                selected: _ctx.userId === user.id,
                value: user.id
              }, _toDisplayString(user.label), 9, _hoisted_6))
            }), 128))
          ], 32))
        : _createCommentVNode("", true)
    ])
  ]))
}