// Models
import { IClasses, Classes, IComment, Comment, ITagData, TagData, IRatingScore, RatingScore } from '@/models/ratings/ratings'

import { Api } from '../api'

async function getClasses(commissionNumber: string): Promise<Classes[]> {
  const classes = await Api.get<IClasses[]>(`/v1/commission/${commissionNumber}/schedules`)
  return classes.map(c => new Classes(c))
}

async function getComments(commissionId: string, classId?: string): Promise<Comment[]> {
  const filterByClass = classId ? `?classId=${classId}` : ''
  const comments = await Api.get<IComment[]>(`/v1/commission/${commissionId}/stats/comments${filterByClass}`)
  return comments.map(c => new Comment(c))
}

async function getTags(commissionId: string, classId?: string): Promise<TagData[]> {
  const filterByClass = classId ? `?classId=${classId}` : ''
  const tags = await Api.get<ITagData[]>(`/v1/commission/${commissionId}/stats/tags${filterByClass}`)
  return tags.map(t => new TagData(t))
}

async function getRating(commissionId: string, classId?: string): Promise<RatingScore | null> {
  const filterByClass = classId ? `?classId=${classId}` : ''
  const rating = await Api.get<IRatingScore[]>(`/v1/commission/${commissionId}/stats/rating${filterByClass}`)
  return rating.length ? new RatingScore(rating[0]) : null
}

export {
  getClasses,
  getComments,
  getTags,
  getRating
}