import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e42d6422"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-d-flex p-ai-center" }
const _hoisted_2 = { class: "p-ml-4 p-mt-3" }
const _hoisted_3 = { class: "p-d-flex gap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SkeletonIcon = _resolveComponent("SkeletonIcon")!
  const _component_SkeletonText = _resolveComponent("SkeletonText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SkeletonIcon, { class: "icon" }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SkeletonText, { length: 12 }),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_SkeletonText, {
          class: "p-mt-2",
          length: 10
        }),
        _createVNode(_component_SkeletonText, {
          class: "p-mt-2",
          length: 12
        })
      ])
    ])
  ]))
}