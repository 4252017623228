import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")!
  const _component_placeholder_state = _resolveComponent("placeholder-state")!
  const _component_skeleton_profile = _resolveComponent("skeleton-profile")!
  const _component_profile_card_student = _resolveComponent("profile-card-student")!
  const _component_profile_card = _resolveComponent("profile-card")!
  const _component_profile_card_connection = _resolveComponent("profile-card-connection")!
  const _component_form_edit_profile = _resolveComponent("form-edit-profile")!
  const _component_profile_student = _resolveComponent("profile-student")!
  const _component_profile_teacher = _resolveComponent("profile-teacher")!
  const _component_custom_transition = _resolveComponent("custom-transition")!
  const _component_two_cols_layout = _resolveComponent("two-cols-layout")!

  return (_ctx.isLoading || _ctx.isLocaleLoading)
    ? (_openBlock(), _createBlock(_component_Loader, { key: 0 }))
    : (_openBlock(), _createBlock(_component_two_cols_layout, { key: 1 }, {
        aside: _withCtx(() => [
          (_ctx.isError)
            ? (_openBlock(), _createBlock(_component_placeholder_state, {
                key: 0,
                isComponentError: "",
                role: _ctx.user.role
              }, null, 8, ["role"]))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_ctx.isLoading)
                  ? (_openBlock(), _createBlock(_component_skeleton_profile, { key: 0 }))
                  : (_ctx.user.role === 2)
                    ? (_openBlock(), _createBlock(_component_profile_card_student, {
                        key: 1,
                        user: _ctx.user,
                        course: _ctx.courseName,
                        information: _ctx.information,
                        editingProfile: _ctx.editingProfile,
                        isVisitorView: _ctx.isVisitorView,
                        onOnClickEdit: _ctx.toggleEditProfile,
                        ref: "profileCardComponent"
                      }, null, 8, ["user", "course", "information", "editingProfile", "isVisitorView", "onOnClickEdit"]))
                    : (_ctx.user.role !== 2)
                      ? (_openBlock(), _createBlock(_component_profile_card, {
                          key: 2,
                          user: _ctx.user,
                          course: _ctx.courseName,
                          information: _ctx.information,
                          editingProfile: _ctx.editingProfile,
                          isVisitorView: _ctx.isVisitorView,
                          onOnClickEdit: _ctx.toggleEditProfile
                        }, null, 8, ["user", "course", "information", "editingProfile", "isVisitorView", "onOnClickEdit"]))
                      : _createCommentVNode("", true),
                (!_ctx.editingProfile)
                  ? (_openBlock(), _createBlock(_component_profile_card_connection, {
                      key: 3,
                      user: _ctx.user
                    }, null, 8, ["user"]))
                  : _createCommentVNode("", true)
              ], 64))
        ]),
        content: _withCtx(() => [
          _createVNode(_component_custom_transition, null, {
            default: _withCtx(() => [
              (_ctx.editingProfile && _ctx.user.role === 2)
                ? (_openBlock(), _createBlock(_component_form_edit_profile, {
                    key: 0,
                    onOnSubmit: _ctx.handleSubmitEditProfile,
                    onOnCancel: _ctx.toggleEditProfile,
                    information: _ctx.information,
                    loadingSubmit: _ctx.loadingSubmitEditProfile
                  }, null, 8, ["onOnSubmit", "onOnCancel", "information", "loadingSubmit"]))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    (_ctx.isVisitorView)
                      ? (_openBlock(), _createBlock(_component_profile_student, {
                          key: 0,
                          userId: _ctx.userIdParams,
                          isVisitorView: true
                        }, null, 8, ["userId"]))
                      : (_ctx.user.role === 2)
                        ? (_openBlock(), _createBlock(_component_profile_student, {
                            key: 1,
                            userId: _ctx.userId
                          }, null, 8, ["userId"]))
                        : (_openBlock(), _createBlock(_component_profile_teacher, {
                            key: 2,
                            userId: _ctx.userId
                          }, null, 8, ["userId"]))
                  ], 64))
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
}