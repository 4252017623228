
// Vendor
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { DateTime } from 'luxon'

// API
import { getChallengesDataService, TGetChallenges } from '@/services/challenges.services'

// Store
import { useCourseStore } from '@/store/modules/course.store'
import { useUserSessionStore } from '@/store/modules/user-session.store'

// Models
import { IChallenge } from '@/models/newPlatform/challenges/challenge'
import { ITimeLineStatus, ITimeline } from '@/models/newPlatform/challenges/timeline'

// Components
import TwoColsLayout from '@/components/aero/layout/TwoColsLayout.vue'
import EntregaCard from '@/components/aero/surfaces/challenges/EntregaCard.vue'
import DesafioCard from '@/components/aero/surfaces/challenges/DesafioCard.vue'
import SkeletonBecaCard from '@/components/aero/skeleton/SkeletonBecaCard.vue'
import SkeletonDesafioCard from '@/components/aero/skeleton/SkeletonDesafioCard.vue'
import UpToDatePlaceHolder from '@/components/aero/surfaces/challenges/UpToDatePlaceHolder.vue'
import ErrorPage from '@/components/aero/dataDisplay/ErrorPage.vue'

// PrimeVue Components
import Menu from 'primevue/menu'
import Badge from 'primevue/badge'

// Amplitude
import { ChallengesEvents } from '@/amplitude/constants'
import { trackEvent } from '@/amplitude/actions'

export default defineComponent({
  components: {
    TwoColsLayout,
    DesafioCard,
    EntregaCard,
    ErrorPage,
    SkeletonBecaCard,
    SkeletonDesafioCard,
    Menu,
    Badge,
    UpToDatePlaceHolder
  },
  props: {
    userId: { type: String, required: true },
    copies: { type: Object, required: true },
    useCoderChallenge: { type: Boolean, required: true }
  },
  setup(props) {
    const { id: courseId, camadaNro } = useCourseStore()
    const session = useUserSessionStore()
    const challenges = ref<Array<IChallenge>>([])
    const openChallenges = ref<IChallenge[]>([])
    const isLoading = ref<boolean>(true)
    const isError = ref<boolean>(false)
    // Dropdown Menu
    const menu = ref()
    const toggle = (event: Event) => menu.value.toggle(event)

    const menuToggle = () => {
      menuIsOpen.value = !menuIsOpen.value
    }

    const getChallenges = async () => {
      const route = useRoute()

      const { mockdata, order } = route.query
      const rolParam = route.query.rol || 2
      const getChallengeDataProps: TGetChallenges = {
        returnMockData: !!mockdata,
        rol: `${rolParam}`,
        order: Number(order),
        userId: session.userId,
        camadaId: camadaNro,
        courseId: courseId
      }
      try {
        const response = await getChallengesDataService(getChallengeDataProps)
        challenges.value = response
      } catch (error) {
        isError.value = true
      } finally {
        isLoading.value = false
      }
    }

    // Available filter slugs for challenges
    const challengeFilters = ref<'all' | 'pending' | 'delivered' | 'approved'>('all')

    // Changes filter depending on selected option in Menu dropdown
    const setChallengeFilter = (filter: 'all' | 'pending' | 'delivered' | 'approved') => (challengeFilters.value = filter)

    // Array used for passing options to Menu component
    const filterOptions = [
      { label: props.copies.filterLabels.viewAll, value: 'all', command: () => setChallengeFilter('all') },
      { label: props.copies.filterLabels.pending, value: 'pending', command: () => setChallengeFilter('pending') },
      { label: props.copies.filterLabels.delivered, value: 'delivered', command: () => setChallengeFilter('delivered') },
      { label: props.copies.filterLabels.approved, value: 'approved', command: () => setChallengeFilter('approved') }
    ]

    const menuIsOpen = ref(false)

    // Watch for filter change in order to look for challenges matching the criteria
    const filteredChallenges = computed(() => {
      if (challengeFilters.value === 'all') {
        return challenges.value
      } else {
        return challenges.value.filter((_challenge) => _challenge.status === challengeFilters.value)
      }
    })

    // gather pending challenges, such as undelivered o need of deliver again, and sort based on date close to expire
    const setPendingChallenges = computed(() => {
      const pending = openChallenges.value
        .filter((challenge) => challenge.expiresAt && challenge.expiresAt > Date.now())
        .filter((challenge) => challenge.status === 'not delivered')

      const deliverAgain = openChallenges.value.filter((challenge) => {
        const finalDeadline = challenge.timeline && getFinalDeadline(challenge.timeline)
        const unlockedUntil =
          challenge.projectData?.unlockedUntil && typeof challenge.projectData?.unlockedUntil === 'string'
            ? DateTime.fromISO(challenge.projectData?.unlockedUntil).toMillis()
            : challenge.projectData?.unlockedUntil

        if (unlockedUntil) {
          return (
            challenge.projectData &&
            challenge.status !== 'approved' &&
            !challenge.projectData?.flags?.isLocked &&
            challenge.projectData?.chat?.length &&
            !challenge.projectData?.chat[challenge.projectData?.chat.length - 1].flags.fromStudent &&
            unlockedUntil &&
            unlockedUntil > Date.now()
          )
        } else {
          return (
            challenge.projectData &&
            challenge.status !== 'approved' &&
            !challenge.projectData?.flags?.isLocked &&
            challenge.projectData?.chat?.length &&
            !challenge.projectData?.chat[challenge.projectData?.chat.length - 1].flags.fromStudent &&
            finalDeadline &&
            finalDeadline > Date.now()
          )
        }
      })

      const fullList = [...pending, ...deliverAgain]
      if (!fullList.length) return []
      return fullList.sort((a, b) => (a.expiresAt || 0) - (b.expiresAt || 0))
    })

    const getFinalDeadline = (timeline: ITimeline[]) => {
      const finalDeadline = timeline.find((step) => step.key === ITimeLineStatus.finalDeadline)
      if (finalDeadline) {
        return typeof finalDeadline.date === 'string' ? DateTime.fromISO(finalDeadline.date).toMillis() : finalDeadline.date
      } else {
        return null
      }
    }

    // Amplitude track event
    const handleTrackEvent = (name: string, props = {}) => {
      trackEvent(name, props)
    }

    onMounted(async () => {
      await getChallenges()
      challenges.value.forEach((_module) => {
        _module.class?.day && _module.class.day < DateTime.local().toMillis() && openChallenges.value.push(_module)
      })

      challenges.value = challenges.value.map((challenge) => {
        let statusTag = ''
        const isDeliverExpired = DateTime.fromMillis(challenge.expiresAt || 0) < DateTime.local()
        const isOpen = DateTime.fromMillis(challenge.class?.day || 0) < DateTime.local()
        const finalDeadline = challenge.timeline && challenge.timeline.find((step) => step.key === ITimeLineStatus.finalDeadline)
        if (finalDeadline) {
          finalDeadline.date =
            typeof finalDeadline.date === 'string' ? DateTime.fromISO(finalDeadline.date).toMillis() : finalDeadline.date
        }

        const unlockedUntil =
          challenge.projectData?.unlockedUntil && typeof challenge.projectData?.unlockedUntil === 'string'
            ? DateTime.fromISO(challenge.projectData?.unlockedUntil).toMillis()
            : challenge.projectData?.unlockedUntil

        if (!isOpen) {
          statusTag = ''
        } else {
          if (challenge.status === 'approved') {
            statusTag = 'approved'
          } else if (
            (challenge.status === 'delivered' || challenge.status === 'disapproved') &&
            unlockedUntil &&
            unlockedUntil > Date.now()
          ) {
            statusTag = 'delivered'
          } else if (
            (challenge.status === 'delivered' || challenge.status === 'disapproved') &&
            finalDeadline &&
            finalDeadline.date > Date.now()
          ) {
            statusTag = 'delivered'
          } else if (challenge.status === 'not delivered' && !isDeliverExpired) {
            statusTag = 'pending'
          } else {
            statusTag = 'expired'
          }
        }

        return { ...challenge, status: statusTag }
      })

      handleTrackEvent(ChallengesEvents.LIST_CHALLENGES)
    })

    return {
      filteredChallenges,
      filterOptions,
      challengeFilters,
      isLoading,
      isError,
      setChallengeFilter,
      setPendingChallenges,
      menu,
      toggle,
      menuToggle,
      menuIsOpen,
      handleTrackEvent,
      camadaNro,
      ChallengesEvents
    }
  }
})
