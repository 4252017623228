import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_challenges_student = _resolveComponent("challenges-student")!
  const _component_challenges_teacher = _resolveComponent("challenges-teacher")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.role === 2)
      ? (_openBlock(), _createBlock(_component_challenges_student, {
          key: 0,
          userId: _ctx.userId,
          useCoderChallenge: _ctx.useCoderChallenge,
          copies: _ctx.copies
        }, null, 8, ["userId", "useCoderChallenge", "copies"]))
      : _createCommentVNode("", true),
    (_ctx.role !== 2)
      ? (_openBlock(), _createBlock(_component_challenges_teacher, {
          key: 1,
          userId: _ctx.userId,
          useCoderChallenge: _ctx.useCoderChallenge,
          isTutor: _ctx.isTutor,
          copies: _ctx.copies
        }, null, 8, ["userId", "useCoderChallenge", "isTutor", "copies"]))
      : _createCommentVNode("", true)
  ], 64))
}