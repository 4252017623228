
// Vendor
import { defineComponent, PropType } from 'vue'

// Components
import Card from '@/components/aero/surfaces/Card.vue'

export default defineComponent({
  props: {
    feedbackType: {
      type: String as PropType<'positive' | 'neutral' | 'negative'>,
      required: true
    },
    message: {
      type: String,
      required: true
    }
  },
  components: {
    Card
  }
})
