import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "subtitle" }
const _hoisted_2 = { class: "helpParagraph" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_transfer_balance_modal = _resolveComponent("transfer-balance-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.t('profile.available-balance.title')), 1),
      _createVNode(_component_DataTable, {
        value: _ctx.formattedBalances,
        responsiveLayout: "scroll"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            field: "createdAt",
            header: _ctx.t('profile.available-balance.table.date')
          }, null, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "formatedPrice",
            header: _ctx.t('profile.available-balance.table.available')
          }, null, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "status",
            header: _ctx.t('profile.available-balance.table.status')
          }, null, 8, ["header"]),
          _createVNode(_component_Column, null, {
            body: _withCtx((slotProps) => [
              (_ctx.isBalanceAvailable(slotProps.data.original))
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    type: "button",
                    class: "p-button p-component secondary",
                    label: _ctx.t('profile.available-balance.table.transfer-btn'),
                    onClick: ($event: any) => (_ctx.openModalToTransferBalance(slotProps.data.original))
                  }, null, 8, ["label", "onClick"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value"]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", {
          class: "supportFont",
          style: {"font-size":"14px"},
          innerHTML: _ctx.t('profile.available-balance.support')
        }, null, 8, _hoisted_3)
      ])
    ]),
    _createVNode(_component_transfer_balance_modal, {
      displayModal: _ctx.transferBalanceModal,
      balance: _ctx.balanceToTransfer,
      onCloseModal: _ctx.closeModal,
      onTransferBalance: _ctx.transferBalance
    }, null, 8, ["displayModal", "balance", "onCloseModal", "onTransferBalance"])
  ], 64))
}