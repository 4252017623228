
import { defineComponent, reactive, ref, onMounted } from 'vue'

// Copies
import copies from '@/locales/profile/es.json'
import { useI18n } from 'vue-i18n'

// Components
import CourseList from '@/views/profile/components/CourseList.vue'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import TrainingView from '@/views/profile/components/TrainingView/TrainingView.vue'
import ProfileUnsubscribe from '@/views/profile/components/ProfileUnsubscribe.vue'
import { useUserStore } from '@/store/modules/user.store'
import { getUserAvailableBalance } from '@/api/newPlatform/profile.api'
import { UserBalance } from '@/models/profile/balance'
import AvailableBalance from './AvailableBalance.vue'
import { useUserSessionStore } from '@/store/modules/user-session.store'
import Button from 'primevue/button'
import moment from 'moment'

export default defineComponent({
  components: {
    TabView,
    TabPanel,
    CourseList,
    TrainingView,
    ProfileUnsubscribe,
    AvailableBalance
  },
  props: {
    userId: { type: String, required: true },
    isVisitorView: { type: Boolean, default: false },
    visitRole: { type: Number, required: false }
  },
  setup() {
    const { t } = useI18n()
    const isIframe = ref()
    const session = useUserSessionStore()
/*     const coderPlusActive = ref(false)
    const coderPlusPrice = ref()
    const coderPlusCurrency = ref()
    const coderPlusAmountOfIntervals = ref()
    const coderPlusLinkPurchased = ref()
    const showCoderPlusTab = ref()
    const coderPlusEndDate = ref() */
    const idToken = session.token
    const accessToken = session.auth
    const userID = ref()
    const country = ref()

    const unsubscribe = reactive({
      show: false,
      courseId: '',
      camadaNro: '',
      courseName: ''
    })

    const handleShowUnsubscribeForm = (values: { [k: string]: string }) => {
      unsubscribe.show = true
      unsubscribe.courseId = values.courseId
      unsubscribe.camadaNro = values.camadaNro
      unsubscribe.courseName = values.courseName
    }
    const { id: userId } = useUserStore()

    /* const showCoderPlus = async () => {
    const user = useUserStore()
    await user.getUser()

    showCoderPlusTab.value = user.coderPlus
    userID.value = user.id
    country.value = user.country
    }

    showCoderPlus()

    const isCoderPlus = async (idToken: string, accessToken: string) => {
      try {
        const url = process.env.VUE_APP_CH_API_BFF
        const res = await fetch(`${url}/coder-plus`,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            idToken,
            accessToken
          },
          mode: 'cors'
        })
        const data = await res.json()
        if (data.isCoderPlus) {
          coderPlusActive.value = true
          coderPlusEndDate.value = moment(data.endDate).format('DD/MMM/YYYY')
        } else {
          coderPlusActive.value = false
          coderPlusPrice.value = data.price / 3
          coderPlusCurrency.value = data.currency
          coderPlusAmountOfIntervals.value = data.amountOfIntervals
          coderPlusLinkPurchased.value = data.linkPurchased
        }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log('hay un error', error)
        }
      }

    isCoderPlus(idToken, accessToken)
 */
    const availableBalances = ref<UserBalance[]>([])
    const disableBalanceTab = ref(true)

    const getUserAvailabeBalance = async () => {
      let userBalances = await getUserAvailableBalance(userId)
      if(!userBalances || userBalances.length === 0) return

      userBalances = userBalances.filter(balance => balance.price > 0)
      availableBalances.value = userBalances

      if (userBalances.some((balance) => balance.claimed === false)) {
        disableBalanceTab.value = false
      }
    }

    getUserAvailabeBalance()

    onMounted(() => {
      isIframe.value = localStorage.getItem('isNewPlatform') === 'true'
    })

    const balanceTransferred = () => {
      getUserAvailabeBalance()
    }

    return {
      copies,
      unsubscribe,
      handleShowUnsubscribeForm,
      availableBalances,
      t,
      isIframe,
/*       coderPlusActive,
      coderPlusPrice,
      coderPlusCurrency,
      coderPlusAmountOfIntervals,
      coderPlusLinkPurchased,
      coderPlusEndDate,
      showCoderPlusTab, */
      userID,
      country,
      balanceTransferred
    }
  }
})
