import { createApp } from 'vue'
import axios from 'axios'
import Hotjar from 'vue-hotjar'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import amplitude from 'amplitude-js'
import 'primevue/resources/themes/saga-blue/theme.css' // theme
import 'primevue/resources/primevue.min.css' // core css
import 'primeicons/primeicons.css' // icons
import PrimeVue from 'primevue/config'
import ToastService from 'primevue/toastservice'
import Tooltip from 'primevue/tooltip'
import ConfirmationService from 'primevue/confirmationservice'
import { registerCommonComponents } from './commonComponents'
import { registerCommonDirectives } from './commonDirectives'
import 'primeflex/primeflex.css' // Support for Flex

import '@/assets/ch-common.css'
import '@/assets/ch-core'

import '@/assets/styles/new_theme/styles.scss'
import '@/assets/styles/old_theme/styles.scss'

import { useI18nStore } from '@/store/modules/i18n.store'

import { useFirebaseStore } from '@/store/modules/firebase.store'

// set luxon in spa / latam
import { Settings } from 'luxon'
Settings.defaultLocale = 'es'

const { hostname, port, protocol } = window.location

let { ENV_PROD = false, NODE_ENV = 'preview' } = process.env
ENV_PROD = !!Number(ENV_PROD) || NODE_ENV === 'production'

// El acceso al API se a través de un proxy en el mismo servicio.

declare global {
  interface Window {
    dataLayer?: any;
  }
}

window.dataLayer = window.dataLayer || []

axios.defaults.withCredentials = false // Set this to false until we use cookies to handle session against the ch-api

// TODO mover !!
// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error)
  }
)

// set amplitude
const amplitudeInstance = amplitude.getInstance()
const { AMPLITUDE_DEV_API_KEY, AMPLITUDE_PROD_API_KEY } = process.env

if (process.env.NODE_ENV === 'production') {
  AMPLITUDE_PROD_API_KEY && amplitudeInstance.init(AMPLITUDE_PROD_API_KEY)
} else {
  AMPLITUDE_DEV_API_KEY && amplitudeInstance.init(AMPLITUDE_DEV_API_KEY)
}

const app = createApp(App)
  .use(createPinia())
  .use(router)
  .use(PrimeVue)
  .use(ToastService)
  .use(ConfirmationService)
  .directive('tooltip', Tooltip)
  .use(Hotjar, { id: '2605828', isProduction: ENV_PROD })
  .use(useI18nStore().getI18n)
  .use(useFirebaseStore().initApp)
// Vue.config.devtools = true

registerCommonComponents(app)
registerCommonDirectives(app)

app.mount('#app')
