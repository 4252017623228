import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3d6480ef"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "url-block-cont" }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Link = _resolveComponent("Link")!
  const _component_icon_base = _resolveComponent("icon-base")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      href: _ctx.link,
      class: "url-block",
      target: "_blank"
    }, [
      _createVNode(_component_icon_base, {
        class: "link-icon",
        "stroke-color": "#ffffff",
        "fill-color": "#E5F876"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Link)
        ]),
        _: 1
      }),
      _createElementVNode("span", null, _toDisplayString(_ctx.link), 1)
    ], 8, _hoisted_2)
  ]))
}