
// Vendor
import { defineComponent, PropType, ref, watch, onMounted } from 'vue'

// Components
import ListCardComision from '@/components/aero/surfaces/ListCardComision.vue'
import CommunityTag from '@/components/aero/dataDisplay/CommunityTag.vue'
import PlaceholderState from '@/components/aero/surfaces/challenges/PlaceholderState.vue'

// PrimeVue Components
import Menu from 'primevue/menu'

// Copies
import copies from '@/locales/profile/es.json'

// Model
import { IncomingClass } from '@/models/newPlatform/products/productCourse'

export default defineComponent({
  components: {
    CommunityTag,
    Menu,
    PlaceholderState,
    ListCardComision
  },
  props: {
    leadTeacherId: {
      type: String,
      required: true
    },
    incomingClasses: {
      type: Array as PropType<IncomingClass[]>,
      required: true
    }
  },
  emits: ['onSelectComission'],
  setup(props, { emit }) {
    const radioRef = ref<string>('')

    // Dropdown Menu
    const menu = ref()
    const handleFilterToggle = (event: Event) => menu.value.toggle(event)

    // Variables for multiple filtering
    const incomingClasses = ref<IncomingClass[]>(props.incomingClasses)
    const firstFilterIncomingClasses = ref<IncomingClass[]>(props.incomingClasses) // Button filter
    const secondFilterIncomingClasses = ref<IncomingClass[]>(props.incomingClasses) // Tag filter

    /* Filtering Options */

    // Filter by tags
    type tagValues = 'weekday' | 'saturdays' | 'sameTeacher'
    const tagFilter = ref<tagValues[]>([])

    const tagsFromCopies = copies.studentChangeCourse.filter.tag
    const tags = Object.entries(tagsFromCopies).map(([key, { label, val }]) => ({ key, val, label }))

    const handleTagActive = (tagLabel: tagValues) => {
      if (tagFilter.value.includes(tagLabel)) {
        tagFilter.value = tagFilter.value.filter((t) => t !== tagLabel)
      } else {
        tagFilter.value.push(tagLabel)
      }
    }

    // Filter by Dropdown Button
    type filterByMonth = 'currmonth' | 'nextmonth' | 'next6months'
    const btnFilter = ref<filterByMonth | null>(null)
    const udpateFilter = (filter: filterByMonth) => (btnFilter.value = filter)

    const filterOptions = [
      {
        label: copies.studentChangeCourse.filter.menu.menu1,
        value: 'currmonth',
        command: () => udpateFilter('currmonth')
      },
      {
        label: copies.studentChangeCourse.filter.menu.menu2,
        value: 'nextmonth',
        command: () => udpateFilter('nextmonth')
      },
      {
        label: copies.studentChangeCourse.filter.menu.menu3,
        value: 'next6months',
        command: () => udpateFilter('next6months')
      }
    ]

    // Is current month if is same month and year
    const currMonthFilter = (incClass: IncomingClass) => {
      const [currMonth, currYear] = [new Date().getMonth(), new Date().getFullYear()]
      const [incMonth, incYear] = [new Date(incClass.startDate).getMonth(), new Date(incClass.startDate).getFullYear()]

      return incMonth === currMonth && incYear === currYear
    }

    // Is next month if is same month and year, except for December
    const nextMonthFilter = (incClass: IncomingClass) => {
      const [currMonth, currYear] = [new Date().getMonth(), new Date().getFullYear()]
      const [incMonth, incYear] = [new Date(incClass.startDate).getMonth(), new Date(incClass.startDate).getFullYear()]

      // If is December check next month to be January
      if (currMonth === 11) return incMonth === 0 && incYear === currYear + 1

      return incMonth === currMonth + 1 && incYear === currYear
    }

    // Is next 6 months if the date of the incoming class is 'lower than' adding six montths to 'now' date
    const next6MonthsFilter = (incClass: IncomingClass) => {
      const sixMonthsFromNow = new Date()
      // Six months from now
      sixMonthsFromNow.setMonth(sixMonthsFromNow.getMonth() + 7)
      sixMonthsFromNow.setDate(0)
      sixMonthsFromNow.setHours(23, 59, 59, 0)

      const incDate = new Date(incClass.startDate)

      return incDate <= sixMonthsFromNow
    }

    // watch btnFilter and deep watch tagFilter array

    const firstFiltering = () => {
      firstFilterIncomingClasses.value = incomingClasses.value.filter((incClass) => {
        if (btnFilter.value === 'currmonth') return currMonthFilter(incClass)
        if (btnFilter.value === 'nextmonth') return nextMonthFilter(incClass)
        if (btnFilter.value === 'next6months') return next6MonthsFilter(incClass)
        return true
      })

      radioRef.value = ''
      secondFilterIncomingClasses.value = firstFilterIncomingClasses.value
      secondFiltering()
    }
    watch(btnFilter, firstFiltering)

    const secondFiltering = () => {
      const weekday = tagFilter.value.includes('weekday')
      const saturday = tagFilter.value.includes('saturdays')
      const sameTeacher = tagFilter.value.includes('sameTeacher')

      secondFilterIncomingClasses.value = firstFilterIncomingClasses.value.filter((incClass) => {
        if (weekday && !saturday && incClass.numDays.includes(6)) return false
        if (saturday && !weekday && !incClass.numDays.includes(6)) return false

        if (sameTeacher && incClass.leadTeacherId !== props.leadTeacherId) return false
        return true
      })
    }

    watch(() => [...tagFilter.value], secondFiltering)

    // DOM handler
    const handleChooseCourse = (value: string) => {
      emit('onSelectComission', value)
    }

    onMounted(() => {
      udpateFilter('currmonth')
    })

    return {
      menu,
      filterOptions,
      handleFilterToggle,
      handleChooseCourse,
      copies,
      tags,
      secondFilterIncomingClasses,
      btnFilter,
      handleTagActive
    }
  }
})
