
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    home: {
      type: Object,
      default: null
    },
    model: {
      type: Array,
      default: () => []
    }
  },
  setup (props, { emit }) {
    const goBack = () => {
      window.parent.postMessage(JSON.stringify({ type: 'history-back' }), '*')
    }

    return {
      goBack
    }
  }
})
