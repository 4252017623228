import { defineStore } from 'pinia'
import { ThemesMagament } from '@/models/themes'
export const themeStore = defineStore({
  id: 'theme-store',
  state () {
    const theme : ThemesMagament = new ThemesMagament()
    return {theme}
  },
  getters: {
    default ():string {
      return this.theme.getDefault()
    } 
  },
  actions: {
    add (key: string, value: string): void {
      this.theme.addTheme(key,value)
    },
    addThemeToDocument(key:string): void {
      document.body.classList.add(key)
    },
    changeDefault (key:string): void{
      this.theme.changeDefaultTheme(key)
      const isNewPlatform = localStorage.getItem('isNewPlatform') === 'true'
      document.body.className = isNewPlatform ? 'new-theme is-iframe' : (this.theme.getDefault() || 'new-theme')
    }
  }
})
