
// Vendor
import { defineComponent } from 'vue'

// Components
import Card from '@/components/aero/surfaces/Card.vue'
import SkeletonText from './SkeletonText.vue'
import SkeletonInput from './SkeletonInput.vue'

export default defineComponent({
  components: {
    Card,
    SkeletonText,
    SkeletonInput
  },
  props: {}
})
