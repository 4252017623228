import { getProductCourses, getGeneralProducts } from '@/api/newPlatform/products.api'
import { ProductCourse } from '@/models/newPlatform/products/productCourse'
import { Product } from '@/models/newPlatform/products/products'

/**
 * Service to fetch all incoming courses from the same category the user is current on
 * @param param.country `required` current country
 * @param param.type 'required' courses to be remote or not
 * @returns an array of courses
 */
export const getIncomingCoursesOfSameCategory = async (
  country: string,
  type: string,
  productId: string
): Promise<ProductCourse[]> => {
  const productCourses = await getProductCourses(productId, country, type)
  return productCourses
}

/**
 * Service to fetch all courses from all categories
 * It includes general information of the courses without specifying incoming classes.
 * @param param.country `required` current country
 * @param param.type 'required' courses to be remote or not
 * @returns an array of courses
 */

export const getAllProductsService = async (country: string, type: string): Promise<Product[]> => {
  return await getGeneralProducts(country, type)
}
