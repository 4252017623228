import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4c486114"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-d-flex p-flex-column p-header" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: require('@/assets/icons/coderhouse-alt.svg'),
      alt: "coderhouse",
      class: "logo"
    }, null, 8, _hoisted_2),
    _createElementVNode("h2", null, _toDisplayString(_ctx.t("onboarding.header.title")), 1),
    _createElementVNode("h3", null, _toDisplayString(_ctx.t("onboarding.header.subtitle")), 1)
  ]))
}