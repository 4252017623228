
import { defineComponent, ref, onMounted, watch, PropType } from 'vue'
import { useRouter } from 'vue-router'

// Services
import { getRequests, requestUnenroll, discardRequest } from '@/services/newPlatform/profile.services'

import InputWrapper from '@/components/aero/inputs/InputWrapper.vue'
import Toast from '@/components/aero/feedback/Toast.vue'

// Prime vue
import Textarea from 'primevue/textarea'
import Dialog from 'primevue/dialog'

// Hooks
import { useToast } from 'primevue/usetoast'

// locales
import { unsubscribe } from '@/locales/profile/es.json'

// Models
import { SelfManagementRequest } from '@/models/newPlatform/selfManagement'

// Amplitude
import { trackEvent } from '@/amplitude/actions'
import { ProfileEvents } from '@/amplitude/constants'

type Course = {
  _id: string
  name: string
  camadaNro: string
}

export default defineComponent({
  components: { Textarea, InputWrapper, Dialog, Toast },
  emits: ['onSubmit', 'onCancel'],
  props: {
    userId: { type: String, required: true },
    course: { type: Object as PropType<Course>, required: true }
  },
  setup(props, { emit }) {
    const comment = ref('')
    const commentError = ref(unsubscribe.textarea.error)
    const loadingSubmit = ref<boolean>(false)
    const disableSubmit = ref<boolean>(true)
    const DisplayFormBtns = ref<boolean>(true)

    const router = useRouter()
    const toast = useToast()

    const options = unsubscribe.options.map((option) => Object.values(option)[0])

    const reenrollStatus = {
      requests: [] as SelfManagementRequest[],
      requestId: '',
      alreadyRequested: false
    }
    // dialog to communicate that a request is already is progress
    const displayDialog = ref<boolean>(false)

    const setRequests = async () => {
      try {
        const requests = await getRequests(props.userId, props.course._id)
        reenrollStatus.requests = requests

        if (requests.length) {
          const currentRequest = requests.find((item) => {
            if (item.fromCourse === props.course._id && ['process', 'processs'].includes(item.status)) {
              return ['reenroll', 'unenroll', 'change', 'transfer'].includes(item.type)
            } else {
              return false
            }
          })
          if (currentRequest?._id) {
            reenrollStatus.alreadyRequested = true
            reenrollStatus.requestId = currentRequest._id
          }
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e, 'error while trying to retrieve requests data')
        throw e
      }
    }

    watch(comment, () => {
      if (comment.value.length >= 50) {
        disableSubmit.value = false
        commentError.value = ''
      } else {
        disableSubmit.value = true
        commentError.value = unsubscribe.textarea.error
      }
    })

    const finishRequest = (severity: 'success' | 'error', message: string) => {
      const { group, life } = unsubscribe.toast.toastSeverity
      toast.add({ severity, detail: message, group, life })

      router.push('/profile')
      // hide form
      emit('onCancel')
    }

    const handleSubmit = async () => {
      loadingSubmit.value = true

      const body = {
        courseId: props.course._id,
        userId: props.userId,
        unenrollReason: comment.value
      }

      const { success, error } = unsubscribe.toast.sendRequest
      try {
        await requestUnenroll(body)

        trackEvent(ProfileEvents.UNSUBSCRIBE_COURSE)

        finishRequest('success', success)
      } catch (e) {
        finishRequest('error', error)
      } finally {
        loadingSubmit.value = false
      }
    }

    const handleDiscardRequest = async () => {
      const body = {
        userId: props.userId,
        requestId: reenrollStatus.requestId
      }
      loadingSubmit.value = true
      const { success, error } = unsubscribe.toast.discardRequest

      try {
        await discardRequest(body)

        trackEvent(ProfileEvents.DISCARD_REQUEST)

        finishRequest('success', success)
      } catch (e) {
        finishRequest('error', error)
      } finally {
        loadingSubmit.value = false
      }
    }

    const handleCancel = () => {
      emit('onCancel')
    }

    const handleClick = (value: string) => {
      comment.value = value + '.'

      const textarea = document.getElementById('unsubscribe_textarea')
      if (textarea) {
        textarea.focus()
      }
    }

    onMounted(async () => {
      try {
        await setRequests()
        if (reenrollStatus.alreadyRequested) {
          displayDialog.value = true
          DisplayFormBtns.value = false
        }
      } catch (e) {
        finishRequest('error', unsubscribe.toast.sendRequest.error)
      }
    })

    return {
      copies: unsubscribe,
      comment,
      commentError,
      options,
      loadingSubmit,
      disableSubmit,
      displayDialog,
      DisplayFormBtns,
      handleClick,
      handleCancel,
      handleSubmit,
      handleDiscardRequest
    }
  }
})
