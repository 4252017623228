
// Vendor
import { defineComponent, ref } from 'vue'
import { DateTime } from 'luxon'

// Components
import Icon from '@/components/aero/icon/Icon.vue'
import { ChevronRight, Check } from '@/components/aero/icon/templates'
import NotificationIcon from './NotificationIcon.vue'

// Utils
import { convertHTMLToString } from '@/utils/convertHTMLToString'
import { Tags } from './Tags'

export default defineComponent({
  components: { Icon, ChevronRight, NotificationIcon, Check },
  props: {
    id: { type: String },
    tag: { type: String },
    text: { type: String, default: '' },
    createdAt: { type: String, default: '' },
    onClick: { type: Function },
    wasRead: { type: Boolean, required: true }
  },
  emits: ['onClick'],
  setup(props, { emit }) {
    const category = ref(Tags.find((t) => t.tag === props.tag))
    const message = ref(convertHTMLToString(props.text?.toString()))
    const notifWasRead = ref(props.wasRead)

    const parseDate = (date: string) => {
      const m = DateTime.fromISO(date).toMillis()
      const n = Date.now()

      const diff = n - m
      const diffInDate = new Date(diff)

      const days = diffInDate.getDate()
      const minutes = diffInDate.getMinutes()
      const hours = diffInDate.getHours()
      const seconds = diffInDate.getSeconds()

      if (days > 0) return `${days}d`
      if (hours > 0) return `${hours}h`
      if (minutes > 0) return `${minutes}m`
      return `${seconds}s`
    }

    const at = ref(parseDate(props.createdAt))

    const handleLinkClick = () => {
      emit('onClick', { id: props.id, closePanel: true })
      notifWasRead.value = true
    }

    const handleButtonClick = () => {
      emit('onClick', { id: props.id, closePanel: false })
      notifWasRead.value = true
    }

    return { message, category, at, handleLinkClick, handleButtonClick, notifWasRead }
  }
})
