import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-37f1325d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "searchInput p-d-flex p-ai-center p-pr-3" }
const _hoisted_2 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search = _resolveComponent("search")!
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "p-d-flex p-ai-center cursor-pointer",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleUpdateSearch && _ctx.handleUpdateSearch(...args)))
    }, [
      _createVNode(_component_icon, {
        strokeColor: "var(--light-gray)",
        size: 16
      }, {
        default: _withCtx(() => [
          _createVNode(_component_search)
        ]),
        _: 1
      })
    ]),
    _withDirectives(_createElementVNode("input", {
      placeholder: _ctx.placeholderText,
      type: "text",
      class: "searchInput__input p-ml-2",
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.inputName) = $event)),
      onKeyup: _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.handleUpdateSearch && _ctx.handleUpdateSearch(...args)), ["enter"]))
    }, null, 40, _hoisted_2), [
      [_vModelText, _ctx.inputName]
    ])
  ]))
}