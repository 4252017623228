
// Vendors
import { defineComponent, PropType, ref } from 'vue'
import { required, helpers } from '@vuelidate/validators'
import { DateTime } from 'luxon'

// Components
import Form from '@/components/aero/forms/Form.vue'
import Toast from '@/components/aero/feedback/Toast.vue'

// Services
import { uploadInvoice } from '@/services/newPlatform/profile.services'

// Stores
import { useUserSessionStore } from '@/store/modules/user-session.store'

// Hooks
import { useToast } from 'primevue/usetoast'

// Models
import { Invoice } from '@/models/profile/billing'

// Utils
import { getMonth, getYear } from '@/utils/datetime'

// Copies
import copies from '@/locales/profile/es.json'

type FormTypes = {
  createdAt: string
  pointOfSale: string
  id: string
  letter: string
  amount: string
  file: File
}

export default defineComponent({
  components: { Form, Toast },
  props: {
    invoiceData: { type: Object as PropType<Invoice> },
    onShowUploadInvoice: { type: Function, required: true },
    invoiceId: { type: String, required: true },
    paymentId: { type: String, required: true }
  },
  setup(props) {
    const { userId } = useUserSessionStore()
    const toast = useToast()

    const loadingSubmit = ref(false)

    const setParsedDate = (date: string) => {
      const dateMillis = DateTime.fromJSDate(new Date(date)).toMillis()
      return `${getMonth(dateMillis)}/${getYear(dateMillis)}`
    }

    const questions = [
      {
        name: 'createdAt',
        label: copies.uploadInvoiceForm.questions.createdAt.label,
        type: 'date'
      },
      {
        name: 'pointOfSale',
        label: copies.uploadInvoiceForm.questions.pointOfSale.label,
        type: 'text'
      },
      {
        name: 'id',
        label: copies.uploadInvoiceForm.questions.id.label,
        type: 'text'
      },
      {
        name: 'letter',
        label: copies.uploadInvoiceForm.questions.letter.label,
        type: 'select',
        options: [
          { label: 'A', value: 'A' },
          { label: 'B', value: 'B' },
          { label: 'C', value: 'C' }
        ]
      },
      {
        name: 'amount',
        label: copies.uploadInvoiceForm.questions.amount.label,
        type: 'text'
      },
      {
        name: 'file',
        label: copies.uploadInvoiceForm.questions.file.label,
        type: 'file',
        styles: { all: 'grid-column: 1 / 3; !important' }
      }
    ]

    const rules = {
      createdAt: { required: helpers.withMessage(copies.uploadInvoiceForm.genericRequiredError, required) },
      pointOfSale: { required: helpers.withMessage(copies.uploadInvoiceForm.genericRequiredError, required) },
      id: { required: helpers.withMessage(copies.uploadInvoiceForm.genericRequiredError, required) },
      letter: { required: helpers.withMessage(copies.uploadInvoiceForm.genericRequiredError, required) },
      amount: { required: helpers.withMessage(copies.uploadInvoiceForm.genericRequiredError, required) },
      file: { required: helpers.withMessage(copies.uploadInvoiceForm.genericRequiredError, required) }
    }

    const buttonLabels = {
      submit: copies.uploadInvoiceForm.buttonLabels.submit,
      cancel: copies.uploadInvoiceForm.buttonLabels.cancel
    }

    const handleCloseForm = () => props.onShowUploadInvoice()

    const handleSubmit = async (values: FormTypes) => {
      loadingSubmit.value = true

      try {
        await uploadInvoice(
          props.invoiceId,
          props.paymentId,
          userId,
          {
            ...props.invoiceData,
            details: {
              amount: values.amount,
              createdAt: new Date(values.createdAt).toISOString(),
              fileName: values.file.name,
              id: values.id,
              letter: values.letter,
              pointOfSale: values.pointOfSale
            }
          },
          values.file
        )

        toast.add({
          severity: 'success',
          detail: copies.uploadInvoiceForm.formSuccess,
          group: 'bc',
          life: 4000
        })

        handleCloseForm()
      } catch (error) {
        toast.add({
          severity: 'error',
          detail: copies.uploadInvoiceForm.formError,
          group: 'bc',
          life: 4000
        })
        // eslint-disable-next-line no-console
        console.error(error)
      } finally {
        loadingSubmit.value = false
      }
    }

    return { questions, rules, buttonLabels, loadingSubmit, handleSubmit, handleCloseForm, setParsedDate, copies }
  }
})
