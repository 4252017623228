
export class ThemesMagament {
  _mapper: Map<string, string>
  themeDefault: string
  constructor (def?: string, from?: Record<string, string>) {
    this._mapper = from ? new Map(Object.entries(from)) : new Map()
    this.themeDefault = def || ''
  }

  addTheme (key: string, value: string) : void {
    this._mapper.set(key, value)
  }

  changeDefaultTheme (theme: string): void {
    this.themeDefault = theme
  }

  getDefault (): string {
    if (!this.themeDefault) throw new Error('Please set default Theme')
    return this._mapper.get(this.themeDefault) as string
  }
}
