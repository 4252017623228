/* eslint-disable @typescript-eslint/no-explicit-any */
import { Api } from '../api'

// Models
import { IGetPostsResponse, INewComment, IPost, SavePostFromAPI } from '@/models/dashboard/community'
import { UserLeaderboard, UserRanking } from '@/models/dashboard/ranking'
import { PerkFromAPI, Perk } from '@/models/dashboard/perks'
import { RatingEP, Schedule } from '@/models/dashboard/rating'
import { GetProfileInfoArgs } from '@/models/profile/profile'

const { API_ENDPOINT } = process.env

// TODO: revisar completo
/**
 * API request to retrieve the posts from a certain user.
 * @param userId Logged user id
 * @param category  represents the category to be applied on the posts and pinned posts filter
 * @param isTeacher checks whether the logged in user role corresponds to a teacher or not
 * @param skip if passed the value 0, it will return the first 20 posts, if passed the value 20, returns the second 20 posts, and so on.
 * @param searchByTitle filter by this string
 * @returns community posts as well as the posts that were pinned out.
 */
export const getPosts = async (params: {
  userId: string
  isTeacher: boolean
  skip: number
  searchByTitle?: string
  category?: string
}): Promise<IGetPostsResponse> => {
  const { posts, pinnedPosts }: { hasNextPage: boolean; posts: IPost[]; pinnedPosts: IPost[] } = await Api.get(
    `${API_ENDPOINT}/platform/blogs`,
    {
      ignoreDrafts: 1,
      isFeed: 1,
      ts: '000',
      ...params,
      skip: `${params.skip}`
    }
  )

  const data = {
    posts,
    pinnedPosts
  }

  return data
}

/**
 * Adds a comment to a post
 * @param param API request params needed to add a new comment to a post
 */
export const commentPost: (params: INewComment) => Promise<void> = async ({ blogId, userId, comment }) => {
  const body = {
    platformUser: userId,
    commentContent: comment.commentContent
  }
  return await Api.post(`${API_ENDPOINT}/platform/blogs/${blogId}/comment`, body)
}

/** API call to like an already existing post
 * @param postId Post ID that is set to be liked
 * @param userId Logged-in user id
 * @returns API request status
 */
export const likePost: (postId: IPost['_id'], userId: string) => Promise<void> = async (postId, userId) => {
  const body = { _id: postId, userId }
  return await Api.put(`${API_ENDPOINT}/platform/blogs/${postId}/like`, body)
}

/**
 *
 * @param postId from the post that is set to be deleted
 * @returns API Request status
 */
export const deletePost: (postId: IPost['_id']) => Promise<void> = async (postId) => {
  return await Api.del(`${API_ENDPOINT}/platform/blogs/${postId}`)
}

/**
 * Get rating and next class for teacher and tutor
 * @param userId Logged user id
 * @param camada Logged camada id
 * @return course rating, next class and course end date
 */
export const getRatingAndNextClass = async (userId: string, camada: string) => {
  const ratingResponse: RatingEP = await Api.get(
    `${API_ENDPOINT}/platform/teachers/${userId}/courses/${camada}/rating?camadaId=${camada}&ts=000&userId=${userId}`
  )

  const {
    complementarySchedules,
    schedules,
    class: _class,
    course
  } = await Api.get(
    `${API_ENDPOINT}/platform/students/${userId}/courses/${camada}?ts=${Date.now()}&camadaId=${camada}&userId=${userId}`
  )

  const classesList: Schedule[] = []
  const afterClassList: Schedule[] = []

  for (const element of schedules) {
    const stage = _class.stages.find((stage: any) => stage._id === element.stage)

    const ordinaryClass = {
      _id: element._id,
      stage: {
        _id: stage._id,
        name: stage.name
      },
      day: typeof element.day === 'string' ? Date.parse(element.day) : element.day,
      from: element.from,
      to: element.to,
      presentationLink: element.presentationLink
    }

    classesList.push(ordinaryClass)
  }

  for (const element of complementarySchedules) {
    const afterClass = {
      _id: element._id,
      stage: {
        _id: element.stage._id,
        name: element.title
      },
      day: typeof element.day === 'string' ? Date.parse(element.day) : element.day,
      from: element.from,
      to: element.to,
      presentationLink: element.presentationLink
    }

    afterClassList.push(afterClass)
  }

  const classes: Schedule[] = [...classesList, ...afterClassList]

  if (classes.length > 0) {
    classes.filter((c: Schedule) => c.day > Date.now()).sort((_a, _b) => _a.day - _b.day)
  }

  const data = {
    rating: {
      teacherRating: ratingResponse.teacherRating,
      tutorRating: ratingResponse.tutorRating,
      positivesPerc: ratingResponse.positivesPerc,
      neutralsPerc: ratingResponse.neutralsPerc,
      negativesPerc: ratingResponse.negativesPerc
    },
    nextClass: classes[0] || {},
    endDate: course.endDate
  }

  return data
}

/**
 * @param userId Logged user id
 * @param camadaId Logged camada id
 * @return link to certificate
 * To try the endpoint /platform/users/${userId}/camadas/${camadaId}/certification pleese
 * @see [Postman]{@link https://coderplatformers.postman.co/workspace/Coder-House-Platform~9b3cd88b-81e2-4307-8432-95267b456aa7/request/17745020-865a7983-c50f-4379-a035-a193c5d09c7b}
 */
export const getCourseCertificate = async (userId: string, camadaId: string): Promise<string> => {
  const response: { url: string } = await Api.get(`${API_ENDPOINT}/platform/users/${userId}/camadas/${camadaId}/certification`)

  return response.url
}

/**
 * Get leaderboard and return the user's rank and progress
 * @param userId Logged user id
 * @param courseId Logged course id
 */
export const getLeaderboard = async (userId: string, courseId: string): Promise<UserRanking> => {
  const response: UserLeaderboard[] = await Api.get(
    `${API_ENDPOINT}/platform/courses/${courseId}/leaderboard?courseId=${courseId}&honorDeliver=&ts=000&userId=${userId}`
  )

  const user: UserLeaderboard = response.filter((u) => u._id === userId)[0]

  return { rank: user.rank, leaderboard: user.progress.totalLeaderboard }
}

/**
 * API Call to like/dislike a comment from an existing post
 * @param commentId set to be liked/disliked
 * @param blogId that contains the comment
 * @returns response status
 */
export const upsertCommentLike = async (commentId: string, blogId: string, userId: string) => {
  const params = {
    _id: blogId,
    _commentId: commentId,
    userId
  }
  return await Api.put(`${API_ENDPOINT}/platform/blogs/${blogId}/comment/${commentId}/like`, params)
}
/**
 *  Get all perks and return the best perk
 * @param userId Logged user id
 * @param courseId Logged course id
 * @returns the best perk
 * To try the endpoint `/platform/courses/${courseId}/users/${userId}/perks-by-course-progress` pleese
 * @see [Postman]{@link https://coderplatformers.postman.co/workspace/Coder-House-Platform~9b3cd88b-81e2-4307-8432-95267b456aa7/request/17745020-219418c2-068c-4c5a-9be1-aa656302ec21}
 */
export const getPerk = async (userId: string, courseId: string): Promise<Perk> => {
  const response: PerkFromAPI = await Api.get(
    `${API_ENDPOINT}/platform/courses/${courseId}/users/${userId}/perks-by-course-progress`
  )

  const unlockedPerks = response.perks.filter((p) => p.isUnlocked)
  const bestPerk = unlockedPerks[unlockedPerks.length - 1]
  const perk: Perk = {
    discount: '',
    link: '',
    text: '',
    courseTitle: '',
    related: []
  }

  if (bestPerk) {
    perk.discount = bestPerk.cupon
    perk.link = bestPerk.enrollmentLink
    perk.text = bestPerk.title.replace(/(<([^>]+)>)/gi, '')
    perk.courseTitle = (bestPerk.items && bestPerk.items[0].titleLongAlias) || ''
    perk.related = response.classmates
  }

  return { ...perk }
}

/**
 * API Call to save/unsave post
 * @param userId Logged user id
 * @param blogId to save/unsave
 * @returns response success boolean
 */
export const savePost = async (userId: string, blogId: string): Promise<boolean> => {
  const response: SavePostFromAPI = await Api.put(`${API_ENDPOINT}/platform/blogs/${blogId}/${userId}/save`, undefined)
  // don't sure if response.success could be false
  return response.success
}

/**
 * API request to check if the student has already done the onboarding.
 * @param userId Logged user id (student)
 * @returns Boolean
 * @see [Postman]{@link https://coderplatformers.postman.co/workspace/Coder-House-Platform~9b3cd88b-81e2-4307-8432-95267b456aa7/request/17745020-e05bce51-4e3c-4b0c-afec-7d019ed3f8aa}
 */

export const wasStudentOnboardingCompleted = async (userId: string): Promise<boolean> => {
  return await Api.get(`${API_ENDPOINT}/platform/users/${userId}/student-onboarding-slides?ts=${Date.now()}`)
}

/**
 * API request to check if the teacher has already done the onboarding.
 * @param userId Logged user id (teacher and tutors)
 * @returns Boolean
 * @see [Postman]{@link https://coderplatformers.postman.co/workspace/Coder-House-Platform~9b3cd88b-81e2-4307-8432-95267b456aa7/request/17745020-596de70d-64f3-4e5c-87be-2778ec0f6dda}
 */

export const wasTeacherOnboardingCompleted = async (userId: string): Promise<boolean> => {
  return await Api.get(`${API_ENDPOINT}/platform/users/${userId}/teacher-onboarding-slides?ts=${Date.now()}`)
}

/**
 * API request to update the student's onboarding as completed.
 * @param userId Logged user id (student)
 * @returns Boolean
 * @see [Postman]{@link https://coderplatformers.postman.co/workspace/Coder-House-Platform~9b3cd88b-81e2-4307-8432-95267b456aa7/request/17745020-45e60c7a-4944-4cf5-a392-2fbd585ea769}
 */

export const setStudentOnboardingCompleted = async (userId: string): Promise<boolean> => {
  return await Api.put(`${API_ENDPOINT}/platform/users/${userId}/student-onboarding-slides?ts=${Date.now()}`, { userId })
}

/**
 * API request to update the student's onboarding as completed.
 * @param userId Logged user id (student)
 * @returns Boolean
 * @see [Postman]{@link https://coderplatformers.postman.co/workspace/Coder-House-Platform~9b3cd88b-81e2-4307-8432-95267b456aa7/request/17745020-608876d1-5ffa-4b33-9a02-052a84175d5b}
 */

export const setTeacherOnboardingCompleted = async (userId: string): Promise<boolean> => {
  return await Api.put(`${API_ENDPOINT}/platform/users/${userId}/teacher-onboarding-slides?ts=${Date.now()}`, { userId })
}

/**
 * API Call to fetch saved posts from a specific user
 * @param param.userId `required` used to fetch currently logged user's saved posts
 * @returns currently logged in user saved posts
 */
export const getSavedPosts: (args: GetProfileInfoArgs) => Promise<IGetPostsResponse> = async ({ userId }) => {
  return await Api.get(
    `${API_ENDPOINT}/platform/blogs/saved/${userId}?savedBy=${userId}&isTeacher=false&ignoreDrafts=1&unpublished=1&ts=${Date.now()}`
  )
}

/**
 * API Call to fetch the user's created posts
 * @param param.userId `required` Currently logged in user id
 * @returns user's posts
 */
export const getMyPosts: (args: GetProfileInfoArgs) => Promise<IGetPostsResponse> = async ({ userId }) => {
  return await Api.get(`${API_ENDPOINT}/platform/blogs?byAuthor=${userId}&isTeacher=false&isFeed=1&unpublished=1&ts=${Date.now()}`)
}
