import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-d-flex p-flex-column p-ai-center" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_check_fill = _resolveComponent("check-fill")!
  const _component_icon = _resolveComponent("icon")!
  const _component_card = _resolveComponent("card")!

  return (_openBlock(), _createBlock(_component_card, {
    bgDark: "",
    border: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_icon, {
          withWrapper: true,
          fillColor: 'var(--white)',
          strokeColor: "transparent",
          size: 32
        }, {
          default: _withCtx(() => [
            _createVNode(_component_check_fill)
          ]),
          _: 1
        }, 8, ["fillColor"]),
        _createElementVNode("p", {
          class: "p-text-center p-mt-3 p2",
          innerHTML: $props.message
        }, null, 8, _hoisted_2)
      ])
    ]),
    _: 1
  }))
}