/* eslint-disable */
import { Api } from '@/api/api'
import { IWrangleJobInput, IWrangleJobOfferResponse, ICompanyDetailResponse, JobApplicationDto, JobApplicationsResponse, CreateJobApplicationResponse } from '@/models/jobs/jobs-offers'
import axios, { AxiosRequestConfig } from 'axios'

const { TALENT_API, ACADEMIC_API_ENDPOINT, JOBS_API } = process.env

const removeHeaders = (data: any, headers: any) => {
  delete headers.common.Authorization
  delete headers.common.Accept
  delete headers.common.accesstoken
  delete headers.common.idtoken
  return data
}
 
export async function findAll (page: any, limit: any, queryParams = ''): Promise<IWrangleJobOfferResponse> {
  const response = await axios.get(`${JOBS_API}/api/v1/jobs/get-all?page=${page}&limit=${limit}${queryParams}`, {transformRequest: removeHeaders})
  const data = response.data

  if (!data.data) {
    return {
      data: [],
      count: 0,
      totalPages: 0,
      skip: 0,
      limit: limit
    }
  }

  return {
    data: data.data,
    count: data.count,
    totalPages: data.totalPages,
    skip: 0,
    limit: limit
  }
}

export async function getWrangleJobForm (appLink : string): Promise<{finalUrl: string, formInfo: IWrangleJobInput[]}> {
  return await Api.post(`${JOBS_API}/api/v1/jobs/get-form`, {appLink})
}

export async function getWrangleFilters (): Promise<any[]> {
  const response = await axios.get(`${JOBS_API}/api/v1/jobs/filters`, {transformRequest: removeHeaders})
  return response.data
}

export async function getOptionsByType (type : string): Promise<{_id: string, value: string}[]> {
  const response = await axios.get(`${TALENT_API}/jobOffers/${type}`, {transformRequest: removeHeaders})
  return response.data
}

export async function findJobOffer (id: string): Promise<IWrangleJobOfferResponse> {
  const response = await axios.get(`${TALENT_API}/jobOffers/${id}}`, {transformRequest: removeHeaders})
  return response.data
}

export async function getJobOfferDetails (type : string, country = 'ar'): Promise<any[]> {
  const response = await axios.get(`${TALENT_API}/jobOfferDetails?type=${type}&country=${country}`, {transformRequest: removeHeaders})
  return response.data
}

export async function getCompanyDetail (detail = '', country = 'ar'): Promise<ICompanyDetailResponse[]> {
  const response = await axios.get(`${TALENT_API}/companyDetails?type=${detail}&country=${country}`, {transformRequest: removeHeaders})
  return response.data
}

export async function apply (application : JobApplicationDto): Promise<CreateJobApplicationResponse> {
  return Api.post(`${TALENT_API}/jobApplication`, application)
}

export async function getJobApplications (id: string): Promise<JobApplicationsResponse> {
  const response = await axios.get(`${TALENT_API}/jobApplications/${id}`, {transformRequest: removeHeaders})
  return response.data
}

export async function findBySector (sector: any): Promise<any> {
  const response = await axios.get(`${TALENT_API}/companies?sector=${sector}`, {transformRequest: removeHeaders})
  return response.data
}

export async function findByCompanyModalityArea (areas: any, modalities: any, companyId: any): Promise<IWrangleJobOfferResponse> {
  const response = await axios.get(`${TALENT_API}/jobOffers?areas=${areas}&modalities=${modalities}&companyId=${companyId}&status=published}`, {transformRequest: removeHeaders})
  return response.data
}
export async function findCustomJobOffers (page: any, limit: any, areas: string, modalities: string, companiesId: string): Promise<IWrangleJobOfferResponse> {
  const encodedCompanies = encodeURIComponent(companiesId)
  const encodedModalities = encodeURIComponent(modalities)
  const encodedAreas = encodeURIComponent(areas)

  const encodedURI = `${TALENT_API}/jobOffers/custom?page=${page}&limit=${limit}&areas=${encodedAreas}&modalities=${encodedModalities}&companiesId=${encodedCompanies}`
  const response = await axios.get(encodedURI, {transformRequest: removeHeaders})
  return response.data
}