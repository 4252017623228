import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-69e24f17"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ratingCard__score p-d-flex p-ai-center p-jc-center" }
const _hoisted_2 = { class: "rating__stars p-ml-4" }
const _hoisted_3 = { class: "p1 medium p-mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_rating_star = _resolveComponent("rating-star")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["ratingCard__container p-d-flex p-ai-center", _ctx.withBorder ? 'ratingCard-border' : ''])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", {
        class: _normalizeClass(["t1 medium", `text-${_ctx.color}`])
      }, _toDisplayString(_ctx.rating), 3)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.text), 1),
      _createVNode(_component_rating_star, {
        rating: _ctx.rating,
        color: _ctx.color
      }, null, 8, ["rating", "color"])
    ])
  ], 2))
}