import { Api } from '../api'
import { CourseResponse } from '@/models/classes/course'

import { Calendar } from '@/models/calendar/Calendar'
const { API_ENDPOINT } = process.env

/**
 *
 * @param userId current logged user id
 * @param camadaId camada number to retrieve data i.e 23300
 * @returns Information of the current course
 * @see [Postman]{@link https://coderplatformers.postman.co/workspace/Coder-House-Platform~9b3cd88b-81e2-4307-8432-95267b456aa7/request/17745020-43a24857-d0d7-43ce-bf91-37e869079803}
 */

export const getCourse = async (userId: string, camadaId: string): Promise<CourseResponse> => {
  return await Api.get(
    `${API_ENDPOINT}/platform/students/${userId}/courses/${camadaId}?ts=${Date.now()}&camadaId=${camadaId}&userId=${userId}`
  )
}

/**
 *
 * @param courseId
 * @returns calendar of classes and holidays for the current course requested
 */

export const getCalendar = async (courseId: string): Promise<Calendar> => {
  return await Api.get(`${API_ENDPOINT}/platform/courses/${courseId}/calendar?courseId=${courseId}`)
}
