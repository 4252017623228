import { defineStore } from 'pinia'

interface FiltersJobOfferState {
  search: string
  areas: string[]
  hierarchies: string[]
  modalities: string[]
  types: string[]
  countries: string[]
}

const parseArrayToQueryParam = (values: string[], name: string) => {
  const pairs = []
  for (const key in values) {
    // if (Object.prototype.hasOwnProperty.call(values, key)) pairs.push(encodeURIComponent(values[key]))
    if (Object.prototype.hasOwnProperty.call(values, key)) pairs.push(values[key])
  }
  if (pairs.length > 0) return name + encodeURIComponent(pairs.join('|')) + '&'
  else return ''
}

export const useFiltersJobOfferStore = defineStore({
  id: 'filters-talent-store',
  state: (): FiltersJobOfferState => ({
    search: '',
    areas: [],
    hierarchies: [],
    modalities: [],
    types: [],
    countries: []
  }),
  getters: {
    getQueryParam (): string {
      let query = ''
      // query += parseArrayToQueryParam(this.areas, 'areas=')
      // query += parseArrayToQueryParam(this.hierarchies, 'hierarchies=')
      query += parseArrayToQueryParam(this.areas, 'categories=')
      query += parseArrayToQueryParam(this.hierarchies, 'seniorities=')
      query += parseArrayToQueryParam(this.modalities, 'modalities=')
      query += parseArrayToQueryParam(this.types, 'types=')
      query += parseArrayToQueryParam(this.countries, 'countries=')

      if(this.search) query += `&search=${this.search}`

      return `&${query}&status=published`
    }
  },
  actions: {
    updateSearch (value: string) {
      this.search = value
    },
    updateAreas (value: string[]) {
      this.areas = value
    },
    updateHierarchies (value: string[]) {
      this.hierarchies = value
    },
    updateModalities (value: string[]) {
      this.modalities = value
    },
    updateTypes (value: string[]) {
      this.types = value
    },
    updateCountries (value: string[]) {
      this.countries = value
    }
  }
})
