import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, TransitionGroup as _TransitionGroup, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "post-wrapper p-p-4 p-d-flex p-flex-column" }
const _hoisted_2 = { class: "p-d-flex p-flex-row p-jc-between" }
const _hoisted_3 = { class: "p-flex-row p-d-flex" }
const _hoisted_4 = { class: "p1" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "bold p1" }
const _hoisted_7 = { class: "p3 text-light-gray" }
const _hoisted_8 = { class: "p-mt-3" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "p-d-flex p-flex-row p-mt-3 p-jc-between" }
const _hoisted_11 = { class: "p-d-flex p-flex-row p-ai-center" }
const _hoisted_12 = {
  key: 0,
  class: "p3 regular p-ml-1 text-light-gray",
  style: {"min-width":"65px"}
}
const _hoisted_13 = { class: "interaction-box" }
const _hoisted_14 = { class: "p-mt-3" }
const _hoisted_15 = { class: "p-d-flex p-flex-row p-ai-center p-jc-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_toast = _resolveComponent("toast")!
  const _component_avatar = _resolveComponent("avatar")!
  const _component_dots = _resolveComponent("dots")!
  const _component_button_icon = _resolveComponent("button-icon")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_thumb_up = _resolveComponent("thumb-up")!
  const _component_icon = _resolveComponent("icon")!
  const _component_chat_single_circle = _resolveComponent("chat-single-circle")!
  const _component_share = _resolveComponent("share")!
  const _component_file_text = _resolveComponent("file-text")!
  const _component_bookmark = _resolveComponent("bookmark")!
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_paper_plane_tilt = _resolveComponent("paper-plane-tilt")!
  const _component_comment = _resolveComponent("comment")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_toast),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.showAvatar)
            ? (_openBlock(), _createBlock(_component_avatar, {
                key: 0,
                name: _ctx.post.platformUser?.fullName,
                imageLink: _ctx.post.platformUser?.avatar
              }, null, 8, ["name", "imageLink"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass(`p-jc-between ${_ctx.showAvatar ? 'p-mx-3' : ''} text-white`)
          }, [
            _createElementVNode("p", _hoisted_4, [
              _createTextVNode(" Mi camada 1221 "),
              (_ctx.post.platformUser?.fullName)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                    _createTextVNode("| "),
                    _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.post.platformUser?.fullName), 1)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.parseCommentDate(_ctx.post.date)), 1)
          ], 2)
        ]),
        (_ctx.role !== 2)
          ? (_openBlock(), _createBlock(_component_button_icon, {
              key: 0,
              href: "#options",
              onClick: _ctx.toggle,
              "aria-haspopup": "true",
              "aria-controls": "overlay_menu",
              color: _ctx.menuItems.length ? 'var(--light-gray)' : 'var(--gray-0)',
              class: _normalizeClass(!_ctx.menuItems.length ? 'menu__notAllowed' : '')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_dots)
              ]),
              _: 1
            }, 8, ["onClick", "color", "class"]))
          : _createCommentVNode("", true),
        _createVNode(_component_Menu, {
          id: "overlay_menu",
          ref: "menu",
          model: _ctx.menuItems,
          popup: true
        }, null, 8, ["model"])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("p", { innerHTML: _ctx.title }, null, 8, _hoisted_9)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", {
            class: "p-d-flex p-flex-row p-ai-center p-jc-center interaction-box",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.post.flags.wasLiked ? _ctx.dislikePost() : _ctx.likePost()))
          }, [
            _createVNode(_component_icon, {
              size: 18,
              strokeColor: 'var(--light-gray)'
            }, {
              default: _withCtx(() => [
                _createVNode(_component_thumb_up)
              ]),
              _: 1
            }, 8, ["strokeColor"]),
            (_ctx.post.likeCount > 0)
              ? (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString(_ctx.post.likeCount) + " " + _toDisplayString(_ctx.copies.community.likes), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", {
            class: "p-flex-row interactions interaction-box p-d-flex p-ai-center p-jc-center",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleComments && _ctx.toggleComments(...args)))
          }, [
            _createVNode(_component_icon, {
              size: 18,
              strokeColor: _ctx.showComments ? 'var(--white)' : 'var(--light-gray)'
            }, {
              default: _withCtx(() => [
                _createVNode(_component_chat_single_circle)
              ]),
              _: 1
            }, 8, ["strokeColor"]),
            (_ctx.post.comments.length > 0)
              ? (_openBlock(), _createElementBlock("p", {
                  key: 0,
                  class: _normalizeClass(`p3 p-mr-1 p-ml-1 regular text-${_ctx.showComments ? 'white' : 'light-gray'}`),
                  style: {"min-width":"70px","white-space":"nowrap"}
                }, _toDisplayString(_ctx.post.comments.length) + " " + _toDisplayString(_ctx.post.comments.length > 1 ? _ctx.copies.community.comments : _ctx.copies.community.comments.slice(0, -1)), 3))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_button_icon, {
              size: 18,
              color: 'var(--light-gray)',
              onClick: _ctx.handleShare
            }, {
              default: _withCtx(() => [
                (_ctx.isMobileDevice)
                  ? (_openBlock(), _createBlock(_component_share, { key: 0 }))
                  : (_openBlock(), _createBlock(_component_file_text, { key: 1 }))
              ]),
              _: 1
            }, 8, ["color", "onClick"])
          ])
        ]),
        _createVNode(_component_button_icon, {
          onClick: _ctx.savePost,
          size: 17,
          class: "p-mr-1",
          color: 'var(--light-gray)'
        }, {
          default: _withCtx(() => [
            _createVNode(_component_icon, {
              fillColor: _ctx.isSaved ? 'var(--light-gray)' : 'transparent'
            }, {
              default: _withCtx(() => [
                _createVNode(_component_bookmark)
              ]),
              _: 1
            }, 8, ["fillColor"])
          ]),
          _: 1
        }, 8, ["onClick", "color"])
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, [
          _createVNode(_component_TextInput, {
            modelValue: _ctx.commentContent,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.commentContent) = $event)),
            placeholder: _ctx.copies.community.textInputPlaceholder,
            onKeyup: _withKeys(_ctx.commentPost, ["enter"])
          }, null, 8, ["modelValue", "placeholder", "onKeyup"]),
          _createVNode(_component_button_icon, {
            class: "p-ml-3",
            color: 'var(--secondary)',
            onClick: _ctx.commentPost
          }, {
            default: _withCtx(() => [
              _createVNode(_component_paper_plane_tilt)
            ]),
            _: 1
          }, 8, ["color", "onClick"])
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.commentsLoaded, (comment) => {
          return (_openBlock(), _createBlock(_TransitionGroup, {
            name: "comment-transition",
            key: comment._id
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_component_comment, {
                class: "transition-item",
                comment: comment,
                commentDate: _ctx.parseCommentDate(comment.createdAt),
                key: comment._id,
                onLikeDislikeComment: _ctx.likeDislikeComment
              }, null, 8, ["comment", "commentDate", "onLikeDislikeComment"]))
            ]),
            _: 2
          }, 1024))
        }), 128)),
        (_ctx.postRef.comments.length > 2)
          ? (_openBlock(), _createElementBlock("p", {
              key: 0,
              class: "showMore p-mt-2 p3",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.allCommentsLoaded ? (_ctx.commentsToShow = 2) : (_ctx.commentsToShow += 2)))
            }, _toDisplayString(_ctx.allCommentsLoaded ? _ctx.copies.community.showLess : _ctx.copies.community.showMore), 1))
          : _createCommentVNode("", true)
      ], 512), [
        [_vShow, _ctx.showComments]
      ])
    ])
  ], 64))
}