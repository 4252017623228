
// Vendor
import { defineComponent, onMounted } from 'vue'
import amplitude from 'amplitude-js'
import { useRoute } from 'vue-router'

// Components
import ConfirmDialog from 'primevue/confirmdialog'
import CoderToast from '@/components/commons/CoderToast.vue'
import Navigation from '@/components/aero/navigation/Navigation.vue'
import { usePrimeVue } from 'primevue/config'
import { dayNames, dayNamesShort, dayNamesMin, monthNames, monthNamesShort } from '@/utils/locale'

// Stores
import { useUserSessionStore } from '@/store/modules/user-session.store'
import { useUserStore } from '@/store/modules/user.store'
import { themeStore } from '@/store/modules/themes.store'
import { useFirebaseStore } from '@/store/modules/firebase.store'

// Themes
import { THEMES, THEME_DEFAULT } from '@/assets/styles/themes'

// Moment
import moment from 'moment'

export default defineComponent({
  components: {
    ConfirmDialog,
    Navigation,
    CoderToast
  },
  setup() {
    const route = useRoute()
    const session = useUserSessionStore()
    const user = useUserStore()
    window.parent.postMessage(JSON.stringify({ type: 'get-user' }), '*') // TODO Change * with correct domain for Cors

    // Configure Aerolab and Coderhouse themes to allow new and old platform L&F run togheter in same code base
    const theme = themeStore()
    Object.entries(THEMES).map((t) => theme.add(t[0], t[1]))
    theme.changeDefault(THEME_DEFAULT)

    // Get firebase remote config
    const firebase = useFirebaseStore()
    firebase.fetchRemoteConfig()
    firebase.getFetchAndActivate()

    const setLocaleCalendar = (locale: string) => {
      const primevue = usePrimeVue()
      if(primevue.config.locale) {
        primevue.config.locale.dayNames = dayNames.get(locale) || []
        primevue.config.locale.dayNamesShort = dayNamesShort.get(locale) || []
        primevue.config.locale.dayNamesMin = dayNamesMin.get(locale) || []
        primevue.config.locale.monthNames = monthNames.get(locale) || []
        primevue.config.locale.monthNamesShort = monthNamesShort.get(locale) || []
      }
    }

    amplitude.getInstance().setUserId(session.userId)

    onMounted(() => {
      const locale = user.country === 'br' ? 'pt' : 'es'
      setLocaleCalendar(locale)
      moment.locale(locale)
    })

    return { session, route }
  }
})
