
import { defineComponent, ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  setup () {
    const { t } = useI18n()
    const isIframe = ref()

    onMounted(() => {
      isIframe.value = localStorage.getItem('isNewPlatform') === 'true'
    }) 
    return { t, isIframe }
  }
})
