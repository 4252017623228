import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-10c6d4f8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-d-flex p-ai-center" }
const _hoisted_2 = { class: "skeleton__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_skeleton_box = _resolveComponent("skeleton-box")!
  const _component_skeleton_text = _resolveComponent("skeleton-text")!
  const _component_card = _resolveComponent("card")!

  return (_openBlock(), _createBlock(_component_card, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_skeleton_box, {
          height: "80px",
          width: "80px",
          class: "p-mr-4 skeleton__avatar"
        }),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_skeleton_text, {
            length: 15,
            class: "p-mb-3"
          }),
          _createVNode(_component_skeleton_text, { length: 10 })
        ])
      ])
    ]),
    _: 1
  }))
}