import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-toast-content" }
const _hoisted_2 = {
  key: 0,
  class: "p-toast-icon-container"
}
const _hoisted_3 = {
  key: 1,
  class: "p-toast-icon-container"
}
const _hoisted_4 = {
  key: 2,
  class: "p-toast-icon-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CircleCheck = _resolveComponent("CircleCheck")!
  const _component_icon_base = _resolveComponent("icon-base")!
  const _component_WarningCircle = _resolveComponent("WarningCircle")!
  const _component_InfoCircle = _resolveComponent("InfoCircle")!
  const _component_Toast = _resolveComponent("Toast")!

  return (_openBlock(), _createBlock(_component_Toast, { position: "top-right" }, {
    message: _withCtx((slotProps) => [
      _createElementVNode("div", _hoisted_1, [
        (slotProps.message.severity === 'success')
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
              _createVNode(_component_icon_base, {
                width: "22",
                height: "20",
                viewBox: '0 0 24 24',
                strokeColor: "#9DF4E2"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_CircleCheck)
                ]),
                _: 1
              })
            ]))
          : (slotProps.message.severity === 'error')
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                _createVNode(_component_icon_base, {
                  width: "20",
                  height: "24",
                  viewBox: '0 0 15 15',
                  strokeColor: "#ff896f"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_WarningCircle)
                  ]),
                  _: 1
                })
              ]))
            : (slotProps.message.severity === 'info')
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                  _createVNode(_component_icon_base, {
                    width: "20",
                    height: "24",
                    viewBox: '0 0 15 15',
                    strokeColor: "#83aefb"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_InfoCircle)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
        _createElementVNode("p", null, _toDisplayString(slotProps.message.detail), 1)
      ])
    ]),
    _: 1
  }))
}