import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "p-d-flex p-mb-4 p-mt-3" }
const _hoisted_2 = { class: "p-d-flex gap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_skeleton_text = _resolveComponent("skeleton-text")!
  const _component_SkeletonPostConversation = _resolveComponent("SkeletonPostConversation")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (i) => {
          return _createVNode(_component_skeleton_text, {
            class: "comment",
            length: 7,
            key: i
          })
        }), 64))
      ])
    ]),
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(4, (i) => {
      return _createVNode(_component_SkeletonPostConversation, {
        class: "p-mt-3",
        key: i
      })
    }), 64))
  ], 64))
}