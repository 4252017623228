import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bc68acd0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-d-flex p-jc-between p-mb-5 filters" }
const _hoisted_2 = { class: "p-d-flex gap" }
const _hoisted_3 = { class: "p-d-flex p-jc-evenly p-pl-6 p-pr-4 p-mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_skeleton_text = _resolveComponent("skeleton-text")!
  const _component_skeleton_icon = _resolveComponent("skeleton-icon")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_skeleton_text, { length: 8 }),
        _createVNode(_component_skeleton_text, { length: 10 }),
        _createVNode(_component_skeleton_text, { length: 8 })
      ]),
      _createVNode(_component_skeleton_text, { length: 8 })
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (i) => {
        return _createVNode(_component_skeleton_text, {
          length: 7,
          key: i
        })
      }), 64))
    ]),
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(4, (i) => {
      return _createVNode(_component_Card, {
        class: "p-d-flex p-jc-evenly p-ai-center p-mt-3 p-pl-0 p-py-5",
        key: i
      }, {
        default: _withCtx(() => [
          _createVNode(_component_skeleton_icon),
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (i) => {
            return _createVNode(_component_skeleton_text, {
              length: 8,
              sm: "",
              key: i
            })
          }), 64))
        ]),
        _: 2
      }, 1024)
    }), 64))
  ]))
}