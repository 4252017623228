import { RouteRecordRaw } from 'vue-router'
import Challenges from '@/views/challenges/Challenges.vue'
import Challenge from '@/views/challenges/Challenge.vue'
import challengesByStudent from '@/views/challenges/ChallengesByStudent.vue'
import { NewPlatform as Names } from '@/router/route-names'

const ChallengesRoutes: Array<RouteRecordRaw> = [
  {
    path: '',
    component: Challenges,
    name: Names.CHALLENGES
  },
  {
    path: 'challenge/:challengeId',
    component: Challenge,
    name: Names.CHALLENGE
  },
  {
    path: 'students/:studentId',
    component: challengesByStudent,
    name: Names.CHALLENGES_BY_STUDENT
  }
]

export default ChallengesRoutes
