import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-d-flex p-jc-end p-mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableFilters = _resolveComponent("TableFilters")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Toast = _resolveComponent("Toast")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TableFilters, {
      leadTeacherId: _ctx.leadTeacherId,
      incomingClasses: _ctx.incomingClasses,
      modelValue: _ctx.reenrollToCourseId,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.reenrollToCourseId) = $event)),
      onOnSelectComission: _ctx.handleChooseComission
    }, null, 8, ["leadTeacherId", "incomingClasses", "modelValue", "onOnSelectComission"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Button, {
        class: "secondary p-mr-2",
        onClick: _ctx.handleCancelButton,
        label: _ctx.studentChangeCopies.buttons.cancel
      }, null, 8, ["onClick", "label"]),
      _createVNode(_component_Button, {
        class: "primary",
        onClick: _ctx.handleSendRequest,
        label: _ctx.submitButtonLabel || _ctx.studentChangeCopies.buttons.submit,
        disabled: _ctx.disableSubmit
      }, null, 8, ["onClick", "label", "disabled"])
    ]),
    _createVNode(_component_Toast)
  ], 64))
}