import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import { useNotificationStore } from '@/store/modules/notifications.store'

import { updateToken } from '@/api/newPlatform/navigation.api'

/**
 * Services to get firebase token and register on message listener.
 * @param userId logged user id
 */
export const getTokenFCM = async (userId: string) => {
  const { newNotification } = useNotificationStore()

  const messaging = getMessaging()

  getToken(messaging, { vapidKey: process.env.VAPID_KEY })
    .then(async (currentToken) => {
      if (currentToken) {
        const response = await updateToken(userId, currentToken)
        return response
      } else {
        // eslint-disable-next-line no-console
        console.log('FCM: No registration token available. Request permission to generate one.')
      }
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.log('FCM: An error occurred while retrieving token. ', err)
    })

  onMessage(messaging, (payload) => {
    // eslint-disable-next-line no-console
    console.log('FCM: Message received. ', payload)
    newNotification()
  })
}
