
import { defineComponent, PropType, Ref, ref, watch } from 'vue'
import { IUser as UserInfo } from '@/models/user'
import Calendar from 'primevue/calendar'
import Checkbox from 'primevue/checkbox'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    Calendar,
    Checkbox
  },
  props: {
    user: { type: Object as PropType<UserInfo>, required: true },
    education: { type: Object, required: true }
  },
  emits: ['changeWorkTitle',
          'changeWorkCompany',
          'changeWorkFrom',
          'changeWorkTo',
          'changeCurrentlyWorking'
         ],
  setup (props, { emit }) {
    const { t } = useI18n()
    const currentlyWorking: Ref<boolean> = ref(false)
    const from = ref<Date>(new Date())
    const to = ref<Date>()

    const changeCurrentlyWorking = () => {
      emit('changeCurrentlyWorking', currentlyWorking.value)
      if (currentlyWorking.value) {
        to.value = undefined
      }
    }

    const changeWorkTitle = (event: Event) => {
      const { target } = event
      if (target) {
        emit('changeWorkTitle', (target as HTMLInputElement).value)
      }
    }

    const changeWorkCompany = (event: Event) => {
      const { target } = event
      if (target) {
        emit('changeWorkCompany', (target as HTMLInputElement).value)
      }
    }

    const validCalendarDate: (date: Date) => boolean = (date) => {
      try {
        return typeof date.getTime() === 'number'
      } catch (error) {
        return false
      }
    }

    watch([from, to], () => {
      if (validCalendarDate(from.value)) {
        emit('changeWorkFrom', (from.value).toISOString())
      }
      if (!to.value || validCalendarDate(to.value)) {
        emit('changeWorkTo', (to.value)?.toISOString())
      }
    })

    return {
      t,
      from,
      to,
      currentlyWorking,
      changeWorkTitle,
      changeWorkCompany,
      changeCurrentlyWorking
    }
  }
})
