const getFilePreview = (link: string, size?: string | 'small' | 'large'): string => {
  if (link.includes('docs.') || link.includes('drive.')) {
    const id = link.split('/d/')[1].split('/edit')[0]
    const previewURL = `https://lh3.googleusercontent.com/d/${id}=s440?authuser=0`
    return previewURL
  }
  if (link && (link.includes('youtube') || link.includes('youtu.be')) && !link.includes('embed')) {
    if (link === null) return ''

    const results = link.match('[\\?&]v=([^&#]*)')
    const video = results === null ? link : results[1]

    if (size === 'large') return 'http://img.youtube.com/vi/' + video + '/hqdefault.jpg'

    return 'http://img.youtube.com/vi/' + video + '/0.jpg'
  }
  const imageReg = /[/.](gif|jpg|jpeg|tiff|png)$/i

  if (imageReg.test(link)) return link

  return '/assets/image-card-placeholder.png'
}
export default getFilePreview
