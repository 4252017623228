
// Vendor
import { defineComponent } from 'vue'

// Store
import { themeStore } from '@/store/modules/themes.store'
import { useUserStore } from '@/store/modules/user.store'

// Components
import ChallengesByModule from '@/views/challenges/components/ChallengesByModule.vue'
import ChallengeStudent from '@/views/challenges/components/ChallengeStudent.vue'

// Challenge copies
import copies from '@/locales/challenges/es.json'

export default defineComponent({
  components: {
    ChallengeStudent,
    ChallengesByModule
  },
  setup() {
    themeStore().changeDefault('aero')
    const { role } = useUserStore()

    return { role, copies }
  }
})
