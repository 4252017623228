
import { defineComponent, PropType, ref, onMounted } from 'vue'

// PrimeVue components
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'

// Models
import { IUser as UserInfo } from '@/models/user'

// Components
import Card from '@/components/aero/surfaces/Card.vue'

// Store
import { useUserSessionStore } from '@/store/modules/user-session.store'

// translations
import { useI18n } from 'vue-i18n'

// Services
import { geUserSocialAccounts, removeUserSocialAccount } from '@/services/newPlatform/profile.services'

export default defineComponent({
  components: {
    Button,
    Card,
    Dialog
  },
  props: {
    user: { type: Object as PropType<UserInfo>, required: true }
  },
  setup() {
    const { t } = useI18n()
    const session = useUserSessionStore()
    const displayModal = ref(false)
    const hasConnection = ref(false)
    const socialAccounts = ref()

    const openModal = () => {
      displayModal.value = true
    }

    const closeModal = async () => {  
      displayModal.value = false
    }

    const addConnection = async () => {
      const loginUrl = process.env.VUE_APP_LOGIN_URL as string
      const idToken = session.token
      const accessToken = session.auth
      const url = `${loginUrl}/social-login?from_profile=true&id_token=${idToken}&access_token=${accessToken}`
      window.parent.location.href = url
      displayModal.value = false
    }

    const removeConnection = async (email: string, provider: string) => {
      try {
        await removeUserSocialAccount(email, provider)
        hasConnection.value = false
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
      }
      displayModal.value = false
    }

    onMounted(async() => {
      socialAccounts.value = await geUserSocialAccounts()
      if (socialAccounts.value.length) {
        hasConnection.value = true
      }
    })

    return {
      t,
      addConnection, 
      removeConnection,
      hasConnection,
      socialAccounts,
      displayModal,
      openModal, 
      closeModal
    }
  }
})
