import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-416a9f96"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-d-flex p-jc-between header-container" }
const _hoisted_2 = { class: "p-d-flex p-ai-center" }
const _hoisted_3 = { class: "" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "subtitle" }
const _hoisted_6 = { class: "idioma__container" }
const _hoisted_7 = { class: "title__container" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "level__container" }
const _hoisted_10 = { class: "title__container" }
const _hoisted_11 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Listbox = _resolveComponent("Listbox")!
  const _component_AccordionTab = _resolveComponent("AccordionTab")!
  const _component_Accordion = _resolveComponent("Accordion")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.modal,
    "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modal) = $event)),
    style: {height: '498px'},
    class: "modal-talent-language",
    modal: true,
    closable: false
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('editProfile.professionalInfo.language.modal.selectLanguage')), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("img", {
            class: "close-modal",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal())),
            src: require('@/assets/icons/Light_X.svg'),
            alt: "Close modal"
          }, null, 8, _hoisted_4)
        ])
      ])
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: _ctx.$t('editProfile.professionalInfo.language.modal.cancelButton'),
        onClick: _ctx.closeModal,
        class: "close-button"
      }, null, 8, ["label", "onClick"]),
      _createVNode(_component_Button, {
        label: _ctx.$t('editProfile.professionalInfo.language.modal.addButton'),
        onClick: _ctx.saveLanguages,
        class: "submit-button",
        disabled: !_ctx.selectedLanguages.filter(selected => { return selected.value.value!=='' && selected.value.level!==''})?.length
      }, null, 8, ["label", "onClick", "disabled"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('editProfile.professionalInfo.language.modal.incomplete')), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedLanguages, (language, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "accordion__container p-my-4",
          key: language.value
        }, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("p", _hoisted_7, [
              _createTextVNode(_toDisplayString(_ctx.$t('editProfile.professionalInfo.language.modal.language')) + " ", 1),
              (index === 0)
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, "*"))
                : _createCommentVNode("", true)
            ]),
            _createVNode(_component_Accordion, {
              class: "accordion-header",
              onTabOpen: ($event: any) => (_ctx.calculateScrollLanguage(index)),
              id: `accordionLanguage_${index}`,
              ref_for: true,
              ref: el => { _ctx.languageAccordion[index] = el }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AccordionTab, {
                  header: language.value.value? language.value.value : _ctx.$t('editProfile.professionalInfo.language.modal.select')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Listbox, {
                      modelValue: language.value.value,
                      "onUpdate:modelValue": ($event: any) => ((language.value.value) = $event),
                      options: _ctx.languagesFiltered,
                      optionValue: "value",
                      onChange: _cache[1] || (_cache[1] = ($event: any) => {_ctx.closeAllLanguage(); _ctx.closeAllLevel(); _ctx.languagesFilter()})
                    }, {
                      option: _withCtx((slotProps) => [
                        _createElementVNode("div", null, [
                          _createElementVNode("span", null, _toDisplayString(slotProps.option.value), 1)
                        ])
                      ]),
                      _: 2
                    }, 1032, ["modelValue", "onUpdate:modelValue", "options"])
                  ]),
                  _: 2
                }, 1032, ["header"])
              ]),
              _: 2
            }, 1032, ["onTabOpen", "id"])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("p", _hoisted_10, [
              _createTextVNode(_toDisplayString(_ctx.$t('editProfile.professionalInfo.language.modal.level')), 1),
              (index === 0)
                ? (_openBlock(), _createElementBlock("span", _hoisted_11, "*"))
                : _createCommentVNode("", true)
            ]),
            _createVNode(_component_Accordion, {
              class: "accordion-header",
              onTabOpen: ($event: any) => (_ctx.calculateScrollLevel(index)),
              id: `accordionLevel_${index}`,
              ref_for: true,
              ref: el => { _ctx.levelAccordion[index] = el }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AccordionTab, {
                  header: language.value.level? language.value.level : _ctx.$t('editProfile.professionalInfo.language.modal.select')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Listbox, {
                      class: "list_box_level",
                      modelValue: language.value.level,
                      "onUpdate:modelValue": ($event: any) => ((language.value.level) = $event),
                      options: _ctx.levels,
                      optionValue: "value",
                      onChange: _cache[2] || (_cache[2] = ($event: any) => {_ctx.closeAllLanguage(); _ctx.closeAllLevel()})
                    }, {
                      option: _withCtx((slotProps) => [
                        _createElementVNode("div", null, [
                          _createElementVNode("span", null, _toDisplayString(slotProps.option.value), 1)
                        ])
                      ]),
                      _: 2
                    }, 1032, ["modelValue", "onUpdate:modelValue", "options"])
                  ]),
                  _: 2
                }, 1032, ["header"])
              ]),
              _: 2
            }, 1032, ["onTabOpen", "id"])
          ])
        ]))
      }), 128)),
      (_ctx.languages.length)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 0,
            label: _ctx.$t('editProfile.professionalInfo.language.modal.addLanguage'),
            class: "add-button",
            onClick: _ctx.addLanguage
          }, null, 8, ["label", "onClick"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["visible"]))
}