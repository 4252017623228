import { defineStore } from 'pinia'
import { initializeApp } from 'firebase/app'
import { Value, RemoteConfig, getRemoteConfig, fetchAndActivate, getBoolean, getNumber, getString, getValue } from 'firebase/remote-config'

const getArrayString = async (remoteConfig: RemoteConfig, key: string): Promise<string[]> =>{
	const someString:string = await getString(remoteConfig, key)
	return someString?JSON.parse(someString):[]
}

export const useFirebaseStore = defineStore({
	id: 'firebase',
	state: () => ({
		remoteConfig: null as RemoteConfig | null,
		isFetchedAndActivated: false as boolean
	}),
	getters: {
		getRemoteConfig(): RemoteConfig | null {
			return this.remoteConfig
		}
	},
	actions: {
		initApp() {
			const firebaseConfig = JSON.parse(`${process.env.FIREBASE_CONFIG_OBJECT}`)
			initializeApp(firebaseConfig)
		},
		fetchRemoteConfig() {
			this.remoteConfig = getRemoteConfig()
			this.remoteConfig.settings.minimumFetchIntervalMillis = parseInt(`${process.env.FIREBASE_FETCH_INTERVAL_MILLIS}`)
		},
		async getFetchAndActivate() {
			this.isFetchedAndActivated = await fetchAndActivate(this.remoteConfig as RemoteConfig)
		},
		async fetchValue(key: string, type: string | null = null): Promise<string | number | boolean | string[] | Value> {
			switch (type) {
				case 'boolean':
					return await getBoolean(this.remoteConfig as RemoteConfig, key)
				case 'number':
					return await getNumber(this.remoteConfig as RemoteConfig, key)
				case 'string':
					return await getString(this.remoteConfig as RemoteConfig, key)
				case 'array':
					return await getArrayString(this.remoteConfig as RemoteConfig, key)
				case null:
					return await getValue(this.remoteConfig as RemoteConfig, key)
				default:
					return await getValue(this.remoteConfig as RemoteConfig, key)
			}
		}
	}

})