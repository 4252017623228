import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-58558d78"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-d-flex p-ai-center" }
const _hoisted_2 = { class: "p2 medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_avatar = _resolveComponent("avatar")!

  return (_openBlock(), _createElementBlock("li", {
    class: _normalizeClass(_ctx.featured && _ctx.shouldBeSticky ? 'user__row-sticky is-pinned' : '')
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", {
        class: _normalizeClass(['p3 medium p-mr-3 rank', _ctx.featured && 'featured'])
      }, "#" + _toDisplayString(_ctx.rank), 3),
      _createVNode(_component_avatar, {
        class: "p-mr-3",
        name: _ctx.name,
        imageLink: _ctx.imageLink
      }, null, 8, ["name", "imageLink"]),
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.name), 1)
    ]),
    _createElementVNode("span", {
      class: _normalizeClass(['p1 medium', _ctx.featured && 'text-secondary'])
    }, _toDisplayString(_ctx.points) + "pts", 3)
  ], 2))
}