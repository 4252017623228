
// Vendor
import { defineComponent } from 'vue'

// copies
import copies from '@/locales/dashboard/es.json'

export default defineComponent({
  setup() {
    return { copies }
  }
})
