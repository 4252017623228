
import LoginForm from '@/components/login/LoginForm.vue'
import { useUserSessionStore } from '@/store/modules/user-session.store'
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    LoginForm
  },

  setup () {
    const session = useUserSessionStore()
    const router = useRouter()

    const onSubmit = async ({ userName, password }: { userName: string; password: string }) => {
      await session.developerLogin({ username: userName, password })
      router.push('/')
    }

    return {
      session,
      onSubmit
    }
  }
})
