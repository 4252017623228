
import { defineComponent, computed, PropType } from 'vue'
import Avatar from 'primevue/avatar'
import BaseIcon from '@/components/aero/icon/Icon.vue'
import { Lock } from '@/components/aero/icon/templates'

export default defineComponent({
  components: { Avatar, BaseIcon, Lock },
  props: {
    name: { type: String, required: true },
    imageLink: { type: String, required: false },
    isBlocked: { type: Boolean, default: false },
    level: { type: String, default: '' },
    role: { type: Number, default: 0 },
    size: { type: String as PropType<'large' | 'xlarge'>, required: false }
  },
  setup(props) {
    const setInitalLetter = computed(() => {
      return props.name.substring(0, 1).toUpperCase()
    })

    return { setInitalLetter }
  }
})
