import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-d-flex p-jc-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_profile_card = _resolveComponent("profile-card")!
  const _component_message = _resolveComponent("message")!
  const _component_button_icon = _resolveComponent("button-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_profile_card, {
      category: _ctx.category,
      name: _ctx.name,
      imageLink: _ctx.imageLink,
      size: "large",
      link: _ctx.profileLink
    }, null, 8, ["category", "name", "imageLink", "link"]),
    (_ctx.chatLink)
      ? (_openBlock(), _createBlock(_component_button_icon, {
          key: 0,
          size: 18,
          color: 'var(--secondary)',
          href: _ctx.chatLink,
          asLinkType: "internal"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_message)
          ]),
          _: 1
        }, 8, ["color", "href"]))
      : _createCommentVNode("", true)
  ]))
}