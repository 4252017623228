
// Vendor
import { defineComponent } from 'vue'

// Components
import SkeletonColumnOpinon from './SkeletonColumnOpinion.vue'

export default defineComponent({
  components: {
    SkeletonColumnOpinon
  }
})
