import { h, resolveComponent } from 'vue'
import { NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from 'vue-router'
import StudentView from '@/views/peerReview/StudentView.vue'
import TutorView from '@/views/peerReview/TutorView.vue'
import { getRole } from '@/utils/roles'
import { PersonRoles } from '@/models/enums'
import { PeerReview as Names } from '@/router/route-names'
import '@/assets/styles/old_theme/styles.scss' // Todo move view
const PeerReviewRoutes: Array<RouteRecordRaw> = [
  {
    path: '',
    component: {
      render: () => h(resolveComponent('router-view'))
    },
    beforeEnter: (to, from, next) => {
      const params = {
        courseId: to.params?.courseId,
        homeworkId: to.params?.homeworkId,
        homeworkPlanId: to.params?.homeworkPlanId
      }
      const query = {
        hideTranslations: to.query?.hideTranslations
      }
      const role = getRole()
      if(role === PersonRoles.Teacher || role === PersonRoles.Tutor) {
        next({ name: 'tutor-reviews', params: params, query: query })
      } else {
        next({ name: 'student-reviews', params: params, query: query })
      }
    }
  },
  {
    path: 'student',
    component: StudentView,
    name: Names.StudentReviews,
    meta: {
      role: [PersonRoles.Student]
    },
    beforeEnter: (to, from, next) => redirect(to, from, next)
  },
  {
    path: 'tutor',
    component: TutorView,
    name: Names.TutorReviews,
    meta: {
      role: [PersonRoles.Tutor, PersonRoles.Teacher]
    },
    beforeEnter: (to, from, next) => redirect(to, from, next)
  }
]

const redirect = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const roles: Array<string> = to.meta.role as Array<string>
  if(roles.includes(getRole())) {
    next()
  } else {
    next({
      path: '/error'
    })
  }
}


export default PeerReviewRoutes
