import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e3e5f214"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-d-flex p-flex-column p-ai-center message-box p-py-3" }
const _hoisted_2 = { class: "p-px-6 p-mb-4" }
const _hoisted_3 = {
  key: 0,
  class: "p-px-6 p-mb-4 subtitle"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_base = _resolveComponent("icon-base")!
  const _component_DynamicCard = _resolveComponent("DynamicCard")!

  return (_openBlock(), _createBlock(_component_DynamicCard, {
    borderless: "",
    shadowless: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_icon_base, {
          class: "p-m-4",
          width: "24",
          height: "24",
          viewBox: _ctx.viewBoxIcon,
          strokeColor: _ctx.iconColor
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon)))
          ]),
          _: 1
        }, 8, ["viewBox", "strokeColor"]),
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "title", {}, undefined, true)
        ]),
        _createElementVNode("span", {
          class: _normalizeClass(["p-px-6 subtitle", _ctx.secondaryMessage ? `p-mb-3` : `p-mb-4`])
        }, _toDisplayString(_ctx.message), 3),
        (_ctx.secondaryMessage)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.secondaryMessage), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 3
  }))
}