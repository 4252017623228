
// Components
import Card from '@/components/aero/surfaces/Card.vue'
import Icon from '@/components/aero/icon/Icon.vue'
import { CheckFill } from '@/components/aero/icon/templates'

export default {
  components: {
    Card,
    Icon,
    CheckFill
  },
  props: {
    message: { type: String, required: true }
  }
}
