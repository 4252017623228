import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_attendance_table = _resolveComponent("attendance-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("div", null, [
      _createVNode(_component_attendance_table, {
        isTutor: "",
        tutor: {},
        data: _ctx.setTableData[0].users,
        columns: _ctx.columns,
        filterTitle: _ctx.filterTitle,
        schedules: _ctx.schedules,
        attendances: _ctx.setTableData[0].attendance,
        onOnChange: _ctx.handleToggleAttendance
      }, null, 8, ["data", "columns", "filterTitle", "schedules", "attendances", "onOnChange"])
    ], 512), [
      [_vShow, _ctx.filterSelected === 'students']
    ]),
    _withDirectives(_createElementVNode("div", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.setTableData, (group, index) => {
        return (_openBlock(), _createBlock(_component_attendance_table, {
          key: index,
          isTutor: "",
          tutor: index === 0 ? {} : group.tutor,
          data: group.users,
          columns: _ctx.columns,
          filterTitle: _ctx.filterTitle,
          schedules: _ctx.schedules,
          attendances: group.attendance,
          onOnChange: _ctx.handleToggleAttendance
        }, null, 8, ["tutor", "data", "columns", "filterTitle", "schedules", "attendances", "onOnChange"]))
      }), 128))
    ], 512), [
      [_vShow, _ctx.filterSelected === 'allGroups']
    ])
  ], 64))
}