
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    length: { type: Number, required: false },
    maxWords: { type: Number, default: 5, required: false },
    sm: { type: Boolean, default: false, required: false }
  }
})
