import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_challenge_student = _resolveComponent("challenge-student")!
  const _component_challenges_by_module = _resolveComponent("challenges-by-module")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.role === 2)
      ? (_openBlock(), _createBlock(_component_challenge_student, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.role !== 2)
      ? (_openBlock(), _createBlock(_component_challenges_by_module, { key: 1 }))
      : _createCommentVNode("", true)
  ], 64))
}