
import { defineComponent, PropType, reactive, onMounted } from 'vue'
import EvaluationItem from './EvaluationItem.vue'
import { useI18n } from 'vue-i18n'
import { ICriteria } from '@/models/peer-review/homeworkPlan'

export default defineComponent({
  components: {
    EvaluationItem
  },
  props: {
    isTutor: Boolean,
    evaluationCriteria: {
      type: Object as PropType<ICriteria[]>,
      required: true
    },
    evaluation: {
      type: Object
    },
    studentsEvaluations: {
      type: Array,
      default: () => []
    },
    usePeerReview: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const { t } = useI18n()

    const criteria = reactive<Record<string, string | null>>({})
    onMounted( () => {
      for (const item of props.evaluationCriteria) {
        criteria[item.id] = null
      }
      emit('update:evaluation', criteria)
    })

    const selectCriteria = () => {
      emit('update:evaluation', criteria)
    }

    return {
      t,
      criteria,
      selectCriteria
    }
  }
})
