
// Vendor
import { defineComponent, onMounted, ref, watch } from 'vue'

// Store
import { themeStore } from '@/store/modules/themes.store'
import { useUserSessionStore } from '@/store/modules/user-session.store'
import { useCourseStore } from '@/store/modules/course.store'

// Components
import TwoColsLayout from '@/components/aero/layout/TwoColsLayout.vue'
import RankingCard from '@/components/aero/surfaces/dashboard/RankingCard.vue'
import UserRow from '@/views/ranking/components/UserRow.vue'
import Card from '@/components/aero/surfaces/Card.vue'
import SkeletonBox from '@/components/aero/skeleton/SkeletonBox.vue'

// Icons
import Icon from '@/components/aero/icon/Icon.vue'
import { Clock, Trophy } from '@/components/aero/icon/templates'

// Models
import { UserLeaderboard } from '@/models/dashboard/ranking'

// Locales
import copies from '@/locales/ranking/ranking.json'

export default defineComponent({
  components: { TwoColsLayout, RankingCard, UserRow, Card, Icon, Trophy, Clock, SkeletonBox },
  setup() {
    themeStore().changeDefault('new-theme')

    const { userId } = useUserSessionStore()
    const courseFromStore = useCourseStore()

    const ranking = ref<UserLeaderboard[]>([])
    const currentUser = ref<UserLeaderboard>()
    const isEnded = ref(false)
    const isStarted = ref(false)
    const isInProgress = ref(false)

    const isLoading = ref(true)
    const isError = ref(false)

    const handleSetLeaderboard = () => {
      isEnded.value = courseFromStore.isEnded
      isStarted.value = courseFromStore.isStarted
      isInProgress.value = courseFromStore.isInProgress

      if (courseFromStore.leaderboard && courseFromStore.leaderboard.length) {
        ranking.value = courseFromStore.leaderboard
        currentUser.value = courseFromStore.leaderboard.find((u) => u._id === userId)
        isLoading.value = false
      } else {
        isError.value = true
      }
    }

    watch(courseFromStore, handleSetLeaderboard)

    onMounted(() => {
      handleSetLeaderboard()
      setTimeout(() => {
        const el = document.querySelector('.user__row-sticky') as HTMLElement

        const observer = new IntersectionObserver(([e]) => e.target.classList.toggle('is-pinned', e.intersectionRatio < 1), {
          threshold: [1]
        })

        el && observer.observe(el)
      }, 100)
    })

    return {
      currentUser,
      userId,
      ranking,
      isLoading,
      isError,
      isEnded,
      isStarted,
      isInProgress,
      copies
    }
  }
})
