
import { defineComponent, reactive, ref, PropType, onBeforeUpdate } from 'vue'
import { useVuelidate, ValidationArgs } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import InputEditor from '@/components/commons/InputEditor.vue'
import ChallengePreview from './ChallengePreview.vue'
import ChallengeEvaluation from './ChallengeEvaluation.vue'
import { ICorrection, ICriteria } from '@/models/peer-review/homeworkPlan'
import { useI18n  } from 'vue-i18n'

export default defineComponent({
  emits: ['send'],
  components: {
    InputEditor,
    ChallengePreview,
    ChallengeEvaluation
  },
  props: {
    correction: {
      type: Object as PropType<ICorrection>,
      required: true
    },
    sending: Boolean,
    evaluationCriteria: {
      type: Object as PropType<ICriteria[]>,
      required: true
    }
  },
  setup (props, { emit }) {
    const isEnabled = ref<boolean>(false)
    const { t } = useI18n()
    const state = reactive({
      evaluation: {},
      comment: ''
    })
    const sent = ref<boolean>(false)
    const evaluationError = ref<boolean>(false)
    const rules: ValidationArgs = {
      comment: { required }
    }
    const v$ = useVuelidate(rules, state)
    const send = async () => {
      sent.value = true
      await v$.value.$validate()
      if(Object.values(state.evaluation).some(o => o === null)) {
        evaluationError.value = true
        return
      } else {
        evaluationError.value = false
      }
      if (v$.value.$invalid) return
      emit('send', state)
    }

    onBeforeUpdate(() => {
      if (Object.values(state.evaluation).some((o) => o === null || state.comment.length < 10)) {
          isEnabled.value = true
        } else {
          isEnabled.value = false
        }
    })

    return {
      t,
      v$,
      sent,
      send,
      state,
      evaluationError,
      isEnabled
    }
  }
})
