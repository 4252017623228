import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "jobs-container" }
const _hoisted_2 = { class: "jobs-offers-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")!
  const _component_JobOffersFilters = _resolveComponent("JobOffersFilters")!
  const _component_JobOfferList = _resolveComponent("JobOfferList")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Loader, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_JobOffersFilters, {
          onIsSearching: _ctx.setSearchValue,
          hasFilters: _ctx.hasFilters
        }, null, 8, ["onIsSearching", "hasFilters"]),
        _createVNode(_component_JobOfferList, {
          hasCoderPlus: _ctx.hasCoderPlus,
          hasSetPreferences: _ctx.hasSetPreferences,
          preferencesFromDialog: _ctx.preferences,
          userSectorsName: _ctx.userSectorsName,
          isSearching: _ctx.searchValue,
          userAreaId: _ctx.userAreaId || '',
          userExpertiseResponse: _ctx.userExpertiseResponse || [],
          userSectorsId: _ctx.userSectorsId || [],
          userModalitiesId: _ctx.userModalitiesId || [],
          onHasFilters: _ctx.ifhasFilters,
          onTabSelected: _ctx.tabSelected
        }, null, 8, ["hasCoderPlus", "hasSetPreferences", "preferencesFromDialog", "userSectorsName", "isSearching", "userAreaId", "userExpertiseResponse", "userSectorsId", "userModalitiesId", "onHasFilters", "onTabSelected"])
      ])
    ])
  ], 64))
}