import { Api } from '../api'

// Models
import { Course, ICourse } from '@/models/navigation/course'
import { Assignment } from '@/models/navigation/assignment'
import { INotification } from '@/models/navigation/notifications'

// Utils
import { getBrowser, getOS } from '@/utils/userAgent'

const { API_ENDPOINT, ACADEMIC_API_ENDPOINT } = process.env

/**
 * API request to fetch courses data based con user role
 * @param userId - id of the user you would like to retrieve the list os courses
 * @param role - role of the user  would like to retrieve the list os courses
 * @returns list of courses the user is subscribed
 */

const getCoursesEndpoint = (userId: string, role: number): Promise<ICourse[]> => {
  if (role === 2) {
    return Api.get(`${API_ENDPOINT}/platform/v6/students/${userId}/courses?lean=1&ts=${Date.now()}&userId=${userId}&v6=1`)
  } else {
    return Api.get(`${API_ENDPOINT}/platform/teachers/${userId}/courses?all=true&ts=${Date.now()}&userId=${userId}&v4=1`)
  }
}

/**
 * API request to retrieve the courses data from a certain user.
 * @param userId Logged user id
 * @returns an array of courses with sharedFolderLink + teachersSharedFolderLink
 */

const getAssigments = async (userId: string): Promise<Assignment[]> => {
  return await Api.get(`${API_ENDPOINT}/platform/users/${userId}/assignments?ts=${Date.now()}`)
}

/**
 * API request to retrieve the courses from a certain user.
 * @param userId Logged user id
 * @returns an array of courses.
 */
export const getCourses = async (userId: string, role: number): Promise<Course[]> => {
  try {
    const [coursesResponse, assigments] = await Promise.all([getCoursesEndpoint(userId, role), getAssigments(userId)])

    const courses: Course[] = []

    coursesResponse.forEach((course: ICourse) => {
      const courseTmp = new Course(
        course.level.title,
        course.camada._id,
        course._id,
        course.camada.id,
        '',
        '',
        course.flags.isEnded,
        course.flags.isStarted,
        course.flags.isInProgress,
        course.flags.hasCoderBeca,
        course.startDate,
        course.endDate,
        course.flags
      )

      const assigment = assigments.find((item) => item._id === course._id)
      if (assigment) {
        courseTmp.sharedFolderLink = assigment.sharedFolderLink
        courseTmp.teachersSharedFolderLink = assigment.teachersSharedFolderLink
      }

      courses.push(courseTmp)
    })

    return courses
  } catch (e) {
    throw new Error('error while trying to retrieve list of courses')
  }
}

/**
 * API request to retrieve the notifications from a certain user.
 * @param userId Logged user id
 * @returns an array of notifications.
 */
export const getUserNotifications = async (userId: string) => {
  const response: INotification[] = await Api.get(
    `${API_ENDPOINT}/platform/users/${userId}/push-notifications?ts=${Date.now()}&userId=${userId}`
  )

  return response
}

/**
 * API call to mark a notification as readed.
 * @param userId logged user id
 * @param notificationId logged notifications id
 * @returns confirmation message
 */
export const markAsReadNotification = async (userId: string, notificationId: string) => {
  const response = await Api.put(`${API_ENDPOINT}/platform/users/${userId}/push-notifications/${notificationId}/readed`, {})

  return response
}

/**
 * API call to register devices on the backend server.
 * @param userId logged user id
 * @param token token from firebase cloud messagin
 * @returns token's array
 */
export const updateToken = async (userId: string, token: string) => {
  const browser = getBrowser()
  const os = getOS()

  const payload = {
    userId,
    os,
    browser,
    token
  }

  const response = await Api.post(`${ACADEMIC_API_ENDPOINT}/v1/users/${userId}/devices`, payload)

  return response
}
