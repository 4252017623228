import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0acee221"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-container" }
const _hoisted_2 = { class: "" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 2,
  class: "container-empty"
}
const _hoisted_5 = { class: "container-empty__logo" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "container-empty__text" }
const _hoisted_8 = { class: "container-empty__title" }
const _hoisted_9 = { class: "container-empty__subtitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tabs = _resolveComponent("Tabs")!
  const _component_skeleton_text = _resolveComponent("skeleton-text")!
  const _component_JobOfferCard = _resolveComponent("JobOfferCard")!
  const _component_Paginator = _resolveComponent("Paginator")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Tabs, {
      tabs: _ctx.tabs,
      onChange: _ctx.changeTab,
      hasCoderPlus: _ctx.hasCoderPlus,
      class: "tabs"
    }, null, 8, ["tabs", "onChange", "hasCoderPlus"]),
    _createElementVNode("div", {
      class: _normalizeClass(["body-container", _ctx.jobOffers.length === 0 ? 'body-container--empty' : ''])
    }, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.tabSelected == 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_ctx.loading)
                ? (_openBlock(), _createBlock(_component_skeleton_text, {
                    key: 0,
                    length: 15
                  }))
                : (_ctx.jobOffers?.length)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jobOffers, (jobOffer) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "card-container",
                          key: jobOffer.id
                        }, [
                          _createVNode(_component_JobOfferCard, { jobOffer: jobOffer }, null, 8, ["jobOffer"])
                        ]))
                      }), 128)),
                      _createElementVNode("div", null, [
                        _createVNode(_component_Paginator, {
                          first: _ctx.offset,
                          rows: _ctx.rowsDefault,
                          totalRecords: _ctx.totalFirstTab * _ctx.rowsDefault,
                          onPage: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changePage($event.page)))
                        }, null, 8, ["first", "rows", "totalRecords"])
                      ])
                    ], 64))
                  : _createCommentVNode("", true),
              (_ctx.jobOffers.length === 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("img", {
                        class: "noCards",
                        src: require('@/assets/icons/suitcase.png'),
                        alt: "empty"
                      }, null, 8, _hoisted_6)
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.t('job-offers.empty.title')), 1),
                      _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.t('job-offers.empty.subtitle')), 1)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ])
    ], 2)
  ]))
}