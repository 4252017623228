import { ref } from 'vue'

import { NewPlatform, JobOffers } from '@/router/route-names'

import lottie, { AnimationItem } from 'lottie-web'
import Briefcase from '@/assets/sidebar/briefcase.json'
import Calendar from '@/assets/sidebar/calendar.json'
import Chats from '@/assets/sidebar/chatsCircle.json'
import Discount from '@/assets/sidebar/discount.json'
import House from '@/assets/sidebar/house.json'
import ListBullet from '@/assets/sidebar/listBullets.json'
import Play from '@/assets/sidebar/play.json'
import ShoppingCart from '@/assets/sidebar/shoppingCart.json'
import SquaresFour from '@/assets/sidebar/squaresFour.json'
import Trending from '@/assets/sidebar/trending.json'

export function useSidebarAnimation() {
  const iconAnimation = ref<AnimationItem[]>([])
  const suffix = 'DOM-id'

  const loadLottie = (sectionName: string, DOMId: Element) => {
    const lottieIcon = getSectionIcon(sectionName)
    const lottieObj = lottie.loadAnimation({
      container: DOMId,
      loop: false,
      renderer: 'svg',
      autoplay: false,
      name: sectionName,
      animationData: lottieIcon
    })

    iconAnimation.value?.push(lottieObj)
  }

  function getSectionIcon(section: string) {
    switch (section) {
      case NewPlatform.DASHBOARD:
        return House
      case NewPlatform.CLASSES:
        return Calendar

      case NewPlatform.CHALLENGES:
        return ListBullet

      case NewPlatform.COMMISSION:
      case NewPlatform.ATTENDANCE:
        return SquaresFour

      case NewPlatform.CHAT:
        return Chats

      case NewPlatform.RATINGS:
        return Trending

      case NewPlatform.RESOURCES:
        return Play

      case JobOffers.Jobs:
        return Briefcase

      case NewPlatform.BENEFITS:
        return Discount

      case NewPlatform.CONTRATAR:
        return ShoppingCart

      default:
        return ''
    }
  }

  function setHoverIn(name: string) {
    iconAnimation.value?.find((icon) => icon.name === name)?.playSegments([3, 27], true)
  }

  function setHoverOut(name: string) {
    iconAnimation.value?.find((icon) => icon.name === name)?.playSegments([63, 87], true)
  }

  function clickedIcon(name: string, wrapperDom: Element, iconDom: Element) {
    const iconLottie = iconAnimation.value?.find((icon) => icon.name === name)
    if (wrapperDom.contains(iconDom)) {
      iconLottie?.playSegments([183, 207], true)
    } else {
      iconLottie?.playSegments([243, 267], true)
    }
  }

  const setLottieAnimations = (sections: string[]) => {
    sections.forEach((section) => {
      const wrapperDomElement = document.querySelector(`#sb-${section}-wrapper`)
      const DOMElement = document.querySelector(`#${section}${suffix}`)
      if (DOMElement && wrapperDomElement) {
        loadLottie(section, DOMElement)

        wrapperDomElement?.addEventListener('mouseenter', () => setHoverIn(section))
        wrapperDomElement?.addEventListener('mouseleave', () => setHoverOut(section))
        wrapperDomElement?.addEventListener('click', () => clickedIcon(section, wrapperDomElement, DOMElement))
      }
    })
  }

  const removeLottieAnimations = (sections: string[]) => {
    sections.forEach((section) => {
      const wrapperDomElement = document.querySelector(`#sb-${section}-wrapper`)
      const DOMElement = document.getElementById(section + suffix)
      if (DOMElement && wrapperDomElement) {
        wrapperDomElement?.removeEventListener('mouseenter', () => setHoverIn(section))
        wrapperDomElement?.removeEventListener('mouseleave', () => setHoverOut(section))
        wrapperDomElement?.removeEventListener('click', () => clickedIcon(section, wrapperDomElement, DOMElement))
      }
    })
  }

  return { setLottieAnimations, removeLottieAnimations }
}
