import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_linkedin = _resolveComponent("linkedin")!
  const _component_whatsapp = _resolveComponent("whatsapp")!
  const _component_download = _resolveComponent("download")!
  const _component_copy = _resolveComponent("copy")!

  return (_ctx.name === 'linkedin')
    ? (_openBlock(), _createBlock(_component_linkedin, { key: 0 }))
    : (_ctx.name === 'whatsapp')
      ? (_openBlock(), _createBlock(_component_whatsapp, { key: 1 }))
      : (_ctx.name === 'download')
        ? (_openBlock(), _createBlock(_component_download, { key: 2 }))
        : (_ctx.name === 'copy')
          ? (_openBlock(), _createBlock(_component_copy, { key: 3 }))
          : _createCommentVNode("", true)
}