import { CorrectionStates } from '@/utils/constants'

export interface ICorrection {
  id: number,
  status: CorrectionStates,
  icon: Element
}


export enum PeerReviewComponent {
  HIDE = 0,
  SHOW = 1
}
