import { Auth0Token, DevUserLogin } from '../models/user-session'
import axios from 'axios'
import { Api } from './api'

async function logout(): Promise<void> {
  delete axios.defaults.headers.common.Authorization
}

async function devLogin({ username, password }: DevUserLogin): Promise<Auth0Token> {
  const body = {
    username: username,
    password: password,
    client_id: `${process.env.AUTH0_CLIENT_ID}`,
    connection: 'Username-Password-Authentication',
    grant_type: 'password',
    scope: 'openid'
  }
  return Api.post<Auth0Token>(`${process.env.AUTH0}`, body)
}

export default {
  logout,
  devLogin
}
