import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4af99182"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "p-d-flex p-ai-center p-py-3"
}
const _hoisted_2 = { class: "p-ml-3 t2 bold text-white" }
const _hoisted_3 = { class: "p2 text-light-gray p-d-flex p-ai-center p-jc-between" }
const _hoisted_4 = { class: "p2" }
const _hoisted_5 = { class: "p2" }
const _hoisted_6 = {
  key: 1,
  class: "modules__list",
  id: "modules-container"
}
const _hoisted_7 = { class: "p-d-flex p-jc-between p-ai-center p-mt-3 challenge__header" }
const _hoisted_8 = { class: "t2 label-title" }
const _hoisted_9 = { class: "label label-bold medium text-primary" }
const _hoisted_10 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorPage = _resolveComponent("ErrorPage")!
  const _component_toast = _resolveComponent("toast")!
  const _component_SkeletonAside = _resolveComponent("SkeletonAside")!
  const _component_breadcrumb = _resolveComponent("breadcrumb")!
  const _component_avatar = _resolveComponent("avatar")!
  const _component_countdown = _resolveComponent("countdown")!
  const _component_state_tags = _resolveComponent("state-tags")!
  const _component_list_tab = _resolveComponent("list-tab")!
  const _component_SkeletonTimeline = _resolveComponent("SkeletonTimeline")!
  const _component_placeholder_state = _resolveComponent("placeholder-state")!
  const _component_message = _resolveComponent("message")!
  const _component_state_historial = _resolveComponent("state-historial")!
  const _component_review_challenge = _resolveComponent("review-challenge")!
  const _component_Timeline = _resolveComponent("Timeline")!
  const _component_two_cols_layout = _resolveComponent("two-cols-layout")!

  return (_ctx.isError)
    ? (_openBlock(), _createBlock(_component_ErrorPage, {
        key: 0,
        copies: _ctx.copies.error
      }, null, 8, ["copies"]))
    : (_openBlock(), _createBlock(_component_two_cols_layout, { key: 1 }, {
        aside: _withCtx(() => [
          _createVNode(_component_toast),
          (_ctx.isAsideLoading)
            ? (_openBlock(), _createBlock(_component_SkeletonAside, { key: 0 }))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createVNode(_component_breadcrumb, {
                  class: "p-mt-3 p-ml-2",
                  home: { label: `${_ctx.copies.challengesByStudent.breadcrum.mainLabel}`, to: '/challenges' },
                  model: [
            {
              label: `${_ctx.copies.challengesByStudent.breadcrum.challengeClass} ${_ctx.activeChallenge.classOrder}`,
              to: `/challenges/challenge/${_ctx.activeChallenge.moduleId}`
            }
          ]
                }, null, 8, ["home", "model"]),
                (_ctx.studentData)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      _createVNode(_component_avatar, {
                        name: _ctx.studentData.fullName,
                        imageLink: _ctx.studentData.avatar
                      }, null, 8, ["name", "imageLink"]),
                      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.studentData.fullName), 1)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.copies.challengesByStudent.asideList.label1), 1),
                  _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.copies.challengesByStudent.asideList.label2), 1)
                ]),
                (!_ctx.isAsideLoading)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modules, (module) => {
                        return (_openBlock(), _createBlock(_component_list_tab, {
                          variant: "challenge",
                          key: module._id,
                          DOMId: module._id,
                          challenge: {
              _id: module._id,
              title: module.name,
              class: module.class.order,
              project: module.project,
              expiration: module.expiresAt,
              finalDeadline: module.finalDeadline,
              studentId: _ctx.studentData._id
            },
                          isActive: module._id === _ctx.activeChallenge.moduleId,
                          hasUnreadMessage: module.project && _ctx.checkUnreadMessages(module.project.chat),
                          scheduleId: module.class._id,
                          onOnClickCard: ($event: any) => (_ctx.handleClick(module._id, module.name, module.projectId)),
                          onMarkAsUnread: _ctx.markAsUnread,
                          onLockDeliver: _ctx.handleLockDeliver,
                          onUnlockDeliver: _ctx.handleUnlockDeliver
                        }, {
                          default: _withCtx(() => [
                            (_ctx.requiredForCountDown(module))
                              ? (_openBlock(), _createBlock(_component_countdown, {
                                  key: 0,
                                  data: [{ expiresAt: module.expiresAt }],
                                  finalDeadline: module.finalDeadline || null,
                                  copies: _ctx.copies.countdown,
                                  challenge: _ctx.getCountDownChallenge(module)
                                }, null, 8, ["data", "finalDeadline", "copies", "challenge"]))
                              : (_ctx.requiredForStatusTag(module))
                                ? (_openBlock(), _createBlock(_component_state_tags, {
                                    key: 1,
                                    role: 3,
                                    state: _ctx.getStatusTag(module),
                                    copies: _ctx.copies.challengesByModule.statusTags
                                  }, null, 8, ["state", "copies"]))
                                : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1032, ["DOMId", "challenge", "isActive", "hasUnreadMessage", "scheduleId", "onOnClickCard", "onMarkAsUnread", "onLockDeliver", "onUnlockDeliver"]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ], 64))
        ]),
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", null, [
              _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.activeChallenge.challengeName), 1),
              _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.copies.challengesByStudent.content.header.title), 1)
            ]),
            (_ctx.activeChallenge.slideUrl)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: _ctx.activeChallenge.slideUrl,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleTrackEvent(_ctx.ChallengesEvents.VIEW_MODULE_ASSETS, { origin: 'Challenges by student' }))),
                  target: "_blank",
                  rel: "noopener noreferrer",
                  class: "p-button secondary"
                }, _toDisplayString(_ctx.copies.challengesByStudent.content.header.cta), 9, _hoisted_10))
              : _createCommentVNode("", true)
          ]),
          (_ctx.isTimeLineLoading)
            ? (_openBlock(), _createBlock(_component_SkeletonTimeline, { key: 0 }))
            : (_openBlock(), _createBlock(_component_Timeline, {
                key: 1,
                class: "timeline__space"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeChallenge.history, (step) => {
                    return (_openBlock(), _createBlock(_component_state_historial, {
                      key: step.key,
                      step: step,
                      role: 3,
                      isTutor: _ctx.isTutor,
                      isIntegratorTp: _ctx.activeChallenge.IntegratorTp
                    }, {
                      default: _withCtx(() => [
                        (step.key === _ctx.ITimeLineStatus.disabled)
                          ? (_openBlock(), _createBlock(_component_placeholder_state, {
                              key: 0,
                              dateString: _ctx.setDateTimeString(_ctx.activeChallenge.openDate),
                              isDisabled: "",
                              role: 3,
                              class: "step__spacing"
                            }, null, 8, ["dateString"]))
                          : (step.key === _ctx.ITimeLineStatus.open && !_ctx.activeChallenge.deliverId && !_ctx.activeChallenge.isDeliverExpired)
                            ? (_openBlock(), _createBlock(_component_placeholder_state, {
                                key: 1,
                                dateString: _ctx.activeChallenge.expiresText,
                                role: 3,
                                class: "step__spacing"
                              }, null, 8, ["dateString"]))
                            : (step.key === _ctx.ITimeLineStatus.chat && !step.hide)
                              ? (_openBlock(), _createBlock(_component_message, {
                                  key: 2,
                                  avatar: step.content.from.avatar,
                                  userName: step.content.from.fullName,
                                  id: step.content._id,
                                  messageType: step.content.from._id === _ctx.studentData._id ? 'received' : 'sent',
                                  message: step.content.text,
                                  date: step.content.date,
                                  file: step.content.file ? { text: step.content.link } : null,
                                  link: !step.content.file && step.content.link ? { text: step.content.link } : null,
                                  class: "step__spacing"
                                }, null, 8, ["avatar", "userName", "id", "messageType", "message", "date", "file", "link"]))
                              : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1032, ["step", "isTutor", "isIntegratorTp"]))
                  }), 128)),
                  (_ctx.setReviewChallenge)
                    ? (_openBlock(), _createBlock(_component_review_challenge, {
                        key: 0,
                        isTutor: _ctx.isTutor,
                        teacherId: _ctx.teacherId,
                        isIntegrator: _ctx.activeChallenge.isIntegratorTp,
                        isPreIntegrator: _ctx.activeChallenge.isPreIntegratorTp,
                        userId: _ctx.studentData._id,
                        project: {
            courseId: _ctx.courseId,
            _id: _ctx.activeChallenge.deliverId,
            name: _ctx.activeChallenge.challengeName,
            score: _ctx.activeChallenge.score,
            softScore: _ctx.activeChallenge.fromSoftScore,
            wasApproved: _ctx.activeChallenge.wasApproved
          },
                        onReloadChallenge: _cache[1] || (_cache[1] = ($event: any) => (_ctx.getDeliverStatus(_ctx.activeChallenge.deliverId))),
                        onReviewSuccess: _ctx.handleCorrectionSuccess,
                        onReviewFailed: _ctx.handleCorrectionFailed,
                        class: _normalizeClass(["step__spacing", !_ctx.isTutor && !_ctx.activeChallenge.isIntegratorTp ? 'p-mt-0' : ''])
                      }, null, 8, ["isTutor", "teacherId", "isIntegrator", "isPreIntegrator", "userId", "project", "onReviewSuccess", "onReviewFailed", "class"]))
                    : (
            (_ctx.activeChallenge.deliverId &&
              !_ctx.activeChallenge.wasApproved &&
              _ctx.activeChallenge.unlockedUntil &&
              _ctx.activeChallenge.unlockedUntil <= Date.now()) ||
            (_ctx.activeChallenge.deliverId &&
              !_ctx.activeChallenge.wasApproved &&
              _ctx.activeChallenge.finalDeadline &&
              _ctx.activeChallenge.finalDeadline < Date.now()) ||
            (!_ctx.activeChallenge.deliverId && _ctx.activeChallenge.isDeliverExpired)
          )
                      ? (_openBlock(), _createBlock(_component_placeholder_state, {
                          key: 1,
                          role: 3,
                          isExpired: "",
                          class: "step__spacing"
                        }))
                      : _createCommentVNode("", true)
                ]),
                _: 1
              }))
        ]),
        _: 1
      }))
}