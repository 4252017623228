
// Vendor
import { defineComponent, onMounted, reactive, toRefs, computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { DateTime, Interval } from 'luxon'

// Store
import { themeStore } from '@/store/modules/themes.store'
import { useUserStore } from '@/store/modules/user.store'
import { useCourseStore } from '@/store/modules/course.store'

// API
import {
  getChallengeDataService,
  TGetChallenge,
  updateMessageViewed,
  updateMessageUnviewed,
  onLockDeliver,
  onUnlockDeliver
} from '@/services/challenges.services'
import { getDeliverData, postDeliverMessage } from '@/api/newPlatform/challenges.api'

// MODELS
import { IDeliverMessage, IDeliverTeacher, IDeliverLock } from '@/models/newPlatform/challenges/deliver'
import { IChat } from '@/models/newPlatform/challenges/chat'
import { ITimeline, ITimeLineStatus } from '@/models/newPlatform/challenges/timeline'
import { IStudent } from '@/models/newPlatform/challenges/student'

// Components
import TwoColsLayout from '@/components/aero/layout/TwoColsLayout.vue'
import Breadcrumb from 'primevue/breadcrumb'
import ErrorPage from '@/components/aero/dataDisplay/ErrorPage.vue'
import ListTab from '@/components/aero/surfaces/challenges/ListTab.vue'
import StateHistorial from '@/components/aero/dataDisplay/StateHistorial.vue'
import PlaceholderState from '@/components/aero/surfaces/challenges/PlaceholderState.vue'
import Message from '@/components/aero/surfaces/Message.vue'
import Icon from '@/components/aero/icon/Icon.vue'
import { AlertCircle, Clock } from '@/components/aero/icon/templates'
import ReviewChallenge from './ReviewChallenge.vue'
import SkeletonTimeline from '@/components/aero/skeleton/SkeletonTimeline.vue'
import SkeletonDesafioModule from '@/components/aero/skeleton/SkeletonDesafioModule.vue'
import Toast from '@/components/aero/feedback/Toast.vue'

// Hooks
import { useTimeline, useUpdateChatApproved, useUpdateChallengeApproved } from '@/hooks/challenges/useTimeline'
import { setChallengesByPriority, isOutOfTime, isToReceive, isPending, isExpired } from '@/hooks/challenges/useSortStudentStatus'
import { useToast } from 'primevue/usetoast'

// Utils
import { getTime, getDate, getHours } from '@/utils/datetime'

// Amplitude
import { ChallengesEvents } from '@/amplitude/constants'
import { trackEvent } from '@/amplitude/actions'

// Locales
import {
  challengesByModule as copies,
  ReviewChallenge as reviewCopies,
  enableLockedDeliver,
  error as errorCopy
} from '@/locales/challenges/es.json'
import { ToastMessageOptions } from 'primevue/toast'

interface IStudentDeliver {
  student: { _id: string; fullName: string; avatar: string }
  expiresAt: number
  finalDeadline?: number | null
  isDeliverExpired: boolean
  hasDeliver: boolean
  unlockedUntil?: number | null
  isLocked?: boolean
  deliverId?: string
  wasReviewed: boolean
  wasReviewedOnTime?: boolean
  firstReviewEstimatedAt: number
  hoursLeftToReview?: number
  wasApproved?: boolean
  chat?: IChat[]
  reviewsCount: number
}

export default defineComponent({
  components: {
    TwoColsLayout,
    Breadcrumb,
    ErrorPage,
    ListTab,
    StateHistorial,
    PlaceholderState,
    Message,
    Icon,
    Clock,
    AlertCircle,
    ReviewChallenge,
    SkeletonTimeline,
    Toast,
    SkeletonDesafioModule
  },
  setup() {
    const challengeData = reactive({
      challengeName: '',
      expiresAt: 0,
      isDeliverExpired: false,
      classOrder: 1,
      isPreIntegratorTp: false,
      isIntegratorTp: false,
      slideUrl: '',
      students: [] as IStudentDeliver[],
      scheduleId: ''
    })

    const activeStudent = reactive({
      _id: '',
      deliverId: '',
      fromSoftScore: '',
      score: 0,
      wasApproved: false,
      wasDisapproved: false,
      wasReviewed: false,
      firstReviewEstimatedAt: null as null | number,
      unlockedUntil: null as null | number,
      flagDeliverAfterExpire: false,
      finalDeadline: null as null | number,
      isLocked: false,
      timeline: [] as ITimeline[],
      chat: [] as IChat[],
      history: [] as (ITimeline | IChat)[],
      relatedStudents: [] as IStudent[]
    })

    const challengeOpenDate = ref<number>(0)
    const isChallengeEnabled = ref<boolean>(false)
    const isLoading = ref<boolean>(true)
    const isTimeLineLoading = ref<boolean>(true)

    const isError = ref<{ aside: boolean; timeline: boolean }>({ aside: false, timeline: false })

    themeStore().changeDefault('aero')
    const { id: userId, isTutor, role, fullName, avatar } = useUserStore()
    const { id: courseId, camadaNro } = useCourseStore()
    const route = useRoute()
    const toast = useToast()
    const { mockdata, order, rol } = route.query
    const { challengeId } = route.params

    const rolParam = rol || 3
    const isTeacher = Number(role) === 3 && !isTutor

    // Fetch Data from API Service
    const getChallenge = async () => {
      try {
        const getChallengeDataProps: TGetChallenge = {
          returnMockData: !!mockdata,
          rol: `${rolParam}`,
          order: Number(order),
          userId: userId,
          camadaId: camadaNro,
          courseId: courseId,
          challengeId: String(challengeId) || '000'
        }

        const response = await getChallengeDataService(getChallengeDataProps)

        challengeData.challengeName = response.moduleName
        challengeData.scheduleId = response.class._id
        challengeData.expiresAt = response.deliverFlags.deliverExpirationDate
        const isExpired = response.deliverFlags.deliverExpirationDate < Date.now()
        challengeData.isDeliverExpired = isExpired
        challengeData.classOrder = response.class.classId
        challengeData.isPreIntegratorTp = response.deliverFlags.isPreIntegratorTp
        challengeData.isIntegratorTp = response.deliverFlags.isIntegratorTp
        challengeData.slideUrl = response.slideUrl

        const studentsList: IStudentDeliver[] = response.students.map((person: IDeliverTeacher) => {
          const isExpired = challengeData.expiresAt < Date.now()
          const unlockedDate = person.deliver?.unlockedUntil ? DateTime.fromISO(person.deliver?.unlockedUntil).toMillis() : null
          const studentStatus: IStudentDeliver = {
            student: {
              _id: person.student._id,
              fullName: person.student.fullName || 'Empty Name',
              avatar: person.student.avatar || ''
            },
            expiresAt: challengeData.expiresAt,
            isDeliverExpired: isExpired,
            hasDeliver: person.flags.hasDeliver,
            unlockedUntil: unlockedDate || null,
            isLocked: person.deliver?.flags?.isLocked || false,
            deliverId: person.deliver?._id,
            wasReviewed: person.deliver?.flags?.wasReviewed || false,
            wasReviewedOnTime: person.deliver?.flags?.wasReviewedOnTime || false,
            firstReviewEstimatedAt: person.deliver?.flags?.firstReviewEstimatedAt || 0,
            hoursLeftToReview: person.deliver?.flags?.hoursLeftToReview,
            wasApproved: person.deliver?.flags?.wasApproved || false,
            chat: person.deliver?.chat,
            finalDeadline: person.deliver?.timeline ? getFinalDeadline(person.deliver.timeline) : null,
            reviewsCount: person.deliver?.flags?.reviewsCount || 0
          }

          return studentStatus
        })

        const orderChallenges = setChallengesByPriority(studentsList, challengeData.isDeliverExpired)

        challengeData.students = orderChallenges

        challengeOpenDate.value = response.openDateModule
        isChallengeEnabled.value = challengeOpenDate.value < Date.now()
        activeStudent._id = challengeData.students[0].student._id

        const history = {
          timeline: activeStudent.timeline,
          chat: activeStudent.chat
        }
        activeStudent.history = useTimeline(
          history,
          challengeOpenDate.value,
          challengeData.expiresAt,
          isChallengeEnabled.value,
          isTeacher,
          activeStudent.firstReviewEstimatedAt || 0
        )

        if (challengeData.students[0].deliverId) {
          const deliverId = challengeData.students[0].deliverId
          getDeliverStatus(deliverId)
        }
      } catch (error) {
        isError.value.aside = true
      } finally {
        isLoading.value = false
        isTimeLineLoading.value = false
      }
    }

    const getDeliverStatus = async (deliverId: string, camadaId = Number(camadaNro)) => {
      try {
        isTimeLineLoading.value = true
        const response = await getDeliverData(camadaId, deliverId)
        const unlockedDate = response.unlockedUntil ? DateTime.fromISO(response.unlockedUntil).toMillis() : null

        activeStudent.flagDeliverAfterExpire = response.flagDeliverAfterExpire || false
        activeStudent.deliverId = deliverId
        activeStudent.isLocked = response.flags?.isLocked || false
        activeStudent.score = response.score || 0
        activeStudent.fromSoftScore = response.toSoftScore || ''
        activeStudent.wasApproved = response.flags?.wasApproved || false
        activeStudent.wasDisapproved = response.flags?.wasDisapproved || false
        activeStudent.wasReviewed = response.flags?.wasReviewed || false
        activeStudent.unlockedUntil = unlockedDate
        activeStudent.finalDeadline = response.timeline ? getFinalDeadline(response.timeline) : null
        activeStudent.firstReviewEstimatedAt = response.firstReviewEstimatedAt || null
        activeStudent.timeline = response.timeline || []
        activeStudent.chat = response.chat || []
        activeStudent.relatedStudents = response.relatedStudents || []

        const history = {
          timeline: activeStudent.timeline,
          chat: activeStudent.chat
        }

        activeStudent.history = useTimeline(
          history,
          challengeOpenDate.value,
          activeStudent.finalDeadline || 0,
          isChallengeEnabled.value,
          isTeacher,
          activeStudent.firstReviewEstimatedAt || 0
        )

        const student = challengeData.students.find((student) => student.deliverId === deliverId)

        if (student) {
          const lastChat = student.chat?.slice(-1)[0]
          if (lastChat?.flags?.fromStudent && !lastChat?.viewed) {
            await markAsRead()
          }
        }
      } catch (e) {
        isError.value.timeline = true
      } finally {
        isTimeLineLoading.value = false
      }
    }

    const setActiveStudentName = computed(() => {
      const person = challengeData.students.find((person: IStudentDeliver) => person.student._id === activeStudent._id)

      return person?.student.fullName
    })

    // condition to display/hide review component
    const setReviewChallenge = computed(() => {
      return (
        // review unlocked challenge
        (challengeOpenDate.value < Date.now() &&
          activeStudent.deliverId &&
          activeStudent.unlockedUntil &&
          activeStudent.unlockedUntil > Date.now() &&
          !activeStudent.wasApproved &&
          activeStudent.history.length &&
          activeStudent.history[activeStudent.history.length - 1].key === ITimeLineStatus.deliveredAgain) ||
        // review first deliver
        (challengeOpenDate.value < Date.now() &&
          activeStudent.deliverId &&
          activeStudent.finalDeadline &&
          activeStudent.finalDeadline > Date.now() &&
          !activeStudent.wasReviewed &&
          !activeStudent.wasApproved) ||
        // review challenges that has more than one deliver
        (challengeOpenDate.value < Date.now() &&
          activeStudent.finalDeadline &&
          activeStudent.finalDeadline > Date.now() &&
          activeStudent.deliverId &&
          !activeStudent.wasApproved &&
          activeStudent.history.length &&
          activeStudent.history.slice(-1)[0].key === ITimeLineStatus.deliveredAgain) ||
        (activeStudent.history.length &&
          activeStudent.history?.slice(-1)[0].key === ITimeLineStatus.reviewExpected &&
          activeStudent.deliverId &&
          activeStudent.flagDeliverAfterExpire)
      )
    })

    // checks unread messages from students onload
    const checkUnreadMessages = (chat: IChat[]) => {
      if (!chat.length) return false
      const lastChat = chat.slice(-1)[0]

      if (lastChat.flags?.fromStudent && !lastChat.viewed) {
        return true
      } else {
        return false
      }
    }

    const setClassOrder = () => {
      if (challengeData.classOrder < 10) {
        return `0${challengeData.classOrder}`
      } else {
        return challengeData.classOrder
      }
    }

    const setBreadCrumTitle = () => {
      if (challengeData.isPreIntegratorTp || challengeData.isIntegratorTp) {
        return copies.breadcrum.integratorTp
      } else {
        return `${copies.breadcrum.challengeClass} ${setClassOrder()}`
      }
    }

    const setDateTimeString = (_date: number) => {
      const date = getDate(_date)
      const time = getTime(_date)

      return `${date} a las ${time}`
    }

    const setCountdown = (date: number, toReceive = false) => {
      const now = DateTime.now()
      const later = DateTime.fromMillis(date)
      const interval = Interval.fromDateTimes(now, later)
      if (now > later) return
      const round = (v: number) => Math.round(Math.abs(v))

      if (round(interval.length('hour')) > 24) {
        if (toReceive) return copies.statusTags.toReceive.label
        return setDateTimeTag(date)
      } else {
        const end = DateTime.fromMillis(date)
        const diffTime = end.diff(now, ['hours', 'minutes']).toObject()

        const minutes = diffTime.minutes && round(diffTime.minutes)

        const hasHoursLeft = diffTime.hours && diffTime.hours > 0
        const toReceiveStatus = `${copies.statusTags.toReceive.countdown} ${hasHoursLeft ? diffTime.hours : minutes} ${
          hasHoursLeft ? 'h' : 'm'
        }`

        const toReviewStatus = `${copies.statusTags.review.countdown} ${
          hasHoursLeft ? diffTime.hours + 'h' + ' ' + minutes + 'm' : minutes + 'm'
        }`

        return toReceive ? toReceiveStatus : toReviewStatus
      }
    }

    const setDateTimeTag = (date: number) => {
      return `${getDate(date)} ${getHours(date)}`
    }

    const getFinalDeadline = (timeline: ITimeline[]) => {
      const finalDeadline = timeline.find((step) => step.key === ITimeLineStatus.finalDeadline)
      if (finalDeadline) {
        return typeof finalDeadline.date === 'string' ? DateTime.fromISO(finalDeadline.date).toMillis() : finalDeadline.date
      } else {
        return null
      }
    }

    const handleClickCard = async (studentId: string, deliverId: string) => {
      activeStudent._id = studentId
      if (!deliverId) {
        resetStudentStatus()
      } else {
        await getDeliverStatus(deliverId)
      }
    }

    const resetStudentStatus = () => {
      activeStudent.deliverId = ''
      activeStudent.fromSoftScore = ''
      activeStudent.score = 0
      activeStudent.wasReviewed = false
      activeStudent.unlockedUntil = null
      activeStudent.flagDeliverAfterExpire = false
      activeStudent.isLocked = false
      activeStudent.wasApproved = false
      activeStudent.timeline = []
      activeStudent.chat = []
      activeStudent.history = []
      activeStudent.relatedStudents = []
      const history = {
        timeline: activeStudent.timeline,
        chat: activeStudent.chat
      }
      activeStudent.history = useTimeline(
        history,
        challengeOpenDate.value,
        challengeData.expiresAt,
        isChallengeEnabled.value,
        isTeacher,
        activeStudent.firstReviewEstimatedAt || 0
      )
    }

    // Toast notifications and state update

    /**
     * Displays success toast notification and modifies the state of the deliver
     */
    const handleCorrectionSuccess = ({
      projectId,
      approved,
      comment,
      score
    }: {
      projectId: string
      approved: boolean
      comment: string
      score?: string
    }) => {
      const teacher = {
        avatar: avatar,
        name: fullName,
        _id: userId
      }
      const { success, group, life } = reviewCopies.toast
      toast.add({ severity: reviewCopies.toast.toastSeverity.success as ToastMessageOptions['severity'], detail: success, group, life })

      const isReviewOnTime =
        challengeData.students.filter((student) => student.deliverId === projectId)[0].firstReviewEstimatedAt > Date.now()

      if ((challengeData.isIntegratorTp || challengeData.isPreIntegratorTp) && activeStudent.relatedStudents.length > 0) {
        activeStudent.relatedStudents.forEach((relatedStudent) => {
          const studentData = challengeData.students.find((student) => student.student._id === relatedStudent._id)
          if (studentData) {
            if (approved) {
              // Updated timeline view
              const chatsApproved = useUpdateChatApproved(teacher, comment, false, challengeData.isPreIntegratorTp, score)

              activeStudent.chat = [...activeStudent.chat, ...chatsApproved]

              activeStudent.history = useUpdateChallengeApproved(
                activeStudent.chat,
                activeStudent.timeline,
                challengeOpenDate.value,
                challengeData.expiresAt,
                isChallengeEnabled.value,
                isTeacher,
                activeStudent.firstReviewEstimatedAt || 0
              )
              activeStudent.wasApproved = true
              setApproveAnimation(studentData.student._id)
            }

            studentData.wasApproved = approved
            studentData.wasReviewedOnTime = isReviewOnTime
            studentData.wasReviewed = true
            if (studentData.chat) {
              studentData.chat.slice(-1)[0].flags.fromStudent = false
            }
          }
        })
      } else {
        const student = challengeData.students.find((student) => student.deliverId === projectId)
        if (student) {
          if (approved) {
            let chatsApproved: IChat[]
            if ((challengeData.isPreIntegratorTp || challengeData.isIntegratorTp) && score) {
              chatsApproved = useUpdateChatApproved(teacher, comment, false, challengeData.isPreIntegratorTp, score)
            } else {
              chatsApproved = useUpdateChatApproved(teacher, comment)
            }
            activeStudent.chat = [...activeStudent.chat, ...chatsApproved]

            // Updated timeline view
            activeStudent.history = useUpdateChallengeApproved(
              activeStudent.chat,
              activeStudent.timeline,
              challengeOpenDate.value,
              challengeData.expiresAt,
              isChallengeEnabled.value,
              isTeacher,
              activeStudent.firstReviewEstimatedAt || 0
            )
            activeStudent.wasApproved = true

            setApproveAnimation(student.student._id)
          }

          student.wasApproved = approved
          student.wasReviewedOnTime = isReviewOnTime
          student.wasReviewed = true
          if (student.chat) {
            student.chat.slice(-1)[0].flags.fromStudent = false
          }
        }
      }
    }

    const handleCorrectionFailed = () => {
      const { error, group, life } = reviewCopies.toast
      toast.add({ severity: reviewCopies.toast.toastSeverity.error as ToastMessageOptions['severity'], detail: error, group, life })
    }

    // Extra actions

    const markAsRead = async () => {
      if (!activeStudent.deliverId) return
      const _deliver: IDeliverMessage = {
        courseId,
        projectId: activeStudent.deliverId,
        teacherId: userId,
        chat: activeStudent.chat
      }
      // update status in the student's list UI.
      // check if the challenge is in group
      if (challengeData.isPreIntegratorTp || challengeData.isIntegratorTp) {
        activeStudent.relatedStudents.forEach((relatedStudent) => {
          const studentData = challengeData.students.find((student) => student.student._id === relatedStudent._id)
          if (studentData) {
            const lastChat = studentData.chat?.slice(-1)[0]
            if (lastChat) lastChat.viewed = userId
          }
        })
      } else {
        const student = challengeData.students.find((student) => student.student._id === activeStudent._id)
        if (student) {
          const lastChat = student.chat?.slice(-1)[0]
          if (lastChat) lastChat.viewed = userId
        }
      }
      updateMessageViewed(_deliver)
    }

    const markAsUnread = async (deliverId: string, chat: IChat[], studentId: string) => {
      if (!deliverId) return

      const _deliver: IDeliverMessage = {
        courseId,
        projectId: deliverId,
        teacherId: userId,
        chat: chat
      }
      // update status in the student's list UI.
      // check if the challenge is in group
      if (challengeData.isPreIntegratorTp || challengeData.isIntegratorTp) {
        activeStudent.relatedStudents.forEach((relatedStudent) => {
          const studentData = challengeData.students.find((student) => student.student._id === relatedStudent._id)
          if (studentData) {
            const lastChat = studentData.chat?.slice(-1)[0]
            if (lastChat) lastChat.viewed = ''
          }
        })
      } else {
        const student = challengeData.students.find((student) => student.student._id === studentId)
        if (student) {
          const lastChat = student.chat?.slice(-1)[0]
          if (lastChat) lastChat.viewed = ''
        }
      }
      await updateMessageUnviewed(_deliver)
    }

    // Lock & Unlock deliver
    const handleLockDeliver = async (deliverId: string, studentId: string) => {
      // toast copies
      const { success, error, group, life } = reviewCopies.lockedNotification
      try {
        if (!deliverId) return

        if (challengeData.isPreIntegratorTp || challengeData.isIntegratorTp) {
          activeStudent.relatedStudents.forEach((relatedStudent) => {
            const studentData = challengeData.students.find((student) => student.student._id === relatedStudent._id)
            if (studentData) {
              studentData.isLocked = true
            }
          })
        } else {
          const student = challengeData.students.find((student) => student.student._id === studentId)
          if (student) {
            student.isLocked = true
          }
        }

        const tempDeliver: IDeliverLock = {
          courseId,
          projectId: deliverId
        }

        await onLockDeliver(tempDeliver, userId)

        toast.add({ severity: reviewCopies.lockedNotification.toastSeverity.success as ToastMessageOptions['severity'], detail: success, group, life })

        await getDeliverStatus(deliverId)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e, 'Ocurrio un error al bloquear una entrega')
        toast.add({ severity: reviewCopies.lockedNotification.toastSeverity.error as ToastMessageOptions['severity'], detail: error, group, life })
      }
    }

    const handleUnlockDeliver = async (deliverId: string, studentId: string) => {
      // toast copies
      const { success, error, group, life } = reviewCopies.unlockedNotification
      try {
        if (!deliverId) return

        const tempDeliver: IDeliverLock = {
          courseId,
          projectId: deliverId
        }

        const unlockedUntil = await onUnlockDeliver(tempDeliver, userId)

        // send the message to update the current status of the timeline to unlocked
        await postDeliverMessage(userId, courseId, activeStudent.deliverId, false, enableLockedDeliver.message, '')

        if (challengeData.isPreIntegratorTp || challengeData.isIntegratorTp) {
          activeStudent.relatedStudents.forEach((relatedStudent) => {
            const studentData = challengeData.students.find((student) => student.student._id === relatedStudent._id)
            if (studentData) {
              studentData.isLocked = false
              studentData.unlockedUntil = unlockedUntil
            }
          })
        } else {
          const student = challengeData.students.find((student) => student.student._id === studentId)
          if (student) {
            student.isLocked = false
            student.unlockedUntil = unlockedUntil
          }
        }

        toast.add({ severity: reviewCopies.unlockedNotification.toastSeverity.success as ToastMessageOptions['severity'], detail: success, group, life })

        await getDeliverStatus(deliverId)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e, 'Ocurrio un error al desbloquear una entrega')
        toast.add({ severity: reviewCopies.unlockedNotification.toastSeverity.error as ToastMessageOptions['severity'], detail: error, group, life })
      }
    }

    // Amplitude track event
    const handleTrackEvent = (name: string, props = {}) => {
      trackEvent(name, props)
    }

    const setApproveAnimation = (studentId: string) => {
      const STUDENT = document.getElementById(studentId)

      const CONTAINER = document.getElementById('students-container')

      STUDENT?.classList.add('animation__approve-challenge-1')

      STUDENT?.addEventListener('animationend', (e: AnimationEvent) => {
        if (e.animationName === 'approve-challenge-1') {
          STUDENT.style.display = 'none'
          CONTAINER?.appendChild(STUDENT)
          STUDENT?.classList.add('animation__approve-challenge-2')
        }
      })
    }

    onMounted(async () => {
      await getChallenge()
    })

    return {
      isTutor,
      teacherId: userId,
      courseId,
      ...toRefs(challengeData),
      isLoading,
      isTimeLineLoading,
      isError,
      challengeOpenDate,
      isChallengeEnabled,
      activeStudent,
      setClassOrder,
      setReviewChallenge,
      setBreadCrumTitle,
      setActiveStudentName,
      setCountdown,
      handleClickCard,
      ITimeLineStatus,
      copies,
      getDeliverStatus,
      setDateTimeString,
      challengeId,
      handleCorrectionSuccess,
      handleCorrectionFailed,
      markAsRead,
      markAsUnread,
      checkUnreadMessages,
      handleLockDeliver,
      handleUnlockDeliver,
      handleTrackEvent,
      ChallengesEvents,
      errorCopy,
      isOutOfTime,
      isToReceive,
      isPending,
      isExpired
    }
  }
})
