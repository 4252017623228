
import { defineComponent, PropType } from 'vue'
import RatingStar from '@/components/aero/dataDisplay/RatingStar.vue'

type Rating = string | number

export default defineComponent({
  components: { RatingStar },
  props: {
    rating: { type: [String, Number] as PropType<Rating>, required: true },
    text: { type: String, required: true },
    color: { type: String, default: 'success' },
    withBorder: { type: Boolean, default: false }
  },
  setup(props) {
    const formatRating = Number(props.rating)
    return { formatRating }
  }
})
