import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["name", "id", "min", "max", "step"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("input", {
    type: "time",
    name: _ctx.name,
    id: _ctx.name,
    min: _ctx.min,
    max: _ctx.max,
    step: _ctx.step,
    onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleSelectedValue && _ctx.handleSelectedValue(...args)))
  }, null, 40, _hoisted_1))
}