import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-53991e87"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "classcard p-d-flex p-ai-center p-jc-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_class_card_content = _resolveComponent("class-card-content")!
  const _component_class_card_buttons = _resolveComponent("class-card-buttons")!
  const _component_card = _resolveComponent("card")!

  return (_openBlock(), _createBlock(_component_card, {
    border: "",
    styles: 'border-color: var(--gray);',
    paddingSm: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_class_card_content, {
          data: _ctx.classData,
          copies: _ctx.content,
          role: _ctx.role
        }, null, 8, ["data", "copies", "role"]),
        (!_ctx.becaSuspended)
          ? (_openBlock(), _createBlock(_component_class_card_buttons, {
              key: 0,
              data: _ctx.classData,
              optionsListClasses: _ctx.optionsListClasses,
              toolTips: _ctx.toolTips,
              nextClass: _ctx.nextClass,
              role: _ctx.role,
              handleOpenForm: _ctx.handleOpenForm,
              createAfterClass: _ctx.handleAfterClass,
              endDate: _ctx.endDate,
              becaSuspended: _ctx.becaSuspended,
              handleEnableRate: _ctx.handleEnableRate,
              useCoderChallenge: _ctx.useCoderChallenge
            }, null, 8, ["data", "optionsListClasses", "toolTips", "nextClass", "role", "handleOpenForm", "createAfterClass", "endDate", "becaSuspended", "handleEnableRate", "useCoderChallenge"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.localAfterClasses?.length > 0)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.localAfterClasses, (afterClass) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "classcard__afterclass p-d-flex p-ai-center p-jc-between",
              key: afterClass._id
            }, [
              _createVNode(_component_class_card_content, {
                isAfterclass: "",
                data: afterClass,
                copies: _ctx.content,
                role: _ctx.role
              }, null, 8, ["data", "copies", "role"]),
              (!_ctx.becaSuspended)
                ? (_openBlock(), _createBlock(_component_class_card_buttons, {
                    key: 0,
                    data: afterClass,
                    optionsListClasses: _ctx.optionsListClasses,
                    toolTips: _ctx.toolTips,
                    nextClass: _ctx.nextClass,
                    role: _ctx.role,
                    onEditAfterclass: _ctx.handleEditAfterclass,
                    onDeleteAfterclass: _ctx.handleDeleteAfterclass,
                    endDate: _ctx.endDate,
                    becaSuspended: _ctx.becaSuspended,
                    handleEnableRate: _ctx.handleEnableRate,
                    useCoderChallenge: _ctx.useCoderChallenge
                  }, null, 8, ["data", "optionsListClasses", "toolTips", "nextClass", "role", "onEditAfterclass", "onDeleteAfterclass", "endDate", "becaSuspended", "handleEnableRate", "useCoderChallenge"]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["styles"]))
}