import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_arrow_up_to_line = _resolveComponent("arrow-up-to-line")!
  const _component_button_icon = _resolveComponent("button-icon")!

  return (_openBlock(), _createBlock(_component_button_icon, {
    onClick: _ctx.windowToTop,
    class: _normalizeClass(["buttonToTop", _ctx.displayButton ? 'buttonToTop--show' : '']),
    size: 24,
    color: _ctx.buttonColor
  }, {
    default: _withCtx(() => [
      _createVNode(_component_arrow_up_to_line)
    ]),
    _: 1
  }, 8, ["onClick", "class", "color"]))
}