
import { defineComponent } from 'vue'

// Component
import Icon from '@/components/aero/icon/Icon.vue'

export default defineComponent({
  components: { Icon },
  props: {
    icon: { type: Object, requerided: true },
    text: { type: String, requerided: true }
  }
})
