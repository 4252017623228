
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CircleBadge',
  props: {
    value: {
      type: Number || String,
      required: false
    },
    color: {
      type: String,
      default: 'primary',
      required: false
    },
    background: {
      type: String,
      default: 'gray-0',
      required: false
    }
  }
})
