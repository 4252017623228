import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-44fb43c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-d-flex p-mb-4" }
const _hoisted_2 = { class: "commentaries" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommunityTag = _resolveComponent("CommunityTag")!
  const _component_PostConversation = _resolveComponent("PostConversation")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (tag, index) => {
        return (_openBlock(), _createBlock(_component_CommunityTag, {
          key: tag.label,
          value: tag.label,
          defaultState: false,
          class: _normalizeClass(index !== 0 ? 'p-ml-2' : ''),
          onClick: () => _ctx.handleTagActive(tag.type)
        }, null, 8, ["value", "class", "onClick"]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.positiveComments, (comment) => {
        return _withDirectives((_openBlock(), _createBlock(_component_PostConversation, {
          key: comment.comment,
          feedbackType: comment.type,
          message: comment.comment
        }, null, 8, ["feedbackType", "message"])), [
          [_vShow, _ctx.positiveTagFilter || _ctx.showAllTags]
        ])
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.neutralComments, (comment) => {
        return _withDirectives((_openBlock(), _createBlock(_component_PostConversation, {
          key: comment.comment,
          feedbackType: comment.type,
          message: comment.comment
        }, null, 8, ["feedbackType", "message"])), [
          [_vShow, _ctx.neutralTagFilter || _ctx.showAllTags]
        ])
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.negativeComments, (comment) => {
        return _withDirectives((_openBlock(), _createBlock(_component_PostConversation, {
          key: comment.comment,
          feedbackType: comment.type,
          message: comment.commentn
        }, null, 8, ["feedbackType", "message"])), [
          [_vShow, _ctx.negativeTagFilter || _ctx.showAllTags]
        ])
      }), 128))
    ])
  ]))
}