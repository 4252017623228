import { App } from '@vue/runtime-core'

import Avatar from 'primevue/avatar'
import Button from 'primevue/button'
import Card from 'primevue/card'
import DataView from 'primevue/dataview'
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions'
import Dropdown from 'primevue/dropdown'
import InputText from 'primevue/inputtext'
import Checkbox from 'primevue/checkbox'
import Message from 'primevue/message'
import Panel from 'primevue/panel'
import TabPanel from 'primevue/tabpanel'
import TabView from 'primevue/tabview'
import Toolbar from 'primevue/toolbar'
import Column from 'primevue/column'
import DataTable from 'primevue/datatable'
import ToggleButton from 'primevue/togglebutton'
import RadioButton from 'primevue/radiobutton'
import Dialog from 'primevue/dialog'
import FileUpload from 'primevue/fileupload'
import InputSwitch from 'primevue/inputswitch'
import Editor from 'primevue/editor'
import { Chart } from 'chart.js'
import Timeline from 'primevue/timeline'
import Tooltip from 'primevue/tooltip'
import Breadcrumb from 'primevue/breadcrumb'
import Toast from 'primevue/toast'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'

/**
 * Register componentes used in all the application
 * @param app the application to register components to
 */
export function registerCommonComponents(app: App): void {
  app.component('Avatar', Avatar)
  app.component('Button', Button)
  app.component('Card', Card)
  app.component('DataView', DataView)
  app.component('Dropdown', Dropdown)
  app.component('DataViewLayoutOptions', DataViewLayoutOptions)
  app.component('InputText', InputText)
  app.component('Message', Message)
  app.component('TabPanel', TabPanel)
  app.component('Panel', Panel)
  app.component('TabView', TabView)
  app.component('Toolbar', Toolbar)
  app.component('ToggleButton', ToggleButton)
  app.component('Chart', Chart)
  app.component('DataTable', DataTable)
  app.component('Column', Column)
  app.component('Dialog', Dialog)
  app.component('Checkbox', Checkbox)
  app.component('FileUpload', FileUpload)
  app.component('Editor', Editor)
  app.component('RadioButton', RadioButton)
  app.component('Timeline', Timeline)
  app.component('InputSwitch', InputSwitch)
  app.component('Breadcrumb', Breadcrumb)
  app.component('Toast', Toast)
  app.component('Accordion', Accordion)
  app.component('AccordionTab', AccordionTab)
}
