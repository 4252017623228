import { IChat } from '@/models/newPlatform/challenges/chat'

interface IStudentDeliver {
  student: { _id: string; fullName: string; avatar: string }
  expiresAt: number
  finalDeadline?: number | null
  isDeliverExpired: boolean
  hasDeliver: boolean
  unlockedUntil?: number | null
  isLocked?: boolean
  deliverId?: string
  wasReviewed: boolean
  wasReviewedOnTime?: boolean
  firstReviewEstimatedAt: number
  hoursLeftToReview?: number
  wasApproved?: boolean
  chat?: IChat[]
  reviewsCount: number
}

export const isOutOfTime = (studentDeliver: IStudentDeliver): boolean => {
  return !!(
    (studentDeliver.deliverId &&
      !studentDeliver.wasApproved &&
      !studentDeliver.wasReviewed &&
      studentDeliver.firstReviewEstimatedAt &&
      studentDeliver.firstReviewEstimatedAt < Date.now()) ||
    (studentDeliver.deliverId &&
      !studentDeliver.wasApproved &&
      studentDeliver.firstReviewEstimatedAt &&
      studentDeliver.firstReviewEstimatedAt < Date.now() &&
      studentDeliver.chat?.length &&
      studentDeliver.chat[studentDeliver.chat.length - 1].flags.fromStudent)
  )
}

export const isPending = (studentDeliver: IStudentDeliver): boolean => {
  return !!(
    (studentDeliver.deliverId &&
      !studentDeliver.wasApproved &&
      !studentDeliver.wasReviewed &&
      studentDeliver.firstReviewEstimatedAt &&
      studentDeliver.firstReviewEstimatedAt > Date.now()) ||
    (studentDeliver.deliverId &&
      !studentDeliver.wasApproved &&
      studentDeliver.firstReviewEstimatedAt &&
      studentDeliver.firstReviewEstimatedAt > Date.now() &&
      studentDeliver.chat?.length &&
      studentDeliver.chat[studentDeliver.chat.length - 1].flags.fromStudent)
  )
}

export const isToReceive = (studentDeliver: IStudentDeliver, isDeliverExpired: boolean): boolean => {
  return !!(
    (!isDeliverExpired && !studentDeliver.deliverId) ||
    (studentDeliver.unlockedUntil &&
      studentDeliver.unlockedUntil > Date.now() &&
      studentDeliver.deliverId &&
      !studentDeliver.wasApproved &&
      studentDeliver.chat?.length &&
      !studentDeliver.chat.slice(-1)[0].flags.fromStudent) ||
    (studentDeliver.deliverId &&
      !studentDeliver.wasApproved &&
      studentDeliver.chat?.length &&
      !studentDeliver.chat.slice(-1)[0].flags.fromStudent &&
      studentDeliver.finalDeadline &&
      studentDeliver.finalDeadline > Date.now())
  )
}

export const isExpired = (studentDeliver: IStudentDeliver, isDeliverExpired: boolean): boolean => {
  return !!(
    (studentDeliver.deliverId &&
      !studentDeliver.wasApproved &&
      studentDeliver.unlockedUntil &&
      studentDeliver.unlockedUntil < Date.now()) ||
    (studentDeliver.deliverId &&
      !studentDeliver.wasApproved &&
      studentDeliver.finalDeadline &&
      studentDeliver.finalDeadline < Date.now()) ||
    (!studentDeliver.deliverId && isDeliverExpired)
  )
}

const isApproved = (studentDeliver: IStudentDeliver): boolean => {
  return !!(studentDeliver.deliverId && studentDeliver.wasApproved)
}

export function setChallengesByPriority(students: IStudentDeliver[], isDeliverExpired: boolean): IStudentDeliver[] {
  const outOfTime: IStudentDeliver[] = []
  const pending: IStudentDeliver[] = []
  const toReceive: IStudentDeliver[] = []
  const expired: IStudentDeliver[] = []
  const approved: IStudentDeliver[] = []

  students.forEach((student) => {
    if (isToReceive(student, isDeliverExpired)) {
      toReceive.push(student)
    } else if (isApproved(student)) {
      approved.push(student)
    } else if (isOutOfTime(student)) {
      outOfTime.push(student)
    } else if (isExpired(student, isDeliverExpired)) {
      expired.push(student)
    } else {
      pending.push(student)
    }
  })

  const delivers = [...outOfTime, ...pending, ...toReceive, ...expired, ...approved]

  return delivers
}
