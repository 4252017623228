
export default {
  props: {
    viewBox: { type: String, default: '0 0 24 24' },
    width: { type: [Number, String], default: 18 },
    height: { type: [Number, String], default: 18 },
    strokeColor: { type: String, default: '#F2F2F2' },
    fillColor: { type: String, default: 'transparent' },
    iconName: { type: String, default: '' }
  }
}
