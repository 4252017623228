
// Vendor
import { defineComponent, ref } from 'vue'
import { required, helpers, maxLength, minLength, integer } from '@vuelidate/validators'

// Components
import Form from '@/components/aero/forms/Form.vue'
import InputError from '@/components/aero/inputs/InputError.vue'
import Toast from '@/components/aero/feedback/Toast.vue'

// Stores
import { useUserSessionStore } from '@/store/modules/user-session.store'
import { useUserStore } from '@/store/modules/user.store'

// Services
import { updateBillingData } from '@/services/newPlatform/profile.services'

// Models
import { BankingArgsForm } from '@/models/profile/profile'

// Hooks
import { useToast } from 'primevue/usetoast'

// Locales
import copies from '@/locales/profile/es.json'

export default defineComponent({
  components: { Form, InputError, Toast },
  props: {
    defaultBillingInfo: { type: Object },
    showFormWithCancelButton: { type: Boolean },
    firstTimeFillingForm: { type: Boolean, default: false },
    onShowForm: { type: Function, required: true }
  },
  setup(props) {
    const userSession = useUserSessionStore()
    const { dni, isNotFromArg } = useUserStore()
    const toast = useToast()

    const buttonLabels = ref({
      submit: copies.billingInformation.submitButton,
      cancel: props.showFormWithCancelButton ? copies.billingInformation.cancelButton : undefined
    })
    const afipMonoCategoryIsDisabled = ref(true)
    const wantUsePaypalAccount = ref(props.defaultBillingInfo?.hasPaypalAccount)

    const questions = ref()
    const rules = ref()
    const loadingSubmit = ref(false)

    // Handle disable form and error message when is not last 3 days in the month
    const daysToEditForm = 3

    const today = new Date()
    const [currYear, currMonth, currDay] = [today.getFullYear(), today.getMonth(), today.getDate()]
    const daysInCurrMonth = new Date(currYear, currMonth + 1, 0).getDate()

    const isLast3DaysOfMonth = () => daysInCurrMonth - currDay < daysToEditForm

    const notAllowedMessage = () => {
      const { available, from, to, of } = copies.errorForm.errorMessage
      const months = Object.values(copies.months)
      const fromDay = daysInCurrMonth - daysToEditForm

      return `${available} ${from} ${fromDay} ${to} ${daysInCurrMonth} ${of} ${months[currMonth]}`
    }

    // The user is allowed to edit the form on the last days of the month or on the first time filling the form
    const allowedToEditForm = isLast3DaysOfMonth() || props.firstTimeFillingForm
    const errorMessageForm = notAllowedMessage()

    const rulesWithoutPPAcount = {
      name: { required: helpers.withMessage(copies.billingInformation.genericRequiredError, required) },
      cbu: { required: helpers.withMessage(copies.billingInformation.genericRequiredError, required) },
      phone: { required: helpers.withMessage(copies.billingInformation.genericRequiredError, required) },
      address: { required: helpers.withMessage(copies.billingInformation.genericRequiredError, required) },
      cp: { required: helpers.withMessage(copies.billingInformation.genericRequiredError, required) }
    }

    const rulesWithPPAcount = {
      paypalAccount: { required: helpers.withMessage(copies.billingInformation.genericRequiredError, required) }
    }

    if (!isNotFromArg) {
      if (props.defaultBillingInfo?.invoiceType === 'monotributista') {
        afipMonoCategoryIsDisabled.value = false
      }

      questions.value = [
        {
          name: 'dni',
          label: copies.billingInformation.dni.label,
          placeholder: '00000000',
          defaultValue: dni,
          disabled: true,
          type: 'text'
        },
        {
          name: 'cuit',
          label: copies.billingInformation.cuit.label,
          placeholder: copies.billingInformation.cuit.placeholder,
          defaultValue: props.defaultBillingInfo?.cuit,
          disabled: !allowedToEditForm,
          type: 'text'
        },
        {
          name: 'invoiceType',
          label: copies.billingInformation.invoiceType.label,
          defaultValue: props.defaultBillingInfo?.invoiceType,
          disabled: !allowedToEditForm,
          type: 'select',
          options: [
            { label: copies.billingInformation.invoiceType.monotributista, value: 'monotributista' },
            { label: copies.billingInformation.invoiceType.inscripto, value: 'inscripto' },
            { label: copies.billingInformation.invoiceType.exento, value: 'exento' }
          ]
        },
        {
          name: 'afipMonoCategory',
          label: copies.billingInformation.afipMonoCategory.label,
          disabled: !allowedToEditForm || afipMonoCategoryIsDisabled,
          type: 'select',
          defaultValue: props.defaultBillingInfo?.afipMonoCategory,
          options: [
            { label: 'A', value: 'A' },
            { label: 'B', value: 'B' },
            { label: 'C', value: 'C' },
            { label: 'D', value: 'D' },
            { label: 'E', value: 'E' },
            { label: 'F', value: 'F' },
            { label: 'G', value: 'G' },
            { label: 'H', value: 'H' },
            { label: 'I', value: 'I' },
            { label: 'J', value: 'J' },
            { label: 'K', value: 'K' }
          ]
        },
        {
          name: 'name',
          label: 'Nombre de tu Banco',
          defaultValue: props.defaultBillingInfo?.name,
          disabled: !allowedToEditForm,
          type: 'text'
        },
        {
          name: 'accountNumber',
          label: copies.billingInformation.accountNumber.label,
          defaultValue: props.defaultBillingInfo?.accountNumber,
          disabled: !allowedToEditForm,
          type: 'text'
        },
        {
          name: 'cbu',
          label: copies.billingInformation.cbu.label,
          defaultValue: props.defaultBillingInfo?.cbu,
          disabled: !allowedToEditForm,
          type: 'text'
        },
        {
          name: 'cbu_alias',
          label: copies.billingInformation.cbuAlias.label,
          defaultValue: props.defaultBillingInfo?.cbu_alias,
          disabled: !allowedToEditForm,
          type: 'text'
        },
        {
          name: 'afipConstancy',
          label: copies.billingInformation.afipConstancy.label,
          defaultValue: props.defaultBillingInfo?.afipConstancy,
          disabled: !allowedToEditForm,
          type: 'file',
          styles: { all: 'grid-column: 1 / 3; !important' }
        },
        {}
      ]

      const notNegative = (val: string) => !val.includes('-')

      rules.value = {
        dni: { required: helpers.withMessage(copies.billingInformation.genericRequiredError, required) },
        cuit: {
          required: helpers.withMessage(copies.billingInformation.genericRequiredError, required),
          maxLength: helpers.withMessage(copies.billingInformation.cuit.placeholder, maxLength(11)),
          minLength: helpers.withMessage(copies.billingInformation.cuit.placeholder, minLength(11)),
          numeric: helpers.withMessage(copies.billingInformation.numericError, integer),
          notNegative: helpers.withMessage(copies.billingInformation.numericError, (val: string) => notNegative(val))
        },
        invoiceType: { required: helpers.withMessage(copies.billingInformation.genericRequiredError, required) },
        afipMonoCategory: {
          required: afipMonoCategoryIsDisabled.value
            ? false
            : helpers.withMessage(copies.billingInformation.genericRequiredError, required)
        },
        name: { required: helpers.withMessage(copies.billingInformation.genericRequiredError, required) },
        accountNumber: {
          required: helpers.withMessage(copies.billingInformation.genericRequiredError, required),
          numeric: helpers.withMessage(copies.billingInformation.numericError, integer),
          notNegative: helpers.withMessage(copies.billingInformation.numericError, (val: string) => notNegative(val))
        },
        cbu: {
          required: helpers.withMessage(copies.billingInformation.genericRequiredError, required),
          maxLength: helpers.withMessage(copies.billingInformation.cbu.error, maxLength(22)),
          minLength: helpers.withMessage(copies.billingInformation.cbu.error, minLength(22)),
          numeric: helpers.withMessage(copies.billingInformation.numericError, integer),
          notNegative: helpers.withMessage(copies.billingInformation.numericError, (val: string) => notNegative(val))
        },
        cbu_alias: { required: helpers.withMessage(copies.billingInformation.genericRequiredError, required) },
        afipConstancy: {
          required: helpers.withMessage(copies.billingInformation.genericRequiredError, required)
        }
      }
    } else {
      questions.value = [
        {
          name: 'hasPaypalAccount',
          label: 'Quiero utilizar Paypal',
          options: [{ label: 'Quiero utilizar Paypal', value: 'PPA', disabled: !allowedToEditForm }],
          defaultValue: props.defaultBillingInfo?.hasPaypalAccount ? ['PPA'] : [],

          type: 'checkbox',
          contained: 'none',
          styles: { all: 'align-self: end; grid-column: 1 / 3; margin-bottom: 1rem !important; margin-top: 0.5rem !important' }
        },
        {
          name: 'paypalAccount',
          label: copies.billingInformation.paypalAccount.label,
          defaultValue: props.defaultBillingInfo?.paypalAccount,
          disabled: !allowedToEditForm || !props.defaultBillingInfo?.hasPaypalAccoun,
          type: 'text'
        },
        {
          name: 'name',
          label: 'Nombre de tu Banco', // TODO
          defaultValue: props.defaultBillingInfo?.name,
          disabled: !allowedToEditForm || wantUsePaypalAccount,
          type: 'text'
        },
        {
          name: 'cbu',
          label: 'SWIFT / IBAN', // TODO
          defaultValue: props.defaultBillingInfo?.cbu,
          disabled: !allowedToEditForm || wantUsePaypalAccount,
          type: 'text'
        },
        {
          name: 'phone',
          label: 'Teléfono', // TODO
          defaultValue: props.defaultBillingInfo?.phone,
          disabled: !allowedToEditForm || wantUsePaypalAccount,
          type: 'text'
        },
        {
          name: 'address',
          label: copies.billingInformation.address.label,
          defaultValue: props.defaultBillingInfo?.address,
          disabled: !allowedToEditForm || wantUsePaypalAccount,
          type: 'text'
        },
        {
          name: 'cp',
          label: 'Código Postal', // TODO
          defaultValue: props.defaultBillingInfo?.cp,
          disabled: !allowedToEditForm || wantUsePaypalAccount,
          type: 'text'
        }
      ]

      if (props.defaultBillingInfo?.hasPaypalAccount) {
        rules.value = rulesWithPPAcount
      } else {
        rules.value = rulesWithoutPPAcount
      }
    }

    const handleCloseForm = () => {
      props.onShowForm(false)
    }

    const handleSubmit = async (values: BankingArgsForm) => {
      const payload = {
        userId: userSession.userId,
        profile: {
          banking: {
            hasPaypalAccount: values.hasPaypalAccount && !!values.hasPaypalAccount[0],
            afipConstancy: values.afipConstancy,
            name: values.name,
            account: {
              number: values.accountNumber
            },
            cuit: values.cuit,
            afipMonoCategory: values.afipMonoCategory,
            cbu: values.cbu,
            cbuValid: true,
            cbu_alias: values.cbu_alias,
            cuitValid: true,
            invoiceType: values.invoiceType,
            paypalAccount: values.paypalAccount,
            socialReason: values.socialReason,
            address: values.address,
            cp: values.cp
          },
          phone: values.phone
        }
      }

      loadingSubmit.value = true
      try {
        if (values.afipConstancy && typeof values.afipConstancy === 'string') {
          await updateBillingData(userSession.userId, payload)
        }
        if (values.afipConstancy && values.afipConstancy instanceof File) {
          await updateBillingData(userSession.userId, payload, values.afipConstancy, values.afipConstancy?.name)
        }

        toast.add({
          severity: 'success',
          detail: 'Datos guardados con éxito',
          group: 'bc',
          life: 4000
        })
        handleCloseForm()
      } catch (error) {
        // eslint-disable-next-line no-console
        toast.add({
          severity: 'error',
          detail: 'Hubo un error, intenta en unos minutos',
          group: 'bc',
          life: 4000
        })
      } finally {
        loadingSubmit.value = false
      }
    }

    const handleChange = (values: { [x: string]: string | [] }) => {
      if (values.hasPaypalAccount && values.hasPaypalAccount[0]) {
        wantUsePaypalAccount.value = true
        questions.value = [
          questions.value[0],
          {
            name: 'paypalAccount',
            label: copies.billingInformation.paypalAccount.label,
            defaultValue: props.defaultBillingInfo?.paypalAccount,
            disabled: !allowedToEditForm,
            type: 'text'
          },
          ...questions.value.slice(2)
        ]

        rules.value = rulesWithPPAcount
      }

      if (values.hasPaypalAccount && !values.hasPaypalAccount[0]) {
        wantUsePaypalAccount.value = false
        questions.value = [
          questions.value[0],
          {
            name: 'paypalAccount',
            label: copies.billingInformation.paypalAccount.label,
            defaultValue: props.defaultBillingInfo?.paypalAccount,
            disabled: true,
            type: 'text'
          },
          ...questions.value.slice(2)
        ]

        rules.value = rulesWithoutPPAcount
      }

      if (values.invoiceType && values.invoiceType === 'monotributista') {
        afipMonoCategoryIsDisabled.value = false
        rules.value = {
          ...rules.value,
          afipMonoCategory: {
            required: helpers.withMessage(copies.billingInformation.genericRequiredError, required)
          }
        }
      }

      if (values.invoiceType && values.invoiceType !== 'monotributista') {
        afipMonoCategoryIsDisabled.value = true
        rules.value = {
          ...rules.value,
          afipMonoCategory: {
            required: false
          }
        }
      }
    }

    const handleDeleteDefaultFile = () => {
      const afipConstancy = questions.value.find(
        (question: { name: string; defaultValue: string }) => question.name === 'afipConstancy'
      )
      afipConstancy.defaultValue = ''
    }

    return {
      questions,
      buttonLabels,
      rules,
      handleSubmit,
      handleChange,
      handleCloseForm,
      loadingSubmit,
      allowedToEditForm,
      errorMessageForm,
      handleDeleteDefaultFile
    }
  }
})
