import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c7a686d"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "select-wrapper"
}
const _hoisted_2 = ["value", "selected"]
const _hoisted_3 = {
  key: 1,
  class: "p2"
}
const _hoisted_4 = {
  key: 2,
  class: "p2 text-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_skeleton_text = _resolveComponent("skeleton-text")!
  const _component_chevron_down = _resolveComponent("chevron-down")!
  const _component_icon = _resolveComponent("icon")!

  return (_ctx.isLoading)
    ? (_openBlock(), _createBlock(_component_skeleton_text, {
        key: 0,
        length: 15
      }))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        (_ctx.courses.length > 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("select", {
                class: "course-selector p2",
                onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleSelectedValue && _ctx.handleSelectedValue(...args))),
                style: _normalizeStyle({ width: `${_ctx.selectWidth}px` })
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.courses, (course) => {
                  return (_openBlock(), _createElementBlock("option", {
                    class: "p2",
                    key: course.id,
                    value: 
            JSON.stringify({
              id: course.id,
              camadaNro: course.camadaNro,
              camadaId: course.camadaId,
              name: course.name,
              sharedFolderLink: course.sharedFolderLink,
              teachersSharedFolderLink: course.teachersSharedFolderLink,
              isEnded: course.isEnded,
              isStarted: course.isStarted,
              isInProgress: course.isInProgress
            })
          ,
                    selected: _ctx.selectedCourse === course.id
                  }, _toDisplayString(_ctx.role === 2 ? course.name : `${course.camadaNro} | ${course.name}`), 9, _hoisted_2))
                }), 128))
              ], 36),
              _createVNode(_component_icon, {
                strokeColor: "var(--white)",
                size: 16
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_chevron_down)
                ]),
                _: 1
              })
            ]))
          : (_ctx.courses.length === 1)
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.courses[0].name), 1))
            : (_ctx.courses.length === 0 || _ctx.hasError)
              ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.copies.errorCourses), 1))
              : _createCommentVNode("", true)
      ], 64))
}