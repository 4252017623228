import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5ec0b5c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-d-flex p-flex-wrap p-jc-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.length)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(`skeleton--text skeleton ${_ctx.sm ? 'sm' : ''}`),
          style: _normalizeStyle(`width:${_ctx.length}ch`)
        }, null, 6))
      : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(Math.floor(Math.random() * _ctx.maxWords) + 1, (l) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(`skeleton--text skeleton p-mr-2 skeleton--paragraph ${_ctx.sm ? 'sm' : ''}`),
            style: _normalizeStyle(`width:${_ctx.length || Math.floor(Math.random() * 15) + 4}ch`),
            key: l
          }, null, 6))
        }), 128))
  ]))
}