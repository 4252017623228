
// Vendor
import { defineComponent, onMounted } from 'vue'

// Components
import DiscordCard from './components/DiscordCard.vue'
// import CommunityCard from './components/CommunityCard.vue'

// Store
import { themeStore } from '@/store/modules/themes.store'

export default defineComponent({
  components: {
    DiscordCard
    // CommunityCard
  },
  setup() {
    themeStore().changeDefault('new-theme')

    onMounted(() => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const homeContainer = document.querySelector<HTMLElement>('#home-container')!

      const resizeObserver = new ResizeObserver(() => {
        const contentHeight = homeContainer.offsetHeight + 100 + 'px'
        window.parent.postMessage(JSON.stringify({ type: 'webinars', height: contentHeight }), '*')
      })

      resizeObserver.observe(homeContainer)
    })
  }
})
