import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, createTextVNode as _createTextVNode, Fragment as _Fragment, withModifiers as _withModifiers, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-319b6133"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-mb-4 p-grid" }
const _hoisted_2 = { class: "p-col-fixed col-avatar" }
const _hoisted_3 = { class: "p-col-4 p-d-flex p-ai-center" }
const _hoisted_4 = { class: "p-grid p-py-2" }
const _hoisted_5 = { class: "p-col-12 p-pb-0" }
const _hoisted_6 = {
  key: 0,
  class: "text-username"
}
const _hoisted_7 = {
  key: 1,
  class: "text-username"
}
const _hoisted_8 = { class: "p-ml-4" }
const _hoisted_9 = {
  key: 0,
  class: "icon-marker"
}
const _hoisted_10 = {
  key: 1,
  class: "dot-marker"
}
const _hoisted_11 = { class: "p-mb-1" }
const _hoisted_12 = {
  key: 1,
  class: "challenge-status"
}
const _hoisted_13 = { class: "challenge-history" }
const _hoisted_14 = {
  key: 0,
  class: "rubrics"
}
const _hoisted_15 = ["onClick"]
const _hoisted_16 = { class: "p-grid card-header" }
const _hoisted_17 = { class: "p-col-fixed chat-avatar-width" }
const _hoisted_18 = { class: "p-col-4" }
const _hoisted_19 = { class: "p-grid" }
const _hoisted_20 = { class: "p-col-12 p-pb-0" }
const _hoisted_21 = { class: "text-base" }
const _hoisted_22 = { class: "p-col-12 p-pt-0" }
const _hoisted_23 = { class: "challenge-date" }
const _hoisted_24 = ["innerHTML"]
const _hoisted_25 = {
  key: 0,
  class: "margin-paragraph"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalRubricasChallenge = _resolveComponent("ModalRubricasChallenge")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_UploadSection = _resolveComponent("UploadSection")!
  const _component_icon_base = _resolveComponent("icon-base")!
  const _component_url_button = _resolveComponent("url-button")!
  const _component_DownloadButton = _resolveComponent("DownloadButton")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Timeline = _resolveComponent("Timeline")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.showModal)
      ? (_openBlock(), _createBlock(_component_ModalRubricasChallenge, {
          key: 0,
          challenge: _ctx.challenge,
          visible: _ctx.showModal,
          onClose: _ctx.closeModal,
          onEnable: _ctx.forwarding,
          rubricas: _ctx.history[_ctx.criteriaIndex].evaluation,
          personStore: _ctx.personStore,
          slotProps: _ctx.history[_ctx.criteriaIndex],
          grade: _ctx.evaluationGrade,
          sending: _ctx.sending,
          showUploadSection: _ctx.showUploadSection,
          isExpired: _ctx.isExpired,
          criteriaIndex: _ctx.criteriaIndex
        }, null, 8, ["challenge", "visible", "onClose", "onEnable", "rubricas", "personStore", "slotProps", "grade", "sending", "showUploadSection", "isExpired", "criteriaIndex"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.student?.avatar || _ctx.studentData?.profile?.avatar)
          ? (_openBlock(), _createBlock(_component_Avatar, {
              key: 0,
              image: _ctx.student?.avatar || _ctx.studentData?.profile?.avatar,
              class: "p-mr-2 avatar-content",
              size: "xlarge",
              shape: "circle"
            }, null, 8, ["image"]))
          : (_openBlock(), _createBlock(_component_Avatar, {
              key: 1,
              label: _ctx.initialLetter(_ctx.student?.name || _ctx.studentData?.profile.name),
              class: "p-mr-2 avatar-content",
              size: "xlarge",
              shape: "circle"
            }, null, 8, ["label"]))
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_ctx.student)
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.student.name), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.personStore.profile.name) + " " + _toDisplayString(_ctx.personStore.profile.lastname), 1))
          ])
        ])
      ])
    ]),
    _createVNode(_Transition, {
      name: "fade",
      appear: ""
    }, {
      default: _withCtx(() => [
        (_ctx.history[0].status === _ctx.HomeworkDeliveryStatus.CLAIMED_FOR_FORWARDING && !_ctx.personStore.profile.roles.includes('teacher') && _ctx.isExpired || _ctx.showUploadSection)
          ? (_openBlock(), _createBlock(_component_UploadSection, {
              key: 0,
              class: "p-mb-4 p-pl-6 p-pr-6",
              onSend: _ctx.send,
              sending: _ctx.sending,
              showCriteria: _ctx.showCriteria,
              setShowCriteria: _ctx.setShowCriteria,
              sendLabelKey: 'homework.challenge.upload.resend.label'
            }, null, 8, ["onSend", "sending", "showCriteria", "setShowCriteria", "sendLabelKey"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_Timeline, {
        value: _ctx.history,
        align: "alternate",
        class: _normalizeClass(['customized-timeline'])
      }, {
        marker: _withCtx((slotProps) => [
          (slotProps.item.status !== 'available')
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createVNode(_component_icon_base, {
                  width: "24",
                  height: "24",
                  viewBox: ['delivered', 'disapproved', 'expired'].includes(slotProps.item.status) ? '0 0 16 16' : '0 0 24 24',
                  "stroke-color": _ctx.getIconColor(slotProps.item.status)
                }, {
                  default: _withCtx(() => [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getIcon(slotProps.item.status))))
                  ]),
                  _: 2
                }, 1032, ["viewBox", "stroke-color"])
              ]))
            : (_openBlock(), _createElementBlock("span", _hoisted_10))
        ]),
        content: _withCtx((slotProps) => [
          _createElementVNode("div", null, [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_11, [
                (['available', 'delivered', 'forwarded'].includes(slotProps.item.status))
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(_toDisplayString(_ctx.t(`homework.challenge.history.${slotProps.item.status}.statusText`, {
                      date: slotProps.item.date,
                      time: slotProps.item.time
                    })), 1)
                    ], 64))
                  : (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.t(`homework.challenge.history.${slotProps.item.status}.statusText`)), 1))
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("span", _hoisted_13, [
                  _createTextVNode(_toDisplayString(_ctx.getHistoryDetail(slotProps.item.status, slotProps.item.createdAt)) + " ", 1),
                  (slotProps.item.evaluation && slotProps.item.evaluation !== null)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_14, [
                        _createElementVNode("a", {
                          href: "#",
                          class: "rubrics-anchor",
                          onClick: _withModifiers(($event: any) => (_ctx.openModal(slotProps.index)), ["prevent"])
                        }, _toDisplayString(_ctx.t('homework.challenge.rubrics.trigger')), 9, _hoisted_15)
                      ]))
                    : _createCommentVNode("", true)
                ])
              ])
            ]),
            (slotProps.item.comment?.message)
              ? (_openBlock(), _createBlock(_component_Card, {
                  key: 0,
                  class: "position-timeline-card"
                }, {
                  content: _withCtx(() => [
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("div", _hoisted_17, [
                        (slotProps.item.author.avatar)
                          ? (_openBlock(), _createBlock(_component_Avatar, {
                              key: 0,
                              image: slotProps.item.author.avatar,
                              class: "p-mr-2",
                              size: "large",
                              shape: "circle"
                            }, null, 8, ["image"]))
                          : (_openBlock(), _createBlock(_component_Avatar, {
                              key: 1,
                              label: _ctx.initialLetter(slotProps.item.comment.author.name),
                              class: "p-mr-2 avatar-content",
                              size: "large",
                              shape: "circle"
                            }, null, 8, ["label"]))
                      ]),
                      _createElementVNode("div", _hoisted_18, [
                        _createElementVNode("div", _hoisted_19, [
                          _createElementVNode("div", _hoisted_20, [
                            _createElementVNode("span", _hoisted_21, _toDisplayString(slotProps.item.comment.author.name), 1)
                          ]),
                          _createElementVNode("div", _hoisted_22, [
                            _createElementVNode("span", _hoisted_23, _toDisplayString(slotProps.item.date) + " " + _toDisplayString(slotProps.item.time), 1)
                          ])
                        ])
                      ]),
                      (slotProps.index === 0 && _ctx.canEdit)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.editChallenge && _ctx.editChallenge(...args))),
                            class: "custom-button-edit"
                          }, [
                            _createVNode(_component_icon_base, {
                              width: "24",
                              height: "24",
                              viewBox: '0 0 24 24',
                              "stroke-color": '#BEBEBE'
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.Pencil)))
                              ]),
                              _: 1
                            })
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("p", {
                      class: "margin-paragraph",
                      innerHTML: slotProps.item.comment.message
                    }, null, 8, _hoisted_24),
                    (slotProps.item.homework.attachment || slotProps.item.homework.link)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                          (slotProps.item.homework.link)
                            ? (_openBlock(), _createBlock(_component_url_button, {
                                key: 0,
                                link: slotProps.item.homework.link
                              }, null, 8, ["link"]))
                            : _createCommentVNode("", true),
                          (slotProps.item.homework.attachment)
                            ? (_openBlock(), _createBlock(_component_DownloadButton, {
                                key: 1,
                                class: "custom-button",
                                label: _ctx.t('homework.challenge.history.download.label'),
                                attachment: slotProps.item.homework.attachment
                              }, null, 8, ["label", "attachment"]))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1024))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["value"])
    ])
  ]))
}