import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-31ef9fb8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nameClass-class" }
const _hoisted_2 = { class: "name-container overflow" }
const _hoisted_3 = { class: "image-text student-name" }
const _hoisted_4 = { class: "status-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomBreadcrumb = _resolveComponent("CustomBreadcrumb")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_Column = _resolveComponent("Column")!
  const _component_icon_base = _resolveComponent("icon-base")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({leftColumn: !_ctx.loading })
  }, [
    _createVNode(_component_CustomBreadcrumb, {
      home: _ctx.home,
      model: _ctx.items
    }, null, 8, ["home", "model"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", null, _toDisplayString(_ctx.nameClass), 1)
    ]),
    _createVNode(_component_DataTable, {
      rowHover: "",
      dataKey: "id",
      class: "p-datatable-sm",
      selectionMode: "single",
      responsiveLayout: "scroll",
      value: _ctx.processedStudents,
      loading: _ctx.loading,
      onRowSelect: _ctx.selectStudent,
      selection: _ctx.selectedStudent,
      "onUpdate:selection": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedStudent) = $event))
    }, {
      empty: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t('homework.challenge.peerReview.correction.tutor.studentsList.empty')), 1)
      ]),
      loading: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t('homework.challenge.peerReview.correction.tutor.studentsList.loading')), 1)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          field: "name",
          header: _ctx.t('homework.challenge.peerReview.correction.tutor.studentsList.student'),
          class: "name-column"
        }, {
          body: _withCtx(({ data }) => [
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_Avatar, {
                image: data.avatar ? data.avatar : '',
                label: !data.avatar ? data.name.slice(0, 1) : null,
                shape: "circle",
                class: "image avatar-content"
              }, null, 8, ["image", "label"]),
              _createElementVNode("span", _hoisted_3, _toDisplayString(data.name), 1)
            ])), [
              [_directive_tooltip, { value: data.name, disabled: !data.isOverflowing }]
            ])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "status",
          header: _ctx.t('homework.challenge.peerReview.correction.tutor.studentsList.status'),
          class: "status-column"
        }, {
          body: _withCtx(({ data }) => [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_icon_base, {
                viewBox: 
                data.homeworkStudentDelivery
                  ? _ctx.getState(data.homeworkStudentDelivery.status, data.homeworkStudentDelivery.schedule?.shouldBeReviewUntil)
                      .viewBox
                  : _ctx.getState(data.homeworkStudentDelivery).viewBox
              ,
                strokeColor: 
                data.homeworkStudentDelivery
                  ? _ctx.getState(data.homeworkStudentDelivery.status, data.homeworkStudentDelivery.schedule?.shouldBeReviewUntil).color
                  : _ctx.getState(data.homeworkStudentDelivery).color
              ,
                class: "status-icon"
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(
                  data.homeworkStudentDelivery
                    ? _ctx.getState(data.homeworkStudentDelivery.status).icon
                    : _ctx.getState(data.homeworkStudentDelivery).icon
                )))
                ]),
                _: 2
              }, 1032, ["viewBox", "strokeColor"]),
              _createElementVNode("span", {
                class: _normalizeClass([
                data.homeworkStudentDelivery
                  ? _ctx.getState(data.homeworkStudentDelivery.status).class
                  : _ctx.getState(data.homeworkStudentDelivery).class,
                'outdated-' +
                  _ctx.isOutdated(data.homeworkStudentDelivery?.status, data.homeworkStudentDelivery?.schedule?.shouldBeReviewUntil)
              ])
              }, _toDisplayString(data.homeworkStudentDelivery
                  ? _ctx.getState(data.homeworkStudentDelivery.status, data.homeworkStudentDelivery.schedule?.shouldBeReviewUntil,data.homeworkStudentDelivery.schedule?.updatableUntil).label
                  : _ctx.getState(data.homeworkStudentDelivery).label), 3)
            ])
          ]),
          _: 1
        }, 8, ["header"])
      ]),
      _: 1
    }, 8, ["value", "loading", "onRowSelect", "selection"])
  ], 2))
}