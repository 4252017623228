
import DynamicCard from '@/components/commons/DynamicCard.vue'
import IconBase from '@/components/commons/IconBase.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    title: String,
    message: String,
    secondaryMessage: String,
    icon: Object,
    iconColor: {
      type: String,
      default: '#393B43'
    },
    viewBoxIcon: {
      type: String,
      default: '0 0 24 24'
    }
  },
  components: {
    DynamicCard,
    IconBase
  }
})
