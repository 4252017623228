import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-02dd9d02"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "accordion_tab-info" }
const _hoisted_2 = { class: "accordion_tab-title" }
const _hoisted_3 = { class: "accordion_tab-description" }
const _hoisted_4 = {
  key: 0,
  class: "accordion_buttons"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AccordionTab = _resolveComponent("AccordionTab")!
  const _component_Accordion = _resolveComponent("Accordion")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    class: "custom-dialog",
    header: _ctx.t('homework.challenge.rubrics.modal.title'),
    breakpoints: { '960px': '75vw', '640px': '100vw' },
    draggable: false,
    dismissableMask: "",
    style: { width: '50vw' },
    closeOnEscape: "",
    visible: _ctx.show,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.show) = $event)),
    modal: "",
    closable: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Accordion, {
        multiple: "",
        expandIcon: "pi-angle-down",
        collapseIcon: "pi-angle-up"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rubriks, (rubrica, index) => {
            return (_openBlock(), _createBlock(_component_AccordionTab, {
              key: rubrica.id,
              header: `${index + 1}. ${rubrica.title}`
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("span", _hoisted_2, _toDisplayString(rubrica.evaluationCriteria.title), 1),
                  _createElementVNode("span", _hoisted_3, _toDisplayString(rubrica.evaluationCriteria.description), 1)
                ])
              ]),
              _: 2
            }, 1032, ["header"]))
          }), 128))
        ]),
        _: 1
      }),
      (_ctx.challenge.type === _ctx.HomeworkTypes.PREFINAL && _ctx.personStore.person.rol === 2)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_Button, {
              class: "p-button-custom-secondary",
              label: _ctx.t('button.cancel'),
              onClick: _ctx.close
            }, null, 8, ["label", "onClick"]),
            _withDirectives((_openBlock(), _createElementBlock("div", null, [
              _createVNode(_component_Button, {
                disabled: !_ctx.isEnabled,
                class: "p-button-custom-primary",
                label: "Volver a entregar",
                onClick: _ctx.enable,
                loading: _ctx.sending
              }, null, 8, ["disabled", "onClick", "loading"])
            ])), [
              [
                _directive_tooltip,
                _ctx.t(`homework.challenge.rubrics.modal.${!_ctx.isEnabled ? 'disabled' : 'enabled'}.tooltip`),
                void 0,
                { top: true }
              ]
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["header", "visible"]))
}