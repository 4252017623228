<template>
  <path
    d="M15.9375 13.6846L12 9.74707L8.0625 13.6846"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path d="M12 22L12 9.75" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  <path d="M6.5 3.74707L17 3.74707" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
</template>
