import { NewPlatform } from '@/router/route-names'

export const Tags = [
  { tag: 'record', icon: '' },
  { tag: 'complementaryClass', path: `/${NewPlatform.CLASSES}`, icon: 'calendar' },
  { tag: 'attendence', path: `/${NewPlatform.CLASSES}` },
  { tag: 'feedbackSuccess', path: `/${NewPlatform.RATING}` },
  { tag: 'ticketUsed' },
  { tag: 'completeHomework', path: `/${NewPlatform.CHALLENGES}`, icon: 'challenge' },
  { tag: 'studentHomework', path: `/${NewPlatform.CHALLENGES}`, icon: 'challenge' },
  { tag: 'studentNewComment', path: `/${NewPlatform.DASHBOARD}`, icon: 'community' },
  { tag: 'teacherNewComment', path: `/${NewPlatform.DASHBOARD}`, icon: 'community' },
  { tag: 'updateHomework', path: `/${NewPlatform.CHALLENGES}`, icon: 'challenge' },
  { tag: 'aproveHomework', path: `/${NewPlatform.CHALLENGES}`, icon: 'challenge' },
  { tag: 'deleteHomework', path: `/${NewPlatform.CHALLENGES}`, icon: 'challenge' },
  { tag: 'studentDeleteHomework', path: `/${NewPlatform.CHALLENGES}`, icon: 'challenge' },
  { tag: 'revisionSent', path: `/${NewPlatform.CHALLENGES}`, icon: 'challenge' },
  { tag: 'withdrawTeacher', path: `/${NewPlatform.CHALLENGES}`, icon: 'challenge' },
  { tag: 'studentReport' },
  { tag: 'postulationSent' },
  { tag: 'jobPostulationNextStep' },
  { tag: 'sentCertificate', icon: 'certificate' },
  { tag: 'turnOffFeedback', path: `/${NewPlatform.DASHBOARD}` },
  { tag: 'newCommentBlog', path: `/${NewPlatform.DASHBOARD}`, icon: 'community' },
  { tag: 'newLikeComment', path: `/${NewPlatform.DASHBOARD}`, icon: 'community' },
  { tag: 'newReactionComment', path: `/${NewPlatform.DASHBOARD}`, icon: 'community' },
  { tag: 'newResponseComment', path: `/${NewPlatform.DASHBOARD}`, icon: 'community' },
  { tag: 'newLikeResponse', path: `/${NewPlatform.DASHBOARD}`, icon: 'community' },
  { tag: 'enrollment' },
  { tag: 'startCourse', path: `/${NewPlatform.CLASSES}`, icon: 'calendar' },
  { tag: 'endCourse', path: `/${NewPlatform.CLASSES}`, icon: 'calendar' },
  { tag: 'upSelling' },
  { tag: 'promoteService' },
  { tag: 'promoterDiscount' },
  { tag: 'newTeacherDiscount' },
  { tag: 'tutorSuscriptionEnd' },
  { tag: 'teacherSuscriptionEnd' },
  { tag: 'salaryEnable' },
  { tag: 'cancelClass', path: `/${NewPlatform.CLASSES}`, icon: 'calendar' }
]
