<template>
  <g>
    <path d="M12.0001 7.50001V12" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M15.8972 14.25L12.0001 12" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M6.73444 9.34832H2.98444V5.59832" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M6.16642 17.8336C7.3202 18.9874 8.79021 19.7731 10.3906 20.0915C11.9909 20.4098 13.6497 20.2464 15.1572 19.622C16.6647 18.9976 17.9531 17.9401 18.8597 16.5834C19.7662 15.2267 20.25 13.6317 20.25 12C20.25 10.3683 19.7662 8.77323 18.8597 7.41652C17.9531 6.05982 16.6647 5.00239 15.1572 4.37797C13.6497 3.75355 11.9909 3.59017 10.3906 3.9085C8.79021 4.22683 7.3202 5.01256 6.16642 6.16635L2.98444 9.34833"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </g>
</template>