import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "reviews p-p-5 p-p-sm-4" }
const _hoisted_2 = { class: "p-grid custom-mt-card" }
const _hoisted_3 = { class: "p-col-3 p-px-5" }
const _hoisted_4 = { class: "p-col-9 p-px-5" }
const _hoisted_5 = {
  key: 0,
  class: "expired-card"
}
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChallengeProgress = _resolveComponent("ChallengeProgress")!
  const _component_MessageBox = _resolveComponent("MessageBox")!
  const _component_ChallengeTimeline = _resolveComponent("ChallengeTimeline")!
  const _component_Upload = _resolveComponent("Upload")!
  const _component_ChallengeCorrectionsList = _resolveComponent("ChallengeCorrectionsList")!
  const _component_StudentCorrection = _resolveComponent("StudentCorrection")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (!_ctx.loading)
          ? (_openBlock(), _createBlock(_component_ChallengeProgress, {
              key: 0,
              challenge: _ctx.challenge,
              delivery: _ctx.delivery,
              "current-step": _ctx.currentStep,
              onChange: _ctx.changeStep,
              class: "",
              usePeerReview: _ctx.usePeerReview,
              orderClass: _ctx.challenge?.classOrder
            }, null, 8, ["challenge", "delivery", "current-step", "onChange", "usePeerReview", "orderClass"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (!_ctx.loading)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_ctx.currentStep == 0)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (
                (_ctx.delivery && _ctx.delivery.events[0]?.status === 'expired') ||
                (!_ctx.delivery && _ctx.DateTime.now() > _ctx.DateTime.fromISO(_ctx.challenge.expirationDate))
              )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                          _createVNode(_component_MessageBox, {
                            icon: _ctx.Lock,
                            message: _ctx.t('homework.challenge.upload.expiredMessage1'),
                            "icon-color": "#BEBEBE"
                          }, {
                            title: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t('homework.challenge.upload.expiredTitle')), 1)
                            ]),
                            _: 1
                          }, 8, ["icon", "message"])
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          (_ctx.showTimeline)
                            ? (_openBlock(), _createBlock(_component_ChallengeTimeline, {
                                key: 0,
                                challenge: _ctx.challenge,
                                "challenge-history": _ctx.delivery,
                                onSend: _ctx.challengeRedelivery,
                                sending: _ctx.sending,
                                showCriteria: _ctx.showCriteria,
                                setShowCriteria: _ctx.setShowCriteria,
                                onEditChallenge: _ctx.showUpload,
                                studentData: _ctx.studentData,
                                onEnable: _ctx.reDeliverChallenge,
                                showUploadSection: _ctx.showUploadSection
                              }, null, 8, ["challenge", "challenge-history", "onSend", "sending", "showCriteria", "setShowCriteria", "onEditChallenge", "studentData", "onEnable", "showUploadSection"]))
                            : (_openBlock(), _createBlock(_component_Upload, {
                                key: 1,
                                slide: _ctx.challenge.slideUrl,
                                onSend: _ctx.challengeDelivery,
                                onCancelEdit: _ctx.cancelEdit,
                                sending: _ctx.sending,
                                mode: _ctx.uploadMode
                              }, null, 8, ["slide", "onSend", "onCancelEdit", "sending", "mode"]))
                        ]))
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    (!_ctx.showCorrectionView)
                      ? (_openBlock(), _createBlock(_component_ChallengeCorrectionsList, {
                          key: 0,
                          onSelect: _ctx.selectDelivery
                        }, null, 8, ["onSelect"]))
                      : _createCommentVNode("", true),
                    (_ctx.showCorrectionView)
                      ? (_openBlock(), _createBlock(_component_StudentCorrection, {
                          key: 1,
                          correction: _ctx.correction,
                          onSend: _ctx.correctionDelivery,
                          sending: _ctx.sending,
                          "evaluation-criteria": _ctx.challenge.evaluationCriteria
                        }, null, 8, ["correction", "onSend", "sending", "evaluation-criteria"]))
                      : _createCommentVNode("", true)
                  ], 64))
            ], 64))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}