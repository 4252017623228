
// Vendor
import { defineComponent } from 'vue'

// Components
import Card from '@/components/aero/surfaces/Card.vue'
import SkeletonMostVotedTag from './SkeletonMostVotedTag.vue'
import SkeletonIcon from './SkeletonIcon.vue'
import SkeletonText from './SkeletonText.vue'

export default defineComponent({
  components: {
    Card,
    SkeletonMostVotedTag,
    SkeletonIcon,
    SkeletonText
  }
})
