
import { defineComponent, watch, ref, PropType } from 'vue'

// Models
import { IChallenge } from '@/models/newPlatform/challenges/challenge'

// Utils
import { getDate, getHours } from '@/utils/datetime'

// Custom Hooks
import useBeginsIn from '@/hooks/classes/useBeginsIn'

// Icons
import Icon from '@/components/aero/icon/Icon.vue'
import { Clock, AlertCircle } from '@/components/aero/icon/templates'

// Luxon
import { DateTime } from 'luxon'

export default defineComponent({
  components: { Icon, Clock, AlertCircle },
  props: {
    role: { type: Number, default: 2 },
    data: { type: Array as PropType<IChallenge[]>, required: true },
    finalDeadline: { type: Number, required: false },
    withLabel: { type: Boolean, default: false },
    copies: { type: Object },
    challenge: { type: Object, required: true }
  },
  setup(props) {
    const expiresAt = ref('')
    const { beginsIn } = useBeginsIn(props.finalDeadline || props.data[0].expiresAt || 0)

    const isExpired = (props.finalDeadline && props.finalDeadline < Date.now()) || (props.data[0].expiresAt || 0) < Date.now()
    const withNumber = `${props.data?.length > 1 ? '1 ' : ''}`
    const text = `${props.withLabel ? props.copies?.expiresAt : props.copies?.haveLeft}`

    /**
     * Returns string depending on hours left for project review
     */
    const correctionExpDate = () => {
      if (props.challenge?.project) {
        if (props.challenge?.project?.flags.hoursLeftToReview > 24) {
          return `${getFirstReviewDate(props.challenge?.project?.flags.firstReviewEstimatedAt)}`
        } else {
          return `${withNumber}${props.copies?.en.charAt(0).toUpperCase() + props.copies?.en.slice(1)}  ${
            Math.floor(getReviewTimeLeft(props.challenge?.project.flags.firstReviewEstimatedAt).hours || 0) > 0
              ? `${Math.floor(getReviewTimeLeft(props.challenge?.project.flags.firstReviewEstimatedAt).hours || 0)}h`
              : ''
          } ${Math.floor(getReviewTimeLeft(props.challenge?.project.flags.firstReviewEstimatedAt).minutes || 0)}m`
        }
      } else {
        return null
      }
    }

    watch(beginsIn, () => {
      if (props.finalDeadline || props?.data?.length) {
        if (beginsIn.value > 1440) {
          if (props.role === 2) {
            expiresAt.value = `${props.copies?.toReceive}`
          }
        } else {
          if (props.role === 2) {
            expiresAt.value = `${withNumber}${props.withLabel ? `${text} ${props.copies?.en}` : `${text}`} ${
              Math.floor(beginsIn.value / 60) > 0 ? Math.floor(beginsIn.value / 60) : ''
            }${Math.floor(beginsIn.value / 60) > 0 ? 'h' : ''}`
          } else {
            expiresAt.value = `${withNumber}${props.copies?.haveLeft} ${Math.floor(beginsIn.value / 60)}h ${beginsIn.value % 60}m`
          }
        }
      }
    })

    // Date helpers
    const getReviewTimeLeft = (date: number) => {
      const now = DateTime.now()
      const endDate = DateTime.fromMillis(date)

      const diff = endDate.diff(now, ['hours', 'minutes'])

      return diff.toObject()
    }

    const getFirstReviewDate = (date: number) => {
      return `${getDate(date)} ${getHours(date)}`
    }

    return {
      expiresAt,
      withNumber,
      isExpired,
      correctionExpDate
    }
  }
})
