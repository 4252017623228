
import Card from '../surfaces/Card.vue'
import SkeletonText from './SkeletonText.vue'
import SkeletonIcon from './SkeletonIcon.vue'
import SkeletonBox from './SkeletonBox.vue'
import SkeletonInput from './SkeletonInput.vue'

export default {
  components: { Card, SkeletonBox, SkeletonIcon, SkeletonText, SkeletonInput }
}
