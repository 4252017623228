
export default {
  props: {
    paddingSm: { type: Boolean, required: false },
    paddingMd: { type: Boolean, required: false },
    border: { type: Boolean, required: false },
    bgDark: { type: Boolean, required: false },
    styles: { type: String, required: false }
  }
}
