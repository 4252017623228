import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-54519f41"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-d-flex p-ai-center" }
const _hoisted_2 = { class: "p-ml-3" }
const _hoisted_3 = { class: "p-d-flex p-mt-2" }
const _hoisted_4 = { class: "p-d-flex p-flex-column p-mt-5" }
const _hoisted_5 = { class: "p-mt-5" }
const _hoisted_6 = { class: "p-mt-5" }
const _hoisted_7 = { class: "p-d-flex p-jc-between p-mt-2 p-mb-4 profile_media" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_skeleton_icon = _resolveComponent("skeleton-icon")!
  const _component_skeleton_text = _resolveComponent("skeleton-text")!
  const _component_skeleton_box = _resolveComponent("skeleton-box")!
  const _component_skeleton_input = _resolveComponent("skeleton-input")!
  const _component_card = _resolveComponent("card")!

  return (_openBlock(), _createBlock(_component_card, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_skeleton_icon, { class: "profile_picture" }),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_skeleton_text, { length: 10 }),
          _createElementVNode("span", _hoisted_3, [
            _createVNode(_component_skeleton_text, { length: 7 }),
            _createVNode(_component_skeleton_text, {
              class: "p-ml-2",
              length: 10
            })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_skeleton_text, { length: 8 }),
        _createVNode(_component_skeleton_box, {
          class: "p-mt-2",
          height: "150px"
        })
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_skeleton_text, { length: 7 }),
        _createVNode(_component_skeleton_text, {
          class: "p-mt-2",
          length: 14
        })
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_skeleton_text, { length: 7 }),
        _createElementVNode("div", _hoisted_7, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (i) => {
            return _createVNode(_component_skeleton_box, {
              key: i,
              height: "25px",
              width: "25px"
            })
          }), 64))
        ])
      ]),
      _createVNode(_component_skeleton_input)
    ]),
    _: 1
  }))
}