import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3d25074e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-d-flex p-flex-column" }
const _hoisted_2 = { class: "p-d-flex p-flex-row" }
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = { class: "p-d-flex p-flex-row" }
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "form-group" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "p-d-flex p-flex-row" }
const _hoisted_11 = { class: "form-group" }
const _hoisted_12 = { class: "container-checkbox" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Checkbox = _resolveComponent("Checkbox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("label", null, _toDisplayString(_ctx.t("onboarding.step-peronal-exp.work.role")), 1),
          _createElementVNode("input", {
            type: "text",
            onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeWorkTitle && _ctx.changeWorkTitle(...args)))
          }, null, 32)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("label", null, _toDisplayString(_ctx.t("onboarding.step-peronal-exp.work.company")), 1),
          _createElementVNode("input", {
            type: "text",
            onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.changeWorkCompany && _ctx.changeWorkCompany(...args)))
          }, null, 32)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("label", null, _toDisplayString(_ctx.t("onboarding.step-peronal-exp.work.startDate")), 1),
        _createElementVNode("label", null, [
          _createVNode(_component_Calendar, {
            class: "step-calendar",
            modelValue: _ctx.from,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.from) = $event)),
            dateFormat: "dd/mm/yy",
            manualInput: false
          }, null, 8, ["modelValue"]),
          _createElementVNode("img", {
            class: "calendar-icon",
            src: require('@/assets/icons/calendar.svg')
          }, null, 8, _hoisted_7)
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("label", null, _toDisplayString(_ctx.t("onboarding.step-peronal-exp.work.endDate")), 1),
        _createElementVNode("label", null, [
          _createVNode(_component_Calendar, {
            class: "step-calendar",
            modelValue: _ctx.to,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.to) = $event)),
            dateFormat: "dd/mm/yy",
            manualInput: false
          }, null, 8, ["modelValue"]),
          _createElementVNode("img", {
            class: "calendar-icon",
            src: require('@/assets/icons/calendar.svg')
          }, null, 8, _hoisted_9)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_Checkbox, {
            binary: true,
            modelValue: _ctx.currentlyWorking,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.currentlyWorking) = $event)),
            onChange: _ctx.changeCurrentlyWorking
          }, null, 8, ["modelValue", "onChange"]),
          _createElementVNode("label", null, _toDisplayString(_ctx.t("onboarding.step-peronal-exp.work.currentlyWorking")), 1)
        ])
      ])
    ])
  ], 64))
}