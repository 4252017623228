<template>
  <path
    d="M13.9362 8.32496V4.42498C13.9362 3.64923 13.6456 2.90525 13.1283 2.35671C12.611 1.80817 11.9094 1.5 11.1778 1.5L7.5 10.2749V20.9999H17.8714C18.3149 21.0052 18.7453 20.8404 19.0832 20.5358C19.4211 20.2312 19.6438 19.8074 19.7103 19.3424L20.9792 10.5674C21.0192 10.288 21.0014 10.0026 20.9271 9.73114C20.8528 9.45968 20.7237 9.20859 20.5487 8.99528C20.3738 8.78197 20.1573 8.61154 19.9141 8.49579C19.6709 8.38005 19.4068 8.32176 19.1403 8.32496H13.9362Z"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="2"
    fill="#B6B6B6"
  />
  <path
    d="M7.57256 20.9999H4.82902C4.34394 20.9999 3.87872 20.7944 3.53571 20.4287C3.1927 20.063 3 19.5671 3 19.0499V12.2249C3 11.7078 3.1927 11.2118 3.53571 10.8461C3.87872 10.4804 4.34394 10.2749 4.82902 10.2749H7.57256M13.9741 8.32496V4.42498C13.9741 3.64923 13.6851 2.90525 13.1706 2.35671C12.6561 1.80817 11.9582 1.5 11.2306 1.5L7.57256 10.2749V20.9999H17.8882C18.3293 21.0052 18.7574 20.8404 19.0935 20.5358C19.4296 20.2312 19.6511 19.8074 19.7173 19.3424L20.9793 10.5674C21.0191 10.288 21.0014 10.0026 20.9275 9.73114C20.8535 9.45967 20.7251 9.20859 20.5512 8.99528C20.3772 8.78197 20.1618 8.61154 19.9199 8.49579C19.678 8.38005 19.4154 8.32176 19.1503 8.32496H13.9741Z"
    stroke-linecap="round"
    stroke-width="2"
    stroke-linejoin="round"
  />
</template>
