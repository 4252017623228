import { formatDate, formatTime } from '@/utils/dates'
import {IEvaluationCriteria} from '@/models/peer-review/homeworkPlan'
export interface IAuthor {
  personId: string
  name: string
  avatar: string
}

export interface IAuthor2 {
  personId: string
  name: string
  avatar: string
}

export interface IComment {
  id: string
  message: string
  author: IAuthor2
  createdAt: Date
}

export interface IAnonimousComment {
  id: string
  message: string
  createdAt: Date
}

export interface IAttachment {
  id: string
}
export interface IHomework {
  link: string
  attachment?: IAttachment
  createdAt: Date
}
export interface IEvent {
  status: string
  author: IAuthor
  comment: IComment
  homework: IHomework
  createdAt: string
  evaluation: Record<string, string> | null
}

export interface IChallengeHistory {
  id: string
  status: string
  events: IEvent[]
  createdAt: string
  type: string
  updatableUntil: string
  shouldBeReviewUntil: string
  grade: number
}

export interface IEvaluationCriterias {
  evaluationCriteria: IEvaluationCriteria
  id: string
  title: string
}

export class ChallengeHistory {
  id: string
  status: string
  events: IEvent[]
  createdAt: string
  type: string
  updatableUntil: string
  shouldBeReviewUntil: string
  grade: number
  constructor (from: IChallengeHistory) {
    this.id = from.id
    this.status = from.status
    this.events = from.events.map((event) => new Event(event))
    this.createdAt = from.createdAt
    this.type = from.type
    this.updatableUntil = from.updatableUntil
    this.shouldBeReviewUntil = from.shouldBeReviewUntil
    this.grade = from.grade
  }
}

export class Event {
  status: string
  author: IAuthor
  comment: IComment
  homework: IHomework
  createdAt: string
  date: string
  time: string
  evaluation: Record<string, string> | null
  constructor(from: IEvent) {
    this.status = from.status
    this.comment = from.comment
    this.author = from.author
    this.homework = from.homework
    this.createdAt = from.createdAt
    this.date = formatDate(from.createdAt)
    this.time = formatTime(from.createdAt)
    this.evaluation = from.evaluation
  }
}

export class EvaluationCriteria {
  evaluationCriteria: IEvaluationCriteria
  id: string
  title: string
  constructor(from: IEvaluationCriterias) {
    this.id = from.id
    this.evaluationCriteria = from.evaluationCriteria
    this.title = from.title
  }
}