
import { defineComponent } from 'vue'

// Components
import ProfileCard from '@/components/aero/dataDisplay/ProfileCardSmall.vue'
import ButtonIcon from '@/components/aero/buttons/ButtonIcon.vue'
import { Message } from '@/components/aero/icon/templates'

export default defineComponent({
  components: { ProfileCard, ButtonIcon, Message },

  props: {
    name: { type: String, required: true },
    category: { type: String, required: true },
    imageLink: { type: String, default: '' },
    chatLink: { type: String, default: '' },
    profileLink: { type: String, default: '' }
  }
})
