
// Vendor
import { defineComponent, onMounted, ref, reactive } from 'vue'
import { useRouter } from 'vue-router'

// Components
import SmallCardCourse from '@/components/aero/surfaces/SmallCardCourse.vue'
import Toast from '@/components/aero/feedback/Toast.vue'
import Dialog from 'primevue/dialog'
import Carousel from 'primevue/carousel'

import SkeletonSmallCardCourse from '@/components/aero/skeleton/SkeletonSmallCardCourse.vue'
import UpsellingBanner from '@/components/aero/feedback/dashboard/UpsellingBanner.vue'

// Hooks
import { useToast } from 'primevue/usetoast'

// Stores
import { useUserSessionStore } from '@/store/modules/user-session.store'
import { useUserStore } from '@/store/modules/user.store'

// Models
import { Course, CourseData } from '@/models/navigation/course'
import { ReferralIcon } from '@/models/profile/profile'
import { Perk } from '@/models/dashboard/perks'

// Services
import { getCourses } from '@/services/newPlatform/navigation.services'
import {
  getEnrollCertificate,
  requestAditionalSchedule,
  requestReSchedule,
  getZoomCredentials,
  getReferralCouponInformation,
  getShortenUrlInformation,
  putShareCoupon,
  getRequests
} from '@/services/newPlatform/profile.services'

import { getPerk } from '@/services/newPlatform/dashboard.services'

import { getUserById } from '@/api/user'

// Copies
import copies from '@/locales/profile/es.json'
import { useI18n } from 'vue-i18n'

// Icons
import ButtonIcon from '@/components/aero/buttons/ButtonIcon.vue'
import ReferralActionsIcon from '@/components/aero/icon/templates/ReferralActionsIcon.vue'

// Utils
import { parseToMillis, getMonthName, getYear } from '@/utils/datetime'

// Amplitude
import { trackEvent } from '@/amplitude/actions'
import { ProfileEvents } from '@/amplitude/constants'
import { ToastMessageOptions } from 'primevue/toast'

export default defineComponent({
  components: { SmallCardCourse, Toast, Dialog, ButtonIcon, ReferralActionsIcon, SkeletonSmallCardCourse, UpsellingBanner, Carousel },
  emits: ['onUnsubscribe'],
  props: {
    isVisitorView: { type: Boolean, default: false },
    userIdToVisit: { type: String, default: '' }
  },
  setup(props, { emit }) {
    const user = useUserStore()
    const { userId } = useUserSessionStore()
    const toast = useToast()
    const router = useRouter()
    const { t } = useI18n()

    const isLoading = ref(true)
    const courses = ref<CourseData[]>([])
    const optionsCourse = ref<Array<{ label: string; command?: () => void }[]>>([])
    const errorTraining = ref(false)

    const displayReferral = ref(false)
    const couponReferral = ref({ _id: '', code: '', enrollmentDiscount: '' })
    const couponImgUrl = ref('')
    const mediaLinksToShare = ref({ linkedin: '', whatsapp: '' })
    const linksLoading = ref(true)

    const responsiveOptions = ref<Array<{ breakpoint: string, numVisible: number, numScroll: number }>>([
			{ breakpoint: '2024px', numVisible: 2, numScroll: 1 },
			{ breakpoint: '1440px', numVisible: 1, numScroll: 1 }
		])

    // const perk = ref<Perk>()
    const perk: Perk = reactive({
      discount: '',
      link: '',
      text: '',
      courseTitle: '',
      related: []
    })

    const userRole = ref<number>(user.role)

    const zoomCredentialsDialog = ref<{ isOpen: boolean; user: string; password: string }>({
      isOpen: false,
      user: '',
      password: ''
    })

    interface referralOption {
      name: ReferralIcon
      action: () => void
    }

    const handleCloseDialog = () => {
      zoomCredentialsDialog.value = { ...zoomCredentialsDialog.value, isOpen: false }
    }

    const handleCloseReferral = () => {
      displayReferral.value = false
    }

    // Get referral coupon image
    const createUrlImage = (coupon: string) => {
      const bucket = 'hdsqazxtw'
      const fontSize = 32
      const text = `l_text:Arial_${fontSize}_bold:${coupon}`
      const imagePath = 'v1620829876/cupones/Cupones%2015%20grandes/Horizontal_1.jpg'
      const xPos = -185
      const yPos = -50

      return `https://res.cloudinary.com/${bucket}/${text},x_${xPos},y_${yPos}/${imagePath}`
    }

    const toDataURL = async (url: string) => {
      const req = await fetch(url)
      const blob = await req.blob()
      return URL.createObjectURL(blob)
    }

    const downloadImage = async (coupon: string) => {
      const url = createUrlImage(coupon)
      const link = document.createElement('a')
      link.href = await toDataURL(url)
      link.download = 'cupon.jpg'
      link.click()
    }

    // REFERRAL COUPON SHARE METHODS

    // Message to share
    const shareMessage = `${ t('profile.shareReferralCouponMessage.line1') } ${couponReferral.value.enrollmentDiscount} ${ t('profile.shareReferralCouponMessage.line2') }`

    // Url to share
    const shareLinkReferralCode = async (shareTo: ReferralIcon): Promise<string> => {
      const fullName = `${user.firstName}-${user.lastName}`.toLowerCase()

      let role
      if (user.roles.includes('influencer')) role = 'influencer'
      else if (user.role === 2) role = 'student'
      else if (user.isTutor) role = 'tutor'
      else role = 'teacher'

      const couponId = couponReferral.value._id

      const longUrl = `https://www.coderhouse.com/?referral-cpn=${couponId}&utm_source=referidos&utm_medium=plataforma&utm_campaign=${role}&utm_term=${fullName}&utm_content=${shareTo}`
      return await getShortenUrlInformation(longUrl)
    }

    const shareCoupon = async (shareTo: 'whatsapp' | 'linkedin') => {
      const shareUrl = mediaLinksToShare.value[shareTo]
      const message = `${shareMessage} ${shareUrl}`

      const isSharingAvailable = await putShareCoupon(userId, couponReferral.value._id, shareTo)
      if (!isSharingAvailable.success) {
        toast.add({
          severity: copies.referralDialog.toast.shareReferralCouponError.severity.error as ToastMessageOptions['severity'],
          detail: t('profile.referralDialog.shareReferralCouponError.detail'),
          group: copies.referralDialog.toast.shareReferralCouponError.group,
          life: copies.referralDialog.toast.shareReferralCouponError.life
        })
        return
      }

      if (shareTo === 'whatsapp') {
        window.open(`https://web.whatsapp.com/send?text=${message}`)
      } else if (shareTo === 'linkedin') {
        window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}/&title=Coderhouse`)
        // Currently not displaying description when sharing, if want to implement check out how meta tags are used for this on Linkedin
      }
    }

    const referralOptions = ref<referralOption[]>([
      {
        name: 'copy',
        action: () => {
          navigator.clipboard.writeText(couponReferral.value.code)
          const copyToast = copies.referralDialog.toast.copyClipboard 

          toast.add({
            severity: copyToast.severity.success as ToastMessageOptions['severity'],
            detail: t('profile.referralDialog.copyClipboard.success'),
            group: copyToast.group,
            life: copyToast.life
          })
        }
      },
      {
        name: 'download',
        action: () => {
          downloadImage(couponReferral.value.code)
        }
      },
      {
        name: 'whatsapp',
        action: () => {
          if (linksLoading.value) return
          shareCoupon('whatsapp')
        }
      },
      {
        name: 'linkedin',
        action: () => {
          if (linksLoading.value) return
          shareCoupon('linkedin')
        }
      }
    ])

    const setCoursesData = async (courses: Course[]): Promise<CourseData[]> => {
      const parsedCourse: CourseData[] = []

      for (const course of courses) {
        const startDate = typeof course.startDate === 'string' ? parseToMillis(course.startDate) : course.startDate
        const endDate = typeof course.endDate === 'string' ? parseToMillis(course.endDate) : course.endDate

        const data = {
          name: course.name,
          id: course.id,
          camadaNro: course.camadaNro,
          isStarted: course.isStarted,
          isInProgress: course.isInProgress,
          isEnded: course.isEnded,
          inProcess: false,
          startDate: startDate,
          endDate: endDate
        }

        // only check status of process if the user is visiting it's own profile
        if (!props.isVisitorView) {
          try {
            const requests = await getRequests(user.id, course.id)

            if (requests.length) {
              const currentRequest = requests.find((item) => {
                if (['process', 'processs'].includes(item.status)) {
                  return ['reenroll', 'unenroll', 'change', 'transfer'].includes(item.type)
                } else {
                  return false
                }
              })
              if (currentRequest?._id) {
                data.inProcess = true
              }
            }
          } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e, 'error while trying to retrieve requests data')
            throw e
          }
        }
        parsedCourse.push(data)
      }

      return parsedCourse
    }

    const setCourseDescription = (course: CourseData): string => {
      if (course.isEnded) {
        return `${t('profile.courseList.status.finished')} | ${getMonthName(course.startDate)} ${getYear(
          course.startDate
        )} - ${getMonthName(course.endDate)} ${getYear(course.endDate)}`
      } else if (!course.isStarted) {
        return `${t('profile.courseList.status.notStarted')} | ${t('profile.courseList.comision')} ${course.camadaNro}`
      } else {
        const status =
          userRole.value === 2 ? `${t('profile.courseList.status.inProgress')}` : `${t('profile.courseList.status.active')}`
        return `${status} | ${t('profile.courseList.comision')} ${course.camadaNro}`
      }
    }

    const handleMounted = async () => {
      const userID = props.isVisitorView ? props.userIdToVisit : userId
      try {
        if (props.isVisitorView && props.userIdToVisit) {
          const user = await getUserById(props.userIdToVisit)
          userRole.value = user.role
        }
        const response: Course[] = await getCourses(userID, userRole.value)
        courses.value = await setCoursesData(response)

        if (user.role === 2 && !props.isVisitorView) {
          const courseInProgress = courses.value.find((course) => course.isInProgress)
          if (courseInProgress) {
            const perkResponse = await getPerk(userID, courseInProgress.id)
            if (perkResponse.courseTitle && perkResponse.link) {
              perk.discount = perkResponse.discount
              perk.link = perkResponse.link
              perk.courseTitle = perkResponse.courseTitle
            }
          }
        }

        for (let i = 0; i < response.length; i++) {
          const options: Array<{ label: string; command?: () => void }> = [
            {
              label: `${t('profile.courseList.itemsLabel.requestEnrollCert')}`,
              command: async () => {
                toast.add({
                  severity: 'info',
                  detail: `${t('profile.courseList.itemsLabel.enrollCertInProgress')}`,
                  group: 'bc',
                  life: 4000
                })

                try {
                  trackEvent(ProfileEvents.REQUEST_ONGOING_CERTIFICATE)
                  // TODO: MODAL TO ENTER NAME, DNI AND LAST NAME
                  // TODO: OPTIONAL VALIDATION TO SEE IF USER HAS BECA SUSPENDED TO DOWNLOAD CERTIFICATE
                  await getEnrollCertificate(response[i].id, userId, response[i].name)
                } catch {
                  toast.add({
                    severity: 'error',
                    detail: `${t('profile.courseList.itemsLabel.enrollCertError')}`,
                    group: 'bc',
                    life: 4000
                  })
                }
              }
            }
          ]

          if (user.role === 2) {
            options.unshift({
              label: t('profile.studentChangeCourse.title'),
              command: () => {
                trackEvent(ProfileEvents.REQUEST_CHANGE_COMMISSION_COURSE)
                router.push(`/profile/change-course/${response[i].camadaNro}`)
              }
            })
            options.push({
              label: t('profile.courseList.itemsLabel.unsubscribe'),
              command: () => {
                trackEvent(ProfileEvents.REQUEST_UNSUBSCRIBE_COURSE)
                emit('onUnsubscribe', { camadaNro: response[i].camadaNro, courseId: response[i].id, courseName: response[i].name })
              }
            })
            options.push({
              label: t('profile.courseList.itemsLabel.transferCourse'),
              command: () => {
                trackEvent(ProfileEvents.REQUEST_TRANSFER_COURSE)
                router.push(`/profile/transfer-course/${response[i].camadaNro}`)
              }
            })
            options.push({
              label: t('profile.courseList.itemsLabel.referUser'),
              command: async () => {
                displayReferral.value = true
                trackEvent(ProfileEvents.OPENED_REFERRAL_CODE)
              }
            })
          }

          if (user.role !== 2) {
            options.unshift({
              label: `${t('profile.courseList.itemsLabel.requestReScheduleClass')}`,
              command: async () => {
                try {
                  await requestReSchedule(response[i].id, userId, 'From new platform: Se solicita una re agendar una clase')
                  trackEvent(ProfileEvents.RE_SCHEDULE_CLASS)
                  toast.add({
                    severity: 'info',
                    detail: `${t('profile.courseList.itemsLabel.requestOk')}`,
                    group: 'bc',
                    life: 4000
                  })
                } catch {
                  toast.add({
                    severity: 'info',
                    detail: `${t('profile.courseList.itemsLabel.requestError')}`,
                    group: 'bc',
                    life: 4000
                  })
                }
              }
            })

            options.unshift({
              label: `${t('profile.courseList.itemsLabel.requestAditionalClass')}`,
              command: async () => {
                try {
                  await requestAditionalSchedule(response[i].id, userId, 'From new platform: Se solicita una clase adicional')

                  trackEvent(ProfileEvents.REQUEST_ADDITIONAL_CLASS)
                  toast.add({
                    severity: 'info',
                    detail: `${t('profile.courseList.itemsLabel.requestOk')}`,
                    group: 'bc',
                    life: 4000
                  })
                } catch {
                  toast.add({
                    severity: 'info',
                    detail: `${t('profile.courseList.itemsLabel.requestError')}`,
                    group: 'bc',
                    life: 4000
                  })
                }
              }
            })

            options.unshift({
              label: `${t('profile.courseList.itemsLabel.zoom')}`,
              command: async () => {
                try {
                  const credentials = await getZoomCredentials(response[i].id)
                  zoomCredentialsDialog.value = { user: credentials.email, password: credentials.password, isOpen: true }

                  trackEvent(ProfileEvents.REQUEST_ZOOM_CREDENTIALS)
                } catch {
                  toast.add({
                    severity: 'error',
                    detail: `${t('profile.courseList.itemsLabel.enrollCertError')}`,
                    group: 'bc',
                    life: 4000
                  })
                }
              }
            })
          }

          optionsCourse.value.push(options)
        }
      } catch {
        errorTraining.value = true
      } finally {
        isLoading.value = false
      }
    }

    onMounted(handleMounted)

    // Referral coupon information
    onMounted(async () => {
      couponReferral.value = await getReferralCouponInformation(userId)
      couponImgUrl.value = createUrlImage(couponReferral.value.code)

      // Initialize the referral links
      mediaLinksToShare.value.linkedin = await shareLinkReferralCode('linkedin')
      mediaLinksToShare.value.whatsapp = await shareLinkReferralCode('whatsapp')
      linksLoading.value = false
    })

    return {
      copies,
      errorTraining,
      courses,
      optionsCourse,
      zoomCredentialsDialog,
      handleCloseDialog,
      displayReferral,
      handleCloseReferral,
      couponReferral,
      referralOptions,
      couponImgUrl,
      linksLoading,
      isLoading,
      setCourseDescription,
      role: user.role,
      perk,
      t,
      responsiveOptions
    }
  }
})
