<template>
  <g clip-path="url(#clip0)">
  <path d="M17.5682 14.4737L21.4452 17.2027C21.5594 17.2535 21.6844 17.2749 21.809 17.2651C21.9336 17.2552 22.0537 17.2144 22.1585 17.1463C22.2633 17.0782 22.3494 16.985 22.409 16.8752C22.4686 16.7654 22.4999 16.6424 22.4999 16.5174V7.4818C22.4999 7.35684 22.4686 7.23385 22.409 7.12402C22.3494 7.0142 22.2633 6.921 22.1585 6.85292C22.0537 6.78483 21.9336 6.744 21.809 6.73415C21.6844 6.72429 21.5594 6.74572 21.4452 6.79649L17.5682 9.52555C17.4698 9.59476 17.3896 9.68658 17.3342 9.79329C17.2788 9.89999 17.2499 10.0185 17.2499 10.1387V13.8606C17.2499 13.9808 17.2788 14.0992 17.3342 14.2059C17.3896 14.3126 17.4698 14.4045 17.5682 14.4737Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12.5625 17.9982H3.9375C3.2916 17.9964 2.67269 17.739 2.21598 17.2823C1.75926 16.8255 1.50186 16.2066 1.5 15.5607V8.43573C1.50186 7.78983 1.75926 7.17092 2.21598 6.71421C2.67269 6.25749 3.2916 6.00009 3.9375 5.99823H12.585C13.2249 6.00021 13.838 6.25528 14.2905 6.70775C14.7429 7.16022 14.998 7.77334 15 8.41323V15.5607C14.9981 16.2066 14.7407 16.8255 14.284 17.2823C13.8273 17.739 13.2084 17.9964 12.5625 17.9982Z" stroke-width="2" stroke-miterlimit="10"/>
  </g>
  <defs>
  <clipPath id="clip0">
  <rect width="24" height="24" fill="white"/>
  </clipPath>
  </defs>
</template>
