import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-pt-1 p-pb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChallengeStatement = _resolveComponent("ChallengeStatement")!
  const _component_UploadSection = _resolveComponent("UploadSection")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h5", null, _toDisplayString(_ctx.t('homework.challenge.upload.title')), 1)
    ]),
    _createVNode(_component_ChallengeStatement, { slide: _ctx.slide }, null, 8, ["slide"]),
    _createVNode(_component_UploadSection, {
      onSend: _ctx.send,
      onCancel: _ctx.cancel,
      sending: _ctx.sending,
      showCriteria: _ctx.showCriteria,
      setShowCriteria: _ctx.setShowCriteria,
      mode: _ctx.mode
    }, null, 8, ["onSend", "onCancel", "sending", "showCriteria", "setShowCriteria", "mode"])
  ]))
}