
// Vendor
import { defineComponent, PropType } from 'vue'

// Components
import Card from '@/components/aero/surfaces/Card.vue'
import ProgressCircle from '@/components/aero/misc/ProgressCircle.vue'

// Locales
import { pointsCard as pointsCardCopies } from '@/locales/attendance/es.json'

// Model
import { PointCardAttendance } from '@/models/comission/comission'

export default defineComponent({
  components: { Card, ProgressCircle },
  props: {
    attendancePercentage: {
      type: Number, // 0-100
      required: true
    },
    attendance: {
      type: Array as PropType<PointCardAttendance[]>,
      required: true
    },
    isTutor: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const copies = props.isTutor ? pointsCardCopies.tutor : pointsCardCopies.teacher

    return { copies }
  }
})
