
// Vendor
import { defineComponent, ref, reactive, onMounted } from 'vue'

// copies
import copies from '@/locales/commission/es.json'

// Stores
import { themeStore } from '@/store/modules/themes.store'
import { useUserSessionStore } from '@/store/modules/user-session.store'
import { useUserStore } from '@/store/modules/user.store'
import { useCourseStore } from '@/store/modules/course.store'

// Services
import { getTeachersWithStudents } from '@/services/newPlatform/commission.services'
import { getCourse } from '@/api/newPlatform/course.api'
import { getHolidays } from '@/services/classes.services'

// Layout
import TwoColsLayout from '@/components/aero/layout/TwoColsLayout.vue'

// Components
import ListProfileGroup from '@/components/aero/surfaces/commission/ListProfileGroup.vue'
import PlaceholderState from '@/components/aero/surfaces/challenges/PlaceholderState.vue'
import SkeletonCourseInfoCard from '@/components/aero/skeleton/SkeletonCourseInfoCard.vue'
import SkeletonTeacherGroup from '@/components/aero/skeleton/SkeletonTeacherGroup.vue'
import SkeletonListProfileGroup from '@/components/aero/skeleton/SkeletonListProfileGroup.vue'
import ItemsCard from '@/components/aero/surfaces/ItemsCard.vue'
import ContactCard from '@/components/aero/dataDisplay/ContactCard.vue'
import Card from '@/components/aero/surfaces/Card.vue'
import ErrorPage from '@/components/aero/dataDisplay/ErrorPage.vue'
import SearchInput from '@/components/aero/inputs/SearchInput.vue'
import ProfileCardSmall from '@/components/aero/dataDisplay/ProfileCardSmall.vue'
import ToTopButton from '@/components/aero/buttons/ToTopButton.vue'

// Models
import { ParsedClassmate, TutorClassmate, StudentClassmate } from '@/models/comission/comission'

export default defineComponent({
  components: {
    TwoColsLayout,
    ItemsCard,
    ListProfileGroup,
    ContactCard,
    Card,
    ErrorPage,
    SkeletonCourseInfoCard,
    SkeletonTeacherGroup,
    SkeletonListProfileGroup,
    PlaceholderState,
    SearchInput,
    ProfileCardSmall,
    ToTopButton
  },

  setup() {
    const theme = themeStore()
    theme.changeDefault('new-theme')

    const session = useUserSessionStore()
    const user = useUserStore()
    const course = useCourseStore()

    const courseId = course.id

    // Refs
    const loading = ref<boolean>(false)
    const isError = ref<boolean>(false)

    const classmates = ref<ParsedClassmate[]>([])
    const teacherGroup = ref<TutorClassmate[]>([])
    const filteredClassmates = ref<(TutorClassmate | StudentClassmate)[]>([])
    const isFilterActive = ref(false)

    // current course state (aside data)
    const currentCourse = reactive({
      _id: '',
      category: '',
      courseName: '',
      courseNameShort: '',
      camadaNro: '',
      daysAndHours: '',
      startDate: '',
      endDate: '',
      holidays: [] as string[]
    })

    const getCurrentCourseData = async () => {
      try {
        const response = await getCourse(user.id, course.camadaNro.toString())
        const holidays = await getHolidays(course.id, response.course.endDate)

        currentCourse.courseName = response.course.level.title
        currentCourse.courseNameShort = response.course.level.titleShortAlias
        currentCourse.daysAndHours = response.course.daysAndHours
        currentCourse.startDate = response.course.startDate
        currentCourse.endDate = response.course.endDate
        currentCourse.category = response.course.level.categories[0]
        currentCourse.camadaNro = response.id.toString()
        currentCourse.holidays = holidays
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('error while trying to retrieve data from the current course')
        throw e
      }
    }

    const handleMountedComponent = async () => {
      loading.value = true

      try {
        await getCurrentCourseData()
        const [tutorWithStudent, teachers] = await getTeachersWithStudents(courseId, session.userId, currentCourse.courseNameShort)

        classmates.value = tutorWithStudent
        teacherGroup.value = teachers

        loading.value = false
      } catch (e) {
        isError.value = true
      } finally {
        loading.value = false
      }
    }

    const normalizeName = (name: string) => {
      return name
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
    }

    const searchTutorOrStudent = (value: string) => {
      isFilterActive.value = value !== ''
      // filter classmate.students comparing it with value, without taking into account accents or case
      const studentsFiltered: StudentClassmate[] = []
      classmates.value.forEach(({ students }) => {
        students.forEach((student) => {
          const studentName = normalizeName(student.fullName)
          const searchedName = normalizeName(value)

          if (studentName.includes(searchedName)) studentsFiltered.push(student)
        })
      })

      const teachersFiltered = teacherGroup.value.filter((teacher) => {
        const teacherName = normalizeName(teacher.fullName)
        const searchedName = normalizeName(value)

        return teacherName.includes(searchedName)
      })

      filteredClassmates.value = [...studentsFiltered, ...teachersFiltered]
    }

    onMounted(handleMountedComponent)

    return {
      user,
      loading,
      isError,
      copies,
      classmates,
      currentCourse,
      teacherGroup,
      searchTutorOrStudent,
      filteredClassmates,
      isFilterActive
    }
  }
})
