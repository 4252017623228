import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_item_information_large = _resolveComponent("item-information-large")!
  const _component_card = _resolveComponent("card")!

  return (_openBlock(), _createBlock(_component_card, { bgDark: "" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createBlock(_component_item_information_large, {
          key: item._id,
          item: item,
          isEducation: _ctx.isEducation,
          onOnEdit: _ctx.handleEdit,
          onOnDelete: _ctx.handleDelete,
          isVisitorView: _ctx.isVisitorView
        }, null, 8, ["item", "isEducation", "onOnEdit", "onOnDelete", "isVisitorView"]))
      }), 128))
    ]),
    _: 1
  }))
}