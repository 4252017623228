
import { defineComponent, PropType, computed } from 'vue'
import AttendanceTable from '@/components/aero/dataDisplay/AttendanceTable.vue'

// Models
import { ColumnType, ISchedule, User, UserAttendance } from '@/models/attendance/attendance'
import { ParsedClassmate } from '@/models/comission/comission'

export default defineComponent({
  props: {
    userId: { type: String, required: true },
    students: { type: Array as PropType<User[]>, required: true },
    classmates: { type: Array as PropType<ParsedClassmate[]>, required: true },
    columns: { type: Array as PropType<ColumnType[]>, required: true },
    schedules: { type: Array as PropType<ISchedule[]>, default: () => [] },
    filterTitle: { type: String, default: 'student' },
    filterSelected: { type: String, default: 'students' }
  },
  components: { AttendanceTable },
  emits: ['onChange'],
  setup(props, { emit }) {
    const setTutorGroup = () => {
      const parsedStudents = props.classmates.map((group) => {
        const tutorData = group.tutor
        const studentAtt = group.students.reduce((result: UserAttendance[], student) => {
          const studentTemp = props.students.find((stu) => stu._id === student._id)

          if (studentTemp) {
            const attendance = studentTemp.schedules.reduce((acc, { _id, attendend }) => {
              return { ...acc, [_id]: !!attendend }
            }, {})

            const flagDate = studentTemp.schedules.reduce((acc, { _id, _flags }) => {
              return { ...acc, [_id]: _flags.isNext || _flags.isTomorrow || _flags.isIncoming }
            }, {})
            const data = {
              id: studentTemp._id,
              name: studentTemp.fullName,
              avatar: studentTemp.avatar,
              beca: studentTemp.flags.beca,
              inactive: studentTemp.flags.inactiveStudent,
              attendance,
              flagDate,
              schedules: studentTemp.schedules
            }

            result.push(data)
          }
          return result
        }, [])

        return { tutor: tutorData, students: studentAtt }
      })

      const myGroup = parsedStudents.find((group) => group.tutor._id === props.userId)

      if (myGroup) {
        const index = parsedStudents.indexOf(myGroup)
        const groupData = parsedStudents.splice(index, 1)

        parsedStudents.splice(0, 0, groupData[0])
      }

      return parsedStudents
    }

    const setTableData = computed(() => {
      const parsedStudents = setTutorGroup()
      const group = parsedStudents.map((group) => {
        const attendance = props.schedules.map(
          (sche) => group.students.filter((stu) => stu.schedules.find((s) => s._id === sche._id && !!s.attendend)).length
        )
        return {
          attendance,
          users: group.students,
          tutor: group.tutor
        }
      })

      return group
    })

    const handleToggleAttendance = (value: { [x: string]: boolean }, scheduleId: string) => {
      emit('onChange', value, scheduleId)
    }

    return { setTableData, handleToggleAttendance }
  }
})
