
// Vendor
import { defineComponent } from 'vue'

// Components
import SkeletonPostConversation from './SkeletonPostConversation.vue'
import SkeletonText from './SkeletonText.vue'

export default defineComponent({
  components: {
    SkeletonPostConversation,
    SkeletonText
  }
})
