
import InputEditor from '@/components/commons/InputEditor.vue'
import InputFile from '@/components/commons/InputFile.vue'
import InputURL from '@/components/commons/InputURL.vue'
import { IChallengeDataDto } from '@/models/peer-review/challenge'
import { REGEX } from '@/models/enums'
import { useVuelidate, ValidationArgs, ValidationRule } from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import { defineComponent, reactive, ref, onBeforeUpdate } from 'vue'
import { useI18n } from 'vue-i18n'
import ChallengeCriteria from './ChallengeCriteria.vue'
import { useToast } from 'primevue/usetoast'

export default defineComponent({
  emits: ['send', 'cancel'],
  props: {
    sending: Boolean,
    showCriteria: Boolean,
    setShowCriteria: Function,
    sendLabelKey: {
      type: String,
      default: 'homework.challenge.upload.send.label'
    },
    mode: {
      type: String,
      default: 'deliver'
    }
  },
  components: {
    ChallengeCriteria,
    InputFile,
    InputURL,
    InputEditor
  },
  setup(props, { emit }) {
    const isEnabled = ref<boolean>(true)
    const { t } = useI18n()
    const toast = useToast()
    const commentWithoutQuill = ref<string>('')
    const state = reactive({
      link: '',
      comment: '',
      criteria: [
        {
          title: t('homework.challenge.criteria.step1.title'),
          description: t('homework.challenge.criteria.step1.description')
        },
        {
          title: t('homework.challenge.criteria.step2.title'),
          description: t('homework.challenge.criteria.step2.description')
        },
        {
          title: t('homework.challenge.criteria.step3.title'),
          description: t('homework.challenge.criteria.step3.description')
        }
      ]
    })
    const file = ref<File>()
    const sent = ref<boolean>(false)
    const url: ValidationRule = {
      $validator: (value: unknown) => {
        if (!file.value) {
          return REGEX.URL.test(value as string)
        } else {
          return true
        }
      }
    }
    const customReq: ValidationRule = {
      $validator: (value: unknown) => {
        if ((!value || value === '') && !file.value) {
          return false
        } else {
          return true
        }
      }
    }
    const rules: ValidationArgs = {
      link: { customReq, url },
      comment: { required, minLength: minLength(10) }
    }
    const v$ = useVuelidate(rules, state)
    const select = (selected: File) => {
      file.value = selected
      if (!file.value) return
      toast.add({ severity: 'success', detail: t('homework.challenge.upload.file.success'), life: 3000 })
    }
    const remove = () => {
      file.value = undefined
    }
    const send = async () => {
      sent.value = true
      await v$.value.$validate()
      if (v$.value.$invalid) return toast.add({ severity: 'error', detail: t('homework.inputeditor.empty.field'), life: 3000 })
      const body: IChallengeDataDto = {
        link: state.link,
        comment: state.comment,
        file: file.value
      }
      emit('send', body)
    }
    const cancel = () => {
      emit('cancel')
    }

    onBeforeUpdate(() => {
      if(commentWithoutQuill.value.length > 10 && (state.link || file.value)) {
        isEnabled.value = false
      } else {
        isEnabled.value = true
      }
    })

    const getComment = (msg:string) => (commentWithoutQuill.value = msg) 
    return {
      t,
      send,
      cancel,
      select,
      remove,
      getComment,
      sent,
      v$,
      state,
      isEnabled
    }
  }
})
