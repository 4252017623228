
// Vendor
import { defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'

// Components
import SmallCardOpinion from '@/components/aero/surfaces/ratings/SmallCardOpinion.vue'

// Models
import { Tag } from '@/models/ratings/ratings'

export default defineComponent({
  components: {
    SmallCardOpinion
  },
  props: {
    feedbackType: { type: String as PropType<'positive' | 'suggestion'>, required: true },
    tags: { type: Array as PropType<Tag[]> },
    tagTotals: { type: Number, required: true },
    totals: { type: Number, required: true }
  },
  setup() {
    const { t } = useI18n()
    return {
      t
    }
  }
})
