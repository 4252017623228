import { usePersonStore } from '@/store/modules/person.store'
import { PersonRoles } from '@/models/enums'

const getRole = () => {
  const person = usePersonStore()
  if(person.profile?.roles.includes(PersonRoles.Tutor)) {
    return PersonRoles.Tutor
  } else if(person.profile?.roles.includes(PersonRoles.Teacher)) {
    return PersonRoles.Teacher
  } else {
    return PersonRoles.Student
  }
}

export {
  getRole
}