<template>
  <g>
    <path
      d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M8 5V8.5" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M8 11.375C8.34518 11.375 8.625 11.0952 8.625 10.75C8.625 10.4048 8.34518 10.125 8 10.125C7.65482 10.125 7.375 10.4048 7.375 10.75C7.375 11.0952 7.65482 11.375 8 11.375Z"
      fill="#D2A7F1"
    />
  </g>
</template>