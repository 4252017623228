
// Vendor
import { defineComponent, ref, onMounted, watch, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
// import { DateTime } from 'luxon'

// Store
import { themeStore } from '@/store/modules/themes.store'
import { useCourseStore } from '@/store/modules/course.store'
import { useUserSessionStore } from '@/store/modules/user-session.store'
import { useUserStore } from '@/store/modules/user.store'

// Components
import TwoColsLayout from '@/components/aero/layout/TwoColsLayout.vue'
import Card from '@/components/aero/surfaces/Card.vue'
import RatingCard from '@/components/aero/surfaces/RatingCard.vue'
import RatingChart from '@/components/aero/dataDisplay/RatingChart.vue'
import MostVotedTag from '@/views/ratings/components/MostVotedTag.vue'
import Commentaries from './components/Commentaries.vue'
import OpinionTags from './components/OpinionTags.vue'
import SelectInput from '@/components/aero/inputs/SelectInput.vue'
import ErrorPage from '@/components/aero/dataDisplay/ErrorPage.vue'
import PlaceholderState from '@/components/aero/surfaces/challenges/PlaceholderState.vue'
import SkeletonComments from '@/components/aero/skeleton/SkeletonComments.vue'
import SkeletonOpinionTag from '@/components/aero/skeleton/SkeletonOpinionTag.vue'
import SkeletonRatingCard from '@/components/aero/skeleton/SkeletonRatingCard.vue'
import SkeletonDropdownRatings from '@/components/aero/skeleton/SkeletonDropdownRatings.vue'
import UpToDatePlaceHolder from '@/components/aero/surfaces/challenges/UpToDatePlaceHolder.vue'
import SkeletonPointsCard from '@/components/aero/skeleton/SkeletonPointsCard.vue'

// Api
import { getClasses, getComments, getTags, getRating } from '@/api/newPlatform/rating.api'
import { getCourses } from '@/api/newPlatform/navigation.api'

// Models
import { Classes, Comment, TagData, RatingScore, TagTypesEnum, Rating } from '@/models/ratings/ratings'
import { Course } from '@/models/navigation/course'

export default defineComponent({
  components: {
    TwoColsLayout,
    Card,
    RatingCard,
    RatingChart,
    MostVotedTag,
    Commentaries,
    OpinionTags,
    SelectInput,
    ErrorPage,
    PlaceholderState,
    SkeletonComments,
    SkeletonOpinionTag,
    SkeletonRatingCard,
    UpToDatePlaceHolder,
    SkeletonDropdownRatings,
    SkeletonPointsCard
  },
  setup() {
    themeStore().changeDefault('new-theme')
    const { t } = useI18n()
    
    const courseFromStore = useCourseStore()
    const session = useUserSessionStore()
    const user = useUserStore()

    const route = useRoute()
    const { classId } = route.params

    const loading = ref()
    const isError = ref(false)

    const currRateOption = ref('')
    const rateOptions = ref<{ label: string; value: string }[]>([])
    const setRateOption = (value: string) => (currRateOption.value = value)

    const rating = ref<RatingScore | null>()
    const comments = ref<Comment[]>([])
    const tags = ref<TagData[]>([])
    const tagPositive = ref<TagData>()
    const tagNegative = ref<TagData>()
    const tagNeutral = ref<TagData>()

    const hasCourseStarted = ref(true)

    // Get ratings
    onMounted(async () => {
      try {
        loading.value = true
        await setCourseInStore()

        const classes: Classes[] = await getClasses(courseFromStore.camadaNro)

        // Set filter ratings
        const allClassesOption = {
          label: t('rating.allClasses'),
          value: ''
        }

        const classesOption = classes.map(({ id, name }) => ({
          label: name,
          value: id
        }))

        rateOptions.value = [allClassesOption, ...classesOption]

        let isValidClassId
        if (classId) isValidClassId = classesOption.some(({ value }) => value === classId)

        if (isValidClassId) {
          currRateOption.value = classId.toString()
        } else {
          currRateOption.value = allClassesOption.value
        }

        fetchData(currRateOption.value)
      } catch (err) {
        isError.value = true
        // eslint-disable-next-line no-console
        console.error(err)
      } finally {
        loading.value = false
      }
    })

    watch(currRateOption, (newVal: string) => {
      fetchData(newVal)
    })

    const fetchData = async (classId: string) => {
      try {
        loading.value = true
        rating.value = await getRating(courseFromStore.camadaId, classId)
        comments.value = await getComments(courseFromStore.camadaId, classId)
        tags.value = await getTags(courseFromStore.camadaId, classId)
        tagPositive.value = tags.value?.find((tag) => tag.rating === TagTypesEnum.POSITIVE)
        tagNeutral.value = tags.value?.find((tag) => tag.rating === TagTypesEnum.NEUTRAL)
        tagNegative.value = tags.value?.find((tag) => tag.rating === TagTypesEnum.NEGATIVE)
        loading.value = false
      } catch (err) {
        isError.value = true
        loading.value = false
        // eslint-disable-next-line no-console
        console.error(err)
      }
    }

    const ratingPercentage = (type: TagTypesEnum) => {
      const ratingByType = rating.value?.ratings.find((r: Rating) => r.rating === type)
      return ratingByType?.percent || 0
    }

    const tagsTotal = computed(() => {
      return tags.value.reduce((accumulator, tag) => {
        return accumulator + tag.total
      }, 0)
    })

    const setCourseInStore = async() => {
      const getCurrentCamada = JSON.parse(localStorage.getItem('currentCamada') as string)
      const course: Course[] = await getCourses(session.userId, user.role)
      const getCurrentCourse = course.find(course => course.camadaNro === Number(getCurrentCamada))

        if(!course.length) {
          loading.value = false
          return
        }

        if(getCurrentCourse) {
          courseFromStore.setCourse({
            id: getCurrentCourse.id,
            camadaNro: getCurrentCourse.camadaNro.toString(),
            camadaId: getCurrentCourse.camadaId,
            name: getCurrentCourse.name,
            sharedFolderLink: getCurrentCourse.sharedFolderLink,
            teachersSharedFolderLink: getCurrentCourse.teachersSharedFolderLink,
            isEnded: getCurrentCourse.isEnded,
            isStarted: getCurrentCourse.isStarted,
            isInProgress: getCurrentCourse.isInProgress,
            hasCoderAsk: getCurrentCourse.flags.hasCoderAsk,
            hasCoderAskActive: getCurrentCourse.flags.hasCoderAskActive
          })
        }
    }

    return {
      t,
      loading,
      rateOptions,
      currRateOption,
      setRateOption,
      isError,
      hasCourseStarted,
      rating,
      comments,
      tags,
      tagPositive,
      tagNegative,
      tagNeutral,
      tagsTotal,
      ratingPercentage
    }
  }
})
