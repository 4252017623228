
// Vendor
import { defineComponent, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

// Components
import HeaderComponent from './Header.vue'
import SideBar from './Sidebar.vue'

export default defineComponent({
  components: { HeaderComponent, SideBar },
  setup() {
    const route = useRoute()
    const showNavigation = ref<boolean>(false)

    // For hide navigation in some routes, for example: /login
    const hideNavigationRoutes = ['Login']
    watch(route, () => {
      showNavigation.value = process.env.NAVIGATION === '1' && !hideNavigationRoutes.some((r) => route.name === r)
    })

    const isOpenMenu = ref<boolean>(false)

    const handleOpenMenu = () => {
      isOpenMenu.value = true
    }

    const handleCloseMenu = () => {
      isOpenMenu.value = false
    }

    return { handleOpenMenu, handleCloseMenu, isOpenMenu, showNavigation }
  }
})
