import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createBlock(_component_Button, {
    label: _ctx.label,
    class: _normalizeClass(`${
      _ctx.label ? 'p-button-lg p-button-outlined' : 'p-button-lg p-button-icon-only p-button-rounded p-button-secondary p-button-text'
    }`),
    icon: "pi pi-download",
    onClick: _ctx.handleClick
  }, null, 8, ["label", "class", "onClick"]))
}