import {
  getCountries,
  getSkills,
  getEducation,
  getWorkExperience,
  setEducation,
  setWorkExperience,
  updateEducation,
  deleteEducation,
  updateWorkExperience,
  deleteWorkExperience,
  changePassword,
  changePasswordAuth0,
  confirmPasswordChange,
  updateProfile,
  updateSocialNetworks,
  getSocialNetworks,
  getPayouts,
  getInvoice,
  uploadAFIPConstancy as uploadAFIPConstancyFromAPI,
  updateBillingData as updateBillingDataFromAPI,
  getInvoiceInfo as getInvoiceInfoFromAPI,
  getPayments as getPaymentsFromAPI,
  uploadInvoice as uploadInvoiceFromAPI,
  uploadBill as uploadBillFromAPI,
  getEnrollCertificate as getEnrollCertificateFromAPI,
  requestAditionalSchedule as requestAditionalScheduleFromAPI,
  requestReSchedule as requestReScheduleFromAPI,
  getZoomCredentials as getZoomCredentialsFromAPI,
  getReferralCoupon,
  getShortenUrl,
  shareCoupon,
  uploadProfileImage,
  updateUserAvatar,
  setSkills,
  getSkillsUser,
  deleteSkill,
  getExpertiseAreas,
  getExpertiseUser,
  setExpertise,
  setFlagPreferences,
  getLanguagesUser,
  getLanguages,
  getLanguageLevel,
  setLanguages,
  deleteLanguages,
  getUserWorkModalities,
  getUserIndustriesPreference,
  setUserWorkModalities,
  setUserIndustriesPreference,
  uploadCurriculum as uploadCurriculumFromAPI,
  getCurriculumUser,
  setCurriculumUser,
  getProfile,
  updateTalent,
  getSocialAccounts,
  removeSocialAccount
} from '@/api/newPlatform/profile.api'

import {
  getPenalty as getPenaltyFromAPI,
  getRequests as getRequestsFromAPI,
  isRequestReenrollValid as isRequestReenrollValidFromAPI,
  requestReenrollV2 as requestReenrollV2FromAPI,
  discardRequest as discardRequestFromAPI,
  requestUnenroll as requestUnenrollFromAPI
} from '@/api/newPlatform/selfManagement.api'

// Models
import {
  PayoutInvoice,
  UpdateBillingDataArgs,
  GetProfileInfoArgs,
  SocialNetwork,
  UpdateOnboardingArgs,
  UpdateProfileArgs,
  User,
  UserProfile,
  Skill,
  Language,
  UserWorkPreference,
  CvFile,
  Country
} from '@/models/profile/profile'

import { Invoice } from '@/models/profile/billing'
import { IExperience, IEducation, IWorkExperience, EducationResponse } from '@/models/newPlatform/profile'
import {
  SelfManagementFlags,
  Penalty,
  SelfManagementRequest,
  SelfManagementPayment,
  ReenrollPayload,
  Unenroll
} from '@/models/newPlatform/selfManagement'

// utils
import { saveBlobDocument } from '@/utils/downloads'

// Copies
import copies from '@/locales/profile/es.json'

import { getUser } from '@/api/user'

/**
 * API Service to fetch current logged-in user's information
 * @param param.userId `required` Takes the logged in userId to display its information
 * @param param.lean `optional` defaults to 1
 * @param param.returnMock `optional` flag to indicate if we want to return mock data or API Data
 * @returns User profile information such as bio, email, location, subscription type, main goal, and socialNetworks
 */
export const getProfileInformation: ({
  userId
}: GetProfileInfoArgs) => Promise<Omit<UserProfile, 'socialNetworks'> & { socialNetworks: { [x: string]: string } }> = async ({
  userId
}) => {
    const user = await getProfile({ userId })

    const responseSocialNetworks = await getSocialNetworks({ userId })
    const socialNetworks = responseSocialNetworks.reduce((acc, socialNetwork) => {
      return {
        ...acc,
        [socialNetwork.key]: socialNetwork.link
      }
    }, {} as { [x: string]: string })

    return { ...user, socialNetworks }
  }

export const getCountriesList = (): Promise<{ data: Country[]; msg: string; error: boolean }> => {
  return getCountries()
}

export const getSkillsList = async (): Promise<Skill[]> => {
  return getSkills()
}

export const getSkillsUserList = async (userId: string): Promise<Skill[]> => {
  return getSkillsUser(userId)
}

export const setSkillsUser = async (userId: string, data: Skill[]): Promise<unknown> => {
  try {
    const { success } = await setSkills(userId, data)
    return success
  } catch (error) {
    return false
  }
}

export const getExpertiseList = async (): Promise<UserWorkPreference[]> => {
  return getExpertiseAreas()
}

export const getUserExpertise = async (userId: string): Promise<UserWorkPreference[]> => {
  return getExpertiseUser(userId)
}

export const setExpertiseUser = async (userId: string, expertiseArea: UserWorkPreference[]): Promise<unknown> => {
  try {
    return setExpertise(userId, expertiseArea)
  } catch (error) {
    return false
  }
}

export const setPreferencesFlag = async (userid: string, flag: boolean): Promise<unknown> => {
  try {
    return setFlagPreferences(userid, flag)
  } catch (error) {
    return false
  }
}

export const deleteSkillUser = async (userId: string, skillId: string): Promise<Skill[]> => {
  return deleteSkill(userId, skillId)
}

export const getLanguagesUserList = async (userId: string): Promise<Language[]> => {
  return getLanguagesUser(userId)
}

export const deleteLanguageUser = async (userId: string, languageId: string): Promise<Language[]> => {
  return deleteLanguages(userId, languageId)
}

export const getLanguagesList = async (country: string): Promise<Language[]> => {
  return getLanguages(country)
}

export const getLanguageLevelsList = async (country: string): Promise<any[]> => {
  return getLanguageLevel(country)
}

export const setLanguagesUser = async (userId: string, languages: Language[]): Promise<unknown> => {
  try {
    return setLanguages(userId, languages)
  } catch (error) {
    return false
  }
}

export const getUserModalities = async (userId: string): Promise<UserWorkPreference[]> => {
  return getUserWorkModalities(userId)
}

export const getUserIndustries = async (userId: string): Promise<UserWorkPreference[]> => {
  return getUserIndustriesPreference(userId)
}

export const setUserModalities = async (userId: string, userModalityPreference: UserWorkPreference[]): Promise<unknown> => {
  try {
    return setUserWorkModalities(userId, userModalityPreference)
  } catch (error) {
    return false
  }
}

export const setUserIndustries = async (userId: string, userIndustriesPreference: UserWorkPreference[]): Promise<unknown> => {
  try {
    return setUserIndustriesPreference(userId, userIndustriesPreference)
  } catch (error) {
    return false
  }
}

export const uploadCurriculum = async (userId: string, file: File) => {
  const formData = new window.FormData()
  const body = { userId, file, prefix: file.name }
  Object.entries(body).map(([k, v]) => formData.append(k, v))

  const fileResponse = await uploadCurriculumFromAPI(formData)

  return setCurriculum(userId, { link: fileResponse.files[0].url, size: fileResponse.files[0].size, name: file.name })
}

export const getCurriculum = async (userId: string): Promise<CvFile> => {
  return getCurriculumUser(userId)
}

export const setCurriculum = async (userId: string, cvLink: any): Promise<unknown> => {
  try {
    return setCurriculumUser(userId, cvLink)
  } catch (error) {
    return false
  }
}

/**
 * API Service to request to change password
 * @param param.userId `required` Logged user ID
 * @returns Promise with success boolean
 */
export const changePasswordService = async ({ userEmail }: { userEmail: string }): Promise<boolean> => {
  try {
    const success = await changePasswordAuth0(userEmail)
    return !!success
  } catch (error) {
    return false
  }
}

export const confirmPasswordService = async ({
  userId,
  token,
  password,
  force = false,
  notification = false
}: {
  userId: string
  token: string
  password: string
  force: boolean
  notification: false
}): Promise<boolean> => {
  try {
    const { success } = await confirmPasswordChange({ userId, token, password, force, notification })
    return success
  } catch (error) {
    return false
  }
}

/**
 * API Service to fetch teh enroll certificate after fetch current logged-in user's information
 * @param courseId current course
 * @param userId logged user id
 * @returns pdf
 */
export const getEnrollCertificate = async (courseId: string, userId: string, courseName: string) => {
  const user: User = await getUser(userId)

  const certificate = await getEnrollCertificateFromAPI(courseId, userId, user)

  const blob = new Blob([certificate], { type: 'application/pdf' })
  saveBlobDocument(blob, `${copies.courseList.itemsLabel.cert} ${courseName}.pdf`)
}

/**
 * API Service to request an aditional schedule from some course
 * @param courseId `required` current course
 * @param userId `required` Logged in user ID
 * @param reason `required` why you want to request and aditional schedule
 * @returns confirmation message
 */
export const requestAditionalSchedule = async (courseId: string, userId: string, reason: string) => {
  return await requestAditionalScheduleFromAPI(courseId, userId, reason)
}

/**
 * API Service to request an aditional schedule from some course
 * @param courseId `required` current course
 * @param userId `required` Logged in user ID
 * @param reason `required` why you want to request and aditional schedule
 * @returns confirmation message
 */
export const requestReSchedule = async (courseId: string, userId: string, reason: string) => {
  return await requestReScheduleFromAPI(courseId, userId, reason)
}

export const getZoomCredentials = async (courseId: string) => {
  return await getZoomCredentialsFromAPI(courseId)
}

/**
 * API Service to update profile information
 * @param param.userId `required` Takes the logged in userId to display its information
 * @param param.lean `optional` defaults to 1
 * @param param.returnMock `optional` flag to indicate if we want to return mock data or API Data
 * @returns success boolean
 */
export const updateProfileService = async ({
  userId,
  ...args
}: UpdateProfileArgs & UpdateOnboardingArgs & { socialNetworks: { [x: string]: string } }): Promise<boolean> => {
  // This data is deprecated in coder api, and it is not possible to access it after the update
  // so not has sense make this request
  // const onboardingData: Omit<UpdateOnboardingArgs, 'userId'> = {
  //   studyLevel: args.studyLevel,
  //   whyTakeTheCourse: args.whyTakeTheCourse
  // }

  const profileData: Omit<UpdateProfileArgs, 'userId'> = {
    bio: args.bio,
    showTalent: args.showTalent,
    country: args.country,
    cityV2: args.cityV2,
    bday: args.bday,
    first_name: args.first_name,
    last_name: args.last_name,
    phone: args.phone,
    curp: args.curp
  }

  const socialNetworksData: SocialNetwork[] = Object.entries(args.socialNetworks)
    .map(([key, value]) => ({
      key,
      link: value
    }))
    .filter((socialNetwork) => socialNetwork.link)

  try {
    // await updateOnboarding({ userId, ...onboardingData })

    await updateProfile({ userId, ...profileData })

    await updateSocialNetworks({ userId, socialNetworks: socialNetworksData })

    return true
  } catch (error) {
    return false
  }
}

export const updateTalentService = async (): Promise<boolean> => {
  try {
    await updateTalent()
    return true
  } catch (error) {
    return false
  }
}

/**
 * API Service to get user's billing history information
 * @param userId `required` Logged in user ID
 * @returns Promise containing user's billing history
 * @see [Postman]{@link https://coderplatformers.postman.co/workspace/Coder-House-Platform~9b3cd88b-81e2-4307-8432-95267b456aa7/request/17745020-63ad50fb-6132-4900-a88a-c4533174e9d2 }
 */
export const getPayoutService = async (userId: string) => {
  const response = await getPayouts(userId)

  const invoices = response.map((item) => {
    const listInvoices = item.invoices.flat().map((invoice) => {
      return { ...invoice, payoutId: item._id, date: item.date }
    })

    return listInvoices
  })

  return invoices
}

/**
 * API Service to get certain bill/invoice detailed
 * @param userId `required` Logged in user ID
 * @param payoutId `required` ID from the payout object
 * @param invoiceId `required` invoice ID to request
 * @returns Promise containing a single invoice detailed
 * @see [Postman]{@link https://coderplatformers.postman.co/workspace/Coder-House-Platform~9b3cd88b-81e2-4307-8432-95267b456aa7/request/17745020-042b4d25-65fa-42d8-bf38-da71764d801c}
 */
export const getInvoiceService = async (userId: string, paymentId: string, invoiceId: string): Promise<PayoutInvoice> => {
  return await getInvoice(userId, paymentId, invoiceId)
}

/**
 * API Service to request user's education information
 * @param userId `required` Logged user ID
 * @returns user's education information
 */
export const getEducationInformation = async (userId: string) => {
  const response = await getEducation(userId)
  return response.map((item) => {
    const data: IExperience = {
      title: item.title,
      subtitle: item.institute,
      startDate: item.range.from,
      endDate: item.range.to,
      currently: item.range.currently,
      _id: item._id
    }

    return data
  })
}

/**
 * API Service to create/update education related information
 * @param userId `required` Logged user ID
 * @param education `required`education data to be created/updated
 * title => academic title
 * subtitle => establishment
 * startDate => date when the course/career has started
 * endDate => date when the course/career has finished (if applies)
 * currently => boolean to comunicate if the course/career is currently in progress
 * _id => 'required for update' => id of the education data
 * @returns education data created/updated such as title, institution, start/end dates or if it's still studying
 */
export const setEducationInformation = async (userId: string, education: IExperience): Promise<EducationResponse> => {
  const educationData: IEducation = {
    title: education.title,
    institute: education.subtitle,
    range: {
      from: education.startDate,
      to: education.endDate,
      currently: education.currently
    }
  }

  if (education._id) {
    educationData._id = education._id
    return await updateEducation(userId, educationData)
  } else {
    return await setEducation(userId, educationData)
  }
}

/**
 * API Service to request user's work experience information
 * @param userId `required` Logged user ID
 * @returns user's work experience information
 */
export const getWorkExperienceInformation = async (userId: string) => {
  const response = await getWorkExperience(userId)
  return response.map((item) => {
    const data: IExperience = {
      title: item.title,
      subtitle: item.company,
      startDate: item.range.from,
      endDate: item.range.to,
      currently: item.range.currently,
      _id: item._id
    }

    return data
  })
}

/**
 * API Service to create/update work related information
 * @param userId `required` Logged user ID
 * @param work `required`education data to be created/updated
 * title => job position
 * subtitle => company name
 * startDate => date when the job has started
 * endDate => date when the job has finished (if applies)
 * currently => boolean to comunicate if this is the user's current job
 * _id => 'required for update' => id of the work data
 * @returns work data created/updated such as title, company, start/end dates or if it's still working
 */
export const setWorkExperienceInformation = (userId: string, work: IExperience) => {
  const workData: IWorkExperience = {
    title: work.title,
    company: work.subtitle,
    range: {
      from: work.startDate,
      to: work.endDate,
      currently: work.currently
    }
  }

  if (work._id) {
    workData._id = work._id
    return updateWorkExperience(userId, workData)
  } else {
    return setWorkExperience(userId, workData)
  }
}

/**
 * API Service to update billing information
 * @param userId `required` loged user id
 * @param payload `required` billing data
 * @param file `required` AFIP constancy
 * @param fileName `required` name of the AFIP constancy
 * @returns success boolean
 */
export const updateBillingData = async (
  userId: string,
  payload: UpdateBillingDataArgs,
  file?: File,
  fileName?: string
): Promise<boolean> => {
  if (file && fileName) {
    const formData = new window.FormData()
    const body = { userId, file, prefix: fileName }
    Object.entries(body).map(([k, v]) => formData.append(k, v))

    try {
      const fileResponse = await uploadAFIPConstancyFromAPI(formData)

      const billingData = {
        ...payload,
        profile: {
          ...payload.profile,
          banking: {
            ...payload.profile.banking,
            afipConstancy: fileResponse.files[0].url
          }
        }
      }

      await updateBillingDataFromAPI({ ...billingData })

      return true
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('SERVICE: update billing information', error)
      return false
    }
  } else {
    try {
      await updateBillingDataFromAPI({ ...payload })

      return true
    } catch (error) {
      return false
    }
  }
}

/**
 * API Service to request the delete of certain user's education information
 * @param userId `required` Logged user ID
 * @param educationId 'required' education's id object the user wants to delete
 * @returns success boolean
 */

export const deleteEducationInformation = async (userId: string, educationId: string) => {
  const response = await deleteEducation(userId, educationId)
  return response
}

/**
 * API Service to request the delete of certain user's work experience information
 * @param userId `required` Logged user ID
 * @param workId 'required' work's id object the user wants to delete
 * @returns success boolean
 */
export const deleteWorkExperienceInformation = async (userId: string, workId: string) => {
  const response = await deleteWorkExperience(userId, workId)
  return response
}

/**
 * API Call to get user's referral coupon
 * @param userId `required` Logged user ID
 * @return promise containing the user referral _id, code and enrollment discount
 **/

export const getReferralCouponInformation = async (userId: string) => {
  const { _id, code, enrollmentDiscount } = await getReferralCoupon(userId)
  return {
    _id,
    code,
    enrollmentDiscount: enrollmentDiscount.toString()
  }
}

/**
 * API Call to get user's referral coupon
 * @param urlToShorten `required` url to be shortened
 * @return promise containing the shorten url from cuttly
 **/

export const getShortenUrlInformation = async (urlToShorten: string) => {
  const res = await getShortenUrl(urlToShorten)
  return res
}

/**
 * API Call to check if coupon can be shared (every 5 hours it can be shared)
 * @param userId `required`
 * @param referralCuponId `required` _id of the coupon
 * @param medium `required` could be either linkedin/whatsapp
 * @return {"sucess": boolean, "message"?: string}
 **/

export const putShareCoupon = async (userId: string, referralCuponId: string, medium: string) => {
  return await shareCoupon(userId, referralCuponId, medium)
}
/**
 * API Service to get billing information
 * @param userId `required` loged user id
 * @returns billing information
 */
export const getInvoiceInfo = async (userId: string) => {
  return await getInvoiceInfoFromAPI(userId)
}

/**
 * API Service to get all payments for some user
 * @param userId `required` loged user id
 * @returns array of payments
 */
export const getPayments = async (userId: string) => {
  try {
    return await getPaymentsFromAPI(userId)
  } catch (error) {
    return error
  }
}

export const uploadInvoice = async (invoiceId: string, payoutId: string, userId: string, payload: Invoice, file: File) => {
  const formData = new window.FormData()
  const body = { userId, file, prefix: file.name }
  Object.entries(body).map(([k, v]) => formData.append(k, v))

  const fileResponse = await uploadInvoiceFromAPI(formData)

  const invocie = {
    invoiceId,
    payoutId,
    userId,
    invoice: {
      ...payload,
      details: {
        ...payload.details,
        fileLink: fileResponse.files[0].url
      }
    }
  }

  return await uploadBillFromAPI(invocie)
}

/** Service to retrieve history of requests
 * @param userId `required` Logged user ID
 * FromCourse => current course id
 * @returns list of requests
 */

export const getRequests = async (userId: string, fromCourse: string): Promise<SelfManagementRequest[]> => {
  const body = {
    userId,
    fromCourse,
    ts: Date.now()
  }
  return await getRequestsFromAPI(userId, body)
}

/** Service to know if is eligible for reenroll and if it has penalty
 * @param userId `required` Logged user ID
 * @param courseId `required` current course
 * @returns Request status for the current course
 */

export const isRequestReenrollValid = async (userId: string, courseId: string): Promise<SelfManagementFlags> => {
  return await isRequestReenrollValidFromAPI(userId, courseId)
}

/** Service to know specific price for penalty
 * @param userId `required` Logged user ID
 * @param country `required` Logged user country
 * @param type `required` type of penalty
 * @returns penalty type and price
 */

export const getPenalty = async (userId: string, country: string, type: 'reenroll' | 'change'): Promise<Penalty> => {
  return await getPenaltyFromAPI(userId, country, type)
}

/** Service to request the reenroll of certain course
 * @param userId `required` Logged user ID
 * @returns confirmation message
 */

export const requestReenroll = async (body: ReenrollPayload): Promise<SelfManagementPayment> => {
  return await requestReenrollV2FromAPI(body)
}

/** API call to request the unenroll of certain course
 * @param body  {Object} - Course and user data to request unenroll
 * @param body.userId  Logged user ID
 * @param body.courseId course the user wants to unenroll
 * @returns {boolean}
 */

export const requestUnenroll = async (body: Unenroll): Promise<boolean> => {
  const response = await requestUnenrollFromAPI(body)
  return response.success
}

/** Service to discard a request that was in process
 * @param userId `required` Logged user ID
 * @param requestId `required` request identifier to discard
 * @returns confirmation message
 */

export const discardRequest = async (body: { userId: string; requestId: string }): Promise<boolean> => {
  return await discardRequestFromAPI(body)
}

export const updatePicture = async (userId: string, file: File): Promise<string | false> => {
  const formData = new FormData()
  formData.append('file', file)

  const uploadedImageLink = await uploadProfileImage(formData)
  if (!uploadedImageLink.url) return false

  // body
  const profile = {
    avatar: uploadedImageLink.url
  }

  const updateAvatar = await updateUserAvatar(userId, profile)
  return updateAvatar.profile.avatar
}

export const geUserSocialAccounts = async (): Promise<unknown> => {
  try {
    return getSocialAccounts()
  } catch (error) {
    return false
  }
}

export const removeUserSocialAccount = async (email: string, provider: string): Promise<unknown> => {
  try {
    return removeSocialAccount(email, provider)
  } catch (error) {
    return false
  }
}