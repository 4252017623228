
import { defineComponent, PropType } from 'vue'


export default defineComponent({
  props: {
    state: {
      type: String as PropType<
        'approved' | 'delivered' | 'closed' | 'pending' | 'expired' | 'on-hold' | 'for-receiving' | 'out-of-time' | 'approved-delay'
      >,
      default: 'approved'
    },
    role: {
      type: Number,
      default: 2
    },
    copies: {
      type: Object,
      required: true
    },
    showChallengeType: { type:Function, required:true, default: () => 'Regular' }
  },
  setup() {
    return {}
  }
})
