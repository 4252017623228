
import { defineComponent, reactive, toRefs } from 'vue'
import Card from '@/components/aero/surfaces/Card.vue'

// Locales
import copies from '@/locales/challenges/es.json'

export default defineComponent({
  components: { Card },
  props: {
    role: { type: Number, required: true },
    customCopies: { type: Object, default: () => ({ line1: undefined, line2: undefined }) },
    isExpired: { type: Boolean, default: false },
    hasError: { type: Boolean, default: false },
    isDisabled: { type: Boolean, default: false },
    dateString: { type: String, default: '' },
    isComponentError: { type: Boolean, required: false },
    isEmpty: { type: Boolean, required: false }
  },
  setup(props) {
    const state = reactive({
      iconColor:
        props.isExpired || props.hasError || props.isDisabled || props.isComponentError || props.isEmpty
          ? 'var(--white)'
          : 'var(--blue)',
      tagColor:
        !props.isExpired && !props.isComponentError
          ? 'text-blue'
          : props.isExpired && props.role === 2
          ? 'text-error '
          : 'text-white'
    })

    return { ...toRefs(state), copies: copies.PlaceholderState }
  }
})
