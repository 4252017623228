
import { defineComponent, computed } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  props: {
    copies: {
      type: Object
    }
  },
  setup(props) {
    const { t } = useI18n()
    const title = computed(() => {
      return props.copies?.title || t('errorPage.title')
    })

    const message = computed(() => {
      return props.copies?.message || t('errorPage.message')
    })

    return {
      title,
      message
    }
  }
})
