
import { EvaluationCriteria } from '@/models/peer-review/challengeHistory'
import { HomeworkDeliveryStatus, HomeworkTypes } from '@/utils/constants'
import { defineComponent, PropType, computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  emits: ['close', 'enable'],
  props: {
    challenge: { required: true, type: Object },
    rubricas: { required: true, type: Array as PropType<EvaluationCriteria[]> },
    visible: {type: Boolean as PropType<boolean>, default: false },
    personStore: { type: Object, required: true },
    slotProps: { type: Object, required: true },
    grade: { default: 0, required: true },
    sending: { type: Boolean, required: true },
    showUploadSection: {type: Boolean, default: false},
    isExpired: {type: Boolean, default: false},
    criteriaIndex: Number
  },
  setup(props, { emit }) {
    const { t } = useI18n()
    const rubriks = ref<EvaluationCriteria[]>(props.rubricas)

    const show = computed({
      get: () => props.visible,
      set: () => emit('close')
    })
    const close = () => emit('close')
    const enable = () => emit('enable')

    const isEnabled = computed(() => 
    props.personStore.person.rol === 2 &&
    props.slotProps.status === HomeworkDeliveryStatus.APPROVED &&
    props.challenge.type === HomeworkTypes.PREFINAL &&
    props.criteriaIndex === 0 &&  
    props.grade === 7 &&
    props.isExpired &&
    !props.showUploadSection 
    )

    return {
      rubriks,
      close,
      show,
      t,
      isEnabled,
      enable,
      HomeworkTypes
    }
  }
})
