
import { defineComponent, ref, PropType } from 'vue'

// Components
import Card from '@/components/aero/surfaces/Card.vue'
import Checkbox from 'primevue/checkbox'

// Models
import { IOptions } from '@/models/commons/InputOptions'

export default defineComponent({
  components: {
    Card,
    Checkbox
  },
  props: {
    contained: { type: Boolean, default: false },
    name: { type: String, required: true },
    options: { type: Array as PropType<IOptions[]> },
    defaultValue: { type: Array as PropType<string[] | number[]> }
  },
  emits: ['onUpdateValue'],
  setup(props, { emit }) {
    const optionValue = ref<string[] | number[]>(props.defaultValue || [])

    const handleUpdateValue = () => {
      emit('onUpdateValue', optionValue.value)
    }

    return { optionValue, handleUpdateValue }
  }
})
