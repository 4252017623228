import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-09b2e677"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-d-flex p-flex-column" }
const _hoisted_2 = {
  key: 0,
  class: "p-d-flex p-flex-column p-mt-4"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 0,
  class: "p-grid p-jc-start p-ai-center"
}
const _hoisted_5 = { class: "p-col p-mb-2 p-mt-2" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 0,
  class: "message-box-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CorrectionCard = _resolveComponent("CorrectionCard")!
  const _component_Ellipse = _resolveComponent("Ellipse")!
  const _component_icon_base = _resolveComponent("icon-base")!
  const _component_Line = _resolveComponent("Line")!
  const _component_MessageBox = _resolveComponent("MessageBox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h5", null, _toDisplayString(_ctx.t('homework.challenge.peerReview.list.title')), 1),
    _createElementVNode("span", null, _toDisplayString(_ctx.t('homework.challenge.peerReview.list.subtitle')), 1),
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.challengeState === _ctx.ChallengeHistoryStates.EXPIRED)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_Line, { class: "line-position" }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.corrections, (correction, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: correction.id
                      }, [
                        _createVNode(_component_CorrectionCard, {
                          correction: correction,
                          index: index,
                          onCorrection: _ctx.goToCorrection
                        }, null, 8, ["correction", "index", "onCorrection"])
                      ]))
                    }), 128)),
                    (_ctx.challengeState === _ctx.ChallengeHistoryStates.EXPIRED)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                          _createVNode(_component_icon_base, {
                            class: "p-col-fixed p-ml-4 p-mt-3 ellipse-icon",
                            "stroke-color": "#E5F876",
                            "fill-color": "#E5F876"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_Ellipse)
                            ]),
                            _: 1
                          }),
                          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.t('homework.challenge.peerReview.list.expired')), 1)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                (_ctx.state.corrections.every(_ctx.isBlocked))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createVNode(_component_MessageBox, {
                        icon: _ctx.ClockCounter,
                        message: _ctx.t('homework.challenge.peerReview.list.blocked.message'),
                        "icon-color": "#EA90FF"
                      }, {
                        title: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('homework.challenge.peerReview.list.blocked.title')), 1)
                        ]),
                        _: 1
                      }, 8, ["icon", "message"])
                    ]))
                  : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.state.corrections, (correction, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: correction.id
                      }, [
                        _createVNode(_component_CorrectionCard, {
                          correction: correction,
                          index: index,
                          onCorrection: _ctx.goToCorrection
                        }, null, 8, ["correction", "index", "onCorrection"])
                      ]))
                    }), 128))
              ]))
        ]))
      : _createCommentVNode("", true)
  ]))
}