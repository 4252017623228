
import { defineComponent, ref, PropType } from 'vue'

// Components
import RadioButton from 'primevue/radiobutton'

interface IComissionItem {
  teacherName: string
  camadaNro: string
  days: string
  schedule: string
  duration: string
  daysToStart: number
  value: string
}

export default defineComponent({
  components: {
    RadioButton
  },
  props: {
    name: { type: String, required: true },
    options: { type: Array as PropType<IComissionItem[]>, default: () => [] }
  },
  emits: ['onUpdateValue'],
  setup(props, { emit }) {
    const optionValue = ref('')
    const handleUpdateValue = () => {
      emit('onUpdateValue', optionValue.value)
    }

    return { handleUpdateValue, optionValue }
  }
})
