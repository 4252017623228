<template>
  <path
    d="M9.75 7.5V19.0986C9.75 19.2221 9.71952 19.3436 9.66126 19.4525C9.60299 19.5614 9.51876 19.6542 9.41603 19.7226L8.38551 20.4097C8.28551 20.4763 8.17095 20.518 8.05148 20.5311C7.93202 20.5443 7.81114 20.5285 7.69904 20.4852C7.58694 20.4418 7.48689 20.3722 7.40733 20.2821C7.32777 20.192 7.27103 20.0841 7.24188 19.9675L6 15"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M6 15C5.00544 15 4.05161 14.6049 3.34835 13.9016C2.64509 13.1984 2.25 12.2445 2.25 11.25C2.25 10.2554 2.64509 9.30159 3.34835 8.59833C4.05161 7.89507 5.00544 7.49998 6 7.49998H9.75C9.75 7.49998 14.8548 7.49998 19.7673 3.37989C19.8765 3.28794 20.0098 3.22909 20.1513 3.21027C20.2929 3.19146 20.4369 3.21345 20.5664 3.27367C20.6958 3.33389 20.8054 3.42983 20.8823 3.55021C20.9591 3.67059 20.9999 3.8104 21 3.9532V18.5468C20.9999 18.6896 20.9591 18.8294 20.8823 18.9498C20.8054 19.0701 20.6958 19.1661 20.5664 19.2263C20.4369 19.2865 20.2929 19.3085 20.1513 19.2897C20.0098 19.2709 19.8765 19.212 19.7673 19.1201C14.8548 15 9.75 15 9.75 15H6Z"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</template>
