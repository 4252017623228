import { Api } from '../api'

// Models
import { UserLeaderboard } from '@/models/dashboard/ranking'

const { API_ENDPOINT } = process.env

/**
 * Get leaderboard and return the user's rank and progress
 * @param userId Logged user id
 * @param courseId Logged course id
 */
export const getRanking = async (userId: string, courseId: string): Promise<UserLeaderboard[]> => {
  const response: UserLeaderboard[] = await Api.get(
    `${API_ENDPOINT}/platform/courses/${courseId}/leaderboard?courseId=${courseId}&honorDeliver=&ts=000&userId=${userId}`
  )

  return response
}
