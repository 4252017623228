
import { defineComponent, PropType } from 'vue'
import Card from '@/components/aero/surfaces/Card.vue'
import ButtonIcon from '@/components/aero/buttons/ButtonIcon.vue'
import { ChevronRight } from '@/components/aero/icon/templates'
import StateTags from '@/components/aero/dataDisplay/StateTags.vue'

// Copies
import copies from '@/locales/challenges/es.json'

// store
import { useUserStore } from '@/store/modules/user.store'

// models
import { IChallenge } from '@/models/newPlatform/challenges/challenge'

// example
// TODO: move to models
enum ChallengesStatus {
  Approved = 'approved',
  Dissapproved = 'disapproved',
  Delivered = 'delivered',
  NotDelivered = 'not delivered',
  OutOfTime = 'out of time',
  OnHold = 'waiting'
}

export default defineComponent({
  components: { Card, ButtonIcon, ChevronRight, StateTags },
  props: {
    challenge: { type: Object as PropType<IChallenge>, required: true },
    camadaNro: { type: String, required: true },
    useCoderChallenge: {type: Boolean, required: true}
  },
  setup(props) {
    const { role } = useUserStore()
    const statuses = {
      [ChallengesStatus.Approved]: {
        label: 'Aprobado',
        color: 'apple-green'
      },
      [ChallengesStatus.NotDelivered]: {
        label: 'Pendiente',
        color: 'purple'
      },
      [ChallengesStatus.Delivered]: {
        label: 'Entregado',
        color: 'blue'
      },
      [ChallengesStatus.OutOfTime]: {
        label: 'Vencido',
        color: 'error'
      },
      [ChallengesStatus.OnHold]: {
        label: 'En espera',
        color: 'white'
      }
    }

    const showChallengeType = ():string => {
      return props.challenge.flags?.isIntegratorTp
        ? 'Final'
        : props.challenge.flags?.isPreIntegratorTp
        ? 'Preentrega'
        : props.challenge.flags?.isComplementaryTp
        ? 'Complementario'
        : 'Regular'
    }

    const customSubtitle = props.challenge.flags?.isIntegratorTp
      ? copies.subtitleIntegrator
      : props.challenge.flags?.isPreIntegratorTp
      ? copies.subtitlePreIntegrator
      : copies.subtitleChallenge

    const customTitle = props.challenge.name?.replace(copies.titleDesafio, '')

    const classOrder = Number(role) === 2 ? props.challenge.class?.order : props.challenge.class?.classId
    const regularChallenge = props.challenge.flags?.isPreIntegratorTp || props.challenge.flags?.isIntegratorTp

    return { statuses, role, copies, customTitle, customSubtitle, classOrder, regularChallenge, showChallengeType }
  }
})
