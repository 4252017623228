import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5a9f94b3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "columnOpinion p-py-4 border-radius bg-dark-gray" }
const _hoisted_2 = { class: "p-d-flex p-jc-between p-mx-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SmallCardOpinion = _resolveComponent("SmallCardOpinion")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.feedbackType === 'suggestion' ? _ctx.t('rating.opinionTags.suggestions') : _ctx.t('rating.opinionTags.positiveRatings')), 1),
      _createElementVNode("span", null, _toDisplayString(_ctx.tagTotals), 1)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (item) => {
      return (_openBlock(), _createBlock(_component_SmallCardOpinion, {
        key: item.tag,
        feedbackType: _ctx.feedbackType,
        description: item.tag,
        amount: item.total,
        percentage: (item.total * 100) / _ctx.totals
      }, null, 8, ["feedbackType", "description", "amount", "percentage"]))
    }), 128))
  ]))
}