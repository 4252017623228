
// Vendor
import { defineComponent, PropType, ref, computed } from 'vue'

// Components
import Card from '@/components/aero/surfaces/Card.vue'

// Icons
import Icon from '@/components/aero/icon/Icon.vue'
import { AlertCircle } from '@/components/aero/icon/templates'

// Models
import { IProjectProgress } from '@/models/classes/projectProgress'
import { IAmount } from '@/models/classes/progress'

export default defineComponent({
  components: { Card, Icon, AlertCircle },
  props: {
    faltas: {
      type: Array as PropType<Array<number>>,
      default: () => [],
      required: false
    },
    classesLength: { type: Number, required: false, default: 0 },
    absents: { type: Object as PropType<IAmount>, required: true },
    attendance: { type: Object as PropType<IAmount>, required: true },
    projectProgress: { type: Array as PropType<Array<IProjectProgress>>, default: () => [], required: true },
    courseStarted: { type: Boolean, required: false },
    copies: { type: Object, required: false }
  },
  setup(props) {
    const maxAbsents = ref<number>(props.absents.maxAmount)
    const absentsExceeded = ref<boolean>(props.absents.completed > maxAbsents.value)

    const faltasCopy = computed(() => {
      if (props.absents.completed < maxAbsents.value) {
        return `${props.absents.completed}/${maxAbsents.value} ${props.copies?.absents} ${props.copies?.accepted}`
      }
      if (props.absents.completed === maxAbsents.value) {
        return props.copies?.withOutAbsence
      }
      if (props.absents.completed > maxAbsents.value) {
        return props.copies?.maxAbsents
      }
      return `0/ ${props.copies?.absents} ${maxAbsents.value} ${props.copies?.accepted}`
    })

    const deliversOnTime = computed(() => {
      const expiredList: any[] = []
      props.projectProgress.forEach((project: IProjectProgress) => {
        return (
          project.flags.hasDelivers &&
          project.modules.forEach((_module: any) => _module.flags.isDeliverExpired && expiredList.push(project))
        )
      })
      const hasDeliversOnTime = expiredList.every((deliver) => deliver.progress.amount !== 0)
      return hasDeliversOnTime
    })

    return {
      faltasCopy,
      absentsExceeded,
      deliversOnTime,
      maxAbsents
    }
  }
})
