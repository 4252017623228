
import { defineComponent, PropType, computed } from 'vue'
import Icon from '@/components/aero/icon/Icon.vue'
import { Point, CheckFill, FlagFill, AlertCircle } from '@/components/aero/icon/templates'
import { ITimeline, ITimeLineStatus } from '@/models/newPlatform/challenges/timeline'

import { DateTime } from 'luxon'
import { getTime, getDate } from '@/utils/datetime'

// Locales
import copies from '@/locales/challenges/es.json'

export default defineComponent({
  components: { Icon, Point, CheckFill, FlagFill, AlertCircle },
  props: {
    role: { type: Number, required: true },
    isTutor: { type: Boolean, required: false },
    step: { type: Object as PropType<ITimeline>, required: true },
    isReviewExpired: { type: Boolean, default: false },
    isDisabled: { type: Boolean, default: false },
    isIntegratorTp: { type: Boolean, default: false }
  },
  setup(props) {
    const statusKey = {
      open: ITimeLineStatus.open,
      disabled: ITimeLineStatus.disabled,
      deadline: ITimeLineStatus.deadline,
      finalDeadline: ITimeLineStatus.finalDeadline,
      delivered: ITimeLineStatus.delivered,
      reviewExpected: ITimeLineStatus.reviewExpected,
      reviewed: ITimeLineStatus.reviewed,
      disapproved: ITimeLineStatus.disapproved,
      approved: ITimeLineStatus.approved,
      expired: ITimeLineStatus.expired,
      deliverAgain: ITimeLineStatus.deliverAgain,
      locked: ITimeLineStatus.locked,
      unlocked: ITimeLineStatus.unlocked,
      deliveredAgain: ITimeLineStatus.deliveredAgain
    }

    const setApprovedTag = computed(() => {
      if (props.step.key !== statusKey.approved) return ''
      if (props.role === 2) {
        return copies.StateHistorial.student.approved.tag
      } else if (props.isTutor) {
        return copies.StateHistorial.tutor.approved.tag
      } else {
        return copies.StateHistorial.teacher.approved.tag
      }
    })

    const setDateTime = computed(() => {
      const checkDate = props.step.expiresAt || props.step.date
      const millisDate = typeof checkDate === 'string' ? DateTime.fromISO(checkDate).toMillis() : checkDate
      return `${getDate(millisDate)} a las ${getTime(millisDate)}`
    })

    return { statusKey, copies: copies.StateHistorial, setApprovedTag, setDateTime }
  }
})
