
import { defineComponent, PropType, ref, computed } from 'vue'

// Components
import Avatar from '@/components/aero/dataDisplay/Avatar.vue'
import TextInput from 'primevue/inputtext'
import Comment from '@/components/aero/dataDisplay/dashboard/Comment.vue'
import ButtonIcon from '@/components/aero/buttons/ButtonIcon.vue'
import Icon from '@/components/aero/icon/Icon.vue'
import Menu from 'primevue/menu'
import Toast from '@/components/aero/feedback/Toast.vue'

// Locales
import copies from '@/locales/dashboard/es.json'

// Icons
import { Bookmark, ChatSingleCircle, Dots, PaperPlaneTilt, ThumbUp, Share, FileText } from '@/components/aero/icon/templates'

// Models
import { IPost } from '@/models/dashboard/community'

// Services
import { commentPostService, likeCommentService, likePostService } from '@/services/newPlatform/dashboard.services'

// Utils
import { parseCommentDate } from '@/utils/datetime'

// Store
import { useUserSessionStore } from '@/store/modules/user-session.store'
import { useUserStore } from '@/store/modules/user.store'

// Hooks
import { useToast } from 'primevue/usetoast'

export default defineComponent({
  components: {
    Avatar,
    Bookmark,
    ButtonIcon,
    ChatSingleCircle,
    Comment,
    Menu,
    Icon,
    PaperPlaneTilt,
    Share,
    ThumbUp,
    TextInput,
    Dots,
    FileText,
    Toast
  },
  emits: ['onSaveUnsavePost', 'onOpenDialog'],
  props: {
    post: { type: Object as PropType<IPost>, required: true },
    isPinned: { type: Boolean, default: false },
    searchedWords: { type: Array as PropType<string[]>, default: () => [] }
  },
  setup(props, { emit }) {
    const { userId } = useUserSessionStore()
    const { role, avatar, firstName, lastName } = useUserStore()

    const showComments = ref<boolean>(false)
    const showAvatar = props.post.platformUser?.avatar || props.post.platformUser?.fullName

    const postRef = ref<IPost>(props.post)
    const error = ref<boolean>(false)
    const menu = ref()

    const commentContent = ref<string>('')

    const commentsToShow = ref<number>(2)

    const isMobileDevice = ref<boolean>(typeof navigator.share === 'function')

    const toast = useToast()

    const toggle = (event: Event) => {
      event.preventDefault()
      if (!menuItems.value.length) return
      menu.value.toggle(event)
    }

    // Computed

    /**
     * Loads more comments
     * @returns array containing all loaded comments
     */
    const commentsLoaded = computed(() => {
      return postRef.value.comments?.slice(0, commentsToShow.value)
    })

    /**
     * @returns boolean indicating that all comments have been loaded in order to display show more or show less button
     */
    const allCommentsLoaded = computed(() => {
      return commentsLoaded.value?.length === postRef.value.comments?.length
    })

    /**
     * Checks if the post was created by the user or the role is teacher or tutor and gives him the possibility to delete it
     */
    const menuItems = computed<{ id?: string; label?: string; command?: () => void }[]>(() => {
      if (role === 3 || postRef.value.platformUser?._id === userId) {
        return [
          {
            id: '1',
            label: copies.menuActions.deletePost,
            command: () => {
              deletePost()
            }
          }
        ]
      } else {
        return []
      }
    })

    /**
     * Method to call the create-comment service and dynamically update the array
     * @param comment Comment
     */
    const commentPost = async () => {
      const comment = {
        _id: Math.random().toString(),
        commentContent: commentContent.value,
        likeCount: 0,
        likes: [],
        date: new Date().toISOString(),
        flags: {
          wasLiked: false
        },
        createdAt: new Date().toISOString(),
        platformUser: { _id: userId, avatar, fullName: `${firstName} ${lastName}` }
      }
      postRef.value.comments?.unshift(comment)
      commentContent.value = ''
      await commentPostService({ blogId: postRef.value._id, comment, userId })
    }

    /**
     * UI method to like a post
     */
    const likePost = async () => {
      try {
        await likePostService(props.post._id, userId)
        postRef.value.flags.wasLiked = true
        postRef.value.likeCount++
      } catch (err) {
        error.value = true
      }
    }

    /**
     * UI method to dislike an already liked post
     */
    const dislikePost = () => {
      postRef.value.flags.wasLiked = false
      postRef.value.likeCount--
    }

    /**
     * UI Method to like or dislike a comment.
     * @param commentId of the comment that is set to be liked or disliked
     * @param isLiked if the comment wants to be liked then `true`, else `false`
     */

    const likeDislikeComment = async (commentId: string, isLiked: boolean) => {
      try {
        // TODO: This service is not working as expected. Ask how it works
        await likeCommentService(commentId, props.post._id, userId)
        const comment = postRef.value.comments?.find(({ _id }) => _id === commentId)
        if (comment && isLiked) {
          comment.likeCount--
          comment.flags.wasLiked = false
        }
        if (comment && !isLiked) {
          comment.likeCount++
          comment.flags.wasLiked = true
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error)
      }
    }

    /**
     * UI Method to open dialog to delete a post
     */
    const deletePost = () => {
      emit('onOpenDialog', props.post._id)
    }

    /**
     * Show/hide comments for the post
     */
    const toggleComments = () => {
      showComments.value = !showComments.value
    }

    /**
     * Method to save/unsave post
     */
    const savePost = () => {
      postRef.value.flags.wasSaved = !postRef.value.flags.wasSaved
      emit('onSaveUnsavePost', props.post._id)
    }

    const isSaved = computed(() => {
      return postRef.value.flags.wasSaved
    })

    const showToast = (success: boolean) => {
      const copyToast = copies.toast.copyPost

      toast.add({
        severity: success ? copyToast.severity.success as 'success' : copyToast.severity.error as 'error',
        detail: success ? copyToast.success : copyToast.error,
        group: copyToast.group,
        life: copyToast.life
      })
    }

    const title = computed(() => {
      const searchedWords = props.searchedWords.map((word) => word.toLowerCase())
      return props.post.title
        .split(' ')
        .map((word) =>
          searchedWords.includes(word.toLowerCase()) ? `<span style="font-weight: var(--bold)">${word}</span>` : word
        )
        .join(' ')
    })

    /** Method to share text of post depending device */
    const handleShare = async () => {
      const postText = postRef.value.title.replace(/<[^>]+>/g, '')
      const existPlatformUser = !!postRef.value.platformUser
      const text = `${existPlatformUser ? `${postRef.value.platformUser?.fullName}: ` : ''}${postText}`

      if (isMobileDevice.value) {
        // TODO: check if this is working
        await navigator.share({
          text
        })
      } else {
        try {
          await navigator.clipboard.readText()
          await navigator.clipboard.writeText(text)
          showToast(true)
        } catch (err) {
          showToast(false)
        }
      }
    }

    return {
      commentContent,
      deletePost,
      toggleComments,
      commentsToShow,
      showComments,
      allCommentsLoaded,
      commentsLoaded,
      parseCommentDate,
      showAvatar,
      menuItems,
      menu,
      copies,
      commentPost,
      likePost,
      dislikePost,
      likeDislikeComment,
      isSaved,
      savePost,
      toggle,
      postRef,
      title,
      handleShare,
      isMobileDevice,
      role
    }
  }
})
