import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, renderSlot as _renderSlot, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4c6599ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "p-col-2 p-p-0 p3"
}
const _hoisted_2 = { class: "p-col-2 p-p-0 p3" }
const _hoisted_3 = { class: "p-col-3 p-p-0 p3" }
const _hoisted_4 = { class: "p-col-2 p-p-0 p3" }
const _hoisted_5 = { class: "p-col-2 p-text-center p-p-0 p3" }
const _hoisted_6 = {
  key: 1,
  class: "p3 medium text-purple"
}
const _hoisted_7 = { class: "p-col-1 p-p-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_check_fill = _resolveComponent("check-fill")!
  const _component_icon = _resolveComponent("icon")!
  const _component_chevron_up = _resolveComponent("chevron-up")!
  const _component_button_icon = _resolveComponent("button-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("ul", {
      class: _normalizeClass(["p-grid item__wrapper", _ctx.isEven ? 'bg-gray-2' : 'bg-gray'])
    }, [
      (_ctx.item.date)
        ? (_openBlock(), _createElementBlock("li", _hoisted_1, _toDisplayString(_ctx.setParsedDate(_ctx.item.date)), 1))
        : _createCommentVNode("", true),
      _createElementVNode("li", _hoisted_2, _toDisplayString(_ctx.item.camada), 1),
      _createElementVNode("li", _hoisted_3, _toDisplayString(_ctx.item.level), 1),
      _createElementVNode("li", _hoisted_4, "$" + _toDisplayString(_ctx.item.amount), 1),
      _createElementVNode("li", _hoisted_5, [
        (_ctx.item.details?.id)
          ? (_openBlock(), _createBlock(_component_icon, {
              key: 0,
              fillColor: "var(--lime-light",
              strokeColor: "transparent"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_check_fill)
              ]),
              _: 1
            }))
          : (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.copies.pending), 1))
      ]),
      _createElementVNode("li", _hoisted_7, [
        _createVNode(_component_button_icon, {
          size: 24,
          strokeColor: "var(--white)",
          onClick: _ctx.handleToggle,
          class: _normalizeClass(["p-ml-auto", _ctx.isOpen ? 'item__chevron--up' : 'item__chevron--down'])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_chevron_up)
          ]),
          _: 1
        }, 8, ["onClick", "class"])
      ])
    ], 2),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 64))
}