import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6572b0a3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "avatar" }
const _hoisted_2 = { class: "name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Avatar, {
      image: _ctx.student?.avatar && _ctx.student.avatar,
      label: !_ctx.student.avatar ? _ctx.initialLetter(_ctx.student?.name) : null,
      class: "avatar-content",
      shape: "circle",
      size: "xlarge"
    }, null, 8, ["image", "label"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h6", null, _toDisplayString(_ctx.student.name), 1)
    ])
  ]))
}