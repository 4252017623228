import { ITimeline, ITimeLineStatus } from '@/models/newPlatform/challenges/timeline'
import { IChat } from '@/models/newPlatform/challenges/chat'

import { DateTime } from 'luxon'

import { ReviewChallenge as reviewCopies, lockDeliver, enableLockedDeliver } from '@/locales/challenges/es.json'

interface IHistory {
  timeline: ITimeline[]
  chat: IChat[]
}

export function useTimeline(
  history: IHistory,
  challengeOpenDate: number,
  expiresAt: number,
  isEnabled: boolean,
  isTeacher?: boolean,
  reviewExpected?: number
) {
  const newTimeline: ITimeline[] = []
  const open = {
    key: ITimeLineStatus.open,
    name: 'habilitada',
    date: challengeOpenDate
  }
  if (!isEnabled) {
    const disabled = {
      key: ITimeLineStatus.disabled,
      name: 'no habilitada',
      date: challengeOpenDate
    }
    newTimeline.push(disabled)
    return newTimeline
  }

  if (history.timeline.length === 0) {
    newTimeline.push(open)
    return newTimeline
  } else {
    const filteredTimeline = history.timeline.filter(
      (step) =>
        step.key !== ITimeLineStatus.open && step.key !== ITimeLineStatus.finalDeadline && step.key !== ITimeLineStatus.today
    )
    // format dates to millis
    const timelineDates = filteredTimeline.map((step) => {
      const date = typeof step.date === 'string' ? DateTime.fromISO(step.date).toMillis() : step.date
      return { ...step, date }
    })

    // add expected review date for the teacher
    if (isTeacher) {
      const deliveredDate = timelineDates.find((step) => step.key === ITimeLineStatus.delivered)

      if (deliveredDate) {
        deliveredDate.expiresAt = reviewExpected
      }
    }

    if (history.chat.length > 0) {
      // format chat to fit timeline interface
      const chatTimeline = history.chat.map((chat) => {
        const date = typeof chat.date === 'string' ? DateTime.fromISO(chat.date).toMillis() : chat.date
        const step = {
          key: ITimeLineStatus.chat,
          name: 'chat',
          date,
          content: { ...chat, date },
          hide: false,
          expiresAt: 0
        }

        return step
      })
      // check if the message contains approved and change prop to hide it
      const messageApproved = chatTimeline.filter((chat) => chat.content.text === reviewCopies.message.approved)
      messageApproved.length &&
        messageApproved.forEach((message) => {
          message.hide = true
        })

      // check if the message contains deliver-again. Overwrite to fit the deliver-again step.
      // This step applies when the challenge is rejected and the teacher/tutor requests a new deliver
      const messageDeliverAgain = chatTimeline.filter((chat) => chat.content.text === reviewCopies.message.deliverAgain)

      messageDeliverAgain.length &&
        messageDeliverAgain.forEach((message) => {
          const preDate = message.date
          message.key = ITimeLineStatus.deliverAgain
          message.name = 'reentregar'
          message.date = preDate + 1
          message.hide = true
          message.expiresAt = expiresAt
        })

      // check if the message contains delivered-again. Overwrite to fit the delivered-again for students step
      // this step applies when the student hands over a new deliver
      const messageStudentDeliverAgain = chatTimeline.filter(
        (chat) => chat.content.text === reviewCopies.message.studentDeliveredAgain
      )

      messageStudentDeliverAgain.length &&
        messageStudentDeliverAgain.forEach((message) => {
          const preDate = message.date
          message.key = ITimeLineStatus.deliveredAgain
          message.name = 'reentrega-estudiante'
          message.date = preDate + 1
          message.hide = true
          message.expiresAt = expiresAt
        })

      // check if the challenge if locked. Overwrite to fit the locked step
      const lockedMessage = chatTimeline.filter((chat) => chat.content.text === lockDeliver.message)

      lockedMessage.length &&
        lockedMessage.forEach((message) => {
          const preDate = message.date
          const lockedStep = {
            key: ITimeLineStatus.locked,
            name: 'bloqueado',
            date: preDate + 1,
            hide: false
          }
          timelineDates.push(lockedStep)
        })

      // check if the challenge has been unlocked. Overwrite to fit the unlocked step
      const unlockedMessage = chatTimeline.filter((chat) => chat.content.text === enableLockedDeliver.message)
      unlockedMessage.length &&
        unlockedMessage.forEach((message) => {
          message.hide = true
          const preDate = message.date
          const unlockedStep = {
            key: ITimeLineStatus.unlocked,
            name: 'desbloqueado',
            date: preDate + 1,
            hide: false
          }
          timelineDates.push(unlockedStep)
        })

      // join timeline with chat and sort based on date
      const timeline = timelineDates.concat(chatTimeline).sort((a, b) => a.date - b.date)
      timeline.unshift(open)

      return timeline
    }
    timelineDates.unshift(open)
    return timelineDates
  }
}

/**
 * update chats realtime when a challenges is approved
 * @param comment feedback from the teacher
 * @param isTp default to normal challenge. If the challenge is preInterator or integrator it needs the score value to show an extra message
 * @param isSoftScore boolean to dsplay the correct copy. only required when the challenge is preInterator or integrator.
 * @param score  softScore or score to display on the message. Only required when the challenge is preInterator or integrator.
 * @returns array of parsed chats with the updated information to display approved challenge
 */

export function useUpdateChatApproved(
  teacher: { name: string; avatar: string; _id: string },
  comment: string,
  isTp = true,
  isSoftScore?: boolean,
  score?: string
): IChat[] {
  function createChat(comment: string, date: number, isRichText: boolean): IChat {
    const userData = {
      avatar: teacher.avatar,
      flags: {
        canBeCoordinator: false,
        canBeTutor: false,
        isCoordinator: false,
        isInfluencer: false,
        isStudent: false,
        isTeacher: true,
        isTutor: true,
        normalized: true
      },

      fullName: teacher.name,
      initials: '',
      profile: {
        avatar: teacher.avatar,
        first_name: '',
        last_name: ''
      },
      _id: teacher._id
    }

    return {
      date: date,
      enable: true,
      image: null,
      viewed: '',
      fromAlias: '',
      flags: {
        fromCoordinator: false,
        fromStudent: false,
        fromSubstitute: false,
        fromTeacher: true,
        fromTutor: true,
        isLast: false,
        isNextFromSameUser: false,
        unviewed: false,
        viewed: true,
        hoursLeftToReview: 0,
        remainingTimeToReview: 0,
        fromUser: true
      },
      from: { ...userData },
      link: '',
      richText: isRichText,
      text: comment,
      viewedBy: [],
      _id: String(Math.random())
    }
  }

  const chats: IChat[] = []
  const feedback = createChat(comment, Date.now(), true)
  chats.push(feedback)

  if (!isTp) {
    const message = isSoftScore ? `${reviewCopies.message.softScore} ${score}` : `${reviewCopies.message.score} ${score}`
    const scoreMsg = createChat(message, Date.now() + 1, false)

    chats.push(scoreMsg)
  }

  return chats
}

/**
 *
 * update timeline on realtime when a challenges is approved
 * @param chats array of chat of the current challenge
 * @param timeline timeline of the current challenge
 * @param challengeOpenDate date to display when the challenge is enabled
 * @param expiresAt challenge expiration date
 * @param isEnabled status to display placeholder
 * @param isTeacher
 * @param reviewExpected date of review expected only for the teacher
 * @returns
 */

export function useUpdateChallengeApproved(
  chats: IChat[],
  timeline: ITimeline[],
  challengeOpenDate: number,
  expiresAt: number,
  isEnabled: boolean,
  isTeacher?: boolean,
  reviewExpected?: number
): ITimeline[] {
  const approvedStep: ITimeline = {
    completed: true,
    date: Date.now() + 10,
    hide: false,
    key: ITimeLineStatus.approved,
    name: 'approved'
  }

  timeline.push(approvedStep)

  const history: IHistory = {
    timeline,
    chat: chats
  }

  const newTimeline = useTimeline(history, challengeOpenDate, expiresAt, isEnabled, isTeacher, reviewExpected)

  return newTimeline
}
