
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    label: String,
    link: String,
    attachment: Object
  },
  setup (props) {

    const download = (url: string, isAttatchment: boolean) => {
      const anchor = document.createElement('a')
      if(isAttatchment) { 
        anchor.download = 'homework'
        anchor.href = url
      } else {
        anchor.href = !url.includes('https://') ? `https://${url}` : url
        anchor.target = '_blank'
      }
      document.body.appendChild(anchor)
      anchor.click()
    }

    const handleClick = () => {
      let url = ''

      if (props.link) {
        url = props.link
        download(url, false)
      }

      if (props.attachment && props.attachment.id) {
        url = `${window.location.origin}/api/v1/homework-student-delivery/file/${props.attachment.id}`
        download(url, true)
      }
    }

    return { handleClick }
  } 
})
