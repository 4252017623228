
// Vendor
import { computed, defineComponent, PropType } from 'vue'
import { useRouter } from 'vue-router'

// Components
import Button from 'primevue/button'
import Card from '@/components/aero/surfaces/Card.vue'
import ProgressCircle from '@/components/aero/misc/ProgressCircle.vue'
import CircleBadge from '../../dataDisplay/CircleBadge.vue'

// Locales
import copies from '@/locales/challenges/es.json'

export default defineComponent({
  components: { Card, ProgressCircle, Button, CircleBadge },
  props: {
    entregas: { type: Number, required: true },
    corregidas: { type: Number, required: true },
    porCorregir: { type: Number, required: true },
    porRecibir: { type: Number, required: true },
    deTutores: { type: Boolean, required: false, default: false },
    proyectoFinal: {
      type: Object as PropType<{
        title: string
        url: string
      }>,
      required: false
    }
  },
  setup(props) {
    const router = useRouter()
    const progress = computed(() => Math.round((props.corregidas * 100) / props.entregas))
    const progressColor = computed(() => (progress.value === 100 ? 'success' : 'purple'))
    const goToProyectoFinal = () => router.push(props.proyectoFinal?.url || '')

    const corrections: { type: string; icon: string; title: string; subtitle: string; color: string }[] = [
      {
        type: 'corregidas',
        icon: 'challenge-corrected',
        title: copies.pointCard.evaluations.evaluated.title,
        subtitle: copies.pointCard.evaluations.evaluated.subtitle,
        color: 'success'
      },
      {
        type: 'porCorregir',
        icon: 'challenge-pending-correction',
        title: copies.pointCard.evaluations.toBeEvaluated.title,
        subtitle: copies.pointCard.evaluations.toBeEvaluated.subtitle,
        color: 'purple'
      },
      {
        type: 'porRecibir',
        icon: 'challenge-pending-delivery',
        title: copies.pointCard.evaluations.toBeRecieved.title,
        subtitle: copies.pointCard.evaluations.toBeRecieved.subtitle,
        color: 'blue'
      }
    ]

    return {
      copies,
      progress,
      progressColor,
      goToProyectoFinal,
      corrections
    }
  }
})
