
// Vendor
import { defineComponent, PropType } from 'vue'

// Components
import Card from '@/components/aero/surfaces/Card.vue'

export default defineComponent({
  components: {
    Card
  },
  props: {
    feedbackType: { type: String as PropType<'positive' | 'neutral' | 'negative'>, required: true },
    amount: { type: Number, required: true },
    title: { type: String, required: true },
    description: { type: String, required: true }
  },
  setup(props) {
    const colorAmount =
      props.feedbackType === 'positive' ? 'text-success' : props.feedbackType === 'neutral' ? 'text-warning' : 'text-error'

    return { colorAmount }
  }
})
