
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    feedbackType: { type: String as PropType<'positive' | 'neutral' | 'negative'>, required: true },
    percentage: { type: Number, required: true }
  },
  setup(props) {
    const colorProgress =
      props.feedbackType === 'positive' ? 'bg-success' : props.feedbackType === 'neutral' ? 'bg-warning' : 'bg-error'

    return {
      colorProgress
    }
  }
})
