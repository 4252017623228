
import {
  getCorrection,
  getHomeworkPlan,
  getMyDelivery,
  sendChallenge,
  sendCorrection,
  sendChallengeRedelivery,
  editChallenge,
  requestDelivery
} from '@/api/challenge.api'
import { IChallengeDataDto } from '@/models/peer-review/challenge'
import { PeerReviewComponent } from '@/models/peer-review/correction'
import { ChallengeHistory } from '@/models/peer-review/challengeHistory'
import { ICorrection, ICorrectionDataDto, HomeworkPlan } from '@/models/peer-review/homeworkPlan'
import { useToast } from 'primevue/usetoast'
import { defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import ChallengeTimeline from './components/ChallengeTimeline.vue'
import StudentCorrection from './components/correction/StudentCorrection.vue'
import ChallengeCorrectionsList from './components/correction/ChallengeCorrectionsList.vue'
import ChallengeProgress from './components/ChallengeProgress.vue'
import Upload from './components/upload/Upload.vue'
import { useI18n } from 'vue-i18n'
import { DateTime } from 'luxon'
import MessageBox from '@/views/peerReview/components/MessageBox.vue'
import Lock from '@/components/commons/icon-templates/Lock.vue'
import { HomeworkTypes } from '@/utils/constants'
import { eventSwitchStepsChallenge } from '@/utils/amplitude'
// store
import { themeStore } from '@/store/modules/themes.store'
import { usePersonStore } from '@/store/modules/person.store'
import { useFirebaseStore } from '@/store/modules/firebase.store'
import { useCourseStore } from '@/store/modules/course.store'

export default defineComponent({
  components: {
    ChallengeProgress,
    Upload,
    ChallengeTimeline,
    StudentCorrection,
    ChallengeCorrectionsList,
    MessageBox
  },
  setup() {
    const theme = themeStore()
    theme.changeDefault('dark')
    const { t } = useI18n()
    const router = useRouter()
    const toast = useToast()
    const courseId = router.currentRoute.value.params.courseId
    const homeworkId = router.currentRoute.value.params.homeworkId
    const homeworkPlanId = router.currentRoute.value.params.homeworkPlanId
    const challenge = ref<HomeworkPlan>()
    const delivery = ref<ChallengeHistory | null>()
    const loading = ref<boolean>(true)
    const currentStep = ref<number>(0)
    const correction = ref<ICorrection>()
    const showCorrectionView = ref<boolean>(false)
    const sending = ref<boolean>(false)
    const homeworkDeliveryId = ref<string>('')
    const showCriteria = ref<boolean>(false)
    const setShowCriteria = (criteria: boolean) => (showCriteria.value = criteria)
    const showTimeline = ref<boolean>(false)
    const uploadMode = ref<string>('deliver')
    const myDeliveryId = ref<string | null>()
    const correctionDeliveryId = ref<string | null>()
    const studentData = usePersonStore()
    const usePeerReview = ref<boolean>(false)
    const showUploadSection = ref<boolean>(false)
    const isReSendPreFinal = ref<boolean>(false)

    const { camadaNro } = useCourseStore()
    const firebase = useFirebaseStore()

    const changeStep = (step: number) => {
      if(currentStep.value !== step){
        currentStep.value = step
        eventSwitchStepsChallenge(currentStep.value)
        showCorrectionView.value = false
        if (currentStep.value === 0) showTimeline.value = true
      }
    }

    const selectDelivery = async (deliveryId: string) => {
      correction.value = await getCorrection(deliveryId as string)
      correctionDeliveryId.value = deliveryId
      showCorrectionView.value = true
    }

    const challengeRedelivery = async (body: IChallengeDataDto) => {
      try {
        sending.value = true
        if(isReSendPreFinal.value) await requestDelivery(homeworkDeliveryId.value)
        await sendChallengeRedelivery(body, homeworkDeliveryId.value)
        toast.add({ severity: 'success', detail: t('homework.challenge.upload.send.success.detail'), life: 3000 })
        getDelivery()
        showUploadSection.value = false
      } catch (e) {
        toast.add({severity: 'error', detail: t('homework.challenge.upload.send.error.detail'), life: 3000 })
      } finally {
        sending.value = false
      }
    }

    const challengeDelivery = async (body: IChallengeDataDto) => {
      try {
        sending.value = true
        if (myDeliveryId.value) {
          await editChallenge(body, myDeliveryId.value)
        } else {
          await sendChallenge(body, courseId as string, homeworkPlanId as string, homeworkId as string)
        }
        toast.add({
          severity: 'success',
          detail: t('homework.challenge.upload.send.success.detail'),
          life: 3000
        })
        usePeerReview.value ? showPeerReviewComponent(PeerReviewComponent.SHOW) : showPeerReviewComponent(PeerReviewComponent.HIDE)
      } catch (e) {
        toast.add({
          severity: 'error',
          summary: t('homework.challenge.upload.send.error.title'),
          detail: t('homework.challenge.upload.send.error.detail'),
          life: 3000
        })
      } finally {
        sending.value = false
      }
    }

    const correctionDelivery = async (body: ICorrectionDataDto) => {
      try {
        sending.value = true
        await sendCorrection(body, correctionDeliveryId.value as string)
        toast.add({
          severity: 'success',
          summary: t('homework.challenge.upload.send.success.title'),
          detail: t('homework.challenge.peerReview.correction.send.success.detail'),
          life: 3000
        })
        if( usePeerReview.value){
          getDelivery()
          if (challenge.value?.type === HomeworkTypes.REGULAR) changeStep(1)
        }
        correction.value = undefined
      } catch (e) {
        toast.add({
          severity: 'error',
          summary: t('homework.challenge.upload.send.error.title'),
          detail: t('homework.challenge.peerReview.correction.send.error.detail'),
          life: 3000
        })
      } finally {
        sending.value = false
      }
    }

    const showPeerReviewComponent = async(step:number) => {
      await getDelivery()
      if (challenge.value?.type === HomeworkTypes.REGULAR && delivery.value?.status !== 'forwarded') changeStep(step)
    }

    const getDelivery = async () => {
      loading.value = true
      challenge.value = await getHomeworkPlan(courseId as string, homeworkPlanId as string, homeworkId as string)
      delivery.value = await getMyDelivery(homeworkPlanId as string, homeworkId as string)
      homeworkDeliveryId.value = delivery.value?.id as string
      showTimeline.value = !!delivery.value
      loading.value = false
    }

    onMounted(async () =>{
      const coderChallenges = await firebase.fetchValue('peer_review', 'array') as string[]
      usePeerReview.value = coderChallenges.includes(camadaNro)
      getDelivery()
    })

    const showUpload = (delivery: string) => {
      showTimeline.value = false
      uploadMode.value = 'edit'
      myDeliveryId.value = delivery
    }

    const cancelEdit = () => {
      showTimeline.value = true
      uploadMode.value = 'deliver'
    }

    const reDeliverChallenge = () => {
        const element = document.querySelector('.p-dialog-header-icon') as HTMLElement
        element.click()
        showUploadSection.value = true
        toast.add({severity: 'info', detail: t('homework.challenge.reupload.prefinal.success.enabled'),life: 3000})
        isReSendPreFinal.value = true
    }
    return {
      t,
      loading,
      delivery,
      challenge,
      currentStep,
      showCorrectionView,
      correction,
      changeStep,
      usePeerReview,
      challengeDelivery,
      challengeRedelivery,
      correctionDelivery,
      selectDelivery,
      sending,
      DateTime,
      Lock,
      showCriteria,
      setShowCriteria,
      showUpload,
      showTimeline,
      uploadMode,
      cancelEdit,
      myDeliveryId,
      studentData,
      reDeliverChallenge,
      showUploadSection
    }
  }
})
