
import { defineComponent, PropType } from 'vue'

// Components
import Card from '@/components/aero/surfaces/Card.vue'
import ItemInformationLarge from '@/components/aero/surfaces/profile/CardInformationLarge/ItemInformationLarge.vue'

// Models
import { IExperience } from '@/models/newPlatform/profile'

export default defineComponent({
  components: { Card, ItemInformationLarge },
  props: {
    items: { type: Object as PropType<IExperience[]>, required: true },
    isEducation: { type: Boolean, default: false },
    isVisitorView: { type: Boolean, default: false }
  },

  setup(_, { emit }) {
    const handleEdit = (item: IExperience, isEducation: boolean) => {
      emit('onEdit', item, isEducation)
    }

    const handleDelete = (itemId: string) => {
      emit('onDelete', itemId)
    }

    return { handleEdit, handleDelete }
  }
})
