
import { computed, defineComponent, onMounted, reactive, ref, watch } from 'vue'

// Components
import InputWrapper from '@/components/aero/inputs/InputWrapper.vue'
import SelectInput from '@/components/aero/inputs/SelectInput.vue'
import CommunityTag from '@/components/aero/dataDisplay/CommunityTag.vue'
import RichTextEditor from '@/components/aero/inputs/RichTextEditor.vue'
import Button from 'primevue/button'

// Models
import { CommunityTag as Tag } from '@/models/dashboard/community'
import { InputSelect } from '@/models/commons/InputSelect'

// Mocks
import { relatedTopic } from '@/mocks/data/dashboard/community.json'

// Store
import { useCourseStore } from '@/store/modules/course.store'

// Hooks
import useDebounce from '@/hooks/useDebounce'

export default defineComponent({
  components: { InputWrapper, SelectInput, CommunityTag, RichTextEditor, Button },
  props: {
    copies: { type: Object, required: true }
  },
  emits: ['onChangeFilters', 'onChangeNewPost', 'onSearch', 'onCleanSearch'],
  setup(props, { emit }) {
    const course = useCourseStore()

    // TODO: Replace hardcoded freelance label for dynamic related topic of the course
    const communityTags = reactive<Tag[]>([
      { key: props.copies.tags.camada.key, tag: `${props.copies.tags.camada.label} ${course.camadaNro}` },
      { key: props.copies.tags.course.key, tag: `${course.name} ${props.copies.tags.course.label}` },
      { key: props.copies.tags.freelance.key, tag: `${relatedTopic} ${props.copies.tags.freelance.label}` }
    ])

    const selectedFilters = reactive([communityTags[0]])

    const topicOptions = reactive<InputSelect[]>(
      communityTags.map((communityTag) => ({ label: communityTag.tag, value: communityTag.key }))
    )

    /**
     * Ref to topic selected `1`, `2` or `3`
     * - Topic `1` means camada is selected
     * - Topic `2` means Course is selected
     * - Topic `3` means related topic is selected
     */
    const selectedTopic = reactive({ topic: topicOptions[0].value })

    const isTextEditorOpen = ref<boolean>(false)

    const searchByTitle = ref<string>('')

    /**
     * Handle text inside text editor and send value to parent component
     * @param text Text value inside text editor component
     */
    const handleTextEditor: (text: string) => void = (text: string) => {
      emit('onChangeNewPost', 'postContent', text)
    }

    /**
     * Handles filter tags
     */
    const handleFilters: (tag: Tag) => void = (communityTag: Tag) => {
      // Search for index of the tag to be removed

      const tagToRemove = selectedFilters.indexOf(communityTag)

      const isFilterApplied = selectedFilters.some((filter) => filter.key === communityTag.key)

      // Check if the filter is already applied and either removes it or push it

      isFilterApplied ? selectedFilters.splice(tagToRemove, 1) : selectedFilters.push(communityTag)

      // Send filters applied to parent component
      emit('onChangeFilters', selectedFilters)
    }

    /**
     * Discussion topic selector handler
     * Topic can either come as `1`, `2` or `3`
     * - Topic 1 means camada is selected
     * - Topic 2 means Course is selected
     * - Topic 3 means related topic is selected
     */
    const handleSelector: (topic: HTMLInputElement['value']) => void = (topic) => {
      selectedTopic.topic = topic
      emit('onChangeNewPost', 'topic', topic)
    }

    /**
     * Method to toggle open/close of text editor
     */
    const toggleOpenTextEditor = () => {
      isTextEditorOpen.value = !isTextEditorOpen.value
    }

    const handleSearch = () => {
      emit('onSearch', searchByTitle.value)
    }

    const debounceSearch = useDebounce(handleSearch, 1000)

    // Computed
    const textEditorButton = computed(() => ({
      label: !isTextEditorOpen.value ? props.copies.inputPost.publish : props.copies.inputPost.close,
      icon: isTextEditorOpen.value ? 'pi pi-times' : undefined
    }))

    // Call this method on render to add first community tag to applied filters
    onMounted(() => {
      emit('onChangeFilters', selectedFilters)
    })

    // Watchers
    watch(searchByTitle, () => {
      if (searchByTitle.value === '') {
        emit('onCleanSearch')
      } else {
        debounceSearch()
      }
    })

    return {
      topicOptions,
      communityTags,
      textEditorButton,
      handleSelector,
      toggleOpenTextEditor,
      handleFilters,
      selectedFilters,
      isTextEditorOpen,
      handleTextEditor,
      handleSearch,
      searchByTitle
    }
  }
})
