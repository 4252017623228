
import { computed, defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    radius: { type: Number, default: 76, required: false },
    progress: { type: Number, required: true },
    total: { type: Number, required: false },
    stroke: { type: Number, default: 4 },
    color: { type: String as PropType<'purple' | 'success'>, default: 'white' },
    variant: { type: String as PropType<'percentage' | 'points'>, default: 'percentage', required: false }
  },
  setup(props) {
    const normalizedRadius = props.radius - props.stroke
    const circumference = props.radius * 2 * Math.PI
    const strokeDashoffset = computed(() => circumference - (props.progress / 100) * circumference)
    return {
      normalizedRadius,
      circumference,
      strokeDashoffset
    }
  }
})
