import { RouteRecordRaw } from 'vue-router'
import Onboarding from '@/views/onboarding/Onboarding.vue'
import { NewPlatform as Names } from '@/router/route-names'

const OnboardingRoutes: Array<RouteRecordRaw> = [
  {
    path: '',
    component: Onboarding,
    name: Names.ONBOARDING
  }
]

export default OnboardingRoutes
