// API
import { getUserNotifications, markAsReadNotification, getCourses as getCoursesFromAPI } from '@/api/newPlatform/navigation.api'

// Models
import { INotification } from '@/models/navigation/notifications'

/**
 * API request to retrieve the notifications from a certain user.
 * @param userId Logged user id
 * @returns an array of notifications.
 */
export const getNotifications = async (userId: string) => {
  const response: INotification[] = await getUserNotifications(userId)

  return response
}

/**
 * API call to mark a notification as readed.
 * @param userId logged user id
 * @param notificationId logged notifications id
 * @returns confirmation message
 */
export const markAsReadNotificationService = async (userId: string, notificationId: string) => {
  const response = await markAsReadNotification(userId, notificationId)

  return response
}

/**
 * API call to mark a notification as readed.
 * @param userId logged user id
 * @param notifications notifications array to mark as readed
 * @returns confirmation message
 */
export const markAllAsReadNotificationService = async (userId: string, notifications: INotification[]) => {
  const response = await Promise.allSettled(notifications.map(({ _id }) => markAsReadNotification(userId, _id)))

  return response
}

/**
 * API call to get all courses for some user.
 * @param userId logged user id
 * @returns array of courses
 */
export const getCourses = async (userId: string, role: number) => {
  const response = await getCoursesFromAPI(userId, role)

  return response
}
