import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e66fb8c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-file p-mb-3" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FileUpload = _resolveComponent("FileUpload")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FileUpload, {
      ref: "file",
      accept: ".jpg, .jpeg, .png, .pdf, .gzip, .doc, .rar, .zip, .docx",
      fileLimit: 1,
      multiple: false,
      maxFileSize: _ctx.maxFileSize,
      showUploadButton: false,
      showCancelButton: false,
      onSelect: _ctx.select,
      onRemove: _ctx.remove,
      invalidFileSizeMessage: _ctx.t('inputfile.error.message', {maxFileSize: _ctx.maxFileSizeInMB}),
      invalidFileTypeMessage: _ctx.t('inputfile.error.fileLimit.message')
    }, {
      empty: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {}, () => [
          _createElementVNode("div", {
            class: "empty-content",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.choose && _ctx.choose(...args)))
          }, [
            _createElementVNode("img", {
              class: "upload-icon p-mb-4",
              src: require('@/assets/icons/upload-icon.svg')
            }, null, 8, _hoisted_2),
            _createElementVNode("span", {
              class: "title",
              innerHTML: _ctx.t('inputfile.title')
            }, null, 8, _hoisted_3),
            _createElementVNode("p", {
              class: "description",
              innerHTML: _ctx.t('inputfile.description')
            }, null, 8, _hoisted_4)
          ])
        ], true)
      ]),
      _: 3
    }, 8, ["maxFileSize", "onSelect", "onRemove", "invalidFileSizeMessage", "invalidFileTypeMessage"])
  ]))
}