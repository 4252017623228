import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-338ed8ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "language-container p-d-flex p-jc-between" }
const _hoisted_2 = { class: "" }
const _hoisted_3 = { class: "p-mb-2 title-languages" }
const _hoisted_4 = { class: "subtitle-languages" }
const _hoisted_5 = { class: "filtered-container p-d-flex p-jc-between" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "filteredLanguages" }
const _hoisted_8 = { class: "p-text-center" }
const _hoisted_9 = { class: "p-mr-3" }
const _hoisted_10 = ["src", "onClick"]
const _hoisted_11 = { class: "p-pt-5" }
const _hoisted_12 = ["src"]
const _hoisted_13 = {
  key: 0,
  class: "error-msj"
}
const _hoisted_14 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_languages_modal = _resolveComponent("languages-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["p-mt-4", {'error-container': _ctx.showLanguagesError}])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('editProfile.professionalInfo.language.title')), 1),
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('editProfile.professionalInfo.language.description')), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            (_ctx.languages.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languages, (language) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "languages",
                        key: language.value
                      }, [
                        _createElementVNode("p", _hoisted_8, _toDisplayString(language.value) + "-" + _toDisplayString(language.level), 1),
                        _createElementVNode("div", _hoisted_9, [
                          _createElementVNode("img", {
                            src: require('@/assets/icons/delete.svg'),
                            alt: "remove",
                            onClick: ($event: any) => (_ctx.deleteLanguage(language._id))
                          }, null, 8, _hoisted_10)
                        ])
                      ]))
                    }), 128))
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("img", {
            class: "pointer",
            src: require('@/assets/icons/add.svg'),
            alt: "",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openModal && _ctx.openModal(...args)))
          }, null, 8, _hoisted_12)
        ])
      ])
    ], 2),
    (_ctx.showLanguagesError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createElementVNode("img", {
            class: "p3",
            src: require('@/assets/icons/pi-icon-alert-red.svg')
          }, null, 8, _hoisted_14),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('editProfile.professionalInfo.language.incomplete')), 1)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_languages_modal, {
      displayModal: _ctx.displayModal,
      onCloseModal: _ctx.closeModal,
      languagesUser: _ctx.languages
    }, null, 8, ["displayModal", "onCloseModal", "languagesUser"])
  ], 64))
}