import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, renderList as _renderList, withCtx as _withCtx, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f8221994"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "t2 p-my-3" }
const _hoisted_2 = { class: "t2 p-my-3" }
const _hoisted_3 = { class: "t2 p-mb-3 p-mr-3" }
const _hoisted_4 = {
  key: 0,
  class: "contactCard p-my-3"
}
const _hoisted_5 = { class: "t2 p-mb-3" }
const _hoisted_6 = {
  key: 1,
  class: "content__wrapper"
}
const _hoisted_7 = { class: "p-d-flex p-ai-center p-jc-between p-mb-4" }
const _hoisted_8 = { class: "t3" }
const _hoisted_9 = { class: "p-d-flex p-ai-center" }
const _hoisted_10 = { class: "menu_dropdown" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ToTopButton = _resolveComponent("ToTopButton")!
  const _component_ErrorPage = _resolveComponent("ErrorPage")!
  const _component_SkeletonPointCard = _resolveComponent("SkeletonPointCard")!
  const _component_SkeletonCourseInfoCard = _resolveComponent("SkeletonCourseInfoCard")!
  const _component_SkeletonTeacherGroup = _resolveComponent("SkeletonTeacherGroup")!
  const _component_point_card_attendance = _resolveComponent("point-card-attendance")!
  const _component_items_card = _resolveComponent("items-card")!
  const _component_contact_card = _resolveComponent("contact-card")!
  const _component_card = _resolveComponent("card")!
  const _component_SkeletonAttendanceTable = _resolveComponent("SkeletonAttendanceTable")!
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_teacher_table = _resolveComponent("teacher-table")!
  const _component_tutor_table = _resolveComponent("tutor-table")!
  const _component_attendance_table = _resolveComponent("attendance-table")!
  const _component_PlaceholderState = _resolveComponent("PlaceholderState")!
  const _component_two_cols_layout = _resolveComponent("two-cols-layout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ToTopButton),
    (_ctx.isError)
      ? (_openBlock(), _createBlock(_component_ErrorPage, { key: 0 }))
      : (_openBlock(), _createBlock(_component_two_cols_layout, { key: 1 }, {
          aside: _withCtx(() => [
            (_ctx.isLoading)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_SkeletonPointCard),
                  _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.copies.aside.courseTitle), 1),
                  _createVNode(_component_SkeletonCourseInfoCard),
                  (_ctx.isTutor)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.copies.aside.teacherTitle), 1),
                        _createVNode(_component_SkeletonTeacherGroup)
                      ], 64))
                    : _createCommentVNode("", true)
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createVNode(_component_point_card_attendance, {
                    attendance: _ctx.attendanceStatus.items,
                    attendancePercentage: _ctx.getAttendancePerc,
                    isTutor: _ctx.isTutor,
                    class: "p-my-3"
                  }, null, 8, ["attendance", "attendancePercentage", "isTutor"]),
                  _createElementVNode("div", null, [
                    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.copies.aside.courseTitle), 1),
                    _createVNode(_component_items_card, {
                      courseName: _ctx.currentCourse.courseName,
                      commission: _ctx.currentCourse.camadaNro,
                      daysAndHours: _ctx.currentCourse.daysAndHours,
                      startDate: _ctx.currentCourse.startDate,
                      endDate: _ctx.currentCourse.endDate,
                      category: _ctx.currentCourse.category,
                      holidays: _ctx.currentCourse.holidays
                    }, null, 8, ["courseName", "commission", "daysAndHours", "startDate", "endDate", "category", "holidays"])
                  ]),
                  (_ctx.isTutor)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.copies.aside.teacherTitle), 1),
                        _createVNode(_component_card, null, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentCourse.teachers, (teacher) => {
                              return (_openBlock(), _createBlock(_component_contact_card, {
                                key: teacher._id,
                                name: teacher.fullName,
                                category: teacher.category,
                                imageLink: teacher.avatar,
                                chatLink: teacher.chatLink,
                                profileLink: teacher.profileLink,
                                class: "contactCard__item"
                              }, null, 8, ["name", "category", "imageLink", "chatLink", "profileLink"]))
                            }), 128))
                          ]),
                          _: 1
                        })
                      ]))
                    : _createCommentVNode("", true)
                ], 64))
          ]),
          content: _withCtx(() => [
            (_ctx.isLoading)
              ? (_openBlock(), _createBlock(_component_SkeletonAttendanceTable, { key: 0 }))
              : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.copies.contentTitle), 1),
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(_component_SearchInput, {
                        onOnUpdateSearch: _ctx.handleSearch,
                        placeholderText: _ctx.copies.search,
                        style: {"width":"150px"}
                      }, null, 8, ["onOnUpdateSearch", "placeholderText"]),
                      _createElementVNode("div", _hoisted_10, [
                        _createVNode(_component_Button, {
                          label: 
                  _ctx.isTutor
                    ? _ctx.selectedOptionLabel(_ctx.sortingOptionsTutor.find((op) => op.value === _ctx.selectedSortTutor)?.label)
                    : _ctx.selectedOptionLabel(_ctx.sortingOptionsTeacher.find((op) => op.value === _ctx.selectedSortTeacher)?.label)
                ,
                          class: _normalizeClass(["p-button-filter p-button-sm menu__label text-light-gray", _ctx.menuIsOpen ? 'menu--open' : '']),
                          icon: "pi pi-angle-down",
                          iconPos: "right",
                          onClick: _ctx.toggle,
                          "aria-haspopup": "true",
                          "aria-controls": "overlay_menu"
                        }, null, 8, ["label", "class", "onClick"]),
                        _createVNode(_component_Menu, {
                          id: "overlay_menu",
                          class: "overlay_menu_challenges",
                          ref: "menu",
                          model: _ctx.isTutor ? _ctx.sortingOptionsTutor : _ctx.sortingOptionsTeacher,
                          popup: true,
                          onShow: _ctx.menuToggle,
                          onHide: _ctx.menuToggle
                        }, null, 8, ["model", "onShow", "onHide"])
                      ])
                    ])
                  ]),
                  (_ctx.students.length && _ctx.studentsByGroup.length)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _withDirectives(_createElementVNode("div", null, [
                          (!_ctx.isTutor)
                            ? (_openBlock(), _createBlock(_component_teacher_table, {
                                key: 0,
                                students: _ctx.students,
                                tutors: _ctx.tutors,
                                columns: _ctx.columns,
                                schedules: _ctx.schedules,
                                filterTitle: _ctx.selectedSortTeacher === 'tutors' ? _ctx.copies.tutor : _ctx.copies.students,
                                filterSelected: _ctx.selectedSortTeacher,
                                onOnChange: _ctx.handleToggleAttendance
                              }, null, 8, ["students", "tutors", "columns", "schedules", "filterTitle", "filterSelected", "onOnChange"]))
                            : (_openBlock(), _createBlock(_component_tutor_table, {
                                key: 1,
                                userId: _ctx.userId,
                                students: _ctx.students,
                                classmates: _ctx.studentsByGroup,
                                columns: _ctx.columns,
                                schedules: _ctx.schedules,
                                filterTitle: _ctx.copies.students,
                                filterSelected: _ctx.selectedSortTutor,
                                onOnChange: _ctx.handleToggleAttendance
                              }, null, 8, ["userId", "students", "classmates", "columns", "schedules", "filterTitle", "filterSelected", "onOnChange"]))
                        ], 512), [
                          [_vShow, !_ctx.search.isActive]
                        ]),
                        (_ctx.search.isActive)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              (_ctx.search.users.length > 0)
                                ? (_openBlock(), _createBlock(_component_attendance_table, {
                                    key: 0,
                                    isTutor: _ctx.isTutor,
                                    data: _ctx.search.users,
                                    columns: _ctx.columns,
                                    filterTitle: _ctx.copies.results,
                                    schedules: _ctx.schedules,
                                    attendances: _ctx.search.attendance,
                                    onOnChange: _ctx.handleToggleAttendance
                                  }, null, 8, ["isTutor", "data", "columns", "filterTitle", "schedules", "attendances", "onOnChange"]))
                                : (_openBlock(), _createBlock(_component_PlaceholderState, {
                                    key: 1,
                                    isEmpty: true,
                                    role: 3,
                                    customCopies: { line1: _ctx.copies.emptyState }
                                  }, null, 8, ["customCopies"]))
                            ], 64))
                          : _createCommentVNode("", true)
                      ], 64))
                    : _createCommentVNode("", true)
                ]))
          ]),
          _: 1
        }))
  ], 64))
}