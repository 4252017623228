<template>
  <g>
    <path
      d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M10 6L6 10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10 10L6 6" stroke-linecap="round" stroke-linejoin="round" />
  </g>
</template>