
import { defineComponent, PropType, watch, ref } from 'vue'

import { ClassUser } from '@/models/classes/class'
import ClassCardContent from './ClassCardContent.vue'
import ClassCardButtons from './ClassCardButtons.vue'
import Card from '../Card.vue'

// Amplitude
import { ClassesEvent } from '@/amplitude/constants'
import { trackEvent } from '@/amplitude/actions'

export default defineComponent({
  components: {
    Card,
    ClassCardButtons,
    ClassCardContent
  },
  name: 'ClassCard',
  props: {
    classData: {
      type: Object as PropType<ClassUser>,
      required: true
    },
    nextClass: {
      type: Object as PropType<ClassUser>,
      required: false
    },
    afterclasses: {
      type: Array as PropType<ClassUser[]>,
      required: true
    },
    optionsListClasses: {
      type: Object,
      required: true
    },
    content: {
      type: Object,
      required: true
    },
    toolTips: {
      type: Object,
      required: true
    },
    role: { type: Number, required: true },
    endDate: { type: Date, required: true },
    handleOpenForm: {
      type: Function,
      required: false,
      default: () => null
    },
    handleOpenDialog: {
      type: Function,
      required: false,
      default: () => null
    },
    becaSuspended: {
      type: Boolean,
      required: true
    },
    useCoderChallenge:{
      type: Boolean,
      required: true
    },
    handleEnableRate: { type: Function }
  },
  setup(props, context) {
    const localAfterClasses = ref<ClassUser[]>(props.afterclasses)
    const handleAfterClass = () => {
      trackEvent(ClassesEvent.START_BOOKING_AFTERCLASS, { origin: 'class card kebab' })
      props.handleOpenForm()
      context.emit('createAfterClass', props.classData.schedule.stage, '')
    }

    const handleEditAfterclass = (stageId: string, complementaryId: string) => {
      trackEvent(ClassesEvent.EDIT_AFTERCLASS, { origin: 'class card kebab' })
      context.emit('editAfterclass', stageId, complementaryId)
      props.handleOpenForm()
    }

    // sending the stageId in case the user regrets and wants to edit the afterclass
    const handleDeleteAfterclass = (stageId: string, complementaryId: string) => {
      context.emit('deleteAfterclass', stageId, complementaryId)
      props.handleOpenDialog()
    }

    watch(props, () => {
      localAfterClasses.value = props.afterclasses
    })

    return {
      localAfterClasses,
      handleAfterClass,
      handleEditAfterclass,
      handleDeleteAfterclass
    }
  }
})
