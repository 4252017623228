import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SkeletonText = _resolveComponent("SkeletonText")!
  const _component_SkeletonInput = _resolveComponent("SkeletonInput")!
  const _component_card = _resolveComponent("card")!

  return (_openBlock(), _createBlock(_component_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_SkeletonText, { length: 14 }),
      _createVNode(_component_SkeletonInput, { class: "p-mt-3" })
    ]),
    _: 1
  }))
}