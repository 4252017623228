import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-157f634a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p1 alertCard__title" }
const _hoisted_2 = { class: "p2 p-mt-1 p-mb-3 text-light-gray alertCard__description" }
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`p-p-4 text-white border border-${_ctx.borderColorClass} alertCard`)
  }, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.title), 1),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.description), 1),
    (_ctx.anchor)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          class: "p1 bold text-primary alertCard__anchor",
          href: _ctx.anchor.link,
          target: "_blank"
        }, _toDisplayString(_ctx.anchor.text), 9, _hoisted_3))
      : _createCommentVNode("", true)
  ], 2))
}