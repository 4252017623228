import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_small_card_course = _resolveComponent("small-card-course")!
  const _component_card = _resolveComponent("card")!

  return (_openBlock(), _createBlock(_component_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_small_card_course, {
        text: `${_ctx.copies.courseEnded.student} ${_ctx.courseName}!`
      }, null, 8, ["text"]),
      (_ctx.link)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            class: "p-button primary full-width p-mt-4",
            href: _ctx.link,
            target: "_blank",
            rel: "noopener noreferrer"
          }, _toDisplayString(_ctx.copies.courseEnded.certificate), 9, _hoisted_1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}