import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RadioButton = _resolveComponent("RadioButton")!
  const _component_card = _resolveComponent("card")!

  return (_ctx.contained)
    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createBlock(_component_card, {
          paddingSm: "",
          key: option.key,
          class: "p-mb-3"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_RadioButton, {
              id: option.key,
              name: _ctx.name,
              value: option.value,
              modelValue: _ctx.optionValue,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.optionValue) = $event)),
              onInput: _ctx.handleUpdateValue,
              onChange: _ctx.handleUpdateValue,
              disabled: option.disabled
            }, null, 8, ["id", "name", "value", "modelValue", "onInput", "onChange", "disabled"]),
            _createElementVNode("label", {
              for: option.key,
              class: _normalizeClass(["p1 p-ml-3", option.disabled ? 'text-light-gray' : ''])
            }, _toDisplayString(option.label), 11, _hoisted_1)
          ]),
          _: 2
        }, 1024))
      }), 128))
    : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createElementBlock("div", {
          key: option.key,
          class: "p-mb-3"
        }, [
          _createVNode(_component_RadioButton, {
            id: option.key,
            name: _ctx.name,
            value: option.value,
            modelValue: _ctx.optionValue,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.optionValue) = $event)),
            onInput: _ctx.handleUpdateValue,
            onChange: _ctx.handleUpdateValue,
            disabled: option.disabled
          }, null, 8, ["id", "name", "value", "modelValue", "onInput", "onChange", "disabled"]),
          _createElementVNode("label", {
            for: option.key,
            class: _normalizeClass(["p1 p-ml-3", option.disabled ? 'text-light-gray' : ''])
          }, _toDisplayString(option.label), 11, _hoisted_2)
        ]))
      }), 128))
}