import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "t2 p-mt-3 p-ml-2" }
const _hoisted_2 = { class: "p-mt-3 t2" }
const _hoisted_3 = { class: "tab-title p-m-0 p-p-0" }
const _hoisted_4 = { class: "tab-title p-m-0 p-p-0" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "p-mb-2 t2 medium" }
const _hoisted_7 = { class: "p-d-block text-white p1" }
const _hoisted_8 = { class: "p-mt-4 p2" }
const _hoisted_9 = {
  href: "https://www.coderhouse.com/legales",
  class: "p-button tertiary p2",
  target: "_blank",
  rel: "noopener noreferrer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Toast = _resolveComponent("Toast")!
  const _component_ErrorPage = _resolveComponent("ErrorPage")!
  const _component_breadcrumb = _resolveComponent("breadcrumb")!
  const _component_SkeletonCourseInfoCard = _resolveComponent("SkeletonCourseInfoCard")!
  const _component_SkeletonCardAlert = _resolveComponent("SkeletonCardAlert")!
  const _component_CourseInformation = _resolveComponent("CourseInformation")!
  const _component_SmallCardAlert = _resolveComponent("SmallCardAlert")!
  const _component_SkeletonTableFilter = _resolveComponent("SkeletonTableFilter")!
  const _component_PlaceholderState = _resolveComponent("PlaceholderState")!
  const _component_ComisionTab = _resolveComponent("ComisionTab")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_CoursesTab = _resolveComponent("CoursesTab")!
  const _component_TabView = _resolveComponent("TabView")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_two_cols_layout = _resolveComponent("two-cols-layout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Toast),
    (_ctx.courseError)
      ? (_openBlock(), _createBlock(_component_ErrorPage, { key: 0 }))
      : (_openBlock(), _createBlock(_component_two_cols_layout, { key: 1 }, {
          aside: _withCtx(() => [
            _createVNode(_component_breadcrumb, {
              class: "p-mt-3 p-ml-2",
              home: { label: _ctx.studentChangeCopies.breadcrumb.home, to: '/profile' },
              model: [
          {
            label: _ctx.studentChangeCopies.breadcrumb.model,
            to: `/profile/change-course`
          }
        ]
            }, null, 8, ["home", "model"]),
            _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.studentChangeCopies.currentCourse), 1),
            (_ctx.loading)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_SkeletonCourseInfoCard),
                  _createVNode(_component_SkeletonCardAlert)
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createVNode(_component_CourseInformation, {
                    class: "p-mt-2 p-ml-2",
                    courseName: _ctx.currentCourse.courseName,
                    imgCategory: _ctx.currentCourse.category,
                    camadaNro: _ctx.currentCourse.camadaNro,
                    courseDaysAndHours: _ctx.currentCourse.daysAndHours,
                    courseStartDate: _ctx.currentCourse.startDate,
                    courseEndDate: _ctx.currentCourse.endDate
                  }, null, 8, ["courseName", "imgCategory", "camadaNro", "courseDaysAndHours", "courseStartDate", "courseEndDate"]),
                  (_ctx.displayCardAlert)
                    ? (_openBlock(), _createBlock(_component_SmallCardAlert, {
                        key: 0,
                        class: "p-mt-2 p-ml-2",
                        title: _ctx.penaltyCardTitle,
                        description: _ctx.studentChangeCopies.alert.description,
                        anchor: { link: _ctx.studentChangeCopies.alert.anchor.link, text: _ctx.studentChangeCopies.alert.anchor.text }
                      }, null, 8, ["title", "description", "anchor"]))
                    : _createCommentVNode("", true)
                ], 64))
          ]),
          content: _withCtx(() => [
            _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.studentChangeCopies.comision.title), 1),
            (!_ctx.reenrollStatus.alreadyRequested)
              ? (_openBlock(), _createBlock(_component_TabView, { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_TabPanel, null, {
                      header: _withCtx(() => [
                        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.studentChangeCopies.header), 1)
                      ]),
                      default: _withCtx(() => [
                        (_ctx.loading)
                          ? (_openBlock(), _createBlock(_component_SkeletonTableFilter, { key: 0 }))
                          : (_ctx.inconmingClassesError)
                            ? (_openBlock(), _createBlock(_component_PlaceholderState, {
                                key: 1,
                                hasError: true
                              }))
                            : (_openBlock(), _createBlock(_component_ComisionTab, {
                                key: 2,
                                courseId: _ctx.currentCourse._id,
                                leadTeacherId: _ctx.currentCourse.leadTeacherId,
                                incomingClasses: _ctx.changeComisionClasses,
                                penaltyReenroll: _ctx.penaltyReenroll,
                                submitButtonLabel: _ctx.buttonLabel
                              }, null, 8, ["courseId", "leadTeacherId", "incomingClasses", "penaltyReenroll", "submitButtonLabel"]))
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_TabPanel, null, {
                      header: _withCtx(() => [
                        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.studentChangeCopies.course.title), 1)
                      ]),
                      default: _withCtx(() => [
                        (!_ctx.loading)
                          ? (_openBlock(), _createBlock(_component_CoursesTab, {
                              key: 0,
                              leadTeacherId: _ctx.currentCourse.leadTeacherId,
                              hasToPay: _ctx.reenrollStatus.flags.hasToPay,
                              courseId: _ctx.currentCourse._id,
                              penaltyReenroll: _ctx.penaltyReenroll
                            }, null, 8, ["leadTeacherId", "hasToPay", "courseId", "penaltyReenroll"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_Dialog, {
              visible: _ctx.displayDialog,
              "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.displayDialog) = $event)),
              modal: true,
              onHide: _ctx.handleCancel
            }, {
              header: _withCtx(() => [
                (_ctx.currentCourse.courseName)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.currentCourse.courseName), 1),
                      _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.studentChangeCopies.dialog.subtitle), 1)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              footer: _withCtx(() => [
                _createElementVNode("a", _hoisted_9, _toDisplayString(_ctx.studentChangeCopies.dialog.tAndc), 1),
                _createVNode(_component_Button, {
                  label: _ctx.studentChangeCopies.dialog.discard,
                  class: "secondary",
                  onClick: _ctx.handleDiscardRequest
                }, null, 8, ["label", "onClick"])
              ]),
              default: _withCtx(() => [
                _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.studentChangeCopies.dialog.message), 1)
              ]),
              _: 1
            }, 8, ["visible", "onHide"])
          ]),
          _: 1
        }))
  ], 64))
}