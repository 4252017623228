
import { defineComponent } from 'vue'
import LayoutMain from '@/layouts/wrappers/LayoutMain.vue'
import { themeStore } from '@/store/modules/themes.store'
import { useUserSessionStore } from '@/store/modules/user-session.store'

export default defineComponent({
  components: {
    LayoutMain
  },
  setup () {
    const theme = themeStore()
    const user = useUserSessionStore()
    const viewMode =  user.darkMode ? 'dark' : 'old'
    theme.changeDefault('dark')
  }
})
