
import { defineComponent, PropType, computed } from 'vue'
import { DateTime } from 'luxon'

// Icons
import Icon from '@/components/aero/icon/Icon.vue'
import { Info } from '@/components/aero/icon/templates'

// Components
import Button from 'primevue/button'
import FileTag from '@/components/aero/dataDisplay/FileTag.vue'
import Avatar from '@/components/aero/dataDisplay/Avatar.vue'

interface IFile {
  text: string
  type?: string
  size?: string
}

export default defineComponent({
  components: { Avatar, Icon, Info, Button, FileTag },
  props: {
    messageType: { type: String as PropType<'sent' | 'received'>, default: 'sent' },
    canEdit: { type: Boolean, default: false },
    id: { type: String, required: true, default: '' },
    message: { type: String, required: true, default: '' },
    userName: { type: String, required: true, default: '' },
    avatar: { type: String, required: true, default: '' },
    file: { type: Object as PropType<IFile> },
    link: { type: Object as PropType<IFile> },
    copies: { type: Object },
    date: { type: Number, required: true }
  },
  setup(props, { emit }) {
    emit(`edit-message-${props}`)

    const setDateTime = computed(() => {
      /* eslint-disable */
      return DateTime.fromMillis(props.date).toFormat("dd 'de' LLLL HH:mm").toLocaleString()
    })

    return { setDateTime }
  }
})
