import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-695945d2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-d-flex p-ai-center p-jc-between full-width small-card" }
const _hoisted_2 = { class: "p-d-flex p-ai-center" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "p-pl-3 p2" }
const _hoisted_5 = { class: "p2 text-light-gray p-mb-2" }
const _hoisted_6 = { class: "p2 bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: `/assets/icons/categories/${_ctx.courseCategory}.svg`,
        alt: _ctx.courseCategory
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.title), 1),
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.subtitle), 1)
      ])
    ])
  ]))
}