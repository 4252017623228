import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-754b9c92"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "t3 text-white" }
const _hoisted_2 = { class: "p1 text-light-gray p-mt-3 p-mb-4" }
const _hoisted_3 = { class: "p-my-2" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 0,
  class: "p-d-flex p-jc-end p-ai-center button__group p-py-3"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "p-mb-2 t2 medium" }
const _hoisted_8 = { class: "p-d-block text-white p1" }
const _hoisted_9 = { class: "p-mt-4 p2" }
const _hoisted_10 = {
  href: "https://www.coderhouse.com/legales",
  class: "p-button tertiary p2",
  target: "_blank",
  rel: "noopener noreferrer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_toast = _resolveComponent("toast")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_input_wrapper = _resolveComponent("input-wrapper")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_toast),
    _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.copies.title), 1),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.copies.subtitle), 1),
    _createVNode(_component_input_wrapper, {
      label: _ctx.copies.textarea.label,
      error: _ctx.commentError
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Textarea, {
          id: "unsubscribe_textarea",
          placeholder: _ctx.copies.textarea.placeholder,
          modelValue: _ctx.comment,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.comment) = $event)),
          autoResize: true,
          rows: "5",
          cols: "30"
        }, null, 8, ["placeholder", "modelValue"])
      ]),
      _: 1
    }, 8, ["label", "error"]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (tag, index) => {
        return (_openBlock(), _createElementBlock("button", {
          key: index,
          class: "btnChip p3 text-light-gray border-radius",
          onClick: ($event: any) => (_ctx.handleClick(tag))
        }, _toDisplayString(tag), 9, _hoisted_4))
      }), 128))
    ]),
    (_ctx.DisplayFormBtns)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_Button, {
            onClick: _ctx.handleCancel,
            label: _ctx.copies.btn.cancel,
            disabled: _ctx.loadingSubmit,
            class: "tertiary p-mr-2"
          }, null, 8, ["onClick", "label", "disabled"]),
          _createVNode(_component_Button, {
            onClick: _ctx.handleSubmit,
            label: _ctx.copies.btn.submit,
            loading: _ctx.loadingSubmit,
            disabled: _ctx.loadingSubmit || _ctx.disableSubmit,
            class: "primary"
          }, null, 8, ["onClick", "label", "loading", "disabled"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_Dialog, {
      visible: _ctx.displayDialog,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.displayDialog) = $event)),
      modal: true,
      onHide: _ctx.handleCancel
    }, {
      header: _withCtx(() => [
        (_ctx.course.name)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("h3", _hoisted_7, _toDisplayString(_ctx.course.camadaNro) + " - " + _toDisplayString(_ctx.course.name), 1),
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.copies.dialog.subtitle), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      footer: _withCtx(() => [
        _createElementVNode("a", _hoisted_10, _toDisplayString(_ctx.copies.dialog.tAndc), 1),
        _createVNode(_component_Button, {
          label: _ctx.copies.dialog.discard,
          class: "secondary",
          loading: _ctx.loadingSubmit,
          onClick: _ctx.handleDiscardRequest
        }, null, 8, ["label", "loading", "onClick"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.copies.dialog.message), 1)
      ]),
      _: 1
    }, 8, ["visible", "onHide"])
  ], 64))
}