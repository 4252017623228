
import { defineComponent, reactive, ref, PropType, onUpdated,  watch } from 'vue'
import { useVuelidate, ValidationArgs } from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
// import ChallengeHeader from '../upload/ChallengeHeader.vue'
import InputEditor from '@/components/commons/InputEditor.vue'
import ChallengePreview from './ChallengePreview.vue'
import ChallengeEvaluation from './ChallengeEvaluation.vue'
import ChallengeStudent from './ChallengeStudent.vue'
import { ICorrection, ICriteria } from '@/models/peer-review/homeworkPlan'
import { ITutorChallengeStudents } from '@/models/peer-review/challenge'
import { useI18n } from 'vue-i18n'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from 'primevue/useconfirm'
import { HomeworkTypes } from '@/utils/constants'

export default defineComponent({
  emits: ['send'],
  components: {
    InputEditor,
    ChallengePreview,
    ChallengeEvaluation,
    // ChallengeHeader,
    ChallengeStudent
  },
  props: {
    correction: { type: Object as PropType<ICorrection>, required: true },
    student: { type: Object as PropType<ITutorChallengeStudents>, required: true },
    usePeerReview: Boolean,
    isTutor: Boolean,
    sending: Boolean,
    evaluationCriteria: { type: Object as PropType<ICriteria[]>, required: true },
    type: String,
    evaluations: { type: Array, default: () => [] }
  },
  setup(props, { emit }) {
    const confirm = useConfirm()
    const toast = useToast()
    const { t } = useI18n()
    const commentWithoutQuill = ref<string>('')
    const state = reactive({
      evaluation: {},
      comment: '',
      grade: props.type === 'regular' || props.type === 'complementary' ? 10 : 0
    })
    const listTypes = reactive({
      final: Array.from(
        {
          length: 10
        },
        (e, index) => ({
          value: index + 1,
          label: index + 1 + ''
        })
      ),
      preFinal: [
        {
          value: 4,
          label: t('homework.challenge.peerReview.correction.low')
        },
        {
          value: 7,
          label: t('homework.challenge.peerReview.correction.medium')
        },
        {
          value: 10,
          label: t('homework.challenge.peerReview.correction.high')
        }
      ]
    })

    const sent = ref<boolean>(false)
    const rules: ValidationArgs = {
      comment: { required, minLength: minLength(10) },
      grade: { required }
    }
    const v$ = useVuelidate(rules, state)

    const sendApprove = ref<boolean>(false)
    const sendRequest = ref<boolean>(false)
    const disabledApprove = ref<boolean>(true)

    const send = async (approve: boolean) => {
      sent.value = true
      await v$.value.$validate()
      
      if (v$.value.$invalid) return toast.add({ severity: 'error', detail: t('homework.inputeditor.empty.field'), life: 3000 })
      if (approve) {
        sendApprove.value = approve
        if (props.type === 'preFinal' && state.grade < 7) approve = false
        emit('send', state, approve)
      } else {
        const cancelModals = [
        {// regular challenge
          group: 'global',
          message: t('homework.challenge.peerReview.correction.tutor.modal.message.requestOtherDelivery'),
          header: t('homework.challenge.peerReview.correction.tutor.modal.header.requestOtherDelivery'),
          acceptLabel: t('homework.challenge.peerReview.correction.tutor.requestOtherDelivery'),
          rejectLabel: t('button.cancel'),
          accept: () => {
            sendRequest.value = true
            return emit('send', state, approve)
          }
        },
        {// complemenary challenge
          group: 'global',
          message: t('homework.challenge.complementary.correction.tutor.modal.message'),
          header: t('homework.challenge.complementary.correction.tutor.modal.header'),
          acceptLabel: t('button.send'),
          rejectLabel: t('button.cancel'),
          accept: () => {
            sendRequest.value = true
            const auxData = {
              evaluation: state.evaluation,
              comment: state.comment
            }
            return emit('send', auxData, approve, HomeworkTypes.COMPLEMENTARY)
          }
        }]
        return confirm.require(props.type === HomeworkTypes.COMPLEMENTARY? cancelModals[1]:cancelModals[0])
      }
    }

    onUpdated(() => {
      if (!props.sending) {
        sendApprove.value = props.sending
        sendRequest.value = props.sending
      }
    })

      watch(state, () => {
      if(state.grade !== 0 && commentWithoutQuill.value.length > 10 && Object.values(state.evaluation).every((o) => o !== null)) {
        disabledApprove.value = false
      }else {
        disabledApprove.value = true
      }
      })

    const getComment = (msg:string) => (commentWithoutQuill.value = msg)
    return {
      t,
      v$,
      sent,
      state,
      sendApprove,
      sendRequest,
      send,
      listTypes,
      disabledApprove,
      getComment
    }
  }
})
