
import { defineComponent, ref, reactive, toRefs, onMounted, shallowRef } from 'vue'
import { ChallengeHistoryStates, HomeworkTypes } from '@/utils/constants'
import DynamicCard from '@/components/commons/DynamicCard.vue'
import { formatTime, formatDate } from '@/utils/dates'
import { useI18n } from 'vue-i18n'
import { DateTime } from 'luxon'
import IconBase from '@/components/commons/IconBase.vue'
import Users from '@/components/commons/icon-templates/Users.vue'
import Target from '@/components/commons/icon-templates/Target.vue'
import CustomBreadcrumb from '@/components/commons/Breadcrumb.vue'

export default defineComponent({
  emits: ['change'],
  components: {
    DynamicCard,
    IconBase,
    Users,
    Target,
    CustomBreadcrumb
  },
  props: {
    challenge: {
      type: Object,
      default: null
    },
    delivery: {
      type: Object,
      default: null
    },
    currentStep: {
      type: Number,
      default: 0
    },
    orderClass: {
      type: Number,
      default: 0
    },
    usePeerReview: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n()
    const { delivery } = toRefs(props)
    const status = ref<string>()
    const lastEvent = delivery.value && [...delivery.value?.events].shift()
    const home = ref({ label: t('homework.challenge.breadcrumb.challenges') })
    const items = ref<Array<unknown>>([])
    const myDeliveryColor = ref<string>('#BEBEBE')
    const myReviewsColor = ref<string>('#BEBEBE')

    if (!lastEvent) {
      if (DateTime.now() > DateTime.fromISO(props.challenge.expirationDate)) {
        status.value = ChallengeHistoryStates.EXPIRED
      } else {
        status.value = ChallengeHistoryStates.PENDING_DELIVERY
      }
    } else {
      status.value = lastEvent?.status
    }

    onMounted(() => {
      if (props.orderClass !== 0 && items.value.length === 0) {
        const label = props.orderClass < 10 ? `0${props.orderClass}` : props.orderClass
        items.value.push({ label: `${t('homework.challenge.peerReview.correction.tutor.studentsList.class')} ${label}` })
      }
    })

    const day = ref<string>()
    const hour = ref<string>()
    const correctionsExpiration = ref<string>()
    const deliveryExpiration = ref<string>()
    const isUnavailable = ref<boolean>(false)

    switch (status.value) {
      case 'pending_delivery':
        day.value = formatDate(props.challenge.expirationDate)
        hour.value = formatTime(props.challenge.classDateEnd)
        deliveryExpiration.value = t(`homework.progress.status.${status.value}`, { date: day.value, time: hour.value })
        correctionsExpiration.value = t('homework.progress.status.unavailable')
        isUnavailable.value = true
        myDeliveryColor.value = '#EA90FF'
        break
      case 'sent':
        day.value = formatDate(lastEvent.createdAt)
        hour.value = formatTime(lastEvent.createdAt)
        deliveryExpiration.value = t(`homework.progress.status.${status.value}`, { date: day.value, time: hour.value })
        day.value = formatDate(props.challenge.expirationDate)
        hour.value = formatTime(props.challenge.classDateEnd)
        correctionsExpiration.value = t('homework.progress.status.pending_feedback', { date: day.value, time: hour.value })
        myDeliveryColor.value = '#EA90FF'
        myReviewsColor.value = '#EA90FF'
        break
      case 'delivered':
        day.value = formatDate(lastEvent.createdAt)
        hour.value = formatTime(lastEvent.createdAt)
        deliveryExpiration.value = t(`homework.progress.status.${status.value}`, { date: day.value, time: hour.value })
        day.value = formatDate(props.challenge.expirationDate)
        hour.value = formatTime(props.challenge.classDateEnd)
        correctionsExpiration.value = t('homework.progress.status.pending_feedback', { date: day.value, time: hour.value })
        myDeliveryColor.value = '#83AEFB'
        myReviewsColor.value = '#EA90FF'
        break
      case 'claimedForwarding':
        deliveryExpiration.value = t(`homework.progress.status.${status.value}`)
        myDeliveryColor.value = '#EA90FF'
        correctionsExpiration.value = t('homework.progress.status.completed')
        myReviewsColor.value = '#82DB91'
        isUnavailable.value = true
        break
      case 'forwarded':
        day.value = formatDate(delivery.value.shouldBeReviewUntil)
        hour.value = formatTime(delivery.value.shouldBeReviewUntil)
        deliveryExpiration.value = t(`homework.progress.status.${status.value}`, { date: day.value, time: hour.value })
        myDeliveryColor.value = '#83AEFB'
        correctionsExpiration.value = t('homework.progress.status.completed')
        myReviewsColor.value = '#82DB91'
        isUnavailable.value = true
        break
      case 'approved':
        deliveryExpiration.value = t(`homework.progress.status.${status.value}`)
        myDeliveryColor.value = '#82DB91'
        isUnavailable.value = true
        correctionsExpiration.value = t('homework.progress.status.completed')
        myReviewsColor.value = '#82DB91'
        break
      case 'reviewed':
        deliveryExpiration.value = t(`homework.progress.status.${status.value}`)
        myDeliveryColor.value = '#82DB91'
        isUnavailable.value = true
        correctionsExpiration.value = t('homework.progress.status.completed')
        myReviewsColor.value = '#82DB91'
        break
      case 'disapproved':
        deliveryExpiration.value = t(`homework.progress.status.${status.value}`)
        myDeliveryColor.value = '#FF896F'
        break
      case 'expired':
        deliveryExpiration.value = t('homework.progress.status.expired')
        correctionsExpiration.value = t('homework.progress.status.expired')
        isUnavailable.value = true
        myDeliveryColor.value = '#FF896F'
        myReviewsColor.value = '#FF896F'
        break
      default:
        correctionsExpiration.value = t('homework.progress.status.expired')
        isUnavailable.value = true
        break
    }

    const state = reactive({
      steps: [
        {
          title: t('homework.progress.myDelivery.title'),
          expiration: deliveryExpiration.value,
          visible: true,
          icon: shallowRef(Target),
          descriptionColor: myDeliveryColor.value
        },
        {
          title: t('homework.progress.peerReview.title'),
          expiration: correctionsExpiration.value,
          isUnavailable: isUnavailable.value,
          visible: props.challenge.type === HomeworkTypes.REGULAR && props.usePeerReview,
          icon: shallowRef(Users),
          descriptionColor: myReviewsColor.value
        }
      ]
    })

    const changeStep = (step: number) => {
      emit('change', step)
    }

    return { t, steps: state.steps, changeStep, home, items }
  }
})
