import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b73b6114"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-profile" }
const _hoisted_2 = { class: "list-profile__tutor p-py-3" }
const _hoisted_3 = {
  key: 0,
  class: "list-profile__students p-mt-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_profile_card_small = _resolveComponent("profile-card-small")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_profile_card_small, {
        name: _ctx.tutor.fullName,
        category: _ctx.tutor.category,
        imageLink: _ctx.tutor.avatar,
        size: "large",
        link: _ctx.tutor.link
      }, null, 8, ["name", "category", "imageLink", "link"])
    ]),
    (_ctx.students?.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.students, (student) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: student._id
            }, [
              (student?.fullName !== ' ')
                ? (_openBlock(), _createBlock(_component_profile_card_small, {
                    key: 0,
                    name: student.fullName,
                    category: student.personalGoal,
                    imageLink: student.avatar,
                    link: student.link
                  }, null, 8, ["name", "category", "imageLink", "link"]))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}