// Vendor
import { defineStore } from 'pinia'

// Models
import { ICourse, Course } from '@/models/course-session'

const courseSessionDefault = {
  id: '',
  camadaNro: '',
  camadaId: '',
  name: '',
  sharedFolderLink: '',
  teachersSharedFolderLink: ''
}
const COURSE_KEY = 'course-session'

const getStoredCourse = () => {
  const localCourse = localStorage.getItem(COURSE_KEY)
  const storedCourse = localCourse ? JSON.parse(localCourse) : courseSessionDefault

  return new Course(storedCourse)
}

export const useCourseStore = defineStore({
  // unique id of the store across your application
  id: 'course-store',
  state() {
    const storedCourse = getStoredCourse()
    const courseSession: ICourse = storedCourse

    return courseSession
  },
  actions: {
    setCourse(course: ICourse) {
      this.id = course.id
      this.camadaNro = course.camadaNro
      this.camadaId = course.camadaId
      this.name = course.name
      this.sharedFolderLink = course.sharedFolderLink
      this.teachersSharedFolderLink = course.teachersSharedFolderLink
      this.isEnded = course.isEnded
      this.isStarted = course.isStarted
      this.isInProgress = course.isInProgress
      this.leaderboard = course.leaderboard
      this.hasCoderAsk = course.hasCoderAsk
      this.hasCoderAskActive = course.hasCoderAskActive

      localStorage.setItem(
        COURSE_KEY,
        JSON.stringify({
          id: course.id,
          camadaNro: course.camadaNro,
          camadaId: course.camadaId,
          name: course.name,
          sharedFolderLink: course.sharedFolderLink,
          teachersSharedFolderLink: course.teachersSharedFolderLink,
          isEnded: course.isEnded,
          isStarted: course.isStarted,
          isInProgress: course.isInProgress
          // leaderboard: course.leaderboard
        })
      )
    },

    clearStore() {
      this.id = ''
      this.camadaNro = ''
      this.camadaId = ''
      this.name = ''
      this.leaderboard = []
    }
  }
})
