import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-76fad40e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "t2 text-white"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_course_list = _resolveComponent("course-list")!
  const _component_training_view = _resolveComponent("training-view")!
  const _component_profile_unsubscribe = _resolveComponent("profile-unsubscribe")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_available_balance = _resolveComponent("available-balance")!
  const _component_TabView = _resolveComponent("TabView")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.isIframe)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_1, _toDisplayString(_ctx.t('profile.student.title')), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_TabView, null, {
      default: _withCtx(() => [
        _createVNode(_component_TabPanel, {
          header: _ctx.t('profile.student.tab.training.title')
        }, {
          default: _withCtx(() => [
            (!_ctx.unsubscribe.show)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_course_list, {
                    onOnUnsubscribe: _ctx.handleShowUnsubscribeForm,
                    userIdToVisit: _ctx.userId,
                    isVisitorView: _ctx.isVisitorView,
                    visitRole: _ctx.visitRole
                  }, null, 8, ["onOnUnsubscribe", "userIdToVisit", "isVisitorView", "visitRole"]),
                  _createVNode(_component_training_view, {
                    userId: _ctx.userId,
                    role: 2,
                    isVisitorView: _ctx.isVisitorView
                  }, null, 8, ["userId", "isVisitorView"])
                ], 64))
              : _createCommentVNode("", true),
            (_ctx.unsubscribe.show)
              ? (_openBlock(), _createBlock(_component_profile_unsubscribe, {
                  key: 1,
                  userId: _ctx.userId,
                  course: { _id: _ctx.unsubscribe.courseId, name: _ctx.unsubscribe.courseName, camadaNro: _ctx.unsubscribe.camadaNro },
                  onOnCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.unsubscribe.show = false))
                }, null, 8, ["userId", "course"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_TabPanel, {
          header: _ctx.t('profile.student.tab.balance.title')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_available_balance, {
              availableBalances: _ctx.availableBalances,
              onBalanceTransferred: _ctx.balanceTransferred
            }, null, 8, ["availableBalances", "onBalanceTransferred"])
          ]),
          _: 1
        }, 8, ["header"])
      ]),
      _: 1
    })
  ]))
}