
import { defineComponent, PropType } from 'vue'

// components
import Card from '@/components/aero/surfaces/Card.vue'
import RatingCard from '@/components/aero/surfaces/RatingCard.vue'
import RatingChart from '@/components/aero/dataDisplay/RatingChart.vue'
import SmallCardCourse from '@/components/aero/surfaces/SmallCardCourse.vue'

// copies
import copies from '@/locales/dashboard/es.json'

interface IDataRating {
  positives: number
  neutrals: number
  negatives: number
  rating: number | string
}

export default defineComponent({
  components: { Card, RatingCard, RatingChart, SmallCardCourse },
  props: {
    isFinish: { type: Boolean, default: false },
    isTutor: { type: Boolean, default: true },
    ratingData: { type: Object as PropType<IDataRating>, required: true },
    camadaNro: { type: String, default: '' },
    courseName: { type: String, required: true }
  },
  setup(props) {
    const showEmptyState = !props.ratingData.neutrals && !props.ratingData.negatives && !props.ratingData.positives

    return { copies, showEmptyState }
  }
})
