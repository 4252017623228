import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`circle t3 bold text-${_ctx.color} bg-${_ctx.background} p-d-flex p-ai-center p-jc-center`)
  }, [
    (_ctx.value)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createTextVNode(_toDisplayString(_ctx.value), 1)
        ], 64))
      : _renderSlot(_ctx.$slots, "default", { key: 1 }, undefined, true)
  ], 2))
}