
import { defineComponent, PropType } from 'vue'

// Models
import { Invoice } from '@/models/profile/billing'

// Copies
import { billingHistory } from '@/locales/profile/es.json'

export default defineComponent({
  props: {
    data: { type: Object as PropType<Invoice>, required: true }
  },
  emits: ['onUpload'],
  setup(props, { emit }) {
    const handleUpload = () => {
      emit('onUpload', props.data)
    }
    return { copies: billingHistory.invoice, handleUpload }
  }
})
