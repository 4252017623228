import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2eb5ea14"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "t3 p-mt-3 p-mb-4" }
const _hoisted_2 = {
  key: 0,
  class: "p-d-flex p-ai-center training--full-width"
}
const _hoisted_3 = { class: "training" }
const _hoisted_4 = { class: "p-d-flex p-flex-column p-ai-center" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "referral__title p-mb-3 bold p-as-start" }
const _hoisted_7 = { class: "referral__text" }
const _hoisted_8 = { class: "p-d-flex p-jc-between p-ai-end" }
const _hoisted_9 = { class: "coupon p-d-flex p-flex-column p-ai-start" }
const _hoisted_10 = { class: "coupon__text p-mb-2" }
const _hoisted_11 = { class: "coupon__code p-py-2 p-px-3" }
const _hoisted_12 = { class: "p-d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_skeleton_small_card_course = _resolveComponent("skeleton-small-card-course")!
  const _component_small_card_course = _resolveComponent("small-card-course")!
  const _component_Carousel = _resolveComponent("Carousel")!
  const _component_upselling_banner = _resolveComponent("upselling-banner")!
  const _component_Toast = _resolveComponent("Toast")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_referral_actions_icon = _resolveComponent("referral-actions-icon")!
  const _component_button_icon = _resolveComponent("button-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.role === 2 ? _ctx.t('profile.coderExperience.student.title') : _ctx.t('profile.coderExperience.teacher.title')), 1),
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(2, (i) => {
            return _createVNode(_component_skeleton_small_card_course, {
              key: i,
              class: "skeleton__smallCard"
            })
          }), 64))
        ]))
      : _createCommentVNode("", true),
    (!_ctx.isLoading && _ctx.courses.length)
      ? (_openBlock(), _createBlock(_component_Carousel, {
          key: 1,
          value: _ctx.courses,
          numVisible: 2,
          numScroll: 1,
          responsiveOptions: _ctx.responsiveOptions,
          showIndicators: false
        }, {
          item: _withCtx((course) => [
            (_openBlock(), _createBlock(_component_small_card_course, {
              key: course.data.id,
              border: true,
              text: 
          course.data.inProcess
            ? `${_ctx.t('profile.courseList.course')} ${course.data.name} - ${_ctx.t('profile.courseList.comision')} ${course.data.camadaNro}`
            : `${_ctx.t('profile.courseList.course')} ${course.data.name}`
        ,
              description: _ctx.setCourseDescription(course.data),
              options: course.data.isEnded || _ctx.isVisitorView ? [] : _ctx.optionsCourse[course.index],
              status: course.data.inProcess && !course.data.isEnded ? _ctx.t('profile.courseList.pending') : ''
            }, null, 8, ["text", "description", "options", "status"]))
          ]),
          _: 1
        }, 8, ["value", "responsiveOptions"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.role === 2 && _ctx.perk.link && _ctx.perk.courseTitle)
        ? (_openBlock(), _createBlock(_component_upselling_banner, {
            key: 0,
            class: _normalizeClass(_ctx.courses.length % 2 === 0 ? 'training--full-width' : ''),
            offerLink: _ctx.perk.link,
            course: _ctx.perk.courseTitle,
            offer: _ctx.perk.discount,
            relatedStudents: _ctx.perk.related,
            paddingMd: "",
            arrowLink: _ctx.courses.length % 2 === 0 ? false : true
          }, null, 8, ["class", "offerLink", "course", "offer", "relatedStudents", "arrowLink"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_Toast),
    _createVNode(_component_Dialog, {
      visible: _ctx.zoomCredentialsDialog.isOpen,
      modal: true,
      "onUpdate:visible": _ctx.handleCloseDialog
    }, {
      header: _withCtx(() => [
        _createElementVNode("h3", null, _toDisplayString(_ctx.t('profile.courseList.itemsLabel.pending')), 1)
      ]),
      default: _withCtx(() => [
        _createElementVNode("p", null, _toDisplayString(_ctx.t('profile.courseList.itemsLabel.zoomUser')) + ": " + _toDisplayString(_ctx.zoomCredentialsDialog.user), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.t('profile.courseList.itemsLabel.zoomPass')) + ": " + _toDisplayString(_ctx.zoomCredentialsDialog.password), 1)
      ]),
      _: 1
    }, 8, ["visible", "onUpdate:visible"]),
    _createVNode(_component_Dialog, {
      id: "coupon-dialog",
      visible: _ctx.displayReferral,
      "onUpdate:visible": _ctx.handleCloseReferral,
      style: { width: '30vw' },
      modal: ""
    }, {
      header: _withCtx(() => []),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("h3", _hoisted_10, _toDisplayString(_ctx.t('profile.referralDialog.footer.title')), 1),
            _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.couponReferral.code), 1)
          ]),
          _createElementVNode("ol", _hoisted_12, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.referralOptions, (ref) => {
              return (_openBlock(), _createElementBlock("li", {
                key: ref.name,
                style: {"list-style-type":"none"},
                class: "p-ml-3"
              }, [
                _createVNode(_component_button_icon, {
                  size: 22,
                  color: _ctx.linksLoading && (ref.name == 'linkedin' || ref.name == 'whatsapp') ? 'var(--light-gray)' : 'var(--secondary)',
                  onClick: ref.action
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_referral_actions_icon, {
                      name: ref.name
                    }, null, 8, ["name"])
                  ]),
                  _: 2
                }, 1032, ["color", "onClick"])
              ]))
            }), 128))
          ])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("img", {
            src: _ctx.couponImgUrl,
            alt: "couponImage",
            class: "referral__image p-mb-5"
          }, null, 8, _hoisted_5),
          _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.t('profile.referralDialog.title')), 1),
          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.t('profile.referralDialog.description.line1')) + " " + _toDisplayString(_ctx.t('profile.referralDialog.description.line2')), 1)
        ])
      ]),
      _: 1
    }, 8, ["visible", "onUpdate:visible"])
  ], 64))
}