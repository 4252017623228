import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0af3c128"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = { class: "p2 p-ml-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { class: "feedback" }, {
    default: _withCtx(() => [
      _createElementVNode("img", {
        src: `/assets/icons/rating/${_ctx.feedbackType}-rating.svg`,
        alt: _ctx.feedbackType
      }, null, 8, _hoisted_1),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.message), 1)
    ]),
    _: 1
  }))
}