
import { computed, defineComponent, PropType } from 'vue'

// Components
import Avatar from '@/components/aero/dataDisplay/Avatar.vue'

// Icons
import Icon from '@/components/aero/icon/Icon.vue'
import { ThumbUp, ThumbUpFill } from '@/components/aero/icon/templates'

// Models
import { Comment } from '@/models/dashboard/community'

export default defineComponent({
  components: { Avatar, Icon, ThumbUp, ThumbUpFill },
  props: {
    comment: { type: Object as PropType<Comment>, required: true },
    commentDate: { type: String, required: true }
  },
  setup(props, { emit }) {
    /**
     * Checks whether the comment was already liked by the logged in user
     */
    const isLiked = computed(() => {
      return props.comment.flags.wasLiked
    })

    /**
     * Like a comment
     */
    const likeComment = () => {
      emit('likeDislikeComment', props.comment._id, false)
    }

    /**
     * Dislike a comment
     */
    const dislikeComment = () => {
      emit('likeDislikeComment', props.comment._id, true)
    }
    return { dislikeComment, likeComment, isLiked }
  }
})
