import { Api } from '../api'

import {
  SelfManagementFlags,
  Penalty,
  SelfManagementRequest,
  ReenrollPayload,
  SelfManagementPayment,
  Unenroll
} from '@/models/newPlatform/selfManagement'
const { API_ENDPOINT } = process.env

/** API call to know specific price for penalty
 * @param userId `required` Logged user ID
 * @param country `required` Logged user country
 * @param type `required` type of penalty
 * @returns penalty type and price
 */

export const getPenalty = async (userId: string, country: string, type: 'reenroll' | 'change'): Promise<Penalty> => {
  return await Api.get(
    `${API_ENDPOINT}/platform/users/${userId}/self-management/penalty?country=${country}&ts=${Date.now()}&type=${type}&userId=${userId}`
  )
}

export const approvePenalty = async (params: { gateway: string; pid: string; userId: string }) => {
  return await Api.get(
    `${API_ENDPOINT}/platform/users/${params.userId}/self-management/reenroll/paid?purchaseId=${params.pid}&gateway=${params.gateway}`
  )
}

/** API call to know if is eligible for reenroll and if it has penalty
 * @param userId `required` Logged user ID
 * @param courseId `required` current course
 * @returns Request status for the current course
 */

export const isRequestReenrollValid = async (userId: string, courseId: string): Promise<SelfManagementFlags> => {
  return await Api.get(
    `${API_ENDPOINT}/platform/users/${userId}/self-management/reenroll/${courseId}?courseId=${courseId}&ts=${Date.now()}&userId=${userId}`
  )
}

/** API call to retrieve history of requests
 * @param userId `required` Logged user ID
 * FromCourse => current course id
 * @returns list of requests
 */

export const getRequests = async (
  userId: string,
  body: { userId: string; fromCourse: string; ts: number }
): Promise<SelfManagementRequest[]> => {
  return await Api.post(`${API_ENDPOINT}/platform/users/${userId}/self-management/requests`, body)
}

/** API call to discard a request that was in process
 * @param userId `required` Logged user ID
 * @param requestId `required` request identifier to discard
 * @returns confirmation message
 */

export const discardRequest = async (body: { userId: string; requestId: string }): Promise<boolean> => {
  const response = await Api.post(
    `${API_ENDPOINT}/platform/users/${body.userId}/self-management/requests/${body.requestId}/discart`,
    body
  )
  return response === 'success'
}

/** API call to request the reenroll of certain course
 * @param userId `required` Logged user ID
 * @returns confirmation message
 */

export const requestReenrollV2 = async (body: ReenrollPayload): Promise<SelfManagementPayment> => {
  return await Api.post(`${API_ENDPOINT}/platform/users/${body.userId}/self-management/reenroll`, body)
}

/** API call to request the unenroll of certain course
 * @param body  {Object} - Course and user data to request unenroll
 * @param body.userId  Logged user ID
 * @param body.courseId course the user wants to unenroll
 * @returns confirmation message
 */

export const requestUnenroll = async (body: Unenroll): Promise<{ success: boolean }> => {
  return await Api.post(`${API_ENDPOINT}/platform/users/${body.userId}/self-management/unenroll/${body.courseId}`, body)
}
