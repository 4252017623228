import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, toHandlers as _toHandlers, mergeProps as _mergeProps, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-light-gray p2"
}
const _hoisted_2 = {
  key: 0,
  href: "https://zoom.us/",
  target: "_blank",
  rel: "noopener noreferrer",
  class: "p-button secondary p-mr-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.onboardingDialog.display,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.onboardingDialog.display) = $event)),
    modal: true,
    style: _normalizeStyle('width:' + _ctx.dialogWidth),
    class: "dialog__wrapper"
  }, {
    header: _withCtx(() => [
      _createElementVNode("h3", null, _toDisplayString(_ctx.onboardingDialog.title), 1)
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.onboardingDialog.step !== 0 ? ' p-d-flex p-jc-between  p-ai-center' : '')
      }, [
        (_ctx.onboardingDialog.step !== 0)
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.onboardingDialog.step) + " de 4", 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, [
          (_ctx.onboardingDialog.step === 1)
            ? (_openBlock(), _createElementBlock("a", _hoisted_2, _toDisplayString(_ctx.onboardingDialog.ctaSecondaryLabel), 1))
            : (_openBlock(), _createBlock(_component_Button, _mergeProps({
                key: 1,
                label: _ctx.onboardingDialog.ctaSecondaryLabel,
                class: "secondary"
              }, _toHandlers({ click: _ctx.onboardingDialog.step === 0 ? _ctx.handleCloseDialog : _ctx.handlePreviousStep })), null, 16, ["label"])),
          _createVNode(_component_Button, _mergeProps({
            label: _ctx.onboardingDialog.ctaPrimaryLabel
          }, _toHandlers({ click: _ctx.onboardingDialog.step === 4 ? _ctx.handleCloseDialog : _ctx.handleNextStep })), null, 16, ["label"])
        ])
      ], 2)
    ]),
    default: _withCtx(() => [
      _createElementVNode("p", {
        style: _normalizeStyle('min-height:' + _ctx.dialogHeight)
      }, _toDisplayString(_ctx.onboardingDialog.message), 5)
    ]),
    _: 1
  }, 8, ["visible", "style"]))
}