import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "smallCardOpinion bg-dark-gray" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SmallCardRatings = _resolveComponent("SmallCardRatings")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SmallCardRatings, {
      feedbackType: _ctx.feedbackType,
      amount: _ctx.amount,
      title: _ctx.title,
      description: _ctx.description
    }, null, 8, ["feedbackType", "amount", "title", "description"]),
    _createVNode(_component_ProgressBar, {
      feedbackType: _ctx.feedbackType,
      percentage: _ctx.percentage,
      class: "p-ml-4",
      style: {"width":"70%"}
    }, null, 8, ["feedbackType", "percentage"])
  ]))
}