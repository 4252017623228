import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7af49d4b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-avatar p-d-flex p-ai-center p-flex-row card__header" }
const _hoisted_2 = { class: "card__avatar" }
const _hoisted_3 = {
  key: 2,
  class: "avatar__button p-p-2"
}
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { class: "profile-name" }
const _hoisted_6 = { class: "text-light-gray" }
const _hoisted_7 = { class: "p-d-flex p-flex-column card__content" }
const _hoisted_8 = {
  key: 0,
  class: "container-input p-d-flex p-flex-column item__editing"
}
const _hoisted_9 = { class: "p3 text-light-gray p-mb-1" }
const _hoisted_10 = { class: "p-inputtext p-component p-filled input-form-student input-form-student" }
const _hoisted_11 = { class: "p2 text-light-gray p-mb-2" }
const _hoisted_12 = { class: "social-network--icons p-d-flex p-ai-center" }
const _hoisted_13 = ["href"]
const _hoisted_14 = {
  key: 2,
  class: "full-width"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Toast = _resolveComponent("Toast")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_SkeletonIcon = _resolveComponent("SkeletonIcon")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_Camera = _resolveComponent("Camera")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_social_network_icon = _resolveComponent("social-network-icon")!
  const _component_button_icon = _resolveComponent("button-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_card = _resolveComponent("card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Toast),
    _createVNode(_component_Dialog, {
      visible: _ctx.changePasswordRefs.dialogOpen,
      modal: true,
      "onUpdate:visible": _ctx.changePasswordHandlers.cancel
    }, {
      header: _withCtx(() => [
        _createElementVNode("h3", null, _toDisplayString(_ctx.copies.changePassword.dialog.title), 1)
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: _ctx.copies.changePassword.dialog.cancel,
          disabled: _ctx.changePasswordRefs.loading,
          class: "secondary",
          onClick: _ctx.changePasswordHandlers.cancel
        }, null, 8, ["label", "disabled", "onClick"]),
        _createVNode(_component_Button, {
          label: _ctx.copies.changePassword.dialog.confirm,
          disabled: _ctx.changePasswordRefs.loading,
          loading: _ctx.changePasswordRefs.loading,
          onClick: _ctx.changePasswordHandlers.confirm
        }, null, 8, ["label", "disabled", "loading", "onClick"])
      ]),
      default: _withCtx(() => [
        _createTextVNode(" " + _toDisplayString(_ctx.copies.changePassword.dialog.subtitle) + " ", 1)
      ]),
      _: 1
    }, 8, ["visible", "onUpdate:visible"]),
    (_ctx.editingProfile)
      ? (_openBlock(), _createBlock(_component_Breadcrumb, {
          key: 0,
          class: "p-mb-3 p-ml-2 p-mt-3",
          home: { label: _ctx.copies.editProfile.breadCrumb.title1, command: _ctx.handleToggleEdit },
          model: [{ label: _ctx.copies.editProfile.breadCrumb.title2, to: '/profile' }]
        }, null, 8, ["home", "model"]))
      : _createCommentVNode("", true),
    _createVNode(_component_card, {
      paddingSm: "",
      style: _normalizeStyle(_ctx.editingProfile && 'background: inherit')
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_ctx.loadingUpdateImage)
              ? (_openBlock(), _createBlock(_component_SkeletonIcon, {
                  key: 0,
                  class: "avatar__skeleton"
                }))
              : (_openBlock(), _createBlock(_component_Avatar, {
                  key: 1,
                  name: _ctx.user.firstName,
                  imageLink: _ctx.user.avatar,
                  size: "xlarge"
                }, null, 8, ["name", "imageLink"])),
            (!_ctx.isVisitorView)
              ? (_openBlock(), _createElementBlock("button", _hoisted_3, [
                  _createElementVNode("input", {
                    type: "file",
                    accept: "image/*",
                    onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateProfilePicture && _ctx.updateProfilePicture(...args))),
                    disabled: _ctx.loadingUpdateImage,
                    class: _normalizeClass({ 'avatar__button--allowed': !_ctx.loadingUpdateImage })
                  }, null, 42, _hoisted_4),
                  _createVNode(_component_Icon, {
                    strokeColor: "var(--secondary)",
                    size: 24
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Camera)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("p", _hoisted_5, _toDisplayString(`${_ctx.user.firstName} ${_ctx.user.lastName}`), 1),
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.getRoleCopy) + " " + _toDisplayString(_ctx.course), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.parsedInformation, (value, key) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["p-d-flex p-flex-column", _ctx.editingProfile && 'item__editing']),
              key: key
            }, [
              (key !== 'chatLink')
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.copies.cardSections[key]), 1),
                    _createElementVNode("span", _hoisted_10, _toDisplayString(value), 1)
                  ]))
                : _createCommentVNode("", true)
            ], 2))
          }), 128)),
          (_ctx.socialNetworksToShow.length)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["p-d-flex p-flex-column", _ctx.editingProfile && 'item__editing'])
              }, [
                _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.copies.cardSections.socialNetworks), 1),
                _createElementVNode("div", _hoisted_12, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.socialNetworksToShow, (socialNetwork, index) => {
                    return (_openBlock(), _createElementBlock("a", {
                      key: socialNetwork.name,
                      href: socialNetwork.link,
                      class: _normalizeClass(index < 5 && 'p-mb-2'),
                      target: "_blank"
                    }, [
                      _createVNode(_component_button_icon, {
                        size: 18,
                        color: '#9DF4E2',
                        class: "button-icon--social"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_social_network_icon, {
                            name: socialNetwork.name
                          }, null, 8, ["name"])
                        ]),
                        _: 2
                      }, 1024)
                    ], 10, _hoisted_13))
                  }), 128))
                ])
              ], 2))
            : _createCommentVNode("", true)
        ]),
        (_ctx.isVisitorView && _ctx.information.chatLink)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              style: {"text-decoration":"none"},
              to: _ctx.information.chatLink
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Button, {
                  label: _ctx.copies.visitorView.button,
                  class: "secondary full-width"
                }, null, 8, ["label"])
              ]),
              _: 1
            }, 8, ["to"]))
          : (!_ctx.editingProfile)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 1,
                onClick: _ctx.handleToggleEdit,
                label: _ctx.copies.editProfile.button,
                class: "secondary full-width"
              }, null, 8, ["onClick", "label"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createVNode(_component_Button, {
                  label: _ctx.copies.changePassword.buttonLabel,
                  onClick: _ctx.changePasswordHandlers.click,
                  class: "secondary full-width"
                }, null, 8, ["label", "onClick"])
              ]))
      ]),
      _: 1
    }, 8, ["style"])
  ], 64))
}