import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_calendar = _resolveComponent("calendar")!
  const _component_trophy = _resolveComponent("trophy")!
  const _component_list_bullets = _resolveComponent("list-bullets")!
  const _component_megaphone = _resolveComponent("megaphone")!
  const _component_bell = _resolveComponent("bell")!
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createBlock(_component_icon, {
    withWrapper: true,
    wrapperS: true,
    strokeColor: 'var(--lime-light)',
    size: 19
  }, {
    default: _withCtx(() => [
      (_ctx.type === 'calendar')
        ? (_openBlock(), _createBlock(_component_calendar, { key: 0 }))
        : (_ctx.type === 'certificate')
          ? (_openBlock(), _createBlock(_component_trophy, { key: 1 }))
          : (_ctx.type === 'challenge')
            ? (_openBlock(), _createBlock(_component_list_bullets, { key: 2 }))
            : (_ctx.type === 'community')
              ? (_openBlock(), _createBlock(_component_megaphone, { key: 3 }))
              : (_openBlock(), _createBlock(_component_bell, { key: 4 }))
    ]),
    _: 1
  }, 8, ["strokeColor"]))
}