
import { defineComponent } from 'vue'
import Card from '@/components/aero/surfaces/Card.vue'
import Icon from '@/components/aero/icon/Icon.vue'
import ThumbDown from '@/components/aero/icon/templates/thumb-down.vue'
import Tag from 'primevue/tag'
import CircleBadge from '@/components/aero/dataDisplay/CircleBadge.vue'
import ThumbUp from '../../icon/templates/thumb-up.vue'
import CheckFill from '../../icon/templates/check-fill.vue'
import copies from '@/locales/challenges/es.json'

export default defineComponent({
  components: { Card, Icon, ThumbDown, Tag, CircleBadge, ThumbUp, CheckFill },
  props: {
    porCorregir: { type: Number, required: false, default: 0 },
    fueraDeTiempo: { type: Boolean, default: false }
  },
  setup() {
    return { copies: copies.CorreccionesCard }
  }
})
