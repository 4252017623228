import { ITimeline } from '@/models/newPlatform/challenges/timeline'
import { IDeliver } from '@/models/newPlatform/challenges/deliver'

interface IProjectProgress {
  flags: { [k: string]: boolean | number }
}

interface IModuleProgress {
  _id: string
  name: string
  slug: string
  expiresAt: number
  evaluationCriteria: string[]
  class: {
    _id: string
    order: number
    name: string
    day: number
  }
  flags: { [k: string]: boolean }
  stage: string
  slideUrl: string
  status: string
  projectId: string
  timeline: ITimeline[] | []
  projectData: IDeliver | null
  finalDeadline: number | null
  project: IProjectProgress
}

const isOutOfTime = (deliver: IModuleProgress): boolean => {
  return !!(
    deliver.project?.flags?.hoursLeftToReviewExpired &&
    !deliver.project?.flags.wasReviewed &&
    deliver.status !== 'approved'
  )
}

const isToReceive = (deliver: IModuleProgress): boolean => {
  return !!(
    (!deliver.project && deliver.expiresAt > Date.now()) ||
    (deliver.projectData &&
      !deliver.projectData.chat?.slice(-1)[0].flags.fromStudent &&
      deliver.finalDeadline &&
      deliver.finalDeadline > Date.now() &&
      deliver.status !== 'approved')
  )
}

const isExpired = (deliver: IModuleProgress): boolean => {
  return !!(
    (!deliver.project && deliver.expiresAt < Date.now()) ||
    (deliver.project && deliver.finalDeadline && deliver.finalDeadline < Date.now() && deliver.status !== 'approved')
  )
}

const isApproved = (deliver: IModuleProgress): boolean => {
  return !!deliver.project?.flags?.wasApproved
}

export function setChallengesByPriority(modules: IModuleProgress[]): IModuleProgress[] {
  const outOfTime: IModuleProgress[] = []
  const pending: IModuleProgress[] = []
  const toReceive: IModuleProgress[] = []
  const expired: IModuleProgress[] = []
  const approved: IModuleProgress[] = []

  modules.forEach((_module) => {
    if (isToReceive(_module)) {
      toReceive.push(_module)
    } else if (isApproved(_module)) {
      approved.push(_module)
    } else if (isOutOfTime(_module)) {
      outOfTime.push(_module)
    } else if (isExpired(_module)) {
      expired.push(_module)
    } else {
      pending.push(_module)
    }
  })

  const delivers = [...outOfTime, ...pending, ...toReceive, ...expired, ...approved]

  return delivers
}
