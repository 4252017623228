import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2c36b21d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "p-d-flex p-ai-center checkbox--placement" }
const _hoisted_3 = {
  for: "currently-studying",
  class: "p2 text-white p-ml-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Form = _resolveComponent("Form")!
  const _component_Checkbox = _resolveComponent("Checkbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Form, {
      onOnSubmit: _ctx.handleSubmit,
      onOnCancel: _ctx.handleCancel,
      onOnChange: _ctx.handleChange,
      questions: _ctx.questions,
      rules: _ctx.rules,
      addDateRules: false,
      buttonLabel: { cancel: _ctx.t('profile.formStudies.cta.cancel'), submit: _ctx.t('profile.formStudies.cta.submit') },
      loadingSubmit: _ctx.isLoading,
      disableSubmit: _ctx.disableSubmit
    }, null, 8, ["onOnSubmit", "onOnCancel", "onOnChange", "questions", "rules", "buttonLabel", "loadingSubmit", "disableSubmit"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Checkbox, {
        id: "currently-studying",
        class: "bigCheckbox p-ai-center p-jc-center",
        name: "currently studying",
        modelValue: _ctx.currentlyStudying,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentlyStudying) = $event)),
        binary: true
      }, null, 8, ["modelValue"]),
      _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.t('profile.formStudies.currentlyStudying')), 1)
    ])
  ]))
}