
import { defineComponent } from 'vue'

// Components
import ButtonIcon from '@/components/aero/buttons/ButtonIcon.vue'
import { ChevronRight } from '@/components/aero/icon/templates'

// Copies
import copies from '@/locales/dashboard/es.json'

export default defineComponent({
  components: { ButtonIcon, ChevronRight },
  props: {
    img: { type: String, required: false },
    offerLink: { type: String, required: true },
    course: { type: String, required: true },
    offer: { type: String, required: true },
    paddingSm: { type: Boolean, required: false, default: false },
    paddingMd: { type: Boolean, required: false, default: false },
    arrowLink: { type: Boolean, default: false }
  },
  setup(props) {
    return { copies }
  }
})
