
// Icons
import ButtonIcon from '@/components/aero/buttons/ButtonIcon.vue'
import { ArrowUpToLine } from '@/components/aero/icon/templates'

// Hooks
import { displayTopButton } from '@/hooks/useToTopButton'

import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    buttonColor: {
      type: String,
      default: 'var(--secondary)'
    }
  },
  components: {
    ButtonIcon,
    ArrowUpToLine
  },
  setup() {
    const { displayButton } = displayTopButton()

    const windowToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
    return { displayButton, windowToTop }
  }
})
