
// Vendor
import { defineComponent, onMounted, ref, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'

// Store
import { themeStore } from '@/store/modules/themes.store'
import { useUserStore } from '@/store/modules/user.store'

// Components
import TwoColsLayout from '@/components/aero/layout/TwoColsLayout.vue'
import SmallCardAlert from '@/components/aero/surfaces/profile/changeCourse/SmallCardAlert.vue'
import CourseInformation from '@/components/aero/surfaces/profile/changeCourse/CourseInformation.vue'
import ComisionTab from '@/views/profile/components/ComisionTab.vue'
import ErrorPage from '@/components/aero/dataDisplay/ErrorPage.vue'
import SkeletonCourseInfoCard from '@/components/aero/skeleton/SkeletonCourseInfoCard.vue'
import SkeletonCardAlert from '@/components/aero/skeleton/SkeletonAlertCard.vue'
import SkeletonTableFilter from '@/components/aero/skeleton/SkeletonTableFilter.vue'
import PlaceholderState from '@/components/aero/surfaces/challenges/PlaceholderState.vue'
import Toast from '@/components/aero/feedback/Toast.vue'
import CoursesTab from '@/views/profile/components/CoursesTab.vue'

// PrimeVue Components
import Dialog from 'primevue/dialog'

// Utils
import { isoToFullDate } from '@/utils/datetime'

// Services
import { getCourse } from '@/api/newPlatform/course.api'
import { getIncomingCoursesOfSameCategory } from '@/services/newPlatform/products.services'
import { getRequests, discardRequest, isRequestReenrollValid, getPenalty } from '@/services/newPlatform/profile.services'

// Copies
import { studentChangeCourse as studentChangeCopies, transferCourse as transferCopies } from '@/locales/profile/es.json'

// Model
import { ProductCourse, IncomingClass } from '@/models/newPlatform/products/productCourse'
import { SelfManagementRequest, SelfManagementFlags } from '@/models/newPlatform/selfManagement'

// Hooks
import { useToast } from 'primevue/usetoast'

// Amplitude
import { trackEvent } from '@/amplitude/actions'
import { ProfileEvents } from '@/amplitude/constants'

export default defineComponent({
  components: {
    TwoColsLayout,
    SmallCardAlert,
    CourseInformation,
    ComisionTab,
    ErrorPage,
    SkeletonCourseInfoCard,
    SkeletonCardAlert,
    PlaceholderState,
    Toast,
    SkeletonTableFilter,
    Dialog,
    CoursesTab
  },

  setup() {
    const { id: userId, country } = useUserStore()
    themeStore().changeDefault('new-theme')

    const route = useRoute()
    const router = useRouter()
    const { camadaNro } = route.params

    // Course information component (aside)
    const currentCourse = reactive({
      _id: '',
      category: '',
      productId: '',
      leadTeacherId: '',
      courseName: '',
      camadaNro,
      daysAndHours: '',
      startDate: '',
      endDate: ''
    })

    const reenrollStatus = {
      requests: [] as SelfManagementRequest[],
      flags: {} as SelfManagementFlags,
      requestId: '',
      steps: {
        currentRequest: false,
        reenroll: false
      },
      alreadyRequested: false
    }

    const penalty = {
      alias: '',
      price: 0,
      type: ''
    }

    const penaltyReenroll = ref<boolean>()

    // dialog to communicate that a request is already is progress
    const displayDialog = ref<boolean>(false)
    const displayCardAlert = ref(false)

    const changeComisionClasses = ref<IncomingClass[]>([])
    const inconmingClassesError = ref(false)

    const penaltyCardTitle = ref('')

    const courseError = ref(false)
    const buttonLabel = ref('')
    const loading = ref<boolean>(true)

    const toast = useToast()

    /** Error and cancel **/
    const failLoadClasses = () => {
      toast.add({
        severity: studentChangeCopies.comision.toast.error.toastSeverity.error as 'error',
        detail: studentChangeCopies.comision.toast.error.errorLoading,
        group: studentChangeCopies.comision.toast.error.group,
        life: studentChangeCopies.comision.toast.error.life
      })
    }

    const handleCancel = () => {
      router.push('/profile')
    }

    /** Products and classes **/

    async function updateCourses() {
      try {
        const response = await getIncomingCoursesOfSameCategory(country, 'remotes', currentCourse.productId)

        changeComisionClasses.value = parseClasses(response)
        if (!changeComisionClasses.value.length) {
          inconmingClassesError.value = true
          failLoadClasses()
        }
      } catch (error) {
        inconmingClassesError.value = true
        failLoadClasses()
        // eslint-disable-next-line no-console
        console.error(error)
      }
    }

    /** Self management requests **/
    async function setRequests() {
      try {
        const requests = await getRequests(userId, currentCourse._id)
        reenrollStatus.requests = requests

        if (requests.length) {
          const currentRequest = requests.find((item) => {
            if (item.fromCourse === currentCourse._id && ['process', 'processs'].includes(item.status)) {
              return ['reenroll', 'unenroll', 'change', 'transfer'].includes(item.type)
            } else {
              return false
            }
          })
          if (currentRequest?._id) {
            reenrollStatus.alreadyRequested = true
            reenrollStatus.requestId = currentRequest._id
            reenrollStatus.steps.currentRequest = true
          }
        }

        reenrollStatus.steps.reenroll = true

        const requestFlags = await isRequestReenrollValid(userId, currentCourse._id)
        reenrollStatus.flags = requestFlags
        penaltyReenroll.value = requestFlags.penaltyReenroll

        // Comision
        buttonLabel.value = reenrollStatus.flags.hasToPay
          ? transferCopies.questions.cta.payPenalty
          : transferCopies.questions.cta.sendRquest
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e, 'error while trying to retrieve requests data')
        throw e
      }
    }
    const setPenalties = async () => {
      try {
        const response = await getPenalty(userId, country, 'reenroll')
        penalty.alias = response.pricing.alias
        penalty.price = response.pricing.price
        penalty.type = response.type
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e, 'error while trying to retrieve penalty data')
        throw e
      }
    }

    const setSelfManagementStatus = async () => {
      try {
        await Promise.all([setRequests(), setPenalties()])
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('error while trying to retrieve data from self-management requests and penalties')
        throw e
      }
    }

    /** Parsing data **/
    const parseClasses = (classes: ProductCourse[]): IncomingClass[] => {
      return classes.map((_class) => {
        // Teacher name
        const teacherName = _class.camada?.leadTeacher?.fullName || studentChangeCopies.table.notAssigned

        return {
          teacherName,
          camadaNro: _class.camada.id,
          days: _class.days.join(` ${studentChangeCopies.table.and} `),
          schedule: _class.hours.slice(0, -2),
          duration: `${isoToFullDate(_class.normalized.startDate)} ${studentChangeCopies.table.to} ${isoToFullDate(
            _class.normalized.endDate
          )}`,
          daysToStart: _class.startsIn,
          // Info not used in the child component but used in filters
          value: _class._id,
          startDate: _class.startDate,
          numDays: _class._days,
          leadTeacherId: _class.camada?.leadTeacher?._id
        }
      })
    }

    // Cancel current request process
    const handleDiscardRequest = async () => {
      const body = {
        userId,
        requestId: reenrollStatus.requestId
      }
      const { success, error, group, life } = transferCopies.toast.toastSeverity as { success: 'success', life: number, error: 'error', group: string}
      try {
        await discardRequest(body)

        trackEvent(ProfileEvents.DISCARD_REQUEST)

        toast.add({ severity: success, detail: transferCopies.toast.discardRequest.success, group, life })
      } catch (e) {
        toast.add({ severity: error, detail: transferCopies.toast.discardRequest.error, group, life })
      } finally {
        // set loading state false
        setTimeout(() => {
          router.push('/profile')
        }, 300)
      }
    }

    onMounted(async () => {
      // params might get string[] so ts complains
      let camadaNroToFetch
      if (Array.isArray(camadaNro)) {
        camadaNroToFetch = camadaNro[0]
      } else {
        camadaNroToFetch = camadaNro
      }

      try {
        const response = await getCourse(userId, camadaNroToFetch)
        const { course } = response

        // Info for incoming Classes
        currentCourse._id = course._id
        currentCourse.productId = course.level._id
        currentCourse.leadTeacherId = course.camada?.leadTeacher?._id || ''

        // Info for courseCard
        currentCourse.courseName = course.level.title
        currentCourse.category = course.level?.categories[0]
        currentCourse.daysAndHours = course.daysAndHours
        currentCourse.startDate = course.startDate
        currentCourse.endDate = course.endDate

        await setSelfManagementStatus()

        // check if a request is already in progress
        if (reenrollStatus.alreadyRequested) {
          displayDialog.value = true
        } else {
          await updateCourses()
        }

        let penaltyTitle = ''

        if (reenrollStatus.flags.hasToPay || reenrollStatus.flags.penaltyReenroll) {
          displayCardAlert.value = true
          if (!reenrollStatus.flags.isStarted) penaltyTitle = studentChangeCopies.alert.notStarted
          if (reenrollStatus.flags.isStarted) penaltyTitle = studentChangeCopies.alert.started
          if (reenrollStatus.flags.isEnded) penaltyTitle = studentChangeCopies.alert.finished
        }

        penaltyCardTitle.value = `${penaltyTitle} ${penalty.alias}. `
      } catch (error) {
        courseError.value = true
        // eslint-disable-next-line no-console
        console.error(error)
      } finally {
        loading.value = false
      }
    })

    return {
      penaltyCardTitle,
      courseError,
      camadaNro,
      loading,
      changeComisionClasses,
      inconmingClassesError,
      displayDialog,
      transferCopies,
      handleCancel,
      handleDiscardRequest,
      studentChangeCopies,
      penaltyReenroll,
      buttonLabel,
      reenrollStatus,
      currentCourse,
      displayCardAlert
    }
  }
})
