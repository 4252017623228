import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-d-flex p-ai-center p-jc-between p-mb-3" }
const _hoisted_2 = { class: "p-d-flex p-ai-center" }
const _hoisted_3 = { class: "p-d-flex p-ai-center p-mb-3" }
const _hoisted_4 = { class: "p-mb-3" }
const _hoisted_5 = { class: "p-d-flex p-ai-center" }
const _hoisted_6 = { class: "p-d-flex p-ai-center p-mb-3" }
const _hoisted_7 = { class: "p-mb-3" }
const _hoisted_8 = { class: "p-d-flex p-ai-center" }
const _hoisted_9 = { class: "p-d-flex p-ai-center p-mb-3" }
const _hoisted_10 = { class: "p-mb-3" }
const _hoisted_11 = { class: "p-d-flex p-ai-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_skeleton_box = _resolveComponent("skeleton-box")!
  const _component_skeleton_icon = _resolveComponent("skeleton-icon")!
  const _component_card = _resolveComponent("card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_skeleton_box, {
      style: {"width":"220px"},
      height: '25px',
      class: "p-mb-2"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_skeleton_box, {
        style: {"width":"180px"},
        height: '25px'
      }),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_skeleton_box, {
          class: "p-mr-1",
          style: {"width":"80px"},
          height: '25px'
        }),
        _createVNode(_component_skeleton_box, {
          style: {"width":"80px"},
          height: '25px'
        })
      ])
    ]),
    _createVNode(_component_card, { class: "p-mb-3" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_skeleton_icon),
          _createVNode(_component_skeleton_box, {
            style: {"width":"80px"},
            class: "p-ml-2"
          })
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_skeleton_box, { class: "p-mb-1" }),
          _createVNode(_component_skeleton_box, {
            class: "p-mb-1",
            style: {"width":"90%"}
          }),
          _createVNode(_component_skeleton_box, {
            class: "p-mb-1",
            style: {"width":"70%"}
          }),
          _createVNode(_component_skeleton_box, { style: {"width":"85%"} })
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_skeleton_box, { style: {"width":"80px"} }),
          _createVNode(_component_skeleton_box, {
            style: {"width":"80px"},
            class: "p-ml-1"
          })
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_card, { class: "p-mb-3" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_skeleton_icon),
          _createVNode(_component_skeleton_box, {
            style: {"width":"80px"},
            class: "p-ml-2"
          })
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_skeleton_box, { class: "p-mb-1" }),
          _createVNode(_component_skeleton_box, {
            class: "p-mb-1",
            style: {"width":"90%"}
          }),
          _createVNode(_component_skeleton_box, {
            class: "p-mb-1",
            style: {"width":"70%"}
          }),
          _createVNode(_component_skeleton_box, { style: {"width":"85%"} })
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_skeleton_box, { style: {"width":"80px"} }),
          _createVNode(_component_skeleton_box, {
            style: {"width":"80px"},
            class: "p-ml-1"
          })
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_card, { class: "p-mb-3" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_skeleton_icon),
          _createVNode(_component_skeleton_box, {
            style: {"width":"80px"},
            class: "p-ml-2"
          })
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_skeleton_box, { class: "p-mb-1" }),
          _createVNode(_component_skeleton_box, {
            class: "p-mb-1",
            style: {"width":"90%"}
          }),
          _createVNode(_component_skeleton_box, {
            class: "p-mb-1",
            style: {"width":"70%"}
          }),
          _createVNode(_component_skeleton_box, { style: {"width":"85%"} })
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_skeleton_box, { style: {"width":"80px"} }),
          _createVNode(_component_skeleton_box, {
            style: {"width":"80px"},
            class: "p-ml-1"
          })
        ])
      ]),
      _: 1
    })
  ], 64))
}