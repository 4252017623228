import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-76dd4329"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "countdown__wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "p3 text-blue medium p-d-flex p-ai-center"
}
const _hoisted_3 = { class: "p3 medium" }
const _hoisted_4 = {
  key: 1,
  class: "p3 text-purple medium p-d-flex p-ai-center"
}
const _hoisted_5 = { class: "p3 medium" }
const _hoisted_6 = {
  key: 0,
  class: "p3 text-white medium p-d-flex p-ai-center"
}
const _hoisted_7 = { class: "p3 medium" }
const _hoisted_8 = {
  key: 1,
  class: "p3 text-blue medium p-d-flex p-ai-center"
}
const _hoisted_9 = { class: "p3 medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_clock = _resolveComponent("clock")!
  const _component_icon = _resolveComponent("icon")!
  const _component_alert_circle = _resolveComponent("alert-circle")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.role === 2 && !!_ctx.expiresAt)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (
          !_ctx.challenge?.project ||
          (_ctx.challenge?.project && _ctx.challenge.finalDeadline > Date.now() && !_ctx.challenge?.project.chat.slice(-1)[0].flags.fromStudent)
        )
            ? (_openBlock(), _createElementBlock("p", _hoisted_2, [
                (_ctx.expiresAt.includes(_ctx.copies.haveLeft))
                  ? (_openBlock(), _createBlock(_component_icon, {
                      key: 0,
                      size: 16,
                      strokeColor: 'var(--blue)',
                      class: "p-mr-1"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_clock)
                      ]),
                      _: 1
                    }, 8, ["strokeColor"]))
                  : _createCommentVNode("", true),
                _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.expiresAt), 1)
              ]))
            : (_openBlock(), _createElementBlock("p", _hoisted_4, [
                _createVNode(_component_icon, {
                  size: 16,
                  strokeColor: 'var(--purple)',
                  class: "p-mr-1"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_clock)
                  ]),
                  _: 1
                }, 8, ["strokeColor"]),
                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.correctionExpDate()), 1)
              ]))
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.role !== 2 && !!_ctx.expiresAt)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.isExpired)
            ? (_openBlock(), _createElementBlock("p", _hoisted_6, [
                _createVNode(_component_icon, {
                  size: 16,
                  strokeColor: 'var(--white)',
                  class: "p-mr-1"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_alert_circle)
                  ]),
                  _: 1
                }, 8, ["strokeColor"]),
                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.withNumber) + _toDisplayString(_ctx.copies.expired), 1)
              ]))
            : (_openBlock(), _createElementBlock("p", _hoisted_8, [
                (_ctx.expiresAt.includes(_ctx.copies.haveLeft))
                  ? (_openBlock(), _createBlock(_component_icon, {
                      key: 0,
                      size: 16,
                      strokeColor: 'var(--blue)',
                      class: "p-mr-1"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_clock)
                      ]),
                      _: 1
                    }, 8, ["strokeColor"]))
                  : _createCommentVNode("", true),
                _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.expiresAt), 1)
              ]))
        ], 64))
      : _createCommentVNode("", true)
  ]))
}