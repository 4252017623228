import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c6e7c17e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-profile" }
const _hoisted_2 = { class: "list-profile__tutor p-py-3" }
const _hoisted_3 = { class: "list-profile__students p-mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SkeletonProfileCardSmall = _resolveComponent("SkeletonProfileCardSmall")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SkeletonProfileCardSmall)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(15, (i) => {
        return _createVNode(_component_SkeletonProfileCardSmall, { key: i })
      }), 64))
    ])
  ]))
}