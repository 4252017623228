

import { defineComponent } from 'vue'
import Link from '@/components/commons/icon-templates/Link.vue'
import IconBase from '@/components/commons/IconBase.vue'

export default defineComponent({
  props: {
    label: String,
    link: String
  },
  components: {
    Link,
    IconBase
  }
})
