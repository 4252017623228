/* eslint-disable no-console */
import { DateTime } from 'luxon'
import { onBeforeUnmount, onMounted, readonly, ref } from 'vue'

const useBeginsIn = (day: number) => {
  const beginsIn = ref(0)
  const getMinutesInterval = ref()

  const getMinutes = () => {
    beginsIn.value = Math.ceil(DateTime.fromMillis(day).diff(DateTime.local(), 'minutes').as('minutes'))
  }

  onMounted(() => {
    getMinutes()

    // Ejecuta la funcion solo si la fecha es igual o anterior a mañana
    if (DateTime.fromMillis(day) <= DateTime.local().plus({ days: 1 })) {
      getMinutesInterval.value = setInterval(() => getMinutes(), 5000)
    } else {
      getMinutesInterval.value = null
    }
  })

  onBeforeUnmount(() => {
    clearInterval(getMinutesInterval.value)
  })

  return {
    beginsIn: readonly(beginsIn)
  }
}

export default useBeginsIn
