
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    strokeColor: {
      type: String,
      default: 'var(--white)'
    },
    fillColor: {
      type: String,
      default: 'transparent'
    },
    size: {
      type: Number,
      default: 24
    },
    viewBox: {
      type: String,
      default: '0 0 24 24'
    },
    withWrapper: {
      type: Boolean,
      default: false
    },
    wrapperXl: { type: Boolean, default: false },
    wrapperS: { type: Boolean, default: false },
    wrapperBorder: { type: String, default: '' }
  }
})
