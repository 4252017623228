import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, withCtx as _withCtx, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5a36573f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "t2" }
const _hoisted_2 = {
  key: 1,
  class: "divider"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorPage = _resolveComponent("ErrorPage")!
  const _component_toast = _resolveComponent("toast")!
  const _component_skeleton_date_card = _resolveComponent("skeleton-date-card")!
  const _component_placeholder_state = _resolveComponent("placeholder-state")!
  const _component_date_card = _resolveComponent("date-card")!
  const _component_end_course_card = _resolveComponent("end-course-card")!
  const _component_rating_summary = _resolveComponent("rating-summary")!
  const _component_student_ranking = _resolveComponent("student-ranking")!
  const _component_upselling_card = _resolveComponent("upselling-card")!
  const _component_skeleton_post = _resolveComponent("skeleton-post")!
  const _component_dashboard_header = _resolveComponent("dashboard-header")!
  const _component_post_community = _resolveComponent("post-community")!
  const _component_ToTopButton = _resolveComponent("ToTopButton")!
  const _component_loading_more_posts = _resolveComponent("loading-more-posts")!
  const _component_two_cols_layout = _resolveComponent("two-cols-layout")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_onboarding = _resolveComponent("onboarding")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isError.posts && _ctx.isError.scheduleRating)
      ? (_openBlock(), _createBlock(_component_ErrorPage, {
          key: 0,
          copies: _ctx.copies.error
        }, null, 8, ["copies"]))
      : _createCommentVNode("", true),
    (!_ctx.loading)
      ? (_openBlock(), _createBlock(_component_toast, {
          key: 1,
          onOnRetryCallback: _ctx.handleAddMorePosts,
          showRetryMessage: _ctx.currentToast === 'loadMorePosts'
        }, null, 8, ["onOnRetryCallback", "showRetryMessage"]))
      : _createCommentVNode("", true),
    _createVNode(_component_two_cols_layout, null, {
      aside: _withCtx(() => [
        (_ctx.loading)
          ? (_openBlock(), _createBlock(_component_skeleton_date_card, { key: 0 }))
          : (_ctx.isError.scheduleRating && !_ctx.loading)
            ? (_openBlock(), _createBlock(_component_placeholder_state, {
                key: 1,
                role: _ctx.user.role,
                isComponentError: ""
              }, null, 8, ["role"]))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                (_ctx.nextClass?.day && !_ctx.courseEnded)
                  ? (_openBlock(), _createBlock(_component_date_card, {
                      key: 0,
                      nextClass: _ctx.nextClass,
                      role: _ctx.user.role,
                      copies: _ctx.copies.nextClass
                    }, null, 8, ["nextClass", "role", "copies"]))
                  : _createCommentVNode("", true),
                (_ctx.courseEnded && _ctx.user.role === 2)
                  ? (_openBlock(), _createBlock(_component_end_course_card, {
                      key: 1,
                      courseName: _ctx.course.name,
                      link: _ctx.courseEndedLink
                    }, null, 8, ["courseName", "link"]))
                  : _createCommentVNode("", true),
                (_ctx.user.role === 3)
                  ? (_openBlock(), _createBlock(_component_rating_summary, {
                      key: 2,
                      ratingData: _ctx.ratingData,
                      courseName: _ctx.course.name,
                      isTutor: _ctx.user.isTutor,
                      isFinish: _ctx.courseEnded,
                      camadaNro: _ctx.course.camadaNro.toString()
                    }, null, 8, ["ratingData", "courseName", "isTutor", "isFinish", "camadaNro"]))
                  : _createCommentVNode("", true),
                (_ctx.user.role === 2 && !_ctx.courseEnded && _ctx.userRanking?.rank)
                  ? (_openBlock(), _createBlock(_component_student_ranking, {
                      key: 3,
                      progress: _ctx.userRanking.leaderboard.completed,
                      total: _ctx.userRanking.leaderboard.amount,
                      ranking: _ctx.userRanking.rank,
                      copies: _ctx.copies
                    }, null, 8, ["progress", "total", "ranking", "copies"]))
                  : _createCommentVNode("", true),
                (_ctx.courseEnded && _ctx.user.role === 2)
                  ? (_openBlock(), _createBlock(_component_upselling_card, {
                      key: 4,
                      offer: _ctx.perk.discount,
                      course: _ctx.perk.text,
                      link: _ctx.perk.link,
                      relatedStudents: _ctx.perk.related
                    }, null, 8, ["offer", "course", "link", "relatedStudents"]))
                  : _createCommentVNode("", true)
              ], 64))
      ]),
      content: _withCtx(() => [
        (_ctx.loading)
          ? (_openBlock(), _createBlock(_component_skeleton_post, { key: 0 }))
          : _createCommentVNode("", true),
        (_ctx.isError.posts && !_ctx.loading)
          ? (_openBlock(), _createBlock(_component_placeholder_state, {
              key: 1,
              isComponentError: "",
              role: _ctx.user.role
            }, null, 8, ["role"]))
          : (!_ctx.isError.posts && !_ctx.loading)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.copies.mainTitle), 1),
                _createVNode(_component_dashboard_header, {
                  copies: _ctx.copies,
                  onOnSearch: _ctx.handleSearch,
                  onOnCleanSearch: _ctx.handleCleanSearch,
                  onOnChangeFilters: _ctx.onChangeFilters,
                  onOnChangeNewPost: _ctx.onChangeNewPost
                }, null, 8, ["copies", "onOnSearch", "onOnCleanSearch", "onOnChangeFilters", "onOnChangeNewPost"]),
                (_ctx.searchedPostsNotFound)
                  ? (_openBlock(), _createBlock(_component_placeholder_state, {
                      key: 0,
                      customCopies: {
            line1: _ctx.copies.search.notFound,
            line2: ''
          },
                      role: _ctx.user.role,
                      isComponentError: ""
                    }, null, 8, ["customCopies", "role"]))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pinnedPosts, (post) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: post._id
                  }, [
                    (post.display)
                      ? (_openBlock(), _createBlock(_component_post_community, {
                          key: 0,
                          isPinned: "",
                          onDeletePost: _ctx.deletePost,
                          onHandleSaveUnsavePost: _ctx.handleSaveUnsavePost,
                          post: post,
                          searchedWords: _ctx.searchedWords
                        }, null, 8, ["onDeletePost", "onHandleSaveUnsavePost", "post", "searchedWords"]))
                      : _createCommentVNode("", true)
                  ]))
                }), 128)),
                (_ctx.pinnedPosts.length > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.posts, (post) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: post._id
                  }, [
                    (post.display)
                      ? (_openBlock(), _createBlock(_component_post_community, {
                          key: 0,
                          onOnOpenDialog: _ctx.handleOpenDialog,
                          onOnSaveUnsavePost: _ctx.handleSaveUnsavePost,
                          post: post,
                          searchedWords: _ctx.searchedWords
                        }, null, 8, ["onOnOpenDialog", "onOnSaveUnsavePost", "post", "searchedWords"]))
                      : _createCommentVNode("", true)
                  ]))
                }), 128)),
                _createVNode(_component_ToTopButton),
                (_ctx.loadingMore)
                  ? (_openBlock(), _createBlock(_component_loading_more_posts, { key: 2 }))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_Dialog, {
      visible: _ctx.displayDialog,
      modal: true,
      "onUpdate:visible": _ctx.handleCloseDialog
    }, {
      header: _withCtx(() => [
        _createElementVNode("h3", null, _toDisplayString(_ctx.copies.modalDeletePost.header), 1)
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: _ctx.copies.modalDeletePost.cancel,
          class: "secondary",
          onClick: _ctx.handleCloseDialog
        }, null, 8, ["label", "onClick"]),
        _createVNode(_component_Button, {
          label: _ctx.copies.modalDeletePost.delete,
          loading: _ctx.pendingDelete,
          onClick: _ctx.deletePost
        }, null, 8, ["label", "loading", "onClick"])
      ]),
      default: _withCtx(() => [
        _createTextVNode(" " + _toDisplayString(_ctx.copies.modalDeletePost.subtitle) + " ", 1)
      ]),
      _: 1
    }, 8, ["visible", "onUpdate:visible"]),
    _createVNode(_component_onboarding, {
      userId: _ctx.user.id,
      role: _ctx.user.role,
      isTutor: _ctx.user.isTutor
    }, null, 8, ["userId", "role", "isTutor"])
  ], 64))
}