
import { defineComponent, onMounted, PropType, reactive, ref } from 'vue'

// Vuelidate
import { helpers, and, url, requiredIf, maxLength, minLength } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'

// Components
import InputSwitch from 'primevue/inputswitch'
import FileUpload from 'primevue/fileupload'
import InputText from 'primevue/inputtext'
import Textarea from 'primevue/textarea'
import InputWrapper from '../../../components/aero/inputs/InputWrapper.vue'
// import ProfileSkills from './ProfileSkills.vue'
// import ProfileExpertise from './ProfileExpertise.vue'
import TermsModal from './modals/TermsModal.vue'
import ProfileLanguages from './ProfileLanguages.vue'
import ProfileGoals from './ProfileGoals.vue'

// Copies
import copies from '@/locales/profile/es.json'

import { setCurriculum, uploadCurriculum, getCurriculum } from '@/services/newPlatform/profile.services'
import { useUserSessionStore } from '@/store/modules/user-session.store'
import { useUserStore } from '@/store/modules/user.store'
// import { useLocaleStore } from '@/store/modules/locale.store'
// import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    InputSwitch,
    FileUpload,
    InputText,
    Textarea,
    InputWrapper,
    // ProfileSkills,
    // ProfileExpertise,
    ProfileLanguages,
    TermsModal,
    ProfileGoals
  },
  props: {
    information: { type: Object as PropType<{ [x: string]: any }>, default: () => ({}) },
    loadingSubmit: { type: Boolean, default: false }
  },
  emits: ['onSubmit', 'onCancel'],
  setup(props, { emit }) {
    const questions: any = copies.editProfile
    const expertiseComponent = ref()
    const languagesComponent = ref()
    const skillsComponent = ref()
    const showLanguagesError = ref(false)
    const showSkillError = ref(false)
    const showExpertiseError = ref(false)
    const showCVError = ref(false)
    const { userId } = useUserSessionStore()
    const user = useUserStore()
    const infoCV = ref('')
    const fileUpload = ref()

    const charAllowed = {
      min: 70,
      max: 300
    }

    interface State {
      showTalent: boolean
      bio: string
      cv: string
      behance: string
      linkedin: string
      twitter: string
      github: string
      website: string
      facebook: string
      instagram: string
      dribbble: string
      youtube: string
      vimeo: string
    }

    const state: State = reactive({
      showTalent: false,
      bio: '',
      cv: '',
      behance: '',
      linkedin: '',
      twitter: '',
      github: '',
      website: '',
      facebook: '',
      instagram: '',
      dribbble: '',
      youtube: '',
      vimeo: ''
    })

    const isCorrectUrl = (value: string, page: string): boolean => {
      const isValidDomain = page === 'behance' ? value.includes(`${page}.net`) : value.includes(`${page}.com`)
      return !value || page === 'website' || isValidDomain
    }

    const socialNetworksValidators = [
      'behance',
      'linkedin',
      'twitter',
      'github',
      'website',
      'facebook',
      'instagram',
      'dribbble',
      'youtube',
      'vimeo'
    ].reduce(
      (acc, page) => ({
        ...acc,
        [page]: {
          correctUrl: helpers.withMessage(
            'editProfile.socialNetworks.invalidFormat',
            and((value: unknown) => isCorrectUrl(value as string, page), url)
          )
        }
      }),
      {}
    )

    const getRules = () => {
      return user.country === 'br' ? {...socialNetworksValidators} : {
        bio: {
          requiredIfFuction: helpers.withMessage(
            'editProfile.bio.requiredField',
            requiredIf(() => !!state.showTalent)
          ),
          minLengthValue: helpers.withMessage('editProfile.bio.minLength', minLength(charAllowed.min)),
          maxLengthValue: helpers.withMessage('editProfile.bio.maxLength', maxLength(charAllowed.max))
        },
        ...socialNetworksValidators
      }
    }

    const rules = getRules()

    const validations = useVuelidate(rules, state)

    const withDefaultValuesQuestions = () => {
      state.showTalent = (props.information.showTalent as boolean) || false
      state.bio = (props.information.bio as string) || ''
      state.cv = (props.information.cv as string) || ''
      state.behance = (props.information.socialNetworks.behance as string) || ''
      state.linkedin = (props.information.socialNetworks.linkedin as string) || ''
      state.twitter = (props.information.socialNetworks.twitter as string) || ''
      state.github = (props.information.socialNetworks.github as string) || ''
      state.website = (props.information.socialNetworks.website as string) || ''
      state.facebook = (props.information.socialNetworks.facebook as string) || ''
      state.instagram = (props.information.socialNetworks.instagram as string) || ''
      state.dribbble = (props.information.socialNetworks.dribbble as string) || ''
      state.youtube = (props.information.socialNetworks.youtube as string) || ''
      state.vimeo = (props.information.socialNetworks.vimeo as string) || ''
    }

    const handledOnMounted = async () => {
      let { name: cvName, size: cvSize } = await getCurriculum(userId)
      cvSize = (parseFloat(cvSize) / 1024 / 1024).toFixed(2).toString()

      infoCV.value = cvName ? `${cvName} ${cvSize}MB` : ''
      withDefaultValuesQuestions()
    }

    onMounted(handledOnMounted)

    const onUpload = (event: any) => {
      uploadCurriculum(userId, event.files[0])
      const cvSize = (parseFloat(event.files[0].size) / 1024 / 1024).toFixed(2).toString()
      infoCV.value = `${event.files[0].name} ${cvSize}MB`
      fileUpload.value.clear()
      showCVError.value = false
    }

    const removeCV = () => {
      setCurriculum(userId, {})
      infoCV.value = ''
      showCVError.value = user.country !== 'br'
    }

    const showSocialError = ref(false)

    const turnOffSocialError = () => {
      showSocialError.value = false
    }

    const handleSubmit = async () => {
      validations.value.$touch()
      const isFormCorrect = await validations.value.$validate()
      if (!isFormCorrect) {
        window.scrollTo(0, 0)
        return
      }

      if (!state.showTalent) {
        emit('onSubmit', state)
        return
      }

      let atLeastOneSocial = false
      for (const input in state) {
        if (input === 'bio' || input === 'cv' || input === 'showTalent') continue
        atLeastOneSocial = !!state[input as keyof State]
        if (atLeastOneSocial) break
      }

      showSocialError.value = !atLeastOneSocial
      showLanguagesError.value = !languagesComponent.value.languages.length
      // showSkillError.value = !(skillsComponent.value.softSkills.length && skillsComponent.value.techSkills.length)
      
      showCVError.value = !infoCV.value

      if (user.country === 'br') {
        state.showTalent = false
      }

      if (
        !showSocialError.value &&
        !showLanguagesError.value &&
        !showExpertiseError.value &&
        !showSkillError.value &&
        !showCVError.value
      )
        openModal()
    }

    const handlePublish = () => {
      emit('onSubmit', state)
    }

    const switchTalent = () => {
      if (state.showTalent) {
        validations.value.$touch()

        let atLeastOneSocial = false

        for (const input in state) {
          if (input === 'bio' || input === 'cv' || input === 'showTalent') continue
          atLeastOneSocial = !!state[input as keyof State]
          if (atLeastOneSocial) break
        }

        showSocialError.value = !atLeastOneSocial
        showLanguagesError.value = !languagesComponent.value.languages.length
        // showSkillError.value = !(skillsComponent.value.softSkills.length && skillsComponent.value.techSkills.length)
        showCVError.value = !infoCV.value
      } else {
        showExpertiseError.value = false
        showLanguagesError.value = false
        showSkillError.value = false
        showCVError.value = false
        showSocialError.value = false
      }
    }

    const handleCancel = () => {
      emit('onCancel')
    }

    const displayModal = ref(false)

    const closeModal = async () => {
      displayModal.value = false
    }

    const openModal = () => {
      displayModal.value = true
    }

    return {
      user,
      questions,
      handleSubmit,
      handleCancel,
      validations,
      state,
      onUpload,
      showSocialError,
      turnOffSocialError,
      switchTalent,
      expertiseComponent,
      languagesComponent,
      skillsComponent,
      showExpertiseError,
      showLanguagesError,
      showSkillError,
      showCVError,
      displayModal,
      closeModal,
      handlePublish,
      removeCV,
      infoCV,
      fileUpload,
      charAllowed
    }
  }
})
