
// Vendor
import { defineComponent, onMounted, ref, toRefs } from 'vue'
import { useRoute } from 'vue-router'

// Hooks
import { useToast } from 'primevue/usetoast'

// Components
import TwoColsLayout from '@/components/aero/layout/TwoColsLayout.vue'
import ProfileCard from '@/components/aero/surfaces/profile/ProfileCard.vue'
import ProfileCardStudent from '@/components/aero/surfaces/profile/ProfileCardStudent.vue'
import PlaceholderState from '@/components/aero/surfaces/challenges/PlaceholderState.vue'
import ProfileStudent from '@/views/profile/components/ProfileStudent.vue'
import ProfileCardConnection from '@/components/aero/surfaces/profile/ProfileCardConnection.vue'
import ProfileTeacher from '@/views/profile/components/ProfileTeacher.vue'
import FormEditProfile from '@/views/profile/components/FormEditProfile.vue'
import SkeletonProfile from '@/components/aero/skeleton/SkeletonProfile.vue'
import CustomTransition from '@/components/aero/misc/CustomTransition.vue'
import Loader from '@/components/commons/Loader.vue'

// Copies
import { useI18n } from 'vue-i18n'

// User store
import { useUserStore } from '@/store/modules/user.store'
import { themeStore } from '@/store/modules/themes.store'
import { useCourseStore } from '@/store/modules/course.store'
import { useUserSessionStore } from '@/store/modules/user-session.store'
import { useLocaleStore } from '@/store/modules/locale.store'

// Services
import { getProfileInformation, updateProfileService, updateTalentService } from '@/services/newPlatform/profile.services'
import { UpdateOnboardingArgs, UpdateProfileArgs, UpdateSocialNetworksArgs } from '@/models/profile/profile'

// API
import { getUserById } from '@/api/user'

// models
import { IUser } from '@/models/user'

// Amplitude
import { trackEvent } from '@/amplitude/actions'
import { ProfileEvents } from '@/amplitude/constants'
import { ToastMessageOptions } from 'primevue/toast'

export default defineComponent({
  components: {
    TwoColsLayout,
    ProfileStudent,
    ProfileTeacher,
    ProfileCard,
    PlaceholderState,
    FormEditProfile,
    SkeletonProfile,
    ProfileCardStudent,
    ProfileCardConnection,
    CustomTransition,
    Loader
  },
  setup() {
    const toast = useToast()
    const localeStore = useLocaleStore()
    const { isLoading: isLocaleLoading } = toRefs(localeStore)

    const route = useRoute()
    const { userId: userIdParams } = route.params

    const isVisitorView = ref(userIdParams !== undefined)

    const { userId } = useUserSessionStore()
    const user = ref<IUser>({
      id: '',
      role: 0,
      roles: [],
      level: '',
      email: '',
      avatar: '',
      firstName: '',
      lastName: '',
      fullName: '',
      dni: '',
      isTutor: false,
      hasBeca: false,
      country: 'ar',
      isNotFromArg: false,
      phone: '',
      getStreamToken: '',
      subscription: [],
      preferencesFlag: false,
      coderPlus: false
    })
    const courseName = ref('')
    const userIdToFetch = ref(isVisitorView.value ? userIdParams : userId)

    const theme = themeStore()

    theme.changeDefault('aero')

    const information = ref({})

    const isLoading = ref<boolean>(false)
    const { t } = useI18n()

    const isError = ref<boolean>(false)

    const editingProfile = ref<boolean>(false)

    const loadingSubmitEditProfile = ref<boolean>(false)
    const profileCardComponent = ref()

    const fetchUserToVisit = async () => {
      isLoading.value = true

      try {
        const userVisited = await getUserById(userIdToFetch.value.toString())
        if (!userVisited) {
          // eslint-disable-next-line
          console.error('Error fetching visitor user: Not user found')
          isError.value = true
        } else {
          user.value = userVisited
        }
      } catch (error) {
        // eslint-disable-next-line
        console.error('Error fetching visitor user: ', error)
        isError.value = true
        isLoading.value = false
      }
    }

    const handleMountedComponent = async () => {
      try {
        isLoading.value = true
        const { profile, personalGoal, flags, socialNetworks } = await getProfileInformation({
          userId: userIdToFetch.value.toString()
        })

        Object.assign(information.value, {
          ...information.value,
          ...(profile.bio && { bio: profile.bio }),
          ...(profile.first_name && { firstName: profile.first_name }),
          ...(profile.last_name && { lastName: profile.last_name }),
          ...(profile.country && { country: profile.country }),
          ...(profile.cityV2 && { cityV2: profile.cityV2 }),
          ...(profile.curp && { curp: profile.curp }),
          ...(profile.bday && { bday: profile.bday }),
          ...(user.value.email && { email: user.value.email }),
          // TODO: Check personal goals since we are not sure where to get the information from (which property)
          ...(personalGoal && { personalGoal }),
          // TODO: Check if there's another property to display different subscription type
          ...(flags.hasBeca && { hasBeca: flags.hasBeca }),
          ...(socialNetworks && { socialNetworks }),
          ...{ showTalent: profile.showTalent ?? false },
          ...(user.value.phone && { phone: user.value.phone }),
          ...(user.value.id && { chatLink: `/chat/${user.value.id}` })
        })
      } catch (error) {
        // eslint-disable-next-line
        console.error('Error fetching profile information: ', error)
        isError.value = true
      } finally {
        isLoading.value = false
      }
    }

    /** Method to render or not edit profile view */
    const toggleEditProfile = () => {
      editingProfile.value = !editingProfile.value
      handleMountedComponent()
    }

    /** Method to handle click on submit button when all validations pass */
    const handleSubmitEditProfile = async (values: { [x: string]: unknown }) => {
      loadingSubmitEditProfile.value = true

      if (!(await profileCardComponent.value.handleSubmit())) {
        loadingSubmitEditProfile.value = false
        return
      }
      let parsedValues = { ...values, ...profileCardComponent.value.state }

      parsedValues = {
        ...parsedValues,
        first_name: parsedValues.firstName,
        last_name: parsedValues.lastName
      }

      parsedValues.socialNetworks = {
        behance: parsedValues.behance,
        linkedin: parsedValues.linkedin,
        twitter: parsedValues.twitter,
        github: parsedValues.github,
        website: parsedValues.website,
        facebook: parsedValues.facebook,
        instagram: parsedValues.instagram,
        dribbble: parsedValues.dribbble,
        youtube: parsedValues.youtube,
        vimeo: parsedValues.vimeo
      }

      let bday
      try {
        bday = new Date(parsedValues.bday).toISOString()
      } catch (error) {
        const dateSplited = parsedValues.bday.split('/')
        bday = new Date(`${dateSplited[2]}/${dateSplited[1]}/${dateSplited[0]}`).toISOString()
      }

      parsedValues.bday = bday

      const success = await updateProfileService({ userId, ...parsedValues } as UpdateOnboardingArgs &
        UpdateProfileArgs & { socialNetworks: { [x: string]: string } })

      loadingSubmitEditProfile.value = false

      if (success) updateTalentService()

      const successStr = success ? t('toast.updateProfile.success') : t('toast.updateProfile.error')

      toast.add({
        severity: successStr as ToastMessageOptions['severity'],
        detail: successStr,
        life: 3000
      })

      information.value = { ...information.value, ...values }

      trackEvent(ProfileEvents.EDITED_PROFILE)

      toggleEditProfile()
    }

    onMounted(async () => {
      if (isVisitorView.value) {
        await fetchUserToVisit()
      } else {
        user.value = useUserStore()

        const { name } = useCourseStore()
        courseName.value = name
      }

      handleMountedComponent()
    })

    return {
      user,
      isLoading,
      userId,
      isError,
      courseName,
      information,
      editingProfile,
      loadingSubmitEditProfile,
      toggleEditProfile,
      handleSubmitEditProfile,
      isVisitorView,
      userIdParams,
      profileCardComponent,
      isLocaleLoading
    }
  }
})
