import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bb2ab95e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-ml-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SkeletonIcon = _resolveComponent("SkeletonIcon")!
  const _component_SkeletonText = _resolveComponent("SkeletonText")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { class: "p-d-flex p-ai-center" }, {
    default: _withCtx(() => [
      _createVNode(_component_SkeletonIcon, { class: "icon" }),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_SkeletonText, { length: 15 }),
        _createVNode(_component_SkeletonText, {
          class: "p-mt-2",
          length: 12
        })
      ])
    ]),
    _: 1
  }))
}