import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22f27cfe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "columnOpinion p-py-4 border-radius bg-dark-gray" }
const _hoisted_2 = { class: "p-d-flex p-jc-between p-mx-4" }
const _hoisted_3 = { class: "p-d-flex p-flex-column" }
const _hoisted_4 = { class: "p2 text-light-gray p-mb-1 label__uppercase" }
const _hoisted_5 = { class: "p2 smallCard__description p-d-flex gap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SkeletonText = _resolveComponent("SkeletonText")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SkeletonText, { length: 7 }),
      _createVNode(_component_SkeletonText, { length: 3 })
    ]),
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(6, (i) => {
      return _createVNode(_component_Card, {
        class: "smallCard p-d-flex p-jc-between p-ai-center",
        key: i
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, [
              _createVNode(_component_SkeletonText, { length: 9 })
            ]),
            _createElementVNode("span", _hoisted_5, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(2, (i) => {
                return _createVNode(_component_SkeletonText, {
                  length: 7,
                  key: i
                })
              }), 64))
            ])
          ]),
          _createVNode(_component_SkeletonText, { length: 3 })
        ]),
        _: 2
      }, 1024)
    }), 64))
  ]))
}