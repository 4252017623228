<template>
  <div class="ch-login-slides-container window-drag">
    <div class="gradient ch-abs-full"></div>
    <div class="content ch-rel-full">
      <div class="slide-list ch-rel-full" :style="{ left: -width * current + '%' }">
        <div class="actual-content login-slide" v-for="(item, index) in slides" :key="index">
          <div class="tag" :class="item.tagClass">
            <span>{{ item.hashtag }}</span>
          </div>
          <div class="ch-title">
            <span v-html="item.title"></span>
          </div>
          <span class="dots" :class="item.colorClass"> <span>.</span><span>.</span><span>.</span> </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useUserStore } from '@/store/modules/user.store'

export default defineComponent({
  setup() {
    const { t } = useI18n()
    const userTmp = useUserStore()
    const current = ref(0)
    const timer = ref(0)
    const width = 100

    const nextSlide = () => {
      current.value++
      if (current.value >= slides.value.length) current.value = 0
      resetPlay()
    }
    const resetPlay = () => {
      clearInterval(timer.value)
      play()
    }
    const play = () => {
      timer.value = setInterval(function () {
        nextSlide()
      }, 4000)
    }

    const ENV_BR = false
    const slides = ref(
      [
        {
          title: t('ch-login-slides.slides.1.title'),
          colorClass: 'yellow-coder',
          tagClass: 'bg-yellow-coder'
        },
        {
          title: t('ch-login-slides.slides.2.title'),
          colorClass: 'yellow-coder',
          tagClass: 'bg-yellow-coder'
        },
        {
          title: t('ch-login-slides.slides.3.title'),
          colorClass: 'yellow-coder',
          tagClass: 'bg-yellow-coder'
        },
        {
          title: t('ch-login-slides.slides.4.title'),
          colorClass: 'pink-coder',
          tagClass: 'bg-pink-coder'
        },
        {
          title: t('ch-login-slides.slides.5.title'),
          colorClass: 'yellow-coder',
          tagClass: 'bg-yellow-coder'
        }
      ].map((item) => ({
        ...item,
        hashtag: `#${item.hashtag || t('ch-login-slides.hashtag')}`
      }))
    )

    onMounted(() => {
      if (userTmp.country === 'br') {
        slides.value.splice(1, 1)
      }
      play()
    })

    return {
      slides,
      width,
      current
    }
  }
})
</script>

<style lang="scss" scoped>
$yellow-coder: #e5f876;
$pink-coder: #ffbae7;

.yellow-coder {
  color: $yellow-coder;
}
.bg-yellow-coder {
  background-color: $yellow-coder;
}
.pink-coder {
  color: $pink-coder;
}
.bg-pink-coder {
  background-color: $pink-coder;
}
.ch-login-slides-container {
  padding: 0;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background: #ffffff;

  .gradient {
    background: #000000;
    opacity: 0.9;
  }

  .ch-abs-full {
    position: absolute;
    --offset: 0;
    --offset-x: var(--offset);
    --offset-y: var(--offset);
    top: var(--offset-y);
    bottom: var(--offset-y);
    right: var(--offset-x);
    left: var(--offset-x);
  }

  .content {
    color: #ffffff;

    .slide-list {
      transition: left cubic-bezier(0.7, 0, 0.25, 1);
      transition-duration: 1.5s;
      display: flex;
      align-items: center;
    }

    .login-slide {
      min-width: 100%;
    }

    .actual-content {
      padding: 3vw;

      .tag {
        padding: 0.15em 0.5em;
        margin-bottom: 0.75em;
        display: inline-block;
        font-size: 0.7em;
        color: #333;
      }

      .ch-title > * {
        font-size: 3em;
        font-weight: 600;
      }

      .dots {
        $offset: 0.3em;
        @keyframes dot-animation {
          0% {
            transform: translateY(0);
          }

          30% {
            transform: translateY(-$offset);
          }

          60% {
            transform: translateY(0);
          }

          100% {
            transform: translateY(0);
          }
        }

        display: inline-block;

        line-height: 1;

        > span {
          $dur: 1.5s;
          font-weight: 600;
          font-size: 3em;
          font-family: var(--font-title-v2);
          display: inline-block;
          animation: dot-animation $dur cubic-bezier(0, 0.5, 0.5, 1) infinite;
          margin-right: 0.1em;

          &:nth-child(2) {
            animation-delay: $dur/10;
          }

          &:nth-child(3) {
            animation-delay: $dur/10 * 2;
            margin-right: 0;
          }
        }
      }
    }
  }

  .ch-rel-full {
    position: relative;
    width: 100%;
    height: 100%;
  }
}
</style>