<template>
  <div class='password-container'>
  <PasswordChangeForm :userId="userId" @onSuccessChange="successMsg" @onErrorChange="errorMsg"></PasswordChangeForm>
  </div>
</template>

<script >
import { defineComponent } from 'vue'
import PasswordChangeForm from '@/components/login/PasswordChangeForm.vue'
import { useUserSessionStore } from '@/store/modules/user-session.store'
import { useToast } from 'primevue/usetoast'

export default defineComponent({
  components: {
    PasswordChangeForm
  },

  setup () {

    const { userId } = useUserSessionStore()
    const toast = useToast()

    const successMsg = () => {
      toast.add({
        severity: 'success',
        detail: '¡Listo! Se actualizó tu contraseña ✨',
        life: 2500
      })
    }

    const errorMsg = () => {
      toast.add({
        severity: 'error',
        detail: 'Hubo un error al cambiar la contraseña',
        life: 2500
      })
    }

    return {
      userId,
      successMsg,
      errorMsg
    }
  }
})
</script>


<style lang="scss" scoped>
.password-container {
  display: flex;
  justify-content: center;
  padding: 75px 0;
}
</style>