import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d9720f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card__wrapper" }
const _hoisted_2 = { class: "p-pl-4" }
const _hoisted_3 = {
  key: 0,
  class: "p3 text-purple medium p-mb-2"
}
const _hoisted_4 = { class: "card__text text-white p1" }
const _hoisted_5 = {
  key: 1,
  class: "card__description text-light-gray p2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_flag = _resolveComponent("flag")!
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card__container", _ctx.border ? 'rounded-border' : ''])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_icon, {
        withWrapper: "",
        wrapperXl: "",
        wrapperBorder: "lime-light",
        strokeColor: "var(--lime-light)",
        size: 32,
        viewbox: "0 0 32 32"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_flag)
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_2, [
        (_ctx.status)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.status), 1))
          : _createCommentVNode("", true),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.text), 1),
        (!_ctx.status && _ctx.description)
          ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.description), 1))
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}