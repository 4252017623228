import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4d3eb2de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-d-flex p-jc-between p-ai-center" }
const _hoisted_2 = { class: "p-m-6 correction-label" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_icon_base = _resolveComponent("icon-base")!
  const _component_DynamicCard = _resolveComponent("DynamicCard")!

  return (_openBlock(), _createBlock(_component_DynamicCard, {
    class: _normalizeClass(`p-mb-3 ${_ctx.correction.status === _ctx.CorrectionStates.BLOCKED && 'disabled-correction-card'}`),
    borderless: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.toOrdinal(_ctx.index + 1, true)) + " " + _toDisplayString(_ctx.t('homework.challenge.peerReview.card.correction')), 1),
        (_ctx.correction.status === _ctx.CorrectionStates.READY_FOR_REVIEW)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_Button, {
                label: _ctx.t('homework.challenge.peerReview.card.button.label'),
                onClick: _ctx.next,
                class: "p-m-4 p-button-lg send p-button-custom-primary"
              }, null, 8, ["label", "onClick"])
            ]))
          : (_openBlock(), _createBlock(_component_icon_base, {
              key: 1,
              class: "p-m-4",
              width: "24",
              height: "24",
              viewBox: "0 0 24 24",
              "stroke-color": _ctx.iconColor(_ctx.correction.status)
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.correction.icon)))
              ]),
              _: 1
            }, 8, ["stroke-color"]))
      ])
    ]),
    _: 1
  }, 8, ["class"]))
}