import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-60dd8307"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-mt-3 p-mb-3" }
const _hoisted_2 = { class: "statement p-py-2 p-px-6" }
const _hoisted_3 = ["data"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_h8 = _resolveComponent("h8")!
  const _component_DynamicCard = _resolveComponent("DynamicCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DynamicCard, {
      borderless: "",
      shadowless: "",
      backgroundColor: "#393B43",
      class: "p-pt-4"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_h8, { class: "statement-title p-px-6" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('homework.challenge.statement.title')), 1)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("object", {
            data: `${_ctx.file}${_ctx.queryParams}`,
            width: "100%",
            height: "400px"
          }, null, 8, _hoisted_3)
        ])
      ]),
      _: 1
    })
  ]))
}