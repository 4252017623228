// Models
import { IAttendances } from '@/models/attendance/attendance'

import { Api } from '../api'
const { API_ENDPOINT } = process.env

/**
 *
 * @param camadaNro  camada number
 * @param teacherId id of the teacher/tutor
 * @returns {Promise} Returns a list of students, tutors with attendance data and schedules.
 * User with role tutor retrieves the list of students assigned
 * User with role teacher retrieves the list of students of the whole commission
 */

export const getAttendances = async (camadaNro: string, teacherId: string): Promise<IAttendances> => {
  return await Api.get(
    `${API_ENDPOINT}/platform/camadas/${camadaNro}/schedules/attendances?camadaId=${camadaNro}&teacherId=${teacherId}&ts=${Date.now()}`
  )
}

/**
 *
 * @param camadaId  camadaId. Is not the same as camadaNro
 * @param scheduleId id associate with the class to update attendance
 * @param studentId  id of the student to update attendance
 * @returns {Promise} Returns if the operation was fullfilled
 */

export const setAttendance = async (camadaId: string, scheduleId: string, studentId: string) => {
  return await Api.put(
    `${API_ENDPOINT}/platform/camadas/${camadaId}/schedules/${scheduleId}/attendance/${studentId}?ts=${Date.now()}`,
    { ts: Date.now() }
  )
}

/**
 *
 * @param camadaId  camadaId. Is not the same as camadaNro
 * @param scheduleId id associate with the class to update attendance
 * @param studentId  id of the student to update attendance
 * @returns {Promise} Returns if the operation was fullfilled
 */

export const setAbsent = async (camadaId: string, scheduleId: string, studentId: string) => {
  return await Api.put(
    `${API_ENDPOINT}/platform/camadas/${camadaId}/schedules/${scheduleId}/absent/${studentId}?ts=${Date.now()}`,
    { ts: Date.now() }
  )
}
