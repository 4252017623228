
// Vendor
import { defineComponent } from 'vue'

// Components
import Card from '../surfaces/Card.vue'
import SkeletonText from './SkeletonText.vue'
import SkeletonIcon from './SkeletonIcon.vue'

export default defineComponent({
  components: {
    Card,
    SkeletonText,
    SkeletonIcon
  }
})
