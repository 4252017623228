
import { defineComponent, onMounted, PropType, ref, Ref, watch, computed } from 'vue'
import InputText from 'primevue/inputtext'
import { useI18n } from 'vue-i18n'

import InputWrapper from '@/components/aero/inputs/InputWrapper.vue'
import { useUserSessionStore } from '@/store/modules/user-session.store'
import { useUserStore } from '@/store/modules/user.store'
import { UserBalance } from '@/models/profile/balance'

const isValidEmail = (email: string): boolean => {
  const re = /\S+@\S+\.\S+/
  return re.test(email)
}

export default defineComponent({
  components: {
    InputText,
    InputWrapper
  },
  props: {
    displayModal: { type: Boolean, default: false },
    balance: { type: Object as PropType<UserBalance>}
  },
  emits: ['closeModal', 'transferBalance'],
  setup(props, { emit }) {
    const { t } = useI18n()
    const messageTemplate = t('profile.available-balance.modal.message')

    const user = useUserStore()
    const { userId } = useUserSessionStore()

    const dstEmail = ref()

    const closeModal = () => {
      emit('closeModal')
    }

    const transfer = () => {
      emit('transferBalance', dstEmail.value, props.balance)
    }

    const modal = computed(() => {
      return props.displayModal
    })

    const validEmail = computed(() => {
      return isValidEmail(dstEmail.value)
    })

    const message = computed(() => {
      const balance = props.balance
      if (!balance) {
        return ''
      }

      let formattedPrice = props.balance?.price?.toLocaleString('es-AR') || ''

      const currency = balance.currency

      formattedPrice = !currency || currency.symbol_position === 'pre'
        ? `${currency?.symbol} ${formattedPrice}`
        : `${formattedPrice} ${currency?.symbol}`

      return messageTemplate.replaceAll('%price%', formattedPrice)
    })

    return {
      t,
      message,
      closeModal,
      transfer,
      modal,
      user,
      dstEmail,
      validEmail
    }
  }
})
