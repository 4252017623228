
import { defineComponent, reactive } from 'vue'

export default defineComponent({
  name: 'Login',

  props: {
    error: [String, Error]
  },

  emits: ['onSubmit'],

  setup (props, { emit }) {
    const data = reactive({
      userName: '',
      password: '',
      showPassword: false
    })

    const submit = () => {
      const { userName, password } = data
      emit('onSubmit', { userName, password })
    }

    return {
      data,
      submit
    }
  }
})
