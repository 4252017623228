
import { defineComponent, ref, PropType } from 'vue'
// Components
import RichTextEditor from '@/components/aero/inputs/RichTextEditor.vue'
import SelectInput from '@/components/aero/inputs/SelectInput.vue'

// Service
import { onSubmitSoftScore, onSubmitFinalScore, onApproved } from '@/services/challenges.services'
import { postDeliverMessage } from '@/api/newPlatform/challenges.api'

// Models
import { ISelect } from '@/models/commons/InputSelect'
import { IDeliverReview } from '@/models/newPlatform/challenges/deliver'

// Locales
import { ReviewChallenge as copies } from '@/locales/challenges/es.json'

// Amplitude
import { ChallengesEvents } from '@/amplitude/constants'
import { trackEvent } from '@/amplitude/actions'

export default defineComponent({
  components: {
    RichTextEditor,
    SelectInput
  },
  props: {
    isPreIntegrator: { type: Boolean, default: false },
    isIntegrator: { type: Boolean, default: false },
    isTutor: { type: Boolean, default: true },
    teacherId: { type: String, required: true },
    userId: { type: String, required: true },
    project: { type: Object as PropType<IDeliverReview>, required: true }
  },
  setup(props, { emit }) {
    const comment = ref<string>('')
    const selectedOption = ref<string>('')

    const isLoading = ref<boolean>(false)
    const isDeliverAgainPending = ref<boolean>(false)

    const softScoreOptions: ISelect[] = [
      { label: copies.select.softScore.highScore.label, value: copies.select.softScore.highScore.value },
      { label: copies.select.softScore.mediumScore.label, value: copies.select.softScore.mediumScore.value },
      { label: copies.select.softScore.lowScore.label, value: copies.select.softScore.lowScore.value }
    ]

    const finalScoreOptions: ISelect[] = [
      { label: '10', value: '10' },
      { label: '9', value: '9' },
      { label: '8', value: '8' },
      { label: '7', value: '7' },
      { label: '6', value: '6' },
      { label: '5', value: '5' },
      { label: '4', value: '4' },
      { label: '3', value: '3' },
      { label: '2', value: '2' },
      { label: '1', value: '1' }
    ]

    // Amplitude track event
    const handleTrackEvent = (name: string, props = {}) => {
      trackEvent(name, props)
    }

    const handleApprovedTp = async () => {
      if (!(props.userId && props.project.courseId && props.project._id)) return
      try {
        isLoading.value = true
        if (comment.value) {
          await postDeliverMessage(props.teacherId, props.project.courseId, props.project._id, true, comment.value, '')
        }
        await onApproved(props.project.courseId, props.project._id, props.project.score, props.project.softScore)

        const text = copies.message.approved
        await postDeliverMessage(props.teacherId, props.project.courseId, props.project._id, false, text, '')

        handleTrackEvent(ChallengesEvents.APPROVE_CHALLENGE, { module_name: props.project.name })

        emit('reviewSuccess', { projectId: props.project._id, approved: true, comment: comment.value })
      } catch (e) {
        emit('reviewFailed')
      } finally {
        isLoading.value = false
      }
    }

    const handleDeliverAgain = async () => {
      if (!(props.userId && props.project.courseId && props.project._id)) return
      try {
        isDeliverAgainPending.value = true
        if (comment.value) {
          await postDeliverMessage(props.teacherId, props.project.courseId, props.project._id, true, comment.value, '')
        }
        const text = copies.message.deliverAgain
        await postDeliverMessage(props.teacherId, props.project.courseId, props.project._id, false, text, '')

        handleTrackEvent(ChallengesEvents.REJECT_CHALLENGE, { module_name: props.project.name })

        emit('reviewSuccess', { projectId: props.project._id, approved: false })
        emit('reload-challenge')
      } catch (e) {
        emit('reviewFailed')
      } finally {
        isDeliverAgainPending.value = false
      }
    }

    const handlePreIntegrator = async () => {
      if (!(props.userId && props.project.courseId && props.project._id && selectedOption.value)) return
      try {
        isLoading.value = true
        if (comment.value) {
          await postDeliverMessage(props.teacherId, props.project.courseId, props.project._id, true, comment.value, '')
        }
        const softScore = softScoreOptions.find((score) => score.value === selectedOption.value)
        await onSubmitSoftScore(props.project, selectedOption.value)
        const text =
          softScore?.label === copies.select.softScore.lowScore.label
            ? copies.message.deliverAgain
            : `${copies.message.softScore} ${softScore?.label}.`

        await postDeliverMessage(props.teacherId, props.project.courseId, props.project._id, false, text, '')

        if (softScore?.label === copies.select.softScore.lowScore.label) {
          handleTrackEvent(ChallengesEvents.REJECT_CHALLENGE, { module_name: props.project.name })
        } else {
          handleTrackEvent(ChallengesEvents.APPROVE_CHALLENGE, { module_name: props.project.name })
        }
        emit('reviewSuccess', {
          projectId: props.project._id,
          approved: selectedOption.value !== copies.select.softScore.lowScore.value,
          comment: comment.value,
          score: softScore?.label
        })
        if (selectedOption.value === copies.select.softScore.lowScore.value) {
          emit('reload-challenge')
        }
      } catch (e) {
        emit('reviewFailed')
      } finally {
        isLoading.value = false
      }
    }

    const handleIntegratorTp = async () => {
      if (!(props.userId && props.project.courseId && props.project._id && selectedOption.value)) return
      try {
        isLoading.value = true
        if (comment.value) {
          await postDeliverMessage(props.teacherId, props.project.courseId, props.project._id, true, comment.value, '')
        }
        await onSubmitFinalScore(props.project, selectedOption.value)
        const text = `${copies.message.score} ${selectedOption.value}.`
        const statusMessage = parseInt(selectedOption.value) >= 7 ? copies.message.approved : copies.message.deliverAgain
        await postDeliverMessage(props.teacherId, props.project.courseId, props.project._id, false, text, '')
        await postDeliverMessage(props.teacherId, props.project.courseId, props.project._id, false, statusMessage, '')

        if (selectedOption.value >= '7') {
          handleTrackEvent(ChallengesEvents.APPROVE_CHALLENGE, { final_score: selectedOption.value })
        } else {
          handleTrackEvent(ChallengesEvents.REJECT_CHALLENGE, { final_score: selectedOption.value })
        }

        emit('reviewSuccess', {
          projectId: props.project._id,
          approved: selectedOption.value >= '7',
          comment: comment.value,
          score: selectedOption.value
        })
        if (selectedOption.value < '7') {
          emit('reload-challenge')
        }
      } catch (e) {
        emit('reviewFailed')
      } finally {
        isLoading.value = false
      }
    }

    /**
     * Handle text inside text editor
     * @param text Text value inside text editor component
     */
    const handleTextEditor: (text: string) => void = (text: string) => {
      comment.value = text
    }

    return {
      comment,
      selectedOption,
      softScoreOptions,
      finalScoreOptions,
      handleApprovedTp,
      handleDeliverAgain,
      handlePreIntegrator,
      handleIntegratorTp,
      copies,
      isLoading,
      isDeliverAgainPending,
      handleTrackEvent,
      handleTextEditor,
      ChallengesEvents
    }
  }
})
