import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61684b32"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-d-flex p-flex-column video" }
const _hoisted_2 = {
  class: "video-player",
  ref: "container"
}
const _hoisted_3 = { class: "video-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_YouTube = _resolveComponent("YouTube")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_YouTube, {
        width: "100%",
        height: _ctx.height,
        src: _ctx.url,
        vars: _ctx.vars,
        ref: "youtube"
      }, null, 8, ["height", "src", "vars"])
    ], 512),
    _createVNode(_component_ProgressBar, { value: 100 }),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("label", null, _toDisplayString(_ctx.title), 1)
    ])
  ]))
}