
// Vendor
import { defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  props: {
    feedbackType: { type: String as PropType<'suggestion' | 'positive'>, required: true },
    title: { type: String },
    tagDescription: { type: String, required: true }
  },
  setup() {
    const { t } = useI18n()
    return {
      t
    }
  }
})
