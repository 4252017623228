import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-d-flex p-flex-column p-ai-start p-jc-start items-card" }
const _hoisted_2 = { class: "items-card__items p-py-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_small_card = _resolveComponent("small-card")!
  const _component_item = _resolveComponent("item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_small_card, {
      title: _ctx.copies.title,
      subtitle: _ctx.subtitle,
      imgCategory: _ctx.category
    }, null, 8, ["title", "subtitle", "imgCategory"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_item, {
        icon: _ctx.Calendar,
        text: _ctx.daysAndHours
      }, null, 8, ["icon", "text"]),
      _createVNode(_component_item, {
        icon: _ctx.Clock,
        text: _ctx.schedule
      }, null, 8, ["icon", "text"]),
      (_ctx.holidays)
        ? (_openBlock(), _createBlock(_component_item, {
            key: 0,
            icon: _ctx.CloseCircle,
            text: _ctx.holidayText
          }, null, 8, ["icon", "text"]))
        : _createCommentVNode("", true)
    ])
  ]))
}