import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a91b58f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-d-flex p-pt-2" }
const _hoisted_2 = { class: "p-d-flex p-flex-column p-jc-around p-ml-3" }
const _hoisted_3 = { class: "p-d-flex gap" }
const _hoisted_4 = { class: "p-d-flex gap" }
const _hoisted_5 = { class: "p-mt-5 p-d-flex p-flex-column" }
const _hoisted_6 = { class: "p-d-flex p-ai-center p-jc-center p-px-2" }
const _hoisted_7 = { class: "p-ml-3" }
const _hoisted_8 = { class: "p-ml-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SkeletonBox = _resolveComponent("SkeletonBox")!
  const _component_skeleton_text = _resolveComponent("skeleton-text")!
  const _component_skeleton_icon = _resolveComponent("skeleton-icon")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_SkeletonBox, {
          height: "60px",
          width: "70px"
        }),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_skeleton_text, { length: 5 }),
            _createVNode(_component_skeleton_text, { length: 6 })
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_skeleton_text, { length: 10 }),
            _createVNode(_component_skeleton_text, { length: 8 })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (index) => {
          return _createElementVNode("div", {
            class: "p-d-flex p-ai-center p-my-2",
            key: index
          }, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_skeleton_icon)
            ]),
            _createElementVNode("p", _hoisted_7, [
              _createVNode(_component_skeleton_text, {
                length: 15 - index * 2
              }, null, 8, ["length"])
            ]),
            _createElementVNode("p", _hoisted_8, [
              _createVNode(_component_skeleton_text, {
                length: 12 - index * 2
              }, null, 8, ["length"])
            ])
          ])
        }), 64))
      ])
    ]),
    _: 1
  }))
}