
// Vendor
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { DateTime } from 'luxon'

import { getChallengesDataService, TGetChallenges } from '@/services/challenges.services'

// Store
import { useCourseStore } from '@/store/modules/course.store'

// Models
import { IProgress } from '@/models/newPlatform/challenges/progress'
import { IDeliver, IStudentDelivers } from '@/models/newPlatform/challenges/deliver'
import { IModuleTeacher } from '@/models/newPlatform/challenges/module'
import { ITimeline, ITimeLineStatus } from '@/models/newPlatform/challenges/timeline'

// Components
import TwoColsLayout from '@/components/aero/layout/TwoColsLayout.vue'
import ReviewCard from '@/components/aero/surfaces/challenges/ReviewCard.vue'
import DesafioCard from '@/components/aero/surfaces/challenges/DesafioCard.vue'
import SkeletonBecaCard from '@/components/aero/skeleton/SkeletonBecaCard.vue'
import SkeletonDesafioCard from '@/components/aero/skeleton/SkeletonDesafioCard.vue'
import ErrorPage from '@/components/aero/dataDisplay/ErrorPage.vue'
import PointCard from '@/components/aero/surfaces/challenges/PointCard.vue'
import CorreccionesCard from '@/components/aero/surfaces/challenges/CorreccionesCard.vue'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'

// Locales
import { sortingOptions } from '@/locales/challenges/es.json'

// Amplitude
import { ChallengesEvents } from '@/amplitude/constants'
import { trackEvent } from '@/amplitude/actions'

export default defineComponent({
  components: {
    TwoColsLayout,
    DesafioCard,
    ErrorPage,
    ReviewCard,
    SkeletonBecaCard,
    SkeletonDesafioCard,
    PointCard,
    TabView,
    TabPanel,
    CorreccionesCard
  },
  props: {
    userId: { type: String, required: true },
    isTutor: { type: Boolean, required: true },
    copies: { type: Object, required: false },
    useCoderChallenge: { type: Boolean, required: false }
  },
  setup(props) {
    const { id: courseId, camadaNro } = useCourseStore()
    const isLoading = ref<boolean>(true)
    const isError = ref<boolean>(false)
    const challenges = ref<IDeliver[]>([])
    const modulesList = ref<IModuleTeacher[]>([])
    const progressDelivers = ref<IProgress>()
    const finalProjectProgress = ref<IProgress>()
    const integratorTp = ref<{ title: string; url: string }>()
    // challenges Out Of Time
    const hasChallengesOOT = ref<boolean>(false)

    const studentsList = ref([])
    const studentsDelivers = ref<IStudentDelivers[]>([])
    const openChallenges = ref<IModuleTeacher[]>([])
    const pendingChallenges = ref([])

    // state for tabView. Required to fire amplitude's event
    const activeIndex = ref(0)

    const selectedTab = ref<number>(0)

    // Dropdown Menu
    const menu = ref()
    const menuIsOpen = ref(false)
    const toggle = (event: Event) => menu.value.toggle(event)

    const menuToggle = () => {
      menuIsOpen.value = !menuIsOpen.value
    }

    const selectedSortByStudent = ref<'priority' | 'alphabetical'>('priority')

    const selectedSortByModule = ref<'priority' | 'chronological'>('priority')

    const setSortingByModule: (sort: 'priority' | 'chronological') => void = (sort) => (selectedSortByModule.value = sort)

    const setSortingByStudent: (sort: 'priority' | 'alphabetical') => void = (sort) => (selectedSortByStudent.value = sort)

    const sortingOptionsByModule: { label: string; value: 'chronological' | 'priority'; command: () => void }[] = [
      { label: 'Alta prioridad', value: 'priority', command: () => setSortingByModule('priority') },
      { label: 'Cronológicamente', value: 'chronological', command: () => setSortingByModule('chronological') }
    ]

    const sortingOptionsByStudent: { label: string; value: 'priority' | 'alphabetical'; command: () => void }[] = [
      { label: 'Alta prioridad', value: 'priority', command: () => setSortingByStudent('priority') },
      { label: 'Alfabéticamente', value: 'alphabetical', command: () => setSortingByStudent('alphabetical') }
    ]

    const changeTab: (e: { index: number }) => void = (e) => {
      selectedTab.value = e.index
    }

    // Sorts the array based on priority as well as alphabetically
    const challengesByStudentSorted = computed(() => {
      if (selectedSortByStudent.value === 'alphabetical')
        // Sort alphabetically
        return studentsDelivers.value.slice(0).sort((a, b) => {
          if (a.student.fullName && b.student.fullName) {
            if (a.student.fullName?.split(' ')[0].toLowerCase() < b.student.fullName?.split(' ')[0].toLowerCase()) {
              return -1
            }
            if (a.student.fullName?.split(' ')[0].toLowerCase() > b.student.fullName?.split(' ')[0].toLowerCase()) {
              return 1
            }
            return 0
          } else {
            return 0
          }
        })
      else {
        // Sort by priority

        // Get students who have any deliver that is out of time for review
        const outOfTimes = studentsDelivers.value.filter((student) =>
          student.delivers.some(
            (deliver) =>
              deliver.hoursLeftToReview === 0 &&
              deliver.flags?.hasDelivers &&
              !deliver.flags.wasReviewed &&
              !deliver.flags.wasApproved
          )
        )

        // Get pending challenges and sort them by their expiration date from closer to expire to far from expiration date
        const pending = studentsDelivers.value
          .filter((student) => student.stats.unreviewed && !student.stats.hoursLeftToReviewExpired)
          .sort((a, b) => a.delivers[0].firstReviewEstimatedAt! - b.delivers[0].firstReviewEstimatedAt!)

        // Get the rest of the students
        const rest = studentsDelivers.value.filter((student) =>
          student.delivers.every(
            (deliver) => !deliver.flags?.hasDelivers && !deliver.flags?.hoursLeftToReviewExpired && !student.stats.unreviewed
          )
        )
        return [...outOfTimes, ...pending, ...rest]
      }
    })

    // Sorts the array based on priority as well as chronologically
    const challengesByModuleSorted = computed(() => {
        return modulesList.value.slice(0).sort((a, b) => {
          return a.expiresAt - b.expiresAt
        })
    })

    watch(activeIndex, () => {
      if (activeIndex.value === 0) {
        handleTrackEvent(ChallengesEvents.VIEW_BY_MODULES)
      } else if (activeIndex.value === 1) {
        handleTrackEvent(ChallengesEvents.VIEW_BY_STUDENTS)
      }
    })

    const getChallenges = async () => {
      const route = useRoute()

      const { mockdata, order } = route.query
      const rol = route.query.rol || 3
      const getChallengeDataProps: TGetChallenges = {
        returnMockData: !!mockdata,
        rol: `${rol}`,
        order: Number(order),
        userId: props.userId,
        camadaId: camadaNro,
        courseId: courseId,
        isTutor: props.isTutor
      }

      try {
        const response = await getChallengesDataService(getChallengeDataProps)
        const { delivers, modules, deliversProgress = null, students } = response
        challenges.value = delivers
        modulesList.value = modules
        progressDelivers.value = deliversProgress
        studentsList.value = students
        isLoading.value = false

        // Check the final project's opening date and in case it's open, set the attributes for card displaying
        if (modulesList.value.find((module) => module.flags.isIntegratorTp && module.class.day <= Date.now())) {
          const finalProject = modulesList.value.filter(
            (module) => module.flags.isIntegratorTp === true && module.class.day < Date.now()
          )[0]

          // Set the final project progress for point card displaying
          finalProjectProgress.value = finalProject.progress

          integratorTp.value = { title: finalProject.name, url: props.useCoderChallenge?`/reviews/${camadaNro}/${finalProject._id}/${finalProject.stage}`:`/challenges/challenge/${finalProject._id}` }
        } else {
          integratorTp.value = undefined
        }
      } catch (error) {
        isError.value = true
      } finally {
        isLoading.value = false
      }
    }

    const setPendingChallenges = () => {
      if (!props.isTutor) return
      // open challenges that didn't expired yet
      const filteredChallenges = challenges.value
        .filter((challenge) => !challenge.flags?.isIntegratorTp)
        .filter((challenge) => {
          const finalDeadline = challenge.timeline && getFinalDeadline(challenge.timeline)
          const unlockedUntil =
            challenge.unlockedUntil && typeof challenge.unlockedUntil === 'string'
              ? DateTime.fromISO(challenge.unlockedUntil).toMillis()
              : challenge.unlockedUntil

          if (unlockedUntil) {
            return (
              !challenge.flags?.wasApproved &&
              challenge.chat?.length &&
              challenge.chat[challenge.chat.length - 1].flags.fromStudent &&
              unlockedUntil > Date.now()
            )
          } else {
            return (
              !challenge.flags?.wasApproved &&
              challenge.chat?.length &&
              challenge.chat[challenge.chat.length - 1].flags.fromStudent &&
              finalDeadline &&
              finalDeadline > Date.now()
            )
          }
        })

      if (!filteredChallenges.length) return []
      const sortedList = filteredChallenges.sort((_a, _b) => _a.firstReviewEstimatedAt! - _b.firstReviewEstimatedAt!)
      const formatedList = sortedList.map((challenge) => {
        return {
          _id: challenge.module?._id || '',
          name: challenge.module?.name,
          expiresAt: challenge.firstReviewEstimatedAt,
          class: {
            order: challenge.class?.classId,
            name: challenge.class?.className
          },
          deliver: {
            projectId: challenge._id,
            projectData: challenge
          }
        }
      })
      const groupedList = formatedList.reduce((acc: any, cur) => {
        acc[cur._id] = [...(acc[cur._id] || []), cur]
        return acc
      }, {})

      const formatArray: any = Object.values(groupedList)

      // check if there are pending challenges out of time
      hasChallengesOOT.value = formatArray
        .flat()
        .some((challenge: any) => challenge.deliver.projectData.firstReviewEstimatedAt < Date.now())

      return formatArray
    }

    const setStudentsDelivers = () => {
      const newList = studentsList.value.map((student: IStudentDelivers) => {
        const deliverList: IDeliver[] = []
        openChallenges.value.forEach((challenge) => {
          let hasDeliver = false
          for (const deliver of student.delivers) {
            if (deliver.module?._id === challenge._id) {
              hasDeliver = true
            }
          }
          if (!hasDeliver) {
            const deliverData = {
              _id: '',
              flags: {
                isDeliverExpired: challenge.expiresAt < Date.now(),
                hasDelivers: false,
                wasReviewed: false,
                wasReviewedOnTime: false
              },
              hoursLeftToReview: 0,
              expiresAt: challenge.expiresAt
            }
            deliverList.push(deliverData)
          }
        })

        return { ...student, delivers: [...student.delivers, ...deliverList] }
      })
      studentsDelivers.value = newList
    }
    // helper
    const getFinalDeadline = (timeline: ITimeline[]) => {
      const finalDeadline = timeline.find((step) => step.key === ITimeLineStatus.finalDeadline)
      if (finalDeadline) {
        return typeof finalDeadline.date === 'string' ? DateTime.fromISO(finalDeadline.date).toMillis() : finalDeadline.date
      } else {
        return null
      }
    }
    const getReviewExpected = (timeline: ITimeline[]) => {
      const reviewExpected = timeline.find((step) => step.key === ITimeLineStatus.reviewExpected)
      if (reviewExpected) {
        return typeof reviewExpected.date === 'string' ? DateTime.fromISO(reviewExpected.date).toMillis() : reviewExpected.date
      } else {
        return null
      }
    }

    /**
     * Returns a string that will be used for displaying the label in the Button in charge of the sorting options dropdown menu
     */
    const selectedOptionLabel: (label: 'Alta prioridad' | 'Alfabéticamente' | 'Cronológicamente') => string = (label) => {
      switch (label) {
        case 'Alta prioridad':
          return sortingOptions.priority.label
        case 'Cronológicamente':
          return sortingOptions.chronologically.label
        case 'Alfabéticamente':
          return sortingOptions.alphabetically.label
      }
    }
    // Amplitude track event
    const handleTrackEvent = (name: string) => {
      trackEvent(name)
    }

    onMounted(async () => {
      await getChallenges()

      modulesList.value = modulesList.value.map((_module: IModuleTeacher) => {
        let statusTag = ''
        if (
          _module.flags.isDeliverExpired ||
          _module.expiresAt < DateTime.local().toMillis() ||
          (_module.progress.total.reviewedPerc === 100 && _module.progress.total.approvedPerc === 100)
        ) {
          statusTag = 'closed'
        } else if (_module.progress.total.reviewedOffTime !== 0) {
          statusTag = 'out-of-time'
        } else if (
          (_module.progress.total.reviewedPerc === 100 &&
            _module.progress.total.chatsUnviewed === 0 &&
            _module.class.day &&
            DateTime.fromMillis(_module.class.day) < DateTime.local()) ||
          (_module.progress.total.reviewedPerc === 0 &&
            _module.progress.total.amount === 0 &&
            _module.class.day &&
            DateTime.fromMillis(_module.class.day) < DateTime.local()) ||
          (_module.progress.total.reviewedOnTimePerc === 100 &&
            _module.progress.total.reviewedPerc === 100 &&
            _module.class.day &&
            DateTime.fromMillis(_module.class.day) < DateTime.local() &&
            _module.progress.total.expected - _module.progress.total.reviewed !== 0)
        ) {
          statusTag = 'for-receiving'
        } else if (
          _module.progress.total.reviewedPerc !== 100 &&
          _module.class.day &&
          DateTime.fromMillis(_module.class.day) < DateTime.local()
        ) {
          statusTag = 'pending'
        }

        const disapprovedChallenges = challenges.value.filter((challenge) => challenge.status !== 'approved')

        // check pending challenges that didn't expired
        const toReview =
          disapprovedChallenges.length &&
          disapprovedChallenges.some((challenge) => {
            const finalDeadline = challenge.timeline && getFinalDeadline(challenge.timeline)
            const unlockedUntil =
              challenge.unlockedUntil && typeof challenge.unlockedUntil === 'string'
                ? DateTime.fromISO(challenge.unlockedUntil).toMillis()
                : challenge.unlockedUntil

            return (
              challenge.module?._id === _module._id &&
              ((unlockedUntil && unlockedUntil > Date.now()) ||
                (finalDeadline && finalDeadline > Date.now() && challenge.chat?.slice(-1)[0].flags.fromStudent))
            )
          })

        if (toReview) {
          const outOfTime =
            disapprovedChallenges.length &&
            disapprovedChallenges.some((challenge) => {
              const reviewDate = challenge.timeline && getReviewExpected(challenge.timeline)
              return challenge.module?._id === _module._id && reviewDate && reviewDate < Date.now()
            })

          if (outOfTime) {
            statusTag = 'out-of-time'
          } else {
            statusTag = 'pending'
          }
        } else {
          // check pending challenges to receive that didn't expired
          const toReceive = disapprovedChallenges.some((challenge) => {
            const finalDeadline = challenge.timeline && getFinalDeadline(challenge.timeline)
            const unlockedUntil =
              challenge.unlockedUntil && typeof challenge.unlockedUntil === 'string'
                ? DateTime.fromISO(challenge.unlockedUntil).toMillis()
                : challenge.unlockedUntil

            return (
              challenge.module?._id === _module._id &&
              ((unlockedUntil && unlockedUntil > Date.now()) ||
                (finalDeadline && finalDeadline > Date.now() && !challenge.chat?.slice(-1)[0].flags.fromStudent))
            )
          })

          if (toReceive) {
            statusTag = 'for-receiving'
          }
        }

        return {
          ..._module,
          status: statusTag
        }
      })

      modulesList.value.forEach((_module) => {
        _module.class.day < DateTime.local().toMillis() && openChallenges.value.push(_module)
      })

      setStudentsDelivers()
      pendingChallenges.value = setPendingChallenges()

      handleTrackEvent(ChallengesEvents.VIEW_BY_MODULES)
    })

    return {
      isLoading,
      isError,
      modulesList,
      progressDelivers,
      camadaNro,
      setPendingChallenges,
      integratorTp,
      finalProjectProgress,
      pendingChallenges,
      challengesByStudentSorted,
      sortingOptionsByModule,
      sortingOptionsByStudent,
      toggle,
      menuToggle,
      menuIsOpen,
      menu,
      changeTab,
      selectedTab,
      challengesByModuleSorted,
      selectedSortByModule,
      selectedSortByStudent,
      selectedOptionLabel,
      activeIndex,
      hasChallengesOOT
    }
  }
})
