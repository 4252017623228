import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dc8a090a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-d-flex p-jc-between header-container" }
const _hoisted_2 = { class: "p-d-flex p-ai-center" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "dialog-content" }
const _hoisted_5 = { class: "p-mb-4" }
const _hoisted_6 = { class: "p-4" }
const _hoisted_7 = { class: "footer-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_text = _resolveComponent("input-text")!
  const _component_input_wrapper = _resolveComponent("input-wrapper")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.modal,
    "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modal) = $event)),
    style: {width: '30rem', minWidth: '30rem'},
    modal: true,
    closable: false
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.t('profile.available-balance.modal.title')), 1),
        _createElementVNode("div", null, [
          _createElementVNode("img", {
            class: "close-modal",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal())),
            src: require('@/assets/icons/Light_X.svg'),
            alt: "Close modal"
          }, null, 8, _hoisted_3)
        ])
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_Button, {
          label: _ctx.t('profile.available-balance.modal.close-btn'),
          onClick: _ctx.closeModal,
          class: "close-button"
        }, null, 8, ["label", "onClick"]),
        _createVNode(_component_Button, {
          label: _ctx.t('profile.available-balance.modal.transfer-btn'),
          disabled: !_ctx.validEmail,
          class: _normalizeClass(!_ctx.validEmail ? 'p-disabled' : 'submit-button'),
          onClick: _ctx.transfer
        }, null, 8, ["label", "disabled", "class", "onClick"])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.message), 1),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_input_wrapper, {
            label: _ctx.t('profile.available-balance.modal.email-label'),
            inputId: "email"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_input_text, {
                id: "email",
                name: "email",
                placeholder: "E-mail",
                modelValue: _ctx.dstEmail,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dstEmail) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["label"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}