export const getBrowser = () => {
  const userAgent = window.navigator.userAgent

  if (userAgent.match(/chrome|chromium|crios/i)) return 'Chrome'
  if (userAgent.match(/firefox|fxios/i)) return 'Firefox'
  if (userAgent.match(/safari/i)) return 'Safari'
  if (userAgent.match(/opr\//i)) return 'Opera'
  if (userAgent.match(/edg/i)) return 'Edge'

  return 'No browser detection'
}

export const getOS = () => {
  const userAgent = window.navigator.userAgent

  if (userAgent.match('Mac')) return 'MacOS'
  if (userAgent.match('Win')) return 'Windows'
  if (userAgent.match('Linux')) return 'Linux'

  return 'No OS detection'
}
