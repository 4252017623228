import amplitude from 'amplitude-js'

const amplitudeInstance = amplitude.getInstance()

amplitudeInstance.init('fb3291f04504f70ecdcdd4ce3ace15e3')

const logEvent = (e: string, payload: unknown) => {
  amplitudeInstance.logEvent(e, payload)
}

const eventSwitchStepsChallenge = (step: unknown) => {
  logEvent('TEST_course-challenge-steps', {
    from: 'student-classes-list',
    cta: step === 0 ? 'my-challenge' : 'peer-review'
  })
}

export {
  eventSwitchStepsChallenge
}