
// Vendor
import { defineComponent } from 'vue'

// Components
import Icon from '@/components/aero/icon/Icon.vue'
import { Bell, Calendar, Trophy, ListBullets, Megaphone } from '@/components/aero/icon/templates'

export default defineComponent({
  props: { type: { type: String } },
  components: { Icon, Bell, Calendar, Trophy, ListBullets, Megaphone }
})
