import { Product } from '@/models/newPlatform/products/products'
import { ProductCourse } from '@/models/newPlatform/products/productCourse'

import { Api } from '../api'
const { API_ENDPOINT } = process.env

/**
 * API Call to fetch all courses from all categories
 * It includes general information of the courses without specifying incoming classes. For that use 'getProdut' instead
 * @param param.country `required` current country
 * @param param.type 'required' courses to be remote or not
 * @returns an array of courses
 */
export const getGeneralProducts = async (country: string, type: string): Promise<Product[]> => {
  const { remotes } = await Api.get(`${API_ENDPOINT}/products?country=${country}&startDate=${Date.now()}&type=${type}`)
  return remotes
}

/**
 * API Call to fetch all incoming 'litters' from an specific type of course
 * example: all courses incoming of UI/UX design
 * @param param.productId `required` the type of course we are looking for e.g.'UI UX Design'
 * @param param.country `required` current country
 * @param param.type 'required' courses to be remote or not
 * @returns an array of courses
 */

export const getProductCourses = async (productId: string, country: string, type: string): Promise<ProductCourse[]> => {
  return await Api.get(
    `${API_ENDPOINT}/products/${productId}/courses?country=${country}&startDate=${Date.now()}&type=${type}&teachers=true`
  )
}
