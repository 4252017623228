//  API
import {
  getAttendances as getAttendancesFromApi,
  setAttendance as setAttendanceFromApi,
  setAbsent as setAbsentFromApi
} from '@/api/newPlatform/attendance.api'

// Models
import { IAttendances } from '@/models/attendance/attendance'

/**
 *
 * @param camadaNro  camada number
 * @param teacherId id of the teacher/tutor
 * @returns {Promise} Returns a list of students, tutors with attendance data and schedules.
 * User with role tutor retrieves the list of students assigned
 * User with role teacher retrieves the list of students of the whole commission
 */

export const getAttendances = async (camadaNro: string, teacherId: string): Promise<IAttendances> => {
  const { allStudents, allTutors, flags, schedules } = await getAttendancesFromApi(camadaNro, teacherId)

  return { flags, allStudents, allTutors, schedules }
}

/**
 *
 * @param camadaId  camadaId. Is not the same as camadaNro
 * @param scheduleId id associate with the class to update attendance
 * @param studentId  id of the student to update attendance
 * @returns {boolean} Returns if the operation was fullfilled
 */

export const setAttendance = async (camadaId: string, scheduleId: string, studentId: string): Promise<boolean> => {
  const response = await setAttendanceFromApi(camadaId, scheduleId, studentId)

  return response === 'success'
}

/**
 *
 * @param camadaId  camadaId. Is not the same as camadaNro
 * @param scheduleId id associate with the class to update attendance
 * @param studentId  id of the student to update attendance
 * @returns {boolean} Returns if the operation was fullfilled
 */

export const setAbsent = async (camadaId: string, scheduleId: string, studentId: string): Promise<boolean> => {
  const response = await setAbsentFromApi(camadaId, scheduleId, studentId)

  return response === 'success'
}
