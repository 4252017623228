import { Api } from './api'
import { IClassesData, ClassesData } from '@/models/classes/classes-data'
import { IClass } from '@/models/classes/class'
import { IAfterClass, IZoomLink, ZoomLink } from '@/models/classes/afterclass'

const { API_ENDPOINT } = process.env

/**
 * API request to retrieve data from a certain user.
 * @param userId Logged user id
 * @returns user data.
 * @see [Postman]{@link https://coderplatformers.postman.co/workspace/Coder-House-Platform~9b3cd88b-81e2-4307-8432-95267b456aa7/request/17745020-7cbd69b4-5e03-44e4-8373-3ec8abc06334}
 */

const getUserData = async (userId: string): Promise<any> => {
  const userData = await Api.get(`${API_ENDPOINT}/platform/users/${userId}`)

  return userData
}

/**
 * API request to retrieve course progress stats from a certain student.
 * @param userId Logged user id
 * @param camadaId current course's camadaId
 * @returns user's course progress stats data.
 * @see [Postman]{@link https://coderplatformers.postman.co/workspace/Coder-House-Platform~9b3cd88b-81e2-4307-8432-95267b456aa7/request/17745020-5b449676-bbca-4247-b882-5fce95575545}
 */

const getProgressStudent = async (userId: string, camadaId: string): Promise<any> => {
  const progress = await Api.get(
    `${API_ENDPOINT}/platform/students/${userId}/courses/${camadaId}/progress?ts=${Date.now()}&camadaId=${camadaId}&userId=${userId}`
  )

  return progress
}

/**
 * API request to retrieve detailed course progress from a certain student.
 * @param userId Logged user id
 * @param camadaId current course's camadaId
 * @returns user's course progress data.
 * @see [Postman]{@link https://coderplatformers.postman.co/workspace/Coder-House-Platform~9b3cd88b-81e2-4307-8432-95267b456aa7/request/17745020-34a77d42-63be-4d2a-8a6a-be9b3fe699c6}
 */

const getProgressUser = async (userId: string, camadaId: string): Promise<any> => {
  const progressUser = await Api.get(
    `${API_ENDPOINT}/platform/users/${userId}/courses/${camadaId}/program/progress?camadaId=${camadaId}&ts=${Date.now()}&upsellingClasses=true&userId=${userId}`
  )

  return progressUser
}

/**
 * API request to retrieve data from the current course.
 * @param userId Logged user id
 * @param camadaId current course's camadaId
 * @returns details from the current course. Data from course, detailed classes, afterclasses and schedules
 * @see [Postman]{@link https://coderplatformers.postman.co/workspace/Coder-House-Platform~9b3cd88b-81e2-4307-8432-95267b456aa7/request/17745020-43a24857-d0d7-43ce-bf91-37e869079803}
 */

export const getClasses = async (userId: string, camadaId: string): Promise<any> => {
  const classes = await Api.get(
    `${API_ENDPOINT}/platform/students/${userId}/courses/${camadaId}?ts=${Date.now()}&camadaId=${camadaId}&userId=${userId}`
  )
  return classes
}

export const getFeedbackLink = async (courseId: string, scheduleId: string, userId: string): Promise<any> => {
  const response: { [key: string]: string } = await Api.get(
    `${API_ENDPOINT}/platform/courses/${courseId}/schedules/${scheduleId}/feedback-url?courseId=${courseId}&scheduleId=${scheduleId}&userId=${userId}`
  )

  return response.url
}

/**
 * Get user data, current course's classes and it's progress
 * @param userId Logged user id
 * @param camadaId current course's camadaId
 * @returns data current user and it's corresponding course progress. Data from course, detailed classes, afterclasses and schedules
 */

export const getAllData = async (userId: string, camadaId: string) => {
  const { flags, role } = await getUserData(userId)
  const { delivers, undelivers, absents, attendance } = await getProgressStudent(userId, camadaId)

  const progressUser = await getProgressUser(userId, camadaId)

  const { complementarySchedules, schedules, class: _class, course } = await getClasses(userId, camadaId)
  const classesList: IClass[] = []
  const afterClasses: IClass[] = []

  for (const element of schedules) {
    const stage = _class.stages.find((stage: any) => stage._id === element.stage)
    const progress = progressUser.find((progrres: any) => progrres._id === element.stage)

    const ordinaryClass = {
      _id: element._id,
      name: stage.name,
      order: stage.order + 1,
      slug: stage.slug,
      delivered: stage.delivered,
      links: {
        sharedFolderLink: course.sharedFolderLink,
        teachersSharedFolderLink: course.teachersSharedFolderLink,
        theoryLink: stage.theoryLink,
        presentationLink: element.presentationLink,
        feedbackLink: ''
      },
      flags: {
        isStarter: stage.flags.isStarter,
        isLeveling: stage.flags.isLeveling,
        isFree: stage.flags.isFree,
        isWorkshop: stage.flags.isWorkshop,
        isPlaybook: stage.flags.isPlaybook,
        normalized: stage.flags.normalized,
        enable: progress.flags.enable,
        hasDelivers: progress.flags.hasDelivers,
        isFinalDeliver: progress.flags.isFinalDeliver,
        isLocked: progress.flags.isLocked,
        isLast: progress.flags.isLast
      },
      schedule: {
        _id: element._id,
        stage: element.stage,
        enable: element.enable,
        day: element.from,
        from: element.from,
        to: element.to,
        isAdditional: element.additional
      },
      videoLink: {
        liveVideoLink: element.liveVideoLink,
        recordingVideoLink: element.recordingVideoLink
      },
      feedback: {
        isFeedbackRequired: progress.schedule.flags.isFeedbackRequired,
        isFeedbackEnable: progress.schedule.flags.isFeedbackEnable,
        isFeedbackExpired: progress.schedule.flags.isFeedbackExpired,
        isFeedbackCompleted: progress.schedule.flags.isFeedbackCompleted,
        feedbackExpirationDate: progress.schedule.flags.feedbackExpirationDate,
        feedbackTimeLeftToExpire: progress.schedule.flags.feedbackTimeLeftToExpire
      },
      modules: {
        project: {
          _id: stage.modules?.find((module: any) => module.flags.isTp)?._id || 0,
          enable: progress.flags.enable,
          hasDelivers: progress.flags.hasDelivers,
          isFinalDeliver: progress.flags.isFinalDeliver,
          isLocked: progress.flags.isLocked,
          isDeliverCompleted: progress.progress.completed === progress.progress.amount,
          isDeliverExpired: element.flags.isDeliverExpired,
          module: progress.modules
        }
      },
      isUserAbsent: element.flags.isUserAbsent
    }

    if (progress.schedule.flags?.isFeedbackEnable && !progress.schedule.flags?.isFeedbackCompleted && element.from < Date.now()) {
      ordinaryClass.links.feedbackLink = await getFeedbackLink(course._id, element._id, userId)

      classesList.push(ordinaryClass)
    } else {
      classesList.push(ordinaryClass)
    }
  }

  for (const element of complementarySchedules) {
    const progress = progressUser.find((progrres: any) => progrres._id === element.stage?._id)

    const afterClass = {
      _id: element._id,
      name: element.title,
      links: {
        sharedFolderLink: course.sharedFolderLink,
        teachersSharedFolderLink: course.teachersSharedFolderLink,
        theoryLink: progress?.theoryLink,
        presentationLink: element.presentationLink,
        feedbackLink: ''
      },
      flags: {
        isFree: element.stage?.flags.isFree,
        isLeveling: element.stage?.flags.isLeveling,
        isPlaybook: element.stage?.flags.isPlaybook,
        isStarter: element.stage?.flags.isStarter,
        isWorkshop: element.stage?.flags.isWorkshop,
        normalized: element.stage?.flags.normalized,
        enable: progress?.flags?.enable,
        hasDelivers: progress?.flags?.hasDelivers,
        isFinalDeliver: progress?.flags?.isFinalDeliver,
        isLocked: progress?.flags?.isLocked,
        isLast: progress?.flags?.isLast
      },
      schedule: {
        _id: element._id,
        stage: element.stage?._id,
        enable: element.enable,
        day: element.day,
        from: element.from,
        to: element.to,
        isAdditional: element.flags.isComplementary
      },
      feedback: {
        isFeedbackRequired: progress.schedule.flags.isFeedbackRequired,
        isFeedbackEnable: progress.schedule.flags.isFeedbackEnable,
        feedbackExpirationDate: progress.schedule.flags.feedbackExpirationDate,
        feedbackTimeLeftToExpire: progress.schedule.flags.feedbackTimeLeftToExpire,
        isFeedbackExpired: progress.schedule.flags.isFeedbackExpired,
        isFeedbackCompleted: progress.schedule.flags.isFeedbackCompleted
      },
      videoLink: {
        liveVideoLink: element.liveVideoLink,
        recordingVideoLink: element.recordingVideoLink
      },
      feedbacks: element.feedbacks,
      isUserAbsent: element.stage?.flags.isUserAbsent
    }

    if (
      progress.schedule.flags?.isFeedbackEnable &&
      !progress.schedule.flags?.isFeedbackCompleted &&
      progress.schedule.flags?.feedbackExpirationDate > Date.now()
    ) {
      afterClass.links.feedbackLink = await getFeedbackLink(course._id, element._id, userId)

      afterClasses.push(afterClass)
    } else {
      afterClasses.push(afterClass)
    }
  }

  const data: IClassesData = {
    user: {
      profile: {
        _id: userId,
        role
      },
      beca: {
        hasBeca: flags.hasBeca,
        becaCompleted: flags.becaCompleted,
        becaExpired: flags.becaExpired,
        becaSuspended: flags.becaSuspended,
        hasDelivers: undelivers.flags.warn
      },
      progress: {
        absents: {
          maxAmount: absents.amount,
          completed: absents.completed,
          perc: absents.perc,
          flags: {
            warningMaxAmountReached: absents.flags.warn,
            alertMaxAmountSurpassed: absents.flags.alert
          }
        },
        project: {
          maxAmount: delivers.amount,
          completed: delivers.completed,
          perc: delivers.perc,
          delivered: delivers.delivered,
          expected: delivers.expected,
          score: delivers.score
        },
        attendance
      }
    },
    content: {
      classes: {
        classesList,
        complementarySchedules: afterClasses,
        projectProgress: progressUser
      }
    },
    courseId: course._id,
    endDate: new Date(course.endDate)
  }

  return new ClassesData(data)
}

/**
 * API request to create a zoom link.
 * @param courseId current course's id
 * @param {Object} body
 * @param {string} body.courseId: current course's Id (not camada number)
 * @param {Object} body.schedule {stage, date, from, to}
 * @param {string} schedule.stage: stage associated to the class the user wants to create the afterclass
 * @param {number} schedule.day: date of the afterclass to be created in milliseconds
 * @param {string} schedule.from: stating time of the afterclass to be created ISO
 * @param {string} schedule.to: ending time of the afterclass to be created ISO
 *
 * @returns the zoom link
 */

export const createZoomLink = async (courseId: string, body: IZoomLink): Promise<ZoomLink> => {
  return await Api.post(`${API_ENDPOINT}/platform/courses/${courseId}/meeting`, body)
}

/**
 * API request to create an afterclass.
 * @param courseId current course's id
 * @param {Object} body
 * @param {string} body.stage: stage associated to the class the user wants to create the afterclass
 * @param {number} body.day: date of the afterclass to be created in milliseconds
 * @param {string} body.from: stating time of the afterclass to be created ISO
 * @param {string} body.to: ending time of the afterclass to be created ISO
 * @param {string} body.liveVideoLink: zoom link url
 * @param {string} body.title: title of the afterclass to be created
 * @param {string} body.teachers: array of teacher's id
 *
 * @returns data of the afterclass created
 */

export const createAfterClass = async (courseId: string, body: IAfterClass) => {
  try {
    return await Api.post(`${API_ENDPOINT}/platform/courses/${courseId}/complementary-schedules`, body)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}

/**
 * API request to delete an afterclass.
 * @param courseId ccurrent course's id
 * @param complementaryId id of the afterclass to be deleted
 * @returns response success boolean
 */

export const deleteAfterclass = async (courseId: string, complementaryId: string): Promise<boolean> => {
  const response = await Api.del(`${API_ENDPOINT}/platform/courses/${courseId}/complementary-schedules/${complementaryId}`)
  return response === 'success'
}

/**
 * API request to update an afterclass.
 * @param courseId current course's id
 * @param complementaryId id of the afterclass to be edited
 * @param {Object} body
 * @param {string} body.stage: stage associated to the class the user wants to create the afterclass
 *  @param {number} body.day: date of the afterclass to be created in milliseconds
 *  @param {string} body.from: stating time of the afterclass to be created ISO
 *  @param {string} body.to: ending time of the afterclass to be created ISO
 *  @param {string} body.liveVideoLink: zoom link url
 *  @param {string} body.title: title of the afterclass to be created
 *  @param {string[]} body.teachers: array of teacher's id
 *  @param {string} body._id: id of the afterclass to be edited
 *
 * @returns data of the afterclass edited
 */

export const updateAfterclass = async (courseId: string, complementaryId: string, body: IAfterClass) => {
  return await Api.put(`${API_ENDPOINT}/platform/courses/${courseId}/complementary-schedules/${complementaryId}`, body)
}
