
import { defineComponent, onMounted, PropType, reactive, Ref, ref } from 'vue'

// PrimeVue components
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'
import Dialog from 'primevue/dialog'
import Calendar from 'primevue/calendar'

// Models
import { IUser as UserInfo } from '@/models/user'
import { Country } from '@/models/profile/profile'

// Components
import Card from '@/components/aero/surfaces/Card.vue'
import Toast from '@/components/aero/feedback/Toast.vue'
import Icon from '@/components/aero/icon/Icon.vue'
import { Camera } from '@/components/aero/icon/templates'
import SkeletonIcon from '@/components/aero/skeleton/SkeletonIcon.vue'
import Avatar from '@/components/aero/dataDisplay/Avatar.vue'
import Breadcrumb from 'primevue/breadcrumb'
import CustomTransition from '@/components/aero/misc/CustomTransition.vue'

// Store
import { useUserStore } from '@/store/modules/user.store'

// Hooks
import { useToast } from 'primevue/usetoast'

// translations
import { useI18n } from 'vue-i18n'

// Services
import { changePasswordService, getCountriesList, updatePicture } from '@/services/newPlatform/profile.services'
import useVuelidate, { ValidationArgs } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { ToastMessageOptions } from 'primevue/toast'

export default defineComponent({
  components: {
    Avatar,
    Button,
    Card,
    Dialog,
    Toast,
    Icon,
    Camera,
    SkeletonIcon,
    InputText,
    Calendar,
    InputNumber,
    Breadcrumb,
    CustomTransition
  },
  props: {
    information: { type: Object, required: true },
    user: { type: Object as PropType<UserInfo>, required: true },
    course: { type: String, required: true },
    editingProfile: { type: Boolean, required: true },
    isVisitorView: { type: Boolean, default: false }
  },
  emits: ['onClickEdit'],
  setup(props, { emit }) {
    const { t } = useI18n()
    const userStore = useUserStore()
    const toast = useToast()
    const loadingUpdateImage = ref(false)

    const hasPlus = ref(userStore.subscription.includes('CoderPlus'))
    const plusExpirationDate = ref('--/--/--')
    const plusIsAboutToExpire = ref(false)

    const state = reactive({
      hasBeca: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: null,
      country: '',
      cityV2: '',
      curp: null,
      bday: null
    })

    const rules: ValidationArgs = {
      firstName: { required },
      lastName: { required },
      country: { required },
      curp: { required }
    }

    const validations = useVuelidate(rules, state)

    const handleSubmit = async () => {
      validations.value.$touch()

      return validations.value.$validate()
    }

    const countryList: Ref<any> = ref([])
    const filteredCountry: Ref<any> = ref([])
    const searchCountry = ref('')

    const searchCountries = () => {
      setTimeout(() => {
        if (searchCountry.value.trim().length) {
          filteredCountry.value = countryList.value.filter((country: any) => {
            return country.country.toLowerCase().startsWith(searchCountry.value.toLowerCase())
          })
        }
      }, 250)
    }

    const isItemSelected: Ref<boolean> = ref(false)

    const selectItemCountry = (item: Country) => {
      searchCountry.value = item.country
      state.country = item.iso2.toLowerCase()
      isItemSelected.value = true
    }

    const deleteSelectionCountry = () => {
      searchCountry.value = ''
      state.country = ''
      isItemSelected.value = false
      filteredCountry.value = countryList.value
    }

    const changePasswordRefs = ref<{ dialogOpen: boolean; loading: boolean }>({
      dialogOpen: false,
      loading: false
    })

    const withDefaultValuesQuestions = () => {
      const country = countryList.value.find((country: any) => {
        return country.iso2.toLowerCase() === props.information.country
      })

      const bday = new Date(props.information.bday)
      const day = bday.getDate() < 10 ? '0' + bday.getDate() : bday.getDate()
      const month = bday.getMonth() + 1
      const year = bday.getFullYear()

      state.email = (props.information.email as string) || ''
      state.firstName = (props.information.firstName as string) || ''
      state.lastName = (props.information.lastName as string) || ''
      state.phone = props.information.phone
      state.country = props.information.country
      searchCountry.value = (country?.country as string) || ''
      state.cityV2 = (props.information.cityV2 as string) || ''
      state.curp = props.information.curp
      state.bday = props.information.bday ? `${day}/${month < 10 ? '0' + month : month}/${year}` : (null as any)
      state.hasBeca = (props.information.hasBeca as string) || ''
    }

    /* Method to update the profile picture */
    const errorUpadtingProfile = () => {
      toast.add({
        severity: t('profile.student.update.severity.error') as ToastMessageOptions['severity'],
        detail: t('profile.student.update.picture.error'),
        group: 'bc',
        life: 3000
      })
    }

    const updateProfilePicture = async (e: Event) => {
      const target = e.target as HTMLInputElement
      const file: File = (target.files as FileList)[0]
      if (!file) return
      loadingUpdateImage.value = true

      try {
        const responseLink = await updatePicture(props.user.id, file)
        loadingUpdateImage.value = false

        if (!responseLink) return errorUpadtingProfile()

        userStore.updateAvatar(responseLink)
      } catch (err) {
        loadingUpdateImage.value = false
        errorUpadtingProfile()
        // eslint-disable-next-line no-console
        console.error(err)
      }
    }

    /** Method to manage click on edit button */
    const handleToggleEdit = () => {
      emit('onClickEdit')
    }

    /** Method to provide feedback to user when confirm change password */
    const changePasswordFeedback = (success: boolean) => {
      const successStr = success ? 'success' : 'error'
      toast.add({
        severity: successStr,
        detail: t(`profile.student.changePassword.toast.${successStr}.detail`),
        group: 'bc',
        life: 3000
      })
    }

    const changePasswordHandlers = {
      /** Method to handle click on change password button */
      click: () => {
        changePasswordRefs.value.dialogOpen = true
      },
      /** Method to handle click on confirm button on change password confirm dialog */
      confirm: async () => {
        changePasswordRefs.value.loading = true

        const success = await changePasswordService({ userEmail: props.user.email })

        changePasswordRefs.value.loading = false

        changePasswordRefs.value.dialogOpen = false
        changePasswordFeedback(success)
      },
      /** Method to handle click on cancel button on change password confirm dialog */
      cancel: () => {
        changePasswordRefs.value.dialogOpen = false
      }
    }

    const handleClickEditEmail = () => {
      // TODO: handle this
    }

    const handledOnMounted = async () => {
      countryList.value = ((await getCountriesList()).data).map(c=>{
        if (c.iso2 === 'BR') c.country = 'Brasil'
        return c
      })
      withDefaultValuesQuestions()
    }

    onMounted(handledOnMounted)

    return {
      changePasswordHandlers,
      changePasswordRefs,
      handleToggleEdit,
      handleClickEditEmail,
      state,
      updateProfilePicture,
      loadingUpdateImage,
      countryList,
      filteredCountry,
      searchCountry,
      searchCountries,
      isItemSelected,
      selectItemCountry,
      deleteSelectionCountry,
      handleSubmit,
      validations,
      hasPlus,
      plusExpirationDate,
      plusIsAboutToExpire,
      t
    }
  }
})
