import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3ac939a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "error" }
const _hoisted_2 = { class: "inner-wrapper" }
const _hoisted_3 = { class: "t2 medium p-mb-2" }
const _hoisted_4 = { class: "p2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "image", {}, undefined, true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.message), 1)
    ]),
    _renderSlot(_ctx.$slots, "buttons", {}, undefined, true)
  ]))
}