export const CODERHOUSE_PLATFORM = 'Coderhouse Plataforma'

export enum JobOffers {
  Jobs = 'jobs'
}

export enum PeerReview {
  StudentReviews = 'student-reviews',
  TutorReviews = 'tutor-reviews'
}

export enum NewPlatform {
  CLASSES = 'classes',
  CHALLENGES = 'challenges',
  CHALLENGE = 'challenge',
  CHALLENGES_BY_MODULE = 'challengesByModule',
  CHALLENGES_BY_STUDENT = 'challengesByStudent',
  DASHBOARD = 'dashboard',
  RATING = 'rating',
  PROFILE = 'profile',
  PROFILE_CHANGE_COURSE = 'profileChangeCourse',
  PROFILE_TRANSFER_COURSE = 'transferCourse',
  CHAT = 'chat',
  PROFILE_VISITOR_VIEW = 'profileVisitorView',
  RANKING = 'ranking',
  COMMISSION = 'commission',
  ATTENDANCE = 'attendance',
  RATINGS = 'ratings',
  RATING_BY_CLASS = 'ratingByClass',
  CONTRATAR = 'shop',
  RESOURCES = 'resources',
  BENEFITS = 'benefits',
  ONBOARDING = 'onboarding',
  VIDEOTUTORIALS = 'videotutorials'
}

// TODO: Check naming for contratar, Beneficios

export type Names = NewPlatform | PeerReview | JobOffers

export const PageTitle = {
  [JobOffers.Jobs]: 'Oferta', // TODO: check this name
  [PeerReview.StudentReviews]: 'Peer Review', // TODO: check this name
  [PeerReview.TutorReviews]: 'Peer Review', // TODO: check this name
  [NewPlatform.CLASSES]: 'Clases',
  [NewPlatform.CHALLENGES]: 'Desafios',
  [NewPlatform.CHALLENGE]: 'Desafios',
  [NewPlatform.CHALLENGES_BY_MODULE]: 'Desafios',
  [NewPlatform.CHALLENGES_BY_STUDENT]: 'Desafios',
  [NewPlatform.DASHBOARD]: 'Dashboard',
  [NewPlatform.RATING]: 'Valoraciones',
  [NewPlatform.RANKING]: 'Ranking',
  [NewPlatform.PROFILE]: 'Mi Perfil',
  [NewPlatform.COMMISSION]: 'Comision',
  [NewPlatform.ATTENDANCE]: 'Asistencia',
  [NewPlatform.PROFILE_CHANGE_COURSE]: 'Cambiar de curso',
  [NewPlatform.PROFILE_TRANSFER_COURSE]: 'Transferir curso',
  [NewPlatform.CHAT]: 'Chat',
  [NewPlatform.PROFILE_VISITOR_VIEW]: 'Perfil',
  [NewPlatform.RATINGS]: 'Valoraciones',
  [NewPlatform.RATING_BY_CLASS]: 'Valoraciones',
  [NewPlatform.CONTRATAR]: 'Contratar',
  [NewPlatform.RESOURCES]: 'Recursos',
  [NewPlatform.BENEFITS]: 'Beneficios',
  [NewPlatform.ONBOARDING]: 'Onboarding',
  [NewPlatform.VIDEOTUTORIALS]: 'Videotutorials'
}
