
import { defineComponent, onMounted, PropType, ref, Ref, watch } from 'vue'
import { useUserSessionStore } from '@/store/modules/user-session.store'
import { useUserStore } from '@/store/modules/user.store'
import { setUserModalities, setUserIndustries } from '@/services/newPlatform/profile.services'
import { getJobOfferDetails, getCompanyDetail } from '@/api/job-offers.api'
import { UserWorkPreference } from '@/models/profile/profile'
import MultiSelect from 'primevue/multiselect'
import copies from '@/locales/profile/es.json'

export default defineComponent({
  components: { MultiSelect },
  props: { 
    displayModal: { type: Boolean, default: false },
    userModalities: { type: Object as PropType<UserWorkPreference[]>},
    userIndustries: { type: Object as PropType<UserWorkPreference[]>} 
  },
  setup(props, { emit }) {
    const user = useUserStore()
    const { userId } = useUserSessionStore()
    const modal = ref(false)
    const selectedModalities = ref(props.userModalities || [])
    const selectedIndustries = ref(props.userIndustries || [])
    const modalities: Ref<UserWorkPreference[]> = ref([])
    const industries: Ref<UserWorkPreference[]> = ref([])

    const closeModal = () => {
      emit('closeModal')
    }
    const profileGoals: any = copies.editProfile.profileGoals
    const handleMountedComponent = async () => {
      await user.getUser()
      
      const modalitiesList = (await getJobOfferDetails('modality', user?.country)).map(({_id, value}: {_id: string, value: string}) => { 
        return { name: value, id: _id }
      })
      const typesList = (await getJobOfferDetails('type', user?.country)).map(({_id, value}: {_id: string, value: string}) => { 
        return { name: value, id: _id }
      })
      modalities.value = modalitiesList.concat(typesList)

      industries.value = (await getCompanyDetail('area', user?.country)).map(({_id, value}: {_id: string, value: string}) => { 
        return { name: value, id: _id }
      })

      // Check coincidences
      selectedModalities.value = []
      props.userModalities?.forEach(modality => {
        if (modalities.value.some(function (el) { return el.id === modality.id })) {
          selectedModalities.value.push(modality)
        }
      })

      selectedIndustries.value = []
      props.userIndustries?.forEach(industry => {
        if (industries.value.some(function (el) { return el.id === industry.id })) {
          selectedIndustries.value.push(industry)
        }
      })
    }

    watch(props, () => {
      modal.value = props.displayModal
    })

    onMounted(handleMountedComponent)

    const saveGoals = async () => {
      await setUserModalities(userId, selectedModalities.value)
      await setUserIndustries(userId, selectedIndustries.value)
      closeModal()
    }


    return { closeModal, modal, modalities, industries, selectedModalities, selectedIndustries, saveGoals, profileGoals, user }
  }
})
