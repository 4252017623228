
// Vendor
import { defineComponent, PropType, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

// Components
import CommunityTag from '@/components/aero/dataDisplay/CommunityTag.vue'
import PostConversation from '@/components/aero/surfaces/PostConversation.vue'

// Models
import { Comment, TagTypes, TagTypesEnum } from '@/models/ratings/ratings'

export default defineComponent({
  components: {
    CommunityTag,
    PostConversation
  },
  props: {
    comments: { type: Array as PropType<Comment[]>, required: true }
  },
  setup(props) {
    const { t } = useI18n()
    const tags = [
      {
        type: TagTypesEnum.POSITIVE,
        label: t('rating.commentaries.tags.positive')
      },
      {
        type: TagTypesEnum.NEUTRAL,
        label: t('rating.commentaries.tags.neutrals')
      },
      {
        type: TagTypesEnum.NEGATIVE,
        label: t('rating.commentaries.tags.negative')
      }
    ]

    // Current active tag
    const tagFilter = ref<TagTypes[]>([])
    const positiveTagFilter = ref(false)
    const neutralTagFilter = ref(false)
    const negativeTagFilter = ref(false)
    const showAllTags = ref(true)

    const handleTagActive = (tagLabel: TagTypes) => {
      if (tagFilter.value.includes(tagLabel)) {
        tagFilter.value = tagFilter.value.filter((t) => t !== tagLabel)
      } else {
        tagFilter.value.push(tagLabel)
      }
    }

    // Comment types
    const positiveComments = ref(props.comments.filter((c) => c.type === TagTypesEnum.POSITIVE))
    const neutralComments = ref(props.comments.filter((c) => c.type === TagTypesEnum.NEUTRAL))
    const negativeComments = ref(props.comments.filter((c) => c.type === TagTypesEnum.NEGATIVE))

    // Filtering
    const filterTagComments = () => {
      const isPositive = tagFilter.value.includes('positive')
      const isNeutral = tagFilter.value.includes('neutral')
      const isNegative = tagFilter.value.includes('negative')

      positiveTagFilter.value = isPositive
      neutralTagFilter.value = isNeutral
      negativeTagFilter.value = isNegative

      if ((!isPositive && !isNeutral && !isNegative) || (isPositive && isNeutral && isNegative)) {
        showAllTags.value = true
      } else {
        showAllTags.value = false
      }
    }

    watch(() => [...tagFilter.value], filterTagComments)

    return {
      handleTagActive,
      tags,
      positiveTagFilter,
      neutralTagFilter,
      negativeTagFilter,
      positiveComments,
      neutralComments,
      negativeComments,
      showAllTags
    }
  }
})
