
import { defineComponent, PropType, computed } from 'vue'
import AttendanceTable from '@/components/aero/dataDisplay/AttendanceTable.vue'

// Models
import { ColumnType, ISchedule, User } from '@/models/attendance/attendance'

export default defineComponent({
  props: {
    students: { type: Array as PropType<User[]>, required: true },
    tutors: { type: Array as PropType<User[]>, required: true },
    columns: { type: Array as PropType<ColumnType[]>, required: true },
    schedules: { type: Array as PropType<ISchedule[]>, default: () => [] },
    filterTitle: { type: String, default: 'student' },
    filterSelected: { type: String, default: 'tutors' }
  },
  components: { AttendanceTable },
  emits: ['onChange'],
  setup(props, { emit }) {
    const setUsers = (users: User[]) => {
      const parsed = users.map((user) => {
        const attendance = user.schedules.reduce((acc, { _id, attendend }) => {
          return { ...acc, [_id]: !!attendend }
        }, {})

        const flagDate = user.schedules.reduce((acc, { _id, _flags }) => {
          return { ...acc, [_id]: _flags.isNext || _flags.isTomorrow || _flags.isIncoming }
        }, {})

        const data = {
          id: user._id,
          name: user.fullName,
          avatar: user.avatar,
          beca: user.flags.beca,
          inactive: user.flags.inactiveStudent,
          attendance,
          flagDate,
          schedules: user.schedules
        }

        return data
      })

      return parsed
    }

    const setTutorsData = computed(() => {
      const users = setUsers(props.tutors)

      const attendance = props.schedules.map(
        (sche) => users.filter((stu) => stu.schedules.find((s) => s._id === sche._id && !!s.attendend)).length
      )
      return { attendance, users, tutor: {} }
    })

    const setStudentsData = computed(() => {
      const users = setUsers(props.students)

      const attendance = props.schedules.map(
        (sche) => users.filter((stu) => stu.schedules.find((s) => s._id === sche._id && !!s.attendend)).length
      )
      return { attendance, users, tutor: {} }
    })

    const handleToggleAttendance = (value: { [x: string]: boolean }, scheduleId: string) => {
      emit('onChange', value, scheduleId)
    }

    return { setTutorsData, setStudentsData, handleToggleAttendance }
  }
})
