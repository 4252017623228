
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TwoColsLayout',
  props: {
    asideClass: { type: String, required: false },
    mainClass: { type: String }
  },
  setup() {
    const isEmbed = process.env.NAVIGATION === '0'

    return { isEmbed }
  }
})
