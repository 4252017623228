import { getClassmates } from '@/api/newPlatform/commission.api'
// Model
import { Classmate, ParsedClassmate, TutorClassmate, TutorOf } from '@/models/comission/comission'
// Utils
import { capitalizeFirstLetter, parseGoal, parseRole } from '@/utils/stringParser'

export const getTeachersWithStudents = async (
  courseId: string,
  userId: string,
  courseName = ''
): Promise<[ParsedClassmate[], TutorClassmate[]]> => {
  const response = await getClassmates(courseId, userId)

  const tutorsWithStudents = response
    .filter((item: Classmate) => item.flags.isTutor && item?.tutorOf.length > 0)
    .map((_tutor) => {
      const students = _tutor.tutorOf.map((student: TutorOf) => {
        const goal = parseGoal(student.personalGoal || 'other') || parseGoal('other') || ''
        return {
          _id: student._id,
          ...student.profile,
          avatar: student.avatar,
          fullName: capitalizeFirstLetter(student.fullName),
          personalGoal: capitalizeFirstLetter(goal),
          link: student._id ? `/profile/${student._id}` : ''
        }
      })

      const tutor = {
        _id: _tutor._id,
        ..._tutor.profile,
        avatar: _tutor.avatar,
        fullName: capitalizeFirstLetter(_tutor.fullName),
        category: `${parseRole('tutor')} ${courseName}`,
        link: _tutor._id ? `/profile/${_tutor._id}` : ''
      }

      return { tutor, students }
    })

  const teacherGroup = response
    .filter((item: Classmate) => item.flags.isTeacher || item.flags.isTutor || item.flags.isCoordinator)
    .map((_tutor) => {
      const role = _tutor.flags.isCoordinator ? 'coordinator' : _tutor.flags.isTutor ? 'tutor' : 'teacher'

      return {
        _id: _tutor._id,
        ..._tutor.profile,
        fullName: capitalizeFirstLetter(_tutor.fullName),
        role,
        avatar: _tutor.avatar,
        category: `${parseRole(role)} ${courseName}`,
        chatLink: _tutor._id ? `/chat/${_tutor._id}` : '',
        profileLink: _tutor._id ? `/profile/${_tutor._id}` : ''
      }
    })
    .sort((a, b) => {
      if (a.role === 'coordinator') return 1
      if (b.role === 'coordinator') return -1
      if (a.role === 'tutor') return 1
      if (b.role === 'tutor') return -1
      return 0
    })

  return [tutorsWithStudents, teacherGroup]
}
