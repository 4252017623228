/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
// APIs services
import {
  getChallengeData,
  getChallengesData,
  getModulesAndDeliversData,
  getCourseDeliversProgress,
  approveProject,
  disapproveProject,
  getDeliversByModuleData,
  messageViewed,
  messageUnviewed,
  lockDeliver,
  unLockDeliver,
  postDeliverMessage,
  postDeliverLink,
  unreviewProject,
  getScheduleReEnableLink as getScheduleReEnableLinkFromAPI
} from '@/api/newPlatform/challenges.api'

import { IDeliverReview, IDeliverMessage, IDeliverLock, IProjectDeliver } from '@/models/newPlatform/challenges/deliver'

import { DateTime } from 'luxon'
import { getDate, getTime } from '@/utils/datetime'
import copies from '@/locales/challenges/es.json'

import challengeStudent from '@/mocks/data/challenge/student-1.json'
import challengeTeacher from '@/mocks/data/challenge/teacher-1.json'
import challengeTutor from '@/mocks/data/challenge/tutor-1.json'

import challengesStudent from '@/mocks/data/challenges/student-1.json'
import challengesTeacher from '@/mocks/data/challenges/teacher-1.json'
import challengesTutor from '@/mocks/data/challenges/tutor-1.json'

const mocksChanllenge = {
  student: challengeStudent,
  teacher: challengeTeacher,
  tutor: challengeTutor
}

const mocksChanllenges = {
  student: challengesStudent,
  teacher: challengesTeacher,
  tutor: challengesTutor
}

export type TGetChallenges = {
  returnMockData?: boolean
  rol?: string | number
  order?: number
  userId?: string
  isTutor?: boolean
  camadaId?: string
  courseId?: string
}
export type TGetChallenge = {
  returnMockData?: boolean
  rol?: string | number
  order?: number
  userId?: string
  isTutor?: boolean
  camadaId?: string
  courseId?: string
  challengeId?: string
}

/**
 * Service to retrieve general data about challenges based on the user role
 * @param {Object} Data
 * @param {boolean} Data.returnMockData is you'd like to test with mock data
 * @param {string | number} Data.rol student | teacher | tutor to retrieve mocked data or number to fetch from the api
 * @param {string} Data.userId current logged user id
 * @param {string} Data.courseId challenges of the course you'd to retrieve the data
 * @param {boolean} Data.isTutor flag to difference the user role 3 between teacher and tutor
 * @returns
 * - For students returns challenges and deliver progress
 * - For tutors returns challenges data and delivers from it's own assigned group of students
 * - For teachers returns challenges data and delivers from the whole commission. And general stats of challenges
 */

export const getChallengesDataService = async ({
  returnMockData,
  rol = 'student',
  camadaId,
  userId,
  courseId,
  isTutor = false
}: TGetChallenges): Promise<any> => {
  if (returnMockData || (!userId && !courseId)) {
    const r = rol as 'tutor' | 'student' | 'teacher'
    const mockData = mocksChanllenges[r]

    return mockData
  }

  // Student
  if (userId && camadaId && courseId && Number(rol) === 2) {
    const results = await getChallengesData(userId, courseId, Number(camadaId))
    return results
  }

  // Tutor & teacher
  if (userId && camadaId && Number(rol) === 3) {
    const tutorId = isTutor ? userId : ''
    const response = await Promise.all([
      getModulesAndDeliversData(Number(camadaId), userId),
      getCourseDeliversProgress(Number(camadaId), tutorId)
    ])
    const { delivers, modules, students } = response[0]
    const deliversProgress = response[1]

    return { delivers, modules, deliversProgress, students }
  }

  return undefined
}

/**
 * Service to retrieve data from challege based on user role
 * @param {Object} Data
 * @param {boolean} Data.returnMockData is you'd like to test with mock data
 * @param {string | number} Data.rol student | teacher | tutor to retrieve mocked data or number to fetch from the api
 * @param {string} Data.camadaId camada number of the current course i.e 23300
 * @param {string} Data.courseId course id of the current course
 * @param {string} Data.challengeId id of the challenge module to retreve data
 * @returns
 * - For students returns data about the challenge and current of progress of the deliver if it has one
 * - For Tutors returns data about the challenge and delivers for the current challenge from it's own group of students
 * - For teachers returns data about the challenge and delivers for the current challenge from the whole commission
 */

export const getChallengeDataService = async ({
  returnMockData,
  rol = 'student',
  camadaId,
  userId,
  courseId,
  challengeId
}: TGetChallenge): Promise<any> => {
  if (returnMockData || (!camadaId && !userId && !courseId && !challengeId)) {
    const r = rol as 'tutor' | 'student' | 'teacher'
    const mockData = mocksChanllenge[r]

    return mockData
  }

  if (Number(rol) === 2 && userId && camadaId && challengeId && courseId) {
    const results = await getChallengeData(userId, Number(camadaId), challengeId, courseId)

    return results
  }

  if (Number(rol) === 3 && userId && camadaId && challengeId) {
    const response = await getDeliversByModuleData(Number(camadaId), challengeId, userId)

    return response
  }

  return undefined
}

// REVIEW PROJECTS

const softScores = [
  {
    key: 'lowScore',
    value: 'Bajo',
    disapprove: true
  },
  {
    key: 'mediumScore',
    value: 'Correcto'
  },
  {
    key: 'highScore',
    value: 'Óptimo'
  }
]

/**
 * Service to approve deliver
 * @param courseId current course id
 * @param projectId project id to update the status
 * @param score only required for integrator challenge
 * @param softScore only required for preIntegrator challenge
 */

export const onApproved = async (courseId: string, projectId: string, score?: number, softScore?: string) => {
  const body = { courseId, projectId, score, softScore }
  await approveProject(courseId, projectId, body)
}

/**
 * Service to disapprove deliver
 * @param courseId current course id
 * @param projectId project id to update the status
 * @param score only required for integrator challenge
 * @param softScore only required for preIntegrator challenge
 */

const onDisapproved = async (courseId: string, projectId: string, score: number, softScore?: string) => {
  const body = { courseId, projectId, score, softScore }
  await disapproveProject(courseId, projectId, body)
}

/**
 * Service to evaluate pre integrator challenge. Approve or Disapprove
 * @param {Object} project
 * @param {string} project.courseId current course id
 * @param {string} project._id project id to assign the score
 * @param {string} project.softScore old soft score given by the tutor if is delivering more than once
 * @param newScore new soft score given by the tutor
 */

export const onSubmitSoftScore = async (project: IDeliverReview, newScore: string) => {
  if (!newScore) return
  const fromSoftScore = softScores.find(({ key }) => key === project.softScore)
  const toSoftScore = softScores.find(({ key }) => key === newScore)

  if (fromSoftScore !== toSoftScore) {
    if (toSoftScore?.disapprove) {
      await onDisapproved(project.courseId, project._id, 0, toSoftScore.key)
    } else {
      if (toSoftScore?.key) {
        await onApproved(project.courseId, project._id, 0, toSoftScore.key)
      }
    }
  }
}

/**
 * Service to evaluate pre integrator challenge. Approve or Disapprove
 * @param {Object} project
 * @param {string} project.courseId current course id
 * @param {string} project._id project id to assign the score
 * @param {string} project.softScore softScore given previously by the tutor
 * @param {string} project.score old score given by the teacher if is delivering more than once
 * @param currentFinalScore new final score given by the teacher
 */

export const onSubmitFinalScore = async (project: IDeliverReview, currentFinalScore: string) => {
  const finalScore = parseInt(currentFinalScore, 10)

  if (finalScore && (!project.wasApproved || finalScore !== project.score)) {
    if (finalScore >= 7) {
      await onApproved(project.courseId, project._id, finalScore, project.softScore)
    } else {
      await onDisapproved(project.courseId, project._id, finalScore, project.softScore)
    }
  }
}

/**
 * Service to upload project
 * @param {Object} project
 * @param {string} project.userId user's id related to the deliver
 * @param {string} project.classId id of the class associated with the challenge
 * @param {string} project.stage stage id associated with the challenge
 * @param {string} project.module module id associated with the challenge
 * @param {string} project.link url of the deliver
 * @param {string} project._id only required if the student delivers the same challenge more than once
 * @param courseId current course id
 * @param comment additional text submit by the student
 * @param isFile format of the the deliver. link or file
 * @returns updated deliver data
 */

export const onSubmitDeliver = async (project: IProjectDeliver, courseId: string, comment = '', isFile = false) => {
  try {
    const projectData = await postDeliverLink(project, courseId)
    const fileLink = isFile ? project.link : ''
    await postDeliverMessage(project.userId, courseId, projectData._id, true, comment, project.link, fileLink)

    // student delivers again message to register the step in the timeline
    if (project._id) {
      await postDeliverMessage(
        project.userId,
        courseId,
        project._id,
        true,
        copies.ReviewChallenge.message.studentDeliveredAgain,
        ''
      )
    }

    const body = {
      courseId: courseId,
      projectId: projectData._id
    }
    await unreviewProject(courseId, projectData._id, body)

    return projectData
  } catch (e) {
    console.log(e, 'error al subir el desafio')
  }
}

/**
 * Service to update challenge's  status of mesagges to viewed
 * @param {Object} deliver
 * @param {string} deliver.courseId current course id
 * @param {string} deliver.projectId id of the project associated to the challenge
 * @param {string} deliver.teacherId id of the current logged teacher
 * @param {array} deliver.chat list of chats associated to the challenge
 */

export const updateMessageViewed = async (deliver: IDeliverMessage) => {
  if (!deliver.chat) return
  try {
    const chats = deliver.chat.filter(({ flags }) => !flags.unviewed)
    for (const chat of chats) {
      await messageViewed({
        courseId: deliver.courseId,
        projectId: deliver.projectId,
        teacherId: deliver.teacherId,
        chatId: chat._id,
        ts: Date.now()
      })
    }
  } catch (e) {
    console.log(e)
  }
}

/**
 * Service to update challenge's  status of mesagges to unviewed
 * @param {Object} deliver
 * @param {string} deliver.courseId current course id
 * @param {string} deliver.projectId id of the project associated to the challenge
 * @param {string} deliver.teacherId id of the current logged teacher
 * @param {array} deliver.chat list of chats associated to the challenge
 */

export const updateMessageUnviewed = async (deliver: IDeliverMessage) => {
  try {
    const lastChat = deliver.chat.slice(-1)
    if (!lastChat[0]._id) return
    return await messageUnviewed({
      courseId: deliver.courseId,
      projectId: deliver.projectId,
      teacherId: deliver.teacherId,
      chatId: lastChat[0]._id,
      ts: Date.now()
    })
  } catch (e) {
    console.log(e)
  }
}

/**
 * Service to lock a challenge and post message to notifify the student
 * @param {Object} deliver
 * @param {string} deliver.courseId current course id
 * @param {string} deliver.projectId id of the project to be locked
 * @param teacherId current logged teacher id
 */

export const onLockDeliver = async (deliver: IDeliverLock, teacherId: string) => {
  try {
    if (!(deliver.courseId && deliver.projectId)) return
    await lockDeliver(deliver)
    const message = copies.lockDeliver.message

    await postDeliverMessage(teacherId, deliver.courseId, deliver.projectId, false, message, '')
  } catch (e) {
    console.log(e)
  }
}

/**
 * Service to lock a challenge and post message to notifify the student
 * @param {Object} deliver
 * @param {string} deliver.courseId current course id
 * @param {string} deliver.projectId id of the project to be unlocked
 * @param teacherId current logged teacher id
 * @return new limit date which the deliver remains unlock
 */

export const onUnlockDeliver = async (deliver: IDeliverLock, teacherId: string) => {
  try {
    if (!(deliver.courseId && deliver.projectId)) return
    const { unlockedUntil } = await unLockDeliver(deliver)

    if (unlockedUntil) {
      const formatDate = DateTime.fromISO(unlockedUntil).toMillis()
      const label = copies.unLockDeliver.message
      const message = `${label} ${getDate(formatDate)} ${getTime(formatDate)}`
      await postDeliverMessage(teacherId, deliver.courseId, deliver.projectId, false, message, '')

      const unlockedDate = unlockedUntil ? DateTime.fromISO(unlockedUntil).toMillis() : null
      return unlockedDate
    } else {
      throw new Error('UnlockedUntil null')
    }
  } catch (e) {
    console.log(e)
    throw e
  }
}

/**
 * Serices call to get link for re enable expired deliver or feedback
 * @param userId `required` loged user id
 * @param courseId `required` current course
 * @param scheduleId `required` schedule to re enable
 * @param flag `required` feedback or deliver
 * @returns
 */
export const getScheduleReEnableLink = async (
  userId: string,
  courseId: string,
  scheduleId: string,
  flag: 'feedback' | 'deliver'
): Promise<string> => {
  return await getScheduleReEnableLinkFromAPI(userId, courseId, scheduleId, flag)
}
