
// Vendor
import { defineComponent, onMounted, ref, reactive, computed } from 'vue'
import { DateTime } from 'luxon'
import { useRoute } from 'vue-router'

// Store
import { themeStore } from '@/store/modules/themes.store'
import { useCourseStore } from '@/store/modules/course.store'
import { useUserStore } from '@/store/modules/user.store'

// Components
import TwoColsLayout from '@/components/aero/layout/TwoColsLayout.vue'
import Avatar from '@/components/aero/dataDisplay/Avatar.vue'
import ListTab from '@/components/aero/surfaces/challenges/ListTab.vue'
import Breadcrumb from 'primevue/breadcrumb'
import Countdown from '@/components/aero/dataDisplay/Countdown.vue'
import StateTags from '@/components/aero/dataDisplay/StateTags.vue'
import PlaceholderState from '@/components/aero/surfaces/challenges/PlaceholderState.vue'
import Message from '@/components/aero/surfaces/Message.vue'
import Timeline from '@/components/aero/dataDisplay/Timeline.vue'
import StateHistorial from '@/components/aero/dataDisplay/StateHistorial.vue'
import ErrorPage from '@/components/aero/dataDisplay/ErrorPage.vue'
import SkeletonTimeline from '@/components/aero/skeleton/SkeletonTimeline.vue'
import SkeletonAside from '@/components/aero/skeleton/SkeletonDesafioAside.vue'
import ReviewChallenge from './components/ReviewChallenge.vue'
import Toast from '@/components/aero/feedback/Toast.vue'

// API Calls
import { getUserById } from '@/api/user'
import { getChallengesData, getDeliverData, getProjects, postDeliverMessage } from '@/api/newPlatform/challenges.api'

// services
import { updateMessageViewed, updateMessageUnviewed, onLockDeliver, onUnlockDeliver } from '@/services/challenges.services'

// Locales
import copies from '@/locales/challenges/es.json'

// Models
import { ITimeline, ITimeLineStatus } from '@/models/newPlatform/challenges/timeline'
import { IChat } from '@/models/newPlatform/challenges/chat'
import { IDeliverMessage, IDeliverLock } from '@/models/newPlatform/challenges/deliver'

// Hooks
import { useTimeline, useUpdateChatApproved, useUpdateChallengeApproved } from '@/hooks/challenges/useTimeline'
import { useToast } from 'primevue/usetoast'
import { setChallengesByPriority } from '@/hooks/challenges/useSortModuleStatus'

// Utils
import { getTime, getDate } from '@/utils/datetime'

// Amplitude
import { ChallengesEvents } from '@/amplitude/constants'
import { trackEvent } from '@/amplitude/actions'
import { ToastMessageOptions } from 'primevue/toast'

export default defineComponent({
  components: {
    TwoColsLayout,
    Breadcrumb,
    ListTab,
    Avatar,
    Countdown,
    StateTags,
    Message,
    ErrorPage,
    PlaceholderState,
    Timeline,
    StateHistorial,
    SkeletonTimeline,
    SkeletonAside,
    ReviewChallenge,
    Toast
  },
  setup() {
    // Store Hooks
    const {
      params: { studentId }
    } = useRoute()
    const { id: courseId, camadaNro } = useCourseStore()
    themeStore().changeDefault('aero')
    const { id: userId, isTutor, role, fullName, avatar } = useUserStore()
    const isTeacher = Number(role) === 3 && !isTutor

    // Toast hook
    const toast = useToast()

    // Local States
    const modules = ref()

    const isTimeLineLoading = ref<boolean>(true)
    const isAsideLoading = ref<boolean>(true)
    const isError = ref<boolean>(false)

    const studentData = reactive({
      fullName: '',
      avatar: '',
      _id: studentId.toString()
    })

    const activeChallenge = reactive({
      moduleId: '',
      challengeName: '',
      classOrder: 0,
      expiresAt: 0,
      expiresText: '',
      openDate: 0,
      isChallengeEnabled: false,
      isDeliverExpired: false,
      isPreIntegratorTp: false,
      isIntegratorTp: false,
      slideUrl: '',

      deliverId: '',
      fromSoftScore: '',
      score: 0,
      wasApproved: false,
      wasDisapproved: false,
      wasReviewed: false,
      firstReviewEstimatedAt: null as null | number,
      unlockedUntil: null as null | number,
      flagDeliverAfterExpire: false,
      isLocked: false,
      finalDeadline: null as null | number,
      timeline: [] as ITimeline[],
      chat: [] as IChat[],
      history: [] as (ITimeline | IChat)[]
    })

    // condition to display/hide review component
    const setReviewChallenge = computed(() => {
      return (
        // review unlocked challenge
        (activeChallenge.openDate < Date.now() &&
          activeChallenge.deliverId &&
          !activeChallenge.wasApproved &&
          activeChallenge.unlockedUntil &&
          activeChallenge.unlockedUntil > Date.now() &&
          activeChallenge.history.length &&
          activeChallenge.history.slice(-1)[0].key === ITimeLineStatus.deliveredAgain) ||
        // review first deliver
        (activeChallenge.openDate < Date.now() &&
          activeChallenge.deliverId &&
          !activeChallenge.wasApproved &&
          !activeChallenge.wasReviewed) ||
        // review challenges that has more than one deliver
        (activeChallenge.openDate < Date.now() &&
          activeChallenge.deliverId &&
          activeChallenge.finalDeadline &&
          activeChallenge.finalDeadline > Date.now() &&
          activeChallenge.history.length &&
          activeChallenge.history.slice(-1)[0].key === ITimeLineStatus.deliveredAgain) ||
        (activeChallenge.history.length &&
          activeChallenge.history.slice(-1)[0].key === ITimeLineStatus.reviewExpected &&
          activeChallenge.deliverId &&
          activeChallenge.flagDeliverAfterExpire)
      )
    })

    /**
     * Returns boolean to display challenges that depend on time display
     */
    const requiredForCountDown = (module: any) => {
      const pendingToReceive =
        (!module.project && module.expiresAt > Date.now()) ||
        (module.projectData &&
          module.finalDeadline > Date.now() &&
          !module.projectData?.chat.slice(-1)[0].flags.fromStudent &&
          !module.projectData.flags.wasApproved)

      const pendingToReview =
        (module.project &&
          !module.project?.flags.hoursLeftToReviewExpired &&
          module.project?.flags.hoursLeftToReview > 0 &&
          !module.project?.flags.wasReviewed &&
          !module.projectData.flags.wasApproved) ||
        (module.project && module.project?.chat.slice(-1)[0].flags.fromStudent && !module.project.flags.wasApproved)

      return pendingToReceive || pendingToReview
    }

    /**
     * Condition for component to display challenges that require static status
     */
    const requiredForStatusTag = (module: any) => {
      const approved = module.project?.flags.wasReviewedOnTime && module.project?.flags.wasApproved
      const approvedDelay = !module.project?.flags.wasReviewedOnTime && module.project?.flags.wasApproved

      const expired =
        (!module.project && Date.now() > module.expiresAt) ||
        (module.project && module.finalDeadline < Date.now() && module.status !== 'approved')

      const outOfTime = module.project?.flags.hoursLeftToReviewExpired && !module.project.flags.wasReviewed

      const reDeliver = module.project && !module.project?.chat.slice(-1)[0].flags.fromStudent && !module.project?.flags.wasApproved

      return approved || approvedDelay || expired || outOfTime || reDeliver
    }

    /**
     * Returns status tag for static states that do not depend on time display
     */
    const getStatusTag = (module: any) => {
      const status = copies.challengesByModule.statusTags
      if (
        (!module.project && module.expiresAt < Date.now()) ||
        (module.project && module.finalDeadline && module.finalDeadline < Date.now() && module.status !== 'approved')
      ) {
        return status.expired.slug
      } else {
        if (module.project?.flags?.wasReviewedOnTime && module.project?.flags?.wasApproved) {
          return status.approved.slug
        } else if (!module.project?.flags?.wasReviewedOnTime && module.project?.flags?.wasApproved) {
          return status.approvedOutOfTime.slug
        } else if (module.project?.flags?.hoursLeftToReviewExpired && !module.project?.flags.wasReviewed) {
          return status.reviewOutOfTime.slug
        } else {
          return status.toReceive.slug
        }
      }
    }

    /**
     * Returns challenge that qualifies for time display, such as correction pending and delivered pending
     */
    const getCountDownChallenge = (module: any) => {
      return (!module.project && module.expiresAt > Date.now()) ||
        (module.finalDeadline > Date.now() &&
          module.projectData.chat.slice(-1)[0].text === copies.ReviewChallenge.message.deliverAgain) ||
        (!module.project?.flags.hoursLeftToReviewExpired &&
          module.project?.flags.hoursLeftToReview > 0 &&
          !module.project?.flags.wasReviewed) ||
        (module.finalDeadline > Date.now() && module.project)
        ? module
        : null
    }

    // Services calls
    const _getStudent = async () => {
      try {
        const res = await getUserById(studentId.toString())
        studentData._id = res.id
        studentData.fullName = `${res.firstName} ${res.lastName}`
        studentData.avatar = res.avatar
      } catch {
        isError.value = true
      }
    }

    const _getModulesByStudent = async () => {
      try {
        const response = await getChallengesData(studentData._id, courseId, Number(camadaNro))
        modules.value = response
        await _getProjects()

        const defautlChallenge = modules.value[0]
        const isExpired = defautlChallenge.expiresAt && defautlChallenge.expiresAt < Date.now()
        activeChallenge.moduleId = defautlChallenge._id
        activeChallenge.challengeName = defautlChallenge.name
        activeChallenge.expiresAt = defautlChallenge.expiresAt || 0
        activeChallenge.isDeliverExpired = isExpired || false
        activeChallenge.openDate = defautlChallenge.class.day
        activeChallenge.isChallengeEnabled = defautlChallenge.class.day < Date.now()
        activeChallenge.classOrder = defautlChallenge.class.order
        activeChallenge.slideUrl = defautlChallenge.slideUrl || ''

        setExpiresAndOpenAtDateTime()

        const history = {
          timeline: activeChallenge.timeline,
          chat: activeChallenge.chat
        }
        activeChallenge.history = useTimeline(
          history,
          activeChallenge.openDate,
          activeChallenge.expiresAt,
          activeChallenge.isChallengeEnabled
        )
        // TODO: Fix => avoid multiple get Projects call
        // _getProjects and getChallengesData calls the same endpoint

        if (defautlChallenge.projectId) {
          getDeliverStatus(defautlChallenge.projectId)
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        isError.value = true
      } finally {
        isTimeLineLoading.value = false
        isAsideLoading.value = false
      }
    }

    const _getProjects = async () => {
      const projects = await getProjects(studentId.toString(), courseId)
      const modulesTmp = modules.value.map((_module: any) => ({
        ..._module,
        project: projects.find((_project: any) => _project.module._id === _module._id)
      }))

      modules.value = setChallengesByPriority(modulesTmp)
    }

    const getDeliverStatus = async (deliverId: string, camadaId = Number(camadaNro)) => {
      try {
        isTimeLineLoading.value = true
        const response = await getDeliverData(camadaId, deliverId)
        const unlockedDate = response.unlockedUntil ? DateTime.fromISO(response.unlockedUntil).toMillis() : null
        const isExpired = response.expiresAt && response.expiresAt < Date.now()

        activeChallenge.flagDeliverAfterExpire = response.flagDeliverAfterExpire || false
        activeChallenge.deliverId = deliverId
        activeChallenge.moduleId = response._id
        activeChallenge.isDeliverExpired = isExpired || false
        activeChallenge.isIntegratorTp = response.flags?.isIntegratorTp || false
        activeChallenge.isPreIntegratorTp = response.flags?.isPreIntegratorTp || false
        activeChallenge.score = response.score || 0
        activeChallenge.fromSoftScore = response.toSoftScore || ''
        activeChallenge.wasApproved = response.flags?.wasApproved || false
        activeChallenge.wasDisapproved = response.flags?.wasDisapproved || false
        activeChallenge.wasReviewed = response.flags?.wasReviewed || false
        activeChallenge.firstReviewEstimatedAt = response.firstReviewEstimatedAt || null
        activeChallenge.unlockedUntil = unlockedDate
        activeChallenge.finalDeadline = response.timeline ? getFinalDeadline(response.timeline) : null
        activeChallenge.isLocked = response.locked || false
        activeChallenge.timeline = response.timeline || []
        activeChallenge.chat = response.chat || []

        const history = {
          timeline: activeChallenge.timeline,
          chat: activeChallenge.chat
        }
        activeChallenge.history = useTimeline(
          history,
          activeChallenge.openDate,
          activeChallenge.finalDeadline || 0,
          activeChallenge.isChallengeEnabled,
          isTeacher,
          activeChallenge.firstReviewEstimatedAt || 0
        )

        const challenge = modules.value.find((challenge: any) => challenge.projectId === activeChallenge.moduleId)
        if (challenge.project) {
          const lastChat = challenge.project.chat?.slice(-1)[0]
          if (lastChat?.flags.fromStudent && !lastChat?.viewed) {
            await markAsRead()
          }
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        isError.value = true
      } finally {
        isTimeLineLoading.value = false
      }
    }

    // DOM Handlers
    const handleClick = async (moduleId: string, challengeName: string, deliverId: string) => {
      activeChallenge.moduleId = moduleId
      activeChallenge.challengeName = challengeName
      setExpiresAndOpenAtDateTime()
      if (!deliverId) {
        resetChallengeStatus()
      } else {
        await getDeliverStatus(deliverId)
      }
    }

    const getFinalDeadline = (timeline: ITimeline[]) => {
      const finalDeadline = timeline.find((step) => step.key === ITimeLineStatus.finalDeadline)
      if (finalDeadline) {
        return typeof finalDeadline.date === 'string' ? DateTime.fromISO(finalDeadline.date).toMillis() : finalDeadline.date
      } else {
        return null
      }
    }

    const resetChallengeStatus = () => {
      activeChallenge.deliverId = ''
      activeChallenge.fromSoftScore = ''
      activeChallenge.score = 0
      activeChallenge.wasReviewed = false
      activeChallenge.unlockedUntil = null
      activeChallenge.isLocked = false
      activeChallenge.wasApproved = false
      activeChallenge.timeline = []
      activeChallenge.chat = []
      activeChallenge.history = []
      activeChallenge.isIntegratorTp = false
      activeChallenge.isPreIntegratorTp = false
      const history = {
        timeline: activeChallenge.timeline,
        chat: activeChallenge.chat
      }
      activeChallenge.history = useTimeline(
        history,
        activeChallenge.openDate,
        activeChallenge.expiresAt,
        activeChallenge.isChallengeEnabled,
        isTeacher,
        activeChallenge.firstReviewEstimatedAt || 0
      )
    }

    const setDateTimeString = (_date: number) => {
      const date = getDate(_date)
      const time = getTime(_date)

      return `${date} a las ${time}`
    }

    // checks unread messages from students onload
    const checkUnreadMessages = (chat: IChat[]) => {
      if (!chat.length) return false
      const lastChat = chat.slice(-1)[0]

      if (lastChat.flags.fromStudent && !lastChat.viewed) {
        return true
      } else {
        return false
      }
    }

    const setExpiresAndOpenAtDateTime = () => {
      const selectedChallenge = modules.value.find((_module: any) => _module._id === activeChallenge.moduleId)
      const isExpired = selectedChallenge.expiresAt && selectedChallenge.expiresAt < Date.now()
      activeChallenge.isDeliverExpired = isExpired
      activeChallenge.expiresText = setDateTimeString(selectedChallenge.expiresAt)
      activeChallenge.openDate = selectedChallenge.class.day
      activeChallenge.isChallengeEnabled = selectedChallenge.class.day < Date.now()
      activeChallenge.classOrder = selectedChallenge.class.order
      activeChallenge.isIntegratorTp = selectedChallenge.flags?.isIntegratorTp || false
      activeChallenge.isPreIntegratorTp = selectedChallenge.flags?.isPreIntegratorTp || false
      activeChallenge.slideUrl = selectedChallenge.slideUrl || ''
    }

    // Toast notifications and state update

    /**
     * Show toast and update state for the challenge
     */
    const handleCorrectionSuccess = ({
      projectId,
      approved,
      comment,
      score
    }: {
      projectId: string
      approved: boolean
      comment: string
      score?: string
    }) => {
      const teacher = {
        avatar: avatar,
        name: fullName,
        _id: userId
      }

      const { success, group, life } = copies.ReviewChallenge.toast
      toast.add({ severity: copies.ReviewChallenge.toast.toastSeverity.success as ToastMessageOptions['severity'], detail: success, group, life })

      const isReviewOnTime = !modules.value.find((_module: any) => _module.project?._id === projectId).project.flags
        .hoursLeftToReviewExpired

      const moduleUpdate = modules.value.find((_module: any) => _module.project?._id === projectId)

      if (moduleUpdate) {
        if (approved) {
          let chatsApproved: IChat[]
          if ((activeChallenge.isPreIntegratorTp || activeChallenge.isIntegratorTp) && score) {
            chatsApproved = useUpdateChatApproved(teacher, comment, false, activeChallenge.isPreIntegratorTp, score)
          } else {
            chatsApproved = useUpdateChatApproved(teacher, comment)
          }

          activeChallenge.chat = [...activeChallenge.chat, ...chatsApproved]

          // Updated timeline view
          activeChallenge.history = useUpdateChallengeApproved(
            activeChallenge.chat,
            activeChallenge.timeline,
            activeChallenge.openDate,
            activeChallenge.expiresAt,
            activeChallenge.isChallengeEnabled,
            isTeacher,
            activeChallenge.firstReviewEstimatedAt || 0
          )
          activeChallenge.wasApproved = true

          setApproveAnimation(moduleUpdate._id)
        }
        moduleUpdate.project.flags = {
          wasApproved: approved,
          wasReviewedOnTime: isReviewOnTime,
          wasReviewed: true,
          hoursLeftToReviewExpired: false,
          canMakeNewUpdates: !approved
        }

        if (moduleUpdate.project.chat) {
          moduleUpdate.project.chat.slice(-1)[0].flags.fromStudent = false
        }
      }
    }

    const handleCorrectionFailed = () => {
      const { error, group, life } = copies.ReviewChallenge.toast
      toast.add({ severity: copies.ReviewChallenge.toast.toastSeverity.error as ToastMessageOptions['severity'], detail: error, group, life })
    }

    // Extra actions

    const markAsRead = async () => {
      if (!activeChallenge.deliverId) return
      const _deliver: IDeliverMessage = {
        courseId,
        projectId: activeChallenge.deliverId,
        teacherId: userId,
        chat: activeChallenge.chat
      }
      // update status in the student's list UI
      const challenge = modules.value.find((challenge: any) => challenge.projectId === activeChallenge.moduleId)
      if (challenge.project) {
        const lastChat = challenge.project.chat?.slice(-1)[0]
        if (lastChat) lastChat.viewed = userId
      }
      updateMessageViewed(_deliver)
    }

    const markAsUnread = async (deliverId: string, chat: IChat[]) => {
      if (!deliverId) return

      const _deliver: IDeliverMessage = {
        courseId,
        projectId: deliverId,
        teacherId: userId,
        chat: chat
      }
      // update status in the student's deliver list UI
      const challenge = modules.value.find((challenge: any) => challenge.projectId === deliverId)
      if (challenge.project) {
        const lastChat = challenge.project.chat?.slice(-1)[0]
        if (lastChat) lastChat.viewed = ''
      }
      await updateMessageUnviewed(_deliver)
    }

    const handleLockDeliver = async (deliverId: string) => {
      try {
        if (!deliverId) return

        const tempDeliver: IDeliverLock = {
          courseId,
          projectId: deliverId
        }

        // update status in the student's deliver list UI
        const challenge = modules.value.find((challenge: any) => challenge.projectId === deliverId)
        if (challenge) {
          challenge.project.locked = true
        }

        await onLockDeliver(tempDeliver, userId)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e, 'Ocurrio un error al bloquear una entrega')
      }
    }

    const handleUnlockDeliver = async (deliverId: string) => {
      try {
        if (!deliverId) return

        const tempDeliver: IDeliverLock = {
          courseId,
          projectId: deliverId
        }

        const unlockedUntil = await onUnlockDeliver(tempDeliver, userId)

        // send the message to update the current status of the timeline to unlocked
        await postDeliverMessage(userId, courseId, activeChallenge.deliverId, false, copies.enableLockedDeliver.message, '')

        // update status in the student's deliver list UI
        const challenge = modules.value.find((challenge: any) => challenge.projectId === deliverId)
        if (challenge) {
          challenge.project.locked = false
          challenge.project.unlockedUntil = unlockedUntil
        }

        await getDeliverStatus(deliverId)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e, 'Ocurrio un error al desbloquear una entrega')
      }
    }

    // Amplitude track event
    const handleTrackEvent = (name: string, props = {}) => {
      trackEvent(name, props)
    }

    const setApproveAnimation = (moduleId: string) => {
      const MODULE = document.getElementById(moduleId)

      const CONTAINER = document.getElementById('modules-container')

      MODULE?.classList.add('animation__approve-challenge-1')

      MODULE?.addEventListener('animationend', (e: AnimationEvent) => {
        if (e.animationName === 'approve-challenge-1') {
          MODULE.style.display = 'none'
          CONTAINER?.appendChild(MODULE)
          MODULE?.classList.add('animation__approve-challenge-2')
        }
      })
    }

    // Lifecycle Hooks
    onMounted(async () => {
      await _getStudent()
      _getModulesByStudent()
    })

    return {
      copies,
      ITimeLineStatus,
      modules,
      activeChallenge,
      studentData,
      isTimeLineLoading,
      isAsideLoading,
      isError,
      teacherId: userId,
      isTutor,
      courseId,
      handleClick,
      getDeliverStatus,
      requiredForCountDown,
      getCountDownChallenge,
      requiredForStatusTag,
      getStatusTag,
      setDateTimeString,
      setReviewChallenge,
      handleCorrectionSuccess,
      handleCorrectionFailed,
      markAsRead,
      markAsUnread,
      checkUnreadMessages,
      handleLockDeliver,
      handleUnlockDeliver,
      handleTrackEvent,
      ChallengesEvents
    }
  }
})
