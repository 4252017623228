import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5a857800"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_check = _resolveComponent("check")!
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass([_ctx.state ? 'active' : '']),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleCick && _ctx.handleCick(...args)))
  }, [
    (_ctx.state)
      ? (_openBlock(), _createBlock(_component_icon, {
          key: 0,
          size: 16,
          class: "p-mr-2"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_check)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.value), 1)
  ], 2))
}