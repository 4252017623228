
import Icon from '../icon/Icon.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    Icon
  },
  props: {
    asLinkType: {
      type: String,
      default: '',
      required: false
    },
    color: {
      type: String,
      default: '',
      required: false
    },
    href: {
      type: String,
      default: '',
      required: false
    },
    size: {
      type: Number,
      default: 22,
      required: false
    }
  }
})
