
// Vendor
import { defineComponent, ref, watch } from 'vue'

// Icons
import Icon from '@/components/aero/icon/Icon.vue'
import { Search } from '@/components/aero/icon/templates'

export default defineComponent({
  components: {
    Search,
    Icon
  },
  props: {
    placeholderText: { type: String, required: true }
  },
  setup(_, { emit }) {
    const inputName = ref('')
    const handleUpdateSearch = () => {
      emit('onUpdateSearch', inputName.value)
    }

    watch(inputName, () => {
      if (inputName.value === '') handleUpdateSearch()
    })

    return { inputName, handleUpdateSearch }
  }
})
