import { Api } from '../api'
const { API_ENDPOINT } = process.env

// Model
// eslint-disable-next-line
import { Classmate } from '@/models/comission/comission'

/**
 * API request to retrieve the posts from a certain user.
 * @param courseId Logged course id
 * @param userId Logged user id
 */

export const getClassmates = async (courseId: string, userId: string): Promise<Classmate[]> => {
  const classmates: Classmate[] = await Api.get(
    `${API_ENDPOINT}/platform/courses/${courseId}/classmates?courseId=${courseId}&userId=${userId}&v2=1`
  )
  return classmates
}
