import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a41f457"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profile" }
const _hoisted_2 = { class: "profile__detail gap" }
const _hoisted_3 = { class: "p-d-flex gap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SkeletonIcon = _resolveComponent("SkeletonIcon")!
  const _component_SkeletonText = _resolveComponent("SkeletonText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SkeletonIcon, { class: "profile__avatar" }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_SkeletonText, { length: 5 }),
        _createVNode(_component_SkeletonText, { length: 12 })
      ]),
      _createVNode(_component_SkeletonText, {
        length: 12,
        sm: ""
      })
    ])
  ]))
}