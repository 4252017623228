import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e7979dae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "viewer p-mb-3" }
const _hoisted_2 = { class: "header p-pb-4" }
const _hoisted_3 = { class: "comment" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 0,
  class: "switch"
}
const _hoisted_6 = { class: "p-ml-2" }
const _hoisted_7 = ["type", "data"]
const _hoisted_8 = {
  key: 1,
  class: "image-preview"
}
const _hoisted_9 = ["src"]
const _hoisted_10 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_url_button = _resolveComponent("url-button")!
  const _component_DownloadButton = _resolveComponent("DownloadButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", { innerHTML: _ctx.comment }, null, 8, _hoisted_4)
      ]),
      (!_ctx.types.link && !_ctx.types.compressed)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_InputSwitch, {
              modelValue: _ctx.preview,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.preview) = $event))
            }, null, 8, ["modelValue"]),
            _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.preview ? _ctx.t('homework.challenge.peerReview.correction.hide') : _ctx.t('homework.challenge.peerReview.correction.show')) + " " + _toDisplayString(_ctx.t('homework.challenge.peerReview.correction.preview')), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.preview)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.types.doc || _ctx.types.pdf)
            ? (_openBlock(), _createElementBlock("object", {
                key: 0,
                type: _ctx.type,
                data: `${_ctx.viewer}${_ctx.file}`,
                width: "100%",
                height: "280px"
              }, null, 8, _hoisted_7))
            : _createCommentVNode("", true),
          (_ctx.types.image)
            ? (_openBlock(), _createElementBlock("figure", _hoisted_8, [
                _createElementVNode("img", {
                  src: _ctx.file,
                  alt: "challenge image"
                }, null, 8, _hoisted_9)
              ]))
            : _createCommentVNode("", true),
          (_ctx.types.compressed)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10))
            : _createCommentVNode("", true)
        ], 64))
      : (!_ctx.preview || _ctx.types.link)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_ctx.challenge.link)
              ? (_openBlock(), _createBlock(_component_url_button, {
                  key: 0,
                  link: _ctx.challenge.link
                }, null, 8, ["link"]))
              : _createCommentVNode("", true),
            (_ctx.challenge.attachment)
              ? (_openBlock(), _createBlock(_component_DownloadButton, {
                  key: 1,
                  class: "p-button-lg send p-button-custom-tertiary",
                  label: _ctx.t('homework.challenge.peerReview.correction.download'),
                  attachment: _ctx.challenge.attachment
                }, null, 8, ["label", "attachment"]))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true)
  ]))
}