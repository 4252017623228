import { OnboardingVideo } from '@/models/newPlatform/onboardingVideo'

import { Api } from '../api'
const { API_ENDPOINT } = process.env


/**
 * API Call to fetch all students onboarding-videos 
 * example: all webnars incoming 
 * @returns an array of onboarding videos
 */

export const getStudentVideos = async (): Promise<OnboardingVideo[]> => {
  return await Api.get(
    `${API_ENDPOINT}/platform/onboarding-videos?forStudents=1`
  )
}
