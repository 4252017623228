import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6078ef80"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form__heading p-mb-6" }
const _hoisted_2 = {
  class: "t2",
  role: "heading"
}
const _hoisted_3 = { class: "form__twocols" }
const _hoisted_4 = { class: "p2 text-light-gray p-my-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_input_wrapper = _resolveComponent("input-wrapper")!
  const _component_input_text = _resolveComponent("input-text")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_input_time = _resolveComponent("input-time")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("form", {
      onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.copies.formAfterClass.title), 1),
        _createVNode(_component_Button, {
          icon: "pi pi-times",
          label: _ctx.copies && _ctx.copies.formAfterClass.btnCancel,
          class: "secondary",
          onClick: _ctx.handleCloseForm
        }, null, 8, ["label", "onClick"])
      ]),
      _createVNode(_component_input_wrapper, {
        label: _ctx.copies && _ctx.copies.formAfterClass.inputs.dropdownLabel,
        inputId: "classSelected"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_select_input, {
            name: "classSelected",
            onClassSelected: _ctx.handleSelectedClass,
            options: _ctx.enabledOptions,
            valueSuggested: _ctx.suggestedClass
          }, null, 8, ["onClassSelected", "options", "valueSuggested"])
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_input_wrapper, {
        label: _ctx.copies && _ctx.copies.formAfterClass.inputs.textLabel,
        error: _ctx.classTitleError ? _ctx.copies.formAfterClass.errorLabel.classTitle : ''
      }, {
        default: _withCtx(() => [
          _createVNode(_component_input_text, {
            modelValue: _ctx.classTitle,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.classTitle) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["label", "error"]),
      _createVNode(_component_input_wrapper, {
        label: _ctx.copies && _ctx.copies.formAfterClass.inputs.calendarLabel,
        inputId: "dateSelected"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Calendar, {
            modelValue: _ctx.classDate,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.classDate) = $event)),
            minDate: _ctx.minDate,
            maxDate: _ctx.maxDate,
            disabledDays: [0, 6],
            disabledDates: _ctx.disabledDates,
            dateFormat: "dd/mm/yy",
            showIcon: ""
          }, null, 8, ["modelValue", "minDate", "maxDate", "disabledDates"])
        ]),
        _: 1
      }, 8, ["label"]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_input_wrapper, {
          label: _ctx.copies && _ctx.copies.formAfterClass.inputs.time.startLabel,
          hint: _ctx.copies && _ctx.copies.formAfterClass.inputs.time.hint,
          inputId: "startTime",
          error: _ctx.time.errorStart ? _ctx.copies && _ctx.copies.formAfterClass.errorLabel.time : ''
        }, {
          default: _withCtx(() => [
            _createVNode(_component_input_time, {
              name: "startTime",
              onStartTime: _ctx.handleSelectedStartTime
            }, null, 8, ["onStartTime"])
          ]),
          _: 1
        }, 8, ["label", "hint", "error"]),
        _createVNode(_component_input_wrapper, {
          label: _ctx.copies && _ctx.copies.formAfterClass.inputs.time.endLabel,
          inputId: "endTime",
          error: _ctx.time.errorEnd ? _ctx.copies && _ctx.copies.formAfterClass.errorLabel.time : ''
        }, {
          default: _withCtx(() => [
            _createVNode(_component_input_time, {
              name: "endTime",
              onEndTime: _ctx.handleSelectedEndTime
            }, null, 8, ["onEndTime"])
          ]),
          _: 1
        }, 8, ["label", "error"])
      ]),
      _createVNode(_component_Button, {
        type: "submit",
        label: _ctx.complementaryId ? _ctx.copies && _ctx.copies.formAfterClass.cta.editAC : _ctx.copies && _ctx.copies.formAfterClass.cta.createAC,
        disabled: !_ctx.isComplete,
        loading: _ctx.isLoading,
        class: "p-mt-4"
      }, null, 8, ["label", "disabled", "loading"])
    ], 32),
    _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.copies.formAfterClass.footer.message), 1)
  ]))
}