
import { CustomTab, ITabChange } from '@/models/commons/primevueTab'
import { defineComponent, ref, watch, Ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import Tabs from '@/components/commons/Tabs.vue'
import { findAll, findBySector, findByCompanyModalityArea, findCustomJobOffers } from '@/api/job-offers.api'
import { IWrangleJobOffer } from '@/models/jobs/jobs-offers'
import JobOfferCard from './JobOfferCard.vue'
import Paginator from 'primevue/paginator'
import SkeletonText from '@/components/aero/skeleton/SkeletonText.vue'
import { useFiltersJobOfferStore } from '@/store/modules/filters-job-offer.store'
import { useUserSessionStore } from '@/store/modules/user-session.store'
import { useUserStore } from '@/store/modules/user.store'
import moment from 'moment'
// import CustomOffersDropdown from './CustomOffersDropdowns.vue'

export default defineComponent({
  components: {
    Tabs,
    JobOfferCard,
    Paginator,
    // CustomOffersDropdown,
    SkeletonText
  },
  emits: ['hasFilters', 'tabSelected'],
  props: {
    isSearching : {
      type: Boolean,
      default: false
    },
    userModalitiesId: { type: Object },
    userAreaId: { type: String },
    userSectorsId: { type: Object },
    hasCoderPlus: { type: Boolean },
    userSectorsName: { type: Object},
    loadingPreferences: { type: Boolean },
    preferencesFromDialog: { type: Boolean },
    hasSetPreferences: { type: Boolean }
  },
  setup (props, { emit }) {
    const { t } = useI18n()
    const assignedFiltersJobOfferStore = useFiltersJobOfferStore()
    const tabSelected = ref<number>(0)
    const jobOffers: Ref<IWrangleJobOffer[]> = ref([])
    const loading = ref(false)
    const offset = ref<number>(0)
    const rowsDefault = 5
    const totalFirstTab = ref<number>(0)
    const totalSecondTab = ref<number>(0)
    const displayDialog = ref(false)
    const dropdowns = ref(null)
    const jobOffersCustom: Ref<IWrangleJobOffer[]> = ref([])
    const preferences = ref(false)
    const coderPlusActive = ref()
    const coderPlusEndDate = ref()
    const coderPlusPrice = ref()
    const coderPlusCurrency = ref()
    const coderPlusAmountOfIntervals = ref()
    const coderPlusLinkPurchased = ref()
    const session = useUserSessionStore()
    const idToken = session.token
    const accessToken = session.auth
    const userID = ref()
    const country = ref()

    const tabs: Array<CustomTab> = [
      { title: 'Ofertas laborales ', id: '1' }
      // { title: 'Ofertas personalizadas', id: '2' }
      // { title: 'Mis postulaciones', id: '3' }
    ]
    const hasCoderPlus = ref(props.hasCoderPlus)
    const changeTab = (e: ITabChange) => {
      tabSelected.value = e.index
      changeFilters(tabSelected.value)
    }

    const isCoderPlus = async (idToken: string, accessToken: string) => {
      try {
        const url = process.env.VUE_APP_CH_API_BFF
        const res = await fetch(`${url}/coder-plus`,
        {
          headers: { 
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            idToken,
            accessToken
          },
          mode: 'cors'
        })
        const data = await res.json()
        if (data.isCoderPlus) {
          coderPlusActive.value = true
          coderPlusEndDate.value = moment(data.endDate).format('DD/MMM/YYYY')
        } else {
          coderPlusActive.value = false
          coderPlusPrice.value = data.price / 3
          coderPlusCurrency.value = data.currency
          coderPlusAmountOfIntervals.value = data.amountOfIntervals
          coderPlusLinkPurchased.value = data.linkPurchased
        }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log('hay un error', error)
        }
      }

    isCoderPlus(idToken, accessToken)

    const landingLink = async () => {
      const user = useUserStore()
      await user.getUser()

      userID.value = user.id
      country.value = user.country
    }

    // landingLink()

    const changeFilters = (tabSelected: number) => {
      let hasFilters = true
      tabSelected === 0 ? hasFilters = true : hasFilters = false
      emit('hasFilters', hasFilters)
      emit('tabSelected', tabSelected)
    }

    const getJobOffers = async (page?:number, limit?:number, queryParams = '') => {
      loading.value = true
      const jobOffersFromApi = await findAll(page, limit, queryParams)
      jobOffers.value = jobOffersFromApi.data
      totalFirstTab.value = jobOffersFromApi.totalPages
      loading.value = false
    }

    const analyticsEvent = () => {
        window.dataLayer.push({
        event: 'trackEvent',
        eventCategory: 'bolsa_de_empleo',
        eventAction: 'click_CTA_suscribirme',
        eventLabel: 'suscribirme_ofertas_personalizadas'
      })
    } 

   const changePage = async (page: number) => {
    if(tabSelected.value === 0) {
      await getJobOffers(page + 1, rowsDefault, assignedFiltersJobOfferStore.getQueryParam)
      offset.value = page * rowsDefault
    }
    if(tabSelected.value === 1) {
      await getCustomJobOffers(page + 1)
      offset.value = page * rowsDefault
    }
    window.dataLayer.push({
      event: 'trackEvent',
      eventCategory: 'bolsa_de_empleo',
      eventAction: 'pagina',
      eventLabel: `${page+1}`
    })
    }

    watch(assignedFiltersJobOfferStore, async () => {
      // getJobOffers(1, rowsDefault, assignedFiltersJobOfferStore.getQueryParam)
      await changePage(0)
    }, { immediate: true })

    watch(props, () => {
      props.preferencesFromDialog ? preferences.value = true : preferences.value = false
    })

    const formComplete = ref(false)
    const setFormCompleteValue = (event: any) => {
      formComplete.value = event.isFormComplete
    }
    // watch(props, () => {
    //  if(!props.loadingPreferences) getCustomJobOffers()
    // })

    const getCustomJobOffers = async (page = 0) => {
      offset.value = 0
      const formatedModalitiesIds = formatPreferenceArray(props.userModalitiesId)
      const formatedAreaId = formatPreferenceArray([props.userAreaId])
      const formatedSector = formatPreferenceArray(props.userSectorsName)
      const companiesIds = (await findBySector(formatedSector)).data.map(({ _id }: { _id: string }) => _id)
      const formatedCompaniesIds = formatPreferenceArray(companiesIds)
      const customJobOffersResponse = await findCustomJobOffers(page, rowsDefault, formatedAreaId, formatedModalitiesIds, formatedCompaniesIds)
      jobOffersCustom.value = customJobOffersResponse.data
      totalSecondTab.value = customJobOffersResponse.totalPages
      
    }
    const formatPreferenceArray = (preference: any) => {
      let formatingPreference = Object.values(preference)
      formatingPreference = formatingPreference.map((preference: any) => {
        const formatedPreference = preference.replace(/ /g,'+')
        return formatedPreference
        })
      const formatingPreferenceString = formatingPreference.join('|')
      return formatingPreferenceString
    }

    const submitForm = async () => {
      const isFormCorrect = await (dropdowns.value as any).submit()
      if (isFormCorrect) {
        getCustomJobOffers()
        preferences.value = true
      }
    }

    return {
      t,
      changeTab,
      tabSelected,
      tabs,
      jobOffers,
      changePage,
      totalFirstTab,
      offset,
      rowsDefault,
      loading,
      displayDialog,
      submitForm,
      setFormCompleteValue,
      formComplete,
      dropdowns,
      jobOffersCustom,
      preferences,
      totalSecondTab,
      coderPlusActive,
      coderPlusPrice,
      coderPlusCurrency,
      coderPlusAmountOfIntervals,
      coderPlusLinkPurchased,
      coderPlusEndDate,
      analyticsEvent
    }
  }
})
