
import { defineComponent, ref, onMounted, PropType } from 'vue'

// Component
import Item from '@/components/aero/dataDisplay/Item.vue'
import SmallCard from '@/components/aero/surfaces/commission/SmallCard.vue'
import { Calendar, Clock, CloseCircle } from '@/components/aero/icon/templates'

// Utils
import { parseToMillis, getDate, getYear } from '@/utils/datetime'

// Locales
import copies from '@/locales/commission/es.json'

export default defineComponent({
  components: { Item, SmallCard },
  props: {
    courseName: { type: String, required: true },
    commission: { type: String, required: true },
    daysAndHours: { type: String, required: true },
    startDate: { type: String, required: true },
    endDate: { type: String, required: true },
    holidays: { type: Array as PropType<string[]>, default: () => [] },
    category: { type: String, required: true }
  },
  setup(props) {
    const schedule = ref('')
    const holidayDates = ref<string[]>(props.holidays)
    const holidayText = ref('')
    const setPeriod = () => {
      const startDate = parseToMillis(props.startDate)
      const endDate = parseToMillis(props.endDate)

      schedule.value = `${copies.ItemsCard.schedule.from} ${getDate(startDate)} ${copies.ItemsCard.schedule.to} ${getDate(
        endDate
      )} ${getYear(endDate)}`
    }

    if (holidayDates.value.length > 0) {
      const holidaysDates = holidayDates.value.join(', ').replace(/, ([^,]*)$/, ` ${copies.ItemsCard.holidays.and} $1`)

      holidayText.value = `${copies.ItemsCard.holidays.holiday} ${holidaysDates}`
    }

    const subtitle = `${props.courseName} ${copies.ItemsCard.subtitle} ${props.commission} `

    onMounted(setPeriod)

    return { Calendar, Clock, CloseCircle, subtitle, schedule, copies: copies.ItemsCard, holidayText }
  }
})
