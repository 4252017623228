import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fa229a1a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p2 p-mb-2 text-light-gray" }
const _hoisted_2 = { class: "p2 p-mb-4 bold" }
const _hoisted_3 = {
  key: 1,
  class: "p-d-flex p-ai-center"
}
const _hoisted_4 = { class: "p-pl-3" }
const _hoisted_5 = { class: "p1 bold p-mb-1" }
const _hoisted_6 = { class: "text-light-gray p2" }
const _hoisted_7 = {
  key: 2,
  class: "p-pt-4 p2"
}
const _hoisted_8 = { class: "full-width p-mt-4" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "p-py-3" }
const _hoisted_11 = ["src", "alt"]
const _hoisted_12 = { class: "full-width p-px-4" }
const _hoisted_13 = { class: "p2 text-light-gray p-mb-1" }
const _hoisted_14 = { class: "p2 bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_progress_circle = _resolveComponent("progress-circle")!
  const _component_circle_badge = _resolveComponent("circle-badge")!
  const _component_card = _resolveComponent("card")!

  return (_openBlock(), _createBlock(_component_card, { class: "p2" }, {
    default: _withCtx(() => [
      (_ctx.proyectoFinal)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.copies.pointCard.finalProject), 1),
            _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.copies.pointCard.approveOf) + " " + _toDisplayString(_ctx.proyectoFinal.title), 1),
            _createVNode(_component_Button, {
              class: "full-width",
              onClick: _ctx.goToProyectoFinal,
              label: _ctx.copies.pointCard.ctaLabel
            }, null, 8, ["onClick", "label"])
          ], 64))
        : _createCommentVNode("", true),
      (!_ctx.proyectoFinal)
        ? (_openBlock(), _createElementBlock("header", _hoisted_3, [
            _createVNode(_component_progress_circle, {
              radius: 40,
              progress: _ctx.progress,
              color: _ctx.progressColor
            }, null, 8, ["progress", "color"]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.copies.pointCard.challengesProgress), 1),
              _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.corregidas) + " " + _toDisplayString(_ctx.copies.pointCard.progressWords[0]) + " " + _toDisplayString(_ctx.entregas) + " " + _toDisplayString(_ctx.copies.pointCard.progressWords[1]), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.deTutores)
        ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.copies.pointCard.correctionsFromTutors), 1))
        : _createCommentVNode("", true),
      _createElementVNode("table", _hoisted_8, [
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.corrections, (item) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: item.title
            }, [
              (_ctx.$props[item.type] || _ctx.$props[item.type] !== 0)
                ? (_openBlock(), _createElementBlock("tr", _hoisted_9, [
                    _createElementVNode("td", _hoisted_10, [
                      _createElementVNode("img", {
                        src: `/assets/icons/challenges/teacher/${item.icon}.svg`,
                        alt: item.title,
                        class: "corrections-icon"
                      }, null, 8, _hoisted_11)
                    ]),
                    _createElementVNode("td", _hoisted_12, [
                      _createElementVNode("p", _hoisted_13, _toDisplayString(item.title), 1),
                      _createElementVNode("p", _hoisted_14, _toDisplayString(item.subtitle), 1)
                    ]),
                    _createElementVNode("td", null, [
                      _createVNode(_component_circle_badge, {
                        value: _ctx.$props[item.type],
                        color: item.color
                      }, null, 8, ["value", "color"])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ])
      ])
    ]),
    _: 1
  }))
}