import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5cbba2dd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "datecard__date" }
const _hoisted_2 = { class: "p1" }
const _hoisted_3 = { class: "t2" }
const _hoisted_4 = { class: "p3" }
const _hoisted_5 = {
  class: "datecard__content",
  role: "contentinfo"
}
const _hoisted_6 = { class: "p2 datecard__subtitle" }
const _hoisted_7 = { class: "p1 datecard__title" }
const _hoisted_8 = {
  role: "group",
  class: "datecard__buttons p-d-flex p-ai-center"
}
const _hoisted_9 = { class: "p-button-label" }
const _hoisted_10 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_card = _resolveComponent("card")!

  return (_openBlock(), _createBlock(_component_card, { class: "datecard" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.shortDay), 1),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.dt.toFormat('d')), 1),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.formattedTime), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.copies.title), 1),
        _createElementVNode("h5", _hoisted_7, _toDisplayString(_ctx.nextClass.stage.name), 1),
        _createElementVNode("div", _hoisted_8, [
          (_ctx.role === 2)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: '/classes',
                class: "p-button secondary full-width",
                style: {"min-width":"max-content"}
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.copies.goToClasses), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.nextClass.presentationLink && _ctx.role !== 2)
            ? (_openBlock(), _createElementBlock("a", {
                key: 1,
                href: _ctx.nextClass.presentationLink,
                target: "_blank",
                class: "p-button secondary full-width"
              }, _toDisplayString(_ctx.copies.goToPresentation), 9, _hoisted_10))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }))
}