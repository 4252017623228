import copies from '@/locales/profile/es.json'
import { roles } from '@/locales/attendance/es.json'
import { PERSONAL_GOAL, PersonRoles } from '@/models/enums'

/**
 * Parses a personal goal coming from the API to the proper way to display it
 * @param goal `required` String that represents the personal goal of the student
 * @returns parsed string to display in Profile Card component
 */
export const parseGoal: (personalGoal: string) => string | undefined = (goal) => {
  const copy = {
    [PERSONAL_GOAL.OTHER]: copies.personalGoalTypes.others,
    [PERSONAL_GOAL.FREELANCE]: copies.personalGoalTypes.freelance,
    [PERSONAL_GOAL.ENTREPRENEUR]: copies.personalGoalTypes.entrepreneur,
    [PERSONAL_GOAL.PROFESSIONAL]: copies.personalGoalTypes.professional
  }[goal]

  return copy
}

/**
 * Parses a Role showing the proper way and language to display it
 * @param goal `required` String that represents the role of the "instructor"
 * @returns parsed string to display
 */
export const parseRole: (role: 'coordinator' | 'tutor' | 'teacher') => string = (role) => {
  const copy = {
    [PersonRoles.Coordinator]: roles.coordinator,
    [PersonRoles.Tutor]: roles.tutor,
    [PersonRoles.Teacher]: roles.teacher
  }[role]

  return copy
}

/**
 * Capitalizes the first letter of a string
 * @param str
 * @returns capitalized string
 */
export const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)
