
import { defineComponent, ref, onMounted } from 'vue'

// Services
import { getPayoutService, getInvoiceService } from '@/services/newPlatform/profile.services'

// Components
import BillingHistoryItem from './BillingHistoryItem.vue'
import Icon from '@/components/aero/icon/Icon.vue'
import { Edit } from '@/components/aero/icon/templates'
import Invoice from '@/components/aero/surfaces/profile/BillingHistory/Invoice.vue'
import PlaceholderState from '@/components/aero/surfaces/challenges/PlaceholderState.vue'
import SkeletonInvoiceCard from '@/components/aero/skeleton/SkeletonInvoiceCard.vue'
import CustomTransition from '@/components/aero/misc/CustomTransition.vue'

// Copies
import { billingHistory } from '@/locales/profile/es.json'

// Models
import { PayoutInvoice } from '@/models/profile/profile'
import { Invoice as TypeInvoice } from '@/models/profile/billing'

export default defineComponent({
  components: { BillingHistoryItem, Icon, Edit, Invoice, PlaceholderState, SkeletonInvoiceCard, CustomTransition },
  props: {
    userId: { type: String, required: true },
    editBillingInfo: { type: Function, required: true },
    onShowUploadInvoice: { type: Function, required: true }
  },
  setup(props) {
    const billingList = ref<PayoutInvoice[]>([])

    const isLoding = ref<boolean>(true)

    const isOpen = ref<boolean>(false)
    const invoiceId = ref<string>('')
    const paymentId = ref<string>('')
    const invoiceDetail = ref<TypeInvoice>({
      reviewsAmount: '',
      complementariesAmount: '',
      baseAmount: '',
      additionalAmount: '',
      amount: '',
      kind: '',
      amountOfSchedules: '',
      amountOfComplementaries: '',
      amountOfPreIntegratorReviews: ''
    })

    const handleShowFormToEditBillingInfo = () => {
      props.editBillingInfo(true)
    }

    const handleUploadForm = (data: TypeInvoice) => {
      props.onShowUploadInvoice({ data: data, invoiceId: invoiceId.value, paymentId: paymentId.value })
    }

    const handleOpenInvoice = async (value: boolean, _paymentId: string, _invoiceId: string) => {
      isOpen.value = value
      invoiceId.value = _invoiceId
      paymentId.value = _paymentId

      // set loading state
      if (!isOpen.value) return

      try {
        const response = await getInvoiceService(props.userId, _paymentId, _invoiceId)
        invoiceDetail.value = { ...response }
      } catch (e) {
        // set error
      }
    }

    onMounted(async () => {
      const response = await getPayoutService(props.userId)
      billingList.value = response.flat()
      isLoding.value = false
    })

    return {
      billingList,
      isOpen,
      invoiceId,
      invoiceDetail,
      handleOpenInvoice,
      copies: billingHistory,
      isLoding,
      handleShowFormToEditBillingInfo,
      handleUploadForm
    }
  }
})
