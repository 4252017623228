
import ChallengeHeader from '@/views/peerReview/components/upload/ChallengeHeader.vue'
import { defineComponent, computed, ref, PropType } from 'vue'
import { usePersonStore } from '@/store/modules/person.store'
import { formatDate, formatTime, addDays } from '@/utils/dates'
import { useI18n } from 'vue-i18n'
import { HomeworkPlan, ICorrectionDataDto, ICriteria } from '@/models/peer-review/homeworkPlan'
import { DateTime } from 'luxon'
import { HomeworkDeliveryStatus } from '@/utils/constants'
import UrlButton from '@/components/commons/UrlButton.vue'
import UploadSection from './upload/UploadSection.vue'
import IconBase from '@/components/commons/IconBase.vue'
import HabilitedCorrection from '@/components/commons/icon-templates/HabilitedCorrection.vue'
import CircleCheck from '@/components/commons/icon-templates/CircleCheck.vue'
import ClockCounter from '@/components/commons/icon-templates/ClockCounter.vue'
import Pencil from '@/components/commons/icon-templates/Pencil.vue'
import DownloadButton from '@/components/atomic/DownloadButton.vue'
import RedeliverCircle from '@/components/commons/icon-templates/RedeliverCircle.vue'
import CancelCircle from '@/components/commons/icon-templates/CancelCircle.vue'
import InfoCircle from '@/components/commons/icon-templates/InfoCircle.vue'
import ModalRubricasChallenge from '@/views/peerReview/components/ModalRubricasChallenge.vue'
import { Event } from '@/models/peer-review/challengeHistory'

export default defineComponent({
  emits: ['editChallenge', 'send', 'enable'],
  components: { DownloadButton, IconBase, UploadSection, ChallengeHeader, UrlButton, ModalRubricasChallenge },
  props: {
    challenge: { type: Object as PropType<HomeworkPlan>, default: null },
    challengeHistory: {type: Object, default: null },
    sending: Boolean,
    showCriteria: Boolean,
    setShowCriteria: Function,
    student: { type: Object, default: null },
    studentData: { type: Object, default: null },
    showUploadSection: {type: Boolean, default: false}
  },
  setup(props, { emit }) {
    const { t } = useI18n()
    const personStore = usePersonStore()
    const evaluationGrade = ref<number>(props.challengeHistory.grade)

    const challengeCriteria = ref<ICriteria[]>(props.challenge.evaluationCriteria)
    const challengeType = ref(props.challenge.type)
    const showModal = ref<boolean>(false)
    const criteriaIndex = ref<number>(0)

    const initialLetter = (name: string) => {
      return (name?.trim() || '').charAt(0)
    }

    const getHistoryDetail = (status: string, createdAt: string) => {
      switch (status) {
        case 'sent':
          return t('homework.challenge.history.sent.details')
        case 'delivered':
          return t('homework.challenge.history.delivered.details', {
            dateNow: formatDate(createdAt),
            expirationDate: challengeType.value === 'final' ? addDays(createdAt, 10) : addDays(createdAt, 3)
          })
        case 'approved':
          if (challengeType.value === 'final' && evaluationGrade.value >= 7) {
            return t('homework.challenge.history.approved.final.details', {
            grade: evaluationGrade.value
          })}
          else if(challengeType.value === 'preFinal' && evaluationGrade.value === 7){
            return t('homework.challenge.history.approved.regular.details')
          }else if(challengeType.value === 'preFinal' && evaluationGrade.value === 10){
            return t('homework.challenge.history.approved.optimum.details')
          } 
          return t('homework.challenge.history.approved.details')
        case 'reviewed':
          return t('homework.challenge.history.complementary.reviewed.details')
        case 'disapproved':
          if (challengeType.value === 'final' && evaluationGrade.value < 7) {
            return t('homework.challenge.history.disapproved.final.details')
            }else if(challengeType.value === 'preFinal' && evaluationGrade.value === 4){
            return t('homework.challenge.history.approved.low.details')
          }
          return null
        case 'claimedForwarding':
            return t('homework.challenge.history.expiration.time.forwarding', {
              reSendDateExpiration: addDays(props.challenge.expirationDate, 7),
              reSendTimeExpiration: formatTime(props.challenge.expirationDate)
            })
        default:
          return ''
      }
    }

    const getIcon = (status: string) => {
      switch (status) {
        case 'sent':
          return ClockCounter
        case 'delivered':
          return HabilitedCorrection
        case 'claimedForwarding':
          return RedeliverCircle
        case 'forwarded':
          return ClockCounter
        case 'approved':
        case 'reviewed':
          return CircleCheck
        case 'disapproved':
          if (challengeType.value === 'final' && evaluationGrade.value < 7) return InfoCircle
          return CancelCircle
        case 'expired':
          return CancelCircle
        default:
          return ClockCounter
      }
    }

    const getIconColor = (status: string) => {
      switch (status) {
        case 'sent':
          return '#BEBEBE'
        case 'delivered':
          return '#83AEFB'
        case 'claimedForwarding':
          return '#83AEFB'
        case 'forwarded':
          return '#83AEFB'
        case 'approved':
          return '#82DB91'
        case 'reviewed':
          return '#83AEFB'
        case 'disapproved':
          return '#FB7A50'
        case 'expired':
          return '#FB7A50'
        default:
          return '#BEBEBE'
      }
    }

    const openModal = (item:number) => {
      criteriaIndex.value = item
      showModal.value = true
    }

    const closeModal = () => {
      showModal.value = false
    }

    const canEdit = computed(() => {
      const events = [...props.challengeHistory.events]
      if (events[0].status === 'forwarded') {
        return DateTime.now() <= DateTime.fromISO(props.challengeHistory.updatableUntil)
      }
      return (
        DateTime.now() <= DateTime.fromISO(props.challengeHistory.updatableUntil) &&
        events[events.length - 1].status === 'delivered' &&
        events[0].status === 'delivered'
      )
    })

    const editChallenge = () => emit('editChallenge', props.challengeHistory.id)

    const send = async (body: ICorrectionDataDto) => {
      emit('send', body)
    }

    const getRubricas = (event:Event) => {
      const evaluations = event.evaluation
      const defaultCriterias = challengeCriteria.value

      if(evaluations) {
        const newEvaluation = Object.keys(evaluations as Record<string, unknown>).map((criteriaId) => {
        const matchCriteria = defaultCriterias.find((criteria) => criteria.id === criteriaId)
        const scoreCorrection = evaluations[criteriaId as unknown as number]
        const titleCriteria = matchCriteria?.title
        const evaluationCriterias = matchCriteria?.evaluationCriteria
        const evaluationCriteria = evaluationCriterias?.find((grade) => grade.score ===(scoreCorrection as unknown as string))

        return {id: criteriaId, title: titleCriteria, evaluationCriteria}
      })

        return {...event, evaluation: newEvaluation}
      }
      return null
    }

    const history = props.challengeHistory.events.map((ev:Event) => {
        if(!ev.evaluation) return ev
        return getRubricas(ev)
    })

    history.push({
      status: 'available',
      statusText: t('homework.challenge.history.available.statusText'),
      date: formatDate(props.challenge.classDateEnd),
      time: formatTime(props.challenge.classDateEnd)
    })

    const forwarding = () => {
      emit('enable')
    }

    const isExpired = computed(() => !(DateTime.fromISO(props.challenge.expirationDate).plus({days: 7}) < DateTime.now()))

    return {
      t,
      history,
      personStore,
      initialLetter,
      getHistoryDetail,
      getIcon,
      getIconColor,
      send,
      HomeworkDeliveryStatus,
      editChallenge,
      canEdit,
      Pencil,
      challengeType,
      openModal,
      showModal,
      closeModal,
      evaluationGrade,
      forwarding,
      isExpired,
      criteriaIndex
    }
  }
})
