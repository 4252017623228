<template>
  <card class="p-d-flex p-ai-center">
    <div class="p-d-flex p-flex-column p-ai-center">
      <skeleton-text :length="3" class="p-mb-1" />
      <skeleton-text :length="2" class="p-mb-1" />
      <skeleton-text :length="3" class="p-mb-1" />
    </div>
    <div class="p-ml-5" style="width: 100%">
      <skeleton-text :length="7" class="p-mb-1" />
      <skeleton-text :maxWords="3" class="p-mb-1" />
      <div class="p-d-flex">
        <skeleton-input v-for="i in Math.ceil(Math.random() + 0.5)" :key="i" class="p-mr-2" />
      </div>
    </div>
  </card>
</template>

<script>
import Card from '../surfaces/Card.vue'
import SkeletonText from './SkeletonText.vue'
import SkeletonInput from './SkeletonInput.vue'
export default {
  components: { Card, SkeletonText, SkeletonInput }
}
</script>
