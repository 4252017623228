
import { defineComponent, ref, onUpdated, onBeforeUpdate } from 'vue'
import IconBase from '@/components/commons/IconBase.vue'
import Warning from '@/components/commons/icon-templates/WarningCircle.vue'
import Cancel from '@/components/commons/icon-templates/CancelCircle.vue'
import Approved from '@/components/commons/icon-templates/ApprovedCircle.vue'
import HabilitedCorrection from '@/components/commons/icon-templates/HabilitedCorrection.vue'
import ClaimedRewarding from '@/components/commons/icon-templates/ClaimedRewarding.vue'
import { HomeworkDeliveryStatus } from '@/utils/constants'
import { useI18n } from 'vue-i18n'
import { DateTime } from 'luxon'
import CustomBreadcrumb from '@/components/commons/Breadcrumb.vue'
import { ITutorChallengeStudents } from '@/models/peer-review/challenge'
import { getDateDiff } from '@/utils/dates'

const __default__ = defineComponent({
  emits: ['select'],
  components: {
    IconBase,
    CustomBreadcrumb
  },
  props: {
    students: {
      type: Array as () => ITutorChallengeStudents[],
      default: () => []
    },
    expirationDate: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: true
    },
    nameClass: {
      type: String,
      default: ''
    },
    orderClass: {
      type: Number,
      default: 0
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n()
    const processedStudents = ref<Array<ITutorChallengeStudents & { isOverflowing: boolean }>>()
    const deliveredStatus = ['forwarded', 'delivered']
    const selectedStudent = ref<ITutorChallengeStudents | null>(null)
    const home = ref({ label: t('homework.challenge.breadcrumb.challenges') })
    const items = ref<Array<unknown>>([])
    const statusColors = ref({
      delivered: '#EA90FF',
      outdated: '#FF896F',
      sent: '#d2a7f1',
      claimedForwarding: '#83AEFB',
      forwarded: '#EA90FF',
      expired: '#FB7A50',
      approved: '#82db91',
      disapproved: '#FF896F',
      reviewed: '#83AEFB',
      null: '#83AEFB'
    })

    const isOutdated = (status: string, date?: string) => {
      let outdated = false
      if (date && deliveredStatus.includes(status)) {
        const reviewUntil = DateTime.fromISO(date, { zone: 'utc' })
        if (reviewUntil.diff(DateTime.now()).milliseconds < 0) {
          outdated = true
        }
      }
      return outdated
    }

    const getState = (status: string, date?: string, updatableUntil?: string) => {
      
      let statusLabel: string
      let viewBox = '0 0 16 16'
      if (!status && props.expirationDate && DateTime.now() > DateTime.fromISO(props.expirationDate)) {
        status = HomeworkDeliveryStatus.EXPIRED
      }

      if (status && status !== HomeworkDeliveryStatus.SENT) {
        (status === HomeworkDeliveryStatus.DELIVERED || status === HomeworkDeliveryStatus.FORWARDED) && date 
        ? statusLabel = getDateDiff(date, t(`homework.challenge.peerReview.correction.tutor.studentsList.${status}`), updatableUntil)
        : statusLabel = t(`homework.challenge.peerReview.correction.tutor.studentsList.${status}`)
      } else {
        statusLabel = t('homework.challenge.peerReview.correction.tutor.studentsList.pending')
      }

      if (status === HomeworkDeliveryStatus.CLAIMED_FOR_FORWARDING) {
        viewBox = '0 0 24 24'
      }

      const outdated = isOutdated(status, date)

      if (outdated) {
        statusLabel = t('homework.challenge.peerReview.correction.tutor.studentsList.outOfDate')
      }

      switch (status) {
        case HomeworkDeliveryStatus.DELIVERED:
          return {
            icon: Warning,
            viewBox: viewBox,
            label: statusLabel,
            color: outdated ? statusColors.value.outdated : statusColors.value.delivered,
            class: `status-${status}`
          }
        case HomeworkDeliveryStatus.SENT:
          return { 
            icon: Warning,
            viewBox: viewBox,
            label: statusLabel,
            color: statusColors.value.sent,
            class: `status-${status}`
          }
        case HomeworkDeliveryStatus.CLAIMED_FOR_FORWARDING:
          return {
            icon: ClaimedRewarding,
            viewBox: viewBox,
            label: statusLabel,
            color: statusColors.value.claimedForwarding,
            class: `status-${status}`
          }
        case HomeworkDeliveryStatus.FORWARDED:
          return {
            icon: Warning,
            viewBox: viewBox,
            label: statusLabel,
            color: outdated ? statusColors.value.outdated : statusColors.value.forwarded,
            class: `status-${status}`
          }
        case HomeworkDeliveryStatus.EXPIRED:
          return {
            icon: Cancel,
            viewBox: viewBox,
            label: statusLabel,
            color: statusColors.value.expired,
            class: `status-${status}`
          }
        case HomeworkDeliveryStatus.APPROVED:
          return {
            icon: Approved,
            viewBox: viewBox,
            label: statusLabel,
            color: statusColors.value.approved,
            class: `status-${status}`
          }
        case HomeworkDeliveryStatus.REVIEWED:
          return {
            icon: Approved,
            viewBox: viewBox,
            label: statusLabel,
            color: statusColors.value.reviewed,
            class: `status-${status}`
          }
        case HomeworkDeliveryStatus.DISAPPROVED:
          return {
            icon: Cancel,
            viewBox: viewBox,
            label: statusLabel,
            color: statusColors.value.disapproved,
            class: `status-${status}`
          }
        case null:
          return {
            icon: HabilitedCorrection,
            viewBox: viewBox,
            label: statusLabel,
            color: statusColors.value.null,
            class: 'status-null'
          }
        default:
          return ''
      }
    }

    const selectStudent = (e: Record<string, unknown>) => {
      emit('select', e.data)
    }

    onBeforeUpdate(() => {
      processedStudents.value = props.students.map((student: ITutorChallengeStudents) => ({
        ...student,
        isOverflowing: student.name?.length >= 22
      }))
    })

    onUpdated(() => {
      if (props.orderClass !== 0 && items.value.length === 0) {
        const label = props.orderClass < 10 ? ' 0' + props.orderClass : ' ' + props.orderClass
        items.value.push({ label: t('homework.challenge.peerReview.correction.tutor.studentsList.class') + label })
      }
    })

    return {
      t,
      selectStudent,
      getState,
      processedStudents,
      isOutdated,
      selectedStudent,
      home,
      items,
      statusColors
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "f96e0ca0": (_ctx.statusColors.delivered),
  "9619aa28": (_ctx.statusColors.sent),
  "0ec49b50": (_ctx.statusColors.forwarded),
  "961dbe4a": (_ctx.statusColors.null),
  "00681c64": (_ctx.statusColors.claimedForwarding),
  "dcc4c95e": (_ctx.statusColors.expired),
  "05a61f4b": (_ctx.statusColors.reviewed),
  "4a517b71": (_ctx.statusColors.disapproved),
  "5bdcf80b": (_ctx.statusColors.approved),
  "18b4493c": (_ctx.statusColors.outdated)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__