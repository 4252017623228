/* eslint-disable func-call-spacing */
import { EVENT_TOPICS } from '@/api/constantEvents'
import usersApi from '@/api/users.api'
import { Auth0Token, DevUserLogin, UserSessionState } from '../../models/user-session'
import axios from 'axios'

import { defineStore } from 'pinia' //

const missingApiToken = ''
const userSessionDefault = {
  // userId: process.env.MOCK_USER === '1' ? '61362c327421a50010cfcc94' : '', // TODO: remove. For development purpouse only. Student role
  // userId: '611285695216e50010de5aac', // TODO: remove. For development purpouse only. student role
  // userId: '60e355066c0ac900122fa78a', // TODO: remove. For development purpouse only. Teacher role
  // userId: '6130122f688c730010875088', // TODO: remove. For development purpouse only. Teacher role
  // userId: '611eb298d9f4080011924b63', // TODO: remove. For development purpouse only. Tutor role
  userId: process.env.MOCK_USER === '1' ? '5e4450882000260092cc18fb' : '', // TODO: remove. For development purpouse only. Tutor role

  // userId: '',
  token: process.env.MOCK_USER === '1' ? 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJodHRwczovL2NvZGVyaG91c2UuYXV0aDAuY29tLyIsInN1YiI6ImF1dGgwfDYwOTQyM2RiNTRkZWQxMDAwZjhmYTdjYyIsImF1ZCI6ImRLODR0QkdCQklYQVFHZ2NaRDFqUldLUGJqdDV2b0cxIiwiaWF0IjoxNjUyMzg3NjMyLCJleHAiOjE2NTQ5Nzk2MzJ9.uzvaSk8GX1AxtZvo415J-FQNF5MxxpCBAyqEs6r09Qo' : missingApiToken,
  auth: missingApiToken,
  loginError: '',
  darkMode: false,
  currentUser: {}
}

const CurrentUserKey = 'user-session'
const messageResolvers = new Map<string, (value: any) => void>() // Todo remove any

async function webMessage<T>(messageType: string): Promise<T> {
  window.addEventListener(
    'message',
    (event: MessageEvent) => {
      if (event && event.data && event.data.type) {
        const resolve = messageResolvers.get(event.data.type)
        if (!resolve) {
          // eslint-disable-next-line no-console
          console.warn(`Not exists event ${event.data.type}`)
        } else {
          resolve(event.data.payload)
        }
      }
    },
    false
  )

  return new Promise<T>((resolve) => messageResolvers.set(messageType, resolve))
}

function getStoredToken(): UserSessionState {
  return userSessionDefault
}

function updateAxios(idtoken: string, accesstoken: string) {
  if (idtoken && accesstoken) {
    axios.defaults.headers.common.idtoken = idtoken
    axios.defaults.headers.common.accesstoken = accesstoken
  } else {
    delete axios.defaults.headers.common.idtoken
    delete axios.defaults.headers.common.accesstoken
  }
}

//
export const useUserSessionStore = defineStore({
  // unique id of the store across your application
  id: 'user-session',
  state() {
    const userSession: UserSessionState = getStoredToken()
    updateAxios(userSession.token, userSession.auth)
    return userSession
  },
  getters: {
    isAuthenticated(): boolean {
      return !!this.token
    }
  },
  actions: {
    async login() {
      try {
        const userSession = await webMessage<UserSessionState>(EVENT_TOPICS.SEND_USER)

        this.token = userSession.token
        this.auth = userSession.auth
        this.userId = userSession.userId
        this.darkMode = Boolean(Number(userSession.darkMode))

        if (!this.token  || !this.auth || !this.userId) {
          throw new Error('No auth')
        }
        updateAxios(this.token, this.auth)
        // Remove token to store because security faild
        // localStorage.setItem(TokenKey, JSON.stringify(userSession))
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(`login failed with: ${err}`)
        this.loginError = err
        this.token = missingApiToken
        this.auth = missingApiToken
      }
    },

    async developerLogin({ username, password }: DevUserLogin) {
      try {
        const auth0Token: Auth0Token = await usersApi.devLogin({ username, password })
        this.token = auth0Token.id_token
        updateAxios(this.token, '')
      } catch (error) { }
    },

    async logout() {
      await usersApi.logout()
      localStorage.removeItem(CurrentUserKey)
      this.token = missingApiToken
      this.auth = missingApiToken
      this.loginError = undefined
    },

    setUserSession(userId: string) {
      this.userId = userId
    }
  }
})
