
import { defineComponent, onMounted, PropType, reactive, Ref, watch, computed } from 'vue'

// PrimeVue components
import InputText from 'primevue/inputtext'
import Textarea from 'primevue/textarea'
import Button from 'primevue/button'
import Calendar from 'primevue/calendar'
import InputFile from '@/components/aero/inputs/InputFile.vue'
import SelectInput from '@/components/aero/inputs/SelectInput.vue'
import Checkbox from 'primevue/checkbox'

// Vuelidate
import useVuelidate, { ValidationArgs, ValidationRuleWithParams } from '@vuelidate/core'

// Components
import InputCheckbox from '../inputs/InputCheckbox.vue'
import InputRadioButton from '../inputs/InputRadioButton.vue'
import InputWrapper from '../inputs/InputWrapper.vue'
import InputError from '../inputs/InputError.vue'
import FileTag from '@/components/aero/dataDisplay/FileTag.vue'

// Models
import { Question, SingleQuestion } from '@/models/commons/form'

// Utils
import { isMultipleQuestion } from '@/utils/Form'
import { helpers } from '@vuelidate/validators'

// Copies
import copies from '@/locales/components/Form.json'

export default defineComponent({
  props: {
    questions: { type: Array as PropType<Question[]>, required: true },
    rules: { type: Object as PropType<Ref<ValidationArgs> | ValidationArgs> },
    allowedToEditForm: { type: Boolean, default: true },
    buttonLabel: { type: Object as PropType<{ cancel: string; submit: string }> },
    loadingSubmit: { type: Boolean, default: false },
    rulesMsj: { type: Object as PropType<{ requiredField: string; minLength: string; maxLength: string }> },
    classStyles: { type: Boolean, default: true },
    addDateRules: { type: Boolean, default: true },
    disableSubmit: { type: Boolean, default: false }
  },
  components: {
    Textarea,
    InputText,
    InputRadioButton,
    InputCheckbox,
    Checkbox,
    InputWrapper,
    Button,
    Calendar,
    SelectInput,
    InputError,
    InputFile,
    FileTag
  },
  emits: ['onSubmit', 'onCancel', 'onChange', 'getValidator', 'onDeleteFile'],
  setup(props, { emit }) {
    const values = reactive<{ [name: string]: unknown | { [name: string]: unknown } }>(
      props.questions?.reduce((acc, question) => {
        if (isMultipleQuestion(question)) {
          return {
            ...acc,
            [question.name]: question.manyQuestions.reduce((acc, { name, type, defaultValue, checked }) => {
              return {
                ...acc,
                [name]: defaultValue || (type === 'checkbox' ? [] : type === 'checkbox-binary' ? checked : '')
              }
            }, {})
          }
        }

        return {
          ...acc,
          [question.name]:
            (question as SingleQuestion).defaultValue ||
            ((question as SingleQuestion).type === 'checkbox'
              ? []
              : (question as SingleQuestion).type === 'checkbox-binary'
              ? (question as SingleQuestion).checked
              : '')
        }
      }, {}) || {}
    )

    const checkMaxDate = (value: string, max: Date) => {
      const valueParts = value.split('-')
      const dateValue = new Date(`${valueParts[1]}/${valueParts[0]}/${valueParts[2]}`)

      return dateValue <= max
    }

    const checkMinDate = (value: string, min: Date) => {
      const valueParts = value.split('-')
      const dateValue = new Date(`${valueParts[1]}/${valueParts[0]}/${valueParts[2]}`)

      return dateValue >= min
    }

    const getMaxValidation = (max: Date) =>
      helpers.withMessage(
        `${copies.validations.date.max} ${max.toISOString().split('T')[0].split('-').reverse().join('/')}`,
        (value: string) => checkMaxDate(value, max)
      )

    const getMinValidation = (min: Date) =>
      helpers.withMessage(
        `${copies.validations.date.min} ${min.toISOString().split('T')[0].split('-').reverse().join('/')}`,
        (value: string) => checkMinDate(value, min)
      )

    // Work only with format dd-mm-yyyy and manualInput=false in Calendar component
    // Vuelidate is not so compatible with PrimeVue, so check correct work
    // Recomendation: leave manualInput=false to prevent errors
    /** Method to add min and max date manual input validations */
    const getRulesWithDateValidations = computed((): ValidationArgs => {
      const toAddValidations: { questionName: string; subQuestionName?: string; validations: { min?: Date; max?: Date } }[] =
        props.questions.reduce((acc, question) => {
          if (isMultipleQuestion(question)) {
            question.manyQuestions.forEach((subQuestion) => {
              const validationsToAdd: { min?: Date; max?: Date } = {}
              if (subQuestion.min) {
                validationsToAdd.min = (subQuestion as SingleQuestion).min
              }
              if (subQuestion.max) {
                validationsToAdd.max = (subQuestion as SingleQuestion).max
              }
              acc.push({ questionName: question.name, subQuestionName: subQuestion.name, validations: validationsToAdd })
            })
          } else {
            const validationsToAdd: { min?: Date; max?: Date } = {}
            if ((question as SingleQuestion).min) {
              validationsToAdd.min = (question as SingleQuestion).min
            }
            if ((question as SingleQuestion).max) {
              validationsToAdd.max = (question as SingleQuestion).max
            }
            acc.push({ questionName: question.name, validations: validationsToAdd })
          }
          return acc
        }, [] as { questionName: string; subQuestionName?: string; validations: { min?: Date; max?: Date } }[])
      const newRules: ValidationArgs<any> = { ...props.rules }
      toAddValidations.forEach(({ questionName, subQuestionName, validations }) => {
        if (subQuestionName) {
          (newRules[questionName] as { [x: string]: ValidationRuleWithParams<any, unknown> })[subQuestionName] = {
            ...(newRules[questionName] as { [x: string]: ValidationRuleWithParams<any, unknown> })[subQuestionName],
            ...(validations.min && { minDate: getMinValidation(validations.min) }),
            ...(validations.max && { maxDate: getMaxValidation(validations.max) })
          }
        } else {
          newRules[questionName] = {
            ...(newRules[questionName] as { [x: string]: ValidationRuleWithParams<any, unknown> }),
            ...(validations.min && { minDate: getMinValidation(validations.min) }),
            ...(validations.max && { maxDate: getMaxValidation(validations.max) })
          }
        }
      })

      return { ...newRules }
    })

    const validations = useVuelidate(props.addDateRules ? getRulesWithDateValidations : props.rules || {}, values)
    /** Method to manage inputs that are not managed with v-model and questions with manyQuestions */
    const handleChange = (name: { questionName: string; subQuestionName: string } | string, value: string | string[]) => {
      if (typeof name === 'object') {
        // TODO: check if this works. To try it, some question should has a subQuestion with type checkbox or radio
        ;(values[name.questionName] as { [subQuestionName: string]: string | string[] })[name.subQuestionName] = value
      } else {
        values[name] = value
      }
    }

    /** Method to make validations and emit onSubmit if success */
    const handleSubmit = async () => {
      const success = await validations.value.$validate()
      if (success) {
        emit('onSubmit', values)
      }
    }

    /** Method to handle click on cancel button */
    const handleCancel = () => {
      emit('onCancel')
    }

    const handleDeleteFile = () => {
      emit('onDeleteFile')
    }

    // Watchers
    watch(values, () => {
      emit('onChange', values)
    })

    onMounted(() => {
      emit('getValidator', validations.value.$validate)
    })

    const customEvent = 'invoiceType'

    return { values, handleChange, handleSubmit, handleCancel, validations, customEvent, handleDeleteFile }
  }
})
