import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_card_banner = _resolveComponent("card-banner")!

  return (_openBlock(), _createBlock(_component_card_banner, {
    title: _ctx.t('home.banner.discord.title'),
    message: _ctx.t('home.banner.discord.message'),
    "padding-sm": "",
    link: _ctx.t('home.banner.discord.link'),
    "button-text": _ctx.t('home.banner.discord.button')
  }, null, 8, ["title", "message", "link", "button-text"]))
}