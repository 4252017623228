import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ae464fbc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "footer" }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("small", null, [
      _createTextVNode(_toDisplayString(_ctx.t('faqs.leyend')) + " ", 1),
      _createElementVNode("a", {
        href: _ctx.link,
        target: "_blank"
      }, _toDisplayString(_ctx.t('faqs.leyend.btn')), 9, _hoisted_2)
    ])
  ]))
}