import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d739574"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "text-white p2"
}
const _hoisted_2 = {
  key: 1,
  class: "text-white p2"
}
const _hoisted_3 = {
  key: 1,
  class: "p-d-flex p-ai-center p2 tag"
}
const _hoisted_4 = { class: "text-blue p2" }
const _hoisted_5 = { class: "text-white p2" }
const _hoisted_6 = {
  key: 2,
  class: "p-d-flex p-ai-center p2 tag"
}
const _hoisted_7 = { class: "text-purple p2" }
const _hoisted_8 = { class: "text-white p2" }
const _hoisted_9 = {
  key: 3,
  class: "p-d-flex p-ai-center p2 tag"
}
const _hoisted_10 = { class: "text-purple p2" }
const _hoisted_11 = { class: "text-white p2" }
const _hoisted_12 = {
  key: 4,
  class: "p-d-flex p-ai-center p2 tag"
}
const _hoisted_13 = { class: "text-blue p2" }
const _hoisted_14 = { class: "text-white p2" }
const _hoisted_15 = {
  key: 5,
  class: "p-d-flex p-ai-center p2 tag"
}
const _hoisted_16 = { class: "text-apple-green p2" }
const _hoisted_17 = { class: "text-white p2" }
const _hoisted_18 = {
  key: 6,
  class: "p-d-flex p-ai-center p2 tag"
}
const _hoisted_19 = { class: "text-error p2" }
const _hoisted_20 = { class: "text-white p2" }
const _hoisted_21 = {
  key: 7,
  class: "p-d-flex p-ai-center p2 tag"
}
const _hoisted_22 = { class: "text-white p2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_point = _resolveComponent("point")!
  const _component_icon = _resolveComponent("icon")!
  const _component_check_fill = _resolveComponent("check-fill")!
  const _component_flag_fill = _resolveComponent("flag-fill")!
  const _component_alert_circle = _resolveComponent("alert-circle")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.step.key === _ctx.statusKey.open || _ctx.step.key === _ctx.statusKey.disabled || _ctx.step.key === _ctx.statusKey.unlocked)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["p-d-flex p-ai-center p2 tag", _ctx.step.key === _ctx.statusKey.open || _ctx.step.key === _ctx.statusKey.disabled ? 'first-step p-mt-0' : ''])
        }, [
          _createVNode(_component_icon, {
            fillColor: "var(--light-gray)",
            strokeColor: "transparent"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_point)
            ]),
            _: 1
          }),
          (_ctx.step.key === _ctx.statusKey.disabled || _ctx.step.key === _ctx.statusKey.open)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.step.key === _ctx.statusKey.disabled ? _ctx.copies.disabled.text : _ctx.copies.open.text) + " " + _toDisplayString(_ctx.setDateTime), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.copies.unlocked.text), 1))
        ], 2))
      : ((_ctx.role === 2 && _ctx.step.key === _ctx.statusKey.delivered) || (_ctx.role === 2 && _ctx.step.key === _ctx.statusKey.deliveredAgain))
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_icon, {
              fillColor: "var(--blue)",
              strokeColor: "transparent"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_check_fill)
              ]),
              _: 1
            }),
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.copies.student.delivered.tag), 1),
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.copies.student.delivered.text), 1)
          ]))
        : ((_ctx.role === 3 && _ctx.step.key === _ctx.statusKey.delivered) || (_ctx.role === 3 && _ctx.step.key === _ctx.statusKey.deliveredAgain))
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_icon, {
                fillColor: "var(--purple)",
                strokeColor: "transparent"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_flag_fill)
                ]),
                _: 1
              }),
              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.isTutor ? _ctx.copies.tutor.pending.tag : _ctx.copies.teacher.pending.tag), 1),
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.isTutor ? _ctx.copies.tutor.pending.text : '') + " " + _toDisplayString(!_ctx.isTutor && _ctx.isIntegratorTp ? `${_ctx.copies.teacher.pending.text} ${_ctx.setDateTime}` : ''), 1)
            ]))
          : ((_ctx.role === 2 && _ctx.step.key === _ctx.statusKey.disapproved) || (_ctx.role === 2 && _ctx.step.key === _ctx.statusKey.deliverAgain))
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createVNode(_component_icon, {
                  fillColor: "var(--purple)",
                  strokeColor: "transparent"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_flag_fill)
                  ]),
                  _: 1
                }),
                _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.copies.student.disapproved.tag) + " " + _toDisplayString(_ctx.setDateTime), 1),
                _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.copies.student.disapproved.text), 1)
              ]))
            : ((_ctx.role === 3 && _ctx.step.key === _ctx.statusKey.disapproved) || (_ctx.role === 3 && _ctx.step.key === _ctx.statusKey.deliverAgain))
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createVNode(_component_icon, {
                    fillColor: "var(--blue)",
                    strokeColor: "transparent"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_check_fill)
                    ]),
                    _: 1
                  }),
                  _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.copies.tutor.disapproved.tag), 1),
                  _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.copies.tutor.disapproved.text) + " " + _toDisplayString(_ctx.setDateTime), 1)
                ]))
              : (_ctx.step.key === _ctx.statusKey.approved)
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    _createVNode(_component_icon, {
                      fillColor: "var(--apple-green)",
                      strokeColor: "transparent"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_check_fill)
                      ]),
                      _: 1
                    }),
                    _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.setApprovedTag), 1),
                    _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.role === 2 ? _ctx.copies.student.approved.text : ''), 1)
                  ]))
                : (_ctx.role === 3 && _ctx.isTutor && _ctx.step.key === _ctx.statusKey.reviewExpected && _ctx.step.date <= Date.now())
                  ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                      _createVNode(_component_icon, {
                        fillColor: "var(--error)",
                        strokeColor: "var(--background)"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_alert_circle)
                        ]),
                        _: 1
                      }),
                      _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.copies.tutor.expired.tag), 1),
                      _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.copies.tutor.expired.text), 1)
                    ]))
                  : (_ctx.step.key === _ctx.statusKey.locked)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                        _createVNode(_component_icon, {
                          fillColor: _ctx.role === 2 ? 'var(--error)' : 'var(--light-gray)',
                          strokeColor: "var(--background)"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_alert_circle)
                          ]),
                          _: 1
                        }, 8, ["fillColor"]),
                        _createElementVNode("p", {
                          class: _normalizeClass(["p2", _ctx.role === 2 ? 'text-error' : 'text-light-gray'])
                        }, _toDisplayString(_ctx.role === 2 ? _ctx.copies.student.locked.tag : _ctx.copies.tutor.locked.tag), 3),
                        _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.role === 2 ? _ctx.copies.student.locked.text : _ctx.copies.tutor.locked.text), 1)
                      ]))
                    : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 64))
}