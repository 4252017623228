import { UserFlags } from '../models/profile/profile'
export interface IUser {
  id: string
  role: number
  roles: string[]
  level: string
  email: string
  avatar: string
  firstName: string
  lastName: string
  fullName: string
  isTutor: boolean
  hasBeca: boolean
  dni: string
  country: string
  cityV2?: string
  flags?: UserFlags
  studyLevel?: string
  studiesInstitute?: string
  jobName?: string
  jobResponsabilities?: string
  isNotFromArg: boolean
  phone: string
  getStreamToken: string
  subscription: string[]
  preferencesFlag: boolean,
  coderPlus: boolean
}

export class User {
  id: string
  role: number
  roles: string[]
  level: string
  email: string
  avatar: string
  firstName: string
  lastName: string
  fullName: string
  isTutor: boolean
  hasBeca: boolean
  dni: string
  country: string
  cityV2?: string
  flags?: UserFlags
  studyLevel?: string
  studiesInstitute?: string
  jobName?: string
  jobResponsabilities?: string
  isNotFromArg: boolean
  phone: string
  getStreamToken: string
  subscription: string[]
  preferencesFlag: boolean
  coderPlus: boolean

  constructor(from: IUser) {
    this.id = from.id
    this.role = from.role
    this.roles = from.roles
    this.level = from.level
    this.email = from.email
    this.avatar = from.avatar
    this.firstName = from.firstName
    this.lastName = from.lastName
    this.fullName = from.fullName
    this.isTutor = from.isTutor
    this.hasBeca = from.hasBeca
    this.dni = from.dni
    this.country = from.country
    this.cityV2 = from.cityV2
    this.flags = from.flags
    this.studyLevel = from.studyLevel
    this.studiesInstitute = from.studiesInstitute
    this.jobName = from.jobName
    this.jobResponsabilities = from.jobResponsabilities
    this.isNotFromArg = from.isNotFromArg
    this.phone = from.phone
    this.getStreamToken = from.getStreamToken
    this.subscription = from.subscription
    this.preferencesFlag = from.preferencesFlag
    this.coderPlus = from.coderPlus
  }
}
