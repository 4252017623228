import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a1623f6e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p2" }
const _hoisted_2 = { class: "notifications-panel-time text-light-gray p-text-right" }
const _hoisted_3 = { class: "p2 p-text-left" }
const _hoisted_4 = { class: "notifications-panel-time p-text-right text-light-gray" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notification_icon = _resolveComponent("notification-icon")!
  const _component_chevron_right = _resolveComponent("chevron-right")!
  const _component_icon = _resolveComponent("icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_check = _resolveComponent("check")!

  return (_ctx.category.path)
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        to: _ctx.category.path,
        onClick: _ctx.handleLinkClick,
        class: "notification"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_notification_icon, {
            class: "notification__icon",
            type: _ctx.category.icon
          }, null, 8, ["type"]),
          _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.message), 1),
          _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.at), 1),
          _createVNode(_component_icon, {
            class: "notifications-panel-icon",
            strokeColor: 'var(--white)',
            size: 18
          }, {
            default: _withCtx(() => [
              _createVNode(_component_chevron_right)
            ]),
            _: 1
          }, 8, ["strokeColor"])
        ]),
        _: 1
      }, 8, ["to", "onClick"]))
    : (_openBlock(), _createElementBlock("button", {
        key: 1,
        class: "notification",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleButtonClick && _ctx.handleButtonClick(...args)))
      }, [
        _createVNode(_component_notification_icon, {
          class: "notification__icon",
          type: _ctx.category.icon
        }, null, 8, ["type"]),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.message), 1),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.at), 1),
        _createVNode(_component_icon, {
          class: "notifications-panel-icon",
          strokeColor: 'var(--white)',
          size: 18
        }, {
          default: _withCtx(() => [
            _createVNode(_component_check)
          ]),
          _: 1
        }, 8, ["strokeColor"])
      ]))
}