
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    title: String,
    borderless: Boolean,
    shadowless: Boolean,
    backgroundColor: String,
    titlePaddingX: {
      type: String,
      deafult: 'p-px-6'
    }

  }
})
