
// Vendors
import { defineComponent, watch, ref, PropType, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { DateTime } from 'luxon'

// Components
import OverlayMenu from '@/components/aero/buttons/OverlayMenu.vue'
import ProfileCard from '@/components/aero/dataDisplay/ProfileCardSmall.vue'
import Icon from '@/components/aero/icon/Icon.vue'
import { Check, CloseCircle, IconCircle } from '@/components/aero/icon/templates'

// PrimeVue component
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Checkbox from 'primevue/checkbox'

// Models
import { UserAttendance, ColumnType, AttendanceClass, ISchedule } from '@/models/attendance/attendance'
import { TutorClassmate } from '@/models/comission/comission'

// Locales
import copies from '@/locales/attendance/es.json'

export default defineComponent({
  components: { ProfileCard, DataTable, Column, Icon, Check, Checkbox, OverlayMenu, CloseCircle, IconCircle },
  props: {
    isTutor: { type: Boolean, default: true },
    tutor: { type: (Object as PropType<TutorClassmate>) || Boolean, default: false },
    schedules: { type: Array as PropType<ISchedule[]>, default: () => [] },
    attendances: { type: Array as PropType<number[]>, default: () => [] },
    data: { type: Array as PropType<UserAttendance[]>, required: true },
    filterTitle: { type: String, default: 'student' },
    columns: { type: Array as PropType<ColumnType[]>, required: true }
  },
  emits: ['onChange'],
  setup(props, { emit }) {
    const router = useRouter()
    const totalAttendance = ref(0)
    const todayScheduleId = ref<string>('')

    const checked = ref<{ [key: string]: boolean }>({ a: false })

    const setMenuOptions = (userId: string) => {
      const items: { label: string; command: () => void }[] = [
        { label: copies.goToChat, command: () => router.push(`/chat/${userId}`) }
      ]

      if (props.isTutor) {
        items.push({ label: copies.goToChallenges, command: () => router.push(`/challenges/students/${userId}`) })
      }
      return items
    }

    const handleChange = (id: string) => {
      const updated = { [id]: checked.value[id] }
      emit('onChange', updated, todayScheduleId.value)
    }

    watch(checked, () => {
      totalAttendance.value = Object.values(checked.value).filter((e) => e).length
    })

    const setTodayAttendance = () => {
      const dt = DateTime.now()
      const todaySchedule = props.schedules.find((sche) => {
        const date = DateTime.fromMillis(sche.day)
        return dt.hasSame(date, 'day')
      })

      if (todaySchedule) {
        todayScheduleId.value = todaySchedule._id
      }
    }

    onMounted(() => {
      setTodayAttendance()
      checked.value = props.data.reduce((acc: AttendanceClass, cur): AttendanceClass => {
        acc[cur.id] = cur.attendance[todayScheduleId.value] || false
        return acc
      }, {})
    })

    return { copies, checked, totalAttendance, handleChange, setMenuOptions, todayScheduleId }
  }
})
