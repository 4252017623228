import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, renderList as _renderList, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4728cbfa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-d-flex p-jc-between p-ai-baseline" }
const _hoisted_2 = { class: "t2" }
const _hoisted_3 = {
  target: "_blank",
  href: "https://www.coderhouse.com/beca",
  class: "btn-vermas text-primary"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "t2 p-mb-3" }
const _hoisted_6 = { class: "clase__scroll" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_toast = _resolveComponent("toast")!
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")!
  const _component_Button = _resolveComponent("Button")!
  const _component_alert_banner = _resolveComponent("alert-banner")!
  const _component_ErrorPage = _resolveComponent("ErrorPage")!
  const _component_skeleton_beca_card = _resolveComponent("skeleton-beca-card")!
  const _component_card_placeholder = _resolveComponent("card-placeholder")!
  const _component_beca_card = _resolveComponent("beca-card")!
  const _component_skeleton_date_card = _resolveComponent("skeleton-date-card")!
  const _component_date_card = _resolveComponent("date-card")!
  const _component_form_after_class = _resolveComponent("form-after-class")!
  const _component_custom_transition = _resolveComponent("custom-transition")!
  const _component_classes_list = _resolveComponent("classes-list")!
  const _component_two_cols_layout = _resolveComponent("two-cols-layout")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_toast),
    _createVNode(_component_ConfirmDialog),
    (_ctx.becaSuspended)
      ? (_openBlock(), _createBlock(_component_alert_banner, {
          key: 0,
          title: _ctx.copies.becaBanner.title,
          message: _ctx.copies.becaBanner.message,
          style: {"margin":"calc(var(--header-height) + var(--space-md)) var(--space-xl) var(--space-md) var(--space-xl)"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Button, {
              class: "p-mb-3",
              label: _ctx.copies.becaBanner.btnPrimaryText,
              onClick: _ctx.changeComision
            }, null, 8, ["label", "onClick"]),
            _createVNode(_component_Button, {
              class: "secondary",
              label: _ctx.copies.becaBanner.btnSecondaryText
            }, null, 8, ["label"])
          ]),
          _: 1
        }, 8, ["title", "message"]))
      : _createCommentVNode("", true),
    (_ctx.isError)
      ? (_openBlock(), _createBlock(_component_ErrorPage, {
          key: 1,
          copies: _ctx.copies.error
        }, null, 8, ["copies"]))
      : (_openBlock(), _createBlock(_component_two_cols_layout, {
          key: 2,
          mainClass: _ctx.becaSuspended ? 'topBorder' : '',
          asideClass: _ctx.becaSuspended ? 'darkBg' : ''
        }, {
          aside: _withCtx(() => [
            (_ctx.rol !== 3 && _ctx.isLoading)
              ? (_openBlock(), _createBlock(_component_skeleton_beca_card, { key: 0 }))
              : _createCommentVNode("", true),
            (!_ctx.isLoading && _ctx.isCourseEnded)
              ? (_openBlock(), _createBlock(_component_card_placeholder, {
                  key: 1,
                  message: _ctx.copies.courseEnded.message
                }, null, 8, ["message"]))
              : (!_ctx.isLoading && _ctx.rol === 2)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                    _createElementVNode("div", _hoisted_1, [
                      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.hasBeca ? _ctx.copies.becaStatus.titleWithBeca : _ctx.copies.becaStatus.titleWithoutBeca), 1),
                      _createElementVNode("a", _hoisted_3, _toDisplayString(_ctx.copies.becaStatus.ctaText), 1)
                    ]),
                    _createVNode(_component_beca_card, {
                      courseStarted: _ctx.classes.find((_class) => _class.order === 1).schedule.day < Date.now(),
                      hasBeca: _ctx.hasBeca,
                      absents: _ctx.absents,
                      classesLength: _ctx.classes.length,
                      attendance: _ctx.attendance,
                      projectProgress: _ctx.projectProgress,
                      copies: _ctx.copies.becaStatus
                    }, null, 8, ["courseStarted", "hasBeca", "absents", "classesLength", "attendance", "projectProgress", "copies"])
                  ], 64))
                : _createCommentVNode("", true),
            _createVNode(_component_custom_transition, null, {
              default: _withCtx(() => [
                (!_ctx.showForm)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createElementVNode("p", _hoisted_5, _toDisplayString((_ctx.classes.length && _ctx.setThisWeekTitle) || ''), 1),
                      _createElementVNode("div", _hoisted_6, [
                        (_ctx.isLoading)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, _renderList(2, (i) => {
                              return _createVNode(_component_skeleton_date_card, { key: i })
                            }), 64))
                          : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.setThisWeek, (_class) => {
                              return (_openBlock(), _createBlock(_component_date_card, {
                                key: _class._id,
                                data: _class,
                                isAfterclass: _class.schedule.isAdditional,
                                role: _ctx.rol,
                                handleOpenForm: _ctx.handleOpenForm,
                                handleCloseForm: _ctx.handleCloseForm,
                                copies: _ctx.copies.thisWeek,
                                onCreateAfterClass: _ctx.handleAfterClassValue,
                                becaSuspended: _ctx.becaSuspended,
                                endDate: _ctx.endDate,
                                handleEnableRate: _ctx.handleEnableRate,
                                camadaNro: _ctx.camadaNro
                              }, null, 8, ["data", "isAfterclass", "role", "handleOpenForm", "handleCloseForm", "copies", "onCreateAfterClass", "becaSuspended", "endDate", "handleEnableRate", "camadaNro"]))
                            }), 128))
                      ])
                    ]))
                  : (_openBlock(), _createBlock(_component_form_after_class, {
                      key: 1,
                      onDisplayError: _ctx.displayError,
                      classesList: _ctx.classes,
                      suggestedClass: _ctx.stageAfterclass,
                      complementaryId: _ctx.complementaryId,
                      handleCloseForm: _ctx.handleCloseForm,
                      courseId: _ctx.courseId,
                      endDate: _ctx.endDate,
                      onOnSubmitEnded: _ctx.onSubmit
                    }, null, 8, ["onDisplayError", "classesList", "suggestedClass", "complementaryId", "handleCloseForm", "courseId", "endDate", "onOnSubmitEnded"]))
              ]),
              _: 1
            })
          ]),
          content: _withCtx(() => [
            _createVNode(_component_classes_list, {
              classes: _ctx.classes,
              afterClasses: _ctx.afterClasses,
              isLoading: _ctx.isLoading,
              role: _ctx.rol,
              handleOpenForm: _ctx.handleOpenForm,
              copies: _ctx.copies,
              handleAfterClassValue: _ctx.handleAfterClassValue,
              handleOpenDialog: _ctx.handleOpenDialog,
              endDate: _ctx.endDate,
              becaSuspended: _ctx.becaSuspended,
              handleEnableRate: _ctx.handleEnableRate
            }, null, 8, ["classes", "afterClasses", "isLoading", "role", "handleOpenForm", "copies", "handleAfterClassValue", "handleOpenDialog", "endDate", "becaSuspended", "handleEnableRate"])
          ]),
          _: 1
        }, 8, ["mainClass", "asideClass"])),
    _createVNode(_component_Dialog, {
      visible: _ctx.displayDialog,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.displayDialog) = $event)),
      modal: true
    }, {
      header: _withCtx(() => [
        _createElementVNode("h3", null, _toDisplayString(_ctx.copies.dialogDeleteAfterClass.title), 1)
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: _ctx.copies.dialogDeleteAfterClass.edit,
          class: "secondary",
          onClick: _ctx.handleEditAfterclass
        }, null, 8, ["label", "onClick"]),
        _createVNode(_component_Button, {
          label: _ctx.copies.dialogDeleteAfterClass.delete,
          loading: _ctx.pendingDelete,
          onClick: _ctx.handleDeleteAfterclass
        }, null, 8, ["label", "loading", "onClick"])
      ]),
      default: _withCtx(() => [
        _createTextVNode(" " + _toDisplayString(_ctx.copies.dialogDeleteAfterClass.text) + " ", 1)
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}