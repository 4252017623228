import { DateTime } from 'luxon'
import { capitalizeFirstLetter } from '@/utils/stringParser'

export const getTime = (milis: number) => {
  const dt = DateTime.fromMillis(milis)
  const value = dt.toFormat('h:mm a').replace(':00', '').replace(/\./gim, '').replace(/\sm/gim, 'm')
  return value
}
export const getDate = (milis: number) => {
  const dt = DateTime.fromMillis(milis)
  const value = dt.toFormat('dd/LL')
  return value
}

export const getCompleteDate = (milis: number) => {
  const dt = DateTime.fromMillis(milis)
  const value = dt.toFormat('dd/LL/yyyy')
  return value
}

export const getHours = (milis: number) => {
  const dt = DateTime.fromMillis(milis)
  const value = dt.toFormat('h a').replace(/\./gim, '').replace(/\sm/gim, 'm')
  return value
}

export const getDayMonthYear = (milis: number) => {
  const dt = DateTime.fromMillis(milis)
  const value = dt.toFormat('dd/MM/yyyy')
  return value
}

/**
 * Parses an ISO string to the specified format. `i.e: 18 de julio 13:12`
 * @param isoDate Date in ISO format
 * @returns Parsed date in format `dd de MMMM HH:mm`
 */
export const parseCommentDate = (isoDate: string) => {
  const date = DateTime.fromISO(isoDate)
  /* eslint-disable */
  return date.setLocale('es').toFormat("d 'de' MMMM HH':'mm")
}

/**
 * Parses an ISO string to the specified format. `i.e: 18/07/2020`
 * @param isoDate Date in ISO format
 * @returns Parsed date in format `dd/MM/yyyy`
 */
export const isoToFullDate = (isoDate: string): string => {
  const date = DateTime.fromISO(isoDate)
  // locale lang relies on main.ts where default language is set
  return date.toFormat('dd/MM/yyyy')
}

export const parseToMillis = (isoDate: string) => {
  return DateTime.fromISO(isoDate).toMillis()
}

/**
 *
 * @param milis
 * @returns four-digit year - 2022
 *
 */

export const getYear = (milis: number) => {
  const dt = DateTime.fromMillis(milis)
  const value = dt.toFormat('yyyy')
  return value
}

/**
 *
 * @param millis
 * @returns month as an padded number - 01
 *
 */

export const getMonth = (millis: number) => {
  const date = DateTime.fromMillis(millis)
  const value = date.toFormat('MM')

  return value
}

/**
 *
 * @param millis
 * @returns month as an abbreviated localized string - Aug
 *
 */

export const getMonthName = (millis: number) => {
  const date = DateTime.fromMillis(millis)
  const value = date.toFormat('MMM')

  const monthCapilize = capitalizeFirstLetter(value)

  return monthCapilize
}
