
import { defineComponent, ref, reactive, toRefs, onMounted, onUnmounted } from 'vue'
import Icon from '@/components/aero/icon/Icon.vue'
import { CloudArrowUp, Close } from '@/components/aero/icon/templates'
import FileTag from '@/components/aero/dataDisplay/FileTag.vue'

// Locales
import copies from '@/locales/challenges/es.json'

// Animations
import { setAnimations, removeAnimations } from '@/components/aero/inputs/InputFileAnimation'

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget
}

/**
 * Props doc
 * isDisable: opt in/out if you want to disable the input
 * animation: if true, when the component mounts it will load the animations functions
 * if you opted for the animation, you need to provide the element's id of the main view to attach the overlay style.
 */

export default defineComponent({
  components: { Icon, CloudArrowUp, FileTag, Close },
  props: {
    isDisabled: { type: Boolean, default: false },
    animation: { type: Boolean, default: false },
    DOMIdOverlay: { type: String, required: false }
  },
  setup(props, { emit }) {
    const isFileSelected = ref<boolean>(false)

    const fileData = reactive({
      text: '',
      type: '',
      size: ''
    })

    const text = reactive({
      heading1: copies.InputFile.heading1,
      infoText: copies.InputFile.fileType
    })
    const error = ref<boolean>(false)

    const handleOnDrop = (e: CustomEvent & { dataTransfer?: any }) => {
      if (props.DOMIdOverlay && props.animation) {
        const dropZoneDOM = document.querySelector('#dropzone-container')
        dropZoneDOM?.classList.toggle('animation__drop-zone--file-dropped')
      }

      if (props.isDisabled) return
      const { name, size } = e.dataTransfer.files[0]

      if (!validateFormatAndSize(name, size)) return

      isFileSelected.value = true
      fileData.text = name.split('.').shift()

      const fileExtension = name.split('.').pop()
      fileData.type = fileExtension

      const sizeMB = formatToMB(size).toFixed(2)
      fileData.size = `${sizeMB}MB`
      emit('onDrop', e.dataTransfer.files[0])
    }

    const handleOnChange = (e: Event) => {
      if (props.isDisabled) return
      const target = e.target as HTMLInputElement
      const file: File = (target.files as FileList)[0]
      const { name, size } = file

      if (!validateFormatAndSize(name, size)) return

      isFileSelected.value = true
      fileData.text = name.split('.').shift() || name

      const fileExtension = name.split('.').pop()
      fileData.type = fileExtension || ''

      const sizeMB = formatToMB(size).toFixed(2)
      fileData.size = `${sizeMB}MB`

      emit('onChange', file)
    }

    const handleDeleteFile = () => {
      isFileSelected.value = false
      fileData.text = ''
      fileData.type = ''
      fileData.size = ''
      emit('onDeleteFile', null)
    }

    const formatToMB = (num: number) => {
      const kb = num / 1000
      return kb / 1000
    }

    const resetError = () => {
      error.value = false
      text.heading1 = copies.InputFile.heading1
      text.infoText = copies.InputFile.fileType
    }

    const validateFormatAndSize = (fileName: string, size: number) => {
      resetError()
      const fileExtension = fileName.split('.').pop()
      const validTypes = ['png', 'jpg', 'jpeg', 'pdf', 'doc', 'gzip', 'rar', 'zip']
      const limitSize = 10000000 // 10MB
      if (fileExtension && !validTypes.includes(fileExtension.toLowerCase())) {
        error.value = true
        text.heading1 = copies.InputFile.error.format.heading1
        text.infoText = copies.InputFile.error.format.infoError
        return
      }
      if (size > limitSize) {
        error.value = true
        text.heading1 = copies.InputFile.error.size.heading1
        text.infoText = copies.InputFile.error.size.infoError
        return
      }
      return true
    }

    onMounted(() => {
      props.DOMIdOverlay && props.animation && setAnimations(props.DOMIdOverlay)
    })
    onUnmounted(() => {
      props.DOMIdOverlay && props.animation && removeAnimations(props.DOMIdOverlay)
    })

    return { handleOnDrop, handleOnChange, handleDeleteFile, isFileSelected, fileData, copies, error, ...toRefs(text) }
  }
})
