
// Vendor
import { defineComponent, ref, PropType } from 'vue'

// Icons
import Icon from '@/components/aero/icon/Icon.vue'
import { Flag } from '@/components/aero/icon/templates'

type ItemTypes = {
  label: string
  url?: string
  target?: '_blank' | '_self'
  command?: () => void
}

export default defineComponent({
  components: { Icon, Flag },
  props: {
    border: { type: Boolean, default: false },
    text: { type: String, required: true },
    description: { type: String, required: false },
    status: { type: String, default: '' },
    options: { type: Array as PropType<ItemTypes[]> }
  },
  setup() {
    const menuRef = ref()

    const openMenu = (event: Event) => {
      event.preventDefault()
      menuRef.value.toggle(event)
    }

    return { menuRef, openMenu }
  }
})
