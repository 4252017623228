<template>
  <path
    d="M2.25012 11.9997C2.25012 6.6236 6.62403 2.24969 12.0001 2.24969C17.3762 2.24969 21.7501 6.6236 21.7501 11.9997C21.7501 17.3758 17.3762 21.7497 12.0001 21.7497C6.62403 21.7497 2.25012 17.3758 2.25012 11.9997Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10.2486 5.94275C9.35991 5.94275 8.72798 6.09115 8.29477 6.26443C8.07883 6.35081 7.91551 6.4421 7.79711 6.52103C7.73805 6.5604 7.69057 6.59643 7.65364 6.62685C7.63519 6.64204 7.61941 6.65581 7.60616 6.66785C7.59953 6.67387 7.59354 6.67946 7.58818 6.68458L7.58059 6.69191L7.57714 6.6953L7.5755 6.69692L7.5747 6.69772C7.57431 6.69811 7.57392 6.6985 8.10425 7.22883L7.57392 6.6985C7.43327 6.83915 7.35425 7.02992 7.35425 7.22883V13.6618V13.6618V17.4143C7.35425 17.8285 7.69003 18.1643 8.10425 18.1643C8.51846 18.1643 8.85425 17.8285 8.85425 17.4143V14.0891C9.0917 13.9945 9.53139 13.8757 10.2486 13.8757C10.8882 13.8757 11.4307 14.0847 12.1143 14.3581L12.1535 14.3738C12.8025 14.6336 13.5878 14.9479 14.5372 14.9479C15.4259 14.9479 16.0578 14.7995 16.491 14.6262C16.7069 14.5398 16.8702 14.4485 16.9886 14.3696C17.0477 14.3302 17.0952 14.2942 17.1321 14.2638C17.1506 14.2486 17.1664 14.2348 17.1796 14.2228C17.1862 14.2167 17.1922 14.2111 17.1976 14.206L17.2052 14.1987L17.2086 14.1953L17.2103 14.1937L17.2111 14.1929C17.2115 14.1925 17.2118 14.1921 16.6815 13.6618L17.2118 14.1921C17.3525 14.0515 17.4315 13.8607 17.4315 13.6618V7.22883C17.4315 6.92548 17.2488 6.652 16.9685 6.53592C16.6986 6.42411 16.3894 6.47951 16.1753 6.67538C16.1719 6.67799 16.1657 6.68249 16.1566 6.68855C16.1242 6.71013 16.053 6.75287 15.9339 6.80051C15.697 6.89527 15.2568 7.01491 14.5372 7.01491C13.8976 7.01491 13.3551 6.80594 12.6714 6.53247L12.6323 6.51681C11.9833 6.25704 11.198 5.94275 10.2486 5.94275ZM8.85425 12.5229V7.65619C9.0917 7.56159 9.53139 7.44275 10.2486 7.44275C10.8882 7.44275 11.4307 7.65172 12.1143 7.92519L12.1535 7.94085C12.8025 8.20061 13.5878 8.51491 14.5372 8.51491C15.1 8.51491 15.5599 8.45538 15.9315 8.3677V13.2344C15.6941 13.329 15.2544 13.4479 14.5372 13.4479C13.8976 13.4479 13.3551 13.2389 12.6714 12.9654L12.6323 12.9498C11.9833 12.69 11.198 12.3757 10.2486 12.3757C9.68572 12.3757 9.22586 12.4352 8.85425 12.5229Z"
    fill="#333437"
    stroke="transparent"
  />
</template>
