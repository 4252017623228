import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9d96f9e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "corner bg-dark-gray p-d-flex p-flex-column p-jc-center p-ai-start" }
const _hoisted_2 = { class: "p2 text-light-gray" }
const _hoisted_3 = { class: "p-mt-2" }
const _hoisted_4 = { class: "card-body p1 text-white medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_state_tags = _resolveComponent("state-tags")!
  const _component_chevron_right = _resolveComponent("chevron-right")!
  const _component_button_icon = _resolveComponent("button-icon")!
  const _component_card = _resolveComponent("card")!

  return (_openBlock(), _createBlock(_component_card, {
    class: "desafiocard p-d-flex p-jc-between p-ai-center",
    paddingSm: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.customSubtitle) + " " + _toDisplayString(!_ctx.regularChallenge ? `${_ctx.copies.classPrefix} ${_ctx.classOrder}` : ''), 1),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_state_tags, {
            role: _ctx.role,
            state: _ctx.challenge.status,
            copies: _ctx.copies.challengesByModule.statusTags,
            showChallengeType: _ctx.showChallengeType
          }, null, 8, ["role", "state", "copies", "showChallengeType"])
        ])
      ]),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.customTitle), 1),
      _createVNode(_component_button_icon, {
        asLinkType: "internal",
        color: "var(--white)",
        size: 16,
        href: _ctx.useCoderChallenge?`/reviews/${_ctx.camadaNro}/${_ctx.challenge._id}/${_ctx.challenge.stage}`:'/challenges/challenge/' + _ctx.challenge._id
      }, {
        default: _withCtx(() => [
          _createVNode(_component_chevron_right)
        ]),
        _: 1
      }, 8, ["href"])
    ]),
    _: 1
  }))
}