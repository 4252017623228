import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, renderList as _renderList, withCtx as _withCtx, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8fd8bd46"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-d-flex p-jc-between p-ai- baseline p-mt-3" }
const _hoisted_2 = { class: "t2 p-ml-2 p-mb-2" }
const _hoisted_3 = { class: "t2 p-ml-2 p-my-2" }
const _hoisted_4 = { class: "t2 p-ml-2 p-my-2" }
const _hoisted_5 = { class: "p-mt-4 p-mb-6" }
const _hoisted_6 = { class: "p-d-flex p-jc-between p-ai-center" }
const _hoisted_7 = { class: "t2" }
const _hoisted_8 = {
  key: 0,
  class: "profile__searchedUser p-mt-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ToTopButton = _resolveComponent("ToTopButton")!
  const _component_ErrorPage = _resolveComponent("ErrorPage")!
  const _component_SkeletonCourseInfoCard = _resolveComponent("SkeletonCourseInfoCard")!
  const _component_SkeletonTeacherGroup = _resolveComponent("SkeletonTeacherGroup")!
  const _component_items_card = _resolveComponent("items-card")!
  const _component_ContactCard = _resolveComponent("ContactCard")!
  const _component_Card = _resolveComponent("Card")!
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_profile_card_small = _resolveComponent("profile-card-small")!
  const _component_PlaceholderState = _resolveComponent("PlaceholderState")!
  const _component_SkeletonListProfileGroup = _resolveComponent("SkeletonListProfileGroup")!
  const _component_list_profile_group = _resolveComponent("list-profile-group")!
  const _component_two_cols_layout = _resolveComponent("two-cols-layout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ToTopButton),
    (_ctx.isError)
      ? (_openBlock(), _createBlock(_component_ErrorPage, { key: 0 }))
      : (_openBlock(), _createBlock(_component_two_cols_layout, { key: 1 }, {
          aside: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.copies.ItemsCard.title), 1)
            ]),
            (_ctx.loading)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_SkeletonCourseInfoCard),
                  _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.copies.teacherGroup.title), 1),
                  _createVNode(_component_SkeletonTeacherGroup)
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  (_ctx.user.role === 2)
                    ? (_openBlock(), _createBlock(_component_items_card, {
                        key: 0,
                        courseName: _ctx.currentCourse.courseName,
                        commission: _ctx.currentCourse.camadaNro,
                        daysAndHours: _ctx.currentCourse.daysAndHours,
                        startDate: _ctx.currentCourse.startDate,
                        endDate: _ctx.currentCourse.endDate,
                        category: _ctx.currentCourse.category,
                        holidays: _ctx.currentCourse.holidays
                      }, null, 8, ["courseName", "commission", "daysAndHours", "startDate", "endDate", "category", "holidays"]))
                    : _createCommentVNode("", true),
                  _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.copies.teacherGroup.title), 1),
                  _createVNode(_component_Card, {
                    class: "p-d-flex p-flex-column",
                    style: {"gap":"var(--space-xxl)"}
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.teacherGroup, (tutor, index) => {
                        return (_openBlock(), _createBlock(_component_ContactCard, {
                          key: tutor._id || index,
                          name: tutor.fullName,
                          category: tutor.category,
                          imageLink: tutor.avatar,
                          chatLink: tutor.chatLink,
                          profileLink: tutor.profileLink
                        }, null, 8, ["name", "category", "imageLink", "chatLink", "profileLink"]))
                      }), 128))
                    ]),
                    _: 1
                  })
                ], 64))
          ]),
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.copies.ListProfileGroup.title), 1),
                _createVNode(_component_SearchInput, {
                  onOnUpdateSearch: _ctx.searchTutorOrStudent,
                  placeholderText: _ctx.copies.searchInputPlaceholder,
                  style: {"width":"204px"}
                }, null, 8, ["onOnUpdateSearch", "placeholderText"])
              ]),
              (_ctx.isFilterActive && _ctx.filteredClassmates.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredClassmates, (user) => {
                      return (_openBlock(), _createElementBlock(_Fragment, {
                        key: user._id
                      }, [
                        (user?.fullName !== ' ')
                          ? (_openBlock(), _createBlock(_component_profile_card_small, {
                              key: 0,
                              name: user.fullName,
                              category: user.personalGoal || user.category,
                              imageLink: user.avatar,
                              link: user.link || user.profileLink
                            }, null, 8, ["name", "category", "imageLink", "link"]))
                          : _createCommentVNode("", true)
                      ], 64))
                    }), 128))
                  ]))
                : (_ctx.isFilterActive)
                  ? (_openBlock(), _createBlock(_component_PlaceholderState, {
                      key: 1,
                      class: "p-mt-5",
                      isEmpty: true,
                      customCopies: { line1: _ctx.copies.emptyState }
                    }, null, 8, ["customCopies"]))
                  : _createCommentVNode("", true),
              (_ctx.loading)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, _renderList(2, (index) => {
                    return _createVNode(_component_SkeletonListProfileGroup, {
                      key: index,
                      style: _normalizeStyle(index === 1 ? 'margin-top:var(--space)' : 'margin-top:var(--space-xxxl)')
                    }, null, 8, ["style"])
                  }), 64))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.classmates, (item, index) => {
                return _withDirectives((_openBlock(), _createElementBlock("div", { key: index }, [
                  _createVNode(_component_list_profile_group, {
                    style: _normalizeStyle(index === 0 ? 'margin-top:var(--space)' : 'margin-top:var(--space-xxxl)'),
                    tutor: item.tutor,
                    students: item.students
                  }, null, 8, ["style", "tutor", "students"])
                ])), [
                  [_vShow, !_ctx.isFilterActive && !_ctx.loading]
                ])
              }), 128))
            ])
          ]),
          _: 1
        }))
  ], 64))
}