
import TutorCorrection from './components/correction/TutorCorrection.vue'
import { defineComponent, onMounted, ref } from 'vue'
import { ICorrection, ICorrectionDataDto, HomeworkPlan } from '@/models/peer-review/homeworkPlan'
import { IChallengeHistory } from '@/models/peer-review/challengeHistory'
import StudentList from './components/StudentList.vue'
import { ITutorChallengeStudents } from '@/models/peer-review/challenge'
import { useRouter } from 'vue-router'
import { useToast } from 'primevue/usetoast'
import {
  getCorrection,
  getStudents,
  qualifyChallenge,
  feedbackDelivery,
  requestDelivery,
  // getComments,
  getEvaluations,
  getHomeworkPlan,
  getStudentDelivery,
  enableChallenge,
  reactivateChallenge
} from '@/api/challenge.api'
import { useI18n } from 'vue-i18n'
import ChallengeTimeline from './components/ChallengeTimeline.vue'
import MessageBox from '@/views/peerReview/components/MessageBox.vue'
import Lock from '@/components/commons/icon-templates/Lock.vue'
import Clock from '@/components/commons/icon-templates/HabilitedCorrection.vue'
import { HomeworkDeliveryStatus, HomeworkTypes } from '@/utils/constants'
import { formatDate, formatTime } from '@/utils/dates'
import { DateTime } from 'luxon'
import { IStudentsEvaluation } from '@/models/peer-review/evaluation'
import { themeStore } from '@/store/modules/themes.store'
import { useFirebaseStore } from '@/store/modules/firebase.store'
import { useCourseStore } from '@/store/modules/course.store'
export default defineComponent({
  components: {
    StudentList,
    TutorCorrection,
    ChallengeTimeline,
    MessageBox
  },
  setup() {
    const theme = themeStore()
    theme.changeDefault('dark')
    const { t } = useI18n()
    const toast = useToast()
    const router = useRouter()
    const correction = ref<ICorrection>()
    const showModal = ref<boolean>(false)
    const loading = ref<boolean>(true)
    const loadingRight = ref<boolean>(false)
    const students = ref<Array<ITutorChallengeStudents>>([])
    const courseId = router.currentRoute.value.params.courseId
    const homeworkId = router.currentRoute.value.params.homeworkId
    const homeworkPlanId = router.currentRoute.value.params.homeworkPlanId
    const selectedStudent = ref<ITutorChallengeStudents>()
    const studentId = ref<string>()
    const deliveryId = ref<string>()
    const sending = ref<boolean>(false)
    const evaluations = ref<IStudentsEvaluation | []>()
    const deliveryStatus = ref<string | null>()
    const challenge = ref<HomeworkPlan>()
    const delivery = ref<IChallengeHistory | null>()
    let challengePromise: Promise<HomeworkPlan>
    const enableReviewDate = ref<DateTime | null>()
    const usePeerReview = ref<boolean>(false)
    const { fetchValue } = useFirebaseStore()
    const { hasCoderAskActive } = useCourseStore()

    onMounted(async () => {
      const coderChallenges = await fetchValue('peer_review', 'array') as string[]
      usePeerReview.value = coderChallenges.includes(courseId as string)
      getStudentList()
      challengePromise = getHomeworkPlan(courseId as string, homeworkPlanId as string, homeworkId as string)
      challenge.value = await challengePromise
    })

    const getStudentList = async () => {
      loading.value = true
      students.value = await getStudents(courseId as string, homeworkPlanId as string, homeworkId as string)
      selectStudent(students.value[0])
      loading.value = false
    }

    const selectStudent = async (student: ITutorChallengeStudents) => {
      loadingRight.value = true
      let status = student.homeworkStudentDelivery ? student.homeworkStudentDelivery.status : student.homeworkStudentDelivery
      deliveryId.value = student.homeworkStudentDelivery?.id
      selectedStudent.value = student
      studentId.value = student.id

      if (!status && DateTime.now() > DateTime.fromISO(challenge.value?.expirationDate as string)) {
        status = HomeworkDeliveryStatus.EXPIRED
      }

      if (status === 'claimedForwarding' || status === 'approved' || status === 'disapproved' || status === 'reviewed') {
        const deliveryPromise = getStudentDelivery(homeworkPlanId as string, homeworkId as string, student.id as string)
        delivery.value = await deliveryPromise
      } else if (status === 'forwarded' || status === 'delivered') {
        const correctionPromise = getCorrection(deliveryId.value as string)
        correction.value = await correctionPromise
        // const commentsPromise = getComments(deliveryId.value as string)
        // if (challenge.value?.type === 'regular') comments.value = await commentsPromise
        if (challenge.value?.type === 'regular' && status === 'delivered') {
          evaluations.value = await getEvaluations(deliveryId.value as string)
        } else {
          evaluations.value = []
        }
        enableReviewDate.value = DateTime.fromISO(
          selectedStudent.value.homeworkStudentDelivery?.schedule?.enableForReviewAt as string
        )
      }
      deliveryStatus.value = status
      loadingRight.value = false
    }

    const send = async (body: ICorrectionDataDto, approve: boolean, challengeType = '') => {
      try {
        sending.value = true
        let  toastMessage = 'homework.challenge.peerReview.correction.send.success.detail'
        if (approve) await qualifyChallenge(body, deliveryId.value as string)
        else if(challengeType === HomeworkTypes.COMPLEMENTARY){
          await feedbackDelivery(body, deliveryId.value as string)
          toastMessage = 'homework.challenge.complementary.correction.send.success.detail'
        }else{
          await requestDelivery(deliveryId.value as string, body)
        } 
        getStudentList()
        toast.add({
          severity: 'success',
          summary: t('homework.challenge.upload.send.success.title'),
          detail: t(toastMessage),
          life: 3000
        })
        correction.value = undefined
      } catch (e) {
        toast.add({
          severity: 'error',
          summary: t('homework.challenge.upload.send.error.title'),
          detail: t('homework.challenge.peerReview.correction.send.error.detailTutor'),
          life: 3000
        })
      } finally {
        sending.value = false
      }
    }

    const enable = async () => {
      try {
        sending.value = true

        let response
        if (deliveryId.value) {
          response = await reactivateChallenge(deliveryId.value as string)
        } else {
          response = await enableChallenge(
            courseId as string,
            homeworkPlanId as string,
            homeworkId as string,
            studentId.value as string
          )
        }

        if (!response) {
          toast.add({
            severity: 'error',
            summary: t('homework.challenge.upload.send.error.title'),
            detail: t('homework.challenge.enable.error.detail'),
            life: 3000
          })
        } else {
          toast.add({
            severity: 'success',
            summary: t('homework.challenge.upload.send.success.title'),
            detail: t('homework.challenge.enable.success.detail'),
            life: 3000
          })
          getStudentList()
        }
      } catch (e) {
        toast.add({
          severity: 'error',
          summary: t('homework.challenge.upload.send.error.title'),
          detail: t('homework.challenge.enable.error.detail'),
          life: 3000
        })
      } finally {
        sending.value = false
        closeModal()
      }
    }

    const openModal = () => {
      showModal.value = true
    }

    const closeModal = () => {
      showModal.value = false
    }

    const getMessagesBox = (deliveryStatus: string | null, expirationDate: string) => {
      let firstMessage = ''
      let secondMessage = ''
      if (deliveryStatus === HomeworkDeliveryStatus.EXPIRED && !hasCoderAskActive) {
        firstMessage = t('homework.challenge.tutorCorrection.expiredMessage1')
        secondMessage = t('homework.challenge.tutorCorrection.expiredMessage2')
      } else if (deliveryStatus === HomeworkDeliveryStatus.EXPIRED && hasCoderAskActive) {
        firstMessage = t('homework.challenge.tutorCorrection.hasCoderAsk.subtitle1')
        secondMessage = t('homework.challenge.tutorCorrection.hasCoderAsk.subtitle2')
      } else {
        firstMessage = t('homework.challenge.tutorCorrection.waitingDelivery.message', { date: formatDate(expirationDate), time: formatTime(expirationDate) })
        secondMessage = ''
      }

      return {firstMessage, secondMessage}
    }

    return {
      t,
      selectStudent,
      deliveryStatus,
      challenge,
      delivery,
      send,
      students,
      showModal,
      usePeerReview,
      openModal,
      closeModal,
      enable,
      loading,
      loadingRight,
      correction,
      selectedStudent,
      sending,
      Lock,
      Clock,
      formatDate,
      formatTime,
      enableReviewDate,
      DateTime,
      evaluations,
      hasCoderAskActive,
      getMessagesBox
    }
  }
})
