import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p2 text-light-gray p-mb-2" }
const _hoisted_2 = { class: "bold p-pb-3" }
const _hoisted_3 = { class: "p-d-flex p-ai-center p-mb-3" }
const _hoisted_4 = { class: "p3 text-purple bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_clock = _resolveComponent("clock")!
  const _component_icon = _resolveComponent("icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_card = _resolveComponent("card")!

  return (_openBlock(), _createBlock(_component_card, null, {
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.copies.EntregaCard.class) + " " + _toDisplayString(_ctx.challenge.class.order), 1),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.challenge.name), 1),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_icon, {
          class: "p-mr-1",
          strokeColor: "var(--purple)",
          size: 16
        }, {
          default: _withCtx(() => [
            _createVNode(_component_clock)
          ]),
          _: 1
        }),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.expiresAt), 1)
      ]),
      _createVNode(_component_router_link, {
        to: _ctx.useCoderChallenge?`/reviews/${_ctx.camadaNro}/${_ctx.challenge._id}/${_ctx.challenge.stage}`:'/challenges/challenge/' + _ctx.challenge._id,
        class: _normalizeClass(`p-button full-width ${!_ctx.isNextToExpire ? 'secondary' : ''}`),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleTrackEvent(_ctx.ChallengesEvents.GO_TO_PENDING_DELIVER, { origin: 'EntregaCard' })))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.label), 1)
        ]),
        _: 1
      }, 8, ["to", "class"])
    ]),
    _: 1
  }))
}