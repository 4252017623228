import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, Fragment as _Fragment, renderList as _renderList, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-44273a18"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "t2 bold" }
const _hoisted_2 = {
  key: 0,
  class: "p-d-flex p-ai-center p-ml-2 status__container"
}
const _hoisted_3 = { class: "p3 text-purple status__text" }
const _hoisted_4 = {
  key: 1,
  class: "p3 text-error bold status__text"
}
const _hoisted_5 = {
  key: 2,
  class: "p3 text-apple-green bold status__text"
}
const _hoisted_6 = {
  key: 3,
  class: "p3 text-error bold status__text"
}
const _hoisted_7 = {
  key: 4,
  class: "p3 text-blue bold status__text"
}
const _hoisted_8 = { class: "t2" }
const _hoisted_9 = { class: "timeline__container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_toast = _resolveComponent("toast")!
  const _component_ErrorPage = _resolveComponent("ErrorPage")!
  const _component_skeleton_desafio_aside = _resolveComponent("skeleton-desafio-aside")!
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_skeleton_text = _resolveComponent("skeleton-text")!
  const _component_clock = _resolveComponent("clock")!
  const _component_icon = _resolveComponent("icon")!
  const _component_image_card = _resolveComponent("image-card")!
  const _component_SkeletonTimeline = _resolveComponent("SkeletonTimeline")!
  const _component_placeholder_state = _resolveComponent("placeholder-state")!
  const _component_message = _resolveComponent("message")!
  const _component_state_historial = _resolveComponent("state-historial")!
  const _component_form_deliver_challenge = _resolveComponent("form-deliver-challenge")!
  const _component_two_cols_layout = _resolveComponent("two-cols-layout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_toast),
    (_ctx.isError)
      ? (_openBlock(), _createBlock(_component_ErrorPage, {
          key: 0,
          copies: _ctx.copies.error
        }, null, 8, ["copies"]))
      : (_openBlock(), _createBlock(_component_two_cols_layout, {
          key: 1,
          id: "student-challenge-view"
        }, {
          aside: _withCtx(() => [
            (_ctx.isLoading)
              ? (_openBlock(), _createBlock(_component_skeleton_desafio_aside, { key: 0 }))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createVNode(_component_Breadcrumb, {
                    home: { label: _ctx.copies.studentChallenge.breadCrumb.challenge, to: '/challenges' },
                    model: [
            { label: `${_ctx.copies.studentChallenge.breadCrumb.challengeClass} ${_ctx.order}`, to: `/challenges/challenge/${_ctx.challengeId}` }
          ]
                  }, null, 8, ["home", "model"]),
                  _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.challengeName), 1),
                  (_ctx.isTimeLineLoading)
                    ? (_openBlock(), _createBlock(_component_skeleton_text, {
                        key: 0,
                        maxWords: 1,
                        sm: "",
                        length: 10
                      }))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        (
              (_ctx.isChallengeEnabled && !_ctx.isDeliverExpired && !_ctx.projectData.deliverId) ||
              (_ctx.isChallengeEnabled && _ctx.projectData.deliverId && _ctx.projectData.unlockedUntil && _ctx.projectData.unlockedUntil > Date.now())
            )
                          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                              _createVNode(_component_icon, {
                                class: "p-mr-1",
                                strokeColor: "var(--purple)",
                                size: 16
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_clock)
                                ]),
                                _: 1
                              }),
                              _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.projectData.unlockedUntil ? _ctx.setCountdown(_ctx.projectData.unlockedUntil) : _ctx.setCountdown(_ctx.expiresAt)), 1)
                            ]))
                          : (_ctx.projectData.isLocked)
                            ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.copies.studentChallenge.challengeState.locked), 1))
                            : (_ctx.isChallengeEnabled && _ctx.projectData.deliverId && _ctx.projectData.wasApproved)
                              ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.copies.studentChallenge.challengeState.approved), 1))
                              : (
              (!_ctx.projectData.deliverId && _ctx.isDeliverExpired) ||
              (_ctx.projectData.deliverId &&
                !_ctx.projectData.wasApproved &&
                _ctx.projectData.unlockedUntil &&
                _ctx.projectData.unlockedUntil < Date.now()) ||
              (_ctx.projectData.deliverId &&
                _ctx.projectData.finalDeadline &&
                _ctx.projectData.finalDeadline < Date.now() &&
                !_ctx.projectData.wasApproved)
            )
                                ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.copies.studentChallenge.challengeState.expired), 1))
                                : (_ctx.isChallengeEnabled && _ctx.projectData.deliverId && !_ctx.projectData.wasApproved)
                                  ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.copies.studentChallenge.challengeState.delivered), 1))
                                  : _createCommentVNode("", true)
                      ], 64)),
                  (_ctx.slideUrl)
                    ? (_openBlock(), _createBlock(_component_image_card, {
                        key: 2,
                        cardType: 'large',
                        link: { title: _ctx.challengeName, url: _ctx.slideUrl, type: 'external' },
                        img: '/assets/image-card-placeholder-invert.png',
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleTrackEvent(_ctx.ChallengesEvents.VIEW_MODULE_ASSETS)))
                      }, null, 8, ["link", "img"]))
                    : _createCommentVNode("", true)
                ], 64))
          ]),
          content: _withCtx(() => [
            (_ctx.isTimeLineLoading)
              ? (_openBlock(), _createBlock(_component_SkeletonTimeline, { key: 0 }))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.copies.challengeView.uploadChallenge), 1),
                  _createElementVNode("div", _hoisted_9, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projectData.history, (step) => {
                      return (_openBlock(), _createBlock(_component_state_historial, {
                        key: step.key,
                        step: step,
                        role: 2,
                        class: "timeline__step"
                      }, {
                        default: _withCtx(() => [
                          (step.key === _ctx.ITimeLineStatus.disabled)
                            ? (_openBlock(), _createBlock(_component_placeholder_state, {
                                key: 0,
                                dateString: _ctx.setDateTimeString(_ctx.openDateChallenge),
                                isDisabled: "",
                                role: 3
                              }, null, 8, ["dateString"]))
                            : _createCommentVNode("", true),
                          (step.key === _ctx.ITimeLineStatus.chat && !step.hide)
                            ? (_openBlock(), _createBlock(_component_message, {
                                key: 1,
                                avatar: step.content.from.avatar,
                                userName: step.content.from.fullName,
                                id: step.content._id,
                                messageType: step.content.from._id === _ctx.userId ? 'received' : 'sent',
                                message: step.content.text,
                                date: step.content.date,
                                file: step.content.file ? { text: step.content.link } : null,
                                link: !step.content.file && step.content.link ? { text: step.content.link } : null,
                                class: "step__spacing"
                              }, null, 8, ["avatar", "userName", "id", "messageType", "message", "date", "file", "link"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1032, ["step"]))
                    }), 128)),
                    (
              _ctx.forceEnableDeliver ||
              (_ctx.isChallengeEnabled && !_ctx.projectData.deliverId && !_ctx.isDeliverExpired) ||
              (_ctx.isChallengeEnabled &&
                _ctx.projectData.deliverId &&
                _ctx.projectData.unlockedUntil &&
                _ctx.projectData.unlockedUntil > Date.now()) ||
              (_ctx.isChallengeEnabled &&
                !_ctx.projectData.isLocked &&
                _ctx.projectData.deliverId &&
                _ctx.projectData.finalDeadline &&
                _ctx.projectData.finalDeadline > Date.now() &&
                (_ctx.projectData.history.slice(-1)[0].key === _ctx.ITimeLineStatus.deliverAgain ||
                  _ctx.projectData.history.slice(-1)[0].key === _ctx.ITimeLineStatus.unlocked))
            )
                      ? (_openBlock(), _createBlock(_component_form_deliver_challenge, {
                          key: 0,
                          moduleId: _ctx.moduleId,
                          moduleName: _ctx.challengeName,
                          stageId: _ctx.stage,
                          classId: _ctx.classId,
                          projectId: _ctx.projectData.deliverId,
                          DOMIdOverlay: "student-challenge-view",
                          onOnDelivered: _ctx.handleOnDelivered,
                          class: "step__spacing"
                        }, null, 8, ["moduleId", "moduleName", "stageId", "classId", "projectId", "onOnDelivered"]))
                      : (
              (_ctx.isChallengeEnabled &&
                _ctx.projectData.deliverId &&
                !_ctx.projectData.wasApproved &&
                _ctx.projectData.unlockedUntil &&
                _ctx.projectData.unlockedUntil <= Date.now()) ||
              (_ctx.isChallengeEnabled &&
                _ctx.projectData.deliverId &&
                !_ctx.projectData.wasApproved &&
                _ctx.projectData.finalDeadline < Date.now() &&
                !_ctx.projectData.isLocked) ||
              (_ctx.isChallengeEnabled && !_ctx.projectData.deliverId && _ctx.isDeliverExpired)
            )
                        ? (_openBlock(), _createBlock(_component_placeholder_state, {
                            key: 1,
                            role: 2,
                            isExpired: "",
                            class: "step__spacing"
                          }))
                        : _createCommentVNode("", true)
                  ])
                ], 64))
          ]),
          _: 1
        }))
  ], 64))
}