import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b9b2511"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-fluid" }
const _hoisted_2 = { class: "p-mb-3" }
const _hoisted_3 = {
  key: 0,
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_InputText, {
        placeholder: _ctx.label,
        value: _ctx.modelValue,
        onInput: _ctx.change,
        type: "text",
        class: _normalizeClass(['custom-input', { 'p-invalid': _ctx.error }])
      }, null, 8, ["placeholder", "value", "onInput", "class"]),
      (_ctx.error)
        ? (_openBlock(), _createElementBlock("small", _hoisted_3, _toDisplayString(_ctx.errorMessage), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}