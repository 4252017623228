
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  emits: ['update:modelValue', 'select', 'comment'],
  props: {
    label: {
      type: String,
      required: true
    },
    modelValue: {
      type: String
    },
    error: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    },
    QuickMessages: {
      type: Array,
      default: () => []
    }
  },
  setup (props, { emit }) {
    const { t } = useI18n()
    const change = (event: { textValue:string, htmlValue:string}) => {
      emit('update:modelValue', event.htmlValue)
      emit('comment', event.textValue)
    }
    const selectMessage = (message: string) => {
      emit('select', message)
    }

    return { t, change, selectMessage }
  }
})
