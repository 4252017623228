
  import { defineComponent, ref } from 'vue'
  import ProgressBar from 'primevue/progressbar'
  import YouTube from 'vue3-youtube'
  
  export default defineComponent({
    components: {
      YouTube, 
      ProgressBar
    },
    props: {
      title: {type: String, required: true },
      url: {type: String, required: true },
      height: {type: Number, required: true }
    },
    setup () {
      const youtube = ref()
      const vars = ref<{rel: number}>({rel: 0})
      return { 
        youtube,
        vars
     }
    }
  })
