
import { defineComponent, ref, PropType, reactive, onMounted } from 'vue'
import { Viewers } from '@/utils/constants'
import { IHomeworkFile } from '@/models/peer-review/homeworkPlan'
import { useI18n } from 'vue-i18n'
import DownloadButton from '@/components/atomic/DownloadButton.vue'
import UrlButton from '@/components/commons/UrlButton.vue'

export default defineComponent({
  components: {
    DownloadButton,
    UrlButton
  },
  props: {
    comment: {
      type: String,
      default: ''
    },
    challenge: {
      type: Object as PropType<IHomeworkFile>,
      required: true
    }
  },
  setup (props) {
    const { t } = useI18n()
    const challenge = ref<IHomeworkFile>(props.challenge)

    const preview = ref<boolean>(false)
    const viewer = ref<string>()
    const file = ref<string>()
    const type = ref<string>()

    const types = reactive({
      link: false,
      image: false,
      doc: false,
      pdf: false,
      compressed: false
    })

    if (challenge.value.link) {
      types.link = true
    } else {
      type.value = challenge.value.attachment.mimeType
      switch (type.value) {
        case 'application/pdf':
          viewer.value = Viewers.get('google')
          types.pdf = true
          break
        case 'image/png':
        case 'image/jpg':
        case 'image/jpeg':
        case 'image/gif':
        case 'image/webp':
          viewer.value = undefined
          types.image = true
          break
        case 'application/x-zip-compressed':
        case 'application/zip':
        case 'application/x-rar-compressed':
        case 'application/octet-stream':
        case 'multipart/x-zip':
          viewer.value = undefined
          types.compressed = true
          break
        default:
          viewer.value = Viewers.get('microsoft')
          types.doc = true
          break
      }
    }

    onMounted(async () => {
      const fileId = challenge.value.attachment?.id
      file.value = `${window.location.origin}/api/v1/homework-student-delivery/file/${fileId}`
    })

    return { t, viewer, preview, types, type, file }
  } 
})
