
// Vendor
import { defineComponent, PropType, ref, computed } from 'vue'
import { DateTime } from 'luxon'

// Locales
import { classPrefix, extraActions } from '@/locales/challenges/es.json'

// Components
import Card from '@/components/aero/surfaces/Card.vue'
import Avatar from '@/components/aero/dataDisplay/Avatar.vue'
import Dots from '@/components/aero/icon/templates/dots.vue'
import Notification from '@/components/aero/icon/templates/notification.vue'
import ButtonIcon from '@/components/aero/buttons/ButtonIcon.vue'
import Icon from '@/components/aero/icon/Icon.vue'
import Menu from 'primevue/menu'
import Toast from '@/components/aero/feedback/Toast.vue'

// Store
import { useCourseStore } from '@/store/modules/course.store'

// Models
import { IChat } from '@/models/newPlatform/challenges/chat'
import { IDeliverFlags } from '@/models/newPlatform/challenges/deliver'

// Services
import { getScheduleReEnableLink } from '@/services/challenges.services'

// Hooks
import { useToast } from 'primevue/usetoast'

// Amplitude
import { trackEvent } from '@/amplitude/actions'
import { ChallengesEvents } from '@/amplitude/constants'

type IStudent = {
  student: { _id?: string; fullName: string; initials?: string; avatar?: string }
  chat?: IChat[]
  deliverId?: string
  expiresAt: number
  finalDeadline?: number
  firstReviewEstimatedAt: number
  hasDeliver: boolean
  hasUnreadMessage: boolean
  hoursLeftToReview: number
  isDeliverExpired: boolean
  unlockedUntil?: number
  isLocked: boolean
  wasApproved: boolean
  wasReviewed: boolean
  wasReviewedOnTime: boolean
  reviewsCount: number
}
type IProject = {
  _id: string
  chat: IChat[]
  flags: IDeliverFlags
  stage: any
  course: string
  module: any
  unlockedUntil: number | null
  locked: boolean
  student: string
}
type IChallenge = {
  _id: string
  title: string
  class?: string
  project?: IProject
  expiration: number
  finalDeadline?: number
  studentId: string
}

export default defineComponent({
  components: {
    Card,
    Avatar,
    Dots,
    ButtonIcon,
    Menu,
    Notification,
    Icon,
    Toast
  },
  props: {
    student: {
      type: Object as PropType<IStudent>,
      required: false
    },
    challenge: {
      type: Object as PropType<IChallenge>,
      required: false
    },
    variant: {
      type: String as PropType<'student' | 'challenge'>,
      default: 'student',
      required: false
    },
    scheduleId: { type: String, required: false },
    isActive: {
      type: Boolean,
      default: false
    },
    hasUnreadMessage: { type: Boolean, default: false },
    DOMId: { type: String, required: true }
  },
  emits: ['onClickCard', 'markAsUnread', 'lockDeliver', 'unlockDeliver'],
  setup(props, { emit }) {
    const toast = useToast()
    const { id: courseId } = useCourseStore()

    const toggle = (event: Event) => {
      event.preventDefault()
      if (!menuItems.value.length) return
      menu.value.toggle(event)
    }

    const studentId = (props.student && props.student.student._id) || props.challenge?.studentId
    const deliverId = props.challenge?.project?._id || props.student?.deliverId
    const chat = props.challenge?.project?.chat || props.student?.chat

    const challengeUnlockedUntil = props.challenge?.project?.unlockedUntil
      ? typeof props.challenge?.project?.unlockedUntil === 'string'
        ? DateTime.fromISO(props.challenge?.project?.unlockedUntil).toMillis()
        : props.challenge?.project?.unlockedUntil
      : null

    const menu = ref()
    const menuItems = computed(() => {
      const items: { label: string; command: () => void }[] = []

      if (
        (props.challenge?.project?._id &&
          props.challenge.project.chat?.slice(-1)[0]?.flags.fromStudent &&
          props.challenge.project.chat.slice(-1)[0].viewed) ||
        (props.student?.deliverId && props.student?.chat?.slice(-1)[0]?.flags.fromStudent && props.student.chat.slice(-1)[0].viewed)
      ) {
        items.push({
          label: extraActions.markAsUnreadLabel,
          command: () => {
            emit('markAsUnread', deliverId, chat, studentId)
          }
        })
      } else {
        // Remove mark as unread option if the message is already unread
        items.pop()
      }
      // ACTION UNLOCK EXPIRED DELIVER
      // TODO: Change expiresAt for final-deadline
      if (
        // challengesByStudent
        (props.challenge?.finalDeadline &&
          props.challenge.finalDeadline < Date.now() &&
          challengeUnlockedUntil === null &&
          deliverId &&
          !props.challenge.project?.flags.wasApproved) ||
        (challengeUnlockedUntil && challengeUnlockedUntil < Date.now() && deliverId) ||
        // challengesByModule
        (props.student?.unlockedUntil && props.student?.unlockedUntil < Date.now() && deliverId) ||
        (props.student?.unlockedUntil === null &&
          (props.student?.finalDeadline || 0) < Date.now() &&
          deliverId &&
          !props.student.wasApproved)
      ) {
        items.push({
          label: extraActions.markAsUnlockedLabel,
          command: () => {
            trackEvent(ChallengesEvents.ENABLE_CHALLENGE_RE_SUBMISSION)
            emit('unlockDeliver', deliverId, studentId)
          }
        })
      } else if (
        // ACTION BLOCK DELIVER

        // challengesByStudent
        (props.challenge?.finalDeadline &&
          props.challenge?.finalDeadline > Date.now() &&
          challengeUnlockedUntil === null &&
          deliverId &&
          props.challenge?.project?.flags?.reviewsCount === 2 &&
          props.challenge?.project?.chat.slice(-1)[0]?.flags.fromStudent &&
          !props.challenge?.project.locked) ||
        (challengeUnlockedUntil &&
          challengeUnlockedUntil > Date.now() &&
          deliverId &&
          props.challenge?.project?.flags?.reviewsCount === 2 &&
          props.challenge?.project?.chat.slice(-1)[0]?.flags.fromStudent &&
          !props.challenge?.project.locked) ||
        // challengesByModule
        ((props.student?.finalDeadline || 0) > Date.now() &&
          props.student?.unlockedUntil === null &&
          deliverId &&
          props.student?.reviewsCount === 2 &&
          props.student?.chat?.slice(-1)[0]?.flags.fromStudent &&
          !props.student?.isLocked) ||
        (props.student?.unlockedUntil &&
          props.student?.unlockedUntil > Date.now() &&
          deliverId &&
          props.student?.reviewsCount === 2 &&
          props.student?.chat?.slice(-1)[0]?.flags.fromStudent &&
          !props.student?.isLocked)
      ) {
        items.push({
          label: extraActions.markAsLockedLabel,
          command: () => {
            trackEvent(ChallengesEvents.BLOCK_CHALLENGE)
            emit('lockDeliver', deliverId, studentId)
          }
        })
      }

      if ((props.scheduleId || props.challenge?._id) && studentId) {
        const handlegetReEnableLink = async (flag: 'deliver' | 'feedback') => {
          try {
            trackEvent(ChallengesEvents.ENABLE_EXPIRED_CHALLENGE)

            const userId = `${studentId || props.challenge?.project?.student}`
            const scheduleId = `${props.scheduleId || props.challenge?._id}`

            const link = await getScheduleReEnableLink(userId, courseId, scheduleId, flag)
            await navigator.clipboard.writeText(link)

            toast.add({
              severity: 'success',
              detail: extraActions.requestEnableSuccess,
              group: 'bc',
              life: 4000
            })
          } catch {
            toast.add({
              severity: 'error',
              detail: extraActions.requestEnableError,
              group: 'bc',
              life: 5000
            })
          }
        }

        items.push({
          label: extraActions.enableDeliver,
          command: () => handlegetReEnableLink('deliver')
        })
      }

      return items
    })

    return {
      classPrefix,
      menu,
      toggle,
      menuItems
    }
  }
})
