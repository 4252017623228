export interface Profile {
  // eslint-disable-next-line camelcase
  first_name: string
  // eslint-disable-next-line camelcase
  last_name: string
  avatar: string
}

export interface Roles {
  student: boolean
  teacher: boolean
  recruiter: boolean
  free: boolean
  corporate: boolean
  trainingManager: boolean
  supervisor: boolean
  assistant: boolean
  coordinator: boolean
  influencer: boolean
}

export interface LeadTeacherFlags {
  isTeacher: boolean
  isStudent: boolean
  isTutor: boolean
  isInfluencer: boolean
  isCoordinator: boolean
  canBeTutor: boolean
  canBeCoordinator: boolean
  normalized: boolean
}

export interface FlagsClass {
  isNow: boolean
  isToday: boolean
  isTomorrow: boolean
  index: number
  isFirst: boolean
  isAdditional: boolean
  isEnableByProgress: boolean
  showStudentFeedbackFormV6V4: boolean
  showStudentFeedbackFormV6V5: boolean
  showStudentFeedbackFormV6V6: boolean
  showStudentFeedbackFormV7: boolean
  hasWeeklyFeedbackV2: boolean
  hasWeeklyFeedbackV3: boolean
  isPast?: boolean
  isNext: boolean
  isIncoming?: boolean
}

export interface StageFlags {
  isStarter: boolean
  isLeveling: boolean
  isFree: boolean
  isWorkshop: boolean
  isPlaybook: boolean
  normalized: boolean
}

export interface Stage {
  _id: string
  name: string
  flags: StageFlags
}
export interface Schedule {
  additional: boolean
  stage: Stage
  day: number
  from: number
  to: number
  tz: boolean
  _day: number
  index: number
  flags: FlagsClass
  _flags: FlagsClass
}

export interface Teacher {
  _id: string
  profile: Profile
  roles: Roles
  flags: LeadTeacherFlags
  fullName: string
  initials: string
  avatar: string
}
export interface Camada {
  _id: string
  teachers: Teacher[]
  schedules: Schedule[]
  id: number
  studentsSatisfaction: any[]
  leadTeacher: Teacher
}

export interface ICourseFlags {
  isFree: boolean
  isSoldOut: boolean
  isRemoteRecorded: boolean
  isRecoded: boolean
  isPrerecorded: boolean
  isPlaybook: boolean
  isPack: boolean
  isService: boolean
  isRemote: boolean
  isB2B: boolean
  isContainer: boolean
  canAssistantsReviewPreIntegratorTp: boolean
  canAssistantsReviewIntegratorTp: boolean
  hasPreIntegratorTpWithSoftRewiew: boolean
  hasComplementaryTpWithOneReview: boolean
  normalized: boolean
  isStarted: boolean
  isEnded: boolean
  isInProgress: boolean
  hasPostulationsLocked: boolean
  hasTeachersPostulationsLocked: boolean
  needCoordinator: boolean
  needSubstitute: boolean
  forceDailyFeedbacks: boolean
  hasTutoringGroups: boolean
  hasPreIntegratorDeliversInGroup: boolean
  hasFinalIntegratorDeliversInGroup: boolean
  hasCoderPro: boolean
  hasServices: boolean
  hasCoderBeca: boolean
  hasCoderBecaV2: boolean
  isLeadTeacher: boolean
  isAssistantTeacher: boolean
  isSubstituteTeacher: boolean
  isCoordinatorTeacher: boolean
  isTeacher: boolean
  isPaidStudent: boolean
  isFreeStudent: boolean
  isInactiveStudent: boolean
  isReenrollStudent: boolean
  isPendingStudent: boolean
  isUnenrollStudent: boolean
  wasWarned: boolean
  isStudent: boolean
  isBecaInactive: boolean
  isWorkshop: boolean
  isFreeTutorial: boolean
  isPersonalDevelopment: boolean
  isIntensive: boolean
  isPersonalGoal: boolean
  isPro: boolean
  isOffline: boolean
  isRecorded: boolean
  isCertified: boolean
  startsTomorrow: boolean
  endsTomorrow: boolean
  startsToday: boolean
  isHalfToday: boolean
  endsToday: boolean
  endsInLessThanAWeek: boolean
  isPlanned: boolean
  hasAttendanceEnable: boolean
  hasDeliversEnable: boolean
  hasFinalDeliverEnable: boolean
  isRatingEnable: boolean
  deliversCanExpire: boolean
  deliversCanBeLocked: boolean
  allDeliversExpire: boolean
  deliversExpirationDays: number
  hasComplementaryDelivers: boolean
  hasAdditionalScoreForApprovedOnFirstTry: boolean
  hasDeliverFinalExpirationDate: boolean
  canReEnableDelivers: boolean
  canChangeDate: boolean
  canReenroll: boolean
  canUnenroll: boolean
  canTeacherUnenroll: boolean
  canRequestAdditionalSchedule: boolean
  hasPowerBiAccount: boolean
  canRequestToBePartnerWith: boolean
  canRequestToChangePartnerWith: boolean
  isLeaderboardEnable: boolean
  hasLeaderboardV1Perks: boolean
  hasLeaderboardV2: boolean
  hasLeaderboardV3: boolean
  hasLeaderboardV4: boolean
  hasLeaderboardV5: boolean
  hasLeaderboardV6: boolean
  hasLeaderboardV7: boolean
  leaderboardClosedByTeacherAt: boolean
  leaderboardNotificationSentAt: boolean
  leaderboardEndDate: number
  isLeaderboardClosed: boolean
  leaderboardPerksStartDate: number
  leaderboardPerksEndDate: number
  leaderboardPerksLocked: boolean
  isLeaderboardEnded: boolean
  isComplementarySchedulesEnable: boolean
  hasWeeklyFeedback: boolean
  hasCsatFeedback: boolean
  hasComplementarySchedulesV2: boolean
  hasCalendar: boolean
  hasTeacherCheckIn: boolean
  showProgramDates: boolean
  showProgramFeedbacks: boolean
  showProgramDelivers: boolean
  showProgramFinalDeliver: boolean
  showAttendanceProgress: boolean
  showDeliversProgress: boolean
  showCertificationProgress: boolean
  showClassmates: boolean
  showTeachers: boolean
  showTutorialsBanner: boolean
  showChat: boolean
  showEmbedLiveMeeting: boolean
  showStudentFeedbackFormV6V4: boolean
  showStudentFeedbackFormV6V5: boolean
  showStudentFeedbackFormV6V6: boolean
  showStudentFeedbackFormV7: boolean
  isCoderConf: boolean
  hasTutorCommunityActive: boolean
  hasCoderAskActive: boolean
  tutorCommunityUser: string
  coderAskTutorUser: string
  showSharedFolder: boolean
  hasWeeklyFeedbackV2: boolean
  hasWeeklyFeedbackV3: boolean
  showBecaRequirements: boolean
  intensive: boolean
  hasCertification: boolean
  hasCoderAsk: boolean
}

export interface FbPixel {
  sku: string
}
export interface Detail {
  teacherWorkHours: number
  hourlyLoadAlias: string
  classesPerWeek: number
  classDurationInHours: number
}

export interface Level {
  _id: string
  workshop: boolean
  free: boolean
  pack: boolean
  service: boolean
  title: string
  url: string
  prerecorded: boolean
  playbook: boolean
  offline: boolean
  remote: boolean
  earlybird: boolean
  flags: { [key: string]: boolean }
  detail: Detail
  titleShortAlias: string
  titleLongAlias: string
  fbPixel: FbPixel
  iconName: null
}

export interface Normalized {
  days: any[]
  daysString: boolean
  shortDaysString: boolean
  hours: boolean
  daysAndHours: boolean
  startDate: string
  endDate: string
}

export interface VacancyFlags {
  high: boolean
  low: boolean
  medium: boolean
}

export interface First {
  max: number
  enrolled: number
  remaining: number
}
export interface Earlybird {
  first: First
  second: First
}
export interface Vacancy {
  capacity: number
  completed: number
  left: number
  completedPerc: number
  daysLeft: number
  hoursLeft: number
  enrolled: number
  earlybird: Earlybird
  flags: VacancyFlags
}

export interface ICourse {
  _id: string
  remote: boolean
  playbook: boolean
  recorded: boolean
  remoteRecorded: boolean
  corporate: boolean
  container: boolean
  forceDailyFeedbacks: boolean
  useStreamChat: boolean
  days: string[]
  country: string
  startDate: string
  endDate: string
  startHour: string
  endHour: string
  level: Level
  camada: Camada
  flags: ICourseFlags
  normalized: Normalized
  vacancy: Vacancy
  startsIn: number
  weeks: number
  months: number
  classes: number
  _days: number[]
  shortDays: string[]
  hours: string
  daysAndHours: string
  tz: boolean
  modality: string
}

export class Course {
  name: string
  camadaId: string
  id: string
  camadaNro: number
  sharedFolderLink: string
  teachersSharedFolderLink: string
  isEnded: boolean
  isInProgress: boolean
  isStarted: boolean
  hasCoderBeca: boolean
  startDate: string | number
  endDate: string | number
  flags: ICourseFlags
  constructor(
    name: string,
    camadaId: string,
    id: string,
    camadaNro: number,
    sharedFolderLink: string,
    teachersSharedFolderLink: string,
    isEnded: boolean,
    isInProgress: boolean,
    isStarted: boolean,
    hasCoderBeca: boolean,
    startDate: string | number,
    endDate: string | number,
    flags: ICourseFlags
  ) {
    this.name = name
    this.camadaId = camadaId
    this.id = id
    this.camadaNro = camadaNro
    this.sharedFolderLink = sharedFolderLink
    this.teachersSharedFolderLink = teachersSharedFolderLink
    this.isEnded = isEnded
    this.isInProgress = isInProgress
    this.isStarted = isStarted
    this.hasCoderBeca = hasCoderBeca
    this.startDate = startDate
    this.endDate = endDate
    this.flags = flags
  }
}

export type course = {
  name: string
  camadaId: string
  id: string
  camadaNro: number
  sharedFolderLink: string
  teachersSharedFolderLink: string
  isEnded: boolean
  isInProgress: boolean
  isStarted: boolean
  hasCoderBeca: boolean
}

export interface ICourseData {
  name: string
  id: string
  camadaNro: number
  isStarted: boolean
  isInProgress: boolean
  isEnded: boolean
  inProcess: boolean
  startDate: number
  EndDate: number
}
export class CourseData {
  name: string
  id: string
  camadaNro: number
  isStarted: boolean
  isInProgress: boolean
  isEnded: boolean
  inProcess: boolean
  startDate: number
  endDate: number
  constructor(from: ICourseData) {
    this.name = from.name
    this.id = from.id
    this.camadaNro = from.camadaNro
    this.isStarted = from.isStarted
    this.isInProgress = from.isInProgress
    this.isEnded = from.isEnded
    this.inProcess = from.inProcess
    this.startDate = from.startDate
    this.endDate = from.EndDate
  }
}
