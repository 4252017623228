
import { Component, defineComponent, onMounted, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import CircleCheck from '@/components/commons/icon-templates/CircleCheck.vue'
import ClockCounter from '@/components/commons/icon-templates/ClockCounter.vue'
import Lock from '@/components/commons/icon-templates/Lock.vue'
import CorrectionCard from '@/views/peerReview/components/correction/CorrectionCard.vue'
import { CorrectionStates, ChallengeHistoryStates } from '@/utils/constants'
import { IReview } from '@/models/peer-review/homeworkPlan'
import { getMyReviews, getRandomDelivery } from '@/api/challenge.api'
import IconBase from '@/components/commons/IconBase.vue'
import Ellipse from '@/components/commons/icon-templates/Ellipse.vue'
import Line from '@/components/commons/Line.vue'
import { useI18n } from 'vue-i18n'
import MessageBox from '@/views/peerReview/components/MessageBox.vue'
import { useToast } from 'primevue/usetoast'

export default defineComponent({
  emits: ['select'],
  components: {
    CorrectionCard,
    IconBase,
    Ellipse,
    Line,
    MessageBox
  },
  setup(props, { emit }) {
    const { t } = useI18n()
    const loading = ref<boolean>(true)
    const router = useRouter()
    const courseId = router.currentRoute.value.params.courseId
    const homeworkPlanId = router.currentRoute.value.params.homeworkPlanId
    const homeworkId = router.currentRoute.value.params.homeworkId
    const deliveryId = ref<string>()
    const challengeState = ChallengeHistoryStates.SENT
    const state = reactive({
      corrections: Array<{ id: string; status: string; icon: Component }>()
    })
    const toast = useToast()

    onMounted(async () => {
      const response = await getMyReviews(homeworkPlanId as string, homeworkId as string)
      response?.reviews.forEach((review: IReview) => {
        state.corrections.push({ id: review.id, status: CorrectionStates.DELIVERED, icon: CircleCheck })
      })
      const random = await getRandomDelivery(courseId as string, homeworkPlanId as string, homeworkId as string)
      deliveryId.value = random?.id
      if (random) state.corrections.push({ id: '1', status: CorrectionStates.READY_FOR_REVIEW, icon: ClockCounter })
      while (state.corrections.length < 3) {
        state.corrections.push({ id: (state.corrections.length + 1).toString(), status: CorrectionStates.BLOCKED, icon: Lock })
      }
      loading.value = false

      if (state.corrections.every(isBlocked)) {
        toast.add({ severity: 'info', detail: t('homework.challenge.upload.no.peerReview.to.do'), life: 3000 })
      } else if (random && response?.reviews.length <= 0) {
        toast.add({ severity: 'info', detail: t('homework.challenge.upload.no.make.peerReview'), life: 3000
        })
      } else if (!random && response?.reviews.length >= 1) {
        toast.add({ severity: 'info', detail: t('homework.challenge.upload.no.more.peerReviews'), life: 3000
        })
      }
    })
    const goToCorrection = () => emit('select', deliveryId.value)

    const isBlocked = (value: { id: string; status: string; icon: Component }): boolean => {
      return value.status === CorrectionStates.BLOCKED
    }

    return { t, state, CorrectionStates, challengeState, ChallengeHistoryStates, loading, goToCorrection, isBlocked, ClockCounter }
  }
})
