<template>
  <div class="p-d-flex p-ai-center p-jc-between p-mb-2">
    <skeleton-text :length="10" />
    <skeleton-text :length="5" />
  </div>
  <card :class="studentView && 'p-mb-3'">
    <div class="p-d-flex p-ai-center p-jc-between p-mb-4">
      <skeleton-text :length="10" class="p-mb-1" />
      <div class="p-d-flex p-ai-center p-ml-auto">
        <skeleton-icon class="p-ml-4" v-for="i in 4" :key="i" />
      </div>
    </div>
    <div class="p-d-flex p-ai-center p-jc-between">
      <skeleton-text :maxWords="2" class="p-mb-1" />
      <skeleton-icon />
    </div>
  </card>
</template>

<script>
import Card from '../surfaces/Card.vue'
import SkeletonText from './SkeletonText.vue'
import SkeletonIcon from './SkeletonIcon.vue'
export default {
  components: { Card, SkeletonText, SkeletonIcon },
  props: {
    studentView: { type: Boolean, required: false }
  }
}
</script>
