import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c0492f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = {
  key: 2,
  class: "p3 p-mt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_error = _resolveComponent("input-error")!

  return (_openBlock(), _createElementBlock("fieldset", {
    class: _normalizeClass(["p-field", !!_ctx.label && 'extra-padding'])
  }, [
    _renderSlot(_ctx.$slots, "default", { class: "wrapper-inner" }, undefined, true),
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.inputId,
          class: _normalizeClass(["p3", !!_ctx.error && 'error'])
        }, _toDisplayString(_ctx.label), 11, _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.error)
      ? (_openBlock(), _createBlock(_component_input_error, {
          key: 1,
          error: _ctx.error
        }, null, 8, ["error"]))
      : (_ctx.hint)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.hint), 1))
        : _createCommentVNode("", true)
  ], 2))
}