import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5fd5350c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sideBar" }
const _hoisted_2 = { class: "ratingChart" }
const _hoisted_3 = {
  key: 0,
  class: "p1 mostVoted p-mb-2"
}
const _hoisted_4 = { class: "t2 p-mt-3 p-mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorPage = _resolveComponent("ErrorPage")!
  const _component_SkeletonRatingCard = _resolveComponent("SkeletonRatingCard")!
  const _component_SkeletonDropdownRatings = _resolveComponent("SkeletonDropdownRatings")!
  const _component_SkeletonPointsCard = _resolveComponent("SkeletonPointsCard")!
  const _component_RatingCard = _resolveComponent("RatingCard")!
  const _component_UpToDatePlaceHolder = _resolveComponent("UpToDatePlaceHolder")!
  const _component_SelectInput = _resolveComponent("SelectInput")!
  const _component_Card = _resolveComponent("Card")!
  const _component_RatingChart = _resolveComponent("RatingChart")!
  const _component_MostVotedTag = _resolveComponent("MostVotedTag")!
  const _component_SkeletonComments = _resolveComponent("SkeletonComments")!
  const _component_PlaceholderState = _resolveComponent("PlaceholderState")!
  const _component_Commentaries = _resolveComponent("Commentaries")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_SkeletonOpinionTag = _resolveComponent("SkeletonOpinionTag")!
  const _component_OpinionTags = _resolveComponent("OpinionTags")!
  const _component_TabView = _resolveComponent("TabView")!
  const _component_two_cols_layout = _resolveComponent("two-cols-layout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isError)
      ? (_openBlock(), _createBlock(_component_ErrorPage, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_two_cols_layout, null, {
      aside: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (_ctx.loading)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_SkeletonRatingCard, { class: "p-mt-3" }),
                _createVNode(_component_SkeletonDropdownRatings),
                _createVNode(_component_SkeletonPointsCard)
              ], 64))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (!!_ctx.rating)
                  ? (_openBlock(), _createBlock(_component_RatingCard, {
                      key: 0,
                      class: "p-mt-3",
                      rating: _ctx.rating.score,
                      color: _ctx.rating.score >= 4 ? 'success' : _ctx.rating.score < 3 ? 'error' : 'warning',
                      text: _ctx.t('rating.sidebar.myAverageRating'),
                      withBorder: ""
                    }, null, 8, ["rating", "color", "text"]))
                  : (_openBlock(), _createBlock(_component_UpToDatePlaceHolder, {
                      key: 1,
                      text: _ctx.t('rating.sidebar.courseHasNotStarted')
                    }, null, 8, ["text"])),
                (_ctx.rateOptions.length)
                  ? (_openBlock(), _createBlock(_component_Card, { key: 2 }, {
                      default: _withCtx(() => [
                        _createElementVNode("h2", null, _toDisplayString(_ctx.t('rating.sidebar.detailsByClass')), 1),
                        _createVNode(_component_SelectInput, {
                          name: "setRateOption",
                          onSetRateOption: _ctx.setRateOption,
                          class: "p-mt-3",
                          options: _ctx.rateOptions,
                          valueSuggested: _ctx.currRateOption
                        }, null, 8, ["onSetRateOption", "options", "valueSuggested"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (!!_ctx.rating)
                  ? (_openBlock(), _createBlock(_component_Card, { key: 3 }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, [
                          (_openBlock(), _createBlock(_component_RatingChart, {
                            positives: _ctx.ratingPercentage('positive'),
                            neutrals: _ctx.ratingPercentage('neutral'),
                            negatives: _ctx.ratingPercentage('negative'),
                            defaultFontSize: 14,
                            key: _ctx.currRateOption
                          }, null, 8, ["positives", "neutrals", "negatives"]))
                        ]),
                        (_ctx.tagPositive || _ctx.tagNeutral)
                          ? (_openBlock(), _createElementBlock("h2", _hoisted_3, _toDisplayString(_ctx.t('rating.sidebar.mostVotedTags')), 1))
                          : _createCommentVNode("", true),
                        (_ctx.tagPositive)
                          ? (_openBlock(), _createBlock(_component_MostVotedTag, {
                              feedbackType: "positive",
                              tagDescription: _ctx.tagPositive.mostVoted,
                              key: _ctx.currRateOption
                            }, null, 8, ["tagDescription"]))
                          : _createCommentVNode("", true),
                        (_ctx.tagNeutral)
                          ? (_openBlock(), _createBlock(_component_MostVotedTag, {
                              feedbackType: "suggestion",
                              tagDescription: _ctx.tagNeutral.mostVoted,
                              key: _ctx.currRateOption
                            }, null, 8, ["tagDescription"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ], 64))
        ])
      ]),
      content: _withCtx(() => [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.t('rating.studentsOpinion')), 1),
        _createVNode(_component_TabView, null, {
          default: _withCtx(() => [
            _createVNode(_component_TabPanel, {
              header: _ctx.t('rating.commentaries.title')
            }, {
              default: _withCtx(() => [
                (_ctx.loading)
                  ? (_openBlock(), _createBlock(_component_SkeletonComments, { key: 0 }))
                  : (!_ctx.comments.length)
                    ? (_openBlock(), _createBlock(_component_PlaceholderState, {
                        key: 1,
                        customCopies: {
              line1: _ctx.hasCourseStarted ? _ctx.t('rating.commentaries.emptyState') : _ctx.t('rating.commentaries.courseHasNotStarted')
            },
                        role: 3,
                        isEmpty: ""
                      }, null, 8, ["customCopies"]))
                    : (_openBlock(), _createBlock(_component_Commentaries, {
                        class: "p-mt-3",
                        comments: _ctx.comments,
                        key: _ctx.currRateOption
                      }, null, 8, ["comments"]))
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_TabPanel, {
              header: _ctx.t('rating.opinionTags.title')
            }, {
              default: _withCtx(() => [
                (_ctx.loading)
                  ? (_openBlock(), _createBlock(_component_SkeletonOpinionTag, { key: 0 }))
                  : (!_ctx.tagPositive?.tags?.length && !_ctx.tagNegative?.tags?.length && !_ctx.tagNeutral?.tags?.length)
                    ? (_openBlock(), _createBlock(_component_PlaceholderState, {
                        key: 1,
                        customCopies: {
              line1: _ctx.hasCourseStarted ? _ctx.t('rating.opinionTags.emptyState') : _ctx.t('rating.opinionTags.courseHasNotStarted')
            },
                        role: 3,
                        isEmpty: ""
                      }, null, 8, ["customCopies"]))
                    : (_openBlock(), _createBlock(_component_OpinionTags, {
                        class: "p-mt-3",
                        tagPositive: _ctx.tagPositive,
                        tagNeutral: _ctx.tagNeutral,
                        tagNegative: _ctx.tagNegative,
                        tagTotals: _ctx.tagsTotal,
                        key: _ctx.currRateOption
                      }, null, 8, ["tagPositive", "tagNeutral", "tagNegative", "tagTotals"]))
              ]),
              _: 1
            }, 8, ["header"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}