
import { defineComponent, PropType } from 'vue'

// Components
import ProfileCardSmall from '@/components/aero/dataDisplay/ProfileCardSmall.vue'

// Models
import { TutorClassmate, StudentClassmate } from '@/models/comission/comission'

export default defineComponent({
  components: { ProfileCardSmall },
  props: {
    students: { type: Array as PropType<StudentClassmate[]> },
    tutor: { type: Object as PropType<TutorClassmate> }
  }
})
