
import IconBase from '@/components/commons/IconBase.vue'
import CircleCheck from './icon-templates/CircleCheck.vue'
import WarningCircle from './icon-templates/WarningCircle.vue'
import InfoCircle from './icon-templates/InfoCircle.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: { IconBase, CircleCheck, WarningCircle, InfoCircle }
})
