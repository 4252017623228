import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height", "viewBox", "aria-labelledby"]
const _hoisted_2 = ["stroke", "fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: $props.width,
    height: $props.height,
    viewBox: $props.viewBox,
    "aria-labelledby": $props.iconName,
    fill: "none"
  }, [
    _createElementVNode("g", {
      stroke: $props.strokeColor,
      fill: $props.fillColor
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 8, _hoisted_2)
  ], 8, _hoisted_1))
}