
import { defineComponent, PropType } from 'vue'

// Models
import { CourseCategory } from '@/models/newPlatform/products/products'

export default defineComponent({
  props: {
    title: { type: String, default: '', required: true },
    subtitle: { type: String, default: '', required: true },
    imgCategory: {
      type: String as PropType<CourseCategory>,
      required: true
    }
  },
  setup(props) {
    let courseCategory
    if (props.imgCategory === 'design') courseCategory = 'design'
    if (props.imgCategory === 'development' || props.imgCategory === 'cybersecurity') courseCategory = 'development'
    if (props.imgCategory === 'marketing') courseCategory = 'marketing'
    if (props.imgCategory === 'product') courseCategory = 'product'
    if (props.imgCategory === 'business' || props.imgCategory === 'finance' || props.imgCategory === 'data') courseCategory = 'data'

    return { courseCategory }
  }
})
