<template>
  <div class="rating__container">
    <div class="rating__container-empty">
      <icon v-for="i in 5" :key="i" strokeColor="var(--light-gray)" :size="16" class="p-mr-2">
        <star />
      </icon>
    </div>
    <div class="rating__container-checked">
      <icon v-for="i in 5" :key="i" :size="16" :strokeColor="`var(--${color})`" :fillColor="`var(--${color})`" class="p-mr-2">
        <star />
      </icon>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import Icon from '@/components/aero/icon/Icon.vue'
import { Star } from '@/components/aero/icon/templates'

export default defineComponent({
  components: { Icon, Star },
  props: {
    rating: { type: Number, default: 0 },
    color: { type: String, default: 'success' }
  },

  setup(props) {
    onMounted(() => {
      const ratingStar = document.querySelector('.rating__container-checked')
      ratingStar.style.clipPath = `polygon(0 0%, ${props.rating * 20 - 3}% 0%, ${props.rating * 20 - 3}% 100%, 0% 100%)`
    })
  }
})
</script>

<style lang="scss" scoped>
.rating {
  &__container {
    position: relative;

    &-checked {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
</style>
