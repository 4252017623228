import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-057cc482"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "videotutorials-container"
}
const _hoisted_2 = {
  key: 0,
  class: "videos-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")!
  const _component_Header = _resolveComponent("Header")!
  const _component_Video = _resolveComponent("Video")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_Loader, { key: 0 }))
      : _createCommentVNode("", true),
    (!_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_Header),
          (_ctx.videos.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.videos, (video, index) => {
                  return (_openBlock(), _createBlock(_component_Video, {
                    key: index,
                    title: `${index}. ${video.title}`,
                    url: video.url,
                    height: 360
                  }, null, 8, ["title", "url"]))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_Footer)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}