
import { defineComponent, ref, watch } from 'vue'
import copies from '@/locales/profile/es.json'

export default defineComponent({
  props: { 
    displayModal: { type: Boolean, default: false } 
  },
  setup(props, { emit }) {
    const termsModal: any = copies.editProfile.termsModal
    const TALENT_URL = process.env.CH_TALENT as string
    const modal = ref(false)
		const checked = ref(false)
		const validationError = ref(false)
    
    const closeModal = () => {
      emit('closeModal')
    }

		const publishProfile = () => {
			if(checked.value) {
				emit('publishTalent')
				closeModal()
			} else {
				validationError.value = true
			}
		}

    watch(props, () => {
      modal.value = props.displayModal
    })

    watch(checked, () => {
      validationError.value = !checked.value
    })


    return { closeModal, modal, checked, publishProfile, validationError, TALENT_URL, termsModal }
  }
})
