export interface IReview {
  id: string
  createdAt: string
}

export interface IReviews {
  total: string
  reviews: Array<IReview>
}

export class Reviews {
  total: string
  reviews: Array<IReview>
  constructor (from: IReviews) {
    this.total = from.total
    this.reviews = from.reviews
  }
}

export interface IAttachment {
  id: string
  mimeType: string
}

export interface IHomeworkFile {
  link: string
  attachment: IAttachment
}

export interface ICorrectionDataDto {
  comment: string,
  evaluation: Array<string>,
  grade?: number
}

export interface ICorrection {
  id: string
  status: string
  comment: string
  homework: IHomeworkFile
  createdAt: string
}

export class Correction {
  id: string
  status: string
  comment: string
  homework: IHomeworkFile
  createdAt: string
  constructor (data: ICorrection) {
    this.id = data.id
    this.status = data.status
    this.comment = data.comment
    this.homework = data.homework
    this.createdAt = data.createdAt
  }
}

export interface ICalendar {
  start: string;
  end: string;
}

export interface IEvaluationCriteria {
  _id: string;
  description: string;
  title: string;
  score: string;
}

export interface ICriteria {
  title: string;
  id: string;
  evaluationCriteria: IEvaluationCriteria[];
}

export interface IHomework {
  name: string;
  slug: string;
  published: boolean;
  slideUrl: string;
  type: string;
  criteria: ICriteria[];
  expiresAt: string;
}

export interface IHomeworkPlan {
  name: string;
  slug: string;
  order: number;
  calendar: ICalendar;
  homework: IHomework;
}

export class HomeworkPlan {
  classDateStart: string
  classDateEnd: string
  expirationDate: string
  moduleName: string
  homeworkName: string
  evaluationCriteria: ICriteria[]
  type: string
  slideUrl: string
  classOrder: number
  constructor (from: IHomeworkPlan) {
    this.moduleName = from.name
    this.homeworkName = from.homework.name
    this.classDateStart = from.calendar.start
    this.classDateEnd = from.calendar.end
    this.expirationDate = from.homework.expiresAt
    this.evaluationCriteria = from.homework.criteria
    this.slideUrl =  from.homework.slideUrl
    this.type = from.homework.type
    this.classOrder = from.order
  }
}