import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-16d415fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "classcard__actions" }
const _hoisted_2 = {
  key: 0,
  class: "p-d-flex p-ai-center"
}
const _hoisted_3 = { class: "p-ml-2 p3 text-white" }
const _hoisted_4 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_clock = _resolveComponent("clock")!
  const _component_icon = _resolveComponent("icon")!
  const _component_trending = _resolveComponent("trending")!
  const _component_button_icon = _resolveComponent("button-icon")!
  const _component_star = _resolveComponent("star")!
  const _component_video_icon = _resolveComponent("video-icon")!
  const _component_list_bullets = _resolveComponent("list-bullets")!
  const _component_dots = _resolveComponent("dots")!
  const _component_Menu = _resolveComponent("Menu")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showCountdown)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          _createVNode(_component_icon, { size: "1.175rem" }, {
            default: _withCtx(() => [
              _createVNode(_component_clock)
            ]),
            _: 1
          }),
          _createElementVNode("p", _hoisted_3, "Comienza en " + _toDisplayString(_ctx.beginsIn) + "'", 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.showZoomBtn)
      ? (_openBlock(), _createElementBlock("a", {
          key: 1,
          "on:onClick": _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleTrackEvent(_ctx.ClassesEvent.GO_TO_ZOOM, 'class card'))),
          href: _ctx.data.videoLink.liveVideoLink,
          target: "_blank",
          class: "text-secondary bold"
        }, "Ir a Zoom", 40, _hoisted_4))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          (_ctx.showRatesBtn)
            ? _withDirectives((_openBlock(), _createBlock(_component_button_icon, {
                key: 0,
                asLinkType: "internal",
                onOnClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleTrackEvent(_ctx.CLASSES_EVENT_NAMES.VIEW_CLASS_RATES, 'class card'))),
                href: `/ratings/${_ctx.data.schedule.stage}`,
                color: "var(--secondary)"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_trending)
                ]),
                _: 1
              }, 8, ["href"])), [
                [
                  _directive_tooltip,
                  _ctx.optionsListClasses.rates,
                  void 0,
                  { top: true }
                ]
              ])
            : _createCommentVNode("", true),
          (_ctx.showRateBtn)
            ? _withDirectives((_openBlock(), _createBlock(_component_button_icon, {
                key: 1,
                onOnClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleTrackEvent(_ctx.ClassesEvent.RATE_CLASS, 'class card'))),
                href: _ctx.data.links.feedbackLink,
                asLinkType: "external"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_star)
                ]),
                _: 1
              }, 8, ["href"])), [
                [
                  _directive_tooltip,
                  _ctx.toolTips.rate,
                  void 0,
                  { top: true }
                ]
              ])
            : _createCommentVNode("", true),
          (_ctx.data.isUserAbsent && _ctx.data.videoLink?.recordingVideoLink && _ctx.data.schedule.day < Date.now() && _ctx.role === 2)
            ? _withDirectives((_openBlock(), _createBlock(_component_button_icon, {
                key: 2,
                onOnClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleTrackEvent(_ctx.ClassesEvent.VIEW_RECORDING, 'class card'))),
                href: _ctx.data.videoLink?.recordingVideoLink,
                asLinkType: "external"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_video_icon)
                ]),
                _: 1
              }, 8, ["href"])), [
                [
                  _directive_tooltip,
                  _ctx.toolTips.video,
                  void 0,
                  { top: true }
                ]
              ])
            : _createCommentVNode("", true),
          (_ctx.role === 2 && (_ctx.data.modules?.project?.hasDelivers || _ctx.data.modules?.project?.isFinalDeliver))
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 3 }, _renderList(_ctx.useChallengeBtn('long', _ctx.challenges), (challenge) => {
                return _withDirectives((_openBlock(), _createBlock(_component_button_icon, {
                  key: challenge.id,
                  asLinkType: "internal",
                  href: _ctx.challengeURL(challenge.id,challenge.stage),
                  onOnClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleTrackEvent(_ctx.ClassesEvent.GO_TO_MODULES, 'class card'))),
                  style: _normalizeStyle(_ctx.data.schedule.day > Date.now() ? 'display: none' : ''),
                  class: _normalizeClass(challenge.isDeliverExpired ? 'p-d-none' : '')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_list_bullets)
                  ]),
                  _: 2
                }, 1032, ["href", "style", "class"])), [
                  [
                    _directive_tooltip,
                    challenge.label,
                    void 0,
                    { top: true }
                  ]
                ])
              }), 128))
            : _createCommentVNode("", true)
        ], 64)),
    (!_ctx.becaSuspended)
      ? (_openBlock(), _createBlock(_component_button_icon, {
          key: 3,
          onClick: _ctx.toggle,
          href: "#options",
          "aria-haspopup": "true",
          "aria-controls": "overlay_menu",
          color: 'var(--light-gray)'
        }, {
          default: _withCtx(() => [
            _createVNode(_component_dots)
          ]),
          _: 1
        }, 8, ["onClick", "color"]))
      : _createCommentVNode("", true),
    _createVNode(_component_Menu, {
      id: "overlay_menu",
      ref: "menu",
      model: _ctx.items,
      popup: true
    }, null, 8, ["model"])
  ]))
}