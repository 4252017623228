import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2a908a86"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "circled-progress p-d-flex p-ai-center p-jc-center" }
const _hoisted_2 = ["height", "width"]
const _hoisted_3 = ["stroke", "stroke-dasharray", "stroke-width", "r", "cx", "cy"]
const _hoisted_4 = { class: "p2" }
const _hoisted_5 = {
  key: 0,
  class: "p3 p-d-block regular text-white"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("figure", _hoisted_1, [
    (_openBlock(), _createElementBlock("svg", {
      height: _ctx.radius * 2,
      width: _ctx.radius * 2
    }, [
      _createElementVNode("circle", {
        class: "progress__circle",
        stroke: `var(--${_ctx.color})`,
        fill: "transparent",
        "stroke-dasharray": _ctx.circumference + ' ' + _ctx.circumference,
        style: _normalizeStyle({ strokeDashoffset: _ctx.strokeDashoffset }),
        "stroke-width": _ctx.stroke,
        r: _ctx.normalizedRadius,
        cx: _ctx.radius,
        cy: _ctx.radius
      }, null, 12, _hoisted_3)
    ], 8, _hoisted_2)),
    _createElementVNode("figcaption", {
      class: _normalizeClass(`t1 bold text-${_ctx.color} p-text-center`)
    }, [
      _createTextVNode(_toDisplayString(_ctx.progress), 1),
      _withDirectives(_createElementVNode("span", _hoisted_4, "%", 512), [
        [_vShow, _ctx.variant === 'percentage']
      ]),
      (_ctx.total)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, "/" + _toDisplayString(_ctx.total) + "pt", 1))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}