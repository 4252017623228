import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fddc2a78"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "challenge" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Button, {
      class: "p-button-outlined custom-button",
      onClick: _ctx.showCriteria,
      label: _ctx.t('homework.challenge.criteria.title')
    }, null, 8, ["onClick", "label"])
  ]))
}