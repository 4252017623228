import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2dc4968e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container__review" }
const _hoisted_2 = {
  key: 1,
  class: "p-d-flex p-jc-end p-ai-center p-mt-3"
}
const _hoisted_3 = {
  key: 2,
  class: "p-d-flex p-jc-end p-ai-center p-mt-3"
}
const _hoisted_4 = {
  key: 3,
  class: "p-d-flex p-jc-end p-ai-center p-mt-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_rich_text_editor = _resolveComponent("rich-text-editor")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isTutor || (!_ctx.isTutor && _ctx.isIntegrator))
      ? (_openBlock(), _createBlock(_component_rich_text_editor, {
          key: 0,
          placeholder: _ctx.copies.richText.placeholder,
          onOnChange: _ctx.handleTextEditor
        }, null, 8, ["placeholder", "onOnChange"]))
      : _createCommentVNode("", true),
    (_ctx.isTutor && _ctx.isPreIntegrator)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_select_input, {
            name: "softScoreSelected",
            onSoftScoreSelected: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectedOption = $event)),
            class: "p-mr-3",
            options: _ctx.softScoreOptions,
            placeholder: _ctx.copies.select.placeholder
          }, null, 8, ["options", "placeholder"]),
          _createVNode(_component_Button, {
            label: _ctx.copies.cta.send,
            onClick: _ctx.handlePreIntegrator,
            loading: _ctx.isLoading,
            disabled: !_ctx.selectedOption
          }, null, 8, ["label", "onClick", "loading", "disabled"])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.isTutor && _ctx.isIntegrator)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_select_input, {
            name: "finalScoreSelected",
            onFinalScoreSelected: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectedOption = $event)),
            class: "p-mr-3",
            options: _ctx.finalScoreOptions,
            placeholder: _ctx.copies.select.placeholder
          }, null, 8, ["options", "placeholder"]),
          _createVNode(_component_Button, {
            label: _ctx.copies.cta.send,
            onClick: _ctx.handleIntegratorTp,
            loading: _ctx.isLoading,
            disabled: !_ctx.selectedOption
          }, null, 8, ["label", "onClick", "loading", "disabled"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isTutor && !_ctx.isPreIntegrator && !_ctx.isIntegrator)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_Button, {
            class: "secondary p-mr-3",
            label: _ctx.copies.cta.deliverAgain,
            onClick: _ctx.handleDeliverAgain,
            loading: _ctx.isDeliverAgainPending,
            disabled: _ctx.isLoading
          }, null, 8, ["label", "onClick", "loading", "disabled"]),
          _createVNode(_component_Button, {
            label: _ctx.copies.cta.approve,
            onClick: _ctx.handleApprovedTp,
            loading: _ctx.isLoading,
            disabled: _ctx.isDeliverAgainPending
          }, null, 8, ["label", "onClick", "loading", "disabled"])
        ]))
      : _createCommentVNode("", true)
  ]))
}