// Vendor
import { defineStore } from 'pinia'

// API
import { getUserById } from '@/api/user'

// Models
import { IUser } from '@/models/user'
import { UserSessionState } from '@/models/user-session'

import { useUserSessionStore } from './user-session.store'

const userStateDefault = {
  id: '',
  role: 2,
  roles: [],
  level: '',
  email: '',
  avatar: '',
  firstName: '',
  lastName: '',
  fullName: '',
  dni: '',
  isTutor: false,
  hasBeca: false,
  country: 'ar',
  cityV2: '',
  isNotFromArg: false,
  phone: '',
  getStreamToken: '',
  subscription: [],
  preferencesFlag: false,
  coderPlus: false
}

export const useUserStore = defineStore({
  id: 'user-store',
  state() {
    const user: IUser = userStateDefault

    return user
  },
  actions: {
    async getUser() {
      const userSession: UserSessionState = useUserSessionStore()
      const user = await getUserById(userSession?.userId) // for develep purpouse

      this.id = user.id
      this.role = user.role
      this.level = user.level
      this.roles = user.roles
      this.email = user.email
      this.avatar = user.avatar
      this.firstName = user.firstName
      this.lastName = user.lastName
      this.fullName = user.fullName
      this.isTutor = user.isTutor
      this.hasBeca = user.hasBeca
      this.dni = user.dni
      this.country = user.country
      this.cityV2 = user.cityV2
      this.isNotFromArg = user.isNotFromArg
      this.phone = user.phone
      this.getStreamToken = user.getStreamToken
      this.subscription = user.subscription
      this.preferencesFlag = user.preferencesFlag
      this.coderPlus = user.coderPlus
    },
    updateAvatar(url: string) {
      this.avatar = url
    }
  }
})
