<template>
  <card class="p-d-flex p-ai-center p-flex-wrap" paddingSm>
    <div class="p-pr-5">
      <skeleton-text :length="10" class="p-pb-2" />
      <skeleton-text :length="6" sm />
    </div>
    <skeleton-text :maxWords="2" class="p-mr-4" />
    <div class="p-d-flex p-ai-center p-ml-auto">
      <skeleton-icon class="p-ml-4" />
    </div>
  </card>
</template>

<script>
import Card from '../surfaces/Card.vue'
import SkeletonIcon from './SkeletonIcon.vue'
import SkeletonText from './SkeletonText.vue'
export default {
  components: { Card, SkeletonIcon, SkeletonText }
}
</script>
