<template>
  <g>
    <path d="M8 8L14 2" stroke="#BEBEBE" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M12.2425 3.75737C11.1797 2.69663 9.75745 2.07344 8.25714 2.01109C6.75684 1.94875 5.28778 2.45178 4.1406 3.42068C2.99342 4.38958 2.25168 5.75377 2.06213 7.24335C1.87257 8.73294 2.24902 10.2394 3.11698 11.4647C3.98494 12.6901 5.28119 13.545 6.74929 13.8604C8.2174 14.1758 9.7504 13.9287 11.045 13.1679C12.3397 12.4072 13.3016 11.1882 13.7405 9.75219C14.1795 8.31618 14.0635 6.76773 13.4154 5.41317"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.1214 5.87867C9.62542 5.38272 8.97133 5.07623 8.27283 5.01248C7.57433 4.94873 6.87558 5.13175 6.29801 5.52973C5.72045 5.92771 5.30059 6.51548 5.11142 7.19089C4.92224 7.8663 4.97571 8.58665 5.26253 9.22673C5.54935 9.86681 6.05138 10.3862 6.68137 10.6945C7.31137 11.0028 8.02949 11.0807 8.71092 10.9145C9.39235 10.7483 9.99402 10.3486 10.4113 9.78489C10.8287 9.22114 11.0353 8.52899 10.9952 7.82873"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </g>
</template>
