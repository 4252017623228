import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, renderList as _renderList } from "vue"

const _hoisted_1 = { class: "t2 p-mb-4" }
const _hoisted_2 = { class: "t2 p-mb-2" }
const _hoisted_3 = { class: "p2 p-ml-3 medium text-white" }
const _hoisted_4 = { class: "p-d-flex p-ai-center p-jc-start" }
const _hoisted_5 = { class: "p2 medium text-white p-ml-3" }
const _hoisted_6 = { class: "text-secondary" }
const _hoisted_7 = { class: "p2 medium text-white" }
const _hoisted_8 = { class: "text-secondary" }
const _hoisted_9 = { style: {"position":"relative"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_skeleton_box = _resolveComponent("skeleton-box")!
  const _component_ranking_card = _resolveComponent("ranking-card")!
  const _component_card = _resolveComponent("card")!
  const _component_clock = _resolveComponent("clock")!
  const _component_icon = _resolveComponent("icon")!
  const _component_trophy = _resolveComponent("trophy")!
  const _component_user_row = _resolveComponent("user-row")!
  const _component_two_cols_layout = _resolveComponent("two-cols-layout")!

  return (_openBlock(), _createBlock(_component_two_cols_layout, null, {
    aside: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.copies.asideTitle), 1),
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_skeleton_box, {
            key: 0,
            height: '6rem'
          }))
        : (_openBlock(), _createBlock(_component_card, {
            key: 1,
            class: "p-p-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ranking_card, {
                total: _ctx.currentUser.progress?.totalLeaderboard?.amount,
                progress: _ctx.currentUser.progress?.totalLeaderboard?.completed,
                ranking: `${_ctx.copies.position} #${_ctx.currentUser.rank} ${_ctx.copies.ofTheRank}`
              }, null, 8, ["total", "progress", "ranking"])
            ]),
            _: 1
          }))
    ]),
    content: _withCtx(() => [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.copies.contentTitle), 1),
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_skeleton_box, {
              class: "p-mb-2",
              height: '4.5rem'
            }),
            _createVNode(_component_skeleton_box, { height: '3.5rem' }),
            _createVNode(_component_skeleton_box, { height: '3.5rem' }),
            _createVNode(_component_skeleton_box, { height: '3.5rem' })
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (!_ctx.isStarted && !_ctx.isEnded && !_ctx.isInProgress)
              ? (_openBlock(), _createBlock(_component_card, {
                  key: 0,
                  class: "p-mb-2 p-d-flex p-ai-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_icon, { strokeColor: 'var(--light-gray)' }, {
                      default: _withCtx(() => [
                        _createVNode(_component_clock)
                      ]),
                      _: 1
                    }, 8, ["strokeColor"]),
                    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.copies.beforeStarting), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.isEnded)
              ? (_openBlock(), _createBlock(_component_card, {
                  key: 1,
                  class: "p-mb-2 p-d-flex p-ai-center p-jc-between"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_icon, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_trophy)
                        ]),
                        _: 1
                      }),
                      _createElementVNode("p", _hoisted_5, [
                        _createTextVNode(_toDisplayString(_ctx.copies.finalRanking) + " ", 1),
                        _createElementVNode("span", _hoisted_6, "#" + _toDisplayString(_ctx.currentUser.rank) + "!", 1)
                      ])
                    ]),
                    _createElementVNode("p", _hoisted_7, [
                      _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.currentUser.progress?.totalLeaderboard?.completed) + "pts", 1)
                    ])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createElementVNode("ol", _hoisted_9, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ranking, (user) => {
                return (_openBlock(), _createBlock(_component_user_row, {
                  key: user._id,
                  totalRows: _ctx.ranking.length,
                  name: user.fullName,
                  imageLink: user.avatar || user.profile?.avatar,
                  rank: user.rank,
                  points: user.progress?.totalLeaderboard?.completed,
                  featured: user._id === _ctx.userId
                }, null, 8, ["totalRows", "name", "imageLink", "rank", "points", "featured"]))
              }), 128))
            ])
          ], 64))
    ]),
    _: 1
  }))
}