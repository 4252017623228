import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5da08079"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-d-flex p-mt-5" }
const _hoisted_2 = { class: "p-d-flex p-jc-between p-mt-5" }
const _hoisted_3 = { class: "p-d-flex p-flex-column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_skeleton_text = _resolveComponent("skeleton-text")!
  const _component_skeleton_icon = _resolveComponent("skeleton-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_skeleton_text, {
      sm: "",
      length: 20,
      class: "p-mt-4"
    }),
    _createElementVNode("span", _hoisted_1, [
      _createVNode(_component_skeleton_text, { length: 9 }),
      _createVNode(_component_skeleton_text, {
        length: 15,
        class: "p-ml-3"
      })
    ]),
    _createElementVNode("span", _hoisted_2, [
      _createVNode(_component_skeleton_text, { length: 6 }),
      _createVNode(_component_skeleton_text, { length: 7 })
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(6, (i) => {
        return _createElementVNode("div", {
          class: "p-d-flex p-ai-center p-pl-5 p-py-4 p-mt-3 card_item",
          key: i
        }, [
          _createVNode(_component_skeleton_icon, { class: "p-mr-3 user_picture" }),
          _createVNode(_component_skeleton_text, {
            length: 10,
            sm: ""
          }),
          _createVNode(_component_skeleton_text, {
            length: 6,
            sm: "",
            class: "p-ml-5"
          })
        ])
      }), 64))
    ])
  ]))
}