import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "p-mb-5 experience__wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "t3 text-white p-my-4"
}
const _hoisted_3 = { class: "p-mb-5 experience__wrapper" }
const _hoisted_4 = {
  key: 0,
  class: "t3 text-white p-my-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_toast = _resolveComponent("toast")!
  const _component_card_information_large = _resolveComponent("card-information-large")!
  const _component_Button = _resolveComponent("Button")!
  const _component_form_studies = _resolveComponent("form-studies")!
  const _component_custom_transition = _resolveComponent("custom-transition")!
  const _component_form_work_experience = _resolveComponent("form-work-experience")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_toast),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.role === 3 ? 'p-d-flex p-flex-column-reverse' : '')
    }, [
      _createElementVNode("div", _hoisted_1, [
        (!_ctx.isVisitorView || (_ctx.isVisitorView && _ctx.academicData.length))
          ? (_openBlock(), _createElementBlock("h3", _hoisted_2, _toDisplayString(_ctx.role === 2 ? _ctx.t('profile.formStudies.mainTitle.student') : _ctx.t('profile.formStudies.mainTitle.teacher')), 1))
          : _createCommentVNode("", true),
        (_ctx.academicData.length)
          ? (_openBlock(), _createBlock(_component_card_information_large, {
              key: 1,
              class: "p-mb-4",
              items: _ctx.academicData,
              isEducation: "",
              isVisitorView: _ctx.isVisitorView,
              onOnEdit: _ctx.handleEditData,
              onOnDelete: _ctx.handleDeleteStudies
            }, null, 8, ["items", "isVisitorView", "onOnEdit", "onOnDelete"]))
          : _createCommentVNode("", true),
        (!_ctx.showStudiesForm && !_ctx.isVisitorView)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 2,
              label: _ctx.t('profile.formStudies.cta.main'),
              class: "tertiary p-pl-0 newDesign",
              icon: "pi pi-plus p-mb-1",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showStudiesForm = true))
            }, null, 8, ["label"]))
          : _createCommentVNode("", true),
        _createVNode(_component_custom_transition, null, {
          default: _withCtx(() => [
            (_ctx.showStudiesForm)
              ? (_openBlock(), _createBlock(_component_form_studies, {
                  key: 0,
                  userId: _ctx.userId,
                  defaultValues: _ctx.editFormValues,
                  onOnSubmit: _ctx.handleStudiesSubmit,
                  onOnCancel: _ctx.handleCancelForm
                }, null, 8, ["userId", "defaultValues", "onOnSubmit", "onOnCancel"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        (!_ctx.isVisitorView || (_ctx.isVisitorView && _ctx.academicData.length))
          ? (_openBlock(), _createElementBlock("h3", _hoisted_4, _toDisplayString(_ctx.role === 2 ? _ctx.t('profile.workExperience.mainTitle.student') : _ctx.t('profile.workExperience.mainTitle.teacher')), 1))
          : _createCommentVNode("", true),
        (_ctx.workData.length)
          ? (_openBlock(), _createBlock(_component_card_information_large, {
              key: 1,
              class: "p-mb-4",
              items: _ctx.workData,
              isVisitorView: _ctx.isVisitorView,
              onOnEdit: _ctx.handleEditData,
              onOnDelete: _ctx.handleDeleteWork
            }, null, 8, ["items", "isVisitorView", "onOnEdit", "onOnDelete"]))
          : _createCommentVNode("", true),
        (!_ctx.showWorkForm && !_ctx.isVisitorView)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 2,
              label: _ctx.t('profile.workExperience.cta.main'),
              class: "tertiary p-pl-0 newDesign",
              icon: "pi pi-plus p-mb-1",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showWorkForm = true))
            }, null, 8, ["label"]))
          : _createCommentVNode("", true),
        _createVNode(_component_custom_transition, null, {
          default: _withCtx(() => [
            (_ctx.showWorkForm)
              ? (_openBlock(), _createBlock(_component_form_work_experience, {
                  key: 0,
                  userId: _ctx.userId,
                  defaultValues: _ctx.editFormValues,
                  onOnSubmit: _ctx.handleWorkSubmit,
                  onOnCancel: _ctx.handleCancelForm
                }, null, 8, ["userId", "defaultValues", "onOnSubmit", "onOnCancel"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ])
    ], 2),
    _createVNode(_component_Dialog, {
      visible: _ctx.displayDialog,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.displayDialog) = $event)),
      modal: true
    }, {
      header: _withCtx(() => [
        _createElementVNode("h3", null, _toDisplayString(_ctx.t('profile.trainingView.dialog.title')) + " " + _toDisplayString(_ctx.academicDataId ? _ctx.t('profile.trainingView.dialog.education') : _ctx.t('profile.trainingView.dialog.job')), 1)
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: _ctx.t('profile.trainingView.dialog.cta.cancel'),
          class: "secondary",
          onClick: _ctx.handleCloseForm,
          disabled: _ctx.isDialogLoading
        }, null, 8, ["label", "onClick", "disabled"]),
        _createVNode(_component_Button, {
          label: _ctx.t('profile.trainingView.dialog.cta.submit'),
          onClick: _ctx.handleConfirmDelete,
          loading: _ctx.isDialogLoading
        }, null, 8, ["label", "onClick", "loading"])
      ]),
      default: _withCtx(() => [
        _createTextVNode(" " + _toDisplayString(_ctx.t('profile.trainingView.dialog.subtitle')) + " ", 1)
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}