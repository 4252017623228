
// Vendor
import { defineComponent, onMounted, PropType, reactive, toRefs } from 'vue'
import { DateTime, Interval } from 'luxon'

// Models
import { IChallenge } from '@/models/newPlatform/challenges/challenge'

// Components
import Card from '@/components/aero/surfaces/Card.vue'
import Icon from '@/components/aero/icon/Icon.vue'
import { AlertCircle, Clock } from '@/components/aero/icon/templates'

// Utils
import { getDate, getHours } from '@/utils/datetime'

// Amplitude
import { ChallengesEvents } from '@/amplitude/constants'
import { trackEvent } from '@/amplitude/actions'

// copies
import copies from '@/locales/challenges/es.json'

export default defineComponent({
  components: { Card, AlertCircle, Clock, Icon },
  props: {
    challenges: { type: Array as PropType<IChallenge[]>, required: true },
    useCoderChallenge: {type: Boolean, required: true}
  },
  setup(props) {
    const challengeData = reactive({
      expiresAt: '',
      isNextToExpire: false,
      pendingQuantity: props.challenges.length > 1 ? `${props.challenges.length} pendientes` : '1 pendiente',
      outOfTime: 0
    })

    const setChallengeData = () => {
      const expiresDate = props.challenges[0].expiresAt || 0
      const now = DateTime.now()
      const later = DateTime.fromMillis(expiresDate)
      const interval = Interval.fromDateTimes(now, later)

      const round = (v: number) => Math.round(Math.abs(v))
      if (round(interval.length('hour')) > 24) {
        // flag to set cta color
        challengeData.isNextToExpire = false

        // set expires text
        challengeData.expiresAt = `${copies.countdown.expiresAt} ${copies.countdown.el} ${getDate(expiresDate)} ${getHours(
          expiresDate
        )}`
      } else {
        //  less than 24 hours
        const end = DateTime.fromMillis(expiresDate)
        const diffTime = end.diff(now, ['hours', 'minutes']).toObject()

        // flag to set cta color
        challengeData.isNextToExpire = true

        // set expires text
        const minutes = diffTime.minutes && round(diffTime.minutes)
        challengeData.expiresAt = `${copies.countdown.expiresAt} ${copies.countdown.en} ${diffTime.hours}h ${minutes}m`
      }

      for (const challenge of props.challenges) {
        if ((challenge.expiresAt || 0) < Date.now()) challengeData.outOfTime++
      }
    }

    // Amplitude track event
    const handleTrackEvent = (name: string, props = {}) => {
      trackEvent(name, props)
    }

    onMounted(() => {
      setChallengeData()
    })

    return {
      ...toRefs(challengeData),
      handleTrackEvent,
      ChallengesEvents
    }
  }
})
