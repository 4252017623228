import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, toHandlerKey as _toHandlerKey, mergeProps as _mergeProps, createElementVNode as _createElementVNode, createBlock as _createBlock, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ce2d2420"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = {
  key: 0,
  class: "p-d-flex p-jc-end p-ai-center button__group"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_input_wrapper = _resolveComponent("input-wrapper")!
  const _component_input_text = _resolveComponent("input-text")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_SelectInput = _resolveComponent("SelectInput")!
  const _component_InputRadioButton = _resolveComponent("InputRadioButton")!
  const _component_input_error = _resolveComponent("input-error")!
  const _component_InputCheckbox = _resolveComponent("InputCheckbox")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_file_tag = _resolveComponent("file-tag")!
  const _component_input_file = _resolveComponent("input-file")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questions, (question, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: question.name,
        class: _normalizeClass(_ctx.classStyles ? `p-mb-${index === _ctx.questions.length - 1 ? 4 : 5}` : ''),
        style: _normalizeStyle(question.styles && question.styles.all)
      }, [
        (!!question.title)
          ? (_openBlock(), _createElementBlock("p", {
              key: 0,
              class: _normalizeClass(`t3 medium p-mb-${!question.description ? (question.type === 'textarea' ? '3' : '4') : '2'} title-field`)
            }, _toDisplayString(question.title), 3))
          : _createCommentVNode("", true),
        (!!question.description)
          ? (_openBlock(), _createElementBlock("p", {
              key: 1,
              class: _normalizeClass(`p1 text-light-gray p-mb-${question.type === 'textarea' ? '3' : '4'} description-field`)
            }, _toDisplayString(question.description), 3))
          : _createCommentVNode("", true),
        (question.type === 'textarea')
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              style: _normalizeStyle(question.styles && question.styles.content)
            }, [
              _createVNode(_component_input_wrapper, {
                label: question.label,
                inputId: question.name,
                error: ((_ctx.validations[question.name] || { $errors: [] }).$errors[0] || { $message: undefined }).$message
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Textarea, {
                    modelValue: _ctx.values[question.name],
                    "onUpdate:modelValue": ($event: any) => ((_ctx.values[question.name]) = $event),
                    id: question.name,
                    placeholder: question.placeholder,
                    rows: "5",
                    cols: "30",
                    class: _normalizeClass({
            'input-error': ((_ctx.validations[question.name] || { $errors: [] }).$errors[0] || { $message: undefined }).$message
          })
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "id", "placeholder", "class"])
                ]),
                _: 2
              }, 1032, ["label", "inputId", "error"])
            ], 4))
          : _createCommentVNode("", true),
        (question.type === 'text')
          ? (_openBlock(), _createElementBlock("div", {
              key: 3,
              style: _normalizeStyle(question.styles && question.styles.content)
            }, [
              _createVNode(_component_input_wrapper, {
                label: question.label,
                inputId: question.name,
                error: ((_ctx.validations[question.name] || { $errors: [] }).$errors[0] || { $message: undefined }).$message
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_input_text, {
                    id: question.name,
                    name: question.name,
                    modelValue: _ctx.values[question.name],
                    "onUpdate:modelValue": ($event: any) => ((_ctx.values[question.name]) = $event),
                    placeholder: question.placeholder,
                    disabled: question.disabled
                  }, null, 8, ["id", "name", "modelValue", "onUpdate:modelValue", "placeholder", "disabled"])
                ]),
                _: 2
              }, 1032, ["label", "inputId", "error"])
            ], 4))
          : _createCommentVNode("", true),
        (question.type === 'date')
          ? (_openBlock(), _createElementBlock("div", {
              key: 4,
              style: _normalizeStyle(question.styles && question.styles.content)
            }, [
              _createVNode(_component_input_wrapper, {
                label: question.label,
                inputId: question.name,
                error: ((_ctx.validations[question.name] || { $errors: [] }).$errors[0] || { $message: undefined }).$message
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Calendar, {
                    id: question.name,
                    name: question.name,
                    modelValue: _ctx.values[question.name],
                    "onUpdate:modelValue": ($event: any) => ((_ctx.values[question.name]) = $event),
                    dateFormat: 'dd/mm/yy',
                    maxDate: question.max,
                    minDate: question.min,
                    showIcon: true,
                    manualInput: false
                  }, null, 8, ["id", "name", "modelValue", "onUpdate:modelValue", "maxDate", "minDate"])
                ]),
                _: 2
              }, 1032, ["label", "inputId", "error"])
            ], 4))
          : _createCommentVNode("", true),
        (question.type === 'select')
          ? (_openBlock(), _createElementBlock("div", {
              key: 5,
              style: _normalizeStyle(question.styles && question.styles.content)
            }, [
              _createVNode(_component_input_wrapper, {
                label: question.label,
                inputId: question.name,
                error: ((_ctx.validations[question.name] || { $errors: [] }).$errors[0] || { $message: undefined }).$message
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_SelectInput, _mergeProps({
                    name: question.name,
                    options: question.options,
                    valueSuggested: question.defaultValue,
                    disabled: question.disabled
                  }, {
                    [_toHandlerKey(`${question.name}`)]: (value) => _ctx.handleChange(question.name, value)
                  }), null, 16, ["name", "options", "valueSuggested", "disabled"])
                ]),
                _: 2
              }, 1032, ["label", "inputId", "error"])
            ], 4))
          : _createCommentVNode("", true),
        (question.type === 'radio')
          ? (_openBlock(), _createElementBlock("div", {
              key: 6,
              style: _normalizeStyle(question.styles && question.styles.content)
            }, [
              _createVNode(_component_InputRadioButton, {
                name: question.name,
                options: question.options,
                contained: true,
                onOnUpdateValue: (value) => _ctx.handleChange(question.name, value)
              }, null, 8, ["name", "options", "onOnUpdateValue"]),
              _createVNode(_component_input_error, {
                error: ((_ctx.validations[question.name] || { $errors: [] }).$errors[0] || { $message: undefined }).$message
              }, null, 8, ["error"])
            ], 4))
          : _createCommentVNode("", true),
        (question.type === 'checkbox')
          ? (_openBlock(), _createElementBlock("div", {
              key: 7,
              style: _normalizeStyle(question.styles && question.styles.content)
            }, [
              _createVNode(_component_InputCheckbox, {
                name: question.name,
                options: question.options,
                contained: question.contained === 'none' ? false : true,
                defaultValue: question.defaultValue,
                onOnUpdateValue: (value) => _ctx.handleChange(question.name, value)
              }, null, 8, ["name", "options", "contained", "defaultValue", "onOnUpdateValue"]),
              _createVNode(_component_input_error, {
                error: ((_ctx.validations[question.name] || { $errors: [] }).$errors[0] || { $message: undefined }).$message
              }, null, 8, ["error"])
            ], 4))
          : _createCommentVNode("", true),
        (question.type === 'checkbox-binary')
          ? (_openBlock(), _createElementBlock("div", {
              key: 8,
              style: _normalizeStyle(question.styles && question.styles.content)
            }, [
              _createVNode(_component_Checkbox, {
                id: question.name,
                name: question.name,
                modelValue: _ctx.values[question.name],
                "onUpdate:modelValue": ($event: any) => ((_ctx.values[question.name]) = $event),
                binary: true
              }, null, 8, ["id", "name", "modelValue", "onUpdate:modelValue"]),
              _createElementVNode("label", {
                for: question.name,
                class: "p1 text-white p-ml-3"
              }, _toDisplayString(question.label), 9, _hoisted_1)
            ], 4))
          : _createCommentVNode("", true),
        (question.type === 'file')
          ? (_openBlock(), _createElementBlock("div", {
              key: 9,
              style: _normalizeStyle(question.styles && question.styles.content)
            }, [
              _createVNode(_component_input_wrapper, {
                label: question.label,
                inputId: question.name,
                error: ((_ctx.validations[question.name] || { $errors: [] }).$errors[0] || { $message: undefined }).$message
              }, {
                default: _withCtx(() => [
                  (!!_ctx.values[question.name] && typeof _ctx.values[question.name] === 'string')
                    ? (_openBlock(), _createBlock(_component_file_tag, {
                        key: 0,
                        modelValue: _ctx.values[question.name],
                        "onUpdate:modelValue": ($event: any) => ((_ctx.values[question.name]) = $event),
                        isUpload: "",
                        data: { text: question.defaultValue },
                        onOnDelete: ($event: any) => (_ctx.values[question.name] = null)
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "data", "onOnDelete"]))
                    : (_openBlock(), _createBlock(_component_input_file, {
                        key: 1,
                        modelValue: _ctx.values[question.name],
                        "onUpdate:modelValue": ($event: any) => ((_ctx.values[question.name]) = $event),
                        isDisabled: !_ctx.allowedToEditForm,
                        onOnDrop: (value) => _ctx.handleChange(question.name, value),
                        onOnChange: (value) => _ctx.handleChange(question.name, value),
                        onOnDeleteFile: ($event: any) => (_ctx.values[question.name] = null)
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "isDisabled", "onOnDrop", "onOnChange", "onOnDeleteFile"]))
                ]),
                _: 2
              }, 1032, ["label", "inputId", "error"])
            ], 4))
          : _createCommentVNode("", true),
        (!!question.manyQuestions)
          ? (_openBlock(), _createElementBlock("div", {
              key: 10,
              class: "p-mb-5",
              style: _normalizeStyle(question.styles && question.styles.manyQuestions && question.styles.manyQuestions.all)
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(question.manyQuestions, (subQuestion) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: subQuestion.name
                }, [
                  (subQuestion.type === 'textarea')
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        style: _normalizeStyle(question.styles && question.styles.manyQuestions && question.styles.manyQuestions.content)
                      }, [
                        _createVNode(_component_input_wrapper, {
                          label: subQuestion.label,
                          inputId: subQuestion.name,
                          error: 
              (
                ((_ctx.validations[question.name] && _ctx.validations[question.name][subQuestion.name]) || { $errors: [] }).$errors[0] || {
                  $message: undefined
                }
              ).$message
            
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_Textarea, {
                              id: subQuestion.name,
                              name: subQuestion.name,
                              modelValue: _ctx.values[question.name][subQuestion.name],
                              "onUpdate:modelValue": ($event: any) => ((_ctx.values[question.name][subQuestion.name]) = $event),
                              placeholder: subQuestion.placeholder
                            }, null, 8, ["id", "name", "modelValue", "onUpdate:modelValue", "placeholder"])
                          ]),
                          _: 2
                        }, 1032, ["label", "inputId", "error"])
                      ], 4))
                    : _createCommentVNode("", true),
                  (subQuestion.type === 'text')
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        style: _normalizeStyle(question.styles && question.styles.manyQuestions && question.styles.manyQuestions.content)
                      }, [
                        _createVNode(_component_input_wrapper, {
                          label: subQuestion.label,
                          inputId: subQuestion.name,
                          error: 
              (
                ((_ctx.validations[question.name] && _ctx.validations[question.name][subQuestion.name]) || { $errors: [] }).$errors[0] || {
                  $message: undefined
                }
              ).$message
            
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_input_text, {
                              id: subQuestion.name,
                              name: subQuestion.name,
                              modelValue: _ctx.values[question.name][subQuestion.name],
                              "onUpdate:modelValue": ($event: any) => ((_ctx.values[question.name][subQuestion.name]) = $event),
                              placeholder: subQuestion.placeholder
                            }, null, 8, ["id", "name", "modelValue", "onUpdate:modelValue", "placeholder"])
                          ]),
                          _: 2
                        }, 1032, ["label", "inputId", "error"])
                      ], 4))
                    : _createCommentVNode("", true),
                  (subQuestion.type === 'date')
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 2,
                        style: _normalizeStyle(subQuestion.styles && subQuestion.styles.content)
                      }, [
                        _createVNode(_component_input_wrapper, {
                          label: subQuestion.label,
                          inputId: subQuestion.name,
                          error: ((_ctx.validations[subQuestion.name] || { $errors: [] }).$errors[0] || { $message: undefined }).$message
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_Calendar, {
                              id: subQuestion.name,
                              name: subQuestion.name,
                              modelValue: _ctx.values[subQuestion.name],
                              "onUpdate:modelValue": ($event: any) => ((_ctx.values[subQuestion.name]) = $event),
                              dateFormat: 'dd/mm/yy',
                              maxDate: subQuestion.max,
                              minDate: subQuestion.min,
                              showIcon: true,
                              manualInput: false
                            }, null, 8, ["id", "name", "modelValue", "onUpdate:modelValue", "maxDate", "minDate"])
                          ]),
                          _: 2
                        }, 1032, ["label", "inputId", "error"])
                      ], 4))
                    : _createCommentVNode("", true),
                  (subQuestion.type === 'radio')
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 3,
                        style: _normalizeStyle(question.styles && question.styles.manyQuestions && question.styles.manyQuestions.content)
                      }, [
                        _createVNode(_component_InputRadioButton, {
                          name: subQuestion.name,
                          options: subQuestion.options,
                          contained: true,
                          onOnUpdateValue: (value) => _ctx.handleChange({ subQuestionName: subQuestion.name, questionName: question.name }, value)
                        }, null, 8, ["name", "options", "onOnUpdateValue"]),
                        _createVNode(_component_input_error, {
                          error: 
              (
                ((_ctx.validations[question.name] && _ctx.validations[question.name][subQuestion.name]) || { $errors: [] }).$errors[0] || {
                  $message: undefined
                }
              ).$message
            
                        }, null, 8, ["error"])
                      ], 4))
                    : _createCommentVNode("", true),
                  (subQuestion.type === 'checkbox')
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 4,
                        style: _normalizeStyle(question.styles && question.styles.manyQuestions && question.styles.manyQuestions.content)
                      }, [
                        _createVNode(_component_InputCheckbox, {
                          name: subQuestion.name,
                          options: subQuestion.options,
                          contained: true,
                          onOnUpdateValue: (value) => _ctx.handleChange({ subQuestionName: subQuestion.name, questionName: question.name }, value)
                        }, null, 8, ["name", "options", "onOnUpdateValue"]),
                        _createVNode(_component_input_error, {
                          error: 
              (
                ((_ctx.validations[question.name] && _ctx.validations[question.name][subQuestion.name]) || { $errors: [] }).$errors[0] || {
                  $message: undefined
                }
              ).$message
            
                        }, null, 8, ["error"])
                      ], 4))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ], 4))
          : _createCommentVNode("", true)
      ], 6))
    }), 128)),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    (_ctx.buttonLabel)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (!!_ctx.buttonLabel.cancel)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 0,
                onClick: _ctx.handleCancel,
                label: _ctx.buttonLabel.cancel,
                disabled: _ctx.loadingSubmit,
                class: "secondary p-mr-2"
              }, null, 8, ["onClick", "label", "disabled"]))
            : _createCommentVNode("", true),
          _createVNode(_component_Button, {
            onClick: _ctx.handleSubmit,
            label: _ctx.buttonLabel.submit,
            loading: _ctx.loadingSubmit,
            disabled: _ctx.loadingSubmit || !_ctx.allowedToEditForm || _ctx.disableSubmit,
            class: "primary"
          }, null, 8, ["onClick", "label", "loading", "disabled"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}