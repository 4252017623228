import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dots = _resolveComponent("dots")!
  const _component_button_icon = _resolveComponent("button-icon")!
  const _component_Menu = _resolveComponent("Menu")!

  return (_ctx.options && _ctx.options.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_button_icon, {
          onClick: _ctx.openMenu,
          "aria-haspopup": "true",
          "aria-controls": "overlay_menu",
          color: 'var(--light-gray)'
        }, {
          default: _withCtx(() => [
            _createVNode(_component_dots)
          ]),
          _: 1
        }, 8, ["onClick", "color"]),
        _createVNode(_component_Menu, {
          id: "overlay_menu",
          ref: "menuRef",
          model: _ctx.options,
          popup: true
        }, null, 8, ["model"])
      ]))
    : _createCommentVNode("", true)
}