import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-19e0f236"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "datecard__date" }
const _hoisted_2 = { class: "p1" }
const _hoisted_3 = { class: "t2" }
const _hoisted_4 = { class: "p3" }
const _hoisted_5 = {
  class: "datecard__content",
  role: "contentinfo"
}
const _hoisted_6 = {
  key: 0,
  class: "p2 datecard__subtitle"
}
const _hoisted_7 = { class: "p1 datecard__title" }
const _hoisted_8 = {
  role: "group",
  class: "datecard__buttons p-d-flex p-ai-center"
}
const _hoisted_9 = ["href"]
const _hoisted_10 = { class: "p-button-label label__uppercase" }
const _hoisted_11 = ["href"]
const _hoisted_12 = {
  key: 1,
  role: "group",
  class: "datecard__beginsin p-mt-2 p-d-flex p-jc-start p-ai-center"
}
const _hoisted_13 = { class: "p3 p-mr-1" }
const _hoisted_14 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Button = _resolveComponent("Button")!
  const _component_clock = _resolveComponent("clock")!
  const _component_icon = _resolveComponent("icon")!
  const _component_card = _resolveComponent("card")!

  return (_openBlock(), _createBlock(_component_card, {
    class: "datecard",
    bgDark: _ctx.isAfterclass,
    border: _ctx.isAfterclass
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.shortDay), 1),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.dt?.toFormat('d')), 1),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.formattedTime), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_ctx.data.name)
          ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.title), 1))
          : _createCommentVNode("", true),
        _createElementVNode("h5", _hoisted_7, _toDisplayString(_ctx.data.name), 1),
        _createElementVNode("div", _hoisted_8, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true),
          (
            _ctx.role === 2 &&
            _ctx.challengesList?.length < 2 &&
            _ctx.data.links.feedbackLink &&
            !_ctx.data.feedback?.isFeedbackExpired &&
            !_ctx.data.schedule.isAdditional &&
            _ctx.data.schedule.to < Date.now() &&
            !_ctx.data.feedback.isFeedbackCompleted &&
            !_ctx.becaSuspended
          )
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                href: _ctx.data.links?.feedbackLink,
                target: "_blank",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleTrackEvent(_ctx.ClassesEvent.RATE_CLASS))),
                class: _normalizeClass(["p-button full-width", { secondary: _ctx.data.modules?.project?.hasDelivers || _ctx.data.modules?.project?.isFinalDeliver }])
              }, _toDisplayString(_ctx.copies.rate), 11, _hoisted_9))
            : _createCommentVNode("", true),
          (_ctx.role === 2 && _ctx.challengesList?.length && _ctx.data.schedule.to < Date.now() && !_ctx.becaSuspended)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.challengesList, (challenge) => {
                return (_openBlock(), _createBlock(_component_router_link, {
                  key: challenge.id,
                  to: `/reviews/${_ctx.camadaNro}/${challenge.id}/${challenge.stage}`,
                  class: _normalizeClass(["p-button full-width", challenge.color]),
                  style: {"min-width":"max-content"},
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleTrackEvent(_ctx.ClassesEvent.GO_TO_MODULES)))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_10, _toDisplayString(challenge.label), 1)
                  ]),
                  _: 2
                }, 1032, ["to", "class"]))
              }), 128))
            : _createCommentVNode("", true),
          (_ctx.role === 3 && !_ctx.showZoomBtn && _ctx.data.schedule.to < Date.now() && !_ctx.data.schedule.isAdditional && !_ctx.courseEnded)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 2,
                class: "full-width",
                label: _ctx.copies.bookAfterClass,
                onClick: _ctx.handleCreateAfterClass
              }, null, 8, ["label", "onClick"]))
            : _createCommentVNode("", true),
          (_ctx.role === 3 && !_ctx.showCountdown && _ctx.data.schedule.day > Date.now() && _ctx.data.links?.presentationLink)
            ? (_openBlock(), _createElementBlock("a", {
                key: 3,
                href: _ctx.data.links?.presentationLink,
                target: "_blank",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleTrackEvent(_ctx.ClassesEvent.GO_TO_PRESENTATION))),
                class: "p-button full-width secondary"
              }, _toDisplayString(_ctx.copies.goToPresentation), 9, _hoisted_11))
            : _createCommentVNode("", true)
        ]),
        (_ctx.showCountdown || _ctx.showZoomBtn)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              (_ctx.showCountdown)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createVNode(_component_icon, { size: "1.175rem" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_clock)
                      ]),
                      _: 1
                    }),
                    _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.copies.beginsIn) + " " + _toDisplayString(_ctx.beginsIn) + "'", 1)
                  ], 64))
                : _createCommentVNode("", true),
              (_ctx.showZoomBtn)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 1,
                    href: _ctx.data.videoLink?.liveVideoLink,
                    target: "_blank",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleTrackEvent(_ctx.ClassesEvent.GO_TO_ZOOM))),
                    class: "p-button full-width"
                  }, _toDisplayString(_ctx.copies.goToZoom), 9, _hoisted_14))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 3
  }, 8, ["bgDark", "border"]))
}