
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    duration: {
      type: Object as PropType<{ enter: number; leave: number }>,
      default: () => ({ enter: 250, leave: 250 })
    }
  }
})
