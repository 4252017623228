<template>
  <card class="p-d-flex p-ai-center p-flex-wrap">
    <skeleton-icon class="p-mr-4" />
    <skeleton-text :length="7" class="p-mr-4" />
    <skeleton-text :maxWords="7" class="p-flex-1" />
    <div class="p-d-flex p-ai-center p-ml-auto">
      <skeleton-icon class="p-ml-4" v-for="i in 4" :key="i" />
    </div>
  </card>
</template>

<script>
import Card from '../surfaces/Card.vue'
import SkeletonIcon from './SkeletonIcon.vue'
import SkeletonText from './SkeletonText.vue'
export default {
  components: { Card, SkeletonIcon, SkeletonText }
}
</script>

<style lang="scss" scoped>
.p-flex-1 {
  flex: 1;
}
</style>
