
// Vendor
import { computed, onMounted, ref, watch } from 'vue'

// PrimeVue components
import Editor from 'primevue/editor'
import Button from 'primevue/button'

// Components
import Icon from '@/components/aero/icon/Icon.vue'

// Icons
import { Header1 } from '@/components/aero/icon/templates'

// Copies
import copies from '@/locales/dashboard/es.json'

export default {
  components: { Editor, Header1, Button, Icon },
  props: {
    placeholder: {
      type: String
    },
    buttons: {
      type: Object
    }
  },
  setup(_: any, context: any) {
    const text = ref('')
    const hoveredHeader = ref<boolean>(false)

    const isTextInvalid = computed(() => {
      return text.value.substring(text.value.indexOf('>') + 1, text.value.lastIndexOf('<')).trim() === ''
    })

    onMounted(() => {
      const editor = document.getElementsByClassName('ql-editor')[0]
      const container = document.getElementsByClassName('p-editor-container')[0]

      editor.addEventListener('focus', function () {
        container.classList.add('is-focused')
      })

      editor.addEventListener('blur', function () {
        container.classList.remove('is-focused')
      })
    })

    const handleSubmit = (type: 'draft' | 'publish') => {
      context.emit('onSubmit', type)
    }

    watch(text, () => {
      context.emit('onChange', text.value)
    })

    return {
      text,
      copies,
      isTextInvalid,
      hoveredHeader,
      handleSubmit
    }
  }
}
