
import { defineComponent, PropType } from 'vue'

// Icons
import Icon from '@/components/aero/icon/Icon.vue'
import { LinkIcon } from '@/components/aero/icon/templates'
import getFilePreview from '@/services/newPlatform/getFilePreview'

export default defineComponent({
  components: { Icon, LinkIcon },
  props: {
    img: {
      type: String,
      required: true
    },
    link: {
      type: Object as PropType<{ title: string; url: string; type: 'external' | 'internal' }>,
      required: true
    },
    cardType: {
      type: String,
      default: 'small'
    }
  },
  setup(props) {
    return {
      filePreview: getFilePreview(props.img)
    }
  }
})
