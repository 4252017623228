import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1051f656"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["width", "height", "viewBox"]
const _hoisted_2 = ["stroke", "fill"]
const _hoisted_3 = ["width", "height", "viewBox"]
const _hoisted_4 = ["stroke", "fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.withWrapper)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["p-d-flex p-jc-center p-ai-center icon__wrapper", [_ctx.wrapperXl ? 'wrapper-xl' : '', _ctx.wrapperBorder ? `wrapper-border-${_ctx.wrapperBorder}` : '', _ctx.wrapperS ? 'wrapper-s' : '']])
      }, [
        (_openBlock(), _createElementBlock("svg", {
          width: _ctx.size,
          height: _ctx.size,
          viewBox: _ctx.viewBox,
          xmlns: "http://www.w3.org/2000/svg"
        }, [
          _createElementVNode("g", {
            stroke: _ctx.strokeColor,
            fill: _ctx.fillColor
          }, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ], 8, _hoisted_2)
        ], 8, _hoisted_1))
      ], 2))
    : (_openBlock(), _createElementBlock("svg", {
        key: 1,
        width: _ctx.size,
        height: _ctx.size,
        viewBox: _ctx.viewBox,
        xmlns: "http://www.w3.org/2000/svg"
      }, [
        _createElementVNode("g", {
          stroke: _ctx.strokeColor,
          fill: _ctx.fillColor
        }, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 8, _hoisted_4)
      ], 8, _hoisted_3))
}