
// Vendor
import { defineComponent, ref } from 'vue'

// Icon
import Icon from '@/components/aero/icon/Icon.vue'
import { Check } from '@/components/aero/icon/templates'

export default defineComponent({
  components: { Icon, Check },
  props: {
    defaultState: {
      type: Boolean,
      default: true
    },
    value: {
      type: String,
      default: '',
      required: true
    }
  },
  setup(props, context) {
    const state = ref<boolean>(props.defaultState)

    const handleCick = () => {
      state.value = !state.value
      context.emit(`${props.value}`, state.value)
    }

    return { state, handleCick }
  }
})
