import { DateTime } from 'luxon'
import { FormatDates } from '@/models/enums'

export const formatDate = (date: string): string => DateTime.fromISO(date).toFormat(FormatDates.LatinAmericaShort)

export const formatTime = (date: string): string => DateTime.fromISO(date).toLocaleString(DateTime.TIME_24_SIMPLE)

export const sumDays = (days: number, date: Date | string): string => {
  return date instanceof Date
    ? DateTime.fromJSDate(date).plus({ days: days }).toFormat(FormatDates.LatinAmericaShort)
    : DateTime.fromISO(date).plus({ days: days }).toFormat(FormatDates.LatinAmericaShort)
}

/**
 * It takes a date, a key, and an optional date, and @returns a string with the key and the difference
 * between the first date and now
 */
export const getDateDiff = (shouldReviewDate: string, key:string, updatableUntil?: string):string => {
  if (DateTime.now().toUTC() < DateTime.fromISO(updatableUntil as string)) return key as string

  const formatDate = DateTime.fromISO(shouldReviewDate, { zone: 'utc' }).diff(DateTime.now()).toFormat(`d'd' h'h' m'm'`) // eslint-disable-line quotes
  const removeZero = formatDate.split(' ').filter((val) => val !== '0d').join(' ')

  return `${key}: ${removeZero}`
}

export const addDays = (date: string, daysToAdd:number): string => DateTime.fromISO(date).plus({days: daysToAdd}).toFormat(FormatDates.LatinAmericaShort)