import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fde8eaee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "t2" }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { class: "t2 p-mt-3" }
const _hoisted_5 = { class: "t2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorPage = _resolveComponent("ErrorPage")!
  const _component_skeleton_beca_card = _resolveComponent("skeleton-beca-card")!
  const _component_point_card = _resolveComponent("point-card")!
  const _component_correcciones_card = _resolveComponent("correcciones-card")!
  const _component_review_card = _resolveComponent("review-card")!
  const _component_skeleton_desafio_card = _resolveComponent("skeleton-desafio-card")!
  const _component_desafio_card = _resolveComponent("desafio-card")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_TabView = _resolveComponent("TabView")!
  const _component_two_cols_layout = _resolveComponent("two-cols-layout")!

  return (_ctx.isError)
    ? (_openBlock(), _createBlock(_component_ErrorPage, {
        key: 0,
        copies: _ctx.copies.error
      }, null, 8, ["copies"]))
    : (_openBlock(), _createBlock(_component_two_cols_layout, { key: 1 }, {
        aside: _withCtx(() => [
          (!_ctx.isTutor)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.isLoading)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, _renderList(2, (i) => {
                      return _createVNode(_component_skeleton_beca_card, { key: i })
                    }), 64))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      (_ctx.integratorTp)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createVNode(_component_point_card, {
                              proyectoFinal: _ctx.integratorTp,
                              entregas: _ctx.finalProjectProgress.total.expected,
                              corregidas: _ctx.finalProjectProgress.total.reviewed,
                              porCorregir: _ctx.finalProjectProgress.total.unreviewed,
                              porRecibir: _ctx.finalProjectProgress.total.undelivered
                            }, null, 8, ["proyectoFinal", "entregas", "corregidas", "porCorregir", "porRecibir"]),
                            _createVNode(_component_point_card, {
                              entregas: _ctx.progressDelivers?.challenges.expected,
                              corregidas: _ctx.progressDelivers?.challenges.reviewed,
                              porCorregir: 0,
                              porRecibir: 0,
                              deTutores: ""
                            }, null, 8, ["entregas", "corregidas"])
                          ], 64))
                        : (_openBlock(), _createBlock(_component_point_card, {
                            key: 1,
                            entregas: _ctx.progressDelivers?.challenges.expected,
                            corregidas: _ctx.progressDelivers?.challenges.reviewed,
                            porCorregir: _ctx.progressDelivers?.challenges.delivered - _ctx.progressDelivers?.challenges.reviewed,
                            porRecibir: _ctx.progressDelivers?.challenges.expected - _ctx.progressDelivers?.challenges.delivered
                          }, null, 8, ["entregas", "corregidas", "porCorregir", "porRecibir"]))
                    ], 64))
              ], 64))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_ctx.isLoading)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, _renderList(3, (i) => {
                      return _createVNode(_component_skeleton_beca_card, { key: i })
                    }), 64))
                  : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.copies.tutorChallenges.correctionStatus), 1),
                      _createVNode(_component_correcciones_card, {
                        class: "p-mt-3",
                        porCorregir: _ctx.pendingChallenges.flat().length || 0,
                        fueraDeTiempo: _ctx.hasChallengesOOT
                      }, null, 8, ["porCorregir", "fueraDeTiempo"])
                    ])),
                (!_ctx.isLoading && _ctx.pendingChallenges.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.copies.tutorChallenges.pendingCorrections), 1),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pendingChallenges.slice(0, 3), (challenges) => {
                        return (_openBlock(), _createBlock(_component_review_card, {
                          key: challenges[0]._id,
                          camadaNro: _ctx.camadaNro,
                          challenges: challenges,
                          useCoderChallenge: _ctx.useCoderChallenge,
                          class: "p-mt-3"
                        }, null, 8, ["camadaNro", "challenges", "useCoderChallenge"]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ], 64))
        ]),
        content: _withCtx(() => [
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.copies.teacherChallenges.title), 1),
          _createVNode(_component_TabView, {
            onTabChange: _ctx.changeTab,
            activeIndex: _ctx.activeIndex,
            "onUpdate:activeIndex": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeIndex) = $event)),
            class: "tabview-challenges"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_TabPanel, {
                header: _ctx.copies.teacherChallenges.tabPanel.filter1
              }, {
                default: _withCtx(() => [
                  (_ctx.isLoading)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, _renderList(12, (i) => {
                        return _createVNode(_component_skeleton_desafio_card, {
                          key: i,
                          class: "p-mt-3"
                        })
                      }), 64))
                    : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.challengesByModuleSorted, (_module) => {
                        return (_openBlock(), _createBlock(_component_router_link, {
                          key: _module._id,
                          to: _ctx.useCoderChallenge?`/reviews/${_ctx.camadaNro}/${_module._id}/${_module.stage}`:`/challenges/challenge/${_module._id}`,
                          style: {"text-decoration":"none","color":"inherit"}
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_desafio_card, {
                              challenge: _module,
                              useCoderChallenge: _ctx.useCoderChallenge,
                              camadaNro: _ctx.camadaNro,
                              class: "p-mt-3"
                            }, null, 8, ["challenge", "useCoderChallenge", "camadaNro"])
                          ]),
                          _: 2
                        }, 1032, ["to"]))
                      }), 128))
                ]),
                _: 1
              }, 8, ["header"])
            ]),
            _: 1
          }, 8, ["onTabChange", "activeIndex"])
        ]),
        _: 1
      }))
}