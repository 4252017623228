export enum ClassesEvent {
  GO_TO_ZOOM = 'Opened Zoom Link',
  VIEW_RECORDING = 'View recording',
  RATE_CLASS = 'Rate class',
  VIEW_CLASS_RATES = 'View class rates',
  GO_TO_MODULES = 'Go to modules',
  GO_TO_PRESENTATION = 'Go to presentation',
  VIEW_CLASS_ASSETS = 'View class assets',
  START_BOOKING_AFTERCLASS = 'Start booking afterclass',
  CREATE_AFTERCLASS = 'Create afterclass',
  EDIT_AFTERCLASS = 'Edit afterclass',
  DELETE_AFTERCLASS = 'Delete afterclass',
  DROP_CODERBECA = 'Drop CoderBeca',
  CHANGE_COMISSION = 'Change commission'
}

export enum ChallengesEvents {
  LIST_CHALLENGES = 'Pageview modules',
  GO_TO_MODULE = 'Go to module details',
  VIEW_MODULE_DETAIL = 'Opened module details',
  HAND_CHALLENGE = 'Module handed',
  GO_TO_PENDING_DELIVER = 'Go to module pending deliver',
  VIEW_MODULE_ASSETS = 'Opened module assets',
  VIEW_BY_MODULES = 'View by module',
  VIEW_BY_STUDENTS = 'View by students',
  APPROVE_CHALLENGE = 'Module approved',
  REJECT_CHALLENGE = 'Module rejected',
  GO_TO_MODULE_INTEGRATOR = 'Go to module integrator',
  REVIEW_PENDING_CHALLENGE = 'Review pending challenge',
  REVERSE_APPROVAL = 'Reverse approval',
  ENABLE_EXPIRED_CHALLENGE = 'Enable expired challenge',
  ENABLE_CHALLENGE_RE_SUBMISSION = 'Enable challenge re-submission',
  BLOCK_CHALLENGE = 'Block challenge',
  NOTIFY_PENDINGS = 'Notifify pendings',
  ENABLE_EXPIRED_RATINGS = 'Enable expired'
}

export enum ProfileEvents {
  EDITED_PROFILE = 'Edited profile',
  ADDED_ACADEMIC_EXPERIENCE = 'Added academic experience',
  DELETE_ACADEMIC_EXPERIENCE = 'Delete academic experience',
  EDIT_ACADEMIC_EXPERIENCE = 'Edit academic experience',
  ADDED_WORK_EXPERIENCE = 'Added work experience',
  EDIT_WORK_EXPERIENCE = 'Edit work experience',
  DELETE_WORK_EXPERIENCE = 'Delete work experience',
  REQUEST_ONGOING_CERTIFICATE = 'Request ongoing certificate',
  DOWNLOAD_CERTIFICATE = 'Download certificate',
  OPENED_REFERRAL_CODE = 'Opened referral code',
  RE_SCHEDULE_CLASS = 'Re-schedule class',
  REQUEST_ADDITIONAL_CLASS = 'Request aditional class',
  REQUEST_CHANGE_COMMISSION_COURSE = 'Request change comission/course',
  CHANGE_COMMISSION = 'Change commission',
  CHANGE_COURSE = 'Change course',
  REQUEST_TRANSFER_COURSE = 'Request transfer course',
  TRANSFER_COURSE = 'Transfer course',
  REQUEST_UNSUBSCRIBE_COURSE = 'Request unsubscribe course',
  UNSUBSCRIBE_COURSE = 'unsubscribe course',
  REQUEST_ZOOM_CREDENTIALS = 'Request zoom credentials',
  DISCARD_REQUEST = 'Discard request'
}

// TODO: add eventos de facturacion?

export enum NotificationsEvents {
  OPENED_NOTIFICATION_INBOX = 'Opened notification inbox',
  CLICKED_ON_NOTIFICATION = 'Click on notification'
}

export enum PageView {
  CLASSES = 'Classes',
  CHALLENGES = 'Challenges',
  PROFILE = 'Profile',
  COMMISSION = 'Commission',
  ATTENDANCE = 'Attendance',
  RANKING = 'Ranking',
  RATING = 'Rating',
  CHAT = 'Chat'
}

export enum TopBarEvents {
  CHANGE_COMISSION = 'Change commission'
}
