import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-11faec16"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isIframe)
      ? (_openBlock(), _createElementBlock("h1", _hoisted_2, _toDisplayString(_ctx.t("onboarding-videos.students-onboarding.title")), 1))
      : _createCommentVNode("", true),
    _createElementVNode("h2", null, _toDisplayString(_ctx.t("onboarding-videos.students-onboarding.description")), 1)
  ]))
}