
import { defineComponent, onMounted, onUnmounted, ref, watch } from '@vue/runtime-core'
import { useRoute } from 'vue-router'
import { NewPlatform, JobOffers } from '@/router/route-names'

// Copies
import copies from '@/locales/challenges/es.json'

// Lottie library
import lottie, { AnimationItem } from 'lottie-web'
import animation from '@/assets/expand-collapse-coderhouse-logo.json'

// Hook
import { useSidebarAnimation } from '@/components/aero/navigation/useSidebarAnimation'

// Components
import Icon from '@/components/aero/icon/Icon.vue'
import { Help, LogOut, Close } from '@/components/aero/icon/templates'

// Store
import { useUserStore } from '@/store/modules/user.store'

export default defineComponent({
  components: {
    Help,
    Icon,
    LogOut,
    Close
  },
  props: {
    isOpenMenu: { type: Boolean, default: false }
  },
  setup(_, context) {
    const route = useRoute()

    const store = useUserStore()

    const role = ref<number>(store.role)

    const selectedTab = ref<string>('dashboard')
    const logoAnimation = ref<AnimationItem>()
    const screenWidth = window.innerWidth
    const playAnimation = screenWidth < 1920 && screenWidth > 1024

    // Set up lotties icons
    const suffix = 'DOM-id'
    const sections: string[] = [
      NewPlatform.DASHBOARD,
      NewPlatform.CLASSES,
      NewPlatform.CHALLENGES,
      NewPlatform.CHAT,
      NewPlatform.RESOURCES,
      JobOffers.Jobs,
      NewPlatform.BENEFITS
    ]

    const { setLottieAnimations, removeLottieAnimations } = useSidebarAnimation()

    /**
     * Loads every lottie file into the DOM
     */
    const loadLotties = () => {
      logoAnimation.value = lottie.loadAnimation({
        container: document.getElementById('coderhouse-logo')!,
        loop: false,
        renderer: 'svg',
        autoplay: false,
        animationData: animation
      })
    }

    /**
     * Removes the hover state in the sidebar component
     */
    const removeHover = () => {
      if (playAnimation) {
        logoAnimation.value?.setDirection(-1)
        logoAnimation.value?.setSpeed(2)
        logoAnimation.value?.play()
      }
    }

    /**
     * Triggers the hovered sidebar state
     */
    const hoverEnter = () => {
      if (playAnimation) {
        logoAnimation.value?.setSpeed(1.5)
        logoAnimation.value?.setDirection(1)
        logoAnimation.value?.playSegments([[0, 30]], true)
      }
    }

    /**
     * Change active item
     */
    const routeAlias = {
      dashboard: [NewPlatform.DASHBOARD],
      classes: [NewPlatform.CLASSES],
      challenge: [
        NewPlatform.CHALLENGE,
        NewPlatform.CHALLENGES,
        NewPlatform.CHALLENGES_BY_MODULE,
        NewPlatform.CHALLENGES_BY_STUDENT
      ],
      comission: [NewPlatform.COMMISSION],
      attendance: [NewPlatform.ATTENDANCE],
      chat: [NewPlatform.CHAT],
      ratings: [NewPlatform.RATINGS],
      shop: [NewPlatform.CONTRATAR]
    }

    /**
     * Close menu
     */
    const handleCloseMenu = () => {
      context.emit('close-menu')
    }

    const handleChangeRoute = () => {
      handleCloseMenu()

      if (routeAlias.classes.some((r) => r === route.name)) {
        selectedTab.value = NewPlatform.CLASSES
        return
      }

      if (routeAlias.challenge.some((r) => r === route.name)) {
        selectedTab.value = NewPlatform.CHALLENGES
        return
      }

      if (routeAlias.dashboard.some((r) => r === route.name)) {
        selectedTab.value = NewPlatform.DASHBOARD
        return
      }

      if (routeAlias.comission.some((r) => r === route.name)) {
        selectedTab.value = NewPlatform.COMMISSION
        return
      }
      if (routeAlias.attendance.some((r) => r === route.name)) {
        selectedTab.value = NewPlatform.ATTENDANCE
        return
      }

      if (routeAlias.chat.some((r) => r === route.name)) {
        selectedTab.value = NewPlatform.CHAT
        return
      }

      if (routeAlias.ratings.some((r) => r === route.name)) {
        selectedTab.value = NewPlatform.RATINGS
        return
      }
      if (routeAlias.shop.some((r) => r === route.name)) {
        selectedTab.value = NewPlatform.CONTRATAR
        return
      }

      selectedTab.value = ''
    }

    /**
     * Callback that gets executed when the user changes its role (necessary ATM since we can change the user from the selector, won't be necessary in the future)
     * //TODO: Remove when role selector gets removed from the UI
     */
    const handleRoleChange = () => {
      role.value = store.role
    }

    watch(store, handleRoleChange)
    watch(route, handleChangeRoute)

    onMounted(() => {
      handleChangeRoute()
      // Coderhouse lottie
      loadLotties()

      if (role.value === 2) {
        sections.push(NewPlatform.COMMISSION, NewPlatform.CONTRATAR)
      } else {
        sections.push(NewPlatform.ATTENDANCE, NewPlatform.RATINGS)
      }

      // sidebar lotties
      setLottieAnimations(sections)

      if (screenWidth >= 1920) {
        logoAnimation.value?.setSpeed(1.5)
        logoAnimation.value?.setDirection(1)
        logoAnimation.value?.playSegments([[0, 30]], true)
      }
    })

    onUnmounted(() => {
      // sidebar lotties
      removeLottieAnimations(sections)
    })

    return {
      selectedTab,
      removeHover,
      hoverEnter,
      handleCloseMenu,
      copies,
      NewPlatform,
      role,
      suffix
    }
  }
})
