import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a27d7fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "p-button primary full-width p-mt-4",
  href: "https://coderhouse.typeform.com/to/qM8QkK",
  target: "_blank",
  rel: "noopener noreferrer"
}
const _hoisted_2 = {
  key: 1,
  class: "p1"
}
const _hoisted_3 = {
  key: 2,
  class: "p1"
}
const _hoisted_4 = {
  key: 3,
  class: "chart__container"
}
const _hoisted_5 = { class: "medium label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_small_card_course = _resolveComponent("small-card-course")!
  const _component_rating_card = _resolveComponent("rating-card")!
  const _component_rating_chart = _resolveComponent("rating-chart")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_card = _resolveComponent("card")!

  return (_openBlock(), _createBlock(_component_card, { class: "ratingSummary__container" }, {
    default: _withCtx(() => [
      (_ctx.isFinish)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_small_card_course, {
              text: 
          _ctx.isTutor
            ? `${_ctx.copies.smallCardCourse.title.tutor} ${_ctx.courseName}!`
            : `${_ctx.copies.smallCardCourse.title.teacher} ${_ctx.courseName} ${_ctx.copies.smallCardCourse.title.comision} ${_ctx.camadaNro}!`
        
            }, null, 8, ["text"]),
            _createElementVNode("a", _hoisted_1, _toDisplayString(_ctx.copies.smallCardCourse.cta.label), 1)
          ], 64))
        : _createCommentVNode("", true),
      _createVNode(_component_rating_card, {
        rating: _ctx.ratingData.rating,
        text: _ctx.copies.ratingCard.title
      }, null, 8, ["rating", "text"]),
      (!_ctx.showEmptyState)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.copies.ratingSummary.title), 1))
        : (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.copies.ratingSummary.emptyState), 1)),
      (!_ctx.showEmptyState)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_rating_chart, {
              positives: _ctx.ratingData.positives,
              neutrals: _ctx.ratingData.neutrals,
              negatives: _ctx.ratingData.negatives
            }, null, 8, ["positives", "neutrals", "negatives"])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_router_link, {
        to: "/valoraciones",
        class: "btn-vermas text-primary"
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.copies.ratingSummary.cta.label), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}