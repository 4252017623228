import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ab68cbf4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "avatar__container"
}
const _hoisted_2 = {
  key: 1,
  class: "avatar__container"
}
const _hoisted_3 = {
  key: 0,
  class: "avatar__bagde p-d-flex p-jc-center p-ai-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar", true)!
  const _component_lock = _resolveComponent("lock")!
  const _component_base_icon = _resolveComponent("base-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      _ctx.role === 2 && !!_ctx.level ? `avatar__border  avatar__border-${_ctx.level}` : 'avatar__without-border',
      _ctx.size === 'xlarge' ? 'avatar--xlarge' : ''
    ])
  }, [
    (_ctx.imageLink)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_Avatar, {
            size: _ctx.size || null,
            shape: "circle",
            image: _ctx.imageLink,
            class: "avatar__image"
          }, null, 8, ["size", "image"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.isBlocked)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                _createVNode(_component_base_icon, { size: 16 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_lock)
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_Avatar, {
            size: _ctx.size,
            shape: "circle",
            label: _ctx.setInitalLetter
          }, null, 8, ["size", "label"])
        ]))
  ], 2))
}