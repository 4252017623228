import { App } from '@vue/runtime-core'
import Tooltip from 'primevue/tooltip'

/**
 * Register directives used in all the application
 * @param app the application to register components to
 */
export function registerCommonDirectives (app: App): void {
  app.directive('tooltip', Tooltip)
}
