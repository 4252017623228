import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_file = _resolveComponent("input-file")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_input_wrapper = _resolveComponent("input-wrapper")!
  const _component_rich_text_editor = _resolveComponent("rich-text-editor")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("form", {
    class: "form-wrapper",
    onSubmit: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"]))
  }, [
    _createVNode(_component_input_file, {
      modelValue: _ctx.form.file,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.file) = $event)),
      onOnDrop: _ctx.handleChange,
      onOnChange: _ctx.handleChange,
      onOnDeleteFile: _cache[1] || (_cache[1] = ($event: any) => (_ctx.form.file = null)),
      isDisabled: !!_ctx.form.link,
      DOMIdOverlay: _ctx.DOMIdOverlay,
      animation: true
    }, null, 8, ["modelValue", "onOnDrop", "onOnChange", "isDisabled", "DOMIdOverlay"]),
    _createVNode(_component_input_wrapper, {
      class: "p-mt-4",
      label: "Link público",
      error: _ctx.validations.link.url.$invalid ? _ctx.copies.inputLink.error : ''
    }, {
      default: _withCtx(() => [
        _createVNode(_component_InputText, {
          placeholder: "Si tu entrega es un link pegalo acá",
          class: "full-width",
          modelValue: _ctx.form.link,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.link) = $event)),
          disabled: !!_ctx.form.file
        }, null, 8, ["modelValue", "disabled"])
      ]),
      _: 1
    }, 8, ["error"]),
    _createVNode(_component_input_wrapper, { label: "Comentario" }, {
      default: _withCtx(() => [
        _createVNode(_component_rich_text_editor, {
          placeholder: "Escribí una publicación",
          modelValue: _ctx.form.comment,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.comment) = $event)),
          onOnChange: _ctx.handleTextEditor
        }, null, 8, ["modelValue", "onOnChange"])
      ]),
      _: 1
    }),
    _createVNode(_component_Button, {
      loading: _ctx.isDelivering,
      label: "Entregar",
      onClick: _ctx.handlePostDeliver,
      class: "form-wrapper__button",
      disabled: ((!_ctx.form.link || _ctx.validations.link.url.$invalid) && !_ctx.form.file) || !_ctx.formComment
    }, null, 8, ["loading", "onClick", "disabled"])
  ], 32))
}