import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-74d8e436"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "reviews p-p-5 p-p-sm-4" }
const _hoisted_2 = { class: "p-grid" }
const _hoisted_3 = { class: "p-sm-5 p-md-5 p-lg-4 p-xl-4 p-px-sm-3 p-px-md-3 p-px-lg-3 p-px-xl-5" }
const _hoisted_4 = { class: "p-sm-7 p-md-7 p-lg-8 p-xl-8 p-px-sm-3 p-px-md-3 p-px-lg-3 p-px-xl-5" }
const _hoisted_5 = { class: "message-box-title" }
const _hoisted_6 = {
  key: 0,
  class: "p-d-flex"
}
const _hoisted_7 = { class: "p-mr-1 message-box-title" }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "message-box-title" }
const _hoisted_10 = { class: "modal-header" }
const _hoisted_11 = { class: "modal-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StudentList = _resolveComponent("StudentList")!
  const _component_ChallengeTimeline = _resolveComponent("ChallengeTimeline")!
  const _component_MessageBox = _resolveComponent("MessageBox")!
  const _component_TutorCorrection = _resolveComponent("TutorCorrection")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_StudentList, {
            orderClass: _ctx.challenge?.classOrder,
            nameClass: _ctx.challenge?.homeworkName,
            loading: _ctx.loading,
            students: _ctx.students,
            "expiration-date": _ctx.challenge?.expirationDate,
            onSelect: _ctx.selectStudent
          }, null, 8, ["orderClass", "nameClass", "loading", "students", "expiration-date", "onSelect"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          (!_ctx.loadingRight)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (['claimedForwarding', 'approved', 'disapproved','reviewed'].includes(_ctx.deliveryStatus))
                  ? (_openBlock(), _createBlock(_component_ChallengeTimeline, {
                      key: 0,
                      challenge: _ctx.challenge,
                      "challenge-history": _ctx.delivery,
                      student: _ctx.selectedStudent
                    }, null, 8, ["challenge", "challenge-history", "student"]))
                  : (_ctx.deliveryStatus === 'forwarded' || _ctx.deliveryStatus === 'delivered')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        (_ctx.DateTime.now() < _ctx.enableReviewDate)
                          ? (_openBlock(), _createBlock(_component_MessageBox, {
                              key: 0,
                              icon: _ctx.Clock,
                              "icon-color": "#83AEFB",
                              "view-box-icon": "0 0 16 16",
                              message: 
                _ctx.t('homework.challenge.tutorCorrection.waitingForReview.subtitle', {
                  date: _ctx.formatDate(_ctx.enableReviewDate),
                  time: _ctx.formatTime(_ctx.enableReviewDate)
                })
              ,
                              class: "message-box-custom"
                            }, {
                              title: _withCtx(() => [
                                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.t('homework.challenge.tutorCorrection.waitingForReview.title')), 1)
                              ]),
                              _: 1
                            }, 8, ["icon", "message"]))
                          : (_openBlock(), _createBlock(_component_TutorCorrection, {
                              key: 1,
                              correction: _ctx.correction,
                              student: _ctx.selectedStudent,
                              sending: _ctx.sending,
                              onSend: _ctx.send,
                              "evaluation-criteria": _ctx.challenge.evaluationCriteria,
                              type: _ctx.challenge.type,
                              evaluations: _ctx.evaluations,
                              usePeerReview: _ctx.usePeerReview
                            }, null, 8, ["correction", "student", "sending", "onSend", "evaluation-criteria", "type", "evaluations", "usePeerReview"]))
                      ], 64))
                    : (_ctx.deliveryStatus === 'expired' || _ctx.deliveryStatus === 'sent' || _ctx.deliveryStatus === null)
                      ? (_openBlock(), _createBlock(_component_MessageBox, {
                          key: 2,
                          icon: _ctx.deliveryStatus === 'expired' ? _ctx.Lock : _ctx.Clock,
                          "icon-color": _ctx.deliveryStatus === 'expired' ? '#F9F9F9' : '#83AEFB',
                          message: _ctx.getMessagesBox(_ctx.deliveryStatus, _ctx.challenge.expirationDate).firstMessage,
                          secondaryMessage: _ctx.getMessagesBox(_ctx.deliveryStatus, _ctx.challenge.expirationDate).secondMessage,
                          "view-box-icon": _ctx.deliveryStatus === 'expired' ? '0 0 24 24' : '0 0 16 16',
                          class: "message-box-custom"
                        }, {
                          title: _withCtx(() => [
                            (_ctx.deliveryStatus === 'expired')
                              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                  _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.hasCoderAskActive ? _ctx.t('homework.challenge.tutorCorrection.hasCoderAsk.expiredTimeline') :
                      _ctx.t('homework.challenge.tutorCorrection.expiredTimeline')), 1),
                                  (!_ctx.hasCoderAskActive)
                                    ? (_openBlock(), _createElementBlock("span", {
                                        key: 0,
                                        class: "enable-challenge-link message-box-title",
                                        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openModal && _ctx.openModal(...args)))
                                      }, _toDisplayString(_ctx.t('homework.challenge.tutorCorrection.enableChallenge')), 1))
                                    : _createCommentVNode("", true)
                                ]))
                              : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                  _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.t('homework.challenge.tutorCorrection.waitingDelivery')), 1)
                                ]))
                          ]),
                          _: 1
                        }, 8, ["icon", "icon-color", "message", "secondaryMessage", "view-box-icon"]))
                      : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createVNode(_component_Dialog, {
      id: "custom-dialog",
      class: "custom-dialog",
      visible: _ctx.showModal,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showModal) = $event)),
      modal: "",
      closable: true
    }, {
      header: _withCtx(() => [
        _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.t('homework.challenge.tutorCorrection.enableChallengeTitle', { challenge: _ctx.challenge?.homeworkName })), 1)
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          class: "p-button-custom-secondary",
          label: _ctx.t('button.cancel'),
          disabled: _ctx.sending,
          onClick: _ctx.closeModal
        }, null, 8, ["label", "disabled", "onClick"]),
        _createVNode(_component_Button, {
          class: "p-button-custom-primary",
          label: _ctx.t('button.enable'),
          disabled: _ctx.sending,
          onClick: _ctx.enable
        }, null, 8, ["label", "disabled", "onClick"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.t('homework.challenge.tutorCorrection.enableChallengeInfo')), 1)
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}