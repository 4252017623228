import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b1b43a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { align: "right" }
const _hoisted_2 = { class: "text-light-gray" }
const _hoisted_3 = { class: "text-light-gray" }
const _hoisted_4 = { class: "text-light-gray" }
const _hoisted_5 = { class: "p-d-flex p-flex-column p-ai-center" }
const _hoisted_6 = { class: "text-light-gray" }
const _hoisted_7 = {
  key: 0,
  class: "text-blue"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RadioButton = _resolveComponent("RadioButton")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
    return (_openBlock(), _createElementBlock("tr", {
      class: "p-mb-3 border-radius bg-gray",
      key: option.value
    }, [
      _createElementVNode("td", _hoisted_1, [
        _createVNode(_component_RadioButton, {
          id: option.value,
          name: _ctx.name,
          value: option.value,
          modelValue: _ctx.optionValue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.optionValue) = $event)),
          onInput: _ctx.handleUpdateValue,
          onChange: _ctx.handleUpdateValue,
          disabled: option.disabled
        }, null, 8, ["id", "name", "value", "modelValue", "onInput", "onChange", "disabled"])
      ]),
      _createElementVNode("td", null, [
        _createElementVNode("p", null, _toDisplayString(option.teacherName), 1)
      ]),
      _createElementVNode("td", null, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(option.camadaNro), 1)
      ]),
      _createElementVNode("td", null, [
        _createElementVNode("p", _hoisted_3, _toDisplayString(option.days), 1)
      ]),
      _createElementVNode("td", null, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(option.schedule), 1)
      ]),
      _createElementVNode("td", null, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("p", _hoisted_6, _toDisplayString(option.duration), 1),
          (option.daysToStart < 30)
            ? (_openBlock(), _createElementBlock("p", _hoisted_7, "Inicia en " + _toDisplayString(option.daysToStart) + " días", 1))
            : _createCommentVNode("", true)
        ])
      ])
    ]))
  }), 128))
}