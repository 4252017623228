
import { defineComponent, PropType } from 'vue'

type NameType = 'startTime' | 'endTime'

export default defineComponent({
  props: {
    name: { type: String as PropType<NameType>, default: '', required: true },
    min: { type: String, default: '08:30', required: false },
    max: { type: String, default: '23:30', required: false },
    step: { type: Number, default: 900, required: false }
  },
  setup(props, context) {
    function handleSelectedValue(e: Event) {
      const { target } = e
      if (target) {
        context.emit(`${props.name}`, (target as HTMLInputElement).value)
      }
    }
    return { handleSelectedValue }
  }
})
