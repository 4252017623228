<template>
  <g>
    <path
      d="M5.5 10C7.29493 10 8.75 8.54493 8.75 6.75C8.75 4.95507 7.29493 3.5 5.5 3.5C3.70507 3.5 2.25 4.95507 2.25 6.75C2.25 8.54493 3.70507 10 5.5 10Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.71338 3.62107C10.1604 3.49513 10.6292 3.46644 11.0882 3.53693C11.5473 3.60743 11.9859 3.77547 12.3745 4.02975C12.7631 4.28403 13.0927 4.61863 13.3411 5.01102C13.5896 5.40342 13.751 5.84449 13.8146 6.30453C13.8782 6.76457 13.8425 7.2329 13.7098 7.67797C13.5772 8.12304 13.3507 8.53452 13.0457 8.8847C12.7406 9.23487 12.364 9.51561 11.9413 9.70799C11.5187 9.90038 11.0596 9.99996 10.5952 10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M0.999756 12.3373C1.50734 11.6153 2.18119 11.026 2.96441 10.6192C3.74764 10.2124 4.61724 10 5.49981 10C6.38238 9.99997 7.252 10.2123 8.03525 10.619C8.81851 11.0258 9.4924 11.615 10 12.337"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.5952 10C11.4779 9.99936 12.3477 10.2114 13.131 10.6182C13.9143 11.025 14.5881 11.6146 15.0952 12.337"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </g>
</template>
