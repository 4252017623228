import { IChallengeDataDto, TutorChallengeStudent, ITutorChallengeStudents } from '@/models/peer-review/challenge'
import { ChallengeHistory, IChallengeHistory, IAnonimousComment } from '@/models/peer-review/challengeHistory'
import {
  Correction,
  HomeworkPlan,
  ICorrection,
  ICorrectionDataDto,
  IHomeworkPlan,
  IReviews,
  Reviews
} from '@/models/peer-review/homeworkPlan'
import { IStudentsEvaluation } from '@/models/peer-review/evaluation'
import { AxiosRequestConfig } from 'axios'
import { Api } from './api'
import { getRole } from '@/utils/roles'
import { PersonRoles } from '@/models/enums'

async function sendChallengeRedelivery (body: IChallengeDataDto, homeworkDeliveryId: string): Promise<boolean> {
  const endpoint = `/v1/homework-delivery/${homeworkDeliveryId}/forward`
  let send
  if (body.file) {
    const data = new FormData()
    data.append('file', body.file)
    data.append('comment', body.comment)
    send = await Api.put<string, FormData>(endpoint, data)
  } else send = await Api.put<string, IChallengeDataDto>(endpoint, body)
  return send === 'success'
}

async function sendChallenge (body: IChallengeDataDto, courseId: string, homeworkPlanId: string, homeworkId: string): Promise<boolean> {
  const headers: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  const endpoint = `/v1/course/${courseId}/homework-plan/${homeworkPlanId}/homework/${homeworkId}/deliver`
  let send
  if (body.file) {
    const data = new FormData()
    data.append('file', body.file)
    data.append('comment', body.comment)
    if(body.link) data.append('link', body.link)
    send = await Api.post<string, FormData, boolean, AxiosRequestConfig>(endpoint, data, false, headers)
  } else send = await Api.post<string, IChallengeDataDto>(endpoint, body)
  return send === 'success'
}

async function editChallenge (body: IChallengeDataDto, myDeliveryId: string): Promise<boolean> {
  const headers: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  const endpoint = `/v1/homework-delivery/${myDeliveryId}/edit`
  let send
  if (body.file) {
    const data = new FormData()
    data.append('file', body.file)
    data.append('comment', body.comment)
    if(body.link) data.append('link', body.link)
    send = await Api.put<string, FormData, boolean, AxiosRequestConfig>(endpoint, data, false, headers)
  } else send = await Api.put<string, IChallengeDataDto>(endpoint, body)
  return send === 'success'
}

async function enableChallenge (courseId: string, homeworkPlanId: string, homeworkId: string, studentId: string): Promise<boolean> {
  try {
    const endpoint = `/v1/course/${courseId}/homework-plan/${homeworkPlanId}/homework/${homeworkId}/student/${studentId}/homework-delivery-out-of-time`
    await Api.post<string, null>(endpoint, null)
    return true
  } catch (e) {
    return false
  }
}

async function reactivateChallenge (homeworkDeliveryId: string): Promise<boolean> {
  try {
    const endpoint = `/v1/homework-delivery/${homeworkDeliveryId}/reactivate`
    await Api.put<string, null>(endpoint, null)
    return true
  } catch (e) {
    return false
  }
}

async function getHomeworkPlan (courseId: string, homeworkPlanId: string, homeworkId: string): Promise<HomeworkPlan> {
  const challenge = await Api.get<IHomeworkPlan>(`v1/course/${courseId}/homework-plan/${homeworkPlanId}/homework/${homeworkId}`)
  return new HomeworkPlan(challenge)
}

async function getRandomDelivery (
  courseId: string,
  homeworkPlanId: string,
  homeworkId: string
): Promise<{ id: string; status: string; createdAt: string } | null> {
  try {
    const random = await Api.get<{ id: string; status: string; createdAt: string }>(
      `v1/course/${courseId}/homework-plan/${homeworkPlanId}/homework/${homeworkId}/random-delivery`
    )
    return random
  } catch (e) {
    return null
  }
}

async function getCorrection (homeworkDeliveryId: string): Promise<Correction> {
  const reviews = await Api.get<ICorrection>(`/v1/homework-student-delivery/${homeworkDeliveryId}`)
  return reviews
}

async function getComments (homeworkDeliveryId: string): Promise<Array<IAnonimousComment>> {
  const comments = await Api.get<Array<IAnonimousComment>>(`/v1/homework-student-delivery/${homeworkDeliveryId}/comments`)
  return comments
}

async function getEvaluations (homeworkDeliveryId: string) : Promise<IStudentsEvaluation> {
  const evaluations = await Api.get<IStudentsEvaluation>(`/v1/homework-delivery/${homeworkDeliveryId}/evaluation`)
  return evaluations
}

async function sendCorrection (body: ICorrectionDataDto, homeworkDeliveryId: string): Promise<boolean> {
  const send = await Api.post<string, ICorrectionDataDto>(`/v1/homework-delivery/${homeworkDeliveryId}/review`, body)
  return send === 'success'
}

async function qualifyChallenge (body: ICorrectionDataDto, homeworkDeliveryId: string): Promise<boolean> {
  const send = await Api.put<string, ICorrectionDataDto>(`/v1/homework-student-delivery/${homeworkDeliveryId}/qualify`, body)
  return send === 'success'
}

async function requestDelivery (homeworkDeliveryId: string, body?: ICorrectionDataDto): Promise<boolean> {
  const send = await Api.put<string, ICorrectionDataDto | undefined>(
    `/v1/homework-student-delivery/${homeworkDeliveryId}/claim-forwarding`,
    body
  )
  return send === 'success'
}

async function feedbackDelivery (body: ICorrectionDataDto, homeworkDeliveryId: string): Promise<boolean> {
  const send = await Api.put<string, ICorrectionDataDto>(`/v1/homework-student-delivery/${homeworkDeliveryId}/feedback`, body)
  return send === 'success'
}

async function getMyReviews (homeworkPlanId: string, homeworkId: string): Promise<Reviews> {
  const reviews = await Api.get<IReviews>(`/v1/homework-plan/${homeworkPlanId}/homework/${homeworkId}/my-reviews`)
  return reviews
}

async function getMyDelivery (homeworkPlanId: string, homeworkId: string): Promise<ChallengeHistory | null> {
  try {
    const history = await Api.get<IChallengeHistory>(`v1/homework-plan/${homeworkPlanId}/homework/${homeworkId}/my-delivery`)
    return new ChallengeHistory(history)
  } catch (e) {
    return null
  }
}

async function getStudentDelivery (homeworkPlanId: string, homeworkId: string, studentId: string): Promise<ChallengeHistory | null> {
  try {
    const history = await Api.get<IChallengeHistory>(`v1/homework-plan/${homeworkPlanId}/homework/${homeworkId}/student/${studentId}/delivery`)
    return new ChallengeHistory(history)
  } catch (e) {
    return null
  }
}

async function getFile (fileId: string): Promise<any> {
  const file = await Api.get<any, boolean, AxiosRequestConfig>(`/v1/homework-student-delivery/file/${fileId}`)
  return file
}

async function getStudents (courseId: string, homeworkPlanId: string, homeworkId: string): Promise<[TutorChallengeStudent]> {
  let role = ''
  if(getRole() === PersonRoles.Teacher) {
    role = 'teacher-'
  }
  const students = await Api.get<[ITutorChallengeStudents]>(`/v1/course/${courseId}/homework-plan/${homeworkPlanId}/homework/${homeworkId}/${role}students`)
  return students
}

export {
  sendChallenge,
  editChallenge,
  enableChallenge,
  reactivateChallenge,
  getMyDelivery,
  getStudentDelivery,
  getHomeworkPlan,
  getMyReviews,
  getRandomDelivery,
  getCorrection,
  sendCorrection,
  getFile,
  getStudents,
  qualifyChallenge,
  requestDelivery,
  feedbackDelivery,
  getComments,
  getEvaluations,
  sendChallengeRedelivery
}
