
// Components
import Card from '@/components/aero/surfaces/Card.vue'

// Copies
import { StudentPlaceHolder as copies } from '@/locales/challenges/es.json'

export default {
  components: {
    Card
  },
  props: {
    text: { type: String, default: copies.upToDate }
  }
}
