import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-d-flex p-ai-center p-jc-start" }
const _hoisted_2 = { class: "p2 medium text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_icon, {
      size: 16,
      strokeColor: "var(--light-gray)"
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon)))
      ]),
      _: 1
    }),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.text), 1)
  ]))
}