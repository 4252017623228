import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-33b0b4e1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "evaluation-item" }
const _hoisted_2 = { class: "header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Panel, { collapsed: _ctx.collapsed }, {
      header: _withCtx(() => [
        _createElementVNode("div", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args))),
          class: "header-container"
        }, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.header), 1)
        ])
      ]),
      icons: _withCtx(() => [
        _createVNode(_component_Button, {
          class: "button-cleared button-md",
          icon: `pi ${_ctx.collapsed ? 'pi-angle-down' : 'pi-angle-up'}`,
          onClick: _ctx.toggle
        }, null, 8, ["icon", "onClick"])
      ]),
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _: 3
    }, 8, ["collapsed"])
  ]))
}