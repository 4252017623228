
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    height: {
      type: String,
      default: 'var(--space)',
      required: false
    },
    width: {
      type: String,
      default: '100%',
      required: false
    }
  }
})
