import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-338fb0ff"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-d-flex p-jc-between header-container" }
const _hoisted_2 = { class: "p-d-flex p-ai-center" }
const _hoisted_3 = { class: "" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "subtitle" }
const _hoisted_6 = { class: "connection-container" }
const _hoisted_7 = { class: "connection-container--title p-d-flex" }
const _hoisted_8 = { class: "connection-container--logo p-d-flex" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "connection-container--status p-d-flex" }
const _hoisted_11 = ["title"]
const _hoisted_12 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_card = _resolveComponent("card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Dialog, {
      visible: _ctx.displayModal,
      modal: true,
      closable: false,
      draggable: false
    }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.hasConnection ? _ctx.t('profile.connections.disconnect-title') :  _ctx.t('profile.connections.connect-title')), 1),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              class: "close-modal",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal())),
              src: require('@/assets/icons/Light_X.svg')
            }, null, 8, _hoisted_4)
          ])
        ])
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: _ctx.t('profile.connections.cancel'),
          class: "secondary",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closeModal()))
        }, null, 8, ["label"]),
        (_ctx.hasConnection)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              label: _ctx.t('profile.connections.confirm'),
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.removeConnection(_ctx.socialAccounts[0].email, _ctx.socialAccounts[0].provider)))
            }, null, 8, ["label"]))
          : (_openBlock(), _createBlock(_component_Button, {
              key: 1,
              label: _ctx.t('profile.connections.confirm'),
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.addConnection()))
            }, null, 8, ["label"]))
      ]),
      default: _withCtx(() => [
        _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.hasConnection ? _ctx.t('profile.connections.disconnect-info') :  _ctx.t('profile.connections.connect-info')), 1)
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_card, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("h2", null, _toDisplayString(_ctx.t('profile.connections.accounts')), 1)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("img", {
              src: require('@/assets/icons/google.svg'),
              alt: "google icon"
            }, null, 8, _hoisted_9),
            _createElementVNode("span", null, _toDisplayString(_ctx.t('profile.connections.google')), 1)
          ]),
          _createElementVNode("div", _hoisted_10, [
            (_ctx.hasConnection)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  title: _ctx.socialAccounts[0].email
                }, _toDisplayString(_ctx.socialAccounts[0].email), 9, _hoisted_11))
              : (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.t('profile.connections.no-accounts')), 1)),
            _createVNode(_component_Button, {
              label: _ctx.hasConnection ? _ctx.t('profile.connections.disconnect') : _ctx.t('profile.connections.connect'),
              class: "tertiary p-pl-0 newDesign",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.openModal()))
            }, null, 8, ["label"])
          ])
        ])
      ]),
      _: 1
    })
  ], 64))
}