
import { defineComponent, PropType, ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import moment from 'moment'
import { useToast } from 'primevue/usetoast'

import { useUserSessionStore } from '@/store/modules/user-session.store'
import { UserBalance, isBalanceAvailable } from '@/models/profile/balance'
import TransferBalanceModal from '@/views/profile/components/modals/TransferBalanceModal.vue'
import { transferUserBalance } from '@/api/newPlatform/profile.api'

export default defineComponent({
  components: {
    TransferBalanceModal
  },
  props: {
    availableBalances: {
      type: Object as PropType<UserBalance[]>
    }
  },
  emits: ['balanceTransferred'],
  setup(props, { emit }) {
    const { t } = useI18n()
    const { userId } = useUserSessionStore()
    const toast = useToast()

    const transferBalanceModal = ref(false)
    const balanceToTransfer = ref()

    const openModalToTransferBalance = (balance: UserBalance) => {
      transferBalanceModal.value = true
      balanceToTransfer.value = balance
    }

    const closeModal = () => {
      transferBalanceModal.value = false
    }

    const transferBalance = async (userEmail: string, balance: UserBalance) => {
      try {
        await transferUserBalance(userId, userEmail, balance._id)

        toast.add({
          severity: 'success',
          detail: 'El saldo se transfirió correctamente',
          life: 3000
        })
      } catch (err) {
        toast.add({
          severity: 'error',
          detail: 'Ocurrió un error al transferir el saldo',
          life: 3000
        })
      }

      emit('balanceTransferred')

      closeModal()
    }

    const formattedBalances = computed(() => {
      return props.availableBalances?.map((balance) => {
        const createdAt = moment(balance.createdAt).format('DD/MM/YYYY')
        const claimedAt = balance.claimedAt ? moment(balance.claimedAt).format('DD/MM/YYYY') : ''
        const priceWithCommas = (balance.price || 0).toLocaleString('es-AR')
        const status = isBalanceAvailable(balance)
          ? t('profile.student.balance.status.enabled')
          : claimedAt
            ? `${t('profile.student.balance.status.claimed')} ${claimedAt}`
            : 'Solicitado'

        return {
          createdAt,
          price: balance.price,
          formatedPrice: `$ ${priceWithCommas}`,
          status,
          original: balance
        }
      })
    })

    return {
      t,
      formattedBalances,
      transferBalanceModal,
      openModalToTransferBalance,
      closeModal,
      isBalanceAvailable,
      balanceToTransfer,
      transferBalance
    }
  }
})
