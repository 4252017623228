
// Vendor
import { defineComponent, PropType } from 'vue'

// Store
import { useUserStore } from '@/store/modules/user.store'

// Components
import Card from '@/components/aero/surfaces/Card.vue'

// Icons
import Icon from '@/components/aero/icon/Icon.vue'
import { Calendar, Clock, User } from '@/components/aero/icon/templates'

// Copies
import copies from '@/locales/profile/es.json'

// Utils
import { parseToMillis, getDate, getYear } from '@/utils/datetime'
import { capitalizeFirstLetter } from '@/utils/stringParser'

// Models
import { CourseCategory } from '@/models/newPlatform/products/products'

export default defineComponent({
  components: {
    Card,
    Icon,
    Calendar,
    Clock,
    User
  },
  props: {
    courseName: {
      type: String,
      required: true
    },
    imgCategory: {
      type: String as PropType<CourseCategory>,
      required: true
    },
    camadaNro: {
      type: String,
      required: true
    },
    courseDaysAndHours: {
      type: String,
      required: true
    },
    courseStartDate: {
      type: String,
      required: true
    },
    courseEndDate: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { firstName, lastName } = useUserStore()
    const fullName = firstName + ' ' + lastName

    const topInformation = {
      title: copies.studentChangeCourse.information,
      description: `${props.courseName} ${copies.studentChangeCourse.header} ${props.camadaNro}`
    }

    let courseCategory
    if (props.imgCategory === 'design') courseCategory = 'design'
    if (props.imgCategory === 'development' || props.imgCategory === 'cybersecurity') courseCategory = 'development'
    if (props.imgCategory === 'marketing') courseCategory = 'marketing'
    if (props.imgCategory === 'product') courseCategory = 'product'
    if (props.imgCategory === 'business' || props.imgCategory === 'finance' || props.imgCategory === 'data') courseCategory = 'data'

    const schedule = copies.studentChangeCourse.schedule
    const startDate = parseToMillis(props.courseStartDate)
    const endDate = parseToMillis(props.courseEndDate)

    const bottomInformation = [
      {
        icon: 'calendar',
        description: capitalizeFirstLetter(props.courseDaysAndHours)
      },
      {
        icon: 'clock',
        description: `${schedule.from} ${getDate(startDate)} ${schedule.to} ${getDate(endDate)} ${getYear(endDate)}`
      },
      {
        icon: 'user',
        description: fullName
      }
    ]

    return {
      courseCategory,
      topInformation,
      bottomInformation
    }
  }
})
