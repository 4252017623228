import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card", {
      'aero-p-sm': $props.paddingSm,
      'aero-border': $props.border,
      'aero-bg-dark': $props.bgDark,
      'aero-p-md': $props.paddingMd
    }]),
    style: _normalizeStyle($props.styles),
    role: "contentinfo"
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 6))
}