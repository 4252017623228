import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-d-flex p-ai-center p-jc-between" }
const _hoisted_2 = { class: "t2 p-d-flex p-ai-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Badge = _resolveComponent("Badge")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_skeleton_class_card = _resolveComponent("skeleton-class-card")!
  const _component_class_card = _resolveComponent("class-card")!
  const _component_PlaceholderState = _resolveComponent("PlaceholderState")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", _hoisted_2, [
        _createTextVNode(_toDisplayString(_ctx.filterBy === 'all' ? _ctx.copies.content.title : _ctx.filterOptions.find((op) => op.value === _ctx.filterBy)?.label) + " ", 1),
        _createVNode(_component_Badge, {
          value: _ctx.localClasses.length > 0 ? _ctx.localClasses.length : '0',
          class: "p-ml-2"
        }, null, 8, ["value"])
      ]),
      _createVNode(_component_Button, {
        label: _ctx.filterOptions.find((op) => op.value === _ctx.filterBy)?.label,
        class: _normalizeClass(["p-button-filter p-button-sm", [_ctx.menuIsOpen && 'open']]),
        icon: "pi pi-angle-down",
        iconPos: "right",
        onClick: _ctx.toggle,
        "aria-haspopup": "true",
        "aria-controls": "overlay_menu",
        style: {"color":"var(--light-gray)"}
      }, null, 8, ["label", "class", "onClick"]),
      _createVNode(_component_Menu, {
        id: "overlay_menu",
        ref: "menu",
        model: _ctx.filterOptions,
        popup: true,
        onShow: _ctx.menuToggle,
        onHide: _ctx.menuToggle
      }, null, 8, ["model", "onShow", "onHide"])
    ]),
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, _renderList(12, (i) => {
          return _createVNode(_component_skeleton_class_card, { key: i })
        }), 64))
      : (_ctx.localClasses.length > 0)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.localClasses, (_class, index) => {
            return (_openBlock(), _createBlock(_component_class_card, {
              key: _class._id,
              classData: _class,
              nextClass: _ctx.localClasses[index + 1],
              afterclasses: 
    _ctx.filterBy === 'absents' ? null : _ctx.localAfterClasses
        .filter((ac) => ac?.schedule?.stage === _class.schedule.stage)
        .sort((_a, _b) => _a.schedule?.day - _b.schedule?.day),
              optionsListClasses: _ctx.copies.optionsListClasses,
              toolTips: _ctx.copies.toolTips,
              content: _ctx.copies.content,
              role: _ctx.role,
              handleOpenForm: _ctx.handleOpenForm,
              handleOpenDialog: _ctx.handleOpenDialog,
              endDate: _ctx.endDate,
              onCreateAfterClass: _ctx.localHandleAfterClassValue,
              onEditAfterclass: _ctx.localHandleAfterClassValue,
              onDeleteAfterclass: _ctx.localHandleAfterClassValue,
              becaSuspended: _ctx.becaSuspended,
              handleEnableRate: _ctx.handleEnableRate,
              useCoderChallenge: _ctx.useCoderChallenge
            }, null, 8, ["classData", "nextClass", "afterclasses", "optionsListClasses", "toolTips", "content", "role", "handleOpenForm", "handleOpenDialog", "endDate", "onCreateAfterClass", "onEditAfterclass", "onDeleteAfterclass", "becaSuspended", "handleEnableRate", "useCoderChallenge"]))
          }), 128))
        : (_openBlock(), _createBlock(_component_PlaceholderState, {
            key: 2,
            role: _ctx.role,
            isEmpty: true,
            customCopies: _ctx.locales.empty
          }, null, 8, ["role", "customCopies"]))
  ], 64))
}