import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_billing = _resolveComponent("billing")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_course_list = _resolveComponent("course-list")!
  const _component_training_view = _resolveComponent("training-view")!
  const _component_TabView = _resolveComponent("TabView")!

  return (_openBlock(), _createBlock(_component_TabView, null, {
    default: _withCtx(() => [
      _createVNode(_component_TabPanel, {
        header: _ctx.copies.tabViewTeacher.billing
      }, {
        default: _withCtx(() => [
          _createVNode(_component_billing)
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_TabPanel, {
        header: _ctx.copies.tabViewTeacher.training
      }, {
        default: _withCtx(() => [
          _createVNode(_component_course_list),
          _createVNode(_component_training_view, {
            userId: _ctx.userId,
            role: 3
          }, null, 8, ["userId"])
        ]),
        _: 1
      }, 8, ["header"])
    ]),
    _: 1
  }))
}