// Product represent a type of course
// ProductCourse are the incoming courses of the category
// Incoming class is the relevant information from a product course

/**
 * Example:
 * Product: UI/UX Design
 * ProductCourse: 7 UI/UX Design Courses this month, 2 next month
 */

export type CourseCategory = 'marketing' | 'design' | 'development' | 'product' | 'data' | 'cybersecurity' | 'finance' | 'business'

export interface Product {
  _id: string
  // eslint-disable-next-line no-use-before-define
  detail: Detail
  categories: CourseCategory[]
  workshop: boolean
  pack: boolean
  remote: boolean
  career: boolean
  title: string
  description: string
  url: string
  // eslint-disable-next-line no-use-before-define
  programs: Programs
  // eslint-disable-next-line no-use-before-define
  tps: Tp[]
  svgIconString: string
  prerecorded: boolean
  offline: boolean
  service: boolean
  free: boolean
  playbook: boolean
  earlybird: boolean
  flags: { [key: string]: boolean }
  titleShortAlias: string
  titleLongAlias: string
  // eslint-disable-next-line no-use-before-define
  fbPixel: FbPixel
  iconName: string
  // eslint-disable-next-line no-use-before-define
  pricing: Pricing
  order: number
}

export interface Detail {
  weeks: number
  dedicationLevel: string
  dedicationText: string
  teacherWorkHours: number
  hourlyLoadAlias: string
  classesPerWeek: number
  classDurationInHours: number
}

export interface FbPixel {
  sku: string
}

export interface Pricing {
  fullFinalPrice: number
  finalPrice: number
  finalPriceLessIva: number
  price: number
  priceDiscount: number
  finalEnrollmentPrice: number
  enrollmentPrice: number
  enrollmentDiscount: null
  finalQuotaPrice: null
  reenrollPenaltyPrice: number
  changeDatePenaltyPrice: number
  // eslint-disable-next-line no-use-before-define
  original: Original
  // eslint-disable-next-line no-use-before-define
  currency: Currency
  // eslint-disable-next-line no-use-before-define
  earlybirds: Earlybird[]
  levels: any[]
  campaignTitle: null
}

export interface Currency {
  _id: string
  symbol: string
  // eslint-disable-next-line
  iso_code: string
}

export interface Earlybird {
  _id: number
  title: string
  remainingVacants: number
  // eslint-disable-next-line no-use-before-define
  pricing: Original
  cupon: string
  // eslint-disable-next-line no-use-before-define
  flags: Flags
}

export interface Flags {
  isAvailable: number
  noVacancy: boolean
}

export interface Original {
  currency?: Currency
  enrollmentDiscount: number | null
  priceDiscount: number | null
  price: number
  enrollmentPrice: number
  finalEnrollmentPrice: number
  finalPrice: number
  fullFinalPrice: number
  cuponEnrollmentDiscount?: number
  isDifferent?: boolean
}

export interface Programs {
  ar: string
}

export interface Tp {
  fromTypeform: boolean
  highlight: boolean
  enable: boolean
  _id: string
  title: string
  description: string
  thumb: string
}

export enum ProductCategory {
  design = 'design',
  marketing = 'marketing',
  development = 'development',
  product = 'product',
  data = 'data',
  finance = 'finance',
  business = 'business',
  cybersecurity = 'cybersecurity'
}
