import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9adae3ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "classcard__content" }
const _hoisted_2 = { class: "p1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_close_circle = _resolveComponent("close-circle")!
  const _component_icon = _resolveComponent("icon")!
  const _component_check_fill = _resolveComponent("check-fill")!
  const _component_calendar = _resolveComponent("calendar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.userAbsent)
      ? (_openBlock(), _createBlock(_component_icon, {
          key: 0,
          strokeColor: "var(--light-gray)"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_close_circle)
          ]),
          _: 1
        }))
      : (_ctx.checkedClass)
        ? (_openBlock(), _createBlock(_component_icon, {
            key: 1,
            fillColor: "var(--light-gray)",
            strokeColor: "transparent"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_check_fill)
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_icon, {
            key: 2,
            strokeColor: "var(--light-gray)"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_calendar)
            ]),
            _: 1
          })),
    _createElementVNode("div", null, [
      _createElementVNode("p", null, _toDisplayString(_ctx.isAfterclass ? _ctx.copies.afterClass : `${_ctx.copies.class} ${_ctx.order}`), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.formatedDate) + " " + _toDisplayString(_ctx.formatedTime), 1)
    ]),
    _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.data.name), 1)
  ]))
}