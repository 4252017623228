import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cc21fa88"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-d-flex p-mb-6 p-ai-center" }
const _hoisted_2 = { class: "p-mr-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_skeleton_box = _resolveComponent("skeleton-box")!
  const _component_skeleton_text = _resolveComponent("skeleton-text")!
  const _component_card = _resolveComponent("card")!

  return (_openBlock(), _createBlock(_component_card, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_skeleton_box, {
          height: "80px",
          width: "80px",
          class: "p-mr-4",
          style: {"border-radius":"50%"}
        }),
        _createElementVNode("div", null, [
          _createVNode(_component_skeleton_text, {
            length: 20,
            class: "p-mb-3"
          }),
          _createVNode(_component_skeleton_text, { length: 15 })
        ])
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.elements, (i) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "p-d-flex p-ai-center p-mb-5 elements",
          key: i
        }, [
          _createVNode(_component_skeleton_box, {
            height: '60px',
            width: '60px',
            style: {"border-radius":"50%"},
            class: "p-mr-4"
          }),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_skeleton_text, {
              length: 15,
              class: "p-mb-2"
            }),
            _createVNode(_component_skeleton_text, { length: 15 })
          ]),
          _createVNode(_component_skeleton_box, {
            height: "30px",
            width: "30px",
            class: "p-ml-auto"
          })
        ]))
      }), 128))
    ]),
    _: 1
  }))
}