import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_skeleton_text = _resolveComponent("skeleton-text")!
  const _component_skeleton_icon = _resolveComponent("skeleton-icon")!
  const _component_card = _resolveComponent("card")!

  return (_openBlock(), _createBlock(_component_card, { class: "p-mb-3 p-d-flex p-ai-center p-flex-wrap p-jc-between" }, {
    default: _withCtx(() => [
      _createVNode(_component_skeleton_text, {
        length: 4,
        class: "p-mr-4"
      }),
      _createVNode(_component_skeleton_text, {
        length: 4,
        class: "p-mr-4"
      }),
      _createVNode(_component_skeleton_text, {
        length: 8,
        class: "p-mr-4"
      }),
      _createVNode(_component_skeleton_text, {
        length: 4,
        class: "p-mr-4"
      }),
      _createVNode(_component_skeleton_text, {
        length: 4,
        class: "p-mr-4"
      }),
      _createVNode(_component_skeleton_icon, { class: "p-ml-4" })
    ]),
    _: 1
  }))
}