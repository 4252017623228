
import { defineComponent, onMounted, ref, Ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import InputText from 'primevue/inputtext'
import { IJobOfferOption } from '@/models/jobs/jobs-offers'
import { getOptionsByType, getWrangleFilters } from '@/api/job-offers.api'
import { getCountriesCoder } from '@/api/countries.api'
import Icon from '@/components/aero/icon/Icon.vue'
import { ChevronUp } from '@/components/aero/icon/templates'
import Checkbox from 'primevue/checkbox'
import { useFiltersJobOfferStore } from '@/store/modules/filters-job-offer.store'

export default defineComponent({
  emits: ['isSearching'],
  components: {
    InputText,
    Checkbox,
    Icon,
    ChevronUp
  },
  props: {
    hasFilters: { type: String, required: false }
  },
  setup (props, { emit }) {
    const { t } = useI18n()
    const assignedFiltersJobOfferStore = useFiltersJobOfferStore()
    const modalities: Ref<IJobOfferOption[]> = ref([])
    const types: Ref<IJobOfferOption[]> = ref([])
    const areas: Ref<IJobOfferOption[]> = ref([])
    const hierarchies: Ref<IJobOfferOption[]> = ref([])
    const countries: Ref<IJobOfferOption[]> = ref([])
    const search = ref('')
    const modalitiesSelected: Ref<string[]> = ref([])
    const typesSelected: Ref<string[]> = ref([])
    const areasSelected: Ref<string[]> = ref([])
    const hierarchiesSelected: Ref<string[]> = ref([])
    const countriesSelected: Ref<string[]> = ref([])
    const selectedCountry = ref<string>('')
    const isAreaOpen = ref<boolean>(true)
    const isHierarchyOpen = ref<boolean>(true)
    const isModalityOpen = ref<boolean>(true)
    const isTypeOpen = ref<boolean>(true)
    const isCountryOpen = ref<boolean>(true)
    const filters = ref()
    let timeOutID: any

    const getModalities = async () => {
      const modalitiesFromApi = await getOptionsByType('modalities')
      modalities.value = modalitiesFromApi.map(({ value, _id }: {value: string, _id: string}) => {
        return { name: value, value: _id }
      })
    }
    const getAreas = async () => {
      // const areasFromApi = await getOptionsByType('areas')
      const areasFromApi = [
        {
          _id: 'Administrative',
          value: 'Administrative'
        },
        {
          _id: 'Information Technology',
          value: 'Information Technology'
        },
        {
          _id: 'Manufacturing',
          value: 'Manufacturing'
        },
        {
          _id: 'Accounting/Auditing',
          value: 'Accounting/Auditing'
        },
        {
          _id: 'Consulting',
          value: 'Consulting'
        },
        {
          _id: 'Product',
          value: 'Product'
        },
        {
          _id: 'Data',
          value: 'Data'
        },
        {
          _id: 'Marketing',
          value: 'Marketing'
        },
        {
          _id: 'Design',
          value: 'Design'
        },
        {
          _id: 'Management',
          value: 'Management'
        },
        {
          _id: 'Sales',
          value: 'Sales'
        },
        {
          _id: 'Finance',
          value: 'Finance'
        },
        {
          _id: 'Engineering',
          value: 'Engineering'
        },
        {
          _id: 'Operations',
          value: 'Operations'
        },
        {
          _id: 'Support',
          value: 'Support'
        }
      ]
      areas.value = areasFromApi.map(({ value, _id }: {value: string, _id: string}) => {
        return { name: value, value: _id }
      })
    }
    const getTypes = async () => {
      const typesFromApi = await getOptionsByType('types')
      types.value = typesFromApi.map(({ value, _id }: {value: string, _id: string}) => {
        return { name: value, value: _id }
      })
    }
    const getHierarchy = async () => {
      // const hierarchiesFromApi = await getOptionsByType('hierarchies')

      const hierarchiesFromApi = [
        {
          _id: 'Associate',
          value: 'Associate'
        },
        {
          _id: 'Entry level',
          value: 'Entry level'
        },
        {
          _id: 'Mid-level',
          value: 'Mid-level'
        },
        {
          _id: 'Mid-Senior level',
          value: 'Mid-Senior level'
        },
        {
          _id: 'Senior',
          value: 'Senior'
        },
        {
          _id: 'Manager',
          value: 'Manager'
        },
        {
          _id: 'Director',
          value: 'Director'
        },
        {
          _id: 'Executive',
          value: 'Executive'
        }
      ]
      hierarchies.value = hierarchiesFromApi.map(({ value, _id }: {value: string, _id: string}) => {
        return { name: value, value: _id }
      })
    }
    const getCountries = async () => {
      // const countriesFromApi = await getCountriesCoder()
      // countries.value = countriesFromApi.map(({ name, code }: {name: string, code: string}) => {
      //   return { name, value: code.toLowerCase() }
      // })
      countries.value = [
        {
            'name': 'Argentina',
            'value': 'ar'
        },
        {
            'name': 'Perú',
            'value': 'pe'
        },
        {
            'name': 'Chile',
            'value': 'cl'
        },
        {
            'name': 'Brasil',
            'value': 'br'
        },
        {
            'name': 'Uruguay',
            'value': 'uy'
        },
        {
            'name': 'Colombia',
            'value': 'co'
        },
        {
            'name': 'México',
            'value': 'mx'
        }
      ]
    }

    const getFilters = async () => {
      filters.value = await getWrangleFilters()
      if (filters.value) {
        filters.value.categories?.forEach((area: any) => {
          areas.value.push({name: area, value: area})
        })
        filters.value.hierarchies?.forEach((seniority: any) => {
          hierarchies.value.push({name: seniority, value: seniority})
        })
      }
    }

    onMounted(async () => {
      if (window.innerWidth <= 1183) {
        isAreaOpen.value = false
        isHierarchyOpen.value = false
        isModalityOpen.value = false
        isTypeOpen.value = false
        isCountryOpen.value = false
      }
      // await getAreas()
      await getModalities()
      await getTypes()
      // await getHierarchy()
      await getCountries()
      await getFilters()
    })

    watch(search, () => {
      assignedFiltersJobOfferStore.updateSearch(search.value)
      if(search.value && search.value.length > 0) {
        emit('isSearching', { isSearching: true })
        if (timeOutID) clearTimeout(timeOutID)
        timeOutID = setTimeout( () => {
          window.dataLayer.push({
            event: 'trackEvent',
            eventCategory: 'bolsa_de_empleo',
            eventAction: 'buscador',
            eventLabel: `${search.value}`
          })
          }, 2000)
      } else{
        emit('isSearching', { isSearching: false })
      }
    })

    watch(modalitiesSelected, () => {
      assignedFiltersJobOfferStore.updateModalities(modalitiesSelected.value)
      if(modalitiesSelected.value.length > 0) {
        const lastModalitySelectedName = getLastFilterName(modalitiesSelected.value, modalities.value)
        window.dataLayer.push({
          event: 'trackEvent',
          eventCategory: 'bolsa_de_empleo',
          eventAction: 'clic-filtro',
          eventLabel: `${lastModalitySelectedName}`
        })
      }
    })

    watch(typesSelected, () => {
      assignedFiltersJobOfferStore.updateTypes(typesSelected.value)
      if(typesSelected.value.length > 0) {
        const lastTypeSelectedName = getLastFilterName(typesSelected.value, types.value)
        window.dataLayer.push({
          event: 'trackEvent',
          eventCategory: 'bolsa_de_empleo',
          eventAction: 'clic-filtro',
          eventLabel: `${lastTypeSelectedName}`
        })
      }
    })

    watch(areasSelected, () => {
      assignedFiltersJobOfferStore.updateAreas(areasSelected.value)
      if(areasSelected.value.length > 0) {
        const lastAreaSelectedName = getLastFilterName(areasSelected.value, areas.value)
        window.dataLayer.push({
          event: 'trackEvent',
          eventCategory: 'bolsa_de_empleo',
          eventAction: 'clic-filtro',
          eventLabel: `${lastAreaSelectedName}`
        })
      }
    })

    watch(hierarchiesSelected, () => {
      assignedFiltersJobOfferStore.updateHierarchies(hierarchiesSelected.value)
      if(hierarchiesSelected.value.length > 0) {
        const lastHierarchySelectedName = getLastFilterName(hierarchiesSelected.value, hierarchies.value)
        window.dataLayer.push({
          event: 'trackEvent',
          eventCategory: 'bolsa_de_empleo',
          eventAction: 'clic-filtro',
          eventLabel: `${lastHierarchySelectedName}`
        })
      }
    })

    watch(countriesSelected, () => {
      assignedFiltersJobOfferStore.updateCountries(countriesSelected.value)
      if(countriesSelected.value.length > 0) {
        const lastCountrySelectedName = getLastFilterName(countriesSelected.value, countries.value)
        window.dataLayer.push({
          event: 'trackEvent',
          eventCategory: 'bolsa_de_empleo',
          eventAction: 'clic-filtro',
          eventLabel: `${lastCountrySelectedName}`
        })
      }
    })

    const getLastFilterName = (filterSelected: any, filters: any) => {
      const lastFilterSelected = filterSelected.length-1
      const lastLiterSelectedId = filterSelected[lastFilterSelected]
      const lastFilterSelectedName = filters.find((filter: any) => filter.value === lastLiterSelectedId)?.name
      return lastFilterSelectedName
    }

    const clearFilters = () => {
      // if (search.value) {
      //   search.value = ''
      // }
      if (modalitiesSelected.value.length) {
        modalitiesSelected.value = []
      }
      if (typesSelected.value.length) {
        typesSelected.value = []
      }
      if (areasSelected.value.length) {
        areasSelected.value = []
      }
      if (hierarchiesSelected.value.length) {
        hierarchiesSelected.value = []
      }
      if (countriesSelected.value.length) {
        countriesSelected.value = []
      }
      search.value = ''
    }

    return { 
      t,
      isAreaOpen,
      isHierarchyOpen,
      isModalityOpen,
      isTypeOpen,
      isCountryOpen,
      modalities,
      areas,
      types,
      hierarchies,
      countries,
      search,
      modalitiesSelected,
      typesSelected,
      areasSelected,
      hierarchiesSelected,
      countriesSelected,
      clearFilters,
      selectedCountry
    }
  }
})
