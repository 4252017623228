
import { defineComponent, PropType, Ref, ref, watch } from 'vue'
import { IUser as UserInfo } from '@/models/user'
import Calendar from 'primevue/calendar'
import Checkbox from 'primevue/checkbox'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    Calendar,
    Checkbox
  },
  props: {
    user: { type: Object as PropType<UserInfo>, required: true },
    education: { type: Object, required: true }
  },
  emits: ['changeEducationTitle',
          'changeEducationInstitute',
          'changeEducationFrom',
          'changeEducationTo',
          'changeCurrentlyStudying'
         ],
  setup (props, { emit }) {
    const { t } = useI18n()
    const currentlyStudying: Ref<boolean> = ref(false)
    const from = ref<Date>(new Date())
    const to = ref<Date>()

    const changeCurrentlyStudying = () => {
      emit('changeCurrentlyStudying', currentlyStudying.value)
      if (currentlyStudying.value) {
        to.value = undefined
      }
    }

    const changeEducationTitle = (event: Event) => {
      const { target } = event
      if (target) {
        emit('changeEducationTitle', (target as HTMLInputElement).value)
      }
    }

    const changeEducationInstitute = (event: Event) => {
      const { target } = event
      if (target) {
        emit('changeEducationInstitute', (target as HTMLInputElement).value)
      }
    }

    const validCalendarDate: (date: Date) => boolean = (date) => {
      try {
        return typeof date.getTime() === 'number'
      } catch (error) {
        return false
      }
    }

    watch([from, to], () => {
      if (validCalendarDate(from.value)) {
        emit('changeEducationFrom', (from.value).toISOString())
      }
      if (!to.value || validCalendarDate(to.value)) {
        emit('changeEducationTo', (to.value)?.toISOString())
      }
    })

    return {
      t,
      from,
      to,
      currentlyStudying,
      changeEducationTitle,
      changeEducationInstitute,
      changeCurrentlyStudying
    }
  }
})
