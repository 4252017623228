
// Vendors
import { defineComponent } from 'vue'

// Components
import Avatar from '@/components/aero/dataDisplay/Avatar.vue'

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true
    },
    imageLink: {
      type: String,
      required: false
    },
    totalRows: {
      type: Number,
      required: true
    },
    points: {
      type: Number,
      required: true
    },
    rank: {
      type: Number,
      required: true
    },
    featured: {
      type: Boolean,
      required: false
    }
  },
  components: { Avatar },
  setup(props) {
    const shouldBeSticky = window.innerHeight < 56 * props.totalRows

    return { shouldBeSticky }
  }
})
