<template>
  <path
    d="M20.1818 3H3.81818C3.36631 3 3 3.36631 3 3.81818V20.1818C3 20.6337 3.36631 21 3.81818 21H20.1818C20.6337 21 21 20.6337 21 20.1818V3.81818C21 3.36631 20.6337 3 20.1818 3Z"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path d="M11.25 10.5001V16.5001" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  <path d="M8.25 10.5001V16.5001" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  <path
    d="M9.3125 7.50006C9.3125 8.08686 8.8368 8.56256 8.25 8.56256C7.6632 8.56256 7.1875 8.08686 7.1875 7.50006C7.1875 6.91326 7.6632 6.43756 8.25 6.43756C8.8368 6.43756 9.3125 6.91326 9.3125 7.50006Z"
    stroke-width="0.125"
  />
  <path
    d="M11.25 13.1251C11.25 12.4289 11.5266 11.7612 12.0188 11.2689C12.5111 10.7766 13.1788 10.5001 13.875 10.5001C14.5712 10.5001 15.2389 10.7766 15.7312 11.2689C16.2234 11.7612 16.5 12.4289 16.5 13.1251V16.5001"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</template>
