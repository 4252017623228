
import { defineComponent, PropType } from 'vue'

// Components

import Avatar from '@/components/aero/dataDisplay/Avatar.vue'

export default defineComponent({
  components: { Avatar },

  props: {
    name: { type: String, required: true },
    category: { type: String, required: true },
    imageLink: { type: String, default: '' },
    size: { type: String as PropType<'medium' | 'large'>, default: 'medium', required: false },
    link: { type: String, required: false },
    blocked: { type: Boolean, default: false }
  }
})
