
import { defineComponent, PropType, ref, watch } from 'vue'
import { DateTime } from 'luxon'

// Custom hooks
import useBeginsIn from '@/hooks/classes/useBeginsIn'
import useGetClassTitle from '@/hooks/classes/useGetClassTitle'
import { useChallengeBtn } from '@/hooks/classes/useChallengesBtn'

// Components
import Button from 'primevue/button'
import Icon from '@/components/aero/icon/Icon.vue'
import Clock from '@/components/aero/icon/templates/clock.vue'
import Card from '@/components/aero/surfaces/Card.vue'

// Models
import { ClassUser } from '@/models/classes/class'

// Utils
import { getTime } from '@/utils/datetime'

// Amplitude
import { ClassesEvent } from '@/amplitude/constants'
import { trackEvent } from '@/amplitude/actions'

export default defineComponent({
  components: { Card, Icon, Clock, Button },
  name: 'DateCard',
  props: {
    data: {
      type: Object as PropType<ClassUser>,
      required: true
    },
    isAfterclass: { type: Boolean, default: false, required: false },
    role: {
      type: Number,
      default: 2, // student 2, teacher 3
      required: false
    },
    handleOpenForm: {
      type: Function,
      required: false,
      default: () => null
    },
    endDate: { type: Date, required: true },
    copies: {
      type: Object,
      required: true
    },
    camadaNro: {
      type: String,
      required: true
    },
    becaSuspended: { type: Boolean, required: true },
    handleEnableRate: { type: Function }
  },
  setup(props, context) {
    const dt = DateTime.fromMillis(props.data.schedule.day)
    const showCountdown = ref<boolean>(false)
    const showZoomBtn = ref<boolean>(false)
    const formattedTime = getTime(props.data.schedule.from)
    const { title } = useGetClassTitle(props.data.schedule.day, props.isAfterclass)
    const { beginsIn } = useBeginsIn(props.data.schedule.day)
    const shortDay = DateTime.fromMillis(props.data.schedule.day).toFormat('ccc').toLocaleString().toUpperCase().slice(0, 2)
    const { ZOOM_COUNTDOWN_TIME, ZOOM_ENABLED_TIME } = process?.env
    const isFeedbackCompleted = ref<boolean>(props.data.feedback?.isFeedbackCompleted || false)
    const isFeedbackExpired = ref<boolean>(props.data.feedback?.isFeedbackExpired || false)
    const isFeedbackEnabled = ref<boolean>(props.data.feedback?.isFeedbackEnable || false)

    const challengesList =
      props.role === 2 && (props.data.modules?.project?.hasDelivers || props.data.modules?.project?.isFinalDeliver)
        ? useChallengeBtn('short', props.data.modules?.project?.module)
        : []

    watch(beginsIn, () => {
      showZoomBtn.value = Boolean(
        (beginsIn.value > 0 && beginsIn.value <= (Number(ZOOM_ENABLED_TIME) || 30) && props.data.videoLink?.liveVideoLink) ||
          (props.data.schedule.from < Date.now() && props.data.schedule.to > Date.now() && props.data.videoLink?.liveVideoLink)
      )

      if (beginsIn.value > 0 && beginsIn.value <= (Number(ZOOM_COUNTDOWN_TIME) || 60)) {
        return (showCountdown.value = true)
      } else {
        return (showCountdown.value = false)
      }
    })

    const handleCreateAfterClass = () => {
      props.handleOpenForm()
      handleTrackEvent(ClassesEvent.START_BOOKING_AFTERCLASS)
      context.emit('createAfterClass', props.data.schedule.stage, '')
    }

    // Amplitude track event
    const handleTrackEvent = (name: string) => {
      trackEvent(name, { origin: 'this week cards' })
    }

    const courseEnded = DateTime.fromJSDate(props.endDate).toMillis() < Date.now()

    return {
      title,
      beginsIn,
      dt,
      formattedTime,
      showCountdown,
      showZoomBtn,
      challengesList,
      handleCreateAfterClass,
      handleTrackEvent,
      courseEnded,
      ClassesEvent,
      shortDay,
      isFeedbackCompleted,
      isFeedbackExpired,
      isFeedbackEnabled
    }
  }
})
