
// Vendor
import { defineComponent, ref, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'

// Components
import SkeletonText from '@/components/aero/skeleton/SkeletonText.vue'

// Icons
import Icon from '../icon/Icon.vue'
import ChevronDown from '../icon/templates/chevron-down.vue'

// Store
import { useCourseStore } from '@/store/modules/course.store'
import { useUserSessionStore } from '@/store/modules/user-session.store'
import { useUserStore } from '@/store/modules/user.store'

// Models
import { Course } from '@/models/navigation/course'

// Services
import { getCourses } from '@/services/newPlatform/navigation.services'

// Copies
import copies from '@/locales/navigation/es.json'

// Utils
import { getRole } from '@/utils/roles'

// Amplitude
import { setUserProperties, trackEvent } from '@/amplitude/actions'
import { TopBarEvents } from '@/amplitude/constants'

export default defineComponent({
  components: { SkeletonText, Icon, ChevronDown },
  setup() {
    const router = useRouter()
    const courseFromStore = useCourseStore()
    const session = useUserSessionStore()
    const user = useUserStore()

    const courses = ref<Array<Course>>([])
    const isLoading = ref<boolean>(true)
    const hasError = ref<boolean>(false)
    const selectedCourse = ref<string>(courseFromStore.id)

    const widthFactor = user.role === 2 ? 9 : 13
    const selectWidth = ref<number>(courseFromStore.name.length * widthFactor)

    const handleSelectedValue = (e: Event) => {
      const { target } = e
      const { camadaNro, camadaId, id, name, sharedFolderLink, teachersSharedFolderLink, isEnded, isStarted, isInProgress, hasCoderAsk, hasCoderAskActive } =
        JSON.parse((target as HTMLInputElement).value)

      selectWidth.value = name.length * widthFactor

      courseFromStore.setCourse({
        id: id,
        camadaNro: camadaNro,
        camadaId: camadaId,
        name: name,
        sharedFolderLink: sharedFolderLink,
        teachersSharedFolderLink: teachersSharedFolderLink,
        isEnded,
        isStarted,
        isInProgress,
        hasCoderAsk,
        hasCoderAskActive
      })

      trackEvent(TopBarEvents.CHANGE_COMISSION, { 'Switch to': camadaNro })

      router.push('/dashboard')
    }

    const handleSetUserProperties = (courses: Course[]) => {
      let userTmp = {
        email: user.email,
        role: getRole()
      }

      courses.forEach((course) => {
        userTmp = {
          ...userTmp,
          [course.name]: {
            camadaNro: course.camadaNro,
            courseStatus: course.isEnded ? 'finished' : !course.isStarted ? 'not started' : 'in progress',
            beca: !!course.hasCoderBeca
          }
        }
      })

      setUserProperties(userTmp)
    }

    const handleMounted = async () => {
      try {
        const response: Course[] = await getCourses(session.userId, user.role)

        if (response.length === 0) {
          isLoading.value = false
          return
        }

        // If localstorage is empty
        if (!courseFromStore.name) {
          selectedCourse.value = response[0].id
          selectWidth.value = response[0].name.length * widthFactor

          courseFromStore.setCourse({
            id: response[0].id,
            camadaNro: response[0].camadaNro.toString(),
            camadaId: response[0].camadaId,
            name: response[0].name,
            sharedFolderLink: response[0].sharedFolderLink,
            teachersSharedFolderLink: response[0].teachersSharedFolderLink,
            isEnded: response[0].isEnded,
            isStarted: response[0].isStarted,
            isInProgress: response[0].isInProgress,
            hasCoderAsk: response[0].flags.hasCoderAsk,
            hasCoderAskActive: response[0].flags.hasCoderAskActive
          })
        }

        // set amplitude user properties
        handleSetUserProperties(response)

        courses.value = response
      } catch {
        hasError.value = true
      } finally {
        isLoading.value = false
      }
    }

    onMounted(handleMounted)

    watch(session, async () => {
      if (process.env.MOCK_USER === '1') {
        await user.getUser()
        isLoading.value = true
        handleMounted()
        router.push('/dashboard')
      }
    })

    return {
      courses,
      isLoading,
      selectedCourse,
      selectWidth,
      role: user.role,
      handleSelectedValue,
      hasError,
      copies
    }
  }
})
