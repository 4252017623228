import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['dynamic-card', { borderless: _ctx.borderless, shadowless: _ctx.shadowless }]),
    style: _normalizeStyle(_ctx.backgroundColor && `background-color: ${_ctx.backgroundColor};`)
  }, [
    _renderSlot(_ctx.$slots, "pre-header", {}, undefined, true),
    (_ctx.title)
      ? _renderSlot(_ctx.$slots, "header", { key: 0 }, () => [
          _createElementVNode("h6", {
            class: _normalizeClass(`${_ctx.titlePaddingX} custom-padding-y`)
          }, _toDisplayString(_ctx.title), 3)
        ], true)
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
  ], 6))
}