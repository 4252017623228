<template>
  <g>
    <g filter="url(#filter0_d_825_6578)">
      <path
        d="M49.3 88.5C48.8045 88.3278 48.2797 88.256 47.7562 88.2888C47.2326 88.3216 46.7209 88.4584 46.2508 88.6911C45.7807 88.9238 45.3617 89.2479 45.0181 89.6443C44.6746 90.0407 44.4135 90.5016 44.25 91C40.95 100.85 30.3 103.9 24.15 104.85C25.1 98.7 28.15 88.05 38 84.75C38.5393 84.6193 39.0456 84.3779 39.4866 84.041C39.9276 83.7041 40.2936 83.2792 40.5615 82.7931C40.8293 82.3071 40.9931 81.7707 41.0425 81.2179C41.0918 80.6652 41.0255 80.1082 40.848 79.5825C40.6704 79.0567 40.3854 78.5736 40.011 78.164C39.6366 77.7544 39.1811 77.4271 38.6734 77.203C38.1657 76.9789 37.617 76.8629 37.062 76.8624C36.5071 76.8619 35.9581 76.9769 35.45 77.2C27.25 79.9 21.25 86.4 18.05 95.95C16.6321 100.246 15.8571 104.728 15.75 109.25C15.75 110.311 16.1714 111.328 16.9216 112.078C17.6717 112.829 18.6891 113.25 19.75 113.25C24.2724 113.143 28.7542 112.368 33.05 110.95C42.6 107.75 49.1 101.75 51.8 93.55C51.9722 93.0545 52.044 92.5297 52.0112 92.0062C51.9784 91.4826 51.8416 90.9709 51.6089 90.5008C51.3762 90.0307 51.0521 89.6117 50.6557 89.2681C50.2593 88.9246 49.7984 88.6635 49.3 88.5Z"
        fill="#EAFF6A"
      />
    </g>
    <g filter="url(#filter1_bii_825_6578)">
      <path
        d="M114.8 20.8999C114.566 19.2057 113.784 17.6345 112.575 16.4251C111.365 15.2157 109.794 14.4344 108.1 14.1999C102.45 13.3499 87.8 12.9499 73.5 27.2499L69 31.6999H38.35C36.2318 31.7144 34.2036 32.558 32.7 34.0499L16.55 50.2499C15.5259 51.2684 14.7986 52.5466 14.4463 53.9473C14.0939 55.3479 14.1298 56.8181 14.55 58.1999C14.9669 59.5918 15.755 60.8438 16.8297 61.8215C17.9044 62.7993 19.2251 63.466 20.65 63.7499L40.4 67.6999L61.3 88.5999L65.25 108.35C65.534 109.775 66.2006 111.096 67.1784 112.17C68.1562 113.245 69.4082 114.033 70.8 114.45C71.5432 114.688 72.3196 114.806 73.1 114.8C74.1515 114.809 75.1941 114.606 76.1651 114.202C77.136 113.798 78.0153 113.202 78.75 112.45L94.95 96.2999C96.442 94.7963 97.2856 92.7681 97.3 90.6499V59.9999L101.75 55.4999C116.05 41.1999 115.65 26.5499 114.8 20.8999ZM38.35 39.6999H61L41.05 59.6499L22.2 55.8999L38.35 39.6999ZM89.3 90.6499L73.1 106.8L69.35 87.9499L89.3 67.9999V90.6499Z"
        fill="white"
        fill-opacity="0.03"
      />
    </g>
    <g filter="url(#filter2_i_825_6578)">
      <path d="M60.7981 40L38.1709 40L22 56.1709L40.8561 59.9419L60.7981 40Z" fill="white" fill-opacity="0.06" />
      <path d="M60.7981 40L38.1709 40L22 56.1709L40.8561 59.9419L60.7981 40Z" fill="white" fill-opacity="0.06" />
    </g>
    <g filter="url(#filter3_i_825_6578)">
      <path d="M89.5 68L89.5 90.5L73 107L69 88L89.5 68Z" fill="white" fill-opacity="0.06" />
      <path d="M89.5 68L89.5 90.5L73 107L69 88L89.5 68Z" fill="white" fill-opacity="0.06" />
    </g>
    <defs>
      <filter
        id="filter0_d_825_6578"
        x="0.75"
        y="61.8624"
        width="66.269"
        height="66.3876"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="7.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.917647 0 0 0 0 1 0 0 0 0 0.415686 0 0 0 1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_825_6578" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_825_6578" result="shape" />
      </filter>
      <filter
        id="filter1_bii_825_6578"
        x="-0.818695"
        y="-1.16904"
        width="130.988"
        height="130.994"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImage" stdDeviation="7.51237" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_825_6578" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_825_6578" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="0.698826" />
        <feGaussianBlur stdDeviation="0.349413" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_825_6578" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="5.94002" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.05 0" />
        <feBlend mode="normal" in2="effect2_innerShadow_825_6578" result="effect3_innerShadow_825_6578" />
      </filter>
      <filter
        id="filter2_i_825_6578"
        x="22"
        y="40"
        width="38.7981"
        height="20.2913"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="0.349413" />
        <feGaussianBlur stdDeviation="0.174706" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.3 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_825_6578" />
      </filter>
      <filter
        id="filter3_i_825_6578"
        x="69"
        y="68"
        width="20.5"
        height="39.3494"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="0.349413" />
        <feGaussianBlur stdDeviation="0.174706" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.3 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_825_6578" />
      </filter>
    </defs>
  </g>
</template>