import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-d-flex p-flex-column" }
const _hoisted_2 = { class: "p2 text-light-gray p-mb-1 label__uppercase" }
const _hoisted_3 = { class: "p2 smallCard__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { class: "smallCard p-d-flex p-jc-between p-ai-center" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.title), 1),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.description), 1)
      ]),
      _createElementVNode("span", {
        class: _normalizeClass([_ctx.colorAmount, "bold"])
      }, _toDisplayString(_ctx.amount), 3)
    ]),
    _: 1
  }))
}