
// Vendor
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  components: {},
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    anchor: {
      type: Object as PropType<{ link: string; text: string }>
    },
    borderColorClass: {
      type: String /* Utily classes for border e.g. => border-error  */,
      default: 'error'
    }
  }
})
