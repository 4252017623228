import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3bf857da"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "banner-wrapper" }
const _hoisted_2 = { class: "banner-text" }
const _hoisted_3 = { class: "banner-text-title p1 p-mb-1" }
const _hoisted_4 = {
  key: 0,
  class: "banner-text-message p2"
}
const _hoisted_5 = { class: "banner-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.title), 1),
      (!!_ctx.message)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.message), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}