
import { defineComponent, onMounted, PropType, ref, Ref, watch } from 'vue'
import { useUserSessionStore } from '@/store/modules/user-session.store'
import { useUserStore } from '@/store/modules/user.store'
import { getLanguagesList, getLanguageLevelsList, setLanguagesUser  } from '@/services/newPlatform/profile.services'
import { Language } from '@/models/profile/profile'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import Listbox  from 'primevue/listbox'
import copies from '@/locales/profile/es.json'

export default defineComponent({
  components: { Accordion, AccordionTab, Listbox },
  props: { 
    displayModal: { type: Boolean, default: false },
    languagesUser: { type: Object as PropType<Language[]>} 
  },
  setup(props, { emit }) {
    const profileLanguages: any = copies.editProfile.profileLanguages
    const { userId } = useUserSessionStore()
    const { country } = useUserStore()
    const modal = ref(false)
    const languages: Ref<Language[]> = ref([])
    const levels: Ref<{key: string, value: string}[]> = ref([])
    const selectedLanguages: Ref<Ref<{value: string, level:string}>[]> = ref([])
    const languagesFiltered: Ref<Language[]> = ref([])

    const languageAccordion: Ref<any>[] = []
    const levelAccordion: Ref<any>[] = []
  
    const closeModal = () => {
      selectedLanguages.value = selectedLanguages.value.filter( (language: Ref<{value: string, level:string}>) => language.value?.value && language.value?.level)
      emit('closeModal')
    }

    const generateRefLanguages = () => {
      if(!props.languagesUser?.length){
        return [ref({ level: '', value: ''})]
      }

      return props.languagesUser.map( language => {
        return ref({ level: language.level, value: language.value })
      })
    }

    const languagesFilter = () => {
      const selectedFilter = selectedLanguages.value.map( (language: Ref<{value: string, level:string}>) => language.value.value )
      languagesFiltered.value = languages.value.filter(language => !selectedFilter.includes(language.value))      
    }

    const handleMountedComponent = async () => {
      languages.value = await getLanguagesList(country)
      levels.value = await getLanguageLevelsList(country)
      selectedLanguages.value = generateRefLanguages()
      languagesFilter()
    }

    watch(props, () => {
      modal.value = props.displayModal
      selectedLanguages.value = generateRefLanguages()
      languagesFilter()
    })

    onMounted(handleMountedComponent)

    const addLanguage = () => {
      selectedLanguages.value.push(ref({ level: '', value: '' }))
    }

    const saveLanguages = async () => {

      const dataLanguages = selectedLanguages.value.filter( (language: Ref<{value: string, level:string}>) => language.value?.value && language.value?.level)
      .map( (language: Ref<{value: string, level:string}>) => language.value )

      await setLanguagesUser(userId, dataLanguages)
      closeModal()
    }

    const closeAllLanguage = () =>{
      languageAccordion.forEach((element: any) => {
        if( element && ({}).hasOwnProperty.call(element, 'd_activeIndex')) element.d_activeIndex = null
      })
    }

    const closeAllLevel = () =>{
      levelAccordion.forEach((element: any) => {
        if( element && ({}).hasOwnProperty.call(element, 'd_activeIndex')) element.d_activeIndex = null
      })
    }
    
    const calculateScrollLanguage = (index: number) => {
      const offsetTopHeader = (document.getElementById(`accordionLanguage_${index}`) as HTMLElement).offsetTop
      const idList = (document.getElementById(`accordionLanguage_${index}`)?.childNodes[1].childNodes[1] as HTMLElement).id
      const listBox = document.getElementById(idList)
      const currentScroll = document.getElementsByClassName('p-dialog-content')[0].scrollTop
      
      if(listBox) listBox.style.top = `${offsetTopHeader + 45 - currentScroll}px`

      languageAccordion.forEach((element:any, i:number) => {
        if(i !== index &&  element && ({}).hasOwnProperty.call(element, 'd_activeIndex')) element.d_activeIndex = null
      })

      levelAccordion.forEach((element:any, i:number) => {
        if( element && ({}).hasOwnProperty.call(element, 'd_activeIndex')) element.d_activeIndex = null
      })
    }

    const calculateScrollLevel = (index: number) => {
      const offsetTopHeader = (document.getElementById(`accordionLevel_${index}`) as HTMLElement).offsetTop
      const idList = (document.getElementById(`accordionLevel_${index}`)?.childNodes[1].childNodes[1] as HTMLElement).id
      const listBox = document.getElementById(idList)
      const currentScroll = document.getElementsByClassName('p-dialog-content')[0].scrollTop

      if(listBox) listBox.style.top = `${offsetTopHeader + 45 - currentScroll}px` 

      levelAccordion.forEach((element:any, i:number) => {
        if(i !== index &&  element && ({}).hasOwnProperty.call(element, 'd_activeIndex')) element.d_activeIndex = null
      })

      languageAccordion.forEach((element:any, i:number) => {
        if( element && ({}).hasOwnProperty.call(element, 'd_activeIndex')) element.d_activeIndex = null
      })
    }

    return { closeModal, modal, languages, levels, selectedLanguages, addLanguage, saveLanguages, languagesFilter, calculateScrollLanguage, closeAllLanguage, calculateScrollLevel, closeAllLevel, languageAccordion, levelAccordion, languagesFiltered, profileLanguages }
  }
})
