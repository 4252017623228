/* eslint-disable no-control-regex */
export enum LabelLinks {
  slack = 'Slack',
  sharedFolderLink = 'Carpeta Compartida',
  teachersSharedFolderLink = 'Carpeta Docente'
}

export enum SummaryRole {
  teacher = 'teacher',
  tutor = 'tutor',
  content = 'content'
}

export enum FormatDates {
  LatinAmerica = 'dd/MM/yy',
  LatinAmericaShort = 'dd/MM'
}

export enum TagsColumnFields {
  Student = 'student',
  Rating = 'rtg',
  TagText = 'text',
  Total = 'total'
}

export enum PersonRoles {
  Mentor = 'mentor',
  Tutor = 'tutor',
  Student = 'student',
  Teacher = 'teacher',
  Free = 'free',
  Corporate = 'corporate',
  TrainingManager = 'trainingManager',
  Supervisor = 'supervisor',
  Assistant = 'assistant',
  Coordinator = 'coordinator',
  Influencer = 'influencer'
}

export enum IdentityTypes {
  Dni = 'DNI',
  Passport = 'passport',
  Rut = 'rut',
  Rg = 'rg',
  Curp = 'curp'
}

export const REGEX = {
  LINKEDIN: /https:\/\/[a-z]{2,3}\.linkedin\.com\/(\w*\/)*/,
  URL: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
  EMAIL:
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
}

export enum PERSONAL_GOAL {
  OTHER = 'other',
  FREELANCE = 'freelance',
  ENTREPRENEUR = 'entrepreneur',
  PROFESSIONAL = 'professional'
}
