import { IdentityTypes, PersonRoles } from '../enums'

export interface IIdentityDocument {
  identity: string
  identityType: IdentityTypes
}

export interface ISocialNetworks {
  instagram: string
  github: string
  linkedin: string
  facebook: string
  behance: string
  twitter: string
  cv: string
}

export interface IPerson {
  _id: string
  name: string
  lastname: string
  email: string
  avatar: string
  position: string
  area: string
  rol: number
  responsibilities: string
  roles: PersonRoles[]
  enabled: boolean
  identityDocument: IIdentityDocument
  socialNetworks: ISocialNetworks
  country: string
  goal: string
}
export class IdentityDocument {
  identity: string
  identityType: IdentityTypes
  constructor (from: IIdentityDocument) {
    this.identity = from.identity
    this.identityType = from.identityType
  }
}

export class SocialNetworks {
  instagram: string
  github: string
  linkedin: string
  facebook: string
  behance: string
  twitter: string
  cv: string
  constructor (from: ISocialNetworks) {
    this.instagram = from.instagram
    this.github = from.github
    this.linkedin = from.linkedin
    this.facebook = from.facebook
    this.behance = from.behance
    this.twitter = from.twitter
    this.cv = from.cv
  }
}

export class Person {
  id: string
  name: string
  lastname: string
  email: string
  avatar: string
  position: string
  area: string
  rol: number
  responsibilities: string
  roles: PersonRoles[]
  enabled: boolean
  identityDocument: IIdentityDocument
  socialNetworks: ISocialNetworks
  country: string
  goal: string
  constructor (from: IPerson) {
    this.id = from._id
    this.name = from.name
    this.lastname = from.lastname
    this.email = from.email
    this.avatar = from.avatar
    this.position = from.position
    this.area = from.area
    this.rol = from.rol
    this.responsibilities = from.responsibilities
    this.roles = from.roles
    this.enabled = from.enabled
    this.identityDocument = new IdentityDocument(from.identityDocument)
    this.socialNetworks = new SocialNetworks(from.socialNetworks)
    this.country = from.country
    this.goal = from.goal
  }
}
