
import { defineComponent, ref, computed, watch, PropType, onMounted } from 'vue'
import { DateTime } from 'luxon'

// Components
import CommunityTag from '@/components/aero/dataDisplay/CommunityTag.vue'
import ListCardComision from '@/components/aero/surfaces/ListCardComision.vue'
import PlaceholderState from '@/components/aero/surfaces/challenges/PlaceholderState.vue'

// PrimeVue Components
import Menu from 'primevue/menu'

// Models
import { IncomingClass } from '@/models/newPlatform/products/productCourse'

// Copies
import { studentChangeCourse as studentChangeCourseCopies } from '@/locales/profile/es.json'

export default defineComponent({
  components: { CommunityTag, ListCardComision, PlaceholderState, Menu },
  emits: ['onSelectComission'],
  props: {
    coursesData: { type: Array as PropType<IncomingClass[]>, default: () => [] },
    leadTeacherId: { type: String, required: true }
  },
  setup(props, { emit }) {
    const filterByPeriod = ref<IncomingClass[]>([])
    // Dropdown Menu
    const menu = ref()
    const toggle = (event: Event) => menu.value.toggle(event)

    // Tags filter
    type tagValues = 'weekday' | 'saturdays' | 'sameTeacher'
    const tagFilter = ref<tagValues[]>([])
    const tagsFromCopies = studentChangeCourseCopies.filter.tag
    const tags = Object.entries(tagsFromCopies).map(([key, { label, val }]) => ({ key, val, label }))

    const handleTagActive = (tagLabel: tagValues) => {
      if (tagFilter.value.includes(tagLabel)) {
        tagFilter.value = tagFilter.value.filter((t) => t !== tagLabel)
      } else {
        tagFilter.value.push(tagLabel)
      }
    }

    // Button filter
    type filterByMonth = 'currmonth' | 'nextmonth' | 'next6months'
    const btnFilter = ref<filterByMonth | null>(null)
    const udpateFilter = (filter: filterByMonth) => (btnFilter.value = filter)

    const filterOptions = [
      {
        label: studentChangeCourseCopies.filter.menu.menu1,
        value: 'currmonth',
        command: () => udpateFilter('currmonth')
      },
      {
        label: studentChangeCourseCopies.filter.menu.menu2,
        value: 'nextmonth',
        command: () => udpateFilter('nextmonth')
      },
      {
        label: studentChangeCourseCopies.filter.menu.menu3,
        value: 'next6months',
        command: () => udpateFilter('next6months')
      }
    ]

    const getSameMonth = (items: IncomingClass[]) => {
      const sameMonth: IncomingClass[] = []
      const dt = DateTime.now()

      items.forEach((item) => {
        const startDate = DateTime.fromMillis(+item.startDate)
        if (dt.hasSame(startDate, 'month')) {
          sameMonth.push(item)
        }
      })
      return sameMonth
    }

    const getNextMonth = (items: IncomingClass[]) => {
      const nextMonth: IncomingClass[] = []
      const dt = DateTime.now()
      const month = dt.plus({ month: 1 })

      items.forEach((item) => {
        const startDate = DateTime.fromMillis(+item.startDate)
        if (month.hasSame(startDate, 'month')) {
          nextMonth.push(item)
        }
      })
      return nextMonth
    }

    const getNex6tMonth = (items: IncomingClass[]) => {
      const nextMonth: IncomingClass[] = []
      const dt = DateTime.now()
      const month = dt.plus({ month: 6 })

      items.forEach((item) => {
        const startDate = DateTime.fromMillis(+item.startDate)
        if (month.hasSame(startDate, 'month')) {
          nextMonth.push(item)
        }
      })
      return nextMonth
    }

    const filterByMonths = () => {
      switch (btnFilter.value) {
        case 'currmonth':
          filterByPeriod.value = getSameMonth(props.coursesData)
          return
        case 'nextmonth':
          filterByPeriod.value = getNextMonth(props.coursesData)
          return
        case 'next6months':
          filterByPeriod.value = getNex6tMonth(props.coursesData)
          return
        default:
          filterByPeriod.value = props.coursesData
      }
    }

    watch(props.coursesData, () => {
      btnFilter.value = 'currmonth'
    })

    watch(btnFilter, filterByMonths)

    const getFilteredProducts = computed(() => {
      const weekday = tagFilter.value.includes('weekday')
      const saturday = tagFilter.value.includes('saturdays')
      const sameTeacher = tagFilter.value.includes('sameTeacher')

      return filterByPeriod.value.filter((incClass) => {
        if (weekday && !saturday && incClass.numDays.includes(6)) return false
        if (saturday && !weekday && !incClass.numDays.includes(6)) return false

        if (sameTeacher && incClass.leadTeacherId !== props.leadTeacherId) return false
        return true
      })
    })

    const handleChooseCourse = (value: string) => {
      emit('onSelectComission', value)
    }

    onMounted(() => {
      // default filter
      btnFilter.value = 'currmonth'
    })

    return {
      menu,
      toggle,
      tags,
      getFilteredProducts,
      filterOptions,
      btnFilter,
      handleTagActive,
      handleChooseCourse,
      studentChangeCourseCopies
    }
  }
})
