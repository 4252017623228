
// Vendor
import { defineComponent, onMounted, PropType, ref, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'

// Models
import { ClassUser } from '@/models/classes/class'

// Hooks
import useBeginsIn from '@/hooks/classes/useBeginsIn'
import { useChallengeBtn } from '@/hooks/classes/useChallengesBtn'

// Components
import Menu from 'primevue/menu'

// Icons
import { Dots, ListBullets, Star, VideoIcon, Clock, Trending } from '@/components/aero/icon/templates'
import ButtonIcon from '@/components/aero/buttons/ButtonIcon.vue'
import Icon from '../../icon/Icon.vue'

// Services
import { getFeedbackLink } from '@/services/classes.services'

// Utils
import { ClassesEvent } from '@/amplitude/constants'
import { trackEvent } from '@/amplitude/actions'

// Store
import { useUserSessionStore } from '@/store/modules/user-session.store'
import { useCourseStore } from '@/store/modules/course.store'

export default defineComponent({
  components: {
    Star,
    Dots,
    Menu,
    ButtonIcon,
    VideoIcon,
    ListBullets,
    Trending,
    Icon,
    Clock
  },
  props: {
    data: { type: Object as PropType<ClassUser>, required: true },
    nextClass: { type: Object as PropType<ClassUser> },
    optionsListClasses: { type: Object, required: true },
    toolTips: { type: Object, required: true },
    role: { type: Number, required: true },
    createAfterClass: { type: Function, required: false, default: () => null },
    endDate: { type: Date, required: true },
    becaSuspended: { type: Boolean, required: true },
    useCoderChallenge: { type: Boolean, required: true },
    handleEnableRate: { type: Function, required: true }
  },
  setup(props, { emit }) {
    const router = useRouter()
    const route = useRoute()

    const { userId } = useUserSessionStore()

    const course = useCourseStore()

    const toggle = (event: Event) => {
      event.preventDefault()
      menu.value.toggle(event)
    }
    const { beginsIn } = useBeginsIn(props.data.schedule.day)
    const menu = ref()
    const items = ref<Array<{ label: string; url?: string; target?: '_blank' | '_self'; command?: () => void }>>([])
    const showCountdown = ref<boolean>(false)
    const showZoomBtn = ref<boolean>(false)
    const forceEnabledRateBtn = ref(false)
    const showRateBtn = ref<boolean>(
      !!(
        props.data.schedule.day < Date.now() &&
        props.data?.feedback?.isFeedbackEnable &&
        !props.data?.feedback?.isFeedbackCompleted &&
        props.data?.links?.feedbackLink
      )
    )

    const showRatesBtn = !!(props.role !== 2 &&
    !props.data.schedule.isAdditional &&
    props.data.schedule.day < Date.now() &&
    props.nextClass
      ? props.nextClass?.schedule?.day > Date.now()
      : false)
    const challengeURL = (challengeId: string, homeworkPlanId: string) =>props.useCoderChallenge?`/reviews/${course.camadaNro}/${challengeId}/${homeworkPlanId}`:`/challenges/challenge/${challengeId}`
    const challenges = props.data?.modules?.project?.module || []
    const { ZOOM_COUNTDOWN_TIME, ZOOM_ENABLED_TIME } = process.env

    /** Force enable rate button when ff (force feedback), stu (student ID)
     *  adn  ts (timpestamp) are received by query params
     */
    const handleForceEnableDeliver = () => {
      const { ff, stu, ts } = route.query

      const onTime = ts && +ts > Date.now()

      if (ff === props.data._id && stu === userId && onTime) {
        forceEnabledRateBtn.value = true
      }
    }

    onMounted(() => {
      handleForceEnableDeliver()
      // Kebab Menu
      if (props.role !== 2) {
        if (!props.data.schedule.isAdditional && props.data.schedule?.day < Date.now()) {
          // Book afterclass
          items.value.push({
            label: props.optionsListClasses.bookAfterClass,
            command: () => {
              props.createAfterClass()
            }
          })
        }

        // update afterclass
        if (props.data.schedule.isAdditional) {
          items.value.push({
            label: props.optionsListClasses.editAfterclass,
            command: () => {
              emit('editAfterclass', props.data.schedule.stage, props.data._id)
            }
          })
        }

        // delete afterclass
        if (props.data.schedule.isAdditional) {
          items.value.push({
            label: props.optionsListClasses.deleteAfterclass,
            command: () => {
              emit('deleteAfterclass', props.data.schedule.stage, props.data._id)
            }
          })
        }

        // Go to view rates
        items.value.push({
          label: props.optionsListClasses.rates,
          command: () => {
            router.push(`/ratings/${props.data.schedule.stage}`)

            handleTrackEvent(ClassesEvent.VIEW_CLASS_RATES, 'class card kebab')
          }
        })
      }

      // Recording video
      if (props.data?.videoLink?.recordingVideoLink) {
        items.value.push({
          label: props.optionsListClasses.recordingVideo,
          url: props.data.videoLink.recordingVideoLink,
          target: '_blank',
          command: () => handleTrackEvent(ClassesEvent.VIEW_RECORDING, 'class card kebab')
        })
      }

      // Challenges
      if (props.data?.modules?.project.hasDelivers || props.data?.modules?.project.isFinalDeliver) {
        const challengesParsed = useChallengeBtn('long', challenges)
        challengesParsed.forEach((challenge) => {
          items.value.push({
            label: challenge.label,
            command: () => {
              router.push(challengeURL(challenge.id,challenge.stage))
              handleTrackEvent(ClassesEvent.GO_TO_MODULES, 'class card kebab')
            }
          })
        })
      }

      // Go to rate class
      if (showRateBtn.value) {
        items.value.push({
          label: props.optionsListClasses.rate,
          url: props.data?.links?.feedbackLink,
          target: '_blank',
          command: () => handleTrackEvent(ClassesEvent.RATE_CLASS, 'class card kebab')
        })
      }

      if (!showRateBtn.value && forceEnabledRateBtn.value && route.params.camadaId) {
        items.value.push({
          label: props.optionsListClasses.rate,
          command: async () => {
            const link = await getFeedbackLink(route.params.camadaId.toString(), props.data.schedule._id, userId)
            window.open(link, '_blank')?.focus()

            handleTrackEvent(ClassesEvent.RATE_CLASS, 'class card kebab')
          }
        })
      }

      // Presentation link
      if ((props.data?.links?.presentationLink || props.data?.links?.sharedFolderLink) && props.role === 2) {
        items.value.push({
          label: props.optionsListClasses.goToMaterial,
          url: props.data?.links?.presentationLink || props.data?.links?.sharedFolderLink,
          target: '_blank',
          command: () => handleTrackEvent(ClassesEvent.VIEW_CLASS_ASSETS, 'class card kebab')
        })
      }

      // Enable valoration for students
      // if(props.role === 2 &&
      // props.data.feedback?.isFeedbackExpired &&
      // !props.data.feedback?.isFeedbackCompleted &&
      // !props.data.feedback?.isFeedbackEnable
      // ){
      //   items.value.push({
      //     label: 'Habilitar valoración',
      //     command: ()=>props?.handleEnableRate(props.data)
      //   })
      // }
    })

    watch(beginsIn, () => {
      if (
        beginsIn.value <= (Number(ZOOM_ENABLED_TIME) || 30) &&
        props.data.schedule.from - (Number(ZOOM_ENABLED_TIME) || 30) * 60 * 1000 < Date.now() &&
        props.data.schedule.to > Date.now() &&
        props.data.videoLink.liveVideoLink
      ) {
        showZoomBtn.value = true
      } else {
        showZoomBtn.value = false
      }

      if (beginsIn.value > 0 && beginsIn.value <= (Number(ZOOM_COUNTDOWN_TIME) || 60)) {
        showCountdown.value = true
      } else {
        showCountdown.value = false
      }
    })

    // Amplitude track event
    const handleTrackEvent = (name: string, origin: string) => {
      trackEvent(name, { origin })
    }

    return {
      menu,
      items,
      toggle,
      beginsIn,
      showZoomBtn,
      showCountdown,
      showRateBtn,
      showRatesBtn,
      challenges,
      challengeURL,
      handleTrackEvent,
      forceEnabledRateBtn,
      ClassesEvent,
      useChallengeBtn
    }
  }
})
