import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a01df826"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-d-flex p-flex-row comment-wrapper" }
const _hoisted_2 = { class: "p-jc-between p-mx-3 text-white" }
const _hoisted_3 = { class: "bold p1" }
const _hoisted_4 = { class: "p-ai-center" }
const _hoisted_5 = { class: "p3 text-light-gray p-mt-1 p-mb-2" }
const _hoisted_6 = { class: "p-d-flex p-flex-row p-ml-auto p-ai-center interactions__container" }
const _hoisted_7 = { class: "p-mr-2 p3" }
const _hoisted_8 = { class: "p-my-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_avatar = _resolveComponent("avatar")!
  const _component_thumb_up = _resolveComponent("thumb-up")!
  const _component_icon = _resolveComponent("icon")!
  const _component_thumb_up_fill = _resolveComponent("thumb-up-fill")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_avatar, {
      name: _ctx.comment.platformUser.fullName,
      imageLink: _ctx.comment.platformUser.avatar
    }, null, 8, ["name", "imageLink"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.comment.platformUser.fullName), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.commentDate), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.comment.likeCount), 1),
        _createElementVNode("div", {
          class: "interactions__button",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isLiked ? _ctx.dislikeComment() : _ctx.likeComment()))
        }, [
          (!_ctx.isLiked)
            ? (_openBlock(), _createBlock(_component_icon, {
                key: 0,
                size: 18,
                fillColor: 'var(--dark-gray)',
                strokeColor: 'var(--light-gray)'
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_thumb_up)
                ]),
                _: 1
              }, 8, ["fillColor", "strokeColor"]))
            : (_openBlock(), _createBlock(_component_icon, {
                key: 1,
                size: 19,
                strokeColor: 'var(--light-gray)'
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_thumb_up_fill)
                ]),
                _: 1
              }, 8, ["strokeColor"]))
        ])
      ]),
      _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.comment.commentContent), 1)
    ])
  ]))
}