import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55809985"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "image-card" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_link_icon = _resolveComponent("link-icon")!
  const _component_icon = _resolveComponent("icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _ctx.filePreview,
      alt: _ctx.link.title,
      class: _normalizeClass(_ctx.cardType)
    }, null, 10, _hoisted_2),
    _createElementVNode("p", {
      class: _normalizeClass(["image-card__link p3 bg-gray p-px-3 text-white p-d-flex p-ai-center", _ctx.cardType === 'large' ? 'p-py-3' : 'p-py-2'])
    }, [
      _createVNode(_component_icon, {
        size: 16,
        class: "p-mr-1"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_link_icon)
        ]),
        _: 1
      }),
      (_ctx.link.type === 'internal')
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: _ctx.link.url,
            class: _normalizeClass(_ctx.cardType)
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.link.title), 1)
            ]),
            _: 1
          }, 8, ["to", "class"]))
        : (_openBlock(), _createElementBlock("a", {
            key: 1,
            href: _ctx.link.url,
            class: _normalizeClass(_ctx.cardType),
            target: "_blank"
          }, _toDisplayString(_ctx.link.title), 11, _hoisted_3))
    ], 2)
  ]))
}