
import { defineComponent, ref } from 'vue'

export default defineComponent({
  props: {
    header: {
      type: String,
      default: ''
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const collapsed = ref<boolean>(!props.open)
    const toggle = () => {
      collapsed.value = !collapsed.value
    }
    return { toggle, collapsed }
  }
})
