
import { defineComponent, PropType, ref, reactive, computed } from 'vue'

// Vuelidate
import { required, helpers } from '@vuelidate/validators'

// Components
import Form from '@/components/aero/forms/Form.vue'
import Checkbox from 'primevue/checkbox'

// Services
import { setEducationInformation } from '@/services/newPlatform/profile.services'

// Models
import { IExperience } from '@/models/newPlatform/profile'

// Copies
import _copies from '@/locales/profile/es.json'
import { useI18n } from 'vue-i18n'

// Amplitude
import { trackEvent } from '@/amplitude/actions'
import { ProfileEvents } from '@/amplitude/constants'

export default defineComponent({
  components: { Form, Checkbox },
  props: {
    userId: { type: String, required: true },
    defaultValues: { type: Object as PropType<{ [x: string]: Date | string }>, required: true }
  },
  emits: ['onSubmit', 'onCancel'],
  setup(props, { emit }) {
    const { formStudies: copies } = _copies
    const { t } = useI18n()

    const isLoading = ref<boolean>(false)

    const currentlyStudying = ref(props.defaultValues.currently)
    const values = reactive({
      title: '',
      institute: '',
      startDate: '',
      endDate: ''
    })

    const questions = [
      {
        name: 'title',
        label: t('profile.formStudies.title.label'),
        placeholder: t('profile.formStudies.title.placeholder'),
        type: 'text',
        defaultValue: props.defaultValues.title,
        styles: { all: 'margin: 0 !important' }
      },
      {
        name: 'institute',
        label: t('profile.formStudies.institute.label'),
        placeholder: t('profile.formStudies.institute.placeholder'),
        type: 'text',
        defaultValue: props.defaultValues.subtitle,
        styles: { all: 'margin: 0 !important' }
      },
      {
        name: 'startDate',
        label: t('profile.formStudies.startDate.label'),
        type: 'date',
        max: new Date(),
        defaultValue: props.defaultValues.startDate ? new Date(props.defaultValues.startDate) : '',
        styles: { all: 'margin: 0 !important' }
      },
      {
        name: 'endDate',
        label: t('profile.formStudies.endDate.label'),
        type: 'date',
        max: new Date(),
        defaultValue: props.defaultValues.endDate ? new Date(props.defaultValues.endDate) : '',
        styles: { all: 'margin: 0 !important' }
      }
    ]

    // Check if checkbox 'currently studying' is checked or final date has been selected
    const finalDate = (value: string) => !!value || !!currentlyStudying.value
    const endDateGreaterThanStart = () => {
      if (!values.startDate || !values.endDate || currentlyStudying.value) return true
      return new Date(values.startDate) < new Date(values.endDate)
    }

    const rules = {
      title: { required: helpers.withMessage(t('profile.formStudies.formRules.required'), required) },
      institute: { required: helpers.withMessage(t('profile.formStudies.formRules.required'), required) },
      startDate: { required: helpers.withMessage(t('profile.formStudies.formRules.required'), required) },
      endDate: {
        finalDate: helpers.withMessage(t('profile.formStudies.formRules.endDateorCheckbox'), finalDate),
        endDateGreaterThanStart: helpers.withMessage(t('profile.formStudies.formRules.endDateGreaterThanStart'), endDateGreaterThanStart)
      }
    }

    const handleChange = (formValues: { [name: string]: string }) => {
      values.title = formValues.title
      values.institute = formValues.institute
      values.startDate = formValues.startDate
      values.endDate = formValues.endDate
    }

    const handleSubmit = async (values: IExperience) => {
      try {
        isLoading.value = true
        // modify property names from values to fit the object expected by ItemInformationLarge component
        let str = JSON.stringify(values)
        str = str.replace('institute', 'subtitle')
        const formatedValues = JSON.parse(str)

        // added the checkbox value
        formatedValues.currently = currentlyStudying.value

        // check if is editting
        if (props.defaultValues._id) {
          formatedValues._id = props.defaultValues._id
        }

        const response = await setEducationInformation(props.userId, formatedValues)

        trackEvent(ProfileEvents.ADDED_ACADEMIC_EXPERIENCE)

        emit('onSubmit', { ...formatedValues, _id: response._id })
      } catch (e) {
        throw new Error('Error trying to submit profile information')
      } finally {
        isLoading.value = false
      }
    }

    const disableSubmit = computed(() => {
      return !values.title || !values.institute || !values.startDate || (!values.endDate && !currentlyStudying.value)
    })

    const handleCancel = () => {
      // true for isEducation
      emit('onCancel', true)
    }

    return {
      questions,
      rules,
      isLoading,
      currentlyStudying,
      handleSubmit,
      handleCancel,
      copies: _copies.formStudies,
      disableSubmit,
      handleChange,
      t
    }
  }
})
