import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2fd20b57"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-mt-4 main" }
const _hoisted_2 = { class: "goals-container" }
const _hoisted_3 = { class: "p-mb-2 title-skills" }
const _hoisted_4 = { class: "subtitle" }
const _hoisted_5 = { class: "filtered-container p-d-flex p-jc-between" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "modalities-container" }
const _hoisted_8 = { class: "category-title" }
const _hoisted_9 = { class: "filteredValues" }
const _hoisted_10 = { class: "p-text-center" }
const _hoisted_11 = { class: "p-mr-3" }
const _hoisted_12 = ["src", "onClick"]
const _hoisted_13 = { class: "p-text-center" }
const _hoisted_14 = { class: "p-mr-3" }
const _hoisted_15 = ["src", "onClick"]
const _hoisted_16 = ["src"]
const _hoisted_17 = {
  key: 0,
  class: "interests-container"
}
const _hoisted_18 = { class: "category-title" }
const _hoisted_19 = { class: "filteredValues" }
const _hoisted_20 = { class: "p-text-center" }
const _hoisted_21 = { class: "p-mr-3" }
const _hoisted_22 = ["src", "onClick"]
const _hoisted_23 = { class: "p-text-center" }
const _hoisted_24 = { class: "p-mr-3" }
const _hoisted_25 = ["src", "onClick"]
const _hoisted_26 = ["src"]
const _hoisted_27 = { class: "add" }
const _hoisted_28 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_goals_modal = _resolveComponent("goals-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('editProfile.professionalInfo.goals.title')), 1),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('editProfile.professionalInfo.goals.description')), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_ctx.userModalities.length || _ctx.userIndustries.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("h3", _hoisted_8, _toDisplayString(_ctx.$t('editProfile.professionalInfo.goals.modality')), 1),
                  _createElementVNode("div", _hoisted_9, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userModalities.slice(0, 3), (modality) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "pills",
                        key: modality.id
                      }, [
                        _createElementVNode("p", _hoisted_10, _toDisplayString(modality.name), 1),
                        _createElementVNode("div", _hoisted_11, [
                          _createElementVNode("img", {
                            src: require('@/assets/icons/delete.svg'),
                            alt: "remove",
                            onClick: ($event: any) => (_ctx.deleteModality(modality.id))
                          }, null, 8, _hoisted_12)
                        ])
                      ]))
                    }), 128)),
                    (_ctx.showAllModalities)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.userModalities.slice(3, _ctx.userModalities.lenght), (modality) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: "pills",
                            key: modality.id
                          }, [
                            _createElementVNode("p", _hoisted_13, _toDisplayString(modality.name), 1),
                            _createElementVNode("div", _hoisted_14, [
                              _createElementVNode("img", {
                                src: require('@/assets/icons/delete.svg'),
                                alt: "remove",
                                onClick: ($event: any) => (_ctx.deleteModality(modality.id))
                              }, null, 8, _hoisted_15)
                            ])
                          ]))
                        }), 128))
                      : _createCommentVNode("", true),
                    (_ctx.userModalities && _ctx.userModalities.length > 3)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          class: "arrow-container",
                          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleAllModalities && _ctx.toggleAllModalities(...args)))
                        }, [
                          _createElementVNode("img", {
                            class: _normalizeClass({rotate: _ctx.showAllModalities}),
                            src: require('@/assets/icons/arrow_down.svg'),
                            alt: "arrow"
                          }, null, 10, _hoisted_16)
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                (_ctx.user?.country !== 'br')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                      _createElementVNode("h3", _hoisted_18, _toDisplayString(_ctx.$t('editProfile.professionalInfo.goals.interest')), 1),
                      _createElementVNode("div", _hoisted_19, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userIndustries.slice(0, 3), (interest) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: "pills",
                            key: interest.id
                          }, [
                            _createElementVNode("p", _hoisted_20, _toDisplayString(interest.name), 1),
                            _createElementVNode("div", _hoisted_21, [
                              _createElementVNode("img", {
                                src: require('@/assets/icons/delete.svg'),
                                alt: "remove",
                                onClick: ($event: any) => (_ctx.deleteIndustry(interest.id))
                              }, null, 8, _hoisted_22)
                            ])
                          ]))
                        }), 128)),
                        (_ctx.showAllInterests)
                          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.userIndustries.slice(3, _ctx.userIndustries.length), (interest) => {
                              return (_openBlock(), _createElementBlock("div", {
                                class: "pills",
                                key: interest.id
                              }, [
                                _createElementVNode("p", _hoisted_23, _toDisplayString(interest.name), 1),
                                _createElementVNode("div", _hoisted_24, [
                                  _createElementVNode("img", {
                                    src: require('@/assets/icons/delete.svg'),
                                    alt: "remove",
                                    onClick: ($event: any) => (_ctx.deleteIndustry(interest.id))
                                  }, null, 8, _hoisted_25)
                                ])
                              ]))
                            }), 128))
                          : _createCommentVNode("", true),
                        (_ctx.userIndustries && _ctx.userIndustries.length>4)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 1,
                              class: "arrow-container",
                              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleAllInterests && _ctx.toggleAllInterests(...args)))
                            }, [
                              _createElementVNode("img", {
                                class: _normalizeClass({rotate: _ctx.showAllSoft}),
                                src: require('@/assets/icons/arrow_down.svg'),
                                alt: "arrow"
                              }, null, 10, _hoisted_26)
                            ]))
                          : _createCommentVNode("", true)
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_27, [
        _createElementVNode("img", {
          class: "pointer",
          src: require('@/assets/icons/add.svg'),
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.openModal && _ctx.openModal(...args)))
        }, null, 8, _hoisted_28)
      ])
    ]),
    _createVNode(_component_goals_modal, {
      displayModal: _ctx.displayModal,
      onCloseModal: _ctx.closeModal,
      modalities: _ctx.modalities,
      userModalities: _ctx.userModalities,
      userIndustries: _ctx.userIndustries
    }, null, 8, ["displayModal", "onCloseModal", "modalities", "userModalities", "userIndustries"])
  ], 64))
}