
import { defineComponent, PropType, ref, reactive, watch, onMounted, onBeforeUnmount } from 'vue'
import { DateTime } from 'luxon'
import InputWrapper from '../inputs/InputWrapper.vue'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import SelectInput from '../inputs/SelectInput.vue'
import InputTime from '../inputs/InputTime.vue'
import Calendar from 'primevue/calendar'

import { InputSelect as InputSelectType } from '@/models/commons/InputSelect'
import { ClassUser } from '@/models/classes/class'
import { IAfterClass, IZoomLink, IAFSchedule } from '@/models/classes/afterclass'

// Services
import { createZoomLink, createAfterClass, updateAfterclass } from '@/api/classes.api'

// Store
import { useUserSessionStore } from '@/store/modules/user-session.store'

// copies
import copies from '@/locales/classes/es.json'

// Amplitude
import { ClassesEvent } from '@/amplitude/constants'
import { trackEvent } from '@/amplitude/actions'

export default defineComponent({
  components: {
    InputWrapper,
    InputText,
    Button,
    SelectInput,
    InputTime,
    Calendar
  },
  props: {
    classesList: {
      type: Array as PropType<ClassUser[]>,
      default: () => [],
      required: true
    },
    suggestedClass: { type: String, default: '', required: true },
    complementaryId: { type: String, default: '' },
    handleCloseForm: {
      type: Function,
      required: false,
      default: () => null
    },
    courseId: { type: String, default: '', required: false },
    endDate: { type: Date, required: true }
  },
  emits: ['onSubmitEnded', 'displayError'],
  setup(props, { emit }) {
    const classId = ref<string>('')
    const classDate = ref<Date>(new Date())
    const classTitle = ref('')
    const classTitleError = ref<boolean>(false)
    const enabledOptions = ref<InputSelectType[]>([])
    const disabledDates = ref<Date[]>([])
    const time = reactive({
      start: '',
      end: '',
      errorStart: false,
      errorEnd: false
    })

    // TODO: Check if there's a nicer way to do this :)
    window.addEventListener('keydown', (ev: KeyboardEvent) => {
      if (ev.key === 'Escape') {
        props.handleCloseForm()
      }
    })

    const isComplete = ref<boolean>(false)
    const isLoading = ref<boolean>(false)

    const minDate = ref<Date>(new Date())
    const maxDate = ref<Date>(new Date(props.endDate))

    const { userId } = useUserSessionStore()

    const closeCalendar = () => {
      const selector = document.querySelector('.calendarAfterclassSelector') as HTMLElement | null
      const input = document.querySelector('.calendarAfterclassInput input') as HTMLElement | null
      if (selector) selector.style.display = 'none'
      if (input) input.blur()
    }
    onMounted(() => {
      classId.value = props.suggestedClass
      setAvailableOptions()
      setAvailableDates()

      document.addEventListener('scroll', closeCalendar)
    })

    onBeforeUnmount(() => {
      document.removeEventListener('scroll', closeCalendar)
    })

    const formatClassesOptions = () => {
      const formattedList = props.classesList.map((c) => {
        return { label: c.name, value: c.schedule.stage, date: c.schedule.day }
      })

      return formattedList
    }

    const setAvailableOptions = () => {
      const formattedList = formatClassesOptions()
      const newList = formattedList.filter((c) => {
        if (c.date && c.date <= Date.now()) {
          return c
        }
        return false
      })

      enabledOptions.value = newList
    }

    const setAvailableDates = () => {
      const list = props.classesList.map((c) => {
        const date = new Date(c.schedule.day)
        return date
      })
      disabledDates.value = list
    }

    watch(classTitle, () => {
      if (classTitle.value.trim().length === 0) {
        classTitleError.value = true
      } else {
        classTitleError.value = false
      }
    })

    watch(time, () => {
      if (time.start && !validMinutes(15, time.start)) {
        time.errorStart = true
      } else {
        time.errorStart = false
      }
    })

    watch(time, () => {
      if (time.end && !validMinutes(15, time.end)) {
        time.errorEnd = true
      } else {
        time.errorEnd = false
      }
    })

    watch([classId, classDate, classTitle, time], () => {
      if (validateEmptyInputs()) {
        if (
          validateTime(time.start) &&
          validateTime(time.end) &&
          checkTimeValid(time.start, time.end) &&
          validCalendarDate(classDate.value)
        ) {
          isComplete.value = true
        } else {
          isComplete.value = false
        }
      } else {
        isComplete.value = false
      }
    })

    /**
     * Checks whether the calendar date is valid
     */
    const validCalendarDate: (date: Date) => boolean = (date) => {
      try {
        return typeof date.getTime() === 'number'
      } catch (error) {
        return false
      }
    }

    const handleSelectedClass = (value: string) => {
      classId.value = value
    }

    const handleSelectedStartTime = (value: string) => {
      time.start = value
    }
    const handleSelectedEndTime = (value: string) => {
      time.end = value
    }

    const formatValues = (day: Date, startTime: string, endTime: string) => {
      const dateF = DateTime.fromJSDate(day).toMillis()
      const startTimeF = DateTime.fromISO(startTime).toISO()
      const endTimeF = DateTime.fromISO(endTime).toISO()
      return { dateF, startTimeF, endTimeF }
    }

    const handleSubmit = async () => {
      try {
        isLoading.value = true

        const { dateF, startTimeF, endTimeF } = formatValues(classDate.value, time.start, time.end)
        const schedule: IAFSchedule = {
          stage: classId.value,
          day: dateF,
          from: startTimeF,
          to: endTimeF
        }
        const meetingData: IZoomLink = {
          courseId: props.courseId,
          schedule
        }
        const liveVideoLink = await createZoomLink(props.courseId, meetingData)

        if (liveVideoLink) {
          const classPayload: IAfterClass = {
            stage: classId.value,
            day: dateF,
            from: startTimeF,
            to: endTimeF,
            liveVideoLink: liveVideoLink.link,
            title: classTitle.value,
            teachers: [userId]
          }

          if (props.complementaryId) {
            classPayload._id = props.complementaryId
            const afterClass = await updateAfterclass(props.courseId, props.complementaryId, classPayload)
            emit('onSubmitEnded', afterClass, true)
          } else {
            const afterClass = await createAfterClass(props.courseId, classPayload)
            emit('onSubmitEnded', afterClass)
          }
        }

        // Amplitude track event
        trackEvent(ClassesEvent.CREATE_AFTERCLASS)
      } catch (error) {
        emit('displayError')
      } finally {
        isLoading.value = false
        props.handleCloseForm()
      }
    }

    // time helpers
    const validMinutes = (num: number, time: string) => {
      const minutes = Number.parseInt(time.slice(-2))
      return minutes % num === 0
    }

    const validateTime = (timeC: string) => {
      if (!time.errorStart && !time.errorEnd) {
        const hours = Number.parseInt(timeC.slice(0, 2))
        return hours >= 8 && hours <= 23
      } else {
        return false
      }
    }

    const validateEmptyInputs = () => {
      return classId.value && classDate.value && classTitle.value && time.start && time.end
    }

    // Returns true if end time is greater than start time
    const checkTimeValid = (startTime: string, endTime: string) => {
      const start = DateTime.fromISO(startTime).toMillis()
      const end = DateTime.fromISO(endTime).toMillis()

      return end - start > 0
    }

    return {
      handleSelectedClass,
      handleSelectedStartTime,
      handleSelectedEndTime,
      handleSubmit,
      classDate,
      disabledDates,
      minDate,
      maxDate,
      classTitle,
      classTitleError,
      time,
      enabledOptions,
      isComplete,
      isLoading,
      copies
    }
  }
})
