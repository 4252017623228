
// Vendor
import { defineComponent, onMounted, onUnmounted, ref } from 'vue'
import { useRoute } from 'vue-router'

// Stores
import { themeStore } from '@/store/modules/themes.store'
import { useUserSessionStore } from '@/store/modules/user-session.store'
import { useUserStore } from '@/store/modules/user.store'
import { useCourseStore } from '@/store/modules/course.store'

// Models
import { PostDataChatIframe } from '@/models/chat/chat'

export default defineComponent({
  setup() {
    const theme = themeStore()
    theme.changeDefault('new-theme')

    const { userId } = useUserSessionStore()
    const { avatar, fullName, getStreamToken: sessionToken } = useUserStore()
    const { camadaNro } = useCourseStore()

    const chatIframe = ref<HTMLIFrameElement>()

    const route = useRoute()
    const { chatId } = route.params

    const CHAT_URL = process.env.CHAT_URL as string
    const STREAM_CHANNEL_TYPE = 'messaging'

    const sendDataByPostMessageToReactApp = (data: PostDataChatIframe) => {
      let tries = 0
      const sendPostMessage = () => {
        if (chatIframe.value && chatIframe.value.contentWindow && tries < 5) {
          chatIframe.value.contentWindow.postMessage(data, '*')
        }
      }
      sendPostMessage()

      const interval = setInterval(() => {
        sendPostMessage()
        tries++
        if (tries > 4) clearInterval(interval)
      }, 1000)

      const clearIntervalPostMessage = (e: MessageEvent) => {
        if (e.data.received === true) clearInterval(interval)
      }

      window.addEventListener('message', clearIntervalPostMessage)

      onUnmounted(() => {
        window.removeEventListener('message', clearIntervalPostMessage)
      })
    }

    onMounted(() => {
      const data: PostDataChatIframe = {
        userData: {
          id: userId,
          name: fullName,
          image: avatar,
          token: sessionToken
        },
        camadaId: camadaNro,
        personalFilters: {
          type: STREAM_CHANNEL_TYPE,
          isPersonal: true,
          members: {
            $in: [userId]
          }
        },
        defaultFilters: {
          type: STREAM_CHANNEL_TYPE,
          $or: [
            {
              name: {
                $autocomplete: `#${camadaNro}-`
              }
            },
            {
              isSupport: true
            }
          ],
          members: {
            $in: [userId]
          }
        },
        sort: {
          last_message_at: -1
        }
      }

      sendDataByPostMessageToReactApp(data)
    })

    const toChatEventHandler = () => {
      if (!chatId) return
      // Info includes id from curr user and user that you want to write to
      const toChatObject = {
        idTransmitter: { _id: userId },
        idReceiver: { _id: chatId.toString() }
      }

      if (chatIframe.value && chatIframe.value.contentWindow) {
        chatIframe.value.contentWindow.postMessage(
          {
            type: 'to-chat-event',
            payload: { toChatObject }
          },
          '*'
        )
      }
    }

    const listenChatIframe = (e: MessageEvent) => {
      const isJSON = (str: string) => typeof str === 'string' && str.includes('{') && str.includes('}') && JSON.parse(str)

      const origin = e.origin
      const data = (e.data && isJSON(e.data)) || e.data
      // Is it valid origin?
      if (origin || origin.includes('local') || origin.includes('herokuapp') || origin.includes('coderhouse')) {
        // Send user data to chatIframe
        if (data?.type === 'get-user') {
          try {
            const darkMode = localStorage.getItem('darkModeIsEnabled')
            if (chatIframe.value && chatIframe.value.contentWindow) {
              chatIframe.value.contentWindow.postMessage(
                {
                  type: 'send-user',
                  // TODO VALIDATE USERSESSION TOKEN WHEN LOGIN IMPLEMENTED
                  payload: { userId, sessionToken, darkMode }
                },
                '*'
              )
            }
          } catch (err) {
            // eslint-disable-next-line no-console
            console.log({ err })
          }
        }
        if (data?.type === 'history-back') window.history.back()
        if (data?.type === 'to-chat-action') toChatEventHandler()
      }
    }

    window.addEventListener('message', listenChatIframe)

    onUnmounted(() => {
      window.removeEventListener('message', listenChatIframe)
    })

    return { CHAT_URL, chatIframe }
  }
})
