
// Vendor
import { defineComponent, onMounted, ref } from 'vue'

// Componentes
import FormInvoiceInfo from '@/views/profile/components/FormInvoiceInfo.vue'
import FormUploadInvoice from '@/views/profile/components/FormUploadInvoice.vue'
import BillingHistory from '@/components/aero/surfaces/profile/BillingHistory/BillingHistory.vue'
import SkeletonInvoiceCard from '@/components/aero/skeleton/SkeletonInvoiceCard.vue'
import CustomTransition from '@/components/aero/misc/CustomTransition.vue'

// Services
import { getInvoiceInfo } from '@/services/newPlatform/profile.services'

// Stores
import { useUserSessionStore } from '@/store/modules/user-session.store'
import { useUserStore } from '@/store/modules/user.store'

// Models
import { Invoice } from '@/models/profile/billing'

export default defineComponent({
  components: { FormInvoiceInfo, FormUploadInvoice, BillingHistory, SkeletonInvoiceCard, CustomTransition },

  setup() {
    const { userId } = useUserSessionStore()
    const { isNotFromArg, phone } = useUserStore()

    const showFormUploadInvoice = ref(false)
    const invoiceId = ref('')
    const paymentId = ref('')

    const invoiceData = ref<Invoice>()

    const showFormToCompletBillingInfo = ref(false)
    const showFormWithCancelButton = ref(false)
    const defaultBillingInfo = ref({})
    const isFirstTimeFilling = ref(false)
    const isLoding = ref(true)

    const setInvoiceInfo = async () => {
      try {
        const invoiceInfo = await getInvoiceInfo(userId)

        defaultBillingInfo.value = {
          cuit: invoiceInfo.cuit,
          invoiceType: invoiceInfo.invoiceType,
          accountNumber: invoiceInfo.account.number,
          afipConstancy: invoiceInfo.afipConstancy,
          afipMonoCategory: invoiceInfo.afipMonoCategory,
          name: invoiceInfo.name,
          cbu_alias: invoiceInfo.cbu_alias,
          cbu: invoiceInfo.cbu,
          socialReason: invoiceInfo.socialReason,
          address: invoiceInfo.address,
          paypalAccount: invoiceInfo.paypalAccount,
          hasPaypalAccount: invoiceInfo.hasPaypalAccount,
          cp: invoiceInfo.cp,
          phone: phone
        }

        if (!isNotFromArg) {
          if (
            !!invoiceInfo.cuit &&
            !!invoiceInfo.invoiceType &&
            !!invoiceInfo.account.number &&
            !!invoiceInfo.afipConstancy &&
            !!invoiceInfo.cbu_alias &&
            !!invoiceInfo.cbu
          ) {
            showFormToCompletBillingInfo.value = false
            isFirstTimeFilling.value = false
          } else {
            showFormToCompletBillingInfo.value = true
            isFirstTimeFilling.value = true
          }
        } else {
          if (!!invoiceInfo.socialReason && !!invoiceInfo.address && !!invoiceInfo.paypalAccount) {
            showFormToCompletBillingInfo.value = false
            isFirstTimeFilling.value = false
          } else {
            showFormToCompletBillingInfo.value = true
            isFirstTimeFilling.value = true
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      } finally {
        isLoding.value = false
      }
    }

    const handleShowFormToCompletBillingInfo = async (value: boolean) => {
      if (value) await setInvoiceInfo()
      showFormToCompletBillingInfo.value = value
      showFormWithCancelButton.value = true
    }

    const handleShowUploadInvoice = (invoiceDataProps: { data: Invoice; invoiceId: string; paymentId: string }) => {
      invoiceData.value = invoiceDataProps.data
      invoiceId.value = invoiceDataProps.invoiceId
      paymentId.value = invoiceDataProps.paymentId
      showFormUploadInvoice.value = true
    }

    const handleHideUploadInvoice = () => (showFormUploadInvoice.value = false)

    onMounted(setInvoiceInfo)

    return {
      isLoding,
      showFormToCompletBillingInfo,
      userId,
      handleShowFormToCompletBillingInfo,
      handleShowUploadInvoice,
      handleHideUploadInvoice,
      defaultBillingInfo,
      showFormWithCancelButton,
      isFirstTimeFilling,
      showFormUploadInvoice,
      invoiceData,
      invoiceId,
      paymentId
    }
  }
})
