<template>
  <g clip-path="url(#clip0)">
  <path d="M9.00002 19.4985H6.75002C6.0055 19.4978 5.26964 19.3387 4.59129 19.0319C3.91294 18.725 3.30762 18.2774 2.8155 17.7187C2.32339 17.16 1.95573 16.503 1.73694 15.7913C1.51814 15.0797 1.45322 14.3296 1.54647 13.591C1.63972 12.8523 1.88902 12.1419 2.27781 11.507C2.66661 10.872 3.18601 10.327 3.80153 9.90817C4.41706 9.4893 5.11464 9.20615 5.84797 9.0775C6.5813 8.94886 7.3336 8.97765 8.05495 9.16199" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M7.5 11.9985C7.50012 10.8102 7.78261 9.63888 8.3242 8.58113C8.86579 7.52338 9.65097 6.60946 10.615 5.91468C11.5791 5.21991 12.6945 4.76416 13.8693 4.585C15.044 4.40584 16.2445 4.50839 17.3719 4.8842C18.4992 5.26001 19.5211 5.89833 20.3534 6.74656C21.1857 7.59478 21.8045 8.62863 22.1588 9.76292C22.5132 10.8972 22.5929 12.0995 22.3915 13.2706C22.19 14.4417 21.7132 15.5483 21.0002 16.499" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M11.0681 15.1805L14.25 11.9985L17.432 15.1805" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M14.25 19.4985V11.9985" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
  <clipPath id="clip0">
  <rect width="24" height="24" fill="white"/>
  </clipPath>
  </defs>
</template>
