<template>
  <path
    d="M8.00065 14.6667C11.6825 14.6667 14.6673 11.6819 14.6673 8.00004C14.6673 4.31814 11.6825 1.33337 8.00065 1.33337C4.31875 1.33337 1.33398 4.31814 1.33398 8.00004C1.33398 11.6819 4.31875 14.6667 8.00065 14.6667Z"
    stroke-linecap="round"
    stroke-width="2"
    stroke-linejoin="round"
  />
  <path
    d="M6.06055 6.00001C6.21728 5.55446 6.52665 5.17875 6.93385 4.93944C7.34105 4.70012 7.81981 4.61264 8.28533 4.69249C8.75085 4.77234 9.17309 5.01436 9.47726 5.3757C9.78144 5.73703 9.94792 6.19436 9.94721 6.66668C9.94721 8.00001 7.94721 8.66668 7.94721 8.66668"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path d="M8 11.3334H8.00667" stroke="#B6B6B6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
</template>
