
import DynamicCard from '@/components/commons/DynamicCard.vue'
import IconBase from '@/components/commons/IconBase.vue'
import { CorrectionStates } from '@/utils/constants'
import { toOrdinal } from '@/utils/spanishOrdinalConverter'
import { ICorrection } from '@/models/peer-review/correction'
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  emits: ['correction'],
  components: {
    DynamicCard,
    IconBase
  },
  props: {
    correction: Object as () => ICorrection,
    index: Number
  },
  setup (props, { emit }) {
    const { t } = useI18n()
    const iconColor = (status: string): string => {
      switch (status) {
        case CorrectionStates.BLOCKED:
          return '#BEBEBE'
        case CorrectionStates.DELIVERED:
          return '#82DB91'
        case CorrectionStates.READY_FOR_REVIEW:
          return '#EA90FF'
        default:
          return '#BEBEBE'
      }
    }
    const next = () => emit('correction')
    return { t, iconColor, CorrectionStates, toOrdinal, next }
  }
})
