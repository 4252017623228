// API V2 DTO
export type TagTypes = 'positive' | 'negative' | 'neutral'
export enum TagTypesEnum {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  NEUTRAL = 'neutral'
}
export interface ITracks {
  freelance: boolean
  entrepreneur: boolean
  professional: boolean
  nini: boolean
  job: boolean
  career: boolean
}

export interface IClasses {
  _id: string
  name: string
  order: number
  enable: boolean
  starter: boolean
  free: boolean
  workshop: boolean
  published: boolean
  leveling: boolean
  tracks: ITracks
}

export class Classes {
  id: string
  name: string
  constructor(data: IClasses) {
    this.id = data._id
    this.name = data.name
  }
}

export interface IComment {
  rating: number
  comments: string
}

export class Comment {
  rating: number
  comment: string
  type: TagTypesEnum
  constructor(data: IComment) {
    this.rating = data.rating
    this.comment = data.comments
    this.type = data.rating >= 4 ? TagTypesEnum.POSITIVE : data.rating < 3 ? TagTypesEnum.NEGATIVE : TagTypesEnum.NEUTRAL
  }
}

export interface ITag {
  tag: string
  total: number
}

export class Tag {
  tag: string
  total: number
  constructor(data: ITag) {
    this.tag = data.tag
    this.total = data.total
  }
}
export interface ITagData {
  rating: TagTypesEnum
  tags: ITag[]
  total: number
  mostVoted: string
}

export class TagData {
  rating: TagTypesEnum
  tags: Tag[]
  total: number
  mostVoted: string
  constructor(data: ITagData) {
    this.rating = data.rating
    this.tags = data.tags.map(tag => new Tag(tag))
    this.total = data.total
    this.mostVoted = data.mostVoted
  }
}

export interface IRating {
  rating: TagTypesEnum
  total: number
  percent: number
}
export class Rating {
  rating: TagTypesEnum
  total: number
  percent: number
  constructor(data: IRating) {
    this.rating = data.rating
    this.total = data.total
    this.percent = data.percent
  }
}
export interface IRatingScore {
  score: number
  ratings: IRating[]
}

export class RatingScore {
  score: number
  ratings: Rating[]
  constructor(data: IRatingScore) {
    this.score = Math.round(data?.score * 10) / 10
    this.ratings = data?.ratings.map(rating => new Rating(rating))
  }
}