import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0792e06d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ranking-card p-d-flex p-ai-center p-jc-between" }
const _hoisted_2 = { class: "p1 medium text-white" }
const _hoisted_3 = {
  key: 1,
  class: "ranking-card p-d-flex p-ai-center p-jc-start"
}
const _hoisted_4 = { class: "p1 p-ml-3 medium text-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_progress_circle = _resolveComponent("progress-circle")!
  const _component_chevron_right = _resolveComponent("chevron-right")!
  const _component_icon = _resolveComponent("icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.link)
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        to: _ctx.link
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_progress_circle, {
              radius: 40,
              progress: _ctx.progress,
              total: _ctx.total,
              color: "purple",
              variant: "points"
            }, null, 8, ["progress", "total"]),
            _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.ranking), 1),
            _createVNode(_component_icon, null, {
              default: _withCtx(() => [
                _createVNode(_component_chevron_right)
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["to"]))
    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
        _createVNode(_component_progress_circle, {
          radius: 40,
          progress: _ctx.progress,
          total: _ctx.total,
          color: "purple",
          variant: "points"
        }, null, 8, ["progress", "total"]),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.ranking), 1)
      ]))
}