
import Icon from '@/components/aero/icon/Icon.vue'
import Calendar from '@/components/aero/icon/templates/calendar.vue'
import CheckFill from '@/components/aero/icon/templates/check-fill.vue'
import CloseCircle from '@/components/aero/icon/templates/close-circle.vue'
import { ClassUser } from '@/models/classes/class'
import { defineComponent, PropType, computed } from 'vue'
import { getDate, getTime } from '@/utils/datetime'

export default defineComponent({
  components: { Icon, Calendar, CheckFill, CloseCircle },
  props: {
    data: {
      type: Object as PropType<ClassUser>,
      required: true
    },
    copies: {
      type: Object,
      required: true
    },
    isAfterclass: {
      type: Boolean,
      default: false,
      required: false
    },
    role: { type: Number, required: true }
  },
  setup(props) {
    const checkedClass = computed(() => {
      if (props.role === 2) {
        return !props.data.isUserAbsent && props.data.schedule?.to < Date.now()
      } else if (props.role === 3) {
        return props.data.schedule?.to < Date.now()
      } else {
        return false
      }
    })

    const userAbsent = computed(() => props.role === 2 && props.data.isUserAbsent && props.data.schedule?.to < Date.now())
    const formatedTime = computed(() => getTime(+props.data.schedule.from || 0))
    const formatedDate = computed(() => getDate(+props.data.schedule.day || 0))
    const order = Number(props.data?.order) < 10 ? `0${props.data.order}` : props.data.order

    return {
      formatedDate,
      formatedTime,
      checkedClass,
      userAbsent,
      order
    }
  }
})
