
import { defineComponent, ref, PropType } from 'vue'

// Components
import Card from '@/components/aero/surfaces/Card.vue'
import RadioButton from 'primevue/radiobutton'

// Models
import { IOptions } from '@/models/commons/InputOptions'

export default defineComponent({
  components: {
    Card,
    RadioButton
  },
  props: {
    contained: { type: Boolean, default: false },
    name: { type: String, required: true },
    options: { type: Array as PropType<IOptions[]> }
  },
  emits: ['onUpdateValue'],
  setup(_, { emit }) {
    const optionValue = ref()

    const handleUpdateValue = () => {
      emit('onUpdateValue', optionValue.value)
    }

    return { handleUpdateValue, optionValue }
  }
})
