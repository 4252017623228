
import { defineComponent, ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  setup () {
    const { t } = useI18n()
    const link = ref<string>('')

    onMounted(async () => {
      link.value = t('faqs.leyend.link')
    })

    return { 
      t, 
      link
    }
  }
})
