import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-17da3103"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container__space aside__space" }
const _hoisted_2 = { class: "t2 p-mb-4" }
const _hoisted_3 = { class: "container__space content__space" }
const _hoisted_4 = { class: "p-d-flex p-ai-center p-jc-between p-mb-4" }
const _hoisted_5 = { class: "t2 p-d-flex p-ai-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorPage = _resolveComponent("ErrorPage")!
  const _component_skeleton_beca_card = _resolveComponent("skeleton-beca-card")!
  const _component_up_to_date_place_holder = _resolveComponent("up-to-date-place-holder")!
  const _component_entrega_card = _resolveComponent("entrega-card")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_skeleton_desafio_card = _resolveComponent("skeleton-desafio-card")!
  const _component_desafio_card = _resolveComponent("desafio-card")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_two_cols_layout = _resolveComponent("two-cols-layout")!

  return (_ctx.isError)
    ? (_openBlock(), _createBlock(_component_ErrorPage, {
        key: 0,
        copies: _ctx.copies.error
      }, null, 8, ["copies"]))
    : (_openBlock(), _createBlock(_component_two_cols_layout, { key: 1 }, {
        aside: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.copies.studentChallenges.title), 1),
            (_ctx.isLoading)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, _renderList(3, (i) => {
                  return _createVNode(_component_skeleton_beca_card, {
                    key: i,
                    studentView: ""
                  })
                }), 64))
              : (!_ctx.isLoading && !_ctx.setPendingChallenges.length)
                ? (_openBlock(), _createBlock(_component_up_to_date_place_holder, { key: 1 }))
                : (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.setPendingChallenges.slice(0, 3), (challenge) => {
                    return (_openBlock(), _createBlock(_component_entrega_card, {
                      key: challenge._id,
                      useCoderChallenge: _ctx.useCoderChallenge,
                      camadaNro: _ctx.camadaNro,
                      challenge: challenge
                    }, null, 8, ["useCoderChallenge", "camadaNro", "challenge"]))
                  }), 128))
          ])
        ]),
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("p", _hoisted_5, [
                _createTextVNode(_toDisplayString(_ctx.challengeFilters === 'all'
                ? _ctx.copies.filterLabels.mainTitle
                : _ctx.filterOptions.find((op) => op.value === _ctx.challengeFilters).label) + " ", 1),
                _createVNode(_component_Badge, {
                  value: _ctx.filteredChallenges.length > 0 ? _ctx.filteredChallenges.length : '0',
                  class: "p-ml-2"
                }, null, 8, ["value"])
              ]),
              _createVNode(_component_Button, {
                label: _ctx.filterOptions.find((op) => op.value === _ctx.challengeFilters)?.label,
                class: _normalizeClass(["p-button-filter p-button-sm", [_ctx.menuIsOpen && 'open']]),
                icon: "pi pi-angle-down",
                iconPos: "right",
                onClick: _ctx.toggle,
                "aria-haspopup": "true",
                "aria-controls": "overlay_menu",
                style: {"color":"var(--light-gray)"}
              }, null, 8, ["label", "class", "onClick"]),
              _createVNode(_component_Menu, {
                id: "overlay_menu",
                ref: "menu",
                model: _ctx.filterOptions,
                popup: true,
                onShow: _ctx.menuToggle,
                onHide: _ctx.menuToggle
              }, null, 8, ["model", "onShow", "onHide"])
            ]),
            (_ctx.isLoading)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, _renderList(12, (i) => {
                  return _createVNode(_component_skeleton_desafio_card, { key: i })
                }), 64))
              : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.filteredChallenges, (challenge) => {
                  return (_openBlock(), _createBlock(_component_router_link, {
                    key: challenge._id,
                    to: _ctx.useCoderChallenge?`/reviews/${_ctx.camadaNro}/${challenge._id}/${challenge.stage}`:`/challenges/challenge/${challenge._id}`,
                    style: {"text-decoration":"none","color":"inherit"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_desafio_card, {
                        class: "p-mt-3",
                        useCoderChallenge: _ctx.useCoderChallenge,
                        camadaNro: _ctx.camadaNro,
                        challenge: challenge
                      }, null, 8, ["useCoderChallenge", "camadaNro", "challenge"])
                    ]),
                    _: 2
                  }, 1032, ["to"]))
                }), 128))
          ])
        ]),
        _: 1
      }))
}