
import { defineComponent } from 'vue'
import SkeletonText from '@/components/aero/skeleton/SkeletonText.vue'
import SkeletonBox from '@/components/aero/skeleton/SkeletonBox.vue'
import Card from '@/components/aero/surfaces/Card.vue'

export default defineComponent({
  components: { SkeletonText, SkeletonBox, Card },
  props: { elements: { type: Number, default: 2 } }
})
