<template>
  <div class="p-d-flex p-ai-center p-jc-between p-mb-2">
    <skeleton-text :length="20" />
    <div class="p-d-flex">
      <skeleton-text :length="25" class="p-mr-5" />
      <skeleton-text :length="25" />
    </div>
  </div>
  <skeleton-box height="40px" class="p-my-2" />
  <!-- <skeleton-box height="40px" /> -->
  <div class="table__content">
    <card class="p-d-flex p-ai-center card" v-for="i in 8" :key="i" paddingSm>
      <div class="p-d-flex p-mr-6 p-ai-center">
        <skeleton-box :height="'50px'" :width="'50px'" style="border-radius: 50%" class="p-mr-4" />
        <div>
          <skeleton-text :length="15" class="p-mb-2" />
        </div>
      </div>

      <div class="p-d-flex p-jc-evenly" style="flex: 1">
        <skeleton-icon v-for="i in 8" :key="i" />
      </div>
    </card>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import SkeletonText from '@/components/aero/skeleton/SkeletonText.vue'
import SkeletonBox from '@/components/aero/skeleton/SkeletonBox.vue'
import SkeletonIcon from '@/components/aero/skeleton/SkeletonIcon.vue'
import Card from '@/components/aero/surfaces/Card.vue'

export default defineComponent({
  components: { SkeletonText, SkeletonBox, SkeletonIcon, Card },
  props: { elements: { type: Number, default: 2 } }
})
</script>

<style lang="scss" scoped>
.table__content {
  .card:nth-child(even) {
    background-color: var(--dark-gray);
  }
}
</style>
