import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c47677b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "upload-flex" }
const _hoisted_2 = { class: "p-d-flex p-jc-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputFile = _resolveComponent("InputFile")!
  const _component_InputURL = _resolveComponent("InputURL")!
  const _component_InputEditor = _resolveComponent("InputEditor")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ChallengeCriteria = _resolveComponent("ChallengeCriteria")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_InputFile, {
      onSelect: _ctx.select,
      onRemove: _ctx.remove,
      maxFileSizeInMB: 10
    }, null, 8, ["onSelect", "onRemove"]),
    _createVNode(_component_InputURL, {
      label: _ctx.t('homework.challenge.upload.link.label'),
      modelValue: _ctx.v$.link.$model,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$.link.$model) = $event)),
      error: _ctx.v$.link.$invalid && _ctx.sent,
      errorMessage: _ctx.v$.link.$errors[0] && _ctx.t(`form.validation.${_ctx.v$.link.$errors[0].$validator}`)
    }, null, 8, ["label", "modelValue", "error", "errorMessage"]),
    _createVNode(_component_InputEditor, {
      label: _ctx.t('homework.challenge.upload.comment.label'),
      modelValue: _ctx.v$.comment.$model,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.v$.comment.$model) = $event)),
      onComment: _ctx.getComment,
      error: _ctx.v$.comment.$invalid && _ctx.sent,
      errorMessage: _ctx.v$.comment.$errors[0] && _ctx.t(`form.validation.${_ctx.v$.comment.$errors[0].$validator}`)
    }, null, 8, ["label", "modelValue", "onComment", "error", "errorMessage"]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.mode === 'edit')
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 0,
            label: "Cancelar",
            onClick: _ctx.cancel,
            class: "p-button-lg send p-button-custom-secondary p-mr-3"
          }, null, 8, ["onClick"]))
        : _createCommentVNode("", true),
      _createVNode(_component_Button, {
        disabled: _ctx.isEnabled,
        loading: _ctx.sending,
        label: _ctx.t(_ctx.sendLabelKey),
        onClick: _ctx.send,
        class: "p-button-lg send p-button-custom-primary"
      }, null, 8, ["disabled", "loading", "label", "onClick"])
    ]),
    _createVNode(_component_ChallengeCriteria, {
      visible: _ctx.showCriteria,
      criteria: _ctx.state.criteria,
      onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setShowCriteria(false)))
    }, null, 8, ["visible", "criteria"])
  ]))
}