<template>
  <div class="skeleton--icon skeleton" />
</template>

<style lang="scss" scoped>
.skeleton--icon {
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 9999px;
  width: var(--space-md);
  height: var(--space-md);
  min-height: var(--space);
}
</style>
