<template>
  <div class="line">
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
@import 'src/assets/styles/old_theme/variables.scss';

@mixin line-before($background-color: '#F0F0F0') {
  &:before {
    content: '';
    position: absolute;
    width: 2px;
    height: 100%;
    margin-left: 24px;
    z-index: -1;
    background-color: $background-color;
  }
}

.line {
  position: relative;
  z-index: 0;
  background-color: #2E2F36;
}
</style>
