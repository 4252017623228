import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b81c218"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "t2 bold p-mt-3 p-ml-2" }
const _hoisted_2 = { class: "p-d-flex p-jc-between p-ai-center p-mt-3 p-ml-2" }
const _hoisted_3 = { class: "p2 text-light-gray" }
const _hoisted_4 = { class: "p2 text-light-gray p-mr-4" }
const _hoisted_5 = {
  class: "students__scroll",
  id: "students-container"
}
const _hoisted_6 = {
  key: 0,
  class: "p3 text-white bold status__text"
}
const _hoisted_7 = {
  key: 1,
  class: "p-d-flex p-ai-center p-ml-2 status__container"
}
const _hoisted_8 = { class: "p3 text-blue bold status__text" }
const _hoisted_9 = {
  key: 2,
  class: "p3 text-apple-green bold status__text"
}
const _hoisted_10 = {
  key: 3,
  class: "p-d-flex p-ai-center p-ml-2 status__container"
}
const _hoisted_11 = { class: "p3 text-apple-green bold status__text" }
const _hoisted_12 = {
  key: 4,
  class: "p3 text-error bold status__text"
}
const _hoisted_13 = {
  key: 5,
  class: "p-d-flex p-ai-center p-ml-2 status__container"
}
const _hoisted_14 = { class: "p3 text-purple bold status__text" }
const _hoisted_15 = { class: "p-d-flex p-jc-between p-ai-center p-mt-3 challenge__header" }
const _hoisted_16 = { class: "t2 label-student text-white" }
const _hoisted_17 = { class: "label label-bold medium text-primary" }
const _hoisted_18 = ["href"]
const _hoisted_19 = { key: 1 }
const _hoisted_20 = {
  key: 2,
  class: "timeline__container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorPage = _resolveComponent("ErrorPage")!
  const _component_toast = _resolveComponent("toast")!
  const _component_SkeletonDesafioModule = _resolveComponent("SkeletonDesafioModule")!
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_clock = _resolveComponent("clock")!
  const _component_icon = _resolveComponent("icon")!
  const _component_alert_circle = _resolveComponent("alert-circle")!
  const _component_list_tab = _resolveComponent("list-tab")!
  const _component_SkeletonTimeline = _resolveComponent("SkeletonTimeline")!
  const _component_placeholder_state = _resolveComponent("placeholder-state")!
  const _component_message = _resolveComponent("message")!
  const _component_state_historial = _resolveComponent("state-historial")!
  const _component_review_challenge = _resolveComponent("review-challenge")!
  const _component_two_cols_layout = _resolveComponent("two-cols-layout")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_ctx.isError.aside)
    ? (_openBlock(), _createBlock(_component_ErrorPage, {
        key: 0,
        copies: _ctx.errorCopy
      }, null, 8, ["copies"]))
    : (_openBlock(), _createBlock(_component_two_cols_layout, { key: 1 }, {
        aside: _withCtx(() => [
          _createVNode(_component_toast),
          (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_SkeletonDesafioModule, { key: 0 }))
            : (_openBlock(), _createBlock(_component_Breadcrumb, {
                key: 1,
                class: "p-mt-3 p-ml-2",
                home: { label: 'Desafíos', to: '/challenges' },
                model: [{ label: `${_ctx.setBreadCrumTitle()}`, to: `/challenges/challenge/${_ctx.challengeId}` }]
              }, null, 8, ["model"])),
          _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.isPreIntegratorTp ? `${_ctx.copies.preIntegratorPrefix} ${_ctx.challengeName}` : _ctx.challengeName), 1),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.copies.asideList.label1), 1),
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.copies.asideList.label2), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.students, (studentDeliver) => {
              return (_openBlock(), _createBlock(_component_list_tab, {
                key: studentDeliver.student._id,
                DOMId: studentDeliver.student._id,
                student: studentDeliver,
                onOnClickCard: ($event: any) => (_ctx.handleClickCard(studentDeliver.student._id, studentDeliver.deliverId)),
                isActive: studentDeliver.student._id === _ctx.activeStudent._id,
                hasUnreadMessage: studentDeliver.chat && _ctx.checkUnreadMessages(studentDeliver.chat),
                scheduleId: _ctx.scheduleId,
                onMarkAsUnread: _ctx.markAsUnread,
                onLockDeliver: _ctx.handleLockDeliver,
                onUnlockDeliver: _ctx.handleUnlockDeliver
              }, {
                default: _withCtx(() => [
                  (_ctx.isExpired(studentDeliver, _ctx.isDeliverExpired))
                    ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.copies.statusTags.expired.label), 1))
                    : (_ctx.isToReceive(studentDeliver, _ctx.isDeliverExpired))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                          _createVNode(_component_icon, {
                            class: "p-mr-1",
                            strokeColor: "var(--blue)",
                            size: 16
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_clock)
                            ]),
                            _: 1
                          }),
                          _createElementVNode("p", _hoisted_8, _toDisplayString(studentDeliver.finalDeadline && studentDeliver.finalDeadline > Date.now()
                  ? _ctx.setCountdown(studentDeliver.finalDeadline, true)
                  : studentDeliver.unlockedUntil && studentDeliver.unlockedUntil > Date.now()
                  ? _ctx.setCountdown(studentDeliver.unlockedUntil, true)
                  : _ctx.setCountdown(studentDeliver.expiresAt, true)), 1)
                        ]))
                      : (studentDeliver.deliverId && studentDeliver.wasApproved && studentDeliver.wasReviewedOnTime)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.copies.statusTags.approved.label), 1))
                        : (studentDeliver.deliverId && studentDeliver.wasApproved && !studentDeliver.wasReviewedOnTime)
                          ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_10, [
                              _createVNode(_component_icon, {
                                class: "p-mr-1",
                                strokeColor: "var(--apple-green)",
                                size: 16
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_alert_circle)
                                ]),
                                _: 1
                              }),
                              _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.copies.statusTags.approvedOutOfTime.label), 1)
                            ])), [
                              [
                                _directive_tooltip,
                                `${_ctx.copies.statusTags.approvedOutOfTime.tooltip}`,
                                void 0,
                                { top: true }
                              ]
                            ])
                          : (_ctx.isOutOfTime(studentDeliver))
                            ? (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString(_ctx.copies.statusTags.reviewOutOfTime.label), 1))
                            : (_ctx.isPending(studentDeliver))
                              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                  _createVNode(_component_icon, {
                                    class: "p-mr-1",
                                    strokeColor: "var(--purple)",
                                    size: 16
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_clock)
                                    ]),
                                    _: 1
                                  }),
                                  _createElementVNode("p", _hoisted_14, _toDisplayString(studentDeliver.firstReviewEstimatedAt && _ctx.setCountdown(studentDeliver.firstReviewEstimatedAt)), 1)
                                ]))
                              : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["DOMId", "student", "onOnClickCard", "isActive", "hasUnreadMessage", "scheduleId", "onMarkAsUnread", "onLockDeliver", "onUnlockDeliver"]))
            }), 128))
          ])
        ]),
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", null, [
              _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.setActiveStudentName), 1),
              _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.copies.content.header.title), 1)
            ]),
            (_ctx.slideUrl)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: _ctx.slideUrl,
                  target: "_blank",
                  rel: "noopener noreferrer",
                  class: "p-button secondary",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleTrackEvent(_ctx.ChallengesEvents.VIEW_MODULE_ASSETS, { origin: 'Challenges by module' })))
                }, _toDisplayString(_ctx.copies.content.header.cta), 9, _hoisted_18))
              : _createCommentVNode("", true)
          ]),
          (_ctx.isTimeLineLoading)
            ? (_openBlock(), _createBlock(_component_SkeletonTimeline, { key: 0 }))
            : _createCommentVNode("", true),
          (_ctx.isError.timeline)
            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                _createVNode(_component_placeholder_state, {
                  hasError: "",
                  role: 3
                })
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_20, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeStudent.history, (step) => {
                  return (_openBlock(), _createBlock(_component_state_historial, {
                    key: step.key,
                    step: step,
                    role: 3,
                    isTutor: _ctx.isTutor,
                    isIntegratorTp: _ctx.isIntegratorTp
                  }, {
                    default: _withCtx(() => [
                      (step.key === _ctx.ITimeLineStatus.disabled)
                        ? (_openBlock(), _createBlock(_component_placeholder_state, {
                            key: 0,
                            dateString: _ctx.setDateTimeString(_ctx.challengeOpenDate),
                            isDisabled: "",
                            role: 3,
                            class: "step__spacing"
                          }, null, 8, ["dateString"]))
                        : (step.key === _ctx.ITimeLineStatus.open && !_ctx.activeStudent.deliverId && !_ctx.isDeliverExpired)
                          ? (_openBlock(), _createBlock(_component_placeholder_state, {
                              key: 1,
                              dateString: _ctx.setDateTimeString(_ctx.expiresAt),
                              role: 3,
                              class: "step__spacing"
                            }, null, 8, ["dateString"]))
                          : (step.key === _ctx.ITimeLineStatus.chat && !step.hide)
                            ? (_openBlock(), _createBlock(_component_message, {
                                key: 2,
                                avatar: step.content.from.avatar,
                                userName: step.content.from.fullName,
                                id: step.content._id,
                                messageType: step.content.from._id === _ctx.activeStudent._id ? 'received' : 'sent',
                                message: step.content.text,
                                date: step.content.date,
                                file: step.content.file ? { text: step.content.link } : null,
                                link: !step.content.file && step.content.link ? { text: step.content.link } : null,
                                class: "step__spacing"
                              }, null, 8, ["avatar", "userName", "id", "messageType", "message", "date", "file", "link"]))
                            : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1032, ["step", "isTutor", "isIntegratorTp"]))
                }), 128)),
                (_ctx.setReviewChallenge)
                  ? (_openBlock(), _createBlock(_component_review_challenge, {
                      key: 0,
                      isTutor: _ctx.isTutor,
                      teacherId: _ctx.teacherId,
                      isIntegrator: _ctx.isIntegratorTp,
                      isPreIntegrator: _ctx.isPreIntegratorTp,
                      userId: _ctx.activeStudent._id,
                      project: {
            courseId: _ctx.courseId,
            _id: _ctx.activeStudent.deliverId,
            name: _ctx.challengeName,
            score: _ctx.activeStudent.score,
            softScore: _ctx.activeStudent.fromSoftScore,
            wasApproved: _ctx.activeStudent.wasApproved
          },
                      onReloadChallenge: _cache[1] || (_cache[1] = ($event: any) => (_ctx.getDeliverStatus(_ctx.activeStudent.deliverId))),
                      onReviewSuccess: _ctx.handleCorrectionSuccess,
                      onReviewFailed: _ctx.handleCorrectionFailed,
                      class: _normalizeClass(["step__spacing", !_ctx.isTutor && !_ctx.isIntegratorTp ? 'p-mt-0' : ''])
                    }, null, 8, ["isTutor", "teacherId", "isIntegrator", "isPreIntegrator", "userId", "project", "onReviewSuccess", "onReviewFailed", "class"]))
                  : (
            (_ctx.activeStudent.deliverId &&
              !_ctx.activeStudent.wasApproved &&
              _ctx.activeStudent.unlockedUntil &&
              _ctx.activeStudent.unlockedUntil < Date.now()) ||
            (_ctx.activeStudent.deliverId &&
              _ctx.activeStudent.finalDeadline &&
              _ctx.activeStudent.finalDeadline < Date.now() &&
              !_ctx.activeStudent.wasApproved) ||
            (!_ctx.activeStudent.deliverId && _ctx.isDeliverExpired)
          )
                    ? (_openBlock(), _createBlock(_component_placeholder_state, {
                        key: 1,
                        role: 3,
                        isExpired: "",
                        class: "step__spacing"
                      }))
                    : _createCommentVNode("", true)
              ]))
        ]),
        _: 1
      }))
}