
import { defineComponent, PropType } from 'vue'

// Models
import { ReferralIcon } from '@/models/profile/profile'

// Icons
import { Linkedin, Whatsapp, Download, Copy } from '@/components/aero/icon/templates'

export default defineComponent({
  props: {
    name: { type: String as PropType<ReferralIcon>, required: true }
  },
  components: {
    Linkedin,
    Whatsapp,
    Download,
    Copy
  }
})
