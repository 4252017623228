
// Vendor
import { defineComponent } from 'vue'

// Components
import ProgressCircle from '@/components/aero/misc/ProgressCircle.vue'
import Icon from '@/components/aero/icon/Icon.vue'
import { ChevronRight } from '@/components/aero/icon/templates'

export default defineComponent({
  components: { ProgressCircle, Icon, ChevronRight },
  props: {
    link: { type: String, required: false },
    progress: { type: Number, default: 0, required: true },
    total: { type: Number, default: 0, required: true },
    ranking: { type: String, default: 'Puesto #0 del ranking', required: true }
  }
})
