
import { useUserSessionStore } from '@/store/modules/user-session.store'
import { useUserStore } from '@/store/modules/user.store'
import { defineComponent, onMounted, Ref, ref } from 'vue'
import { getUserModalities, getUserIndustries, setUserModalities, setUserIndustries } from '@/services/newPlatform/profile.services'
import { getCompanyDetail } from '@/api/job-offers.api'
import { UserWorkPreference } from '@/models/profile/profile'
import copies from '@/locales/profile/es.json'

import GoalsModal from './modals/GoalsModal.vue'

export default defineComponent({
	components: { GoalsModal },
  setup(_, { emit }) {
    const profileGoals: any = copies.editProfile.profileGoals
    const displayModal = ref(false)
    const user = useUserStore()
    const { userId } = useUserSessionStore()
    const userModalities: Ref<UserWorkPreference[]> = ref([])
    const userIndustries: Ref<UserWorkPreference[]> = ref([])
    const industriesList: Ref<UserWorkPreference[]> = ref([])
    const showAllModalities = ref(false)
    const showAllInterests = ref(false)

    const openModal = () => {
      displayModal.value = true
    }

    const closeModal = async () => {  
      try {
        await getProfessionalGoals()
        displayModal.value = false
      } catch (error) {
        displayModal.value = false
      }
    }

    const intersectionBy = (a: any, b: any, f: any) => a.filter((v: any) => b.some((u: any) => f(v, u)))

    const getProfessionalGoals = async () => {
      industriesList.value = (await getCompanyDetail('area')).map(({_id, value}: {_id: string, value: string}) => { 
        return { name: value, id: _id }
      }) 
      userModalities.value = (await getUserModalities(userId)).map(({id, name}: {id: string, name: string}) => { 
        return { name, id }
      }) 
      userIndustries.value = (await getUserIndustries(userId)).map(({id, name}: {id: string, name: string}) => { 
        return { name, id }
      })

      // Check coincidences
      userIndustries.value = intersectionBy(industriesList.value, userIndustries.value, (v: any, u: any) => v.id === u.id)
    }

    const deleteModality = async (modalityToDelete: string) => {
      userModalities.value = userModalities.value.filter(({ id }:{ id: string}) => id !== modalityToDelete)
      await setUserModalities(userId, userModalities.value)
      await getProfessionalGoals()
    }

    const deleteIndustry = async (industryToDelete: string) => {
      userIndustries.value = userIndustries.value.filter(({ id }:{ id: string}) => id !== industryToDelete)
      await setUserIndustries(userId, userIndustries.value)
      await getProfessionalGoals()
    }

    const toggleAllModalities = () =>{
      showAllModalities.value = !showAllModalities.value
    }

    const toggleAllInterests = () =>{
      showAllInterests.value = !showAllInterests.value
    }

    onMounted(getProfessionalGoals)

    return { userModalities, userIndustries, showAllModalities, showAllInterests, toggleAllModalities, toggleAllInterests, displayModal, openModal, closeModal, deleteModality, deleteIndustry, profileGoals, user }
  }
})
