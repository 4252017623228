
// Vendor
import { onMounted, ref, defineComponent } from 'vue'

// Store
import { themeStore } from '@/store/modules/themes.store'
import { useUserStore } from '@/store/modules/user.store'
import { useCourseStore } from '@/store/modules/course.store'
import { useFirebaseStore } from '@/store/modules/firebase.store'

// Components
import ChallengesStudent from '@/views/challenges/components/ChallengesStudent.vue'
import ChallengesTeacher from '@/views/challenges/components/ChallengesTeacher.vue'

// Challenge copies
import copies from '@/locales/challenges/es.json'

export default defineComponent({
  components: {
    ChallengesStudent,
    ChallengesTeacher
  },
  setup() {
    const { camadaNro } = useCourseStore()
    const useCoderChallenge = ref<boolean>(false)

    themeStore().changeDefault('aero')
    const { id, role, isTutor } = useUserStore()
    const firebase = useFirebaseStore()

    onMounted(async () =>{
      const coderChallenges = await firebase.fetchValue('use_fresh_challenge', 'array') as string[]
      useCoderChallenge.value = coderChallenges.includes(camadaNro)
    })

    return { userId: id, role, useCoderChallenge, isTutor, copies }
  }
})
