
  import { defineComponent, ref, onMounted, Ref } from 'vue'
  import JobOffersFilters from './components/JobOffersFilters.vue'
  import JobOfferList from './components/JobOfferList.vue'
  import { useUserStore } from '@/store/modules/user.store'
  import { getUserIndustries, getUserModalities, getUserExpertise } from '@/services/newPlatform/profile.services'
  import { useToast } from 'primevue/usetoast'
  import Loader from '@/components/commons/Loader.vue'
  // import CustomOffersDropdown from './components/CustomOffersDropdowns.vue'
  
  export default defineComponent({
    components: {
      JobOffersFilters,
      // CustomOffersDropdown,
      JobOfferList,
      Loader
    },
    setup () {
      const user = useUserStore()
      const toast = useToast()
      const dropdowns = ref(null)
      const hasCoderPlus = ref(user.subscription.includes('CoderPlus'))
      const searchValue:Ref<boolean> = ref(false)
      const userAreaId: Ref<string> = ref('')
      const userSectorsId: Ref<string[]> = ref([])
      const userSectorsName: Ref<string[]> = ref([])
      const userModalitiesId: Ref<string[]> = ref([])
      const loading: Ref<boolean> = ref(true)
  
      const userId = user.id
      const hasSetPreferences: Ref<boolean> = ref(user.preferencesFlag)
  
      const preferences = ref(false)
  
      const getUserPreferences = async () => {
        loading.value = true
        const userIndustries = await getUserIndustries(userId)
        userSectorsId.value = userIndustries.map(({ id }: { id: string}) => id )
        userSectorsName.value = userIndustries.map(({ name }: { name: string}) => name )
        userModalitiesId.value = (await getUserModalities(userId)).map(({ id }: { id: string}) => id )
        userAreaId.value = (await getUserExpertise(userId))[0]?.id || ''
        loading.value = false
        if(userAreaId.value && userSectorsId.value.length && userModalitiesId.value.length) {
          preferences.value = true
        }
      }
  
      const setSearchValue = (event: any) => {
        searchValue.value = event.isSearching
      }
      const displayDialog = ref(false)
      const openDialog = () => {
        displayDialog.value = true
      }
      const closeDialog = () => {
        displayDialog.value = false
      }
      const showDialog = () => {
        if(hasCoderPlus.value && !hasSetPreferences.value){
          openDialog()
        }
      }
      const changePreferencesValue = () => {
        preferences.value = true
      }
      const submitToast = () => {
        toast.add({
          severity: 'success',
          detail: '¡Listo! Se guardaron tus preferencias ✨',
          life: 3000
        })
      }
      const submitForm = async () => {
        const isFormCorrect = await (dropdowns.value as any).submit()
        if (isFormCorrect) {
          closeDialog()
          submitToast()
          getUserPreferences()
          changePreferencesValue()
        }
      }
      onMounted( async () => {
        await user.getUser()
        await getUserPreferences()
        showDialog()
      })

      const hasFilters = ref(true)
      const ifhasFilters = (e: any) => {
        hasFilters.value = e
      }

      const isOfertasTitle = ref(false)
      const tabSelected = (e: any) => {
        e === 1 && hasCoderPlus.value ? isOfertasTitle.value = true : isOfertasTitle.value = false
      }
  
      return {
        setSearchValue,
        searchValue,
        ifhasFilters,
        hasFilters,
        submitForm,
        showDialog,
        dropdowns,
        displayDialog,
        userAreaId,
        userSectorsId,
        userModalitiesId,
        hasCoderPlus,
        closeDialog,
        openDialog,
        userSectorsName,
        loading,
        preferences,
        isOfertasTitle,
        tabSelected,
        hasSetPreferences
      }
    }
  })
  