import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_instagram = _resolveComponent("instagram")!
  const _component_facebook = _resolveComponent("facebook")!
  const _component_twitter = _resolveComponent("twitter")!
  const _component_linkedin = _resolveComponent("linkedin")!
  const _component_behance = _resolveComponent("behance")!
  const _component_upload = _resolveComponent("upload")!
  const _component_github = _resolveComponent("github")!
  const _component_dribbble = _resolveComponent("dribbble")!
  const _component_youtube = _resolveComponent("youtube")!
  const _component_vimeo = _resolveComponent("vimeo")!

  return (_ctx.name === 'instagram')
    ? (_openBlock(), _createBlock(_component_instagram, { key: 0 }))
    : (_ctx.name === 'facebook')
      ? (_openBlock(), _createBlock(_component_facebook, { key: 1 }))
      : (_ctx.name === 'twitter')
        ? (_openBlock(), _createBlock(_component_twitter, { key: 2 }))
        : (_ctx.name === 'linkedin')
          ? (_openBlock(), _createBlock(_component_linkedin, { key: 3 }))
          : (_ctx.name === 'behance')
            ? (_openBlock(), _createBlock(_component_behance, { key: 4 }))
            : (_ctx.name === 'website')
              ? (_openBlock(), _createBlock(_component_upload, { key: 5 }))
              : (_ctx.name === 'github')
                ? (_openBlock(), _createBlock(_component_github, { key: 6 }))
                : (_ctx.name === 'dribbble')
                  ? (_openBlock(), _createBlock(_component_dribbble, { key: 7 }))
                  : (_ctx.name === 'youtube')
                    ? (_openBlock(), _createBlock(_component_youtube, { key: 8 }))
                    : (_ctx.name === 'vimeo')
                      ? (_openBlock(), _createBlock(_component_vimeo, { key: 9 }))
                      : _createCommentVNode("", true)
}