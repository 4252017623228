import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_attendance_table = _resolveComponent("attendance-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("div", null, [
      _createVNode(_component_attendance_table, {
        isTutor: false,
        tutor: {},
        data: _ctx.setTutorsData.users,
        columns: _ctx.columns,
        filterTitle: _ctx.filterTitle,
        schedules: _ctx.schedules,
        attendances: _ctx.setTutorsData.attendance,
        onOnChange: _ctx.handleToggleAttendance
      }, null, 8, ["data", "columns", "filterTitle", "schedules", "attendances", "onOnChange"])
    ], 512), [
      [_vShow, _ctx.filterSelected === 'tutors']
    ]),
    _withDirectives(_createElementVNode("div", null, [
      _createVNode(_component_attendance_table, {
        isTutor: false,
        tutor: {},
        data: _ctx.setStudentsData.users,
        columns: _ctx.columns,
        filterTitle: _ctx.filterTitle,
        schedules: _ctx.schedules,
        attendances: _ctx.setStudentsData.attendance,
        onOnChange: _ctx.handleToggleAttendance
      }, null, 8, ["data", "columns", "filterTitle", "schedules", "attendances", "onOnChange"])
    ], 512), [
      [_vShow, _ctx.filterSelected === 'students']
    ])
  ], 64))
}