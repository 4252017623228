import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1988ca06"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.asLinkType === 'internal' && !!_ctx.href)
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        to: _ctx.href,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onClick')))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_icon, {
            strokeColor: !!_ctx.color ? _ctx.color : 'var(--secondary)',
            size: _ctx.size
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ]),
            _: 3
          }, 8, ["strokeColor", "size"])
        ]),
        _: 3
      }, 8, ["to"]))
    : (_ctx.asLinkType === 'external' && !!_ctx.href)
      ? (_openBlock(), _createElementBlock("a", {
          key: 1,
          href: _ctx.href,
          target: "_blank",
          rel: "noopener noreferrer",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('onClick')))
        }, [
          _createVNode(_component_icon, {
            strokeColor: !!_ctx.color ? _ctx.color : 'var(--secondary)',
            size: _ctx.size
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ]),
            _: 3
          }, 8, ["strokeColor", "size"])
        ], 8, _hoisted_1))
      : (_openBlock(), _createElementBlock("button", {
          key: 2,
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('onClick')))
        }, [
          _createVNode(_component_icon, {
            strokeColor: !!_ctx.color ? _ctx.color : 'var(--white)',
            size: _ctx.size
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ]),
            _: 3
          }, 8, ["strokeColor", "size"])
        ]))
}