import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fedc7636"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card__container" }
const _hoisted_2 = { class: "p-jc-between p-p-3" }
const _hoisted_3 = { class: "p2 p-mb-2 medium text-light-gray" }
const _hoisted_4 = { class: "p1 medium bold" }
const _hoisted_5 = {
  key: 0,
  class: "p-d-flex p-flex-row p-mt-3 p-ai-center p-jc-between"
}
const _hoisted_6 = { class: "p3 medium text-white" }
const _hoisted_7 = { class: "p-d-flex p-flex-row p-ai-center p-jc-center" }
const _hoisted_8 = {
  key: 0,
  class: "p2 medium p-ml-2"
}
const _hoisted_9 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_avatar = _resolveComponent("avatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.offers.offerLabel.replace('40% off', `${_ctx.offer.toLowerCase()}`)), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.course), 1),
      (_ctx.relatedStudents?.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.offers.signedUpStudents), 1),
            _createElementVNode("div", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.relatedStudents.slice(0).splice(0, 4), (student) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "p-d-flex p-flex-row avatar-item",
                  key: student._id
                }, [
                  _createVNode(_component_avatar, {
                    name: student.fullName,
                    imageLink: student.avatar
                  }, null, 8, ["name", "imageLink"])
                ]))
              }), 128)),
              (_ctx.relatedStudents.length > 3)
                ? (_openBlock(), _createElementBlock("p", _hoisted_8, " +" + _toDisplayString(_ctx.relatedStudents.length - _ctx.relatedStudents.slice(0).splice(0, 4).length), 1))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("a", {
        href: _ctx.link,
        target: "_blank",
        class: "btn-moreinformation p-mt-3"
      }, _toDisplayString(_ctx.offers.moreInformation), 9, _hoisted_9)
    ])
  ]))
}