
// Vendor
import { defineComponent, onMounted, ref, reactive, watch } from 'vue'
import { DateTime } from 'luxon'

// Stores
import { themeStore } from '@/store/modules/themes.store'
import { useUserSessionStore } from '@/store/modules/user-session.store'
import { useUserStore } from '@/store/modules/user.store'
import { useCourseStore } from '@/store/modules/course.store'

// Layout
import TwoColsLayout from '@/components/aero/layout/TwoColsLayout.vue'

// Services
import {
  getAllPosts,
  getRatingAndNextClass,
  getCertificate,
  getUserRanking,
  getPerk,
  savePostService,
  deletePostService
} from '@/services/newPlatform/dashboard.services'

// Models
import { IPost, Post, CommunityTag as Tag, IGetPostsResponse } from '@/models/dashboard/community'
import { Schedule } from '@/models/dashboard/rating'
import { UserRanking } from '@/models/dashboard/ranking'
import { Perk } from '@/models/dashboard/perks'

// Components
import SkeletonPost from '@/components/aero/skeleton/SkeletonPost.vue'
import DashboardHeader from '@/views/dashboard/components/DashboardHeader.vue'
import SkeletonDateCard from '@/components/aero/skeleton/SkeletonDateCard.vue'
import RatingSummary from '@/components/aero/surfaces/dashboard/RatingSummary.vue'
import ErrorPage from '@/components/aero/dataDisplay/ErrorPage.vue'
import PostCommunity from '@/components/aero/dataDisplay/dashboard/PostCommunity.vue'
import DateCard from '@/components/aero/surfaces/dashboard/DateCard.vue'
import PlaceholderState from '@/components/aero/surfaces/challenges/PlaceholderState.vue'
import EndCourseCard from './components/EndCourseCard.vue'
import StudentRanking from './components/StudentRanking.vue'
import LoadingMorePosts from './components/LoadingMorePosts.vue'
import UpsellingCard from '@/components/aero/feedback/dashboard/UpsellingCard.vue'
import Toast from '@/components/aero/feedback/Toast.vue'
import Dialog from 'primevue/dialog'
import Onboarding from './components/Onboarding.vue'
import ToTopButton from '@/components/aero/buttons/ToTopButton.vue'

// PrimeVue components
import Button from 'primevue/button'

// copies
import copies from '@/locales/dashboard/es.json'

// Hooks
import { useToast } from 'primevue/usetoast'
import { ToastMessageOptions } from 'primevue/toast'

export default defineComponent({
  components: {
    TwoColsLayout,
    DashboardHeader,
    SkeletonPost,
    RatingSummary,
    PostCommunity,
    SkeletonDateCard,
    DateCard,
    Dialog,
    Toast,
    ErrorPage,
    PlaceholderState,
    StudentRanking,
    UpsellingCard,
    Onboarding,
    EndCourseCard,
    Button,
    LoadingMorePosts,
    ToTopButton
  },

  setup() {
    const theme = themeStore()
    theme.changeDefault('aero')

    const session = useUserSessionStore()
    const user = useUserStore()

    const displayDialog = ref<boolean>(false)
    const postToDelete = ref<IPost['_id']>()
    const pendingDelete = ref<boolean>(false)

    const course = useCourseStore()

    const toast = useToast()

    const posts = ref<Array<Post>>([])
    const pinnedPosts = ref<Array<Post>>([])
    const skip = ref<number>(0)
    const toSkipAmount = 20
    const areMorePosts = ref<boolean>(true)
    const searchedWords = ref<string[]>([])
    const searchedPostsNotFound = ref<boolean>(false)

    const filtersApplied = ref<Tag[]>([])

    const postData = ref<{ topic: Tag['tag']; postContent: string }>({
      topic: '1',
      postContent: ''
    })

    const nextClass = ref<Schedule>()
    const courseEnded = ref<boolean>()
    const courseEndedLink = ref<string>('')
    const ratingData = reactive({
      rating: 0,
      positives: 0,
      neutrals: 0,
      negatives: 0
    })
    const userRanking = ref<UserRanking>()
    const perk = ref<Perk>()

    const loading = ref<boolean>(true)
    const loadingMore = ref<boolean>(false)
    const isError = ref<{ posts: boolean; scheduleRating: boolean; loadMorePosts: boolean }>({
      posts: false,
      scheduleRating: false,
      loadMorePosts: false
    })

    const currentToast = ref<'loadMorePosts' | 'delete' | 'searchError' | null>(null)

    /**
     * Opens a dialog modal and sets the id of the post to be deleted
     */
    const handleOpenDialog = (postId: IPost['_id']) => {
      postToDelete.value = postId
      displayDialog.value = true
    }

    /**
     * Event that gets invoked everytime a filter is applied/removed
     * @param filters Array of applied filters using community tags coming from child component Dashboard Header
     */
    const onChangeFilters: (filters: Tag[]) => void = (filters) => {
      filtersApplied.value = filters
    }

    /**
     * Closes the pop-up dialog window
     */
    const handleCloseDialog = () => {
      displayDialog.value = false
      postToDelete.value = undefined
    }

    /**
     * Deletes a post using the `postToDelete` value.
     */
    const deletePost = async () => {
      try {
        pendingDelete.value = true
        if (postToDelete.value) {
          await deletePostService(postToDelete.value)
          posts.value = [...posts.value.filter(({ _id }) => _id !== postToDelete.value)]
          // Show error toast when post was deleted successfully
          onDelete(true)
        }
      } catch (error) {
        // Show error toast when it couldn't be deleted
        onDelete(false)
      } finally {
        pendingDelete.value = false
        displayDialog.value = false
      }
    }

    /**
     * Method to get the data required by the component
     */
    const handleMountedComponent = async () => {
      try {
        const response: { posts: Post[]; pinnedPosts: Post[] } = (await getAllPosts({
          userId: session.userId,
          role: user.role,
          skip: skip.value,
          category: 'all'
        })) as IGetPostsResponse

        posts.value = response.posts
        // TODO: separate in other request
        pinnedPosts.value = response.pinnedPosts
      } catch (err) {
        isError.value.posts = true
      }
      try {
        const ratingAndNextClass = await getRatingAndNextClass(session.userId, course.camadaNro)

        courseEnded.value = DateTime.fromISO(ratingAndNextClass.endDate).toMillis() < Date.now()

        // Avoid API call when user isn't a student
        if (user.role === 2) {
          try {
            if (courseEnded.value) {
              const perkFromService = await getPerk(session.userId, course.id)
              perk.value = perkFromService

              const certificate = await getCertificate(session.userId, course.camadaId)
              courseEndedLink.value = certificate
            } else {
              const ranking = await getUserRanking(session.userId, course.id)
              userRanking.value = ranking
            }
          } catch (error) {
            courseEndedLink.value = ''
          }
        }

        nextClass.value = ratingAndNextClass.nextClass

        ratingData.rating = user.isTutor ? +ratingAndNextClass.rating.tutorRating : +ratingAndNextClass.rating.teacherRating
        ratingData.positives = ratingAndNextClass.rating.positivesPerc
        ratingData.neutrals = ratingAndNextClass.rating.neutralsPerc
        ratingData.negatives = ratingAndNextClass.rating.negativesPerc
      } catch (error) {
        isError.value.scheduleRating = true
      } finally {
        loading.value = false
      }
    }

    /**
     * Method to get more posts and render them
     */
    const handleAddMorePosts = async () => {
      // remove toast if user is trying to make fetch again
      // without this, user could make many clicks and add many times posts to array
      toast.removeAllGroups()

      loadingMore.value = true

      try {
        if (areMorePosts.value) {
          skip.value += toSkipAmount
        }

        const response: { posts: Post[]; pinnedPosts: Post[] } = (await getAllPosts({
          userId: session.userId,
          role: user.role,
          skip: skip.value,
          // TODO: Replace `all` value with Vue ref to change filters along with filters applied array
          category: 'all'
        })) as IGetPostsResponse

        posts.value = [...posts.value, ...response.posts]
        // TODO: remove this line when separate request, should get only a few posts, so one request should be enough
        pinnedPosts.value = [...pinnedPosts.value, ...response.pinnedPosts]

        areMorePosts.value = response.posts.length === toSkipAmount
      } catch (error) {
        handleAddMorePostsError()
      } finally {
        loadingMore.value = false
      }
    }

    /**
     * Method to get more posts when scroll to bottom
     */
    const handleInfiniteScroll = async () => {
      const isBottom = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight

      const shouldLoadMorePosts =
        isBottom && areMorePosts.value && !isError.value.loadMorePosts && !loading.value && currentToast.value !== 'loadMorePosts'

      if (shouldLoadMorePosts) {
        await handleAddMorePosts()
      }
    }

    window.addEventListener('scroll', handleInfiniteScroll)

    /**
     * Method to toggle bookmark icon and save/unsave post
     */
    const handleSaveUnsavePost = async (blogId: string) => {
      const savedPost = [...pinnedPosts.value, ...posts.value].find((post) => post._id === blogId)

      let success: boolean | null = null

      try {
        success = await savePostService(user.id, blogId)
        if (!success) {
          throw new Error()
        }
      } catch (error) {
        success = false
      } finally {
        if (savedPost?.flags.wasSaved || !success) {
          // I'm sure that success will always has a boolean value here
          handleSaveFeedback(success!)
        }
      }
    }

    /**
     * Method to manage value of new post input (topic selector and rich text input)
     */
    const onChangeNewPost = (key: string, value: string) => {
      // TODO: implement logic for creating a new post when API is ready for receiving categories/topics
      postData.value = {
        ...postData.value,
        [key]: value
      }
    }

    /**
     * Method to manage search
     */
    const handleSearch = async (searchByTitle: string) => {
      try {
        skip.value = 0

        const response: { posts: Post[]; pinnedPosts: Post[] } = (await getAllPosts({
          userId: session.userId,
          role: user.role,
          skip: skip.value,
          searchByTitle
        })) as IGetPostsResponse

        posts.value = response.posts
        pinnedPosts.value = response.pinnedPosts

        areMorePosts.value = response.posts.length === toSkipAmount

        searchedWords.value = searchByTitle.split(' ')

        searchedPostsNotFound.value = !posts.value.length && !pinnedPosts.value.length && !!searchedWords.value.length
      } catch (error) {
        handleSearchError()
      }
    }

    onMounted(handleMountedComponent)

    /**
     * Callback that gets executed everytime the Course's store data changes
     */
    const onUpdateStore = () => {
      loading.value = true
      skip.value = 0
      areMorePosts.value = true
      handleMountedComponent()
    }

    // Watchers
    watch(course, onUpdateStore)
    // TODO: Watch changes on filters applied and make API calls to fetch topic-related posts

    // Toasts
    /**
     * Shows toast indicating request status for deleting a particular post
     * @param success indicates whether to show an error or success toast depending on request status
     */
    const onDelete = (success: boolean) => {
      const { toast: _toast } = copies

      currentToast.value = 'delete'

      toast.add({
        severity: success ? _toast.deletePost.toastSeverity.success as 'success' : _toast.deletePost.toastSeverity.error as 'error',
        detail: success ? _toast.deletePost.success : _toast.deletePost.error,
        group: _toast.deletePost.group,
        life: _toast.deletePost.life
      })

      currentToast.value = null
    }

    const handleAddMorePostsError = () => {
      currentToast.value = 'loadMorePosts'

      skip.value -= toSkipAmount

      isError.value.loadMorePosts = true

      toast.add({
        severity: copies.toast.loadMorePosts.severity as ToastMessageOptions['severity'],
        detail: copies.toast.loadMorePosts.error,
        group: copies.toast.loadMorePosts.group,
        life: copies.toast.loadMorePosts.life
      })

      currentToast.value = null
    }

    const handleSearchError = () => {
      currentToast.value = 'searchError'

      toast.add({
        severity: copies.toast.search.severity as 'error',
        detail: copies.toast.search.error,
        group: copies.toast.search.group,
        life: copies.toast.search.life
      })

      currentToast.value = null
    }

    /**
     * Method to handle errors when save/unsave post
     */
    const handleSaveFeedback = (success: boolean) => {
      const resultStr = success ? 'success' : 'error'

      toast.add({
        severity: copies.toast.savePost.severity[resultStr] as ToastMessageOptions['severity'],
        detail: copies.toast.savePost[resultStr],
        group: copies.toast.savePost.group,
        life: copies.toast.savePost.life
      })
    }

    const handleCleanSearch = () => {
      handleSearch('')
    }

    return {
      copies,
      handleAddMorePosts,
      handleSaveUnsavePost,
      deletePost,
      displayDialog,
      pendingDelete,
      filtersApplied,
      onChangeNewPost,
      isError,
      loading,
      onChangeFilters,
      loadingMore,
      pinnedPosts,
      posts,
      ratingData,
      user,
      nextClass,
      handleOpenDialog,
      handleCloseDialog,
      userRanking,
      course,
      courseEnded,
      courseEndedLink,
      perk,
      handleSearch,
      handleCleanSearch,
      currentToast,
      searchedWords,
      searchedPostsNotFound
    }
  }
})
