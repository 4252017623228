import { IUser } from '@/models/classes/user'
import { IClass } from '@/models/classes/class'
import { IProjectProgress } from '@/models/classes/projectProgress'

interface IClasses {
  classes: {
    classesList: IClass[]
    complementarySchedules?: IClass[]
    projectProgress?: IProjectProgress
  }
}

export interface IClassesData {
  user: IUser
  content: IClasses
  courseId: string
  endDate: Date
}

export class ClassesData {
  user: IUser
  content: IClasses
  courseId: string
  endDate: Date

  constructor(from: IClassesData) {
    this.user = from.user
    this.content = from.content
    this.courseId = from.courseId
    this.endDate = from.endDate
  }
}
