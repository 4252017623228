
import DynamicCard from '@/components/commons/DynamicCard.vue'
import { defineComponent, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: { DynamicCard },
  props: {
    slide: {
      type: String
    }
  },
  setup (props) {
    const file = ref<string>()
    const queryParams = ref<string>()

    const { t } = useI18n()

    onMounted( async () => {
      if(props.slide && 
        props.slide.toLowerCase().includes('/edit') &&
        props.slide.toLowerCase().includes('/presentation')
      ){
        file.value =  props.slide.split('edit')[0]
        queryParams.value = 'preview?start=false&loop=false'
      } 
    })


    return { t, queryParams, file }
  }
})
