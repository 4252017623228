
// Vendors
import { defineComponent, PropType, ref } from 'vue'
import { DateTime } from 'luxon'

// Components
import Icon from '@/components/aero/icon/Icon.vue'
import ButtonIcon from '@/components/aero/buttons/ButtonIcon.vue'
import { ChevronUp, CheckFill } from '@/components/aero/icon/templates'

// Utils
import { getMonth, getYear } from '@/utils/datetime'

// Copies
import { billingHistory } from '@/locales/profile/es.json'

interface IBillingItem {
  date: string
  camada: string
  level: string
  amount: string
  registered: boolean
  payoutId: string
  _id: string
}

export default defineComponent({
  components: { Icon, ButtonIcon, ChevronUp, CheckFill },
  emits: ['onToggleDetail'],
  props: {
    item: { type: Object as PropType<IBillingItem>, required: true },
    isEven: { type: Boolean, default: false }
  },
  setup(props, { emit }) {
    const isOpen = ref<boolean>(false)

    const handleToggle = () => {
      isOpen.value = !isOpen.value
      emit('onToggleDetail', isOpen.value, props.item.payoutId, props.item._id)
    }

    const setParsedDate = (date: string) => {
      // Parse date to millis
      const dateMillis = DateTime.fromISO(date).toMillis()
      return `${getMonth(dateMillis)}/${getYear(dateMillis)}`
    }

    return { setParsedDate, isOpen, handleToggle, copies: billingHistory }
  }
})
