import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-713e90fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-pl-3 p-pt-2" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "p-pr-2 p-pt-2" }
const _hoisted_4 = { class: "icon-wrapper p-d-flex p-jc-center p-ai-center" }
const _hoisted_5 = { class: "p-pl-2" }
const _hoisted_6 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomBreadcrumb = _resolveComponent("CustomBreadcrumb")!
  const _component_icon_base = _resolveComponent("icon-base")!
  const _component_DynamicCard = _resolveComponent("DynamicCard")!

  return (_openBlock(), _createBlock(_component_DynamicCard, {
    title: _ctx.challenge.homeworkName,
    shadowless: "",
    borderless: "",
    "title-padding-x": "p-px-5",
    backgroundColor: '#27282D'
  }, {
    "pre-header": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_CustomBreadcrumb, {
          home: _ctx.home,
          model: _ctx.items
        }, null, 8, ["home", "model"])
      ])
    ]),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (step, index) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
          (step.visible)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass([
          'p-px-5 p-py-5 cursor-pointer',
          'step-wrapper',
          { active: index === _ctx.currentStep, disabled: step.isUnavailable },
          step.isUnavailable ? 'disable-div' : ''
        ]),
                onClick: () => _ctx.changeStep(index)
              }, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_icon_base, {
                      width: "16",
                      height: "16",
                      viewBox: '0 0 16 16',
                      strokeColor: "#BEBEBE"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(step.icon)))
                      ]),
                      _: 2
                    }, 1024)
                  ])
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("h6", _hoisted_6, _toDisplayString(step.title), 1),
                  _createElementVNode("span", {
                    class: "description",
                    style: _normalizeStyle(`color: ${step.descriptionColor};`)
                  }, _toDisplayString(step.expiration), 5)
                ])
              ], 10, _hoisted_2))
            : _createCommentVNode("", true)
        ], 64))
      }), 128))
    ]),
    _: 1
  }, 8, ["title"]))
}