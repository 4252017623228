import { RouteRecordRaw } from 'vue-router'
import Videotutorials from '@/views/videotutorials/Videotutorials.vue'
import { NewPlatform as Names } from '@/router/route-names'

const VideotutorialsRoutes: Array<RouteRecordRaw> = [
  {
    path: '',
    component: Videotutorials,
    name: Names.VIDEOTUTORIALS
  }
]

export default VideotutorialsRoutes
