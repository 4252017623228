import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-788dedb8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p2 medium p-ml-3 p-pr-3 label__student" }
const _hoisted_2 = {
  key: 1,
  class: "p-d-flex p-ai-center"
}
const _hoisted_3 = { class: "p2 p-d-flex p-flex-column p-pr-3" }
const _hoisted_4 = { class: "p2 text-light-gray p-mb-1" }
const _hoisted_5 = { class: "p2 medium" }
const _hoisted_6 = { class: "p-ml-auto p-d-flex p-ai-center p-text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_toast = _resolveComponent("toast")!
  const _component_notification = _resolveComponent("notification")!
  const _component_icon = _resolveComponent("icon")!
  const _component_avatar = _resolveComponent("avatar")!
  const _component_dots = _resolveComponent("dots")!
  const _component_button_icon = _resolveComponent("button-icon")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_card = _resolveComponent("card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_toast),
    _createVNode(_component_card, {
      class: "listtab p-d-flex p-ai-center",
      id: _ctx.DOMId,
      style: _normalizeStyle(_ctx.isActive && 'background-color: var(--gray)'),
      tabindex: "0"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: "listtab__left p-d-flex p-ai-center",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onClickCard')))
        }, [
          (_ctx.variant === 'student')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_icon, {
                  strokeColor: 'var(--text-secondary)',
                  size: 20,
                  class: "p-mt-2",
                  fillColor: 'var(--secondary)'
                }, {
                  default: _withCtx(() => [
                    (_ctx.hasUnreadMessage)
                      ? (_openBlock(), _createBlock(_component_notification, { key: 0 }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["strokeColor", "fillColor"]),
                _createVNode(_component_avatar, {
                  name: _ctx.student.student.fullName,
                  imageLink: _ctx.student.student.avatar
                }, null, 8, ["name", "imageLink"]),
                _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.student.student.fullName), 1)
              ], 64))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_icon, {
                  strokeColor: 'var(--text-secondary)',
                  size: 20,
                  class: "p-mt-2",
                  fillColor: 'var(--secondary)'
                }, {
                  default: _withCtx(() => [
                    (_ctx.hasUnreadMessage)
                      ? (_openBlock(), _createBlock(_component_notification, { key: 0 }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["strokeColor", "fillColor"]),
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.classPrefix) + " " + _toDisplayString(_ctx.challenge.class), 1),
                  _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.challenge.title), 1)
                ])
              ]))
        ]),
        _createElementVNode("div", _hoisted_6, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _createVNode(_component_button_icon, {
          href: "#options",
          onClick: _ctx.toggle,
          "aria-haspopup": "true",
          "aria-controls": "overlay_menu",
          color: _ctx.menuItems.length ? 'var(--light-gray)' : 'var(--gray-0)',
          class: _normalizeClass(!_ctx.menuItems.length ? 'menu__notAllowed' : '')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_dots)
          ]),
          _: 1
        }, 8, ["onClick", "color", "class"]),
        _createVNode(_component_Menu, {
          id: "overlay_menu",
          ref: "menu",
          model: _ctx.menuItems,
          popup: true
        }, null, 8, ["model"])
      ]),
      _: 3
    }, 8, ["id", "style"])
  ], 64))
}