// Models
import { UserLeaderboard } from '@/models/dashboard/ranking'

export interface ICourse {
  id: string
  camadaNro: string
  camadaId: string
  name: string
  sharedFolderLink?: string
  teachersSharedFolderLink?: string
  isEnded: boolean
  isStarted: boolean
  isInProgress: boolean
  leaderboard?: UserLeaderboard[]
  hasCoderAsk: boolean
  hasCoderAskActive: boolean
}

export class Course {
  id: string
  camadaNro: string
  camadaId: string
  name: string
  sharedFolderLink?: string
  teachersSharedFolderLink?: string
  isEnded: boolean
  isStarted: boolean
  isInProgress: boolean
  leaderboard?: UserLeaderboard[]
  hasCoderAsk: boolean
  hasCoderAskActive: boolean

  constructor(from: ICourse) {
    this.id = from.id
    this.camadaNro = from.camadaNro
    this.camadaId = from.camadaId
    this.name = from.name
    this.sharedFolderLink = from.sharedFolderLink
    this.teachersSharedFolderLink = from.teachersSharedFolderLink
    this.isEnded = from.isEnded
    this.isStarted = from.isStarted
    this.isInProgress = from.isInProgress
    this.leaderboard = from.leaderboard
    this.hasCoderAsk = from.hasCoderAsk
    this.hasCoderAskActive = from.hasCoderAskActive
  }
}
