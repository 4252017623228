import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-36e4f1a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input__wrapper p-my-4" }
const _hoisted_2 = { class: "p-d-flex p-ai-center p-my-4" }
const _hoisted_3 = { class: "p-d-flex p-my-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_skeleton_text = _resolveComponent("skeleton-text")!
  const _component_skeleton_input = _resolveComponent("skeleton-input")!
  const _component_skeleton_icon = _resolveComponent("skeleton-icon")!
  const _component_skeleton_box = _resolveComponent("skeleton-box")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_skeleton_text, { length: 30 }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_skeleton_input),
      _createVNode(_component_skeleton_input),
      _createVNode(_component_skeleton_input),
      _createVNode(_component_skeleton_input)
    ]),
    _createVNode(_component_skeleton_text, { length: 30 }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_skeleton_icon, { class: "p-mr-3" }),
      _createVNode(_component_skeleton_text, { length: 15 })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_skeleton_input, { class: "p-mr-3" }),
      _createVNode(_component_skeleton_input)
    ]),
    _createVNode(_component_skeleton_box, { height: "150px" })
  ], 64))
}