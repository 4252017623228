
// Vendor
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

// Components
import CardBanner from '@/components/aero/dataDisplay/CardBanner.vue'

export default defineComponent({
  components: {
    CardBanner
  },
  setup() {
    const { t } = useI18n()

    return {
      t
    }
  }
})
