
import { Language } from '@/models/profile/profile'
import { getLanguagesUserList, deleteLanguageUser } from '@/services/newPlatform/profile.services'
import { useUserSessionStore } from '@/store/modules/user-session.store'
import { defineComponent, onMounted, Ref, ref } from 'vue'
import copies from '@/locales/profile/es.json'
import LanguagesModal from './modals/LanguagesModal.vue'

export default defineComponent({
  components: { LanguagesModal },
  emits: ['switchTalent'],
  props: {
    showLanguagesError: { type: Boolean, default: false }
  },
  setup(_, { emit }) {
    const profileLanguages: any = copies.editProfile.profileLanguages
    const displayModal = ref(false)
    const { userId } = useUserSessionStore()
    const languages: Ref<Language[]> = ref([])

    const openModal = () => {
      displayModal.value = true
    }

    const closeModal = async () => {  
      await getLanguages()    
      emit('switchTalent')
      displayModal.value = false
    }

    const getLanguages = async () => {
      languages.value = await getLanguagesUserList( userId )
    }

    onMounted(getLanguages)

    const deleteLanguage = async (languageId: string) => {
      await deleteLanguageUser(userId, languageId)
      await getLanguages()
      emit('switchTalent')
    }

    return { openModal, displayModal, closeModal, deleteLanguage, languages, profileLanguages }
  }
})

