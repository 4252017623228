
// Vendor
import { defineComponent, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { NewPlatform } from '@/router/route-names'
import { DateTime } from 'luxon'

// Components
import CourseSelector from './CourseSelector.vue'
import Avatar from '@/components/aero/dataDisplay/Avatar.vue'
import Icon from '@/components/aero/icon/Icon.vue'
import { MenuIcon, Trophy } from '@/components/aero/icon/templates'
import Notifications from './notifications/Notifications.vue'
import SkeletonIcon from '@/components/aero/skeleton/SkeletonIcon.vue'
import SkeletonBox from '@/components/aero/skeleton/SkeletonBox.vue'

// Stores
import { useUserStore } from '@/store/modules/user.store'
import { useCourseStore } from '@/store/modules/course.store'
import { useUserSessionStore } from '@/store/modules/user-session.store'
import { useNotificationStore } from '@/store/modules/notifications.store'

// Services
import { getNotifications } from '@/services/newPlatform/navigation.services'
import { getRanking } from '@/services/newPlatform/ranking.services'

// Models
import { INotification } from '@/models/navigation/notifications'

const ShowTopBarButtonsInPath = [
  NewPlatform.CLASSES,
  NewPlatform.CHALLENGE,
  NewPlatform.CHALLENGES,
  NewPlatform.CHALLENGES_BY_MODULE,
  NewPlatform.CHALLENGES_BY_STUDENT
]

const USERS = [
  {
    label: 'E1',
    id: '61362c327421a50010cfcc94'
  },
  {
    label: 'E2',
    id: '61788c7ae935330070e1ae62'
  },
  {
    label: 'P1',
    id: '60e355066c0ac900122fa78a'
  },
  {
    label: 'P2',
    id: '6130122f688c730010875088'
  },
  {
    label: 'T1',
    id: '611eb298d9f4080011924b63'
  },
  {
    label: 'T2',
    id: '6130125b0ef09700103430fb'
  }
]

export default defineComponent({
  components: { CourseSelector, Avatar, Icon, MenuIcon, Notifications, Trophy, SkeletonIcon, SkeletonBox },
  setup(_, context) {
    const route = useRoute()
    const courseFromStore = useCourseStore()
    const notificationStore = useNotificationStore()

    const { userId, setUserSession } = useUserSessionStore()
    const MOCK_USER = process.env.MOCK_USER === '1'
    const usersMenu = ref()
    const users = ref<Array<{ label: string; id: string; command?: () => void }>>(USERS)

    const role = ref(2)
    const firstName = ref('')
    const level = ref('')
    const imageLink = ref('')

    const sharedFolderLink = ref<string | undefined>('')
    const teachersSharedFolderLink = ref<string | undefined>('')

    const userNotifications = ref<Array<INotification>>([])
    const loadingNotification = ref<boolean>(true)
    const errorNotifications = ref<boolean>(false)

    const loadingRanking = ref(true)
    const userRanking = ref(0)

    const showButtons = ref<boolean>(false)

    const handleLinks = () => {
      sharedFolderLink.value = courseFromStore.sharedFolderLink
      teachersSharedFolderLink.value = courseFromStore.teachersSharedFolderLink
    }

    const handleOpenMenu = (e: Event) => {
      context.emit('open-menu')
      e.stopPropagation()
    }

    const handleMountedComponent = async () => {
      handleLinks()
      const user = useUserStore()
      await user.getUser()

      role.value = Number(user.role)
      level.value = user.level
      firstName.value = user.firstName
      imageLink.value = user.avatar

      watch(user, () => {
        imageLink.value = user.avatar
      })

      try {
        const response = await getNotifications(userId)
        userNotifications.value = response
          .filter((n) => !n.flags.wasReaded)
          .sort((a, b) => DateTime.fromISO(b.createdAt).toMillis() - DateTime.fromISO(a.createdAt).toMillis())
        loadingNotification.value = false
      } catch (error) {
        errorNotifications.value = true
        loadingNotification.value = false
        // eslint-disable-next-line no-console
        console.error(error)
      }
    }

    const handleChangeUser = (e: Event) => {
      const { target } = e
      const id = (target as HTMLInputElement).value
      setUserSession(id)

      courseFromStore.setCourse({
        id: '',
        camadaNro: '',
        camadaId: '',
        name: '',
        sharedFolderLink: '',
        teachersSharedFolderLink: '',
        isEnded: false,
        isStarted: false,
        isInProgress: false,
        leaderboard: [],
        hasCoderAsk: false,
        hasCoderAskActive: false
      })

      handleMountedComponent()
    }

    const handleLeaderboard = async () => {
      try {
        const response = await getRanking(userId, courseFromStore.id)

        courseFromStore.setCourse({
          ...courseFromStore,
          leaderboard: response
        })

        userRanking.value = response.find((user) => user._id === userId)?.rank || 0
      } catch {
      } finally {
        loadingRanking.value = false
      }
    }

    onMounted(async () => {
      await handleMountedComponent()
      await handleLeaderboard()

      try {
        /**
         *  The BroadcastChannel API is used to communication between the app and services worker.
         *  This API is used to update notifications array when receive a background notification.
         *  @see https://developer.mozilla.org/en-US/docs/Web/API/Broadcast_Channel_API
         */
        const FCMBroadcastChannel = new BroadcastChannel('FCM')
        FCMBroadcastChannel.onmessage = () => {
          handleMountedComponent()
        }
      } catch {
        // eslint-disable-next-line no-console
        console.log('BroadcastChannel API is not supported')
      }
    })

    watch(courseFromStore, async () => {
      handleLinks()
      if (!courseFromStore.leaderboard?.length) {
        await handleLeaderboard()
      }
    })

    // Update notifications array when receive a foreground notification
    watch(notificationStore, () => {
      if (notificationStore.hasNewNotification) {
        handleMountedComponent()
      }
    })

    watch(route, () => {
      showButtons.value = ShowTopBarButtonsInPath.some((r) => r === route.name)
    })

    return {
      showButtons,
      teachersSharedFolderLink,
      sharedFolderLink,
      role,
      firstName,
      level,
      handleOpenMenu,
      MOCK_USER,
      userId,
      users,
      usersMenu,
      handleChangeUser,
      loadingNotification,
      userNotifications,
      errorNotifications,
      loadingRanking,
      userRanking,
      imageLink
    }
  }
})
