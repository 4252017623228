
import { defineComponent, ref, onMounted } from 'vue'

// Models
import { OnboardingVideo } from '@/models/newPlatform/onboardingVideo'

// Api
import { getStudentVideos } from '@/api/newPlatform/onboardingVideos.api'

// Store
import { themeStore } from '@/store/modules/themes.store'

import { useI18n } from 'vue-i18n'
import Header from './components/Header.vue'
import Video from './components/Video.vue'
import Footer from './components/Footer.vue'
import Loader from '../../components/commons/Loader.vue'

export default defineComponent({
  components: {
    Header,
    Video,
    Footer,
    Loader
  },
  setup() {
    themeStore().changeDefault('new-theme')
    const { t } = useI18n()
    const isLoading = ref<boolean>(true)
    const videos = ref<OnboardingVideo[]>([])

    onMounted(async () => {
      videos.value = await getStudentVideos()
      videos.value.unshift(videos.value[0])
      isLoading.value = false
    })

    return {
      t,
      videos,
      isLoading
    }
  }
})
