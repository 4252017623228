
import { defineComponent, PropType, ref } from 'vue'

// Components
import Menu from 'primevue/menu'
import ButtonIcon from '@/components/aero/buttons/ButtonIcon.vue'

// Icons
import { Dots } from '@/components/aero/icon/templates'

// utils
import { getYear, parseToMillis } from '@/utils/datetime'

// Models
import { IExperience } from '@/models/newPlatform/profile'

export default defineComponent({
  components: { Menu, ButtonIcon, Dots },
  props: {
    item: { type: Object as PropType<IExperience>, required: true },
    isEducation: { type: Boolean, default: false },
    isVisitorView: { type: Boolean, default: false }
  },
  emits: ['onEdit', 'onDelete'],
  setup(props, { emit }) {
    // Dropdown Menu
    const menu = ref()
    const toggle = (event: Event) => menu.value.toggle(event)
    const options = ref<Array<{ label: string; command: () => void }>>([
      { label: 'Editar', command: () => emit('onEdit', props.item, props.isEducation) },
      { label: 'Eliminar', command: () => emit('onDelete', props.item._id) }
    ])

    const formatYears = (startDate: string, endDate?: string) => {
      const start = parseToMillis(startDate)
      if (endDate) {
        const end = parseToMillis(endDate)
        return `${getYear(start)}-${getYear(end)}`
      } else {
        return `${getYear(start)}-Actualidad`
      }
    }

    return { formatYears, menu, toggle, options }
  }
})
