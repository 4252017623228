import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "p-mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_skeleton_invoice_card = _resolveComponent("skeleton-invoice-card")!
  const _component_form_invoice_info = _resolveComponent("form-invoice-info")!
  const _component_form_upload_invoice = _resolveComponent("form-upload-invoice")!
  const _component_billing_history = _resolveComponent("billing-history")!
  const _component_custom_transition = _resolveComponent("custom-transition")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isLoding)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_skeleton_invoice_card),
          _createVNode(_component_skeleton_invoice_card),
          _createVNode(_component_skeleton_invoice_card),
          _createVNode(_component_skeleton_invoice_card)
        ], 64))
      : (_openBlock(), _createBlock(_component_custom_transition, { key: 1 }, {
          default: _withCtx(() => [
            (_ctx.showFormToCompletBillingInfo)
              ? (_openBlock(), _createBlock(_component_form_invoice_info, {
                  key: 0,
                  defaultBillingInfo: _ctx.defaultBillingInfo,
                  firstTimeFillingForm: _ctx.isFirstTimeFilling,
                  onShowForm: _ctx.handleShowFormToCompletBillingInfo,
                  showFormWithCancelButton: _ctx.showFormWithCancelButton
                }, null, 8, ["defaultBillingInfo", "firstTimeFillingForm", "onShowForm", "showFormWithCancelButton"]))
              : (_ctx.showFormUploadInvoice)
                ? (_openBlock(), _createBlock(_component_form_upload_invoice, {
                    key: 1,
                    invoiceData: _ctx.invoiceData,
                    invoiceId: _ctx.invoiceId,
                    paymentId: _ctx.paymentId,
                    onShowUploadInvoice: _ctx.handleHideUploadInvoice
                  }, null, 8, ["invoiceData", "invoiceId", "paymentId", "onShowUploadInvoice"]))
                : (_openBlock(), _createBlock(_component_billing_history, {
                    key: 2,
                    userId: _ctx.userId,
                    editBillingInfo: _ctx.handleShowFormToCompletBillingInfo,
                    onShowUploadInvoice: _ctx.handleShowUploadInvoice
                  }, null, 8, ["userId", "editBillingInfo", "onShowUploadInvoice"]))
          ]),
          _: 1
        }))
  ]))
}