
import { defineComponent, Ref, ref, PropType, onMounted } from 'vue'
import { IUser as UserInfo } from '@/models/user'
import { Country } from '@/models/profile/profile'
import { useI18n } from 'vue-i18n'
import InputText from 'primevue/inputtext'
import { getCountriesList } from '@/services/newPlatform/profile.services'

export default defineComponent({
  components: {
    InputText
  },
  props: {
    user: { type: Object as PropType<UserInfo>, required: true }
  },
  emits: ['changeFirstName', 
          'changeLastName',
          'changePhone',
          'changeCountry',
          'changeCity'
         ],
  setup (props, { emit }) {
    const { t } = useI18n()
    const isItemSelected: Ref<boolean> = ref(false)
    const country = ref(props.user.country)
    const countryList: Ref<Array<Country>> = ref([])
    const filteredCountry: Ref<Array<Country>> = ref([])
    const searchCountry = ref('')

    const searchCountries = () => {
      setTimeout(() => {
        if (searchCountry.value.trim().length) {
          filteredCountry.value = countryList.value.filter((country: Country) => {
            return country.country.toLowerCase().startsWith(searchCountry.value.toLowerCase())
          })
        }
      }, 250)
    }

    const setSelectedItem = (value: boolean) => {
      setTimeout(() => {
        isItemSelected.value = value
      }, 100)
    }

    const selectItemCountry = (item: Country) => {
      searchCountry.value = item.country
      country.value = item.iso2.toLowerCase()
      changeCountry(country.value)
      isItemSelected.value = true
    }

    const deleteSelectionCountry = () => {
      const input = document.getElementById('search')
      if (input) {
        input.focus()
      }
      isItemSelected.value = false
      filteredCountry.value = countryList.value
    }

    const changeFirstName = (event: Event) => {
      const { target } = event
      if (target) {
        emit('changeFirstName', (target as HTMLInputElement).value)
      }
    }

    const changeLastName = (event: Event) => {
      const { target } = event
      if (target) {
        emit('changeLastName', (target as HTMLInputElement).value)
      }
    }

    const changePhone = (event: Event) => {
      const { target } = event
      if (target) {
        emit('changePhone', (target as HTMLInputElement).value)
      }
    }

    const changeCountry = (country: string) => {
      emit('changeCountry', country)
    }

    const changeCity = (event: Event) => {
      const { target } = event
      if (target) {
        emit('changeCity', (target as HTMLInputElement).value)
      }
    }

    onMounted(async () => {
      countryList.value = (await getCountriesList()).data

      if (country.value) {
        const matchCountry = await countryList.value.filter(
          (c: Country) => c.iso2.toLowerCase() === country.value.toLowerCase()
        )

        if (matchCountry?.length) {
          selectItemCountry(matchCountry[0])
          isItemSelected.value = true
        }
      }
    })

    return {
      t,
      changeFirstName,
      changeLastName,
      changePhone,
      changeCountry,
      changeCity,
      countryList,
      filteredCountry,
      searchCountry,
      searchCountries,
      isItemSelected,
      setSelectedItem,
      selectItemCountry,
      deleteSelectionCountry
    }
  }
})
