
import { ICorrectionDataDto } from '@/models/peer-review/homeworkPlan'
import { defineComponent, ref } from 'vue'
// import ChallengeHeader from './ChallengeHeader.vue'
import ChallengeStatement from './ChallengeStatement.vue'
import UploadSection from './UploadSection.vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  emits: ['send', 'cancelEdit'],
  props: {
    sending: Boolean,
    slide: String,
    mode: {
      type: String
    }
  },
  components: {
    // ChallengeHeader,
    ChallengeStatement,
    UploadSection
  },
  setup (props, { emit }) {
    const { t } = useI18n()
    const showCriteria = ref<boolean>(false)
    const setShowCriteria = (criteria: boolean) => (showCriteria.value = criteria)

    const send = async (body: ICorrectionDataDto) => {
      emit('send', body)
    }

    const cancel = () => {
      emit('cancelEdit')
    }

    return {
      t,
      showCriteria,
      setShowCriteria,
      send,
      cancel
    }
  }
})
