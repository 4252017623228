
// Vendor
import { defineComponent, ref, watch, reactive, onMounted } from 'vue'
import { useRouter } from 'vue-router'

// Store
import { useUserStore } from '@/store/modules/user.store'

// Components
import Form from '@/components/aero/forms/Form.vue'
import CoursesTable from '@/views/profile/components/CoursesTable.vue'
import Toast from '@/components/aero/feedback/Toast.vue'
import PlaceholderState from '@/components/aero/surfaces/challenges/PlaceholderState.vue'
import SkeletonTableFilter from '@/components/aero/skeleton/SkeletonTableFilter.vue'

// Copies
import { studentChangeCourse as studentChangeCopies, transferCourse as transferCopies } from '@/locales/profile/es.json'

// Utils
import { parseToMillis, getCompleteDate } from '@/utils/datetime'

// Services
import { requestReenroll } from '@/services/newPlatform/profile.services'
import { getAllProductsService, getIncomingCoursesOfSameCategory } from '@/services/newPlatform/products.services'
import { payPenalty } from '@/services/newPlatform/payment.service'

// Model
import { Product, ProductCategory } from '@/models/newPlatform/products/products'
import { ProductCourse, IncomingClass } from '@/models/newPlatform/products/productCourse'
import { ReenrollPayload, PaymentPayload } from '@/models/newPlatform/selfManagement'

// Hooks
import { useToast } from 'primevue/usetoast'

// Amplitude
import { trackEvent } from '@/amplitude/actions'
import { ProfileEvents } from '@/amplitude/constants'
import { ToastMessageOptions } from 'primevue/toast'

export default defineComponent({
  components: {
    Form,
    PlaceholderState,
    CoursesTable,
    Toast,
    SkeletonTableFilter
  },
  props: {
    leadTeacherId: {
      type: String,
      required: true
    },
    hasToPay: {
      type: Boolean,
      default: true
    },
    penaltyReenroll: {
      type: Boolean,
      required: true
    },
    courseId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { id: userId, country } = useUserStore()

    const _copies = studentChangeCopies.comision.toast // local copies

    const router = useRouter()

    const toast = useToast()

    /** Error and cancel **/
    const failLoadClasses = () => {
      toast.add({
        severity: studentChangeCopies.comision.toast.error.toastSeverity.error as ToastMessageOptions['severity'],
        detail: studentChangeCopies.comision.toast.error.errorLoading,
        group: studentChangeCopies.comision.toast.error.group,
        life: studentChangeCopies.comision.toast.error.life
      })
    }

    const failSubmitRequest = () => {
      toast.add({
        severity: _copies.error.toastSeverity.error as 'success',
        detail: _copies.error.errorSubmit,
        group: _copies.error.group,
        life: _copies.error.life
      })
    }

    /** Change course **/
    const productsList = ref<Product[]>([])

    const toUser = reactive({
      category: '',
      course: '',
      productId: ''
    })

    // List of comission available per course
    const coursesData = ref<IncomingClass[]>([])
    const comissionSelected = ref('')

    const newCategory = ref('')
    const newCourse = ref('')
    const isLoadingCourses = ref(false)
    const changeCourseClassesError = ref(false)

    watch(newCategory, () => {
      comissionSelected.value = ''
      toUser.productId = ''
      toUser.course = ''
      coursesData.value = []
    })

    // Form states
    const disableSubmit = ref<boolean>(true)

    const formCategory = transferCopies.questions.category
    const questions = ref([
      {
        name: 'category',
        label: formCategory.label,
        defaultValue: '',
        disabled: false,
        type: 'select',
        options: [
          { label: formCategory.options.design, value: ProductCategory.design },
          { label: formCategory.options.marketing, value: ProductCategory.marketing },
          { label: formCategory.options.development, value: ProductCategory.development },
          { label: formCategory.options.product, value: ProductCategory.product },
          { label: formCategory.options.data, value: ProductCategory.data },
          { label: formCategory.options.finance, value: ProductCategory.finance },
          { label: formCategory.options.business, value: ProductCategory.business },
          { label: formCategory.options.cybersecurity, value: ProductCategory.cybersecurity }
        ]
      },
      {
        name: 'course',
        label: transferCopies.questions.course.label,
        defaultValue: '',
        disabled: false,
        type: 'select',
        options: [
          {
            label: '',
            value: ''
          }
        ]
      }
    ])

    const buttonLabels = ref({
      submit: props.hasToPay ? transferCopies.questions.cta.payPenalty : transferCopies.questions.cta.sendRquest,
      cancel: transferCopies.questions.cta.cancel
    })

    // creates a list of courses depending on the category previuosly selected
    watch(toUser, () => {
      const filtered = productsList.value.filter((product) => product.categories[0] === toUser.category)
      const newList = filtered.map((product) => {
        return {
          label: product.title,
          value: product._id
        }
      })
      questions.value[1].options = newList
    })

    watch(newCourse, () => {
      updateCourses()
    })

    // DOM Handlers
    const handleChange = (values: { [x: string]: string }) => {
      toUser.category = values.category
      toUser.course = values.course

      newCategory.value = values.category
      newCourse.value = toUser.course
    }

    const handleChooseComission = (value: string) => {
      comissionSelected.value = value
    }

    //  Enable/disable submit button and show/hide empty state placeholder
    watch(toUser, () => {
      if (!questions.value[0].disabled && toUser.category && toUser.course) {
        disableSubmit.value = false
      } else {
        disableSubmit.value = true
      }
    })

    // Products
    const getAllProducts = async () => {
      try {
        const products = await getAllProductsService(country, 'remote')
        productsList.value = products
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e, 'error while trying to retrieve all products')
        throw e
      }
    }

    const parseClasses = (classes: ProductCourse[]): IncomingClass[] => {
      return classes.map((course) => {
        // Teacher name
        const teacherName = course.camada?.leadTeacher?.fullName || 'No asignado aún'
        const startDate = parseToMillis(course.startDate)
        const endDate = parseToMillis(course.endDate)

        return {
          teacherName,
          camadaNro: course.camada.id,
          days: course.days.join(' y '),
          schedule: course.hours,
          duration: `${getCompleteDate(startDate)} al ${getCompleteDate(endDate)}`,
          daysToStart: course.startsIn,
          leadTeacherId: course.camada?.leadTeacher?._id || '',
          startDate: parseToMillis(course.startDate),
          numDays: course._days,
          courseId: course._id,
          value: course._id
        }
      })
    }

    async function updateCourses() {
      isLoadingCourses.value = true

      try {
        const response = await getIncomingCoursesOfSameCategory(country, 'remotes', newCourse.value)

        coursesData.value = parseClasses(response)
      } catch (error) {
        changeCourseClassesError.value = true
        failLoadClasses()
        // eslint-disable-next-line no-console
        console.error(error)
      } finally {
        isLoadingCourses.value = false
      }
    }

    const handleCancel = () => {
      router.push('/profile')
    }

    /* pay penalty */
    const handlePayPenalty = async (payload: PaymentPayload): Promise<any> => {
      try {
        return await new Promise((resolve, reject) => {
          payPenalty(payload, (err: boolean, data: any) => (err ? reject(err) : resolve(data)))
        })
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e, 'error payment')
        throw e
      }
    }

    const handleSendRequest = async () => {
      const isSelfPayment = 1
      const { success, error, info, group, life } = transferCopies.toast.toastSeverity

      const payload: ReenrollPayload = {
        userId,
        fromCourseId: props.courseId,
        toCourseId: comissionSelected.value,
        flag: props.penaltyReenroll ? 'reenroll' : 'change',
        isSelfPayment,
        country,
        toUser: false,
        isTransferRequest: false
      }

      try {
        const paymentData = await requestReenroll(payload)

        if (!paymentData) return failSubmitRequest()

        if (isSelfPayment && paymentData.gateway) {
          toast.add({
            severity: info as ToastMessageOptions['severity'],
            detail: transferCopies.toast.payPenalty.info,
            group,
            life
          })
          localStorage.setItem('ch-reenroll-request', JSON.stringify(paymentData))

          await new Promise((resolve) => setTimeout(resolve, 3000))
          const _paymentData = await handlePayPenalty({
            ...paymentData,
            ...payload
          })

          if (_paymentData?.success) {
            toast.add({ severity: success as ToastMessageOptions['severity'], detail: transferCopies.toast.payPenalty.success, group, life })

            trackEvent(ProfileEvents.CHANGE_COURSE)

            setTimeout(() => {
              router.push('/profile')
            }, 300)
          }
        } else {
          trackEvent(ProfileEvents.CHANGE_COURSE)

          setTimeout(() => {
            toast.add({
              severity: _copies.sucessSubmit.toastSeverity.success as 'success',
              detail: _copies.sucessSubmit.sucess,
              group: _copies.sucessSubmit.group,
              life: _copies.sucessSubmit.life
            })
          }, 500)
          router.push('/profile')
        }
      } catch (error) {
        failSubmitRequest()
        // eslint-disable-next-line no-console
        console.error(error)
      }
    }

    onMounted(() => {
      getAllProducts()
    })

    return {
      questions,
      handleChange,
      handleChooseComission,
      disableSubmit,
      coursesData,
      buttonLabels,
      isLoadingCourses,
      handleSendRequest,
      handleCancel,
      transferCopies,
      studentChangeCopies,
      comissionSelected
    }
  }
})
