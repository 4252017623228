
// Vendor
import { defineComponent, PropType } from 'vue'

// Components
import SmallCardRatings from '@/components/aero/surfaces/ratings/SmallCardRatings.vue'
import ProgressBar from '@/components/aero/dataDisplay/ProgressBar.vue'

export default defineComponent({
  props: {
    feedbackType: { type: String as PropType<'positive' | 'neutral' | 'negative'>, required: true },
    title: { type: String, required: true },
    description: { type: String, required: true },
    amount: { type: Number, required: true },
    percentage: { type: Number, required: true }
  },
  components: {
    SmallCardRatings,
    ProgressBar
  }
})
