
import { defineComponent, PropType, ref } from 'vue'
import { CustomTab } from '@/models/commons/primevueTab'

export default defineComponent({
  emits: ['change'],
  props: {
    tabs: {
      type: Array as PropType<Array<CustomTab>>,
      default: () => []
    },
    hasCoderPlus: {
      type: Boolean
    }
  },
  setup (props, { emit }) {
    const modalFlag = ref(false)

    const change = (e: unknown) => emit('change', e)

    const modalFlagStatus = () => {
      modalFlag.value = true
    }

    const displayModal = ref(false)
    const openModal = (indx: number) => {
      if (indx === 1 && !props.hasCoderPlus){displayModal.value = true}
    }

    const closeModalDelay = () => {
      setTimeout(closeModalIf, 250)
    }

    const closeModalIf = () => {
      if (!modalFlag.value) {
        closeModal()
      }
    }

    const closeModal = () => {
      displayModal.value = false
      modalFlag.value = false
    }

    const analyticsEvent = () => {
      window.dataLayer.push({
      event: 'trackEvent',
      eventCategory: 'bolsa_de_empleo',
      eventAction: 'click_tab_ofertas_personalizadas',
      eventLabel: 'tab_ofertas_personalizadas'
      })
    } 

    return { 
      change,
      openModal,
      closeModal,
      displayModal,
      closeModalDelay,
      modalFlagStatus,
      analyticsEvent
      }
  }
})
