import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b3460668"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "message" }
const _hoisted_2 = { class: "message_header p-d-flex p-jc-between p-ai-start" }
const _hoisted_3 = { class: "message_user" }
const _hoisted_4 = { class: "message_user_data" }
const _hoisted_5 = { class: "user__name p1 medium text-white" }
const _hoisted_6 = { class: "user__date p3 text-light-gray" }
const _hoisted_7 = {
  key: 0,
  class: "message_edit p-d-flex p-ai-center"
}
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = {
  key: 0,
  class: "message_footer p-d-flex p-mt-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_avatar = _resolveComponent("avatar")!
  const _component_info = _resolveComponent("info")!
  const _component_icon = _resolveComponent("icon")!
  const _component_Button = _resolveComponent("Button")!
  const _component_file_tag = _resolveComponent("file-tag")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_avatar, {
          imageLink: _ctx.avatar,
          name: _ctx.userName
        }, null, 8, ["imageLink", "name"]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.userName), 1),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.setDateTime), 1)
        ])
      ]),
      (_ctx.canEdit)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _withDirectives((_openBlock(), _createElementBlock("span", null, [
              _createVNode(_component_icon, {
                size: 16,
                strokeColor: 'var(--light-gray)'
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_info)
                ]),
                _: 1
              }, 8, ["strokeColor"])
            ])), [
              [
                _directive_tooltip,
                _ctx.copies.tooltip,
                void 0,
                { top: true }
              ]
            ]),
            _createVNode(_component_Button, {
              class: "tertiary p1 p-button-sm p-ml-1",
              label: _ctx.copies.editButton
            }, null, 8, ["label"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", {
      class: "message_body p2 p-mt-3 text-white",
      innerHTML: _ctx.message
    }, null, 8, _hoisted_8),
    (!!_ctx.file || !!_ctx.link)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          (!!_ctx.file)
            ? (_openBlock(), _createBlock(_component_file_tag, {
                key: 0,
                isDownload: "",
                data: { text: _ctx.file.text, type: _ctx.file.type, size: _ctx.file.size }
              }, null, 8, ["data"]))
            : _createCommentVNode("", true),
          (!!_ctx.link)
            ? (_openBlock(), _createBlock(_component_file_tag, {
                key: 1,
                isLink: "",
                data: { text: _ctx.link.text, type: _ctx.link.type, size: _ctx.link.size }
              }, null, 8, ["data"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}