
// Vendors
import { defineComponent, ref, reactive, computed, watch, onMounted } from 'vue'

// Components
import TwoColsLayout from '@/components/aero/layout/TwoColsLayout.vue'
import AttendanceTable from '@/components/aero/dataDisplay/AttendanceTable.vue'
import TeacherTable from '@/views/attendance/components/TeacherTable.vue'
import TutorTable from '@/views/attendance/components/TutorTable.vue'
import ItemsCard from '@/components/aero/surfaces/ItemsCard.vue'
import PointCardAttendance from '@/components/aero/surfaces/attendance/PointCardAttendance.vue'
import Card from '@/components/aero/surfaces/Card.vue'
import ContactCard from '@/components/aero/dataDisplay/ContactCard.vue'
import SearchInput from '@/components/aero/inputs/SearchInput.vue'
import PlaceholderState from '@/components/aero/surfaces/challenges/PlaceholderState.vue'
import SkeletonCourseInfoCard from '@/components/aero/skeleton/SkeletonCourseInfoCard.vue'
import SkeletonPointCard from '@/components/aero/skeleton/SkeletonPointCard.vue'
import SkeletonAttendanceTable from '@/components/aero/skeleton/SkeletonAttendanceTable.vue'
import SkeletonTeacherGroup from '@/components/aero/skeleton/SkeletonTeacherGroup.vue'
import ErrorPage from '@/components/aero/dataDisplay/ErrorPage.vue'
import ToTopButton from '@/components/aero/buttons/ToTopButton.vue'

// PrimeVue
import Menu from 'primevue/menu'

// Stores
import { themeStore } from '@/store/modules/themes.store'
import { useCourseStore } from '@/store/modules/course.store'
import { useUserStore } from '@/store/modules/user.store'

// Services
import { getCourse } from '@/api/newPlatform/course.api'
import { getHolidays } from '@/services/classes.services'
import { getAttendances, setAttendance, setAbsent } from '@/services/newPlatform/attendance.services'
import { getTeachersWithStudents } from '@/services/newPlatform/commission.services'

// Models
import { ColumnType, UserAttendance, ISchedule, User } from '@/models/attendance/attendance'
import { ParsedClassmate, TutorClassmate } from '@/models/comission/comission'

// Utils
import { getDate } from '@/utils/datetime'

// Locales
import copies from '@/locales/attendance/es.json'

export default defineComponent({
  components: {
    TwoColsLayout,
    AttendanceTable,
    TutorTable,
    TeacherTable,
    ItemsCard,
    Card,
    ContactCard,
    PointCardAttendance,
    Menu,
    SearchInput,
    PlaceholderState,
    SkeletonCourseInfoCard,
    SkeletonPointCard,
    SkeletonAttendanceTable,
    SkeletonTeacherGroup,
    ErrorPage,
    ToTopButton
  },
  setup() {
    const theme = themeStore()
    theme.changeDefault('new-theme')
    const course = useCourseStore()
    const user = useUserStore()

    // current course state (aside data)
    const currentCourse = reactive({
      _id: '',
      category: '',
      courseName: '',
      titleShort: '',
      camadaNro: '',
      daysAndHours: '',
      startDate: '',
      endDate: '',
      holidays: [] as string[],
      teachers: [] as TutorClassmate[]
    })

    const attendanceStatus = reactive({
      items: [
        { type: 'active', value: 0 },
        { type: 'inactive', value: 0 }
      ]
    })

    const search = reactive({
      isActive: false,
      users: [] as UserAttendance[],
      attendance: [] as number[]
    })

    const isLoading = ref<boolean>(true)
    const isError = ref<boolean>(false)

    const columns = ref<ColumnType[]>()

    const students = ref<User[]>([])
    const tutors = ref<User[]>([])
    const schedules = ref<ISchedule[]>([])
    const tutorGroup = ref<User[]>([])

    const studentsByGroup = ref<ParsedClassmate[]>([])

    // Dropdown Menu
    const menu = ref()
    const menuIsOpen = ref(false)
    const toggle = (event: Event) => menu.value.toggle(event)

    const menuToggle = () => {
      menuIsOpen.value = !menuIsOpen.value
    }

    /**
     * Returns a string that will be used for displaying the label in the Button in charge of the sorting options dropdown menu
     */
    const selectedOptionLabel: (label: 'Mi grupo' | 'Todos los grupos' | 'Ver estudiantes' | 'Ver tutores') => string = (label) => {
      switch (label) {
        case 'Mi grupo':
          return copies.filters.tutor.myGroup
        case 'Todos los grupos':
          return copies.filters.tutor.allGroups
        case 'Ver estudiantes':
          return copies.filters.teacher.students
        case 'Ver tutores':
          return copies.filters.teacher.tutors
      }
    }

    const selectedSortTutor = ref<'students' | 'allGroups'>('students')

    const selectedSortTeacher = ref<'students' | 'tutors'>('tutors')

    const setSortingTutor: (sort: 'students' | 'allGroups') => void = (sort) => (selectedSortTutor.value = sort)
    const setSortingTeacher: (sort: 'students' | 'tutors') => void = (sort) => (selectedSortTeacher.value = sort)

    const sortingOptionsTutor: { label: string; value: 'students' | 'allGroups'; command: () => void }[] = [
      { label: copies.filters.tutor.myGroup, value: 'students', command: () => setSortingTutor('students') },
      { label: copies.filters.tutor.allGroups, value: 'allGroups', command: () => setSortingTutor('allGroups') }
    ]

    const sortingOptionsTeacher: { label: string; value: 'students' | 'tutors'; command: () => void }[] = [
      { label: copies.filters.teacher.tutors, value: 'tutors', command: () => setSortingTeacher('tutors') },
      { label: copies.filters.teacher.students, value: 'students', command: () => setSortingTeacher('students') }
    ]

    watch([selectedSortTutor, selectedSortTeacher], () => {
      search.isActive = false
      search.users = []
      search.attendance = []
    })

    const getActiveStudents = () => {
      const studentsGroup = user.isTutor ? tutorGroup.value : students.value
      return studentsGroup.filter((stu) => !stu.flags.inactiveStudent).length
    }

    const getAttendancePerc = computed(() => {
      const studentsGroup = user.isTutor ? tutorGroup.value : students.value
      const nextClass = schedules.value.findIndex(
        (_class) => _class.flags.isIncoming || _class.flags.isToday || _class.flags.isNext
      )

      const lastClass = schedules.value[nextClass - 1]

      let attendance = 0

      studentsGroup.forEach((student) => {
        student.schedules.forEach((sche) => {
          if (sche._id === lastClass._id && sche.attendend === 1) {
            attendance++
          }
        })
      })

      const attendancePerc = (attendance / studentsGroup.length) * 100
      return Math.round(attendancePerc)
    })

    const getCurrentCourseData = async () => {
      try {
        const response = await getCourse(user.id, course.camadaNro.toString())
        const holidays = await getHolidays(course.id, response.course.endDate)

        currentCourse.courseName = response.course.level.title
        currentCourse.titleShort = response.course.level.titleShortAlias
        currentCourse.daysAndHours = response.course.daysAndHours
        currentCourse.startDate = response.course.startDate
        currentCourse.endDate = response.course.endDate
        currentCourse.category = response.course.level.categories[0]
        currentCourse.camadaNro = response.id.toString()
        currentCourse.holidays = holidays
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('error while trying to retrieve data from the current course')
        throw e
      }
    }

    const getAttendancesData = async (userId: string, myGroup = false) => {
      try {
        const response = await getAttendances(course.camadaNro, userId)

        if (myGroup) {
          tutorGroup.value = response.allStudents
          return
        }
        students.value = response.allStudents
        tutors.value = response.allTutors
        schedules.value = response.schedules
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('error while trying to retrieve attendance data')
        throw e
      }
    }

    const setColumns = () => {
      columns.value = schedules.value.map((sche) => {
        const data = {
          field: sche._id,
          day: sche.day,
          header: getDate(sche.day)
        }

        return data
      })
    }

    const setUsers = (users: User[]) => {
      const parsed = users.map((user) => {
        const attendance = user.schedules.reduce((acc, { _id, attendend }) => {
          return { ...acc, [_id]: !!attendend }
        }, {})

        const flagDate = user.schedules.reduce((acc, { _id, _flags }) => {
          return { ...acc, [_id]: _flags.isNext || _flags.isTomorrow || _flags.isIncoming }
        }, {})

        const data = {
          id: user._id,
          name: user.fullName,
          avatar: user.avatar,
          beca: user.flags.beca,
          blocked: user.flags.fullBlocked,
          inactive: user.flags.inactiveStudent,
          attendance,
          flagDate,
          schedules: user.schedules
        }

        return data
      })

      return parsed
    }

    const normalizeName = (name: string) => {
      return name
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
    }

    const handleToggleAttendance = (values: { [x: string]: boolean }, scheduleId: string) => {
      const attendance = Object.values(values)[0]
      const userId = Object.keys(values)[0]
      if (attendance) {
        setAttendance(course.camadaId, scheduleId, userId)
      } else {
        setAbsent(course.camadaId, scheduleId, userId)
      }
    }

    const handleSearch = (value: string) => {
      search.isActive = value !== ''
      if (!search.isActive) {
        search.users = []
        search.attendance = []
        return
      }
      const name = normalizeName(value)
      const users = students.value
      if (!user.isTutor) users.concat(tutors.value)

      const filteredStudents = users.filter((user) => {
        const normalizedName = normalizeName(user.fullName)
        return normalizedName.includes(name)
      })

      if (filteredStudents.length === 0) {
        search.users = []
        return
      }

      const parsedUsers = setUsers(filteredStudents)

      const attendance = schedules.value.map(
        (sche) => parsedUsers.filter((stu) => stu.schedules.find((s) => s._id === sche._id && !!s.attendend)).length
      )

      search.users = parsedUsers
      search.attendance = attendance
    }

    onMounted(async () => {
      try {
        await getCurrentCourseData()
        const [tutorsWithStudents, teacherGroup] = await getTeachersWithStudents(course.id, user.id, currentCourse.titleShort)
        studentsByGroup.value = tutorsWithStudents

        // aside
        currentCourse.teachers = teacherGroup
        const teacherId = teacherGroup.find((user) => user.role === 'teacher')?._id

        if (user.isTutor) {
          await getAttendancesData(user.id, true)
          await getAttendancesData(teacherId || user.id)
        } else {
          await getAttendancesData(user.id)
        }
        setColumns()

        // active students
        attendanceStatus.items[0].value = getActiveStudents()
        const studentsGroup = user.isTutor ? tutorGroup.value : students.value
        // inactive students
        attendanceStatus.items[1].value = studentsGroup.length - attendanceStatus.items[0].value
      } catch (error) {
        isError.value = true
      } finally {
        isLoading.value = false
      }
    })

    return {
      currentCourse,
      isLoading,
      isError,
      copies,
      columns,
      students,
      tutors,
      studentsByGroup,
      search,
      attendanceStatus,
      menu,
      toggle,
      isTutor: user.isTutor,
      userId: user.id,
      schedules,
      handleToggleAttendance,
      handleSearch,
      selectedOptionLabel,
      sortingOptionsTutor,
      sortingOptionsTeacher,
      selectedSortTutor,
      selectedSortTeacher,
      getAttendancePerc,
      menuToggle,
      menuIsOpen
    }
  }
})
