
// Vendor
import { defineComponent, ref, watch, PropType } from 'vue'
import { useRouter } from 'vue-router'

// Store
import { useUserStore } from '@/store/modules/user.store'

// Components
import TableFilters from '@/components/aero/surfaces/profile/changeCourse/TableFilters.vue'
import Toast from '@/components/aero/feedback/Toast.vue'

// Copies
import { studentChangeCourse as studentChangeCopies, transferCourse as transferCopies } from '@/locales/profile/es.json'

// Services
import { requestReenroll } from '@/services/newPlatform/profile.services'
import { payPenalty } from '@/services/newPlatform/payment.service'

// Model
import { ProductCourse } from '@/models/newPlatform/products/productCourse'
import { ReenrollPayload, PaymentPayload } from '@/models/newPlatform/selfManagement'

// Hooks
import { useToast } from 'primevue/usetoast'

// Amplitude
import { trackEvent } from '@/amplitude/actions'
import { ProfileEvents } from '@/amplitude/constants'
import { ToastMessageOptions } from 'primevue/toast'

export default defineComponent({
  components: {
    TableFilters,
    Toast
  },
  props: {
    courseId: {
      type: String,
      required: true
    },
    leadTeacherId: {
      type: String,
      required: true
    },
    incomingClasses: {
      type: Array as PropType<ProductCourse[]>,
      required: true
    },
    penaltyReenroll: {
      type: Boolean,
      required: true
    },
    submitButtonLabel: {
      type: String,
      required: false
    }
  },
  setup(props) {
    const { id: userId, country } = useUserStore()

    const router = useRouter()

    const toast = useToast()

    const _copies = studentChangeCopies.comision.toast // local copies

    // Reenrollment
    const reenrollToCourseId = ref('')
    const disableSubmit = ref(true)

    watch(reenrollToCourseId, () => {
      if (reenrollToCourseId.value === '') {
        disableSubmit.value = true
      } else {
        disableSubmit.value = false
      }
    })

    /* Buttons */
    const handleCancelButton = () => {
      router.push('/profile')
    }

    const handleChooseComission = (value: string) => {
      reenrollToCourseId.value = value
    }

    const failSubmitRequest = () => {
      toast.add({
        severity: _copies.error.toastSeverity.error as ToastMessageOptions['severity'],
        detail: _copies.error.errorSubmit,
        group: _copies.error.group,
        life: _copies.error.life
      })
    }

    /* pay penalty */
    const handlePayPenalty = async (payload: PaymentPayload): Promise<any> => {
      try {
        return await new Promise((resolve, reject) => {
          payPenalty(payload, (err: boolean, data: any) => (err ? reject(err) : resolve(data)))
        })
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e, 'error payment')
        throw e
      }
    }

    const handleSendRequest = async () => {
      const isSelfPayment = 1
      const { success, error, info, group, life } = transferCopies.toast.toastSeverity

      const payload: ReenrollPayload = {
        userId,
        fromCourseId: props.courseId,
        toCourseId: reenrollToCourseId.value,
        flag: props.penaltyReenroll ? 'reenroll' : 'change',
        isSelfPayment,
        country,
        toUser: false,
        isTransferRequest: false
      }

      try {
        const paymentData = await requestReenroll(payload)

        if (!paymentData) return failSubmitRequest()

        if (isSelfPayment && paymentData.gateway) {
          toast.add({
            severity: info as ToastMessageOptions['severity'],
            detail: transferCopies.toast.payPenalty.info,
            group,
            life
          })
          localStorage.setItem('ch-reenroll-request', JSON.stringify(paymentData))

          await new Promise((resolve) => setTimeout(resolve, 3000))
          const _paymentData = await handlePayPenalty({
            ...paymentData,
            ...payload
          })

          if (_paymentData?.success) {
            toast.add({ severity: success as ToastMessageOptions['severity'], detail: transferCopies.toast.payPenalty.success, group, life })

            trackEvent(ProfileEvents.CHANGE_COMMISSION)

            setTimeout(() => {
              router.push('/profile')
            }, 300)
          }
        } else {
          setTimeout(() => {
            trackEvent(ProfileEvents.CHANGE_COMMISSION)

            toast.add({
              severity: _copies.sucessSubmit.toastSeverity.success as 'success',
              detail: _copies.sucessSubmit.sucess,
              group: _copies.sucessSubmit.group,
              life: _copies.sucessSubmit.life
            })
          }, 500)
          router.push('/profile')
        }
      } catch (error) {
        failSubmitRequest()
        // eslint-disable-next-line no-console
        console.error(error)
      }
    }

    return {
      handleCancelButton,
      handleSendRequest,
      handleChooseComission,
      disableSubmit,
      reenrollToCourseId,
      studentChangeCopies
    }
  }
})
