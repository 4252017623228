import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, toHandlers as _toHandlers, mergeProps as _mergeProps, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8c07f47e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = {
  key: 1,
  class: "p2 text-white"
}
const _hoisted_3 = {
  key: 2,
  class: "p3 text-light-gray file-data"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_link_icon = _resolveComponent("link-icon")!
  const _component_file = _resolveComponent("file")!
  const _component_icon = _resolveComponent("icon")!
  const _component_trash = _resolveComponent("trash")!
  const _component_download = _resolveComponent("download")!
  const _component_button_icon = _resolveComponent("button-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "tag-container p-d-flex p-ai-center bg-gray-2",
    style: _normalizeStyle(_ctx.styles)
  }, [
    _createVNode(_component_icon, { strokeColor: "var(--light-gray)" }, {
      default: _withCtx(() => [
        (_ctx.isLink)
          ? (_openBlock(), _createBlock(_component_link_icon, { key: 0 }))
          : (_openBlock(), _createBlock(_component_file, { key: 1 }))
      ]),
      _: 1
    }),
    (_ctx.isLink)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          class: "p2 text-white",
          href: _ctx.data.text,
          target: "_blank"
        }, _toDisplayString(_ctx.data.text), 9, _hoisted_1))
      : (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.data.text), 1)),
    (_ctx.data.type && _ctx.data.size)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.data.type) + " " + _toDisplayString(_ctx.data.size), 1))
      : _createCommentVNode("", true),
    (_ctx.isUpload || _ctx.isDownload)
      ? (_openBlock(), _createBlock(_component_button_icon, _mergeProps({
          key: 3,
          size: 16,
          color: _ctx.isDownload ? 'var(--secondary)' : 'var(--white)'
        }, _toHandlers({ click: _ctx.isUpload ? _ctx.handleDelete : _ctx.handleDownload })), {
          default: _withCtx(() => [
            (_ctx.isUpload)
              ? (_openBlock(), _createBlock(_component_trash, { key: 0 }))
              : (_openBlock(), _createBlock(_component_download, { key: 1 }))
          ]),
          _: 1
        }, 16, ["color"]))
      : _createCommentVNode("", true)
  ], 4))
}