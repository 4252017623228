import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0600f9b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tab-title p-m-0 p-p-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_TabView = _resolveComponent("TabView")!

  return (_openBlock(), _createBlock(_component_TabView, { onTabChange: _ctx.change }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, indx) => {
        return (_openBlock(), _createBlock(_component_TabPanel, { key: indx }, {
          header: _withCtx(() => [
            _createElementVNode("span", _hoisted_1, _toDisplayString(tab.title), 1)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }, 8, ["onTabChange"]))
}