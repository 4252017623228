import { RouteRecordRaw } from 'vue-router'
import Ranking from '@/views/ranking/Ranking.vue'

import { NewPlatform as Names } from '@/router/route-names'

const RankingRoutes: Array<RouteRecordRaw> = [
  {
    path: '',
    component: Ranking,
    name: Names.RANKING
  }
]

export default RankingRoutes
