
// Vendor
import { defineComponent } from 'vue'

// Components
import SmallCardCourse from '@/components/aero/surfaces/SmallCardCourse.vue'
import Card from '@/components/aero/surfaces/Card.vue'

// copies
import copies from '@/locales/dashboard/es.json'

export default defineComponent({
  components: { SmallCardCourse, Card },
  props: {
    courseName: { type: String, default: '' },
    link: { type: String, default: '' }
  },
  setup() {
    return { copies }
  }
})
