import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-849792a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "p-mr-3"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "p-d-flex p-flex-column p-jc-center p-p-3 text-wrapper" }
const _hoisted_4 = { class: "p2 medium text-white" }
const _hoisted_5 = { class: "p-d-flex p-flex-row p-mt-3 p-jc-between" }
const _hoisted_6 = { class: "p1 medium bold" }
const _hoisted_7 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chevron_right = _resolveComponent("chevron-right")!
  const _component_button_icon = _resolveComponent("button-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["banner-wrapper", { 'banner-wrapper-p-sm': _ctx.paddingSm, 'banner-wrapper-p-md': _ctx.paddingMd, 'p-d-flex': true, 'p-flex-row': true }])
  }, [
    (_ctx.img)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("img", {
            src: _ctx.img,
            class: "banner-image"
          }, null, 8, _hoisted_2)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.copies.offers.offerLabel.replace('40%', `${_ctx.offer}%`)), 1),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.course), 1),
        (_ctx.arrowLink)
          ? (_openBlock(), _createBlock(_component_button_icon, {
              key: 0,
              class: "icon-cta",
              color: "var(--white)",
              size: 24,
              href: _ctx.offerLink,
              asLinkType: "external"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_chevron_right)
              ]),
              _: 1
            }, 8, ["href"]))
          : (_openBlock(), _createElementBlock("a", {
              key: 1,
              style: {"text-decoration":"none"},
              href: _ctx.offerLink,
              target: "_blank",
              class: "text-primary bold"
            }, _toDisplayString(_ctx.copies.offers.moreInformation), 9, _hoisted_7))
      ])
    ])
  ], 2))
}