
import { defineComponent, PropType, ref, watch, onMounted } from 'vue'
import { IClass } from '@/models/classes/class'

// Components
import Menu from 'primevue/menu'
import Badge from 'primevue/badge'
import SkeletonClassCard from '@/components/aero/skeleton/SkeletonClassCard.vue'
import ClassCard from '@/components/aero/surfaces/classes/ClassCard.vue'
import PlaceholderState from '@/components/aero/surfaces/challenges/PlaceholderState.vue'

// Locales
import locales from '@/locales/classes/es.json'

// Store
import { useFirebaseStore } from '@/store/modules/firebase.store'
import { useCourseStore } from '@/store/modules/course.store'

export default defineComponent({
  components: { Menu, Badge, SkeletonClassCard, ClassCard, PlaceholderState },
  props: {
    classes: { type: Array as PropType<Array<IClass>>, default: () => [], required: true },
    afterClasses: { type: Array as PropType<Array<IClass>>, default: () => [], required: true },
    isLoading: { type: Boolean, default: true, required: true },
    role: { type: Number, default: 2, required: true },
    handleOpenForm: { type: Function, required: false, default: () => null },
    handleAfterClassValue: { type: Function, required: false, default: () => null },
    copies: { type: Object, required: true },
    handleOpenDialog: {
      type: Function,
      required: false,
      default: () => null
    },
    endDate: { type: Date, required: true },
    becaSuspended: { type: Boolean, required: true },
    handleEnableRate: { type: Function }
  },
  setup(props) {
    type filters = 'all' | 'last' | 'next' | 'absents'
    const filterBy = ref<filters>('all')
    const setFilter = (value: filters) => (filterBy.value = value)
    const localClasses = ref<Array<IClass>>([])
    const localAfterClasses = ref<Array<IClass>>([])
    const role = ref(props.role)
    const firebase = useFirebaseStore()
    const useCoderChallenge = ref<boolean>(false)
    const { camadaNro } = useCourseStore()

    const filterOptions = ref([
      { label: props.copies.content.viewAll, value: 'all', command: () => setFilter('all') },
      { label: props.copies.content.previous, value: 'last', command: () => setFilter('last') },
      { label: props.copies.content.next, value: 'next', command: () => setFilter('next') }
    ])
    const menuIsOpen = ref(false)

    // Absent filter just available for students
    watch(role, () => {
      const absentsFilter = { label: props.copies.content.absents, value: 'absents', command: () => setFilter('absents') }
      if (role.value === 2) filterOptions.value.push(absentsFilter)
    })

    const localHandleAfterClassValue = (stageId: string, complementaryId: string) => {
      props.handleAfterClassValue(stageId, complementaryId)
    }

    // Check if class has afterclass, and If that afterclass is scheduled later ('next filter')
    const nextFilter = (_class: IClass) => {
      if (props.afterClasses.length === 0) return _class.schedule.day > Date.now()

      const afterClassRelated = props.afterClasses.find((ac) => ac.schedule.stage === _class.schedule.stage)

      if (!afterClassRelated) return _class.schedule.day > Date.now()
      return _class.schedule.day > Date.now() || afterClassRelated.schedule.day > Date.now()
    }

    // filter classes using Ver todas filter
    watch(filterBy, () => {
      localClasses.value = props.classes.filter((_class: any) => {
        if (filterBy.value === 'all') return true
        if (filterBy.value === 'last' || filterBy.value === 'absents') return _class.schedule.day < Date.now()
        if (filterBy.value === 'next') return nextFilter(_class)
        return false
      })
    })

    watch(props, () => {
      localClasses.value = props.classes
      localAfterClasses.value = props.afterClasses
      role.value = props.role
    })

    // Dropdoown Menu
    const menu = ref()

    const menuToggle = () => {
      menuIsOpen.value = !menuIsOpen.value
    }

    const toggle = (event: Event) => {
      menu.value.toggle(event)
    }

     onMounted(async () =>{
      const coderChallenges  = await firebase.fetchValue('use_fresh_challenge', 'array') as string[]
      useCoderChallenge.value = coderChallenges.includes(camadaNro)
    })

    return {
      localClasses,
      localAfterClasses,
      useCoderChallenge,
      filterBy,
      setFilter,
      filterOptions,
      menu,
      menuToggle,
      menuIsOpen,
      localHandleAfterClassValue,
      toggle,
      locales
    }
  }
})
