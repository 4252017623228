const getDownloadLink = (link: string): string => {
  if (link?.includes('dropbox.')) {
    if (!link.includes('?')) link += '?'
    link += '&raw=1'
  } else if (link?.includes('google.') && link?.includes('/d/')) {
    const fileId = link.split('/d/')[1].split('/')[0]
    link = `https://drive.google.com/uc?export=download&id=${fileId}`
  }
  return link
}
export default getDownloadLink
