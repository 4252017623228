
// Vendor
import { defineComponent } from 'vue'

// Components
import RankingCard from '@/components/aero/surfaces/dashboard/RankingCard.vue'

export default defineComponent({
  components: { RankingCard },
  props: {
    progress: { type: Number, default: 0, required: true },
    total: { type: Number, default: 0, required: true },
    ranking: { type: Number, default: 0, required: true },
    copies: { type: Object }
  }
})
