import { ref, onBeforeUnmount } from 'vue'

export const displayTopButton = (distanceToTop = 300) => {
  const distanceToTopButton = distanceToTop
  const displayButton = ref<boolean>(false)

  const handleScroll = () => {
    // set render of to top button
    displayButton.value = window.scrollY > distanceToTopButton
  }
  window.addEventListener('scroll', handleScroll)

  onBeforeUnmount(() => {
    removeEventListener('scroll', handleScroll)
  })

  return { displayButton }
}
