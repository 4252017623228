import { DateTime } from 'luxon'
import { computed } from 'vue'

import esLang from '@/locales/classes/es.json'

const useGetClassTitle = (day: number, isAfterclass = false) => {
  const dt = DateTime.fromMillis(day)

  const title = computed(() => {
    if (isAfterclass) {
      if (dt < DateTime.local()) return esLang.thisWeek.previousAfterClass
      if (dt.hasSame(DateTime.local(), 'day')) return esLang.thisWeek.today
      if (dt > DateTime.local()) return esLang.thisWeek.nextAfterClass
    }
    if (dt < DateTime.local()) return esLang.thisWeek.previousClass
    if (dt.hasSame(DateTime.local(), 'day')) return esLang.thisWeek.today
    if (dt > DateTime.local()) return esLang.thisWeek.nextClass
    return esLang.content.class
  })

  return {
    title: title
  }
}

export default useGetClassTitle
