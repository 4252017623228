
// Vendors
import { defineComponent, ref, PropType } from 'vue'

// Components
import ButtonIcon from '@/components/aero/buttons/ButtonIcon.vue'
import { Dots } from '@/components/aero/icon/templates'
import Menu from 'primevue/menu'

type ItemTypes = {
  label: string
  url?: string
  target?: '_blank' | '_self'
  command?: () => void
}

export default defineComponent({
  components: { ButtonIcon, Dots, Menu },
  props: {
    options: { type: Array as PropType<ItemTypes[]> }
  },
  setup() {
    const menuRef = ref()

    const openMenu = (event: Event) => {
      event.preventDefault()
      menuRef.value.toggle && menuRef.value.toggle(event)
    }

    return { menuRef, openMenu }
  }
})
