// APIs services
import { getAllData, getFeedbackLink as getFeedbackLinkFromAPI } from '@/api/classes.api'
import { getCourse, getCalendar } from '@/api/newPlatform/course.api'

import classesStudent from '@/mocks/data/classes/student-1.json'
import classesTeacher from '@/mocks/data/classes/teacher-1.json'

// utils
import { getDate } from '@/utils/datetime'

import { DateTime } from 'luxon'

const mockClasses = {
  student: classesStudent,
  teacher: classesTeacher
}

export type TGetClases = {
  returnMockData?: boolean
  rol?: string
  order?: number
  userId?: string
  camadaId?: string
}

/**
 * Service to retrieve classes's data
 * @param {Object} ClassesData
 * @param {boolean} ClassesData.returnMock
 * @param {string} [ClassesData.rol = 'student']
 * @param {string} ClassesData.camadaId
 * @param {string} ClassesData.userId
 * @returns user, course, classes and afterclasses data
 */

export const getClassesData = async ({ returnMockData, rol = 'student', camadaId, userId }: TGetClases): Promise<any> => {
  if (returnMockData || (!camadaId && !userId)) {
    const r = rol as 'student' | 'teacher'
    const mockData = mockClasses[r]

    return mockData
  }

  if (userId && camadaId) {
    const results = await getAllData(userId, camadaId)

    return results
  }

  return undefined
}

export const getFeedbackLink = async (camdaId: string, scheduleId: string, userId: string) => {
  if (!!camdaId && !!scheduleId && !!userId) {
    return 'https://google.com'
  }

  const response = await getCourse(userId, camdaId)

  return await getFeedbackLinkFromAPI(response.course._id, scheduleId, userId)
}

/**
 *
 * @param courseId current course's calendar you'd like to request
 * @return array of parsed dates i.e ['18/4', '21/5']
 */

export const getHolidays = async (courseId: string, endDate: string): Promise<string[]> => {
  const { holidays } = await getCalendar(courseId)

  const parsedEndDate = DateTime.fromISO(endDate)
  const parsedHolidays: string[] = []

  holidays.forEach((holiday) => {
    const holidayDate = DateTime.fromISO(holiday.day)
    if (holidayDate < parsedEndDate) {
      const parsed = holidayDate.toMillis()
      parsedHolidays.push(getDate(parsed))
    }
  })

  return parsedHolidays
}
