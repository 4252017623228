
// Vendor
import { defineComponent } from 'vue'

// Primevue Components
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'

// Components
import CourseList from '@/views/profile/components/CourseList.vue'
import Billing from '@/views/profile/components/Billing.vue'
import TrainingView from '@/views/profile/components/TrainingView/TrainingView.vue'

// Copies
import copies from '@/locales/profile/es.json'

export default defineComponent({
  components: {
    TabView,
    TabPanel,
    CourseList,
    Billing,
    TrainingView
  },
  props: {
    userId: { type: String, required: true }
  },
  setup() {
    return { copies }
  }
})
