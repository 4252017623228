
import DynamicCard from '@/components/commons/DynamicCard.vue'
import { defineComponent, computed, PropType } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: { DynamicCard },
  emits: ['close'],
  props: {
    visible: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    criteria: {
      type: Array,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n()
    const show = computed({
      get: () => props.visible,
      set: () => emit('close')
    })
    const close = () => emit('close')

    return {
      t,
      close,
      show
    }
  }
})
