import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c6dc3ea4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profile" }
const _hoisted_2 = { class: "profile__detail" }
const _hoisted_3 = { class: "p3 regular text-light-gray" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Avatar, {
      name: _ctx.name,
      imageLink: _ctx.imageLink,
      isBlocked: _ctx.blocked
    }, null, 8, ["name", "imageLink", "isBlocked"]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.link)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            style: {"text-decoration":"none","color":"white"},
            to: _ctx.link
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", {
                class: _normalizeClass(`medium nameLink  ${_ctx.size === 'large' ? 't3' : 'p2 text-white'}`)
              }, _toDisplayString(_ctx.name), 3)
            ]),
            _: 1
          }, 8, ["to"]))
        : (_openBlock(), _createElementBlock("span", {
            key: 1,
            class: _normalizeClass(`medium name ${_ctx.size === 'large' ? 't3' : 'p2 text-white'}`)
          }, _toDisplayString(_ctx.name), 3)),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.category), 1)
    ])
  ]))
}