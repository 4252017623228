<template>
  <section class='containerPassword'>
    <h2>Cambiar <br> contraseña</h2>
    <div class="input-container">
      <label for="newPassword">Nueva contraseña</label>
      <InputText class="p-inputtext-lg bg-input input-top" v-model="v$.password.$model" type="password" name="newPassword"/>
    </div>
    <div v-if="v$.password.$errors[0]?.$validator == 'required'" class="form-error">El campo no puede estar vacio</div>
    <div v-if="v$.password.$errors[0]?.$validator == 'minLengthValue'" class="form-error">La contraseña debe contener al menos 6 caracteres</div>
    <div v-if="v$.password.$errors[0]?.$validator == 'maxLengthValue'" class="form-error">La contraseña debe contener menos de 12 caracteres</div>
    <div v-if="v$.password.$errors[0]?.$validator == 'containsPasswordRequirement'" class="form-error">La constraseña no cumple con los caracteres requeridos</div>
    <div class="input-container">
      <label for="confirmPassword">Confirmar contraseña</label>
      <InputText class="p-inputtext-lg bg-input input-top" v-model="v$.newPassword.$model" type="password" name="confirmPassword"/>
    </div>
    <div v-if="v$.newPassword.$errors[0]?.$validator == 'required'" class="form-error">El campo no puede estar vacio</div>
    <div v-if="passwordAreDifferents" class="form-error">Las contraseñas deben coincidir</div>
    <p class="password-requisites">Debe contener al menos un número y una letra mayúscula, y con un mínimo de entre 6 y 12 caracteres.</p>
    <Button :label="isLoading ? '' : 'Guardar'" class="secondary" :loading="isLoading" @click="submit()" />
  </section>
</template>

<script>
import { defineComponent, reactive, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { confirmPasswordService } from '@/services/newPlatform/profile.services'

export default defineComponent({
  props: {
    userId: { type: String, required: true }
  },
  emits: ['onSuccessChange', 'onErrorChange'],
  setup (props, { emit }) {
    const state = reactive({
      password: '',
      newPassword: ''
    })
    const passwordAreDifferents = ref(false)

    const isLoading = ref(false)
    const route = useRoute()
    const router = useRouter()
    const token = route.query.tkn

    const rules = {
      password: {
      required,
      minLengthValue: minLength(6),
      maxLengthValue: maxLength(12),
      containsPasswordRequirement: (value) => /(?=.*[A-Z])(?=.*[0-9])/.test(value)
      },
      newPassword: { required }
    }

    const v$ = useVuelidate(rules, state)

    const submit = async () => {
      v$.value.$touch()

      const isFormCorrect = await v$.value.$validate()
      
      if (!isFormCorrect) return

      if(state.password !== state.newPassword){    
        passwordAreDifferents.value = true
        return
      }

      try {
        isLoading.value = true
        const success = await confirmPasswordService({ userId: props.userId, token, password: state.password })
        v$.value.$reset()
        state.password = ''
        state.newPassword = ''
        isLoading.value = false
        if(success) {
        emit('onSuccessChange')
        router.push('/')
        } else {
        emit('onErrorChange')
        }
      } catch (error) {
        isLoading.value = false
        emit('onErrorChange')
      }
    }                                                                                                                                                                                                                                                                                                                                                                                                                                              

    return {
      state,
      v$,
      submit,
      passwordAreDifferents,
      isLoading
    }
  }
})
</script>

<style lang="scss" scoped>

  .containerPassword{
    height: 720px;
    width: 495px;
    background-color: var(--gray);
    border-radius: var(--border-radius);
    padding: 75px;
    box-shadow: 0 4px 20px rgba(31, 32, 35, 0.52);

    .input-container{
      margin-top: 20px;
    }

    h2{
      font-size: 40px;
      font-family: "Untitled Sans Regular", var(--fonts-fallback);
      font-weight: 700;
      text-align: center;
      line-height: 50px;
      margin: 0 auto 40px auto;
    }
    .bg-input{
      background-color: black !important;
    }
    button{
      margin:0 auto;
      display:block;
    }
    .input-top{
      margin-top: 8px;
    }
    .password-requisites{
      margin: 20px 0;
      font-size: 16px;
      line-height: 24px; 
    }
    .form-error {
      color: #ff896f;
      font-size: 0.625rem;
      line-height: 16px;
      text-align: left;
      margin-top: 0.5rem;
      width: 100%;
      margin-bottom: 8px;
    }
  }
</style>