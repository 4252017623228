import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "opinionTag p-d-flex p-flex-wrap gap-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SkeletonColumnOpinon = _resolveComponent("SkeletonColumnOpinon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(2, (i) => {
      return _createVNode(_component_SkeletonColumnOpinon, { key: i })
    }), 64))
  ]))
}