
// Vendor
import { defineComponent } from 'vue'
// Components
import SkeletonProfileCardSmall from './SkeletonProfileCardSmall.vue'

export default defineComponent({
  components: {
    SkeletonProfileCardSmall
  }
})
