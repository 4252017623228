import { getPersonById } from '@/api/person.api'
import { Person } from '@/models/person/person'
import { UserSessionState } from '@/models/user-session'
import { defineStore } from 'pinia'
import { useUserSessionStore } from './user-session.store'

export const usePersonStore = defineStore({
  id: 'person',
  state: () => ({
    person: null as Person | null
  }),
	getters: {
		profile (): Person | null  {
			return this.person
		}
	},
	actions: {
		async getPerson () {
			const userSession: UserSessionState = useUserSessionStore()
			this.person = await getPersonById(userSession.userId)
		}
	}
})
