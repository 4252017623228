import { IChat } from './chat'
export enum ITimeLineStatus {
  open = 'open',
  disabled = 'disabled',
  deadline = 'deadline',
  finalDeadline = 'final-deadline',
  delivered = 'delivered',
  reviewExpected = 'review-expected',
  reviewed = 'reviewed',
  disapproved = 'disapproved',
  approved = 'approved',
  expired = 'expired',
  chat = 'chat',
  deliverAgain = 'deliver-again',
  today = 'today',
  locked = 'locked',
  unlocked = 'unlocked',
  deliveredAgain = 'delivered-again'
}

export interface ITimeline {
  key: ITimeLineStatus
  name: string
  date: number | string
  expiresAt?: number
  completed?: boolean
  step?: number
  hide?: boolean
  content?: IChat
}
